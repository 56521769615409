import {
  FormControl,
  FormLabel,
  Switch,
  SwitchProps,
  Text,
} from "@chakra-ui/react";

import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import { useTargetingParam } from "@/feature/hooks/useTargetingParam";

type Props = SwitchProps;

export const UseTargetingSwitch = (props: Props) => {
  const [useTargeting, setUseTargeting] = useTargetingParam(true);

  return (
    <FormControl alignItems="center" display="flex" w="auto">
      <FormLabel
        alignItems="center"
        display="inline-flex"
        gap={1}
        htmlFor="use-targeting-rules"
        mr={2}
        pb="0"
      >
        <Text fontWeight="normal">Use targeting</Text>
        <InfoIconTooltip text="If this is enabled, the widget data will be filtered by the targeting rules, otherwise all data is shown." />
      </FormLabel>
      <Switch
        id="use-targeting-rules"
        isChecked={useTargeting}
        onChange={() => {
          setUseTargeting(!useTargeting);
        }}
        {...props}
      />
    </FormControl>
  );
};
