import { useNavigate } from "react-router-dom";

import { FlagUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import NewFlagModal from "@/flags/components/NewFlagModal";

export default function NewFlagPage() {
  const { currentEnv } = useAuthContext();
  const navigate = useNavigate();

  return (
    <NewFlagModal
      isOpen={true}
      onClose={() => {
        navigate("..");
      }}
      onSuccess={(flag) => {
        navigate(FlagUrl(currentEnv!, flag.id));
      }}
    />
  );
}
