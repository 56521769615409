import { useCallback } from "react";
import { RiMailSendLine, RiMarkdownLine } from "react-icons/ri";
import { useParams } from "react-router";
import {
  Alert,
  Button,
  ButtonGroup,
  Center,
  Divider,
  Flex,
  FlexProps,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Link,
  Spinner,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useClipboard,
  useColorMode,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { AttributeFilterOperator } from "@bucketco/shared/attributeFilter";
import { EnvironmentDTO } from "@bucketco/shared/environmentAPI";
import { EventSelectorType, FeatureDetail } from "@bucketco/shared/featureAPI";
import { GlobalSettingsUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import LinearLogo from "@/common/assets/linear-logo.svg?react";
import CodeBlock from "@/common/components/CodeBlock";
import { SectionHeading } from "@/common/components/SectionHeading";
import SlackStatus from "@/common/components/SlackStatus";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useFirstChangeFromTo } from "@/common/hooks/useFirstChangeFromTo";
import useSlackConnectionUrl from "@/common/hooks/useSlackConnectionUrl";
import useSlackStatus from "@/common/hooks/useSlackStatus";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import featureQueryKeys from "@/feature-legacy/data/featureQueryKeys";
import useFeatureData from "@/feature-legacy/data/useFeatureData";
import useFeatureHasData from "@/feature-legacy/hooks/useFeatureHasData";

import {
  getBrowserSDKCodeSample,
  getHTTPExampleForFeature,
  getNodeSDKCodeSample,
  getSegmentBrowserSDKCodeSample,
} from "./trackingCodeSamples";

export const TypeByOperator: Record<AttributeFilterOperator, string> = {
  GT: "number",
  LT: "number",
  IS: "string | number | boolean",
  IS_NOT: "string[] | number[] | boolean[]",
  ANY_OF: "string[] | number[] | boolean[]",
  NOT_ANY_OF: "string[]",
  CONTAINS: "string",
  NOT_CONTAINS: "string",
  IS_TRUE: "boolean",
  IS_FALSE: "boolean",
  AFTER: "date",
  BEFORE: "date",
  SET: "string | number | boolean",
  NOT_SET: `undefined | ""`,
  SEGMENT: "",
  NOT_SEGMENT: "",
  FLAG: "",
  NOT_FLAG: "",
};

export function ReportEmptyState({ children }: { children: React.ReactNode }) {
  const { featureId } = useParams();
  const { appId } = useCurrentEnv();
  const { currentEnv } = useAuthContext();
  const queryClient = useQueryClient();

  const hasDataQuery = useFeatureHasData(featureId);

  // when data has arrived, refetch the feature status which most likely has been
  // set to "evaluating" through the firstFeatureDataReceived automation
  useFirstChangeFromTo(hasDataQuery.data?.status, "no-data", "has-data", () => {
    queryClient.refetchQueries({
      queryKey: featureQueryKeys.single(appId, featureId),
    });
  });

  const featureDataQuery = useFeatureData(featureId);

  if (featureDataQuery.isLoading || hasDataQuery.isLoading) {
    return (
      <Center flexGrow={1}>
        <Spinner size="sm"></Spinner>
      </Center>
    );
  }

  if (
    hasDataQuery.isSuccess === false ||
    featureDataQuery.isSuccess === false
  ) {
    return (
      <Center flexGrow={1}>
        <VStack maxW="md">
          <Text color="red.500" fontSize="sm">
            Could not load the feature
          </Text>
          <Button
            size="sm"
            type="button"
            variant="outline"
            onClick={(e) => {
              e.preventDefault();
              hasDataQuery.refetch();
              featureDataQuery.refetch();
            }}
          >
            Try again
          </Button>
        </VStack>
      </Center>
    );
  }

  return (
    <Flex direction="column" flexGrow={1} pos="relative" w="100%">
      {hasDataQuery.data?.status === "no-data" && featureDataQuery.data ? (
        <EmptyState env={currentEnv!} feature={featureDataQuery.data} />
      ) : hasDataQuery.data?.status === "bootstrapping" ? (
        <ElevatedCard
          alignItems="center"
          flexDirection="row"
          p={12}
          width="auto"
        >
          <Spinner size="sm"></Spinner>
          <VStack align="start" spacing={0}>
            <Text fontWeight="semibold">Processing data...</Text>
            <Text color="dimmed">This is a one-time processing step.</Text>
          </VStack>
        </ElevatedCard>
      ) : (
        children
      )}
    </Flex>
  );
}

function ElevatedCard(props: FlexProps) {
  return (
    <Flex
      bg="appBackground"
      border="1px solid"
      borderColor="appBorder"
      borderRadius="lg"
      boxShadow="xl"
      flexDirection="column"
      gap="6"
      left="50%"
      maxWidth="3xl"
      mx="auto"
      my={8}
      pos="absolute"
      transform="translateX(-50%)"
      w="100%"
      {...props}
    />
  );
}

const tabNames = [
  "Bucket Browser SDK",
  "Bucket Node SDK",
  "Segment SDK",
  "HTTP API",
  "Markdown",
] as const;

function EmptyState({
  feature,
  env,
}: {
  feature: FeatureDetail;
  env: EnvironmentDTO;
}) {
  const featureMarkdown = getMarkdownForFeature(feature);

  const featureMarkdownWithLink = [
    `We need to set up tracking for the '${feature.name}'-feature in Bucket`,
    featureMarkdown,
    `[More details in Bucket](${window.location.href})`,
  ].join("\n\n");

  const trackTabSwitch = (idx: number) => {
    segmentAnalytics.track("Feature Planning Code Example Interaction", {
      type: "switch tab",
      tab: tabNames[idx].toLowerCase(),
    });
  };

  return (
    <ElevatedCard>
      <Alert
        bg={useColorMode().colorMode === "dark" ? "gray.700" : undefined}
        borderBottomRadius="0"
        color={useColorMode().colorMode === "dark" ? "orange.200" : undefined}
        justifyContent="space-between"
        pl={6}
        size="lg"
        status="warning"
      >
        <HStack>
          <Spinner size="xs" sx={{ animationDuration: "4s" }} />
          <Text fontSize="sm" fontWeight="medium">
            Waiting for data...
          </Text>
        </HStack>
        {env?.isProduction && (
          <FirstDataNotification env={env} feature={feature} />
        )}
      </Alert>
      <Flex flexDirection="column" gap="5" p={6} pt={0}>
        <Heading as="h3" fontSize="lg">
          Start tracking this feature
        </Heading>

        <Tabs
          colorScheme="gray"
          size="sm"
          variant="soft-rounded"
          onChange={trackTabSwitch}
        >
          <TabList>
            {tabNames.map((name) => (
              <Tab key={name}>{name}</Tab>
            ))}
          </TabList>

          <TabPanels color="gray.500" fontSize="sm" pt={4}>
            <TabPanel p={0}>
              <Flex flexDirection="column" gap="2">
                <CodeBlock language="javascript">
                  {getBrowserSDKCodeSample(feature)}
                </CodeBlock>
                <Text>
                  For installation instructions, see{" "}
                  <Link
                    href="https://github.com/bucketco/bucket-tracking-sdk"
                    target="_blank"
                  >
                    Bucket Tracking SDK
                  </Link>
                </Text>
              </Flex>
            </TabPanel>
            <TabPanel p={0}>
              <Flex flexDirection="column" gap="2">
                <CodeBlock language="javascript">
                  {getNodeSDKCodeSample(feature)}
                </CodeBlock>
                <Text>
                  For installation instructions, see{" "}
                  <Link
                    href="https://github.com/bucketco/bucket-tracking-sdk"
                    target="_blank"
                  >
                    Bucket Tracking SDK
                  </Link>
                </Text>
              </Flex>
            </TabPanel>
            <TabPanel p={0}>
              <Flex flexDirection="column" gap="2">
                <CodeBlock language="javascript">
                  {getSegmentBrowserSDKCodeSample(feature)}
                </CodeBlock>
                <Text>
                  Segment supports various languages and platforms, see{" "}
                  <Link
                    href="https://segment.com/docs/connections/sources/"
                    target="_blank"
                  >
                    Segment Sources
                  </Link>
                </Text>
              </Flex>
            </TabPanel>
            <TabPanel p={0}>
              <Flex flexDirection="column" gap="2">
                <CodeBlock language="http">
                  {getHTTPExampleForFeature(feature, env)}
                </CodeBlock>
                <Text>
                  See detailed instructions on how to use the{" "}
                  <Link
                    href="https://github.com/bucketco/bucket-tracking-sdk/blob/main/HTTP-API.md"
                    target="_blank"
                  >
                    Bucket HTTP API
                  </Link>
                </Text>
              </Flex>
            </TabPanel>
            <TabPanel p={0}>
              <Flex flexDirection="column" gap="2">
                <CodeBlock language="markdown">{featureMarkdown}</CodeBlock>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <Divider />

        <SectionHeading>Share instructions with the team</SectionHeading>

        <ButtonGroup size="sm" variant="outline">
          <CopyMarkdownButton markdown={featureMarkdownWithLink} />
          <SendEmailButton
            feature={feature}
            markdown={featureMarkdownWithLink}
          />
          <TrackInLinearButton
            feature={feature}
            markdown={featureMarkdownWithLink}
          />
        </ButtonGroup>
      </Flex>
    </ElevatedCard>
  );
}

function CopyMarkdownButton({ markdown }: { markdown: string }) {
  const { onCopy } = useClipboard(markdown);
  const toast = useToast();

  const handleCopy = useCallback(() => {
    onCopy();
    toast({
      title: "Markdown copied",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    segmentAnalytics.track("Feature Planning Sharing Interaction", {
      action: "copy markdown",
    });
  }, [onCopy, toast]);

  return (
    <Button
      leftIcon={<RiMarkdownLine opacity="0.7" size="16" />}
      onClick={handleCopy}
    >
      Copy as Markdown
    </Button>
  );
}

function SendEmailButton({
  feature,
  markdown,
}: {
  feature: FeatureDetail;
  markdown: string;
}) {
  const url = new URL("mailto:");

  url.searchParams.set(
    "subject",
    `Set up Bucket tracking for '${feature.name}'-feature`,
  );
  url.searchParams.set("body", ["Hi,", markdown].join("\n\n"));

  return (
    <Button
      as={Link}
      href={url.toString()}
      leftIcon={<RiMailSendLine opacity="0.7" size="16" />}
      target="_blank"
      onClick={() => {
        segmentAnalytics.track("Feature Planning Sharing Interaction", {
          action: "email",
        });
      }}
    >
      Send as Email
    </Button>
  );
}

function TrackInLinearButton({
  feature,
  markdown,
}: {
  feature: FeatureDetail;
  markdown: string;
}) {
  const url = new URL("https://linear.app/new");

  url.searchParams.set(
    "title",
    `Set up Bucket tracking for '${feature.name}'-feature`,
  );
  url.searchParams.set("description", markdown);

  return (
    <Button
      as={Link}
      href={url.toString()}
      leftIcon={<LinearLogo color="#5E6AD2" opacity="1" width="16" />}
      target="_blank"
      onClick={() => {
        segmentAnalytics.track("Feature Planning Sharing Interaction", {
          action: "linear task",
        });
      }}
    >
      Add Linear task
    </Button>
  );
}

function getMarkdownForFeature(feature: FeatureDetail) {
  if (feature.source === "event") {
    const serializeAttributeFilters = (selector: EventSelectorType) => {
      return selector.filter
        .map(
          (rule) => `- \`${rule.field}\`: \`${TypeByOperator[rule.operator]}\``,
        )
        .join("\n");
    };

    if (feature.eventSelectors.length === 1) {
      let result = `Track an event named:\n\`${feature.eventSelectors[0].name}\``;

      const filter = feature.eventSelectors[0].filter ?? [];
      if (filter.length > 0) {
        result += "\n\nThe following attributes should be included:\n";
        result += serializeAttributeFilters(feature.eventSelectors[0]);
      }

      return result;
    }

    let result = `Track one of these events:\n`;

    feature.eventSelectors.forEach((selector) => {
      result += `\n- Name: ${selector.name}`;
      result += `\n  Attributes: \n    ${serializeAttributeFilters(
        selector,
      )}\n`;
    });

    return result;
  }

  const result = `Track companies with the following attributes:

${feature.usingItAttributeFilter
  .map((rule) => `- \`${rule.field}\`: \`${TypeByOperator[rule.operator]}\``)
  .join("\n")}
`;

  return result;
}

function FirstDataNotification({
  feature,
  env,
}: {
  feature: FeatureDetail;
  env: EnvironmentDTO;
}) {
  const { envId, appId } = useCurrentEnv();
  const toast = useToast();
  const queryClient = useQueryClient();
  const firstDataNotificationMutation = useMutation<
    FeatureDetail,
    AxiosError<ErrorResponse>,
    boolean
  >({
    mutationKey: featureQueryKeys.singleEnv(appId, envId, feature.id),
    mutationFn: (data) =>
      api
        .patch<"/apps/:appId/features/:featureId">(
          `/apps/${appId}/features/${feature.id}`,
          { integrations: { slack: { firstDataNotification: data } } },
          { params: { envId: envId! } },
        )
        .then((res) => res.data.feature),
    onSuccess(featureData) {
      toast({
        title: `First data notification ${
          featureData.integrations.slack.firstDataNotification
            ? "enabled"
            : "disabled"
        }`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      queryClient.setQueryData(
        featureQueryKeys.singleEnv(appId, envId, feature.id),
        featureData,
      );
    },
    onError() {
      toast({
        title: `Unable to update first data notification`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    },
  });

  const slackStatus = useSlackStatus(feature);
  const slackConnectionUrl = useSlackConnectionUrl();

  return (
    <Flex as="form" direction={"column"}>
      <FormControl
        alignItems="center"
        display="flex"
        gap="3"
        isDisabled={
          firstDataNotificationMutation.isPending || !slackStatus.canSend
        }
      >
        <FormLabel fontSize="sm" m={0} p={0}>
          {slackStatus.canSend ? (
            <HStack spacing="1">
              <Text>Notify me in</Text>
              <SlackStatus gap={1} slackEntity={feature} />
              <Text>when first data is received</Text>
            </HStack>
          ) : !slackStatus.isConnected ? (
            <Text>
              <Link href={slackConnectionUrl}>Connect to Slack</Link> to notify
              when first data is received
            </Text>
          ) : (
            <Text>
              <Link href={GlobalSettingsUrl(env, "app-slack")}>
                Select a Slack channel
              </Link>{" "}
              to notify when first data is received
            </Text>
          )}
        </FormLabel>
        <Switch
          colorScheme="gray"
          isChecked={feature.integrations.slack.firstDataNotification}
          size="sm"
          onChange={() =>
            firstDataNotificationMutation.mutate(
              !feature.integrations.slack.firstDataNotification,
            )
          }
        />
      </FormControl>
    </Flex>
  );
}
