import React, { SyntheticEvent, useMemo, useState } from "react";
import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from "recharts";

import { FeatureMetricsHistoricalDataPoint } from "@bucketco/shared/featureAPI";

import {
  IncompleteBarPattern,
  incompleteBarPatternId,
} from "@/common/charts/components/IncompleteBar";
import useChartTokens, {
  useChartColorTokens,
} from "@/common/charts/hooks/useChartTokens";
import { useThrottle } from "@/common/hooks/useThrottle";

const BAR_GAP = 4;
const MAX_BAR_SIZE = 24;

export type EvaluationChartDataKeyType = {
  key: string;
  name: string;
  type: "bar" | "line";
  color: keyof useChartColorTokens;
  style?: "solid" | "dashed";
};

export type EvaluationChartProps = {
  data?: FeatureMetricsHistoricalDataPoint[];
  dataKeys: EvaluationChartDataKeyType[];
  isLoading?: boolean;
  barYAxis?: YAxisProps;
  lineYAxis?: YAxisProps;
  xAxis?: XAxisProps;
  tooltip?: TooltipProps<any, any>;
  height?: number;
  highlightLatest?: boolean;
  highlightPartialPeriod?: boolean;
  axisLabelFontSize?: string;
  isAnimationActive?: boolean;
  onResize?: (width: number) => void;
  onBarClick?: (dataPoint: FeatureMetricsHistoricalDataPoint) => void;
};

type RechartsOnclickData<TData> = {
  x: number;
  y: number;
  width: number;
  height: number;
  value: [number, number];
  payload: TData;
  background: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  tooltipPayload: [
    {
      fill: string;
      name: string;
      radius: [number, number, number, number];
      /** This could potentially be inferred from datakeys */
      dataKey: string;
      color: string;
      value: number;
      payload: TData;
    },
  ];
  tooltipPosition: {
    x: number;
    y: number;
  };
};

export default function EvaluationChart({
  data = [],
  dataKeys = [],
  isLoading = false,
  lineYAxis = {},
  barYAxis = {},
  xAxis = {},
  tooltip = {},
  height = 300,
  highlightPartialPeriod = false,
  highlightLatest = false,
  axisLabelFontSize,
  isAnimationActive = true,
  onResize,
  onBarClick,
}: EvaluationChartProps) {
  const { colors, fontSizes, radii, sizes, properties } = useChartTokens();
  const axisLabelFontSizeValue = axisLabelFontSize || fontSizes.axisLabel;
  const lastDataPeriod = useMemo<[number, number]>(
    () =>
      data
        .filter((point) => "total" in point)
        .map(({ epoch }) => epoch)
        .reverse()
        .slice(0, 2) as [number, number],
    [data],
  );
  const [width, setWidth] = useState<number>(720);
  useThrottle(width, 500, onResize);

  return (
    <ResponsiveContainer
      height={height}
      width="100%"
      onResize={(newWidth) => {
        setWidth(newWidth);
      }}
    >
      <ComposedChart
        barCategoryGap={BAR_GAP}
        barGap={BAR_GAP}
        data={data}
        margin={{
          top: 24,
          right: 0,
          bottom: 0,
          left: 0,
        }}
        maxBarSize={MAX_BAR_SIZE}
        stackOffset="sign"
        syncId="a"
      >
        <CartesianGrid
          stroke={colors.grid}
          strokeDasharray="3"
          vertical={false}
        />
        <XAxis
          axisLine={true}
          dataKey="epoch"
          dy={8}
          fontSize={axisLabelFontSizeValue}
          height={50}
          interval="equidistantPreserveStart"
          minTickGap={4}
          scale="utc"
          stroke={colors.axis}
          tickLine={true}
          type="number"
          {...xAxis}
        ></XAxis>
        <YAxis
          axisLine={false}
          fontSize={axisLabelFontSizeValue}
          orientation="right"
          stroke={colors.axis}
          tick={{
            dx: 4,
          }}
          tickLine={false}
          tickMargin={4}
          type="number"
          width={sizes.yAxisWidth}
          yAxisId="bar"
          {...barYAxis}
        ></YAxis>
        <YAxis
          axisLine={false}
          fontSize={axisLabelFontSizeValue}
          stroke={colors.axis}
          tick={{
            dx: 0,
          }}
          tickLine={false}
          tickMargin={8}
          type="number"
          width={sizes.yAxisWidth}
          yAxisId="line"
          {...lineYAxis}
        ></YAxis>
        {highlightPartialPeriod && data?.length && (
          <>
            <defs>
              <IncompleteBarPattern
                colorName={"partialHighlight"}
                colorValue={colors.partialHighlight}
              />
            </defs>
            <ReferenceArea
              fill={`url(#${incompleteBarPatternId("partialHighlight")})`}
              x1={lastDataPeriod[0]}
              x2={lastDataPeriod[1]}
              yAxisId="line"
            />
          </>
        )}
        {highlightLatest && data?.length && (
          <ReferenceArea
            fill={colors.highlightBg}
            fillOpacity={0.4}
            radius={10}
            x1={lastDataPeriod[0]}
            x2={lastDataPeriod[1]}
            yAxisId="line"
          />
        )}
        {!isLoading &&
          dataKeys
            .filter(({ type }) => type === "bar")
            .map(({ key, name, color }) => (
              <Bar
                key={key}
                animationDuration={300}
                dataKey={key}
                fill={colors[color as keyof typeof colors]}
                isAnimationActive={isAnimationActive}
                name={name}
                radius={[7, 7, 0, 0]}
                stackId="a"
                yAxisId="bar"
                onClick={(
                  dataPoint: RechartsOnclickData<FeatureMetricsHistoricalDataPoint>,
                  _idx: number,
                  _e: SyntheticEvent,
                ) => {
                  onBarClick?.(dataPoint.payload);
                }}
              />
            ))}
        <Tooltip
          contentStyle={{
            borderColor: colors.tooltipBorder,
            backgroundColor: colors.tooltipBg,
            borderRadius: radii.tooltip,
            fontSize: fontSizes.tooltip,
          }}
          cursor={{ stroke: colors.cursor }}
          isAnimationActive={false}
          itemSorter={(item) =>
            dataKeys.findIndex((key) => key.key === item.dataKey)
          }
          {...tooltip}
        />
        {!isLoading &&
          dataKeys
            .filter(({ type }) => type === "line")
            .map(({ key, name, color, style }) => (
              <Line
                key={key}
                activeDot={properties.activeDot}
                dataKey={key}
                dot={false}
                isAnimationActive={false}
                name={name}
                stopColor={colors.primary}
                stroke={colors[color as keyof typeof colors]}
                strokeDasharray={style === "dashed" ? "3, 7" : undefined}
                strokeLinecap="round"
                strokeWidth={sizes.lineStrokeWidth}
                type="monotone"
                yAxisId="line"
              />
            ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export function EvaluationChartLegend({
  fontSize = "sm",
  triedDisabled = false,
  retentionRateDisabled = false,
}: {
  fontSize?: string;
  triedDisabled?: boolean;
  retentionRateDisabled?: boolean;
}) {
  const { colors } = useChartTokens();
  return (
    <HStack borderRadius="md" py={1} spacing={4}>
      {!triedDisabled && (
        <HStack spacing={2}>
          <Box
            borderColor={colors.tried}
            borderLeftWidth={4}
            borderRadius="full"
            h={4}
            ml={1}
            transform="rotate(45deg)"
            w={0}
          />
          <Text color="gray.500" fontSize={fontSize}>
            Tried
          </Text>
        </HStack>
      )}
      <HStack spacing={2}>
        <Box
          borderColor={colors.retained}
          borderLeftWidth={4}
          borderRadius="full"
          h={4}
          ml={1}
          transform="rotate(45deg)"
          w={0}
        />
        <Text color="gray.500" fontSize={fontSize}>
          Retained
        </Text>
      </HStack>
      {!retentionRateDisabled && (
        <HStack spacing={2}>
          <Box
            borderColor={colors.retained}
            borderLeftWidth={4}
            borderStyle="dotted"
            h={4}
            ml={1}
            transform="rotate(45deg)"
            w={0}
          />
          <Text color="gray.500" fontSize={fontSize}>
            Retention rate
          </Text>
        </HStack>
      )}
      {!retentionRateDisabled && (
        <HStack spacing={1}>
          <Flex direction="column">
            <Box
              bg={colors.problem}
              borderRadius="3px 3px 0 0"
              h="10px"
              w="8px"
            ></Box>
          </Flex>
          <Text color="gray.500" fontSize={fontSize}>
            Retained churn
          </Text>
        </HStack>
      )}
    </HStack>
  );
}
