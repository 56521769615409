import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import {
  RadioGroupButtons,
  RadioGroupButtonsProps,
} from "@/common/components/RadioGroupButton";
import {
  FormElementBaseProps,
  useManagedFormControlProps,
} from "@/common/hooks/useManagedFormControlProps";
import { mergeFunctions } from "@/common/utils/mergeFunctions";

export type FormRadioGroupButtonsProps = FormElementBaseProps &
  Omit<RadioGroupButtonsProps, "value">;

const FormRadioGroupButtons = ({
  _control,
  onChange,
  ...rest
}: FormRadioGroupButtonsProps) => {
  const [managedProps, RadioGroupButtonsProps] =
    useManagedFormControlProps(rest);
  return (
    <ManagedFormControl
      {..._control}
      {...managedProps}
      render={({ field }) => (
        <RadioGroupButtons
          {...RadioGroupButtonsProps}
          name={field.name}
          value={field.value}
          onChange={mergeFunctions(field.onChange, onChange)}
        />
      )}
    />
  );
};

export default FormRadioGroupButtons;
