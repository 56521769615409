import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useSessionStorage } from "usehooks-ts";

import {
  ReleasePostArgs,
  ReleasePostSchema,
} from "@bucketco/shared/releaseAPI";
import { ReleaseUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import useApiForm from "@/common/hooks/useApiForm";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { releasesQueryKeys } from "@/release/data/releasesQueryKeys";
import { useReleaseEditing } from "@/release/hooks/useReleaseEditing";
import { ReleaseDraft } from "@/release/types/types";

import { LegacyReleasePage } from "./LegacyReleasePage";
import { baseValues, releaseToPostArgs } from "./releaseFormTools";

export const LegacyCreateReleasePage = () => {
  const { appId } = useCurrentEnv();
  const { currentEnv } = useAuthContext();

  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [_, setEditing] = useReleaseEditing();
  const [releaseDraft, setReleaseDraft] = useSessionStorage<
    ReleaseDraft | undefined
  >("release:new", undefined);

  const { form, handleSubmit } = useApiForm(
    (data: ReleasePostArgs) =>
      api
        .post<"/apps/:appId/releases">(`/apps/${appId}/releases`, data)
        .then((res) => res.data.release),
    ReleasePostSchema,
    {
      onSuccess(release) {
        setEditing(false);
        setReleaseDraft(undefined);
        form.reset(releaseToPostArgs(release));
        queryClient.invalidateQueries({
          queryKey: releasesQueryKeys.list(appId),
        });
        navigate(ReleaseUrl(currentEnv!, release.id), { replace: true });
      },
      onError() {
        toast({
          title: "Failed to create a new release",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      },
    },
    {
      defaultValues: releaseDraft ?? baseValues,
    },
  );

  useEffect(() => {
    const subscription = form.watch((value, { type }) => {
      // Won't catch appending or removing goals
      if (type === "change") setReleaseDraft(value);
    });
    return () => subscription.unsubscribe();
  }, [form, setReleaseDraft]);

  return (
    <FormProvider {...form}>
      <LegacyReleasePage onSaved={handleSubmit} />
    </FormProvider>
  );
};
