import { useEffect } from "react";
import {
  keepPreviousData,
  QueryFunction,
  useQuery,
} from "@tanstack/react-query";

import {
  GoalDryRunPostArgs,
  GoalDryRunPostSchema,
  GoalDryRunResult,
} from "@bucketco/shared/goalAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { goalQueryKeys } from "@/release/data/goalQueryKeys";

export function useGoalsData(releaseId?: string) {
  const { appId } = useCurrentEnv();
  return useQuery({
    queryKey: goalQueryKeys.list(appId, releaseId),

    queryFn: () =>
      api
        .get<"/apps/:appId/releases/:releaseId/goals">(
          `/apps/${appId}/releases/${releaseId}/goals`,
        )
        .then((res) => res.data.goals),
    enabled: !!appId && !!releaseId,
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export const fetchGoalPreviewData =
  (
    appId: string | undefined,
    envId: string | undefined,
    args: GoalDryRunPostArgs,
  ): QueryFunction<GoalDryRunResult> =>
  async ({ signal }) => {
    return api
      .post<"/apps/:appId/releases/dry-run">(
        `/apps/${appId}/releases/dry-run`,
        args,
        { signal, params: { envId: envId! } },
      )
      .then((res) => {
        return res.data.result;
      });
  };

export function useGoalPreviewData(
  args: GoalDryRunPostArgs,
  enabled = true,
  /** @deprecated */
  onChange?: (data: GoalDryRunResult) => void,
) {
  const { appId, envId } = useCurrentEnv();

  const result = useQuery<GoalDryRunResult>({
    queryKey: goalQueryKeys.dryRun(appId, args),

    queryFn: fetchGoalPreviewData(appId, envId, args),

    enabled:
      !!appId &&
      !!envId &&
      enabled &&
      GoalDryRunPostSchema.safeParse(args).success,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    staleTime: 1000 * 10, // 10 seconds before an identical request can be made
    retry: false,
  });

  useEffect(() => {
    if (result.data !== undefined && !result.isPlaceholderData) {
      result.data;
      onChange?.(result.data);
    }
  }, [onChange, result.data, result.isPlaceholderData]);

  return result;
}
