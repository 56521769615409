import { ReactNode } from "react";
import { Text, TextProps } from "@chakra-ui/react";

type Props = TextProps & {
  children: ReactNode;
};

export function TextBadge({ children, ...textProps }: Props) {
  return (
    <Text
      background="gray.500"
      color="white"
      fontSize="xs"
      fontWeight="bold"
      px={1.5}
      rounded="md"
      {...textProps}
    >
      {children}
    </Text>
  );
}
