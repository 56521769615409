import { useFieldArray, useFormContext } from "react-hook-form";
import { Box, Button, useColorModeValue, VStack } from "@chakra-ui/react";

import { EventSelectorType } from "@bucketco/shared/featureAPI";

import { AndOrList } from "@/common/components/AndOrList";
import { DeleteIconButton } from "@/common/components/CommonIconButtons";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import { FormEventAttributeFilterPickerInput } from "@/feature-legacy/components/FormEventAttributeFilterPicker";
import { FormEventAutoCompleteInputOnly } from "@/feature-legacy/components/FormEventAutoCompleteInput";

export function FormEventBasedFilterInputs({ w }: { w?: string }) {
  const boxBgColor = useColorModeValue("gray.25", "gray.800");
  const boxBorderColor = useColorModeValue("gray.100", "gray.750");

  const form = useFormContext();
  const formSelectors = useFieldArray({
    control: form.control,
    name: "customEventSelectors",
  });

  const { isEnabled: featureKeyEventSelector } = useFeature(
    "feature-revamp-2024",
  );

  return (
    <VStack align="flex-start" w={w}>
      <AndOrList
        conjunction="or"
        conjunctionProps={{ ml: 4, my: -1, zIndex: 1 }}
        direction="vertical"
        gap="0"
      >
        {formSelectors.fields.map((field, index) => (
          <Box
            key={field.id}
            background={boxBgColor}
            border="1px"
            borderColor={boxBorderColor}
            flexGrow={1}
            p={4}
            position="relative"
            pr={2}
            rounded="lg"
            w="full"
          >
            <FormEventSelectorInput
              key={field.id}
              nameBase={`customEventSelectors.${index}`}
            />

            <DeleteIconButton
              isDisabled={
                !featureKeyEventSelector && formSelectors.fields.length < 2
              }
              label={formSelectors.fields.length < 2 ? "" : "Remove"}
              position="absolute"
              right={2.5}
              top={2.5}
              onClick={() => formSelectors.remove(index)}
            />
          </Box>
        ))}
      </AndOrList>

      <Button
        isDisabled={form.formState.isSubmitting}
        size="sm"
        variant="input"
        onClick={() => formSelectors.append({ name: "", filter: [] })}
      >
        Add event
      </Button>
    </VStack>
  );
}

function FormEventSelectorInput({ nameBase }: { nameBase: string }) {
  const form = useFormContext();
  const field: EventSelectorType = form.watch(nameBase);

  return (
    <VStack align="flex-start" spacing={2}>
      <Box pr="40px" w="full">
        <FormEventAutoCompleteInputOnly
          name={`${nameBase}.name`}
          placeholder="Your event name"
        />
      </Box>

      <FormEventAttributeFilterPickerInput
        buttonText="Add attribute filter"
        eventName={field.name}
        name={`${nameBase}.filter`}
        size="sm"
      />
    </VStack>
  );
}
