import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Flex, HStack, Text } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  WidgetSTARSFunnelConfiguration,
  WidgetSTARSFunnelConfigurationSchema,
} from "@bucketco/shared/widgetAPI";

import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import { StarsFunnel } from "@/feature/components/StarsFunnel";
import { BaseWidgetProps } from "@/widget/components/types";
import { WidgetLayout } from "@/widget/components/WidgetLayout";
import { WidgetForm } from "@/widget/components/WidgetStarsFunnel/WidgetForm";
import { useWidgetUpdateMutation } from "@/widget/data/useWidgetMutations";

type Props = BaseWidgetProps<WidgetSTARSFunnelConfiguration>;

export const WidgetStarsFunnel = ({
  widgetId,
  configuration,
  implicitConfiguration,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  const updateMutation = useWidgetUpdateMutation(widgetId);

  const form = useForm<WidgetSTARSFunnelConfiguration>({
    resolver: zodResolver(WidgetSTARSFunnelConfigurationSchema),
    mode: "onChange",
    defaultValues: {
      ...implicitConfiguration,
      ...configuration,
    },
  });

  const values = form.watch();

  const currentConfiguration = useMemo(
    () => (isEditing ? values : configuration),
    [configuration, isEditing, values],
  );

  return (
    <WidgetLayout
      canEdit={!implicitConfiguration?.featureId}
      content={
        <Flex direction="column" gap={2} w="full">
          <StarsFunnel
            featureId={currentConfiguration.featureId}
            title="STARS Funnel"
          />
          <HStack ml="auto" spacing={1}>
            <Text color="dimmed" fontSize="sm">
              Showing all time
            </Text>
            <InfoIconTooltip text="This widget is not filtered by the time period." />
          </HStack>
        </Flex>
      }
      form={
        <WidgetForm
          configuration={currentConfiguration}
          form={form}
          implicitConfiguration={implicitConfiguration}
          widgetMutation={updateMutation}
          onDone={(configuration) => {
            form.reset(configuration);
            setIsEditing(false);
          }}
        />
      }
      isEditing={isEditing}
      setIsEditing={(newIsEditing) => {
        if (!newIsEditing) form.reset(configuration);
        setIsEditing(newIsEditing);
      }}
      widgetId={widgetId}
    />
  );
};
