import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Button, ButtonGroup, Tooltip } from "@chakra-ui/react";
import { useSessionStorage } from "usehooks-ts";

import { ReleaseUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import HeaderLayout from "@/common/components/HeaderLayout";
import { linkClickModifierHandler } from "@/common/utils/linkClickHandler";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { ReleasesTable } from "@/release/components/ReleasesTable";
import { ReleaseDraft } from "@/release/types/types";

export default function Page() {
  const { currentEnv } = useAuthContext();

  const navigate = useNavigate();
  const [releaseDraft] = useSessionStorage<ReleaseDraft | undefined>(
    "release:new",
    undefined,
  );

  useEffect(() => {
    segmentAnalytics.page("Releases");
  }, []);

  return (
    <HeaderLayout
      actions={
        <ButtonGroup>
          <Tooltip
            label={releaseDraft ? `Release "${releaseDraft.name}"` : undefined}
            hasArrow
          >
            <Button
              colorScheme={releaseDraft ? "brand" : undefined}
              size="sm"
              variant={!releaseDraft ? "primary" : undefined}
              onClick={(e) => {
                const url = ReleaseUrl(currentEnv!, "new", true);

                linkClickModifierHandler(url, () => navigate(url))(e);
              }}
            >
              {releaseDraft ? "Resume creating" : "New release"}
            </Button>
          </Tooltip>
        </ButtonGroup>
      }
      title="Releases"
    >
      <Helmet>
        <title>Releases › All</title>
      </Helmet>
      <ReleasesTable />
    </HeaderLayout>
  );
}
