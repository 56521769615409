import pick from "lodash/pick";

import {
  ReleaseDetailsDTO,
  ReleasePostArgs,
} from "@bucketco/shared/releaseAPI";

export const baseValues: ReleasePostArgs = {
  name: "",
  releasedAt: null,
  evaluationPeriod: 14,
  slackNotificationsEnabled: false,
  slackChannelId: null,
  goals: [],
};

export function releaseToPostArgs(
  release?: ReleaseDetailsDTO | null,
): ReleasePostArgs | undefined {
  if (!release) return undefined;
  return {
    ...pick(release, [
      "name",
      "releasedAt",
      "evaluationPeriod",
      "slackNotificationsEnabled",
      "slackChannelId",
    ]),
    goals: release.goals.map((goal) => ({
      configuration: pick(goal.configuration, [
        "featureId",
        "metric",
        "subsegmentId",
        "threshold",
      ]),
    })),
  };
}
