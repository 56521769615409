import {
  AuditChartAxisMetricKey,
  AuditChartDataItem,
  AXIS_METRICS,
} from "@/common/charts/components/AuditChart/types";

export function useAuditChartMetricText(
  item: AuditChartDataItem | null,
  key: AuditChartAxisMetricKey,
) {
  if (item === null) return null;

  const metric = AXIS_METRICS[key];
  return {
    label: metric.label,
    formattedValue: metric.getFormattedValue(item),
  };
}
