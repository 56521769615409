import { FocusEventHandler, useCallback, useRef, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  useColorModeValue,
} from "@chakra-ui/react";

export default function SearchInput({
  value,
  onFocus,
  onBlur,
  ...inputProps
}: InputProps) {
  const searchIconColor = useColorModeValue("gray.400", "gray.600");
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    (...args) => {
      setIsFocused(true);
      onFocus?.(...args);
    },
    [onFocus],
  );

  const handleBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    (...args) => {
      setIsFocused(false);
      onBlur?.(...args);
    },
    [onBlur],
  );

  return (
    <InputGroup>
      <InputLeftElement
        color={!value ? searchIconColor : undefined}
        cursor="text"
        onClick={() => inputRef.current?.focus()}
      >
        <RiSearchLine size={16} />
      </InputLeftElement>
      <Input
        ref={inputRef}
        _focus={{
          width: 44,
        }}
        placeholder={isFocused ? "Search by name/ID" : "Search"}
        size="sm"
        transition="width 120ms ease-in-out"
        type="search"
        value={value}
        width={value === "" ? 24 : 44}
        onBlur={handleBlur}
        onFocus={handleFocus}
        {...inputProps}
      />
    </InputGroup>
  );
}
