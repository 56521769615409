import { ColumnSort } from "@tanstack/react-table";
import { useLocalStorage } from "usehooks-ts";

import { ColumnState } from "@bucketco/shared/types/columns";

export function useLocalStorageTableConfiguration(
  key: string,
  {
    defaultColumns,
    defaultSort,
  }: {
    defaultColumns: ColumnState[];
    defaultSort: ColumnSort;
  },
) {
  const [sort, setSort] = useLocalStorage(`ColumnSort_${key}`, defaultSort);
  const [columns, setColumns] = useLocalStorage(
    `Columns_${key}`,
    defaultColumns,
  );

  return {
    columns,
    setColumns,
    sort,
    setSort,
  };
}
