import { useMemo } from "react";
import { RiFlag2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Box, Center, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import omit from "lodash/omit";

import {
  Flag,
  FlagListQuery,
  FlagListQuerySortBy,
} from "@bucketco/shared/flagAPI";
import { FlagUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import { DataTable } from "@/common/components/DataTable";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import TimestampCell from "@/common/components/TimestampCell";
import useDataTable from "@/common/hooks/useDataTable";
import { useLocalStorageTableConfiguration } from "@/common/hooks/useLocalStorageTableConfiguration";
import { createSortableColumnHelper } from "@/common/types/reactTableHelpers";
import api from "@/common/utils/api";
import pluralize from "@/common/utils/pluralize";
import FlagsEmptyState from "@/flags/components/FlagsEmptyState";
import { ReleaseDisplay } from "@/release/components/ReleaseDisplay";
import { releasesQueryKeys } from "@/release/data/releasesQueryKeys";

const sortBy = {
  id: "key",
  desc: false,
} as const;

export const FlagsTable = () => {
  const navigate = useNavigate();

  const { currentApp, currentEnv } = useAuthContext();

  const tableConfiguration = useLocalStorageTableConfiguration("Flags", {
    defaultColumns: [],
    defaultSort: sortBy,
  });

  const table = useDataTable<Flag, Required<Pick<FlagListQuery, "sortBy">>>({
    apiCacheKey: releasesQueryKeys.list(currentApp?.id),
    apiHandler: (params) => async () => {
      const res = await api.get<`/apps/:appId/flags`>(
        `/apps/${currentApp?.id}/flags`,
        {
          params,
        },
      );
      return res.data;
    },
    apiOptions: {
      enabled: !!currentApp,
    },
    defaultQueryParams: {
      sortBy: tableConfiguration.sort.id as FlagListQuerySortBy,
      sortOrder: tableConfiguration.sort.desc ? "desc" : "asc",
    },
  });

  const columnHelper = createSortableColumnHelper<Flag, FlagListQuerySortBy>();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("key", {
        header: "Key",
        cell: (cell) => {
          const key = cell.getValue();
          return (
            <HStack spacing={2}>
              <Box color="dimmed">
                <RiFlag2Line size={15} />
              </Box>
              <Text>{key}</Text>
            </HStack>
          );
        },
      }),
      columnHelper.accessor("currentVersions", {
        header: "Rollout rules",
        enableSorting: false,
        cell: (cell) => {
          const rules =
            cell.getValue().find((cv) => cv.environment.id === currentEnv?.id)
              ?.rules ?? [];

          if (!rules.length) {
            return <NotAvailableCell />;
          }

          const count = rules.length;
          return (
            <Text>
              {count} {pluralize("rule", count)}
            </Text>
          );
        },
      }),
      columnHelper.accessor("releases", {
        header: "Releases",
        enableSorting: false,
        cell: (cell) => {
          const releases = cell.getValue();

          if (!releases.length) {
            return <NotAvailableCell />;
          }

          return (
            <VStack alignItems="start">
              {releases.map((release) => (
                <ReleaseDisplay
                  key={release.id}
                  release={release}
                  onClick={(e) => e.stopPropagation()}
                />
              ))}
            </VStack>
          );
        },
      }),
      columnHelper.accessor("createdBy", {
        header: "Created by",
        cell: (cell) => {
          const createdBy = cell.getValue();
          if (!createdBy) return <NotAvailableCell />;
          return <Text>{createdBy.name}</Text>;
        },
      }),
      columnHelper.accessor("createdAt", {
        header: "Created at",
        sortDescFirst: true,
        cell: (cell) => {
          const createdAt = cell.getValue();
          if (!createdAt) return <NotAvailableCell />;
          return <TimestampCell value={createdAt} />;
        },
      }),
    ];
  }, [columnHelper, currentEnv?.id]);

  const flags = table.data?.data ?? [];

  if (table.isLoading) {
    return (
      <Center flexGrow={1}>
        <Spinner size="sm" />
      </Center>
    );
  }

  if (flags.length === 0) {
    return <FlagsEmptyState isLoading={table.isLoading} />;
  }

  return (
    <DataTable
      {...omit(table, [
        "data",
        "isLoading",
        "isPreviousData",
        "canPaginate",
        "paginateActions",
      ])}
      columns={columns}
      columnStates={tableConfiguration.columns}
      data={flags}
      rowOnClick={(row, e) => {
        e.preventDefault();
        navigate(FlagUrl(currentEnv!, row.original.id));
      }}
      setColumnStates={tableConfiguration.setColumns}
      setSortBy={tableConfiguration.setSort}
      sortBy={tableConfiguration.sort}
      sx={{
        td: {
          verticalAlign: "middle",
        },
      }}
      trackingId="flags"
      variant="clickable"
    />
  );
};
