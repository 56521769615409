import { FormProvider } from "react-hook-form";
import {
  ButtonGroup,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

import {
  CreateFlagArgsSchema,
  CreateFlagArgsType,
  Flag,
} from "@bucketco/shared/flagAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import FormInput from "@/common/components/form/FormInput";
import { FormRootError } from "@/common/components/form/FormRootError";
import FormSubmit from "@/common/components/form/FormSubmit";
import ModalCancelButton from "@/common/components/ModalCancelButton";
import useApiForm from "@/common/hooks/useApiForm";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { CreateAffectEnvironments } from "@/environment/components/EnvironmentCallouts";
import flagsQueryKeys from "@/flags/data/flagsQueryKeys";

import FlagSlackSettings from "./FlagSlackSettings";

type NewFlagModalProps = {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (flag: Flag) => void;
};

export default function NewFlagModal({
  title = "New feature flag",
  isOpen,
  onClose,
  onSuccess,
}: NewFlagModalProps) {
  const queryClient = useQueryClient();
  const { currentApp } = useAuthContext();
  const { form, handleSubmit } = useApiForm(
    async (formData: CreateFlagArgsType) => {
      return api
        .post<"/apps/:appId/flags">(`/apps/${currentApp?.id}/flags`, formData)
        .then((response) => response.data.flag);
    },
    CreateFlagArgsSchema,
    {
      onSuccess: (flag) => {
        queryClient.setQueryData(
          flagsQueryKeys.single(currentApp?.id, flag.id),
          flag,
        );
        queryClient.invalidateQueries({
          queryKey: flagsQueryKeys.list(currentApp?.id),
          exact: true,
        });

        segmentAnalytics.track("Feature Flag Created");

        onSuccess(flag);
      },
    },
    {
      mode: "onChange",
      shouldUnregister: false,
      defaultValues: {
        key: "",
        description: "",
        slackNotificationsEnabled: true,
      },
    },
  );

  return (
    <Modal isOpen={isOpen} size="md" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <VStack align="start">
            <Heading>{title}</Heading>
          </VStack>
        </ModalHeader>
        <ModalCloseButton />

        <FormProvider {...form}>
          <ModalBody>
            <form id="new-flag" onSubmit={handleSubmit}>
              <Flex direction="column" gap={4} w="full">
                <FormInput
                  description="You'll use this unique key in the code to check if the flag is enabled. It cannot be changed after creation."
                  label="Flag key"
                  name="key"
                  placeholder="Ex. chat-messages"
                />

                <FormInput
                  label="Description"
                  name="description"
                  placeholder="Ex. Send a chat message"
                />

                <FlagSlackSettings />

                <FormRootError />

                <CreateAffectEnvironments entity="flags" mt={4} />
              </Flex>
            </form>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <ModalCancelButton />
              <FormSubmit form="new-flag">New feature flag</FormSubmit>
            </ButtonGroup>
          </ModalFooter>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
