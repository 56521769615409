import { useCallback, useState } from "react";

export default function useDimensions({
  fallback = { width: 256, height: 32 },
}: {
  fallback?: { width: number; height: number };
} = {}) {
  const [dimensions, setDimensions] = useState<DOMRect | undefined>();
  const measureRef = useCallback((domNode: HTMLDivElement) => {
    if (domNode) {
      setDimensions(domNode.getBoundingClientRect());
    }
  }, []);

  return {
    measureRef: measureRef,
    width: dimensions?.width ?? fallback.width,
    height: dimensions?.height ?? fallback.height,
  };
}
