import { FeatureDetail } from "@bucketco/shared/featureAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import { SlackEnabledEntity } from "../components/SlackStatus";

export default function useSlackStatus(
  slackEntity?: FeatureDetail | SlackEnabledEntity,
): {
  isConnected: boolean;
  canSend: boolean;
  channelId?: string;
  channelName?: string;
  channelBase: "app" | "argument" | "none";
} {
  const { currentOrg, currentEnv } = useAuthContext();

  if (!currentOrg || !currentEnv) {
    return {
      isConnected: false,
      canSend: false,
      channelBase: "none",
    };
  }

  const result: ReturnType<typeof useSlackStatus> = {
    isConnected: currentOrg.hasSlackConnection,
    canSend: false,
    channelBase: "none",
  };

  if (slackEntity) {
    // Feature
    if ("integrations" in slackEntity) {
      if (slackEntity.integrations.slack.channelId) {
        result.channelBase = "argument";
        result.channelId = slackEntity.integrations.slack.channelId;
      }
    } else {
      if (slackEntity.slackChannelId) {
        result.channelBase = "argument";
        result.channelId = slackEntity.slackChannelId;
        if (slackEntity.slackChannelName) {
          result.channelName = slackEntity.slackChannelName;
        }
      }
    }
  }

  // Fall back to app
  if (currentEnv.slackChannelId) {
    result.channelBase = "app";
    result.channelId = currentEnv.slackChannelId;
    if (currentEnv.slackChannelName) {
      result.channelName = currentEnv.slackChannelName;
    }
  }

  if (result.isConnected && result.channelId) {
    result.canSend = true;
  }

  return result;
}
