import React, { useEffect, useMemo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { RiChat1Line } from "react-icons/ri";
import {
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from "@chakra-ui/react";

import { FeatureDetail } from "@bucketco/shared/featureAPI";
import { GoalConfiguration } from "@bucketco/shared/goalAPI";

import FormSubmit from "@/common/components/form/FormSubmit";
import StarsChartIcon from "@/common/components/StarsChartIcon";
import { formatPercentage } from "@/common/utils/numberFormatting";
import pluralize from "@/common/utils/pluralize";
import FeatureDisplay from "@/feature-legacy/components/FeatureDisplay";
import { SatisfactionRateIcon } from "@/feature-legacy/components/SatisfactionRateIcon";
import {
  GoalTemplate,
  GoalTemplateFields,
} from "@/release/components/GoalTemplateFields";

type FormData = {
  templateKeys: string[];
};

type Props = Omit<ModalProps, "children"> & {
  feature: FeatureDetail;
  onSuccess?: (goals: GoalConfiguration[]) => void;
};

export const LegacyGoalTemplateModal = ({
  feature,
  isOpen,
  onClose,
  onSuccess,
  ...rest
}: Props) => {
  const form = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      templateKeys:
        feature.source === "event" ? ["tried", "feedback"] : ["feedback"],
    },
  });

  useEffect(() => {
    // Trigger validation when form is initialized
    form.trigger();
  }, [form]);

  const handleSubmit: SubmitHandler<FormData> = ({ templateKeys }) => {
    const selectedTemplates = templateKeys.map((key) => goalTemplates[key]);
    onSuccess?.(
      selectedTemplates.map(({ configuration }) => ({
        ...configuration,
        featureId: feature.id,
      })),
    );
  };

  const goalTemplates = useMemo(() => {
    const triedThreshold = 10; // todo: suggestedThreshold("triedCount", currentValue);
    const feedbackThreshold = 5; // todo: suggestedThreshold("feedbackCount", currentValue);
    const adoptedThreshold = 0.25; // todo: suggestedThreshold("adopted", currentValue);

    const templates: Record<string, GoalTemplate> = {};
    if (feature.source === "event") {
      templates.tried = {
        icon: <StarsChartIcon boxSize={5} step="tried" />,
        label: `${triedThreshold} companies Tried feature`,
        configuration: {
          type: "featureMetric",
          version: "1",
          metric: "triedCount",
          threshold: triedThreshold,
        },
      };
    }
    templates.feedback = {
      icon: <RiChat1Line size={20} />,
      label: `Feedback from ${feedbackThreshold} companies`,
      configuration: {
        type: "featureMetric",
        version: "1",
        metric: "feedbackCount",
        threshold: feedbackThreshold,
      },
    };
    templates.adopted = {
      icon: <StarsChartIcon boxSize={5} step="retained" />,
      label: `${formatPercentage(adoptedThreshold)} Adopted feature`,
      configuration: {
        type: "featureMetric",
        version: "1",
        metric: "adopted",
        threshold: adoptedThreshold,
      },
    };
    templates.satisfaction = {
      icon: <SatisfactionRateIcon satisfactionRate={5} />,
      label: "High Satisfaction rate",
      configuration: {
        type: "featureMetric",
        version: "1",
        metric: "satisfactionRate",
        threshold: 0.8,
      },
    };
    return templates;
  }, [feature.source]);

  const count = form.watch("templateKeys").length;
  return (
    <Modal isOpen={isOpen} size="2xl" onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...form}>
          <ModalHeader display="flex" gap={1}>
            <Text>Add goals for</Text>
            <FeatureDisplay
              display="inline-flex"
              feature={feature}
              fontSize="xl"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text color="dimmed" mb={6}>
              Here are a few quick goals to get you started.
            </Text>
            <form
              id="goal-template-form"
              onSubmit={form.handleSubmit(handleSubmit)}
            >
              <GoalTemplateFields templates={goalTemplates} />
            </form>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Text color="dimmed" fontStyle="italic">
              Step 2 of 2
            </Text>
            <ButtonGroup>
              <FormSubmit form="goal-template-form" allowPristine>
                {!count
                  ? "Skip goals"
                  : `Add ${count} ${pluralize("goal", count)}`}
              </FormSubmit>
            </ButtonGroup>
          </ModalFooter>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};
