import { Flag } from "@bucketco/shared/flagAPI";

import { CodeExample, CodeExampleProps } from "@/common/components/CodeExample";

type SDK = "react" | "node" | "browser";

type Props = Omit<CodeExampleProps, "examples"> & {
  flag: Flag;
};

export function FlagImplementationInstructions({ flag, ...rest }: Props) {
  return (
    <CodeExample
      examples={[
        {
          key: "react",
          label: "React",
          highlighterLanguage: "jsx",
          code: getFlagInstructions(flag, "react"),
        },
        {
          key: "browser",
          label: "Browser",
          highlighterLanguage: "javascript",
          code: getFlagInstructions(flag, "browser"),
        },
        {
          key: "node",
          label: "Node.js",
          highlighterLanguage: "jsx",
          code: getFlagInstructions(flag, "node"),
        },
      ]}
      {...rest}
    />
  );
}

function getFlagInstructions(flag: Flag, output: SDK) {
  switch (output) {
    case "react":
      return `
import { useFeature } from "@bucketco/react-sdk";

// Component wrapped in the BucketProvider
function StartHuddleButton() {
  const {isEnabled, track} = useFeature("${flag.key}");

  if (!isEnabled) {
    return null;
  }

  return <button onClick={() => track()}>Start huddle!</button>;
}`.trim();
    case "browser":
      return `
<script>
// Using an initialized bucketClient
const features = bucketClient.getFeatures();

// Check if feature is enabled
if (features["${flag.key}"]?.isEnabled) {
  // Track usage of feature
  features["${flag.key}"].track();
}
</script>`.trim();
    case "node":
      return `
// Using an initialized bucketClient
const features = bucketClient.getFeatures();

// Check if feature is enabled
if (features["${flag.key}"]?.isEnabled) {
  // Track usage of feature
  features["${flag.key}"].track();
}`.trim();
  }
}
