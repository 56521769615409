import { ReactNode, useCallback, useState } from "react";
import { RiFileCopyLine, RiMailSendLine } from "react-icons/ri";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardProps,
  Flex,
  IconButton,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useClipboard,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import LinearLogo from "@/common/assets/linear-logo.svg?react";
import CodeBlock, {
  SupportedHighlightLanguage,
} from "@/common/components/CodeBlock";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

type CodeExample = {
  key: string;
  label: string;
  highlighterLanguage?: SupportedHighlightLanguage;
  code: string;
  installInstructions?: ReactNode;
};

export type CodeExampleProps = {
  examples: CodeExample[];
  /** Use to automatically track events when switching tabs and sharing the example */
  trackingEventBase?: string;
  sharingPreface?: string;
  header?: string;
} & CardProps;

export function CodeExample({
  examples,
  trackingEventBase,
  sharingPreface,
  header,
  ...props
}: CodeExampleProps) {
  const [tabIndex, setTabIndex] = useState(0);
  const currentExample = examples[tabIndex];
  const headerBackground = useColorModeValue("brand.50", "gray.750");
  const headerText = useColorModeValue("brand.700", "brand.300");

  return (
    <Flex direction="column" gap={4}>
      <Card {...props} as="section" variant="filled">
        {header && (
          <CardHeader as="header" bg={headerBackground} py={2}>
            <Text as="h3" color={headerText}>
              {header}
            </Text>
          </CardHeader>
        )}
        <CardBody p={0}>
          <Tabs
            colorScheme="gray"
            index={tabIndex}
            size="sm"
            variant="soft-rounded"
            isLazy
            onChange={setTabIndex}
          >
            <TabList alignItems="center">
              {examples.map(({ key, label }) => (
                <Tab key={key}>{label}</Tab>
              ))}
              {(() => {
                const prefacedMarkdown = [
                  sharingPreface,
                  [
                    "```" + currentExample.highlighterLanguage,
                    currentExample.code,
                    "```",
                  ].join("\n"),
                  `[More details in Bucket](${window.location.href})`,
                ]
                  .filter(Boolean)
                  .join("\n\n");

                return (
                  <ButtonGroup ml="auto" size="xs" variant="outline">
                    <TrackInLinearButton
                      markdown={prefacedMarkdown}
                      title={sharingPreface ?? "Bucket TODO"}
                      trackingEventBase={trackingEventBase}
                    />
                    <CopyClipboardButton
                      code={currentExample.code}
                      trackingEventBase={trackingEventBase}
                    />
                  </ButtonGroup>
                );
              })()}
            </TabList>
            <TabPanels color="dimmed" fontSize="sm">
              {examples.map((example) => (
                <TabPanel key={example.key} borderRadius="md">
                  <CodeBlock language={example.highlighterLanguage}>
                    {example.code}
                  </CodeBlock>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </CardBody>
        {currentExample.installInstructions && (
          <CardFooter
            as="footer"
            borderTopColor="appBorder"
            borderTopWidth="1px"
            color="dimmed"
            fontSize="sm"
            px={4}
            py={2}
          >
            {currentExample.installInstructions}
          </CardFooter>
        )}
      </Card>
    </Flex>
  );
}

export function CopyClipboardButton({
  code,
  trackingEventBase,
}: {
  code: string;
  trackingEventBase?: string;
}) {
  const { onCopy } = useClipboard(code);
  const toast = useToast();

  const handleCopy = useCallback(() => {
    onCopy();
    toast({
      title: "Code copied",
      status: "success",
      duration: 2000,
      isClosable: true,
    });

    if (trackingEventBase) {
      segmentAnalytics.track(`${trackingEventBase} Shared`, {
        action: "copy markdown",
      });
    }
  }, [onCopy, toast, trackingEventBase]);

  return (
    <IconButton
      aria-label="Copy to clipboard"
      icon={<RiFileCopyLine size="14" />}
      onClick={handleCopy}
    />
  );
}

export function SendEmailButton({
  title,
  markdown,
  trackingEventBase,
}: {
  title: string;
  markdown: string;
  trackingEventBase?: string;
}) {
  const url = new URL("mailto:");

  url.searchParams.set("subject", title);
  url.searchParams.set("body", ["Hi,", markdown].join("\n\n"));

  return (
    <Button
      as={Link}
      href={url.toString()}
      leftIcon={<RiMailSendLine opacity="0.7" size="16" />}
      target="_blank"
      onClick={() => {
        if (trackingEventBase) {
          segmentAnalytics.track(`${trackingEventBase} Shared`, {
            action: "email",
          });
        }
      }}
    >
      Send as email
    </Button>
  );
}

export function TrackInLinearButton({
  title,
  markdown,
  trackingEventBase,
}: {
  title: string;
  markdown: string;
  trackingEventBase?: string;
}) {
  const url = new URL("https://linear.app/new");

  url.searchParams.set("title", title);
  url.searchParams.set("description", markdown);

  return (
    <Button
      as={Link}
      href={url.toString()}
      leftIcon={<LinearLogo color="#5E6AD2" opacity="1" width="14" />}
      target="_blank"
      onClick={() => {
        if (trackingEventBase) {
          segmentAnalytics.track(`${trackingEventBase} Shared`, {
            action: "linear task",
          });
        }
      }}
    >
      Add Linear task
    </Button>
  );
}
