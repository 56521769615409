import { useMemo } from "react";
import dayjs from "dayjs";

import { Period } from "@/common/components/PeriodPicker";
import { useSearchParam } from "@/common/hooks/useSearchParam";

export const useFeatureDates = () => {
  /**
   * The evaluation period is determined by the following rules:
   * if evaluation start < 30 days ago => Past 30 days
   * else if evaluation start < 3 months ago => Past 3 months
   * else => Past 6 months
   */
  const [evaluationPeriod, setEvaluationPeriod] = useSearchParam<Period>(
    "evaluation",
    {
      fallback: "past30days",
    },
  );

  const granularity: "daily" | "weekly" =
    evaluationPeriod === "past30days" ? "daily" : "weekly";

  const startDate = useMemo(() => {
    switch (evaluationPeriod) {
      case "past30days":
        return dayjs().utc().startOf("day").subtract(29, "days").toDate();
      case "past3months":
        return dayjs().utc().startOf("week").subtract(3, "months").toDate();
      case "past6months":
        return dayjs().utc().startOf("week").subtract(6, "months").toDate();
    }
  }, [evaluationPeriod]);

  const endDate = useMemo(() => {
    switch (evaluationPeriod) {
      case "past30days":
        return dayjs().utc().startOf("day").toDate();
      case "past3months":
      case "past6months":
        return dayjs().utc().startOf("week").toDate();
    }
  }, [evaluationPeriod]);

  const hasPartialPeriod = useMemo(() => {
    return dayjs(endDate)
      .add(1, granularity === "daily" ? "day" : "week")
      .isAfter(dayjs());
  }, [endDate, granularity]);

  return {
    startDate,
    endDate,
    evaluationPeriod,
    setEvaluationPeriod,
    granularity,
    hasPartialPeriod,
  };
};
