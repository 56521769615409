import { ReleasesQuery } from "@bucketco/shared/releaseAPI";

export const releasesQueryKeys = {
  list: (appId?: string, params?: ReleasesQuery) =>
    params
      ? (["apps", appId, "releases", params] as const)
      : (["apps", appId, "releases"] as const),

  single: (appId?: string, releaseId?: string) =>
    [...releasesQueryKeys.list(appId), releaseId] as const,

  features: (appId?: string, releaseId?: string) => [
    ...releasesQueryKeys.single(appId, releaseId),
    "features",
  ],
};
