import {
  AuditChartAxisMetricKey,
  AxisProps,
} from "@/common/charts/components/AuditChart/types";
import useChartTokens from "@/common/charts/hooks/useChartTokens";

import { useAxisPropsForMetric } from "./useAxisPropsForMetric";

export function useAuditChartAxis(
  metric: AuditChartAxisMetricKey,
  axis: "x" | "y",
): AxisProps {
  const { colors, fontSizes } = useChartTokens();
  const axisProps = useAxisPropsForMetric(metric);

  return {
    type: "number",
    fontSize: fontSizes.axisLabel,
    stroke: colors.grid,
    tick: { fill: colors.axis },
    tickLine: false,
    axisLine: true,
    ...axisProps,
    label:
      axis === "x"
        ? {
            value: axisProps.name,
            position: "insideBottom",
            offset: -24,
          }
        : {
            value: axisProps.name,
            angle: -90,
            position: "insideLeft",
            offset: -18,
          },
  };
}
