import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { Flag } from "@bucketco/shared/flagAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import api from "@/common/utils/api";
import flagsQueryKeys from "@/flags/data/flagsQueryKeys";

export function useFlagData(flagId?: string | null) {
  const { currentApp } = useAuthContext();
  const queryClient = useQueryClient();

  return useQuery<Flag, AxiosError<ErrorResponse>>({
    queryKey: flagsQueryKeys.single(currentApp?.id, flagId),

    queryFn: () =>
      api
        .get<"/apps/:appId/flags/:flagId">(
          `/apps/${currentApp?.id}/flags/${flagId}`,
        )
        .then((res) => res.data),
    enabled: !!currentApp && !!flagId,
    initialData: () =>
      queryClient
        .getQueryData<Array<Flag>>(flagsQueryKeys.list(currentApp?.id))
        ?.find((f) => f.id === flagId),
  });
}
