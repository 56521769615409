import { PropsWithChildren, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Center, Spinner } from "@chakra-ui/react";

import { useFeature } from "@bucketco/react-sdk";
import { AdminUrl } from "@bucketco/shared/urls";

import GoToHome from "@/auth/components/GoToHome";
import { useAuthContext } from "@/auth/contexts/authContext";
import navigateToLogin from "@/common/utils/navigateToLogin";

export default function RequireAuth({ children }: PropsWithChildren) {
  const { isInitialized, isLoading, isError, user, currentOrg } =
    useAuthContext();
  const { isLoading: isLoadingFlags } = useFeature("feature-revamp-2024");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.code === "KeyA" && e.altKey === true) {
        navigate(AdminUrl(currentOrg?.id));
      }
    };

    if (user?.isAdmin) {
      window.addEventListener("keydown", handler);
    }

    return () => {
      if (user?.isAdmin) {
        window.removeEventListener("keydown", handler);
      }
    };
  }, [user?.isAdmin, currentOrg?.id, navigate]);

  if (!isInitialized || isLoading || isLoadingFlags) {
    return (
      <Center h="100vh">
        <Spinner mr={2} size="sm" />
      </Center>
    );
  }

  if (isError) {
    navigateToLogin();
    return null;
  }

  const isMissingApp = currentOrg === undefined || currentOrg.apps.length === 0;
  const isMissingOrg = currentOrg === undefined;

  if (
    (isMissingApp || isMissingOrg) &&
    location.pathname !== "/apps/new" &&
    location.pathname !== "/get-started" &&
    !location.pathname.includes("invite")
  ) {
    return <GoToHome />;
  }

  return <>{children}</>;
}
