import { useFormContext, useWatch } from "react-hook-form";
import { Box, Flex, HStack, Spinner, TagLabel, Text } from "@chakra-ui/react";

import {
  defaultTypeValues,
  listOperators,
  operatorDisplayName,
  operatorsWithoutValue,
  relevantOperators,
  TypeByOperator,
} from "@bucketco/shared/attributeFilter";
import {
  CompanyFeatureMetricFilter,
  FeatureMetric,
  featureMetricOperators,
} from "@bucketco/shared/filter";

import { FeatureAutocompleteSelect } from "@/common/components/FeatureAutocompleteSelect";
import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import RuleMetric, {
  featureMetricOptions,
} from "@/common/components/Rule/RuleMetric";
import RuleOperator from "@/common/components/Rule/RuleOperator";
import RuleValue, { FieldType } from "@/common/components/Rule/RuleValue";
import { useAttributeValues } from "@/common/data/useAttributeValues";
import { useFormFieldDiff } from "@/common/hooks/useFormFieldDiff";
import { useFormFieldUpdate } from "@/common/hooks/useFormFieldUpdate";
import FeatureDisplay from "@/feature-legacy/components/FeatureDisplay";
import useFeatureNamesData from "@/feature-legacy/data/useFeatureNamesData";
import { TextWordBreak } from "../TextWordBreak";

import FilterValue from "./FilterValue";

const defaultMetric: FeatureMetric = "funnelStep";

const getRelevantOperators = (metric: CompanyFeatureMetricFilter["metric"]) => {
  const operators =
    metric === "firstUsed"
      ? relevantOperators(["date", "any"])
      : metric === "lastUsed"
      ? relevantOperators(["date", "any"])
      : relevantOperators(["date", "any"]);

  const unfilteredOperators = ["firstUsed", "lastUsed"].includes(metric)
    ? relevantOperators(["date", "any"])
    : ["funnelStep", "frequency", "satisfaction"].includes(metric)
    ? relevantOperators(["number", "list", "any"])
    : relevantOperators(["number", "any"]);

  for (const type in unfilteredOperators) {
    const t = type as keyof typeof unfilteredOperators;
    operators[t] = unfilteredOperators[t]!.filter((op) =>
      featureMetricOperators[metric].includes(op),
    );
  }

  return operators;
};

const getValueType = (
  metric: CompanyFeatureMetricFilter["metric"],
  operator: CompanyFeatureMetricFilter["operator"],
): FieldType => {
  if (operatorsWithoutValue.includes(operator)) {
    return "none";
  }

  if (listOperators.includes(operator)) {
    return "multiSelect";
  }

  switch (metric) {
    case "firstUsed":
    case "lastUsed":
      return "date";
    case "eventCount":
      return "number";
    default:
      return "select";
  }
};

export function CompanyFeatureMetricFilterFields() {
  const form = useFormContext<CompanyFeatureMetricFilter>();
  const metric = useWatch({
    name: "metric",
    defaultValue: form.getValues("metric") ?? defaultMetric,
  });
  const operator = useWatch({ name: "operator" });
  const operators = getRelevantOperators(metric);
  const valueType = getValueType(metric, operator);

  // The field changed. Reset the operator to default if the field type does not support the current operator.
  useFormFieldUpdate(form, "metric", (newValue) => {
    if (form.getValues("type") !== "featureMetric") return;
    const currentOperator = form.getValues("operator");
    const operators = getRelevantOperators(newValue);
    const allowed = Object.values(operators).flat();

    if (!allowed.includes(currentOperator)) {
      form.setValue("operator", allowed[0], {
        shouldDirty: true,
      });
    }
    form.setValue("values", [""], {
      shouldDirty: true,
    });
  });

  // The operator changed. Reset value to default if the value type also changed.
  useFormFieldDiff(form, "operator", ({ oldValue, newValue }) => {
    if (form.getValues("type") !== "featureMetric") return;
    const valueType = TypeByOperator[newValue];
    const previousValueType = oldValue && TypeByOperator[oldValue];

    if (valueType !== previousValueType) {
      form.setValue("values", defaultTypeValues[valueType], {
        shouldDirty: true,
      });
    } else {
      // Validate value field, since the new operator might not work
      // form the existing value
      form.trigger("values");
    }
  });

  return (
    <>
      <ManagedFormControl
        name="featureId"
        render={({ field }) => (
          <FeatureAutocompleteSelect
            showClearButton={false}
            {...field}
            value={field.value}
            onChange={(nextFeature) => {
              if (nextFeature) {
                field.onChange(nextFeature.id);
              }
            }}
          />
        )}
      />

      <ManagedFormControl
        defaultValue={defaultMetric}
        name="metric"
        render={({ field }) => <RuleMetric {...field} />}
      />

      <ManagedFormControl
        name="operator"
        render={({ field }) => (
          <RuleOperator {...field} operators={operators} />
        )}
      />

      <ManagedFormControl
        key={`${metric}.${operator}`}
        name={listOperators.includes(operator) ? "values" : "values[0]"}
        render={({ field }) => (
          <RuleValue
            {...field}
            entityType={
              metric === "frequency"
                ? "feature-frequency"
                : metric === "satisfaction"
                ? "feature-satisfaction"
                : metric === "funnelStep"
                ? "feature-funnelStep"
                : "company"
            }
            fieldType={valueType}
          />
        )}
      />
    </>
  );
}

type CompanyFeatureMetricFilterItemProps = {
  filter: CompanyFeatureMetricFilter;
  showIcon?: boolean;
};

export function CompanyFeatureMetricFilterItem({
  filter,
  showIcon,
}: CompanyFeatureMetricFilterItemProps) {
  const { data: features = [], isLoading } = useFeatureNamesData();
  const feature = features?.find(({ id }) => id === filter.featureId);
  const metric = featureMetricOptions.find(
    ({ metric }) => metric === filter.metric,
  );
  const { data = [] } = useAttributeValues({
    entityType:
      filter.metric === "frequency"
        ? "feature-frequency"
        : filter.metric === "satisfaction"
        ? "feature-satisfaction"
        : filter.metric === "funnelStep"
        ? "feature-funnelStep"
        : "company",
  });

  const values = data.filter((v) => filter.values.includes(v.value));
  const type =
    filter.metric === "firstUsed" || filter.metric === "lastUsed"
      ? "date"
      : "number";

  return (
    <TagLabel whiteSpace="normal">
      {isLoading ? (
        <Flex align="center" color="dimmed" columnGap={2}>
          <Spinner size="xs" />
          <TextWordBreak as="span">Loading filter...</TextWordBreak>
        </Flex>
      ) : (
        <Flex align="center" columnGap={2} wrap="wrap">
          {metric && showIcon && (
            <Box as="span" color="dimmed" fontSize="14px">
              {metric.icon}
            </Box>
          )}
          {metric && <TextWordBreak as="span">{metric.label}</TextWordBreak>}
          <Text as="span" color="dimmed">
            for
          </Text>
          {feature && <FeatureDisplay as="span" feature={feature} size="sm" />}
          <Text as="span" color="dimmed">
            {operatorDisplayName[filter.operator]}
          </Text>
          {values.length ? (
            values.map((value) => (
              <HStack key={value.value} spacing={2}>
                {value.icon}
                <Text as="span">{value.label}</Text>
              </HStack>
            ))
          ) : (
            <FilterValue
              operator={filter.operator}
              type={type}
              value={filter.values}
            />
          )}
        </Flex>
      )}
    </TagLabel>
  );
}
