import { useEffect, useMemo, useState } from "react";
import {
  Box,
  chakra,
  HStack,
  Text,
  useColorModeValue,
  useToken,
} from "@chakra-ui/react";
import bezier from "bezier-easing";
import dayjs from "dayjs";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  YAxisProps,
} from "recharts";

import {
  GoalFeatureMetric,
  GoalFeatureMetrics,
  GoalStatus,
} from "@bucketco/shared/goalAPI";
import { ReleaseDTO } from "@bucketco/shared/releaseAPI";
import { getFraction } from "@bucketco/shared/utils/getFraction";

import TargetSvg from "@/common/assets/target.svg?react";
import {
  IncompleteBarPattern,
  incompleteBarPatternId,
} from "@/common/charts/components/IncompleteBar";
import { ReferenceLabel } from "@/common/charts/components/ReferenceLabel";
import useChartTokens from "@/common/charts/hooks/useChartTokens";
import useDimensions from "@/common/hooks/useDimensions";
import {
  epochIsSame,
  epochToDate,
  epochToShortDate,
  fullFormattedDate,
  fullFormattedDateTime,
} from "@/common/utils/datetime";
import { useGoalStatusColors } from "@/release/hooks/useGoalStatusColors";
import { formatGoalValue } from "@/release/utils/format";

const TargetIcon = chakra(TargetSvg);

type Props = {
  data: { epoch: number; value: number | null }[];
  startDate: Date;
  endDate: Date;
  status: GoalStatus;
  metric?: GoalFeatureMetric;
  releasedAt?: Date;
  achievedAt?: Date;
  height?: number;
  threshold: number;
  isPercentage?: boolean;
  highlightPartialPeriod?: boolean;
};

const LINE_ANIMATION_DURATION = 500;
const DOT_ANIMATION_DURATION = 50;

export const GoalChart = ({
  data: baseData,
  startDate,
  endDate,
  status,
  metric,
  releasedAt,
  achievedAt,
  threshold,
  height = 270,
  isPercentage,
  highlightPartialPeriod = true,
}: Props) => {
  const definition = metric && GoalFeatureMetrics[metric];

  const [width, setWidth] = useState<number>(720);
  const lineColor = useToken(
    "colors",
    useColorModeValue("gray.600", "whiteAlpha.900"),
  );
  const labelBg = useToken("colors", useColorModeValue("white", "gray.700"));
  const labelBorder = useToken(
    "colors",
    useColorModeValue("gray.300", "gray.650"),
  );
  const labelColor = useToken("colors", "dimmed");
  const chartStroke = useToken(
    "colors",
    useColorModeValue("gray.200", "gray.650"),
  );
  const { colors, properties, radii, fontSizes, sizes } = useChartTokens();

  const data = useMemo(() => {
    return baseData.flatMap((d) => {
      // Exclude values beyond the end date
      if (endDate && d.epoch > dayjs(endDate).unix()) return [];

      // Force the value on the "achieved" date to be the
      // threshold. This is because the value at 00:00 on the
      // date it was achieved won't be at the threshold yet
      if (achievedAt && d.epoch === dayjs(achievedAt).unix()) {
        return [{ epoch: d.epoch, value: threshold }];
      }

      // Don't draw a line for values beyond the achieved date
      // (but still include those dates in the chart)
      if (achievedAt && d.epoch > dayjs(achievedAt).unix()) {
        return [{ epoch: d.epoch, value: null }];
      }

      // Include all other values
      return [d];
    });
  }, [baseData, threshold, achievedAt, endDate]);

  const releasedValue = useMemo(
    () =>
      releasedAt
        ? data.findLast((d) => d.epoch <= dayjs(releasedAt).unix())?.value ??
          null
        : null,
    [data, releasedAt],
  );

  const achievedPlotTime = useMemo(() => {
    if (!achievedAt) return null;
    return data.find((d) => d.epoch >= dayjs(achievedAt).unix())?.epoch ?? null;
  }, [data, achievedAt]);

  const endValue = useMemo(
    () =>
      endDate
        ? data.find((d) => d.epoch >= dayjs(endDate).unix())?.value ?? null
        : null,
    [data, endDate],
  );

  const currentPoint = useMemo(
    () => data.findLast((d) => d.value !== null),
    [data],
  );

  const partialDataPeriod = useMemo<[number, number] | null>(() => {
    // Only show when enabled
    if (!highlightPartialPeriod) return null;

    // Only show for planned and evaluating goals
    if (status !== "planned" && status !== "evaluating") return null;

    const lastTwoEntries = data
      .filter(({ value }) => value !== null)
      .map(({ epoch }) => epoch)
      .reverse()
      .slice(0, 2);

    // Only show if there is enough data
    if (lastTwoEntries.length !== 2) return null;

    // Only show if the partial data period is today
    if (!epochIsSame(lastTwoEntries[0], "day")) return null;

    return lastTwoEntries as [number, number];
  }, [data, status, highlightPartialPeriod]);

  const now = dayjs();
  const tickCount = width
    ? Math.floor((width - sizes.yAxisWidth * 2) / 48) // 48px per tick
    : 0;
  const granularityDiff = dayjs(endDate).diff(startDate, "day");
  const nowDiff = now.diff(startDate, "day");
  const shift = nowDiff % 2; // always show now tick

  const domain = useMemo<[number, number]>(
    () =>
      data.length > 0
        ? [data[0].epoch, data[data.length - 1].epoch]
        : [dayjs(startDate).unix(), dayjs(endDate).unix()],
    [data, startDate, endDate],
  );

  const yAxis = useGoalYAxis({ data, metric, threshold, isPercentage });

  return (
    <ResponsiveContainer
      height={height}
      width="100%"
      onResize={(newWidth) => {
        setWidth(newWidth);
      }}
    >
      <LineChart
        data={data}
        margin={{
          top: 48,
          right: 70,
          bottom: -12,
          left: 0,
        }}
      >
        {partialDataPeriod !== null && (
          <>
            <defs>
              <IncompleteBarPattern
                colorName={"partialHighlight"}
                colorValue={colors.partialHighlight}
              />
            </defs>
            <ReferenceArea
              fill={`url(#${incompleteBarPatternId("partialHighlight")})`}
              x1={partialDataPeriod[0]}
              x2={partialDataPeriod[1]}
              yAxisId="line"
            />
          </>
        )}
        <CartesianGrid
          stroke={chartStroke}
          strokeDasharray="3"
          vertical={false}
        />
        <XAxis
          axisLine={true}
          dataKey="epoch"
          domain={domain}
          dy={8}
          fontSize={fontSizes.axisLabel}
          height={50}
          interval={0}
          minTickGap={4}
          scale="utc"
          stroke={colors.axis}
          tickFormatter={(epoch: number, index: number) => {
            if (
              (index + shift) %
                Math.ceil(getFraction(granularityDiff, tickCount)) !==
              0
            )
              return "";
            if (epochIsSame(epoch, "day")) return "Now";
            return epochToShortDate(epoch);
          }}
          tickLine={true}
          type="number"
        />
        <YAxis
          axisLine={false}
          fontSize={fontSizes.axisLabel}
          stroke={colors.axis}
          tickFormatter={(value) =>
            formatGoalValue(value, isPercentage, metric)
          }
          tickLine={false}
          tickMargin={4}
          type="number"
          width={sizes.yAxisWidth}
          yAxisId="line"
          {...yAxis}
        />
        {threshold && (
          <>
            <ReferenceLine
              ifOverflow="extendDomain"
              label={
                <AdoptionLineLabel
                  label={formatGoalValue(threshold, isPercentage, metric)}
                  status={status}
                />
              }
              stroke={status === "achieved" ? colors.active : colors.axis}
              strokeDasharray="3"
              y={threshold}
              yAxisId="line"
            />
          </>
        )}
        <Tooltip
          contentStyle={{
            borderColor: colors.tooltipBorder,
            backgroundColor: colors.tooltipBg,
            borderRadius: radii.tooltip,
            fontSize: fontSizes.tooltip,
          }}
          cursor={{ stroke: colors.cursor }}
          formatter={(value: number) => {
            return [
              formatGoalValue(value, isPercentage, metric),
              definition?.label ?? "",
            ] as any; // workaround for https://github.com/recharts/recharts/issues/2976
          }}
          isAnimationActive={false}
          labelFormatter={(epoch: number) => {
            if (epochIsSame(epoch, "day")) {
              return (
                <>
                  <Text as="span">{dayjs().format("MMM D, LT")}</Text>
                  <br />
                  <Text as="span" color="dimmed">
                    (Partial day)
                  </Text>
                </>
              );
            }
            return epochToDate(epoch);
          }}
        />
        <Line
          activeDot={properties.activeDot}
          animationDuration={LINE_ANIMATION_DURATION}
          animationEasing="ease"
          dataKey="value"
          dot={false}
          stroke={lineColor}
          strokeLinecap="round"
          strokeWidth={3}
          type="monotone"
          yAxisId="line"
        />

        {releasedAt && achievedAt?.getDate() !== releasedAt.getDate() && (
          <ReferenceLine
            // Add the timezone offset to the shift reference line to match utc data points
            label={
              <ReferenceLabel
                bg={labelBg}
                borderColor={labelBorder}
                borderRadius="xl"
                chartWidth={width}
                color={labelColor}
                offsetY={-32}
                text="Released"
                tooltip={fullFormattedDateTime(releasedAt)}
              />
            }
            shape={<DateLine />}
            stroke={colors.referenceLabelBorder}
            x={dayjs(releasedAt).unix()}
            yAxisId="line"
            isFront
          />
        )}

        {endDate && (
          <ReferenceLine
            // Add the timezone offset to the shift reference line to match utc data points
            label={
              <ReferenceLabel
                bg={labelBg}
                borderColor={labelBorder}
                borderRadius="xl"
                chartWidth={width}
                color={labelColor}
                offsetY={-32}
                text="End"
                tooltip={fullFormattedDateTime(endDate)}
              />
            }
            shape={<DateLine />}
            stroke={colors.referenceLabelBorder}
            x={dayjs(endDate).unix()}
            yAxisId="line"
            isFront
          />
        )}

        {achievedAt && (
          <ReferenceLine
            label={
              <ReferenceLabel
                bg={labelBg}
                borderColor={colors.active}
                borderRadius="xl"
                chartWidth={width}
                color={labelColor}
                offsetY={-32}
                text={
                  achievedAt.getDate() === releasedAt?.getDate()
                    ? "Released & Achieved"
                    : "Achieved"
                }
                textProps={{
                  color: colors.active,
                }}
                tooltip={fullFormattedDate(achievedAt)}
              />
            }
            shape={<DateLine />}
            stroke={colors.active}
            x={achievedPlotTime ?? dayjs(achievedAt).unix()}
            yAxisId="line"
          />
        )}

        {releasedValue !== null &&
          achievedAt?.getDate() !== releasedAt?.getDate() && (
            <ReferenceDot
              shape={
                <ValueDot
                  domain={domain}
                  isPercentage={isPercentage}
                  metric={metric}
                  status="planned"
                  value={releasedValue}
                />
              }
              x={dayjs(releasedAt).unix()}
              y={releasedValue}
              yAxisId="line"
              isFront
            />
          )}

        {endValue !== null && (
          <ReferenceDot
            shape={
              <ValueDot
                domain={domain}
                isPercentage={isPercentage}
                metric={metric}
                status={status}
                value={endValue}
              />
            }
            x={dayjs(endDate).unix()}
            y={endValue}
            yAxisId="line"
            isFront
          />
        )}

        {endValue === null && currentPoint?.value !== null && (
          <ReferenceDot
            shape={
              <ValueDot
                domain={domain}
                isPercentage={isPercentage}
                metric={metric}
                status={status}
                value={currentPoint?.value}
              />
            }
            x={currentPoint?.epoch}
            y={currentPoint?.value}
            yAxisId="line"
            isFront
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

const ease = bezier(0.25, 0.1, 0.25, 1.0); // Equivalent to CSS "ease", as used by recharts

function ValueDot({
  x = 0,
  cx = 0,
  cy = 0,
  value,
  isPercentage,
  metric,
  status,
  domain,
}: {
  x?: number;
  cx?: number;
  cy?: number;
  value?: number;
  isPercentage?: boolean;
  metric?: GoalFeatureMetric;
  status: GoalStatus;
  domain: readonly [number, number];
}) {
  const { width, height, measureRef } = useDimensions();

  const { fg } = useGoalStatusColors(status);
  const label = formatGoalValue(value ?? 0, isPercentage, metric);

  const [entered, setEntered] = useState(false);
  useEffect(() => {
    if (entered) return;

    // Calculate the x distance across the chart of the Dot
    const total = domain[1] - domain[0];
    const distance = (x - domain[0]) / total;

    // Time the intro transition to the position of the Line, making the mid-point of
    // the Dot's animation occur as the Line is passing through it
    const delay =
      ease(distance) * LINE_ANIMATION_DURATION - DOT_ANIMATION_DURATION / 2;

    const t = setTimeout(() => {
      setEntered(true);
    }, delay);

    return () => clearTimeout(t);
  }, [x, entered, domain]);

  return (
    <foreignObject
      height={height}
      width={width}
      x={cx - width / 2}
      y={cy - height / 2}
    >
      <Box ref={measureRef} width="fit-content">
        <Box
          bg={fg}
          borderRadius="full"
          display="block"
          px={2}
          py={0.5}
          transform={entered ? "scale(1)" : "scale(0.6)"}
          transition={`transform ease ${DOT_ANIMATION_DURATION}ms`}
        >
          <Text color="chakra-body-bg" fontSize="xs" fontWeight="semibold">
            {label}
          </Text>
        </Box>
      </Box>
    </foreignObject>
  );
}

function DateLine(props: any) {
  return (
    <line
      {...props}
      y2={props.y2 - 32} // Bleed over the chart margin to reach the ReferenceLabel
    />
  );
}

function AdoptionLineLabel({
  label,
  status,
  viewBox = { width: 0, x: 0, y: 0 },
}: {
  label: string;
  status: string;
  viewBox?: { width: number; x: number; y: number };
}) {
  const { fg } = useGoalStatusColors(
    status === "achieved" ? "achieved" : "planned",
  );

  return (
    <foreignObject
      height="100px"
      width="200px"
      x={viewBox.width + viewBox.x + 2}
      y={viewBox.y - 12}
    >
      <Box width="fit-content">
        <HStack bg={fg} borderRadius="full" px={2} py={0.5} spacing={0.5}>
          <TargetIcon color="chakra-body-bg" height="14px" width="14px" />
          <Text color="chakra-body-bg" fontSize="xs" fontWeight="semibold">
            {label}
          </Text>
        </HStack>
      </Box>
    </foreignObject>
  );
}

function useGoalYAxis({
  data,
  metric,
  isPercentage,
  threshold,
}: {
  data: { epoch: number; value: number | null }[];
  metric?: GoalFeatureMetric;
  isPercentage?: boolean;
  threshold: number;
}): YAxisProps {
  return useMemo(() => {
    if (metric === "averageFrequency") {
      return {
        ticks: [0, 1, 2, 3],
        domain: [0, 3],
      };
    }

    const max = Math.max(threshold, ...data.map((d) => d.value ?? 0));
    const formattedMax = isPercentage
      ? 1 // Ensure percentage caps at 100%
      : Math.max(5, max); // Ensure there are at least as many tick values as ticks

    const ticks = getTicks(formattedMax, 5, 5);
    const domain = [0, ticks[ticks.length - 1]];

    return {
      ticks,
      domain,
    };
  }, [data, metric, isPercentage, threshold]);
}

// Create neat looking chart ticks by ensuring each tick value is
// divisible by a given number
function getTicks(value: number, tickCount: number, tickDivisor: number) {
  const step = roundUpToNearest(value / (tickCount - 1), tickDivisor);
  return Array.from(new Array(tickCount)).map((_, i) => i * step);
}

// Round a number to the nearest value keeping at most 2 decimal places.
function roundUpToNearest(value: number, nearest: number) {
  if (value <= 1) {
    return Math.round(Math.ceil((100 * value) / nearest) * nearest) / 100;
  } else {
    return Math.round(Math.ceil(value / nearest) * nearest * 100) / 100;
  }
}

export function getChartStartEndDates(release: ReleaseDTO) {
  // today or the release date, whichever is earlier
  const baseDate = dayjs.min(
    dayjs.utc(),
    dayjs.utc(release.releasedAt ?? undefined),
  )!;
  return {
    startDate: baseDate.subtract(7, "days").toDate(),
    endDate: baseDate.add(release.evaluationPeriod ?? 14, "days").toDate(),
  };
}
