import { FormProvider, useForm } from "react-hook-form";
import { RiFileCopyLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Tooltip,
  useClipboard,
  useColorModeValue,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { PatchFeatureArgsSchema } from "@bucketco/shared/featureAPI";

import FormCancel from "@/common/components/form/FormCancel";
import { FormRootError } from "@/common/components/form/FormRootError";
import FormSubmit from "@/common/components/form/FormSubmit";
import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import { getFormMutationSubmitHandler } from "@/common/hooks/useApiForm";
import { SettingsDelete } from "@/feature/components/SettingsDelete";
import { useFeatureData } from "@/feature/data/useFeatureData";
import { useFeatureUpdateMutation } from "@/feature/data/useFeatureUpdateMutation";
import { useFlagData } from "@/flags/data/useFlagData";

export function SettingsGeneral() {
  const { featureId } = useParams();
  const { data: feature } = useFeatureData(featureId!);
  const { data: flag } = useFlagData(feature?.flagId);

  const form = useForm<z.input<typeof PatchFeatureArgsSchema>>({
    resolver: zodResolver(PatchFeatureArgsSchema),
    mode: "onChange",
    defaultValues: {
      name: feature?.name,
    },
  });

  const updateMutation = useFeatureUpdateMutation(featureId!);
  const submitHandler = getFormMutationSubmitHandler(
    form,
    updateMutation,
    (result) => {
      form.reset({
        name: result.name,
      });
    },
  );

  const readonlyInputBg = useColorModeValue("gray.50", "gray.800");
  const { hasCopied, onCopy } = useClipboard(flag?.key ?? "");

  return (
    <FormProvider {...form}>
      <Flex
        as="form"
        direction={"column"}
        gap={5}
        maxW="compactForm"
        onSubmit={submitHandler}
      >
        <ManagedFormControl
          label="Name"
          name="name"
          render={({ field }) => (
            <Input
              {...field}
              placeholder="e.g. Huddle calls"
              size="sm"
              autoFocus
            />
          )}
        />
        <Flex alignItems="flex-start" flexDirection="column" gap={2}>
          <HStack>
            <FormLabel
              alignItems="center"
              display="flex"
              flex="0 0 auto"
              fontSize="md"
              gap={1}
              marginInlineEnd={0}
              pb={0}
            >
              Key
            </FormLabel>
            <InfoIconTooltip
              // _content={{ lineHeight: "base", whiteSpace: "normal" }}
              // docsURL={descriptionLink}
              // renderInPortal={descriptionInPortal}
              text="TODO! Something about the feature key and how's used for both flagging and usage tracking"
            />
          </HStack>
          <HStack w="full">
            <Input
              bg={readonlyInputBg}
              size="sm"
              value={feature?.key ?? ""}
              isReadOnly
            />
            <Tooltip
              closeOnClick={false}
              label={hasCopied ? "Copied!" : "Copy to clipboard"}
            >
              <IconButton
                aria-label="Copy to clipboard"
                borderRadius="md"
                icon={<RiFileCopyLine />}
                p={0}
                size="sm"
                variant="outline"
                onClick={onCopy}
              />
            </Tooltip>
          </HStack>
        </Flex>

        <FormRootError />

        <ButtonGroup>
          <FormSubmit>Save</FormSubmit>
          {form.formState.isDirty && (
            <FormCancel color="dimmed" size="sm" onClick={() => form.reset()}>
              Reset
            </FormCancel>
          )}
        </ButtonGroup>

        <Divider />

        <Box>
          <SettingsDelete feature={feature!} />
        </Box>
      </Flex>
    </FormProvider>
  );
}
