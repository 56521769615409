import { TargetAudienceEstimateType } from "@bucketco/shared/flagAPI";

const qk = {
  list: (appId?: string) => ["apps", appId, "flags"] as const,

  single: (appId?: string, flagId?: string | null) =>
    [...qk.list(appId), flagId] as const,

  status: (appId?: string, flagId?: string) =>
    [...qk.single(appId, flagId), "status"] as const,

  targetAudienceEstimate: (
    appId?: string,
    envId?: string,
    flagId?: string,
    ruleId?: string,
    args?: TargetAudienceEstimateType,
  ) =>
    [
      ...qk.single(appId, flagId),
      "environments",
      envId,
      "estimate",
      ruleId,
      args,
    ] as const,

  versions: (appId?: string, flagId?: string) =>
    [...qk.single(appId, flagId), "versions"] as const,

  attributeNames: (appId?: string, prefix?: string) =>
    [qk.list(appId), "context", "keys", prefix] as const,

  attributeKeys: (appId?: string, prefix?: string, value?: string) =>
    [qk.attributeNames(appId, prefix), "values", value] as const,
};

export default qk;
