import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  AlertIcon,
  Flex,
  Heading,
  Input,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";

import { CreateOrgArgsSchema } from "@bucketco/shared/organizationAPI";
import { EnvironmentUrl } from "@bucketco/shared/urls";

import { useOrgCreateMutation } from "@/app/data/useOrgCreateMutation";
import { useAuthContext } from "@/auth/contexts/authContext";
import CenteredLayout from "@/common/components/CenteredLayout";
import { FormRootError } from "@/common/components/form/FormRootError";
import FormSubmit from "@/common/components/form/FormSubmit";
import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import commonQueryKeys from "@/common/data/commonQueryKeys";
import { getFormMutationSubmitHandler } from "@/common/hooks/useApiForm";
import { useFormFieldUpdate } from "@/common/hooks/useFormFieldUpdate";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

function Form() {
  const queryClient = useQueryClient();
  const boostrapQuery = queryClient.getQueryState(commonQueryKeys.bootstrap);
  const { setActiveOrg } = useAuthContext();
  const { fetchStatus } = boostrapQuery ?? {};

  const form = useForm({
    resolver: zodResolver(CreateOrgArgsSchema),
    mode: "onChange",
    defaultValues: {
      name: "",
      app: {
        name: "",
      },
    },
  });

  const nameSuggestion = useOrgNameSuggestion();

  useEffect(() => {
    if (nameSuggestion) {
      form.setValue("name", nameSuggestion);

      form.setValue("app.name", nameSuggestion, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  }, [nameSuggestion, form]);

  const orgCreateMutation = useOrgCreateMutation();

  const handleSubmit = getFormMutationSubmitHandler(
    form,
    orgCreateMutation,
    (org) => {
      const env = org.apps?.[0]?.environments?.[0];
      if (!env) {
        console.error("[GetStarted] No app found in newly created org");
        return;
      }

      setActiveOrg(org.id, { refresh: false });

      window.location.assign(`${EnvironmentUrl(env)}/tracking/recent`);
    },
  );

  // generate app name from org name as you type
  useFormFieldUpdate(form, "name", (orgName) => {
    form.setValue("app.name", `${orgName}`);
  });

  // keep form disabled if bootstrap is fetching after successful submit
  const isDisabled = form.formState.isSubmitting || fetchStatus === "fetching";

  return (
    <form onSubmit={handleSubmit}>
      <FormProvider {...form}>
        <VStack align="flex-start" spacing={6}>
          <ManagedFormControl
            label="Organization"
            name="name"
            render={({ field }) => (
              <Input
                {...field}
                autoComplete="organization"
                placeholder="Acme Inc."
                size="lg"
                autoFocus
              />
            )}
          />

          <FormRootError />

          <Alert status="info">
            <AlertIcon />
            We automatically create a production, staging, and development
            environment for your app. You can create more apps and environments
            later.
          </Alert>

          <FormSubmit isLoading={isDisabled} size="lg">
            Let&apos;s go
          </FormSubmit>
        </VStack>
      </FormProvider>
    </form>
  );
}

function useOrgNameSuggestion(): string | undefined {
  const { user } = useAuthContext();
  const domainNamePart = user?.email.split("@").at(-1)?.split(".").at(-2);

  if (!domainNamePart || domainNamePart === "gmail") {
    return undefined;
  }

  // We're ignoring possibly punycoded domains
  // Replace all non-numbers and non-letters with space
  const suggestion = domainNamePart.replace(/[^0-9A-Za-z]/g, " ");

  // Uppercase first letter
  return suggestion.charAt(0).toLocaleUpperCase() + suggestion.slice(1);
}

export default function GetStarted() {
  const navigate = useNavigate();
  const { currentEnv, user } = useAuthContext();

  // on load, if user already has an org + app, redirect to that
  useEffect(() => {
    if (currentEnv) {
      navigate(EnvironmentUrl(currentEnv), { replace: true });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    segmentAnalytics.page("Sign Up");

    // Browser tracking destinations only tracking of "Signed Up"
    // Keep the destinations opt-in here to avoid duplicate tracking on cloud
    segmentAnalytics.track(
      "Signed Up",
      {
        email: user?.email,
      },
      {
        integrations: {
          // Avoid duplicating this event on cloud destinations
          All: false,
          // Enable for all browser-only destinations
          "Twitter Ads": true,
          "LinkedIn conversion tracking": true,
        },
      },
    );
  });

  return (
    <CenteredLayout>
      <Helmet>
        <title>Get Started</title>
      </Helmet>
      <Flex direction="column" w="compactForm">
        <Heading fontSize="3xl" mb={8}>
          Get started
        </Heading>
        <Form />
      </Flex>
    </CenteredLayout>
  );
}
