import { useColorModeValue } from "@chakra-ui/react";

export function useStarsFunnelColors() {
  const [
    segmentColor,
    triedColor,
    adoptedColor,
    retainedColor,
    satisfiedColor,
    disabledColor,
    dropOffColor,
  ] = useColorModeValue(
    [
      "brand.100",
      "brand.200",
      "brand.300",
      "brand.400",
      "brand.500",
      "gray.200",
      "red.100",
    ],
    [
      "brand.700",
      "brand.600",
      "brand.500",
      "brand.400",
      "brand.300",
      "gray.700",
      "red.400",
    ],
  );
  return {
    segmentColor,
    triedColor,
    adoptedColor,
    retainedColor,
    satisfiedColor,
    disabledColor,
    dropOffColor,
  };
}
