import { useColorModeValue } from "@chakra-ui/react";

import { ReleaseStatus } from "@bucketco/shared/releaseAPI";

export function useReleaseStatusColors(): Record<
  ReleaseStatus,
  { fg: string; bg: string }
> {
  return {
    planned: {
      fg: useColorModeValue("gray.500", "gray.400"),
      bg: useColorModeValue("gray.150", "gray.650"),
    },
    evaluating: {
      fg: useColorModeValue("yellow.600", "yellow.600"),
      bg: useColorModeValue("#CA8A042B", "#CA8A0440"),
    },
    concluded: {
      fg: useColorModeValue("brand.500", "brand.400"),
      bg: useColorModeValue("#E7E7FD", "#6464FF69"),
    },
  };
}
