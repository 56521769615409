import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { TbHash, TbPercentage } from "react-icons/tb";
import { HStack, Text, useToken } from "@chakra-ui/react";

import { FeatureName } from "@bucketco/shared/featureAPI";
import { GoalFeatureMetric } from "@bucketco/shared/goalAPI";

import RadioGroupList from "@/common/components/RadioGroupList";
import SimpleSelect from "@/common/components/SimpleSelect";
import useGoalMetricOptions, {
  GoalMetricOption,
  METRIC_CATEGORIES,
} from "@/release/hooks/useGoalMetricOptions";

export type GoalMetricSelectorProps = {
  value: GoalFeatureMetric;
  onChange: (metricId: GoalFeatureMetric) => void;
  selectedFeature?: FeatureName;
  disabled?: boolean;
};

export const GoalMetricSelector = forwardRef<
  HTMLButtonElement,
  GoalMetricSelectorProps
>(function GoalMetricSelector(
  { value, onChange, selectedFeature, disabled },
  ref,
) {
  const [metricOption, _setMetricOption] = useState("");
  const [metricMeasurement, _setMetricMeasurement] = useState("");

  const metrics = useGoalMetricOptions();
  const filteredMetrics = useMemo<GoalMetricOption[]>(
    () =>
      metrics.filter((f) => {
        if (selectedFeature?.source !== "event") {
          return f.restriction !== "event-based";
        }

        return true;
      }),
    [selectedFeature, metrics],
  );

  const selectedMetric = metrics.find((m) =>
    m.measurements.some((mm) => mm.id === value),
  );

  // Set initial local state from form value
  useEffect(() => {
    const metric = filteredMetrics.find((f) =>
      f.measurements.some((mm) => mm.id === value),
    );
    const measurement = metric?.measurements.find((mm) => mm.id === value);

    // If the selected metric is not available for the selected feature, select the first available metric
    if (metric === undefined) {
      _setMetricOption(filteredMetrics[0].id);
      _setMetricMeasurement(filteredMetrics[0].measurements[0].id);
      return;
    }

    // If the selected metric does not have the given measurement available, select the first available measurement
    if (measurement === undefined) {
      _setMetricOption(metric.id);
      _setMetricMeasurement(metric.measurements[0].id);
      return;
    }

    // Set the correct selected metric and measurement
    _setMetricOption(metric.id);
    _setMetricMeasurement(measurement.id);
  }, [value, filteredMetrics]);

  // Update the form when changing local state
  const setMetricOption = useCallback(
    (value: GoalFeatureMetric) => {
      const metric = filteredMetrics.find((m) => m.id === value);
      const measurement = metric?.measurements[0];

      if (metric && measurement) {
        _setMetricOption(metric.id);
        _setMetricMeasurement(measurement.id);
        onChange(measurement.id);
      }
    },
    [filteredMetrics, onChange],
  );

  // Update the form when changing local state
  const setMetricMeasurement = useCallback(
    (value: GoalFeatureMetric) => {
      _setMetricMeasurement(value);
      onChange(value);
    },
    [onChange],
  );

  const measurementIconColor = useToken("colors", "dimmed");

  const metricOptions = useMemo(
    () =>
      METRIC_CATEGORIES.map((category) => ({
        id: category.id,
        title: category.title,
        options: filteredMetrics
          .filter((metric) => metric.categoryId === category.id)
          .map((metric) => ({
            value: metric.id as GoalFeatureMetric,
            label: (
              <HStack minW={0}>
                {metric.icon}
                <Text as="span" isTruncated>
                  {metric.label}
                </Text>
              </HStack>
            ),
          })),
      })),
    [filteredMetrics],
  );

  return (
    <>
      <SimpleSelect
        ref={ref}
        disabled={disabled}
        options={metricOptions}
        size="sm"
        value={metricOption}
        w="full"
        onChange={(value) => setMetricOption(value as GoalFeatureMetric)}
      />

      {selectedMetric?.measurements &&
      selectedMetric.measurements.length > 1 ? (
        <RadioGroupList
          colorScheme="brand"
          isDisabled={disabled}
          options={
            selectedMetric?.measurements?.map((measurement) => ({
              value: measurement.id,
              label:
                measurement.type === "count" ? (
                  <HStack spacing={1}>
                    <Text>Count</Text>{" "}
                    <TbHash size={16} stroke={measurementIconColor} />
                  </HStack>
                ) : (
                  <HStack spacing={1}>
                    <Text>Percentage</Text>{" "}
                    <TbPercentage size={16} stroke={measurementIconColor} />
                  </HStack>
                ),
            })) ?? []
          }
          size="sm"
          value={metricMeasurement}
          onChange={(value) => setMetricMeasurement(value as GoalFeatureMetric)}
        />
      ) : null}
    </>
  );
});
