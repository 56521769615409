import { RiCircleFill, RiCircleLine } from "react-icons/ri";
import { Box, BoxProps } from "@chakra-ui/react";

import { FunnelState } from "@bucketco/shared/featureAPI";

import { useStarsStateColors } from "@/feature/hooks/useStarsStateColors";

type StarsFunnelStateVisualizationProps = Omit<BoxProps, "children"> & {
  state: FunnelState | "none";
};

export function StarsFunnelStateVisualization({
  state,
  ...rest
}: StarsFunnelStateVisualizationProps) {
  const color = useStarsStateColors(state);
  return (
    <Box color={color} fontSize="2xs" {...rest}>
      {state == "none" ? <RiCircleLine /> : <RiCircleFill />}
    </Box>
  );
}
