import { Flex, FlexProps, Heading, HeadingProps, Text } from "@chakra-ui/react";

import ErrorBoundary from "@/common/components/ErrorBoundary";

type HeaderLayoutProps = {
  title: string | React.ReactElement;
  actions?: React.ReactNode;
  children: React.ReactNode;
  isFixedViewport?: boolean;
};

export default function HeaderLayout({
  isFixedViewport = false,
  ...props
}: HeaderLayoutProps & Omit<FlexProps, "title">) {
  if (isFixedViewport) {
    return (
      <Flex flexDir="column" h="100vh">
        <Header {...props} />
        <Flex flexGrow={1} minH={0}>
          <ErrorBoundary>{props.children}</ErrorBoundary>
        </Flex>
      </Flex>
    );
  }

  return (
    <>
      <Header {...props} />
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </>
  );
}

function Header({
  title,
  actions,
  ...props
}: HeaderLayoutProps & Omit<FlexProps, "title">) {
  return (
    <Flex
      alignItems="center"
      backgroundColor="appBackground"
      borderBottom="1px solid"
      borderBottomColor="appBorder"
      gap={4}
      justifyContent="space-between"
      minH={16}
      px={6}
      {...props}
    >
      <Flex
        align="center"
        flexDirection="row"
        fontSize="xl"
        fontWeight="medium"
        gap={1.5}
        minW="0px"
      >
        {typeof title === "string" ? (
          <HeaderLayoutHeading>{title}</HeaderLayoutHeading>
        ) : (
          title
        )}
      </Flex>
      {actions ?? ""}
    </Flex>
  );
}

export function HeaderLayoutHeading({ children, ...props }: HeadingProps) {
  return (
    <Heading {...props} as="h1" fontSize="lg" fontWeight="medium">
      {children}
    </Heading>
  );
}

export function HeaderLayoutBreadcrumb({ children, ...props }: HeadingProps) {
  return (
    <Text {...props} as="span" color="dimmed" fontSize="md" fontWeight="normal">
      {children}
    </Text>
  );
}
