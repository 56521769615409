import { useRef } from "react";

// keeps track of the first time a value changes from one specific value to another
export function useFirstChangeFromTo<T>(
  value: T,
  from: T,
  to: T,
  fn: () => void,
) {
  const triggerNextRef = useRef<boolean | null>(null);

  if (value === from && triggerNextRef.current === null) {
    triggerNextRef.current = true;
  }

  if (value === to && triggerNextRef.current) {
    triggerNextRef.current = false;
    fn();
  }
}
