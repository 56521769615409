import { useFeatureViews } from "@/feature-legacy/data/useFeatureViews";

export function useFeatureView(viewId: string | null) {
  const { data, ...rest } = useFeatureViews();
  const featureView = data?.find((d) =>
    viewId === null ? d.isAllFeatureView : d.id === viewId,
  );

  return {
    data: featureView,
    ...rest,
  };
}
