import { HStack } from "@chakra-ui/react";

import {
  WidgetFeatureMetricConfiguration,
  WidgetFeatureMetrics,
} from "@bucketco/shared/widgetAPI";

import { SectionHeading } from "@/common/components/SectionHeading";
import { FeatureDisplay } from "@/feature/components/FeatureDisplay";
import { useFeatureName } from "@/feature/hooks/useFeatureName";
import { formatWidgetValue } from "@/widget/utils/format";

type Props = {
  configuration: WidgetFeatureMetricConfiguration;
  implicitConfiguration?: Partial<WidgetFeatureMetricConfiguration>;
  currentValue?: number | null;
};

export const WidgetHeader = ({
  configuration,
  implicitConfiguration = {},
  currentValue,
}: Props) => {
  const definition = WidgetFeatureMetrics[configuration.metric];
  const isPercentage = definition.type === "percentage";

  const featureName = useFeatureName(configuration.featureId);

  return (
    <HStack align="center" spacing={4}>
      {!implicitConfiguration.featureId && featureName && (
        <FeatureDisplay feature={featureName} fontWeight="normal" link />
      )}
      {definition && (
        <SectionHeading>
          {definition?.label}:{" "}
          {formatWidgetValue(
            currentValue ?? 0,
            isPercentage,
            configuration.metric,
          )}
        </SectionHeading>
      )}
    </HStack>
  );
};
