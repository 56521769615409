import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import {
  DataExportConfiguration,
  DataExportTypeType,
} from "@bucketco/shared/exportAPI";

import appQueryKeys from "@/app/data/appQueryKeys";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

export default function useDataExportConfigurationData(
  type?: DataExportTypeType,
) {
  const { appId, envId } = useCurrentEnv();
  return useQuery<DataExportConfiguration | null, AxiosError<ErrorResponse>>({
    queryKey: appQueryKeys.dataExportConfiguration(appId, envId, type),

    queryFn: () =>
      api
        .get<"/apps/:appId/export/:type">(`/apps/${appId}/export/${type}`, {
          params: { envId: envId! },
        })
        .then((res) => res.data),
    enabled: !!appId && !!envId && !!type,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
