import { Dependee, UIFilter } from "@bucketco/shared/filter";
import { UsersQueryType } from "@bucketco/shared/userAPI";

const qk = {
  bootstrap: ["bootstrap"] as const,

  root: (appId?: string, envId?: string) => [
    "apps",
    appId,
    "environments",
    envId,
  ],

  distinctEventNames: (appId?: string, envId?: string) =>
    [...qk.root(appId, envId), "distinctEventNames"] as const,

  users: (
    appId?: string,
    envId?: string,
    params?: Omit<UsersQueryType, "envId">,
  ) =>
    params
      ? ([...qk.root(appId, envId), "users", params] as const)
      : ([...qk.root(appId, envId), "users"] as const),

  companyAttributeValues: (
    appId?: string,
    envId?: string,
    params?: Record<string, string | undefined>,
  ) =>
    params
      ? ([...qk.root(appId, envId), "companyAttributeValues", params] as const)
      : ([...qk.root(appId, envId), "companyAttributeValues"] as const),

  eventAttributeValues: (
    appId?: string,
    envId?: string,
    entityValue?: string,
    attributeKey?: string,
    search?: string,
  ) =>
    [
      ...qk.root(appId, envId),
      "eventAttributeValues",
      entityValue,
      attributeKey,
      search,
    ] as const,

  eventAttributeNames: (appId?: string, envId?: string, eventName?: string) =>
    [...qk.root(appId, envId), "eventAttributeNames", eventName] as const,

  filterCheck: (appId?: string, dependee?: Dependee, filter?: UIFilter) =>
    filter
      ? (["apps", appId, "filter-check", dependee, filter] as const)
      : (["apps", appId, "filter-check", dependee] as const),
};

export default qk;
