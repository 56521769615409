import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";

import { FunnelStep } from "@bucketco/shared/featureAPI";
import { buildFeatureFunnelFilters } from "@bucketco/shared/filter";
import { CompaniesUrl, FeedbacksUrl } from "@bucketco/shared/urls";
import {
  WidgetFeatureMetricConfiguration,
  WidgetFeatureMetricConfigurationSchema,
  WidgetFeatureMetrics,
} from "@bucketco/shared/widgetAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import { Period, periodList } from "@/common/components/PeriodPicker";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import useAllSegment from "@/company/data/useAllSegment";
import { useFeatureHistoricalData } from "@/feature/data/useFeatureHistoricalData";
import { useTargetingParam } from "@/feature/hooks/useTargetingParam";
import { BaseWidgetProps } from "@/widget/components/types";
import { WidgetChart } from "@/widget/components/WidgetFeatureMetric/WidgetChart";
import { WidgetHeader } from "@/widget/components/WidgetFeatureMetric/WidgetHeader";
import { WidgetState } from "@/widget/components/WidgetFeatureMetric/WidgetState";
import { WidgetLayout } from "@/widget/components/WidgetLayout";
import { useWidgetUpdateMutation } from "@/widget/data/useWidgetMutations";
import { getCurrentValue, periodToDates } from "@/widget/utils/widget";

import { WidgetForm } from "./WidgetForm";

type Props = BaseWidgetProps<WidgetFeatureMetricConfiguration>;

export function WidgetFeatureMetric({
  widgetId,
  configuration,
  implicitConfiguration,
}: Props) {
  const { currentEnv } = useAuthContext();
  const [isEditing, setIsEditing] = useState(false);
  const [subsegment] = useSubsegmentParam(["Active"]);
  const [useTargeting] = useTargetingParam(true);
  const [period] = useSearchParam<Period>("period", {
    allowlist: periodList,
    fallback: "past30days",
  });
  const { startDate, endDate } = useMemo(() => periodToDates(period), [period]);

  const updateMutation = useWidgetUpdateMutation(widgetId);

  const form = useForm<WidgetFeatureMetricConfiguration>({
    resolver: zodResolver(WidgetFeatureMetricConfigurationSchema),
    mode: "onChange",
    defaultValues: {
      threshold: WidgetFeatureMetrics["adopted"].defaultThreshold,
      ...implicitConfiguration,
      ...configuration,
    },
  });

  const values = form.watch();

  const currentConfiguration = useMemo(
    () => (isEditing ? values : configuration),
    [configuration, isEditing, values],
  );

  const { data, status } = useFeatureHistoricalData(
    currentConfiguration.featureId,
    {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      metric: currentConfiguration.metric,
      subsegment: subsegment[0],
      useTargetingRules: useTargeting,
      useLegacyLogic: false,
    },
  );

  const currentValue = useMemo(() => {
    return getCurrentValue(data?.timeseries ?? []);
  }, [data]);
  const [subsegments] = useSubsegmentParam(["Active"]);
  const allSegment = useAllSegment();

  const filter = useMemo(() => {
    let step: FunnelStep | "satisfied";

    switch (currentConfiguration.metric) {
      case "tried":
      case "triedCount":
        step = "tried";
        break;
      case "adopted":
      case "adoptedCount":
        step = "adopted";
        break;
      case "retained":
      case "retainedCount":
        step = "retained";
        break;
      case "satisfied":
      case "satisfiedCount":
        step = "satisfied";
        break;
      default:
        return undefined;
    }

    const [, filter] = buildFeatureFunnelFilters(
      step,
      currentConfiguration.featureId,
      useTargeting,
      subsegments,
    );

    return filter;
  }, [currentConfiguration, useTargeting, subsegments]);

  const linkUrl = useMemo(() => {
    if (currentConfiguration.metric === "feedbackCount") {
      return FeedbacksUrl(currentEnv!, {
        featureFilter: currentConfiguration.featureId,
      });
    }

    if (filter) {
      return CompaniesUrl(currentEnv!, {
        filter,
        segmentId: allSegment?.id,
      });
    }

    return undefined;
  }, [filter, currentConfiguration, allSegment, currentEnv]);

  return (
    <WidgetLayout
      content={
        <WidgetState hasData={!!data?.timeseries.length} status={status}>
          <WidgetChart
            data={data?.timeseries ?? []}
            endDate={endDate.toDate()}
            isPercentage={
              WidgetFeatureMetrics[currentConfiguration.metric].type ===
              "percentage"
            }
            metric={currentConfiguration.metric}
            startDate={startDate.toDate()}
            threshold={currentConfiguration.threshold}
          />
          {linkUrl && (
            <Button as={Link} to={linkUrl} variant="outline">
              {currentConfiguration.metric === "feedbackCount"
                ? "See feedback"
                : "See companies"}
            </Button>
          )}
        </WidgetState>
      }
      form={
        <WidgetForm
          configuration={currentConfiguration}
          currentValue={currentValue}
          form={form}
          implicitConfiguration={implicitConfiguration}
          widgetMutation={updateMutation}
          onDone={(configuration) => {
            form.reset(configuration);
            setIsEditing(false);
          }}
        />
      }
      isEditing={isEditing}
      setIsEditing={(newIsEditing) => {
        if (!newIsEditing) form.reset(configuration);
        setIsEditing(newIsEditing);
      }}
      title={
        <WidgetHeader
          configuration={currentConfiguration}
          currentValue={currentValue}
          implicitConfiguration={implicitConfiguration}
        />
      }
      widgetId={widgetId}
    />
  );
}
