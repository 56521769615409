import React from "react";
import { Center, useColorModeValue } from "@chakra-ui/react";

export default function CenteredLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Center
      bg={useColorModeValue("white", "gray.900")}
      minH="100vh"
      px={6}
      py={12}
      width="100%"
    >
      {children}
    </Center>
  );
}
