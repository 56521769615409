import { useEffect, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import omit from "lodash/omit";
import { useSessionStorage } from "usehooks-ts";

import {
  ReleasePatchArgs,
  ReleasePatchSchema,
} from "@bucketco/shared/releaseAPI";

import EmptyState from "@/common/components/EmptyState";
import useApiForm from "@/common/hooks/useApiForm";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { releasesQueryKeys } from "@/release/data/releasesQueryKeys";
import { useReleaseData } from "@/release/data/useReleaseData";
import { useReleaseEditing } from "@/release/hooks/useReleaseEditing";
import { ReleaseDraft } from "@/release/types/types";

import { LegacyReleasePage } from "./LegacyReleasePage";
import { baseValues, releaseToPostArgs } from "./releaseFormTools";

export const LegacyEditReleasePage = ({ releaseId }: { releaseId: string }) => {
  const { appId } = useCurrentEnv();

  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [_, setEditing] = useReleaseEditing();
  const { data: release, isFetching, isError } = useReleaseData(releaseId);
  const [releaseDraft, setReleaseDraft] = useSessionStorage<
    ReleaseDraft | undefined
  >(`release:${releaseId}`, undefined);

  const values = useMemo(
    () => ({ ...releaseToPostArgs(release), ...omit(releaseDraft, ["id"]) }),
    // Intentionally ignore changes to releaseDraft to avoid overwriting the form
    // https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [release],
  );

  const { form, handleSubmit } = useApiForm(
    (data: ReleasePatchArgs) =>
      api
        .patch<"/apps/:appId/releases/:releaseId">(
          `/apps/${appId}/releases/${releaseId}`,
          data,
        )
        .then((res) => res.data.release),
    ReleasePatchSchema,
    {
      onSuccess(release) {
        setEditing(false);
        setReleaseDraft(undefined);
        form.reset(releaseToPostArgs(release));
        queryClient.invalidateQueries({
          queryKey: releasesQueryKeys.list(appId),
        });
        queryClient.invalidateQueries({
          queryKey: releasesQueryKeys.single(appId, release.id),
        });
      },
      onError() {
        toast({
          title: "Failed to update release details",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      },
    },
    {
      defaultValues: baseValues,
      values,
    },
  );

  useEffect(() => {
    const subscription = form.watch((value, { type }) => {
      // Won't catch appending or removing goals
      if (type === "change") setReleaseDraft({ id: releaseId, ...value });
    });
    return () => subscription.unsubscribe();
  }, [form, releaseId, setReleaseDraft]);

  return (
    <FormProvider {...form}>
      {isFetching || isError ? (
        <EmptyState
          action={
            <Button
              size="sm"
              variant="outline"
              onClick={() => {
                navigate(-1);
              }}
            >
              Go back
            </Button>
          }
          description="It looks like the release you're looking for doesn't exist or something went wrong while loading it."
          flexGrow={1}
          isLoading={isFetching}
          title="Could not load the release"
        />
      ) : (
        <LegacyReleasePage release={release} onSaved={handleSubmit} />
      )}
    </FormProvider>
  );
};
