import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";

import { SlackIntegrationType } from "@bucketco/shared/featureAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import PostToSlackNow from "@/common/components/PostToSlackNow";
import useDefaultSlackChannel from "@/common/hooks/useDefaultSlackChannel";
import useSlackChannels from "@/common/hooks/useSlackChannels";
import { SlackChannelAutocompleteSelect } from "@/global-settings/components/SlackChannelAutocompleteSelect";

const reports = [
  {
    reportType: "weeklyFeatureReport" as const,
    fieldName: "weeklyReport" as const,
    display: "Weekly report",
    description:
      "Get the feature report with current adoption metrics and chart in Slack every Monday.",
  },
  {
    reportType: "dailyFeatureReport" as const,
    fieldName: "dailyReport" as const,
    display: "Daily report",
    description:
      "Get a list of new companies entering the Using it state every day.",
  },
];

export default function FormSlackIntegration() {
  const { featureId } = useParams();
  const { currentEnv } = useAuthContext();

  const { data: slackChannels = [], isLoading } = useSlackChannels();

  const form = useFormContext<SlackIntegrationType>();
  const currentChannel = form.watch("channelId");

  useDefaultSlackChannel({
    slackChannels,
    currentChannel,
    preferredChannelId: currentEnv?.slackChannelId,
    cb: (channel) => {
      form.setValue("channelId", channel.id);
    },
  });

  return (
    <VStack alignItems="flex-start" maxW="compactForm" spacing={6} w="100%">
      <ManagedFormControl
        label="Slack Channel"
        name="channelId"
        render={({ field }) => (
          <SlackChannelAutocompleteSelect
            {...field}
            onChange={(channel) => field.onChange(channel?.id)}
          />
        )}
      />

      {reports.map((r) => (
        <FormControl
          key={r.fieldName}
          as={Flex}
          flexDirection="column"
          gap={2}
          id={r.fieldName}
          isDisabled={form.formState.isSubmitting || isLoading}
        >
          <Flex align="center" gap={4}>
            <Box>
              <FormLabel
                flexGrow={1}
                htmlFor={`slack-${r.fieldName}`}
                margin={0}
                padding={0}
              >
                {r.display}
              </FormLabel>
            </Box>
            <Box flexGrow={1}>
              <PostToSlackNow
                key={r.fieldName}
                channelId={currentChannel ?? ""}
                featureId={featureId!}
                isDisabled={
                  form.formState.isSubmitting || isLoading || !currentChannel
                }
                reportType={r.reportType}
              />
            </Box>
            <Box>
              <Switch
                {...form.register(r.fieldName)}
                colorScheme="brand"
                id={`slack-${r.fieldName}`}
              />
            </Box>
          </Flex>
          <Flex>
            <Box flexGrow={1} maxW="xs">
              <Text color="gray.500" fontSize="sm">
                {r.description}
              </Text>
            </Box>
          </Flex>
        </FormControl>
      ))}

      <FormControl
        as={Flex}
        flexDirection="column"
        gap={2}
        isDisabled={form.formState.isSubmitting || isLoading}
      >
        <Flex align="center" gap={4}>
          <Box>
            <FormLabel flexGrow={1} margin={0} padding={0}>
              Notify on user feedback
            </FormLabel>
          </Box>
          <Box flexGrow={1}></Box>
          <Box>
            <Switch
              {...form.register("feedbackNotification")}
              colorScheme="brand"
            />
          </Box>
        </Flex>
        <Flex>
          <Box flexGrow={1} maxW="xs">
            <Text color="gray.500" fontSize="sm">
              An immediate notification when a user leaves feedback on this
              feature
            </Text>
          </Box>
        </Flex>
      </FormControl>
    </VStack>
  );
}
