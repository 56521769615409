import {
  GoalFeatureMetric,
  GoalFeatureMetrics,
} from "@bucketco/shared/goalAPI";

/** Round a number to the nearest value keeping at most 2 decimal places. */
const roundUpToNearest = (value: number, nearest: number) => {
  return Math.round(Math.ceil(value / nearest) * nearest * 100) / 100;
};

/** Suggest a new goal threshold based on the current value, by default 20% higher. */
export const suggestedThreshold = (
  metric: GoalFeatureMetric,
  currentValue: number,
  scaleFactor = 1.2,
) => {
  const isPercentage = GoalFeatureMetrics[metric].type === "percentage";
  const scaledValue = currentValue * scaleFactor;
  const roundedValue = roundUpToNearest(scaledValue, isPercentage ? 0.1 : 5);
  const maxThreshold = GoalFeatureMetrics[metric].maxThreshold;
  return Math.min(
    Math.max(roundedValue, GoalFeatureMetrics[metric].defaultThreshold),
    maxThreshold,
  );
};
