import { Outlet, useParams } from "react-router-dom";
import { Box } from "@chakra-ui/react";

import { useFeatureData } from "@/feature/data/useFeatureData";
import FlagTargeting from "@/flags/components/FlagTargeting";

export default function FeatureTargeting() {
  const { featureId } = useParams();
  const { data: feature } = useFeatureData(featureId!);
  if (!feature) return null;
  return (
    <Box bg="appBackdrop" flexGrow={1} h="full">
      <FlagTargeting flagId={feature.flagId} />
      <Outlet />
    </Box>
  );
}
