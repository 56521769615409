import { Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";

import { getFraction } from "@bucketco/shared/utils/getFraction";

import { AuditChartDataItem } from "@/common/charts/components/AuditChart/types";
import { SimplePieChart } from "@/common/charts/components/SimplePieChart";
import useChartTokens from "@/common/charts/hooks/useChartTokens";

export function CellLabel({
  viewBox,
  id,
  value,
  onMouseEnter,
  data,
}: Record<string, any> & {
  data: AuditChartDataItem[];
}) {
  const subsegmentColor = useColorModeValue("teal.500", "teal.300");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const { colors, radii, fontSizes } = useChartTokens();

  const item = data.find((i) => i.id === id);
  if (item === undefined) return null;
  const maxW = 150;
  const maxH = item.subsegment ? 49 : 30;

  return (
    <foreignObject
      {...viewBox}
      height={`${maxH}px`}
      width={`${maxW}px`}
      x={viewBox.x - maxW / 2 + 7}
      y={viewBox.y + 15 + 5}
    >
      <Flex
        backgroundColor={colors.tooltipBg}
        borderColor={colors.tooltipBorder}
        borderRadius={radii.tooltip}
        borderWidth="1px"
        flexDirection="column"
        fontSize={fontSizes.tooltip}
        maxH={maxH}
        maxW={maxW}
        mx="auto"
        px={1.5}
        py={1}
        shadow="sm"
        textAlign="center"
        width="fit-content"
        onMouseEnter={(e) => {
          onMouseEnter(e); // Forward the MouseEvent to the dot element
        }}
      >
        <Text
          color={textColor}
          fontWeight="semibold"
          overflow="hidden"
          textDecoration="none"
          textOverflow="ellipsis"
          isTruncated
        >
          {value}
        </Text>
        {item.subsegment && (
          <HStack maxW="100%" mx="auto">
            <SimplePieChart
              color={subsegmentColor}
              value={
                getFraction(
                  item.subsegment.segmentCount,
                  item.subsegment.allCount,
                ) * 100
              }
            />
            <Text
              color={subsegmentColor}
              fontWeight="medium"
              overflow="hidden"
              textDecoration="none"
              textOverflow="ellipsis"
              isTruncated
            >
              {item.subsegment.name}
            </Text>
          </HStack>
        )}
      </Flex>
    </foreignObject>
  );
}
