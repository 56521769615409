import { CompanyFeature } from "@bucketco/shared/companyAPI";
import { FeatureListItem } from "@bucketco/shared/featureAPI";

type Feature = FeatureListItem | CompanyFeature;

export function useFeaturesSearch<T extends Feature>(
  features: T[],
  options: {
    enabled: boolean;
    predicate: (feature: T) => boolean;
  },
) {
  if (!options.enabled) return features;

  const isChildMatch = (f: T): boolean => {
    return (
      hasSubRows(f) &&
      f.subRows.some((r) => {
        return options.predicate(r) || isChildMatch(r);
      })
    );
  };

  return features
    .filter((feature) => options.predicate(feature) || isChildMatch(feature))
    .map((feature) => ({
      ...feature,
      subRows: hasSubRows(feature)
        ? feature.subRows.filter((s) => options.predicate(s) || isChildMatch(s))
        : undefined,
    }));
}

type FeatureListItemWithSubRows<T extends Feature> = T & {
  subRows: T[];
};

function hasSubRows<T extends Feature>(
  f: T,
): f is FeatureListItemWithSubRows<T> {
  return "subRows" in f && Array.isArray(f.subRows);
}
