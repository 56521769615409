import { ReactNode } from "react";

import { FeatureDetail, STARSMetrics } from "@bucketco/shared/featureAPI";

import pluralize from "@/common/utils/pluralize";
import { RelevantMetrics } from "@/feature/utils/relevantMetricsForFeature";

export type DescriptionMetric = {
  count: number;
  dropOff: number;
};

export type DescriptionMetrics = Record<
  Exclude<keyof STARSMetrics, "company">,
  DescriptionMetric
>;

type DescriptionsType = {
  triedDescription: ReactNode;
  adoptedDescription: ReactNode;
  retainedDescription: ReactNode;
  satisfiedDescription: ReactNode;
};

const common = ({ metrics }: { metrics?: DescriptionMetrics }) => {
  const relevantFeedback = metrics
    ? metrics.satisfied.count + metrics.satisfied.dropOff
    : 0;

  return {
    satisfiedDescription: !relevantFeedback
      ? "no feedback yet"
      : relevantFeedback >= 5
      ? `${relevantFeedback} company responses`
      : `only ${relevantFeedback} ${pluralize(
          "company response",
          relevantFeedback,
        )}`,
  };
};

// for event-based features
const eventBased = ({
  metrics,
  feature,
}: {
  feature: FeatureDetail;
  relevantMetrics: RelevantMetrics[];
  metrics?: DescriptionMetrics;
}): DescriptionsType => {
  let settings;
  switch (feature.adoptionEvaluationStrategy) {
    case "frequency":
      settings = {
        minCount: feature.adoptionStrategyFrequencyMinDaysCount,
        weeks: feature.adoptionWindowSizeInDays / 7,
        unit: "day",
        units: "days",
      };
      break;
    case "eventCount":
    default:
      settings = {
        minCount: feature.adoptionStrategyEventCountMinEventCount,
        weeks: feature.adoptionWindowSizeInDays / 7,
        unit: "event",
        units: "events",
      };
      break;
  }

  let adoptedDescription = `${settings.minCount}+ ${pluralize(
    settings.unit,
    settings.minCount ?? 0,
  )}`;

  const retainedDescription =
    adoptedDescription +
    ` within last ${settings.weeks} ${pluralize("week", settings.weeks)}`;

  adoptedDescription += ` within ${settings.weeks} ${pluralize(
    "week",
    settings.weeks,
  )}`;

  const triedDescription = "1+ events";

  return {
    ...common({ metrics }),
    triedDescription: triedDescription,
    adoptedDescription: adoptedDescription,
    retainedDescription: retainedDescription,
  };
};

// for attribute-based features
const attributeBased = ({
  metrics,
}: {
  metrics?: DescriptionMetrics;
}): DescriptionsType => ({
  ...common({ metrics }),
  triedDescription: null,
  adoptedDescription: "Criteria was met",
  retainedDescription: "Criteria is still met",
});

export function useFeatureDescriptions(
  feature: FeatureDetail,
  relevantMetrics: RelevantMetrics[],
  metrics?: DescriptionMetrics,
) {
  return feature?.source === "event"
    ? eventBased({ feature, relevantMetrics, metrics })
    : attributeBased({ metrics });
}
