import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import {
  FormElementBaseProps,
  useManagedFormControlProps,
} from "@/common/hooks/useManagedFormControlProps";
import { mergeFunctions } from "@/common/utils/mergeFunctions";
import {
  GoalMetricSelector,
  GoalMetricSelectorProps,
} from "@/release/components/GoalMetricSelector";

export type FormGoalMetricSelector = FormElementBaseProps &
  Omit<GoalMetricSelectorProps, "value">;

export default function FormGoalMetricSelector({
  _control,
  name,
  onChange,
  ...rest
}: FormGoalMetricSelector) {
  const [managedProps, selectorProps] = useManagedFormControlProps(rest);
  return (
    <ManagedFormControl
      {..._control}
      {...managedProps}
      name={name}
      render={({ field }) => (
        <GoalMetricSelector
          {...selectorProps}
          {...field}
          onChange={mergeFunctions(field.onChange, onChange)}
        />
      )}
    />
  );
}
