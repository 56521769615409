import { HStack } from "@chakra-ui/react";

import { GoalDetailsDTO } from "@bucketco/shared/goalAPI";

import GoalDescription from "@/release/components/GoalDescription";
import { GoalStatusIcon } from "@/release/components/GoalStatusIcon";

function GoalDisplay({ goal }: { goal: GoalDetailsDTO }) {
  return (
    <HStack justify="start" w="full">
      <GoalStatusIcon
        progress={goal.progress}
        progressIndeterminate={goal.progressIndeterminate}
        showPercentage={false}
        size={14}
        status={goal.status}
      />
      <GoalDescription goal={goal} colored showThreshold />
    </HStack>
  );
}

export default GoalDisplay;
