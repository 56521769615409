import { useQuery } from "@tanstack/react-query";

import { FeatureCompaniesQueryType } from "@bucketco/shared/featureAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

import featureQueryKeys from "./featureQueryKeys";

export default function useFeatureCompaniesData(
  featureId: string | undefined,
  params: Omit<FeatureCompaniesQueryType, "envId">,
  enabled = true,
) {
  const { appId, envId } = useCurrentEnv();
  return useQuery({
    queryKey: featureQueryKeys.singleFeatureCompaniesWithParams(
      appId,
      envId,
      featureId,
      params,
    ),

    queryFn: () =>
      api
        .get<"/apps/:appId/features/:featureId/companies">(
          `/apps/${appId}/features/${featureId}/companies`,
          {
            params: { ...params, envId: envId! },
          },
        )
        .then((res) => res.data),
    enabled: enabled && !!appId && !!envId && !!featureId,
  });
}
