import { z } from "zod";

import { nullablePartial } from "./utils/types";
import { APIEmptyResponse, APIResponse } from "./api";
import { FeatureMetric, FeatureMetricSchema } from "./featureAPI";

// Configuration
export const WidgetFeatureMetrics: Record<
  FeatureMetric,
  {
    label: string;
    type: "percentage" | "count";
    restriction: "event-based" | "none";
    defaultThreshold: number;
    maxThreshold: number;
  }
> = {
  tried: {
    label: "Tried",
    type: "percentage",
    restriction: "event-based",
    defaultThreshold: 0.25,
    maxThreshold: 1,
  },
  adopted: {
    label: "Adopted",
    type: "percentage",
    restriction: "none",
    defaultThreshold: 0.25,
    maxThreshold: 1,
  },
  retained: {
    label: "Retained",
    type: "percentage",
    restriction: "none",
    defaultThreshold: 0.25,
    maxThreshold: 1,
  },
  satisfied: {
    label: "Satisfied",
    type: "percentage",
    restriction: "none",
    defaultThreshold: 0.25,
    maxThreshold: 1,
  },
  adoptionRate: {
    label: "Adoption rate",
    type: "percentage",
    restriction: "none",
    defaultThreshold: 0.5,
    maxThreshold: 1,
  },
  retentionRate: {
    label: "Retention rate",
    type: "percentage",
    restriction: "none",
    defaultThreshold: 0.5,
    maxThreshold: 1,
  },
  satisfactionRate: {
    label: "Satisfaction rate",
    type: "percentage",
    restriction: "none",
    defaultThreshold: 0.5,
    maxThreshold: 1,
  },
  triedCount: {
    label: "Tried",
    type: "count",
    restriction: "event-based",
    defaultThreshold: 10,
    maxThreshold: Infinity,
  },
  adoptedCount: {
    label: "Adopted",
    type: "count",
    restriction: "none",
    defaultThreshold: 10,
    maxThreshold: Infinity,
  },
  retainedCount: {
    label: "Retained",
    type: "count",
    restriction: "none",
    defaultThreshold: 10,
    maxThreshold: Infinity,
  },
  satisfiedCount: {
    label: "Satisfied",
    type: "count",
    restriction: "none",
    defaultThreshold: 10,
    maxThreshold: Infinity,
  },
  averageFrequency: {
    label: "Frequency",
    type: "count",
    restriction: "none",
    defaultThreshold: 2,
    maxThreshold: 3,
  },
  feedbackCount: {
    label: "Feedback",
    type: "count",
    restriction: "none",
    defaultThreshold: 10,
    maxThreshold: Infinity,
  },
};

export const WidgetConfigurationTypes = [
  "featureMetric",
  "starsFunnel",
  "feedback",
] as const;
export type WidgetConfigurationType = (typeof WidgetConfigurationTypes)[number];

export const WidgetBaseConfigurationSchema = z
  .object({
    version: z.enum(["1"]).default("1"),
  })
  .strict();

export const WidgetFeatureMetricConfigurationSchema =
  WidgetBaseConfigurationSchema.extend({
    type: z.literal("featureMetric"),
    featureId: z.string().length(14, "The widget needs a feature"),
    metric: FeatureMetricSchema,
    threshold: z.number().nullish(),
  }).strict();
export type WidgetFeatureMetricConfiguration = z.infer<
  typeof WidgetFeatureMetricConfigurationSchema
>;

export const WidgetSTARSFunnelConfigurationSchema =
  WidgetBaseConfigurationSchema.extend({
    type: z.literal("starsFunnel"),
    featureId: z.string().length(14, "The widget needs a feature"),
  }).strict();
export type WidgetSTARSFunnelConfiguration = z.infer<
  typeof WidgetSTARSFunnelConfigurationSchema
>;

export const WidgetFeedbackConfigurationSchema =
  WidgetBaseConfigurationSchema.extend({
    type: z.literal("feedback"),
    featureId: z.string().length(14, "The widget needs a feature"),
  }).strict();
export type WidgetFeedbackConfiguration = z.infer<
  typeof WidgetFeedbackConfigurationSchema
>;

export const WidgetConfigurationSchema = z.discriminatedUnion("type", [
  WidgetFeatureMetricConfigurationSchema,
  WidgetSTARSFunnelConfigurationSchema,
  WidgetFeedbackConfigurationSchema,
]);
export type WidgetConfiguration = z.infer<typeof WidgetConfigurationSchema>;

// Post & Patch DTO

export const WidgetPostSchema = z
  .object({
    configuration: WidgetConfigurationSchema,
  })
  .strict();
export type WidgetPostArgs = z.input<typeof WidgetPostSchema>;

export const WidgetPatchSchema = WidgetPostSchema.partial();
export type WidgetPatchArgs = z.input<typeof WidgetPatchSchema>;

// Query
export const WidgetsFilterQuerySchema = nullablePartial(
  WidgetFeatureMetricConfigurationSchema.merge(
    WidgetSTARSFunnelConfigurationSchema,
  ).merge(WidgetFeedbackConfigurationSchema),
);
export type WidgetsFilterQuery = z.input<typeof WidgetsFilterQuerySchema>;

// Model

export type WidgetDTO = {
  id: string;
  order: number;
  configuration: WidgetConfiguration;
};

// API

export interface WidgetAPI {
  "/apps/:appId/widgets": {
    POST: {
      body: WidgetPostArgs;
      response: APIResponse<{
        widget: WidgetDTO;
      }>;
      params: { appId: string };
    };
    GET: {
      response: APIResponse<{
        widgets: WidgetDTO[];
      }>;
      params: { appId: string };
      query: WidgetsFilterQuery;
    };
  };
  "/apps/:appId/widgets/:widgetId": {
    GET: {
      response: APIResponse<{
        widget: WidgetDTO;
      }>;
      params: { appId: string; widgetId: string };
    };
    PATCH: {
      body: WidgetPatchArgs;
      response: APIResponse<{
        widget: WidgetDTO;
      }>;
      params: { appId: string; widgetId: string };
    };
    DELETE: {
      response: APIEmptyResponse;
      params: { appId: string; widgetId: string };
    };
  };
}
