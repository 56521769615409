import React from "react";
import { useParams } from "react-router-dom";
import { Grid, GridItem, Progress, Text } from "@chakra-ui/react";
import capitalize from "lodash/capitalize";

import { frequencyNumberToText } from "@bucketco/shared/featureAPI";

import CompactNumber from "@/common/components/CompactNumber";
import PercentageNumber from "@/common/components/PercentageNumber";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import { ChartWrapper } from "@/feature-legacy/components/ChartWrapper";
import useCurrentMetricsData from "@/feature-legacy/data/useCurrentMetricsData";

export default function FrequencyHistogram() {
  const { featureId } = useParams();
  const [subsegment] = useSubsegmentParam();
  const { data, isLoading } = useCurrentMetricsData(featureId, {
    subsegment: subsegment[0],
  });

  const histogram = data?.metrics.frequencyHistogram;
  const histogramOrder = [3, 2, 1, 0];

  let total = 0;
  for (const key in histogram) {
    total += histogram[parseInt(key)];
  }

  return (
    <ChartWrapper isLoading={isLoading || !histogram} title="Distribution">
      <Text color="dimmed" fontSize="sm">
        Frequency distribution for retained companies
      </Text>
      <Grid
        alignItems="center"
        fontSize="sm"
        gap={3}
        maxW="xs"
        rowGap={4}
        templateColumns="min-content 1fr min-content min-content"
      >
        {histogram &&
          histogramOrder.map((frequency) => {
            return (
              <React.Fragment key={`frq-${frequency}`}>
                <Text>{capitalize(frequencyNumberToText(frequency))}</Text>

                <Progress
                  color="brand.500"
                  height="6px"
                  max={1}
                  min={0}
                  value={total != 0 ? histogram[frequency] / total : 0}
                />
                <GridItem textAlign="right">
                  <PercentageNumber
                    value={total != 0 ? histogram[frequency] / total : 0}
                  />
                </GridItem>

                <GridItem color="dimmed" textAlign="right">
                  <CompactNumber value={histogram[frequency]} />
                </GridItem>
              </React.Fragment>
            );
          })}
      </Grid>
    </ChartWrapper>
  );
}
