import { ReactNode, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

import {
  CreateFeatureArgsSchema,
  CreateFeatureArgsType,
  FeatureDetail,
} from "@bucketco/shared/featureAPI";
import { Feature2Url, FeatureUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import { FormRootError } from "@/common/components/form/FormRootError";
import FormSubmit from "@/common/components/form/FormSubmit";
import ModalCancelButton from "@/common/components/ModalCancelButton";
import useApiForm from "@/common/hooks/useApiForm";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { useSegments } from "@/company/data/useSegments";
import { CreateAffectEnvironments } from "@/environment/components/EnvironmentCallouts";
import { NewFeatureForm } from "@/feature-legacy/components/NewFeatureForm";
import featureQueryKeys from "@/feature-legacy/data/featureQueryKeys";
import useFeatureData from "@/feature-legacy/data/useFeatureData";
import { useFeedbackPromptingStatus } from "@/global-settings/data/useFeedbackPromptingStatus";

const DEFAULT_SOURCE = "event";

export default function NewFeature({
  duplicate,
  isOpen = true,
  footerDetails,
  onClose,
  onSuccess,
}: {
  duplicate?: boolean;
  isOpen?: boolean;
  footerDetails?: ReactNode;
  onClose?: () => void;
  onSuccess?: (feature: FeatureDetail) => void;
}) {
  const { featureId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { data: feature, isLoading } = useFeatureData(
    duplicate ? featureId : undefined,
  );

  const { isEnabled: featureRevamp } = useFeature("feature-revamp-2024");

  useEffect(() => {
    if (!isOpen) return;
    segmentAnalytics.track(
      `${duplicate ? "Duplicate" : "Create"} Feature Opened`,
    );
  }, [duplicate, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      size={featureRevamp ? "lg" : "2xl"}
      trapFocus={false} // This is necessary to be able to focus the textarea within the feedback widget
      onClose={() => {
        if (onClose) {
          onClose();
        } else {
          navigate({
            pathname: "..",
            search: searchParams.toString(),
          });
        }
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <VStack align="start">
            <Heading>New feature</Heading>
            {duplicate && !isLoading && (
              <Heading as="h3" color="dimmed" size="sm">
                Settings copied from feature &quot;
                {feature?.name}&quot;
              </Heading>
            )}
          </VStack>
        </ModalHeader>
        <ModalCloseButton />
        {duplicate && isLoading ? (
          <ModalBody
            alignItems="center"
            display="flex"
            justifyContent="center"
            minH={24}
          >
            <Spinner />
          </ModalBody>
        ) : (
          <NewFeatureBody
            feature={duplicate ? feature : undefined}
            footerDetails={footerDetails}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export function NewFeatureBody({
  feature,
  footerDetails,
  onClose,
  onSuccess,
}: {
  feature?: FeatureDetail;
  footerDetails?: ReactNode;
  onClose?: () => void;
  onSuccess?: (feature: FeatureDetail) => void;
}) {
  const { currentApp, currentEnv } = useAuthContext();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const cancelHoverColor = useColorModeValue("gray.700", "gray.300");

  // given that we fetch segments as part of bootstrapping on app load, segments
  // will always be available instantly in react-query cache here. this is potentially brittle though
  // and can result in race conditions if we ever change the bootstrapping process
  const { data: segments = [] } = useSegments();
  const activeSegment = segments.find((s) => s.system && s.name === "Active");
  const allSegment = segments.find((s) => s.isAllSegment);

  const status = useFeedbackPromptingStatus();
  const isAutoFeedbackSurveys = !!status.data?.configured;
  const hasFeatureMergeFlag = useFeature("feature-revamp-2024").isEnabled;
  const featureKeyEventSelector = hasFeatureMergeFlag;

  const { form, handleSubmit } = useApiForm(
    async (formData: CreateFeatureArgsType) => {
      const { segmentId, ...creationData } = formData;

      const realSegmentId = segmentId === allSegment?.id ? null : segmentId;

      return api
        .post<"/apps/:appId/features">(
          `/apps/${currentApp?.id}/features`,
          {
            segmentId: realSegmentId,
            ...creationData,
          },
          { params: { envId: currentEnv!.id } },
        )
        .then((response) => response.data.feature);
    },
    CreateFeatureArgsSchema,
    {
      onSuccess: (feature, formValues) => {
        queryClient.invalidateQueries({
          queryKey: featureQueryKeys.listNames(currentApp?.id),
        });
        queryClient.setQueryData(
          featureQueryKeys.single(currentApp?.id, feature.id),
          feature,
        );
        queryClient.setQueryData(
          featureQueryKeys.singleEnv(
            currentApp?.id,
            currentEnv?.id,
            feature.id,
          ),
          feature,
        );

        queryClient.invalidateQueries({
          queryKey: featureQueryKeys.list(currentApp?.id),
          exact: true,
        });

        const selectedSegment = formValues.segmentId
          ? segments.find((s) => s.id === formValues.segmentId)
          : null;

        const segmentTrackingId = selectedSegment?.system
          ? selectedSegment.name.toLowerCase()
          : "custom";

        segmentAnalytics.track("Feature Created", {
          source: feature.source,
          feature_views: (formValues.featureViews ?? []).length,
          segment: segmentTrackingId,
        });
        if (onSuccess) {
          onSuccess(feature);
        } else {
          navigate(
            hasFeatureMergeFlag
              ? Feature2Url(currentEnv!, feature)
              : FeatureUrl(currentEnv!, feature),
          );
        }
      },
    },
    {
      mode: "onChange",
      shouldUnregister: false,
      defaultValues: {
        source:
          feature?.source ??
          (searchParams.get("source") as "event" | "attribute" | null) ??
          DEFAULT_SOURCE,
        name: feature?.name ?? "",
        featureViews: feature?.featureViews.map(({ id }) => id) ?? [],
        segmentId: feature?.segment?.id ?? activeSegment?.id ?? null,
        customEventSelectors:
          feature && "customEventSelectors" in feature
            ? feature.customEventSelectors
            : featureKeyEventSelector
            ? []
            : [{ name: "", filter: [] }],
        usingItAttributeFilter: feature
          ? feature.usingItAttributeFilter || []
          : [],

        feedbackCampaign: {
          enabled: false,
          question: "",
        },
      },
    },
  );

  useEffect(() => {
    if (!status.isLoading) {
      form.setValue("feedbackCampaign.enabled", isAutoFeedbackSurveys);
    }
  }, [form, isAutoFeedbackSurveys, status.isLoading]);

  // append or increment number on feature name if duplicate
  useEffect(() => {
    if (feature) {
      form.setValue(
        "name",
        feature.name.match(/\(\d+\)\s*$/)
          ? feature.name.replace(/\((\d+)\)\s*$/, (_, copy) => `(${+copy + 1})`)
          : `${feature.name} (1)`,
        { shouldDirty: true },
      );
    }
  }, [feature, form]);

  return (
    <FormProvider {...form}>
      <ModalBody>
        <NewFeatureForm
          id="new-feature"
          showAutoFeedbackSurveys={isAutoFeedbackSurveys}
          onSubmit={handleSubmit}
        />
        <FormRootError />
        <CreateAffectEnvironments entity="features" mt={8} />
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        {typeof footerDetails === "string" ? (
          <Text color="dimmed" fontStyle="italic">
            {footerDetails}
          </Text>
        ) : (
          <Box>{footerDetails}</Box>
        )}
        <ButtonGroup>
          {onClose ? (
            <Button
              _hover={{ color: cancelHoverColor }}
              color="gray.500"
              type="button"
              variant="ghost"
              onClick={onClose}
            >
              Cancel
            </Button>
          ) : (
            <ModalCancelButton />
          )}
          <FormSubmit form="new-feature">Create</FormSubmit>
        </ButtonGroup>
      </ModalFooter>
    </FormProvider>
  );
}
