import { Controller, FormProvider, UseFormReturn } from "react-hook-form";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import {
  ButtonGroup,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
  useUpdateEffect,
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { CompanyFeature } from "@bucketco/shared/companyAPI";
import {
  CreateFeedbackArgsSchema,
  FeedbackDTO,
} from "@bucketco/shared/feedbackAPI";

import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import AutocompleteSelect from "@/common/components/AutocompleteSelect";
import { CompanyAutocompleteSelect } from "@/common/components/CompanyAutocompleteSelect";
import { FormRootError } from "@/common/components/form/FormRootError";
import FormSubmit from "@/common/components/form/FormSubmit";
import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import ModalCancelButton from "@/common/components/ModalCancelButton";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import { UserAutocompleteSelect } from "@/common/components/UserAutocompleteSelect";
import useApiForm from "@/common/hooks/useApiForm";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import companyQueryKeys from "@/company/data/companyQueryKeys";
import FeatureDisplay from "@/feature-legacy/components/FeatureDisplay";
import { FormSatisfactionInput } from "@/feature-legacy/components/FeedbackSatisfaction";
import feedbackQueryKeys from "@/feedback/data/feedbackQueryKeys";
import { starsFunnelStateDescriptions } from "../data/starsFunnelDescriptions";

type Props = {
  handleSubmit: () => void;
  form: UseFormReturn<FeedbackFormType>;
  submitLabel?: "Add" | "Update";
  isLoading?: boolean;
  showCompanyPicker?: boolean;
  showFeaturePicker?: boolean;
  includedCompanies?: { id: string; name: string }[];
};

function FeedbackForm({
  submitLabel = "Add",
  handleSubmit,
  form,
  isLoading = false,
  showCompanyPicker = true,
  showFeaturePicker = true,
}: Props) {
  const { envId, appId } = useCurrentEnv();
  const navigate = useNavigate();

  const isDisabled = form.formState.isSubmitting || isLoading;

  const { companyId, featureId } = form.watch();

  const { data: features = [] } = useQuery<CompanyFeature[]>({
    queryKey: companyQueryKeys.singleFeatures(appId, envId, companyId),
    queryFn: async () => {
      const res = await api.get<"/apps/:appId/companies/:companyId/features">(
        `/apps/${appId}/companies/${encodeURIComponent(companyId!)}/features`,
        { params: { envId: envId! } },
      );

      return res.data.data;
    },
    enabled: showFeaturePicker && !!appId && !!envId && !!companyId,
  });

  return (
    <Modal
      size="md"
      isOpen
      onClose={() => {
        form.reset();
        closeModal(navigate);
      }}
    >
      <ModalOverlay />
      <form onSubmit={handleSubmit}>
        <ModalContent>
          <FormProvider {...form}>
            <ModalHeader>
              <HStack spacing={3}>
                <Heading size="lg">{submitLabel} feedback</Heading>
                <AnimatedSpinner show={isLoading} size="sm" />
              </HStack>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex flexDirection="column" gap={6}>
                {showCompanyPicker && (
                  <ManagedFormControl
                    isDisabled={form.formState.isSubmitting}
                    label="Company"
                    name="companyId"
                    render={({ field }) => {
                      return (
                        <CompanyAutocompleteSelect
                          {...field}
                          value={field.value ? { id: field.value } : undefined}
                          onChange={(selected) => {
                            field.onChange(selected?.id ?? "");
                          }}
                        />
                      );
                    }}
                  />
                )}

                {showFeaturePicker && (
                  <FormControl
                    id="featureName"
                    isDisabled={form.formState.isSubmitting || !companyId}
                  >
                    <FormLabel fontSize="md">Feature</FormLabel>
                    <AutocompleteSelect
                      itemFilterFn={(f, search) => {
                        return (
                          f.name
                            ?.toLocaleLowerCase()
                            ?.includes(search.toLocaleLowerCase()) ||
                          f.id
                            .toLocaleLowerCase()
                            .includes(search.toLocaleLowerCase())
                        );
                      }}
                      itemKeyProperty="id"
                      itemRenderfn={(f, search) => {
                        const description = starsFunnelStateDescriptions.find(
                          ({ id }) => id === f.funnelStep,
                        );

                        return (
                          <Flex
                            alignItems="center"
                            gap={1}
                            justifyContent="space-between"
                            py={0.5}
                            w="full"
                          >
                            <FeatureDisplay
                              feature={f}
                              highlightText={search}
                              size="sm"
                            />
                            <Flex>
                              {description ? (
                                <HStack spacing={2}>
                                  {description.visualization}
                                  <Text>{description.label}</Text>
                                </HStack>
                              ) : (
                                <NotAvailableCell />
                              )}
                            </Flex>
                          </Flex>
                        );
                      }}
                      placeholder="Signed In"
                      suggestions={features}
                      value={features.find((f) => f.id === featureId)}
                      showClearButton
                      onChange={(selected) => {
                        form.setValue("featureSearch", "");
                        form.setValue("featureId", selected?.id ?? "", {
                          shouldValidate: true,
                          shouldDirty: true,
                          shouldTouch: true,
                        });
                      }}
                      onClear={() => {
                        form.setValue("featureSearch", "");
                      }}
                      onSearchInput={(search) => {
                        form.setValue("featureSearch", search);
                      }}
                    />
                    <FormErrorMessage>
                      <ErrorMessage name="featureSearch" />
                    </FormErrorMessage>
                  </FormControl>
                )}

                <ManagedFormControl
                  isDisabled={form.formState.isSubmitting}
                  label="User"
                  name="userId"
                  render={({ field }) => (
                    <UserAutocompleteSelect
                      {...field}
                      query={{ companyIdFilter: companyId }}
                    />
                  )}
                />

                <FormControl
                  id={"score"}
                  isDisabled={isDisabled}
                  isInvalid={Boolean(form.getFieldState("score").error)}
                >
                  <FormLabel>Score</FormLabel>

                  <FormControl
                    isDisabled={isDisabled}
                    isInvalid={Boolean(form.getFieldState("comment").error)}
                  >
                    <Controller
                      name="score"
                      render={({ field }) => (
                        <FormSatisfactionInput
                          {...field}
                          value={String(field.value)}
                          onChange={(value) => {
                            field.onChange(value ? Number(value) : null);
                          }}
                        />
                      )}
                    />
                  </FormControl>

                  <FormErrorMessage>
                    <ErrorMessage name="score" />
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  id={"comment"}
                  isDisabled={isDisabled}
                  isInvalid={Boolean(form.getFieldState("comment").error)}
                >
                  <FormLabel>Comment (optional)</FormLabel>

                  <Textarea size={"sm"} {...form.register("comment")} />

                  <FormErrorMessage>
                    <ErrorMessage name="comment" />
                  </FormErrorMessage>
                </FormControl>

                {submitLabel === "Add" ? (
                  <FormControl>
                    <Checkbox
                      {...form.register("sendToSlack")}
                      colorScheme="brand"
                    >
                      Send to Slack
                    </Checkbox>
                  </FormControl>
                ) : null}

                <FormErrorMessage>
                  <ErrorMessage name="_form" />
                </FormErrorMessage>

                <FormRootError />
              </Flex>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <ModalCancelButton />
                <FormSubmit>{submitLabel}</FormSubmit>
              </ButtonGroup>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </form>
    </Modal>
  );
}

const feedbackFormSchema = CreateFeedbackArgsSchema.merge(
  z.object({
    companyId: z.string().min(1, "A company is required"),
    featureId: z.string().min(1, "A feature is required"),
    userId: z.string().min(1).nullable().optional(),
    featureSearch: z.string().optional(),
    _form: z.any(),
  }),
).refine(({ score, comment }) => Boolean(score || comment), {
  path: ["_form"],
  message: "Either score or comment must be defined",
});

type FeedbackFormType = z.infer<typeof feedbackFormSchema>;

function EditFeedbackForm({ feedback }: { feedback: FeedbackDTO }) {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [queryFeatureId] = useSearchParam("featureId");
  const { featureId: paramsFeatureId, companyId, feedbackId } = useParams();

  const { envId, appId } = useCurrentEnv();

  const featureId = paramsFeatureId || queryFeatureId;

  const { form, handleSubmit } = useApiForm<FeedbackFormType, FeedbackDTO>(
    ({
      companyId,
      featureId,
      userId,
      comment,
      score,
      sendToSlack,
    }: FeedbackFormType) =>
      api
        .patch<"/apps/:appId/feedbacks/:feedbackId">(
          `/apps/${appId}/feedbacks/${feedbackId}`,
          {
            featureId,
            companyId,
            userId: userId || null,
            comment: comment || null,
            score: score || null,
            sendToSlack,
          },
          { params: { envId: envId! } },
        )
        .then((res) => res.data),
    feedbackFormSchema,
    {
      onSuccess: async () => {
        segmentAnalytics.track("Feedback Updated");
        await queryClient.invalidateQueries({
          queryKey: feedbackQueryKeys.list(appId, envId),
        });
        closeModal(navigate);
      },
    },
    {
      mode: "onChange",
      defaultValues: {
        companyId: feedback.companyId ?? "",
        featureId: feedback.featureId ?? "",
        userId: feedback.userId ?? null,
        featureSearch: "",
        score: feedback.score ?? null,
        comment: feedback.comment ?? "",
        sendToSlack: false,
      },
    },
  );

  const score = form.watch("score");
  const comment = form.watch("comment");

  useUpdateEffect(() => {
    form.trigger("_form");
  }, [form, score, comment]);

  return (
    <FeedbackForm
      form={form}
      handleSubmit={handleSubmit}
      showCompanyPicker={!companyId}
      showFeaturePicker={!featureId}
      submitLabel="Update"
    />
  );
}

export function EditFeedback() {
  const { feedbackId } = useParams();

  const { envId, appId } = useCurrentEnv();

  const feedbackQuery = useQuery({
    queryKey: feedbackQueryKeys.single(appId, envId, feedbackId),

    queryFn: () =>
      api
        .get<`/apps/:appId/feedbacks/:feedbackId`>(
          `/apps/${appId}/feedbacks/${feedbackId}`,
          { params: { envId: envId! } },
        )
        .then((res) => res.data),
    enabled: !!envId && !!envId && !!feedbackId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });

  if (feedbackQuery.isSuccess) {
    return <EditFeedbackForm feedback={feedbackQuery.data} />;
  }

  if (feedbackQuery.isLoading) {
    return null;
  }

  if (feedbackQuery.isError) {
    // TODO
  }
}

export function NewFeedback() {
  const navigate = useNavigate();

  const toast = useToast();
  const errorToast = useErrorToast();
  const queryClient = useQueryClient();

  const { featureId, companyId } = useParams();
  const [queryFeatureId] = useSearchParam("featureId");

  const { envId, appId } = useCurrentEnv();

  const { form, handleSubmit } = useApiForm<FeedbackFormType, FeedbackDTO>(
    ({
      companyId,
      featureId,
      userId,
      comment,
      score,
      sendToSlack,
    }: FeedbackFormType) =>
      api
        .post<"/apps/:appId/feedbacks">(
          `/apps/${appId}/feedbacks`,
          {
            featureId,
            companyId,
            userId: userId || null,
            comment: comment || null,
            score: score || null,
            sendToSlack,
          },
          { params: { envId: envId! } },
        )
        .then((res) => res.data),
    feedbackFormSchema,
    {
      onSuccess: async () => {
        segmentAnalytics.track("Feedback Created");
        await queryClient.invalidateQueries({
          queryKey: feedbackQueryKeys.list(appId, envId),
        });
        closeModal(navigate);
        toast({
          title: "Feedback sent!",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      },
      onError: () => {
        errorToast({
          description: "Couldn't send feedback",
        });
      },
    },
    {
      mode: "onChange",
      defaultValues: {
        companyId: companyId || "",
        featureId: featureId || queryFeatureId || "",
        userId: null,
        featureSearch: "",
        score: null,
        sendToSlack: false,
      },
    },
  );

  const score = form.watch("score");
  const comment = form.watch("comment");

  useUpdateEffect(() => {
    form.trigger("_form");
  }, [form, score, comment]);

  return (
    <FeedbackForm
      form={form}
      handleSubmit={handleSubmit}
      showCompanyPicker={!companyId}
      showFeaturePicker={!featureId}
      submitLabel="Add"
    />
  );
}

function closeModal(navigate: NavigateFunction) {
  // Clear the featureId search param when closing
  const search = new URLSearchParams(window.location.search);
  search.delete("featureId");

  navigate({
    pathname: "..",
    search: search.toString(),
  });
}
