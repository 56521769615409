import { MiddlewareFunction } from "@segment/analytics-next";

let allowTracking = true;

export function setAllowTracking(isAllowed: boolean) {
  allowTracking = isAllowed;
}

/**
 * Block all tracking calls when `window.allowtracking === false`.
 *
 * The app might want to set this in case admin users are
 * causing trakcing on orgs that they are not a member of.
 */
export const blockNonMemberTracking: MiddlewareFunction = ({
  payload,
  next,
}) => {
  if (allowTracking) {
    next(payload);
  }
};
