import { useQuery } from "@tanstack/react-query";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import featureQueryKeys from "@/feature-legacy/data/featureQueryKeys";

export default function useFeatureHasData(featureId?: string) {
  const { appId, envId } = useCurrentEnv();

  return useQuery({
    queryKey: featureQueryKeys.singleFeatureHasData(appId, envId, featureId),

    queryFn: () =>
      api
        .get<"/apps/:appId/features/:featureId/processing-status">(
          `/apps/${appId}/features/${featureId}/processing-status`,
          { params: { envId: envId! } },
        )
        .then((res) => res.data),
    enabled: !!appId && !!envId && !!featureId,
    staleTime: Infinity,
    refetchInterval(query) {
      if (query.state.data?.status === "no-data") return 5000;
      if (query.state.data?.status === "bootstrapping") return 500;
      if (query.state.data?.historicalDataProcessed === false) return 5000;
      return false;
    },
  });
}
