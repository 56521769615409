import { useParams } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import { useLocalStorage } from "usehooks-ts";

import { FeatureWidgets } from "@/feature/components/FeatureWidgets";
import { SetupInstructions } from "@/feature/components/SetupInstructions";

export default function FeatureEvaluate() {
  const { featureId } = useParams();
  const [showSetup, setShowSetup] = useLocalStorage(`${featureId}:setup`, true);

  return (
    <Flex bg="appBackdrop" direction="column" flexGrow={1} gap={4} p={6}>
      {showSetup && (
        <SetupInstructions
          featureId={featureId}
          onHide={() => setShowSetup(false)}
        />
      )}
      <FeatureWidgets featureId={featureId!} />
    </Flex>
  );
}
