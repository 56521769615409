import { useQuery } from "@tanstack/react-query";

import { SubsegmentQueryType } from "@bucketco/shared/featureAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import featureQueryKeys from "@/feature-legacy/data/featureQueryKeys";

export default function useCurrentMetricsData(
  featureId?: string,
  params?: SubsegmentQueryType,
) {
  const { appId, envId } = useCurrentEnv();

  return useQuery({
    queryKey: featureQueryKeys.singleMetricsCurrent(
      appId,
      envId,
      featureId,
      params,
    ),

    queryFn: ({ signal }) =>
      api
        .get<"/apps/:appId/features/:featureId/metrics/current">(
          `/apps/${appId}/features/${featureId}/metrics/current`,
          { signal, params: { ...params, envId: envId! } },
        )
        .then((res) => res.data),
    enabled: !!appId && !!envId && !!featureId,
  });
}
