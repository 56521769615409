import { useCallback, useState } from "react";
import { RiArrowLeftRightLine } from "react-icons/ri";
import { Link, useMatch } from "react-router-dom";
import {
  Avatar,
  Badge,
  Box,
  Button,
  chakra,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

import { AppDTO } from "@bucketco/shared/appAPI";
import { EnvironmentDTO } from "@bucketco/shared/environmentAPI";
import { EnvironmentUrl } from "@bucketco/shared/urls";

import { useOrgsData } from "@/app/data/useOrgsData";
import { useAuthContext } from "@/auth/contexts/authContext";
import DoubleArrowSelectIcon from "@/common/assets/double-arrow-select-icon.svg?react";
import EnvironmentSvg from "@/common/assets/environment-dot-circle-fill.svg?react";
import { NewApp } from "@/common/components/NewApp";
import OrganizationSwitcher from "@/common/components/OrganizationSwitcher";
import OrgLogo from "@/common/components/OrgLogo";
import { IndentType } from "@/common/utils/tree";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { useEnvironmentColors } from "@/environment/hooks/useEnvironmentColors";
import { TreeVisualization } from "@/feature-legacy/components/TreeVisualization";

const EnvironmentIcon = chakra(EnvironmentSvg);

export function AppEnvironmentPicker() {
  const { user, currentOrg, currentApp, currentEnv } = useAuthContext();
  const { data: orgs } = useOrgsData();
  const [isNewAppOpen, setIsNewAppOpen] = useState(false);
  const activeColor = useColorModeValue("brand.500", "brand.300");
  const inactiveColor = useColorModeValue("gray.700", "gray.400");
  const environmentTree = useEnvironmentMenuItems();
  const colors = useEnvironmentColors();
  const match = useMatch("/envs/:envId/*");

  const onNewAppClose = useCallback(
    () => setIsNewAppOpen(false),
    [setIsNewAppOpen],
  );

  const [isOrgSwitcherOpen, setIsOrgSwitcherOpen] = useState(false);

  return (
    <>
      <Menu
        autoSelect={false}
        placement="right-end"
        size="sm"
        variant="padded"
        closeOnSelect
      >
        <MenuButton
          _hover={{ bg: useColorModeValue("gray.100", "gray.800") }}
          as={Button}
          bg="transparent"
          color={inactiveColor}
          flexShrink={0}
          fontWeight="medium"
          mt={1.5}
          mx={-1}
          my={1}
          px={2}
          rightIcon={
            <Box color="dimmed" ml={-2} mr={-2}>
              <DoubleArrowSelectIcon width={24} />
            </Box>
          }
          size="lg"
          textAlign="left"
          variant="ghost"
          w="auto"
        >
          <Flex align="center" as="span" gap={2} w="100%">
            <OrgLogo
              name={currentOrg!.name}
              size="xs"
              url={currentOrg!.logoUrl}
            />
            <Flex as="span" flexDirection="column" gap={1} overflow="hidden">
              <Text as="span" isTruncated>
                {currentApp?.name}
              </Text>
              {!currentEnv?.isProduction && (
                <EnvironmentDisplayName
                  environment={currentEnv!}
                  fontSize="xs"
                />
              )}
            </Flex>
          </Flex>
        </MenuButton>
        <MenuList boxShadow="xl">
          <Box m={-2} px={5} py={4}>
            <Text
              color="dimmed"
              flexGrow={1}
              fontSize="sm"
              fontWeight="medium"
              mb={2}
            >
              Logged in as
            </Text>
            <HStack spacing={2}>
              <Avatar name={user?.name} size="sm" src={user?.avatar} />
              <VStack align="flex-start" spacing={0}>
                <Text fontSize="sm" fontWeight="semibold" isTruncated>
                  {user?.email}
                </Text>
                <HStack spacing={1}>
                  <Text fontSize="sm" isTruncated>
                    {currentOrg?.name}
                  </Text>
                  {orgs && orgs.length > 1 ? (
                    <Tooltip label="Switch organization">
                      <IconButton
                        aria-label="switch organization"
                        color="gray.500"
                        icon={<RiArrowLeftRightLine size={15} />}
                        size="xs"
                        variant="ghost"
                        isRound
                        onClick={() => setIsOrgSwitcherOpen(true)}
                      >
                        switch
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </HStack>
              </VStack>
            </HStack>
          </Box>
          <Flex
            align="center"
            borderBottom="1px solid"
            borderBottomColor="appBorder"
            gap={1}
            mb={2}
            mx={2.5}
            pb={2}
          >
            <OrgLogo
              name={currentOrg!.name}
              size="2xs"
              url={currentOrg!.logoUrl}
            />
            <Text color="dimmed" flexGrow={1} fontSize="sm" fontWeight="medium">
              {currentOrg?.name}&apos;s apps
            </Text>
            <Button
              color="dimmed"
              fontWeight="semibold"
              ml={2}
              size="2xs"
              variant="ghost"
              onClick={() => setIsNewAppOpen(true)}
            >
              New app
            </Button>
          </Flex>
          {environmentTree?.map(({ app, environment, indents }) => {
            const isSameApp = currentApp?.id === app.id;
            const isSelected = currentEnv?.id === environment.id;
            const envColor =
              colors[environment.isProduction ? "production" : "nonProduction"];
            const iconColor = isSelected ? activeColor : envColor.icon;
            const textColor = isSelected ? activeColor : envColor.text;
            const textDimmedColor = isSelected ? activeColor : "dimmed";
            const envUrl = EnvironmentUrl(environment);
            const fullEnvUrl = `${envUrl}/${match ? match.params["*"] : ""}`;

            return (
              <MenuItem
                key={environment.id}
                as={Link}
                gap={1}
                h={8}
                py={0}
                to={isSameApp ? fullEnvUrl : envUrl}
              >
                {!!indents.length && (
                  <TreeVisualization h="125%" indents={indents} />
                )}
                <EnvironmentIcon
                  boxSize={3}
                  color={iconColor}
                  ml={!indents.length ? 1 : undefined}
                />
                {environment.isProduction ? (
                  <>
                    <Text color={textColor}>{app.name}</Text>
                    <Text color={textDimmedColor} fontSize="xs">
                      ({environment.name})
                    </Text>
                    {app.demo ? <Badge size="xs">Demo</Badge> : null}
                  </>
                ) : (
                  <Text color={textColor}>{environment.name}</Text>
                )}
              </MenuItem>
            );
          })}
        </MenuList>
        <NewApp isOpen={isNewAppOpen} onClose={onNewAppClose} />
      </Menu>
      <OrganizationSwitcher
        isOpen={isOrgSwitcherOpen}
        onClose={() => setIsOrgSwitcherOpen(false)}
      />
    </>
  );
}

type EnvironmentMenuItem = {
  app: AppDTO;
  environment: EnvironmentDTO;
  indents: Array<IndentType>;
};

function useEnvironmentMenuItems(): EnvironmentMenuItem[] {
  const { currentOrg } = useAuthContext();
  if (!currentOrg) {
    return [];
  }

  const tree: Array<EnvironmentMenuItem> = [];

  // One iteration to establish the tree
  for (const app of currentOrg.apps) {
    app.environments.forEach((environment, index) => {
      const node: EnvironmentMenuItem = {
        app,
        environment,
        indents: environment.isProduction
          ? []
          : index !== app.environments.length - 1
          ? ["├"]
          : ["└"],
      };

      tree.push(node);
    });
  }

  return tree;
}
