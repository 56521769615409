import { useRef } from "react";
import {
  RiErrorWarningFill,
  RiFileCopyLine,
  RiRefreshLine,
} from "react-icons/ri";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Input,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useClipboard,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { Member, Org } from "@bucketco/shared/organizationAPI";
import { slug } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import TimestampCell from "@/common/components/TimestampCell";
import commonQueryKeys from "@/common/data/commonQueryKeys";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { useOrgMembers } from "@/global-settings/data/useOrgMembers";

function RenewKey() {
  const toast = useToast();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  const { currentOrg } = useAuthContext();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  const { mutate: renewKey, isPending: isRenewalLoading } = useMutation({
    mutationFn: () =>
      api.post<"/orgs/:orgId/invite/refresh">(
        `/orgs/${currentOrg?.id}/invite/refresh`,
      ),

    retry: 0,

    onSuccess: async () => {
      segmentAnalytics.track("Deleted Feature View");
      await queryClient.invalidateQueries(
        { queryKey: commonQueryKeys.bootstrap },
        { throwOnError: true },
      );
      toast({
        title: "Invite key renewed",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      onClose();
    },

    onError: () => {
      errorToast({
        description: "Couldn't refresh invite key",
      });
      onClose();
    },
  });

  return (
    <>
      <Tooltip label="Generate new key">
        <IconButton
          aria-label="Generate new key"
          icon={<RiRefreshLine />}
          isLoading={isRenewalLoading}
          variant="outline"
          onClick={onOpen}
        />
      </Tooltip>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Generate new key</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to generate a new invite key?
              <HStack
                color={useColorModeValue("red.500", "red.400")}
                mt={3}
                spacing={1}
              >
                <RiErrorWarningFill />
                <Text fontWeight="medium">
                  The current invite link will be invalidated.
                </Text>
              </HStack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <ButtonGroup>
                <Button
                  ref={cancelRef}
                  _hover={{ color: useColorModeValue("gray.700", "gray.300") }}
                  color="gray.500"
                  isDisabled={isRenewalLoading}
                  variant="ghost"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="brand"
                  isLoading={isRenewalLoading}
                  onClick={() => renewKey()}
                >
                  Renew
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default function Team() {
  const { currentOrg } = useAuthContext();

  return (
    <Flex direction="column" gap={6}>
      <Flex direction="column">
        <FormLabel>Invite new members</FormLabel>
        <Text color="gray.500" fontSize="sm" mb={4}>
          Share the link below to invite new members to your organization.
        </Text>
        <InviteKeyDisplay org={currentOrg!} />
      </Flex>

      <Box>
        <FormLabel>Members</FormLabel>
        <MembersTable />
      </Box>
    </Flex>
  );
}

const columnHelper = createColumnHelper<Member>();
const MemberColumnConfig = [
  columnHelper.accessor("name", {
    header: "Name",
    cell: (cell) => {
      const value = cell.getValue();
      return (
        <Grid columnGap={3} rowGap={0.5} templateColumns="min-content 1fr">
          <GridItem rowSpan={2}>
            <Avatar
              display="flex"
              name={value}
              size="md"
              src={cell.row.original.avatar ?? undefined}
            />
          </GridItem>
          <GridItem>
            <Text>{value}</Text>
          </GridItem>
          <GridItem>
            <Text color="dimmed">{cell.row.original.email}</Text>
          </GridItem>
        </Grid>
      );
    },
  }),
  columnHelper.accessor("createdAt", {
    header: "Signed up at",
    cell: (cell) => <TimestampCell value={cell.getValue()}></TimestampCell>,
  }),
];

function MembersTable() {
  const { currentOrg } = useAuthContext();

  // team members
  const orgId = currentOrg!.id;
  const { data = [], isLoading } = useOrgMembers(orgId);

  const tableInstance = useReactTable({
    columns: MemberColumnConfig,
    data: data,
    getCoreRowModel: getCoreRowModel(),
  });

  if (isLoading) {
    return null;
  }

  return (
    <Table maxW="wideForm">
      <Thead>
        {tableInstance.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Th key={header.id}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {
          // Loop over the table rows
          tableInstance.getRowModel().rows.map((row) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <Tr key={row.id}>
                {
                  // eslint-disable-next-line react/jsx-key
                  row.getAllCells().map((cell) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <Td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </Td>
                    );
                  })
                }
              </Tr>
            );
          })
        }
      </Tbody>
    </Table>
  );
}

export function InviteKeyDisplay({
  org,
  editable = true,
}: {
  org: Org;
  editable?: boolean;
}) {
  const invite = org.inviteKey;
  const orgSlug = slug({ id: org.id, name: org.name });
  const inviteUrl = invite
    ? new URL(
        `/orgs/${orgSlug}/invite/${invite}`,
        window.location.toString(),
      ).toString()
    : "";

  const { hasCopied, onCopy } = useClipboard(inviteUrl);

  return (
    <HStack maxW="compactForm">
      <Input
        bg={useColorModeValue("gray.50", "gray.800")}
        overflow="hidden"
        textOverflow="ellipsis"
        value={inviteUrl}
        variant="outline"
        whiteSpace="nowrap"
        isReadOnly
        onClick={(e) => {
          e.currentTarget.setSelectionRange(0, e.currentTarget.value.length);
        }}
      />
      <Tooltip
        closeOnClick={false}
        label={hasCopied ? "Copied!" : "Copy to clipboard"}
      >
        <IconButton
          aria-label="Copy to clipboard"
          icon={<RiFileCopyLine />}
          variant="outline"
          onClick={onCopy}
        />
      </Tooltip>
      {editable && <RenewKey />}
    </HStack>
  );
}
