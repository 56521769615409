import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";

import EmptyState from "@/common/components/EmptyState";
import ReleasesEmptyStateIllustration from "@/feature-legacy/components/ReleasesEmptyStateIllustration";

export default function ReleasesEmptyState({
  isLoading,
  isFiltered = true,
}: {
  isLoading: boolean;
  isFiltered?: boolean;
}) {
  return (
    <EmptyState
      action={
        <Button as={Link} size="sm" to="new" variant="outline">
          {isFiltered ? "Create new release" : "Create first release"}
        </Button>
      }
      description="Releases combine feature flags, metrics, and customer feedback in one powerful workflow. Roll out features, track adoption, and make confident data-driven product decisions."
      flexGrow={1}
      isLoading={isLoading}
      title={isFiltered ? "No releases found" : "Launch your first release"}
    >
      <ReleasesEmptyStateIllustration />
    </EmptyState>
  );
}
