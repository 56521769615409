import React, { ReactNode, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Center } from "@chakra-ui/react";

import { ReleaseDetailsDTO } from "@bucketco/shared/releaseAPI";
import { ReleasesUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { useReleaseData } from "@/release/data/useReleaseData";

import { LegacyEditReleasePage } from "./legacy/LegacyEditReleasePage";

export default function Page() {
  const { releaseId } = useParams();

  useEffect(() => {
    segmentAnalytics.page("Release", {
      id: releaseId,
    });
  }, [releaseId]);

  return (
    <ReleaseProvider>
      {<LegacyEditReleasePage releaseId={releaseId!} />}
    </ReleaseProvider>
  );
}

type ReleaseContextType = { release: ReleaseDetailsDTO };
const ReleaseContext = React.createContext<ReleaseContextType>(null!);

function ReleaseProvider({ children }: { children: ReactNode }) {
  const { releaseId } = useParams();
  const { data, isSuccess, isLoading, isError } = useReleaseData(releaseId);

  const { currentEnv } = useAuthContext();
  const navigate = useNavigate();
  const errorToast = useErrorToast();

  useEffect(() => {
    if (isError) {
      errorToast({
        description: `Couldn't open release, please try again`,
      });
      navigate(ReleasesUrl(currentEnv!));
    }
  }, [currentEnv, errorToast, isError, navigate]);

  if (isSuccess) {
    return (
      <ReleaseContext.Provider
        value={{
          release: data,
        }}
      >
        {isLoading ? (
          <Center flexGrow={1}>
            <AnimatedSpinner size="sm" show />
          </Center>
        ) : (
          children
        )}
      </ReleaseContext.Provider>
    );
  }
}

export function useReleaseContext() {
  return useContext<ReleaseContextType>(ReleaseContext);
}
