import { useFormContext } from "react-hook-form";
import { FormControl, FormLabel, InputProps } from "@chakra-ui/react";

import { AttributeFilters } from "@/common/components/AttributeFilters";
import { useEventAttributeNames } from "../../common/data/useEventAttributeNames";

export default function FormEventAttributeFilterPicker({
  name,
  eventName,
  label,
  size = "md",
  isDisabled = false,
}: {
  name: string;
  eventName: string;
  label?: string;
  isDisabled?: boolean;
  size?: InputProps["size"];
}) {
  const form = useFormContext();
  const fieldState = form.getFieldState(name);

  return (
    <FormControl
      id={name}
      isDisabled={form.formState.isSubmitting || isDisabled}
      isInvalid={Boolean(fieldState.error)}
    >
      {label && <FormLabel fontSize={size}>{label}</FormLabel>}
      <FormEventAttributeFilterPickerInput
        eventName={eventName}
        isDisabled={isDisabled}
        name={name}
        size={size}
      />
    </FormControl>
  );
}

export function FormEventAttributeFilterPickerInput({
  name,
  eventName,
  size = "md",
  buttonText,
  isDisabled = false,
}: {
  name: string;
  eventName: string;
  size?: InputProps["size"];
  buttonText?: string;
  isDisabled?: boolean;
}) {
  const { data: attributeNames = [] } = useEventAttributeNames(eventName);

  return (
    <AttributeFilters
      attributes={attributeNames}
      buttonText={buttonText}
      entityType="event"
      eventName={eventName}
      isDisabled={isDisabled}
      minFilterCount={0}
      name={name}
      size={size}
    />
  );
}
