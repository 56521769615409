import { forwardRef } from "react";
import { HStack, Text } from "@chakra-ui/react";
import startCase from "lodash/startCase";

import { frequencyNumberToText } from "@bucketco/shared/featureAPI";

import FormSelect, {
  FormSelectProps,
} from "@/common/components/form/FormSelect";
import FeatureFrequencyProgress from "@/feature-legacy/components/FeatureMetrics";

export type FormFrequencyPickerProps = Omit<
  FormSelectProps,
  "options" | "valueAsNumber" | "value" | "onChange"
> & {
  onChange?: (value: number) => void;
};

const FormFrequencyPicker = forwardRef<
  HTMLSelectElement,
  FormFrequencyPickerProps
>(({ onChange, ...props }, ref) => (
  <FormSelect
    {...props}
    ref={ref}
    options={[0, 1, 2, 3].map((value) => ({
      value: value,
      label: (
        <HStack>
          <FeatureFrequencyProgress
            filledColor="brand.400"
            frequency={value}
            showText={false}
            size="xs"
          />
          <Text>{startCase(frequencyNumberToText(value))}</Text>
        </HStack>
      ),
    }))}
    placeholder="Select a frequency"
    valueAsNumber
    onChange={(value) => {
      onChange?.(value);
    }}
  />
));

FormFrequencyPicker.displayName = "FormFrequencyPicker";

export default FormFrequencyPicker;
