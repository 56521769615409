import { WidgetsFilterQuery } from "@bucketco/shared/widgetAPI";

export const widgetQueryKeys = {
  list: (appId?: string, params?: WidgetsFilterQuery) =>
    params
      ? (["apps", appId, "widgets", params] as const)
      : (["apps", appId, "widgets"] as const),

  single: (appId?: string, widgetId?: string) =>
    [...widgetQueryKeys.list(appId), widgetId] as const,
};
