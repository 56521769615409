import { HStack, Text, TextProps, useColorModeValue } from "@chakra-ui/react";

import InfoIconTooltip from "@/common/components/InfoIconTooltip";

export function StarsMetricDescription({
  isLoading,
  color,
  tooltip,
  docsURL,
  children,
}: {
  isLoading: boolean;
  color?: TextProps["color"];
  tooltip?: string | React.ReactNode;
  docsURL?: string;
  children?: string | React.ReactNode;
}) {
  return (
    <HStack color={useColorModeValue("gray.500", "gray.500")} h={8}>
      {isLoading ? (
        " "
      ) : children ? (
        <Text
          align="center"
          color={color}
          fontSize="sm"
          lineHeight="shorter"
          maxW={60}
        >
          {children}
        </Text>
      ) : null}
      {tooltip ? <InfoIconTooltip docsURL={docsURL} text={tooltip} /> : null}
    </HStack>
  );
}
