import React from "react";
import { Flex, FlexProps, HStack } from "@chakra-ui/react";

import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import { SectionHeading } from "@/common/components/SectionHeading";

export function ChartWrapper({
  title,
  metric,
  tooltip,
  docsURL,
  isLoading,
  children,
  ...props
}: {
  title?: string;
  metric?: React.ReactNode;
  tooltip?: string | React.ReactNode;
  docsURL?: string;
  isLoading?: boolean;
  children: React.ReactNode;
} & FlexProps) {
  return (
    <Flex direction="column" gap={4} {...props}>
      {title ? (
        <Flex align="center" flexDirection="row">
          <HStack flexGrow={1} spacing={2}>
            <SectionHeading>{title}</SectionHeading>
            {tooltip && <InfoIconTooltip docsURL={docsURL} text={tooltip} />}
            {isLoading !== undefined && (
              <AnimatedSpinner show={isLoading} size="sm" />
            )}
          </HStack>
          {metric && (
            <Flex alignItems="center" gap={4}>
              {metric}
            </Flex>
          )}
        </Flex>
      ) : null}
      {children}
    </Flex>
  );
}
