import {
  createContext,
  ForwardedRef,
  forwardRef,
  ReactElement,
  useContext,
} from "react";
import { List, ListItem, ListItemProps, ListProps } from "@chakra-ui/react";
import { HTMLMotionProps, Reorder } from "framer-motion";

type ReorderContextType<T> = {
  onDrop: (value: T) => void;
};

const ReorderContext = createContext<ReorderContextType<any>>({
  onDrop: () => {},
});

export type ReorderListItemProps<T> = Omit<
  ListItemProps,
  "as" | "style" | "value"
> &
  HTMLMotionProps<any> & {
    value: T;
    layout?: true | "position";
  };

function ReorderListItemInner<T>(
  props: ReorderListItemProps<T>,
  ref: ForwardedRef<HTMLUListElement>,
) {
  const { onDrop } = useContext<ReorderContextType<T>>(ReorderContext);

  return (
    <ListItem
      ref={ref}
      as={Reorder.Item}
      onDragEnd={() => {
        onDrop?.(props.value);
      }}
      {...(props as any)}
    />
  );
}

export type ReorderListProps<T> = Omit<
  ListProps,
  "as" | "onDrop" | "children"
> &
  Omit<HTMLMotionProps<any>, "values" | "onDrop"> & {
    axis?: "x" | "y";
    values: T[];
    children?: ReactElement[];
    onReorder: (newOrder: T[]) => void;
    onDrop?: (value: T, order: number, newOrder: T[]) => void;
  };

function ReorderListInner<T>(
  { values, onDrop, ...rest }: ReorderListProps<T>,
  ref: ForwardedRef<HTMLUListElement>,
) {
  return (
    <ReorderContext.Provider
      value={{
        onDrop: (value) => {
          onDrop?.(value, values.indexOf(value), values);
        },
      }}
    >
      <List ref={ref} as={Reorder.Group} values={values} {...rest} />
    </ReorderContext.Provider>
  );
}

export const ReorderListItem = forwardRef(ReorderListItemInner) as (<T>(
  props: ReorderListItemProps<T> & {
    ref?: ForwardedRef<HTMLButtonElement>;
  },
) => ReturnType<typeof ReorderListItemInner>) & { displayName: string };

ReorderListItem.displayName = "ReorderListItem";

export const ReorderList = forwardRef(ReorderListInner) as (<T>(
  props: ReorderListProps<T> & {
    ref?: ForwardedRef<HTMLButtonElement>;
  },
) => ReturnType<typeof ReorderListInner>) & { displayName: string };

ReorderList.displayName = "ReorderList";
