import { AuditChartDataItem } from "@/common/charts/components/AuditChart/types";
import useChartTokens from "@/common/charts/hooks/useChartTokens";

export const DOT_DIAMETER = 15;

export function Dot({
  id,
  x,
  y,
  data,
  onMouseEnter,
}: Record<string, any> & {
  data: AuditChartDataItem[];
  onMouseEnter: (item: AuditChartDataItem) => void;
}) {
  const { colors } = useChartTokens();

  const itemIdx = data.findIndex((i) => i.id === id);
  const item = data[itemIdx];
  if (item === undefined) return null;

  return (
    <g
      data-scatter-dot-item-id={item.id}
      onMouseEnter={() => {
        onMouseEnter(item);
      }}
    >
      <circle
        cx={x + DOT_DIAMETER / 2}
        cy={y + DOT_DIAMETER / 2}
        fill={colors.primary}
        r={DOT_DIAMETER / 2}
      />
      <text
        alignmentBaseline="middle"
        dy={1}
        fill={colors.scatterDotLabel}
        fontSize={10}
        fontWeight="bold"
        textAnchor="middle"
        x={x + DOT_DIAMETER / 2}
        y={y + DOT_DIAMETER / 2}
      >
        {item.dotLabel ?? itemIdx + 1}
      </text>
    </g>
  );
}
