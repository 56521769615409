import { useMemo, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceArea,
  ReferenceLine,
  ReferenceLineProps,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from "recharts";

import {
  IncompleteBarPattern,
  incompleteBarPatternId,
} from "@/common/charts/components/IncompleteBar";
import useChartTokens from "@/common/charts/hooks/useChartTokens";
import { useThrottle } from "@/common/hooks/useThrottle";

export type DataKeyType = { key: string; name: string };

type FrequencyChartProps = {
  data?: any[];
  dataKeys: DataKeyType[];
  isLoading?: boolean;
  referenceLines?: ReferenceLineProps[];
  xAxis?: XAxisProps;
  yAxis?: YAxisProps;
  tooltip?: TooltipProps<any, any>;
  height?: number;
  highlightPartialPeriod?: boolean;
  highlightLatest?: boolean;
  grid?: boolean;
  onResize?: (width: number) => void;
};

export default function FrequencyChart({
  data = [],
  dataKeys = [],
  isLoading = false,
  referenceLines = [],
  xAxis = {},
  yAxis = {},
  tooltip = {},
  height = 300,
  highlightPartialPeriod = false,
  highlightLatest = false,
  grid = true,
  onResize,
}: FrequencyChartProps) {
  const { colors, fontSizes, radii, sizes, properties } = useChartTokens();
  const lastDataPeriod = useMemo<[number, number]>(
    () =>
      data
        .filter((point) => "value" in point)
        .map(({ epoch }) => epoch)
        .reverse()
        .slice(0, 2) as [number, number],
    [data],
  );
  const [width, setWidth] = useState<number>(720);
  useThrottle(width, 500, onResize);

  return (
    <ResponsiveContainer
      height={height}
      width="100%"
      onResize={(newWidth) => {
        setWidth(newWidth);
      }}
    >
      <ComposedChart
        data={data}
        margin={{
          top: 24,
          right: 0,
          bottom: 0,
          left: 0,
        }}
        syncId="a"
      >
        <defs>
          <linearGradient id="areaGradient" x1="0" x2="0" y1="0" y2="1">
            <stop offset="20%" stopColor={colors.primary} stopOpacity={0.85} />
            <stop offset="100%" stopColor={colors.primary} stopOpacity={0.1} />
          </linearGradient>
        </defs>

        {grid && (
          <CartesianGrid
            stroke={colors.grid}
            strokeDasharray="4"
            vertical={false}
          />
        )}

        <XAxis
          axisLine={true}
          dataKey="epoch"
          dy={8}
          fontSize={fontSizes.axisLabel}
          height={50}
          interval="equidistantPreserveStart"
          minTickGap={0}
          scale="utc"
          stroke={colors.axis}
          tick={{
            textAnchor: "end",
          }}
          tickLine={true}
          type="number"
          {...xAxis}
        ></XAxis>
        <YAxis
          axisLine={false}
          fontSize={fontSizes.axisLabel}
          stroke={colors.axis}
          tick={{
            dx: 4,
          }}
          tickCount={5}
          tickLine={false}
          tickMargin={0}
          type="number"
          width={sizes.yAxisWidth}
          yAxisId="left"
          {...yAxis}
        ></YAxis>
        <YAxis
          axisLine={false}
          orientation="right"
          tick={{
            dx: 4,
          }}
          tickLine={false}
          tickMargin={0}
          type="number"
          width={1} // Allow a tiny margin so a reference line at the end is fully visible
          yAxisId="dummy"
        ></YAxis>
        <Tooltip
          contentStyle={{
            borderColor: colors.tooltipBorder,
            backgroundColor: colors.tooltipBg,
            borderRadius: radii.tooltip,
            fontSize: fontSizes.tooltip,
          }}
          cursor={{ stroke: colors.cursor }}
          isAnimationActive={false}
          {...tooltip}
        />
        {highlightPartialPeriod && data?.length && (
          <>
            <defs>
              <IncompleteBarPattern
                colorName={"partialHighlight"}
                colorValue={colors.partialHighlight}
              />
            </defs>
            <ReferenceArea
              fill={`url(#${incompleteBarPatternId("partialHighlight")})`}
              x1={lastDataPeriod[0]}
              x2={lastDataPeriod[1]}
              yAxisId="left"
            />
          </>
        )}
        {highlightLatest && data?.length && (
          <ReferenceArea
            fill={colors.highlightBg}
            x1={lastDataPeriod[0]}
            x2={lastDataPeriod[1]}
            yAxisId="left"
          />
        )}

        {referenceLines.map((referenceLineProps, idx) => (
          <ReferenceLine
            key={idx}
            {...referenceLineProps}
            stroke={colors.grid}
            yAxisId="left"
          />
        ))}

        {!isLoading &&
          dataKeys.map(({ key, name }) => (
            <Area
              key={key}
              activeDot={properties.activeDot}
              dataKey={key}
              dot={false}
              fill="url('#areaGradient')"
              fillOpacity={0.2}
              isAnimationActive={false}
              label={false}
              name={name}
              stroke={colors.primary}
              strokeOpacity={1}
              strokeWidth={0}
              tooltipType="none"
              type="monotone"
              yAxisId="left"
            />
          ))}
        {!isLoading &&
          dataKeys.map(({ key, name }) => (
            <Line
              key={key}
              activeDot={false}
              dataKey={key}
              dot={false}
              isAnimationActive={false}
              name={name}
              stroke={colors.primary}
              strokeLinecap="round"
              strokeWidth={sizes.lineStrokeWidth}
              type="monotone"
              yAxisId="left"
            />
          ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
}
