import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import {
  SegmentDTOWithTotalCompanies,
  SegmentsQueryType,
} from "@bucketco/shared/segmentAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import segmentsQueryKeys from "@/company/data/segmentsQueryKeys";

type OptionsType<TData> = Omit<
  UseQueryOptions<
    SegmentDTOWithTotalCompanies[],
    AxiosError<ErrorResponse>,
    TData
  >,
  "queryKey" | "queryFn"
>;

export function useSegments<TData = SegmentDTOWithTotalCompanies[]>(
  query?: Omit<SegmentsQueryType, "envId">,
  options: OptionsType<TData> = {},
) {
  const { appId, envId } = useCurrentEnv();
  const { enabled, ...otherOptions } = options;

  return useQuery<
    SegmentDTOWithTotalCompanies[],
    AxiosError<ErrorResponse>,
    TData
  >({
    queryKey: segmentsQueryKeys.list(appId, envId, query),
    queryFn: () =>
      api
        .get<"/apps/:appId/segments">(`/apps/${appId}/segments`, {
          params: { ...query, envId: envId! },
        })
        .then((res) => res.data),
    ...otherOptions,
    enabled: !!appId && !!envId && Boolean(enabled ?? true),
  });
}

export function useSegment(segmentId?: string | null) {
  return useSegments(undefined, {
    select(segments) {
      return segments.find((segment) => segment.id === segmentId);
    },
    enabled: !!segmentId,
  });
}
