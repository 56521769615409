import { defineStyleConfig, ThemeComponents } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

import { buttonSizes, focusStyle, textLink } from "@/theme/helpers";

import { AlertTheme } from "./Alert.theme";
import { AvatarTheme } from "./Avatar.theme";
import { CardTheme } from "./Card.theme";
import { InputTheme } from "./Input.theme";
import { MenuTheme } from "./Menu.theme";
import { NumberInputTheme } from "./NumberInput.theme";
import { PopoverTheme } from "./Popover.theme";
import { ProgressTheme } from "./Progress.theme";
import { RadioTheme } from "./Radio.theme";
import { SwitchTheme } from "./Switch.theme";
import { TableTheme } from "./Table.theme";
import { TabsTheme } from "./Tabs.theme";
import { TagTheme } from "./Tag.theme";
import { theme } from "./theme";

const Button = defineStyleConfig({
  defaultProps: {
    size: "sm",
  },
  baseStyle: {
    textDecoration: "none",
    _hover: {
      textDecoration: "none",
    },
    _focus: {
      boxShadow: focusStyle,
    },
  },
  sizes: {
    "2xs": {
      ...buttonSizes["2xs"],
      px: 2,
      fontSize: "xs",
      fontWeight: "medium",
    },
    xs: {
      ...buttonSizes.xs,
    },
    sm: {
      ...buttonSizes.sm,
    },
    md: {
      ...buttonSizes.md,
    },
    lg: {
      ...buttonSizes.lg,
    },
  },
  variants: {
    primary: (props) => ({
      boxShadow: "sm",
      color: "white",
      bg: mode("brand.500", "brand.500")(props),
      _hover: {
        bg: mode("brand.600", "brand.400")(props),
        _disabled: {
          color: mode("white", "gray.100")(props),
          bg: mode("gray.400", "gray.600")(props),
        },
      },
      _active: {
        bg: mode("brand.600", "brand.300")(props),
      },
      _disabled: {
        color: mode("white", "gray.100")(props),
        bg: mode("gray.400", "gray.600")(props),
      },
    }),
    ghost: (props) =>
      props.colorScheme === "gray"
        ? {
            color: mode("inherit", "gray.100")(props),
            _hover: {
              bg: mode("gray.50", "gray.750")(props),
            },
            _active: { bg: mode("gray.100", "gray.700")(props) },
          }
        : {},
    outlineOnHover: (props) => ({
      borderWidth: "1px",
      color: mode("inherit", "gray.100")(props),
      borderColor: "transparent",
      _hover: {
        bg: mode("gray.50", "gray.750")(props),
        borderColor: mode(`gray.200`, `whiteAlpha.300`)(props),
        boxShadow: "sm",
      },
      _active: {
        backgroundColor: mode("gray.50", "gray.800")(props),
        borderColor: mode(`gray.200`, `whiteAlpha.300`)(props),
        boxShadow: "sm",
        bg: mode("gray.100", "gray.700")(props),
      },
    }),
    outline: (props) =>
      props.colorScheme === "gray"
        ? {
            boxShadow: "sm",
            bg: mode("white", "gray.900")(props),
            color: mode("gray.800", "gray.100")(props),
            borderColor: mode("gray.250", "gray.650")(props),
            _hover: {
              bg: mode("gray.50", "gray.750")(props),
              borderColor: mode("gray.300", "gray.600")(props),
            },
            _active: { bg: mode("gray.100", "gray.700")(props) },
          }
        : {
            boxShadow: "sm",
            color: mode(
              `${props.colorScheme}.500`,
              `${props.colorScheme}.300`,
            )(props),
            bg: mode(
              `${props.colorScheme}.50`,
              `${props.colorScheme}.900`,
            )(props),
            borderColor: mode(
              `${props.colorScheme}.400`,
              `${props.colorScheme}.700`,
            )(props),
            _hover: {
              bg: mode(
                `${props.colorScheme}.50`,
                `${props.colorScheme}.900`,
              )(props),
              borderColor: mode(
                `${props.colorScheme}.600`,
                `${props.colorScheme}.600`,
              )(props),
            },
            _active: {
              bg: mode(
                `${props.colorScheme}.100`,
                `${props.colorScheme}.800`,
              )(props),
            },
          },
    solid: (props) =>
      props.colorScheme === "gray"
        ? {
            boxShadow: "sm",
            bg: mode("gray.50", "gray.750")(props),
            _hover: {
              bg: mode("gray.100", "gray.700")(props),
              _disabled: {
                bg: mode("gray.50", "gray.750")(props),
              },
            },
            _active: { bg: mode("gray.200", "gray.650")(props) },
          }
        : {
            boxShadow: "sm",
            bg: mode(
              `${props.colorScheme}.500`,
              `${props.colorScheme}.500`,
            )(props),
            color: "white",
            _hover: {
              bg: mode(
                `${props.colorScheme}.600`,
                `${props.colorScheme}.400`,
              )(props),
              _disabled: {
                bg: mode(
                  `${props.colorScheme}.300`,
                  `${props.colorScheme}.600`,
                )(props),
              },
            },
            _active: {
              bg: mode(
                `${props.colorScheme}.700`,
                `${props.colorScheme}.300`,
              )(props),
            },
            _disabled: {
              bg: mode(
                `${props.colorScheme}.300`,
                `${props.colorScheme}.600`,
              )(props),
            },
          },
    input: (props) => ({
      ...theme.components.Input.variants.outline(props).field,
      bg: mode("white", "gray.900")(props),
      _hover: {
        bg: mode("gray.50", "gray.750")(props),
      },
      _active: { bg: mode("gray.100", "gray.700")(props) },
      fontWeight: "normal",
      textAlign: "left",
      justifyContent: "space-between",
    }),
    link: textLink,
  },
});

// form labels should be bold by default,
// fix problem with highlight when hovering between field and label
const FormLabel = defineStyleConfig({
  baseStyle: (props) => ({
    fontWeight: "medium",
    color: mode("gray.900", "gray.50")(props),
    mb: 0,
    pb: 2,
  }),
});

const modalPadding = 8;
const Modal = defineStyleConfig({
  baseStyle: (props) => ({
    overlay: {
      bg: mode("blackAlpha.500", "blackAlpha.700")(props),
    },
    dialog: {
      bg: "appBackground",
      borderRadius: "md",
      pt: modalPadding,
      gap: modalPadding,
    },
    header: {
      py: 0,
      px: modalPadding,
      h2: {
        fontSize: ["lg", "xl", "2xl"],
      },
    },
    body: {
      py: 0,
      px: modalPadding,
    },
    footer: {
      px: modalPadding,
      py: 5,
      bg: mode("gray.50", "gray.900")(props),
      borderTop: "1px solid",
      borderTopColor: "appBorder",
      borderBottomRightRadius: "md",
      borderBottomLeftRadius: "md",
    },
    closeButton: {
      borderRadius: "full",
      p: 4,
      top: 4,
      right: 4,
    },
  }),
  variants: {
    video: {
      dialog: {
        pt: 0,
        mx: 5,
        borderRadius: "xl",
      },
      body: {
        p: 0,
        overflow: "hidden",
        borderRadius: "xl",
      },
      closeButton: {
        top: -4,
        right: -4,
        background: "appBackground",
        boxShadow: "lg",
        _hover: {
          background: "appBackground",
        },
        _active: {
          background: "appBackground",
        },
      },
    },
  },
});

const Tooltip = defineStyleConfig({
  baseStyle: {
    borderRadius: "md",
    px: 2,
    py: 1,
  },
});

const Badge = defineStyleConfig({
  baseStyle: {
    textTransform: "none",
    fontWeight: "medium",
    borderRadius: "full",
  },
  sizes: {
    xs: {
      px: 1.5,
      py: "1px",
      fontSize: "xs",
    },
    sm: {
      px: 1.5,
      py: 0.5,
      fontSize: "xs",
    },
    md: {
      px: 2,
      py: 1,
      fontSize: "sm",
    },
  },
  defaultProps: {
    size: "sm",
  },
});

const Heading = defineStyleConfig({
  baseStyle: {
    fontWeight: "semibold",
  },
});

const Link = defineStyleConfig({
  variants: {
    default: textLink,
    unstyled: {},
  },
  defaultProps: {
    variant: "default",
  },
});

const Text = defineStyleConfig({
  variants: {
    dimmedDisabled: (props) => ({
      color: mode("gray.300", "gray.600")(props),
    }),
    dimmed: (props) => ({
      color: mode("gray.500", "gray.500")(props),
    }),
  },
});

const Divider = defineStyleConfig({
  baseStyle: {
    opacity: 1,
    borderColor: "appBorder",
  },
});

const components: ThemeComponents = {
  Button,
  Card: CardTheme,
  FormLabel,
  Modal,
  Progress: ProgressTheme,
  Menu: MenuTheme,
  Table: TableTheme,
  Tabs: TabsTheme,
  Popover: PopoverTheme,
  Tooltip,
  Badge,
  Radio: RadioTheme,
  Switch: SwitchTheme,
  Tag: TagTheme,
  Heading,
  NumberInput: NumberInputTheme,
  Input: InputTheme,
  Link,
  Text,
  Divider,
  Avatar: AvatarTheme,
  Alert: AlertTheme,
};

export default components;
