import { ReactNode } from "react";
import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  useColorModeValue,
} from "@chakra-ui/react";

export type ButtonDefinition<Tid> = {
  id: Tid;
  label: string | ReactNode;
  isDisabled?: boolean;
  leftIcon?: ButtonProps["leftIcon"];
};

type SwitchButtonGroupProps<TValue> = Omit<ButtonGroupProps, "onChange"> & {
  buttons: Array<ButtonDefinition<TValue>>;
  label: string;
  activeId: TValue;
  onChange: (value: TValue) => void;
};

export default function SwitchButtonGroup<TValue extends string>({
  buttons,
  activeId,
  label,
  onChange,
  ...props
}: SwitchButtonGroupProps<TValue>) {
  const activeColor = useColorModeValue("gray.900", "gray.50");
  const activeBg = useColorModeValue("gray.50", "gray.800");
  const inactiveColor = useColorModeValue("gray.500", "gray.500");
  return (
    <ButtonGroup
      variant="outline"
      isAttached
      {...props}
      aria-label={label}
      as="section"
    >
      {buttons.map((button) => {
        return (
          <Button
            key={button.id}
            aria-pressed={activeId === button.id}
            bg={activeId === button.id ? activeBg : undefined}
            color={activeId === button.id ? activeColor : inactiveColor}
            isDisabled={button.isDisabled}
            leftIcon={button.leftIcon ?? undefined}
            onClick={() => onChange(button.id)}
          >
            {button.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}
