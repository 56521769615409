import { useMemo } from "react";
import { Link, To } from "react-router-dom";
import {
  chakra,
  Divider,
  Spinner,
  Tag,
  TagLabel,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { FeatureRolloutStatus } from "@bucketco/shared/featureAPI";

import EnvironmentSvg from "@/common/assets/environment-dot-circle-fill.svg?react";
import dayjs from "@/common/utils/dayjs";
import pluralize from "@/common/utils/pluralize";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { RulesSummary } from "@/feature/components/RolloutStatus";
import { useFeatureRolloutStatusData } from "@/feature/data/useFeatureRolloutStatusData";

const EnvironmentIcon = chakra(EnvironmentSvg);

type RolloutStatusLabelProps = {
  status?: FeatureRolloutStatus;
};

export const RolloutStatusLabel = ({ status }: RolloutStatusLabelProps) => {
  const dividerColor = useColorModeValue("gray.300", "gray.600");

  const estimatedCount = useMemo(() => {
    if (!status) return null;
    return Object.values(status.estimatedRuleTargetAudience).reduce(
      (acc, curr) => acc + curr,
      0,
    );
  }, [status]);

  if (!status) {
    return (
      <TagLabel alignItems="center" display="inline-flex" gap={1}>
        <EnvironmentIcon boxSize={3} color="dimmed" />
        <Text as="span" color="dimmed" fontWeight="medium">
          No data yet
        </Text>
      </TagLabel>
    );
  }

  return (
    <TagLabel alignItems="center" display="inline-flex" gap={2}>
      <EnvironmentDisplayName as="span" environment={status.environment} />
      <Divider
        alignSelf="stretch"
        borderColor={dividerColor}
        h="auto"
        orientation="vertical"
      />
      <RulesSummary rules={status.targetingRules} />
      {estimatedCount !== null && (
        <Text as="span" color="dimmed">
          ({estimatedCount} {pluralize("company", estimatedCount, "companies")})
        </Text>
      )}
    </TagLabel>
  );
};

type RolloutStatusTagProps = {
  featureId?: string;
  link?: To;
};

export const RolloutStatusTag = ({
  featureId,
  link,
}: RolloutStatusTagProps) => {
  const { data, isLoading } = useFeatureRolloutStatusData(featureId);

  const status = useMemo(() => {
    return data?.find(
      ({ latestCheck, latestUsage, latestFeedback }) =>
        dayjs().isAfter(latestCheck) ||
        dayjs().isAfter(latestUsage) ||
        dayjs().isAfter(latestFeedback),
    );
  }, [data]);

  if (isLoading) {
    return <Spinner color="dimmed" size="sm" />;
  }

  return (
    <Tag
      as={link ? Link : undefined}
      borderRadius="full"
      size="sm"
      to={link}
      variant="clickable"
    >
      <RolloutStatusLabel status={status} />
    </Tag>
  );
};
