import { AttributeFilter } from "@bucketco/shared/attributeFilter";
import { EnvironmentDTO } from "@bucketco/shared/environmentAPI";
import { EventSelectorType } from "@bucketco/shared/featureAPI";
import { NonEmpty } from "@bucketco/shared/utils/types";

import { TypeByOperator } from "./ReportEmptyState";

type FeatureTrackingDetails =
  | { eventSelectors: EventSelectorType[] }
  | { usingItAttributeFilter: NonEmpty<AttributeFilter> };

export function getBrowserSDKCodeSample(feature: FeatureTrackingDetails) {
  let featureSpecificCode: string;

  if ("eventSelectors" in feature) {
    featureSpecificCode = "";
    feature.eventSelectors.forEach((selector, idx) => {
      featureSpecificCode += `bucket.track("${selector.name}"${
        selector.filter?.length
          ? `, {
${renderAttributeFilter(selector.filter || [])}
}`
          : ""
      });`;

      if (idx < feature.eventSelectors.length - 1)
        featureSpecificCode += "\n\n// OR\n\n";
    });
  } else {
    featureSpecificCode = `// track the company attributes
bucket.company(COMPANY_ID, {
${renderAttributeFilter(feature.usingItAttributeFilter || [])}
});`;
  }

  return featureSpecificCode;
}

export function getNodeSDKCodeSample(feature: FeatureTrackingDetails) {
  let featureSpecificCode: string;

  if ("eventSelectors" in feature) {
    featureSpecificCode = "";
    feature.eventSelectors.forEach((selector, idx) => {
      featureSpecificCode += `bucket.track("${selector.name}"${
        selector.filter?.length
          ? `, {
${renderAttributeFilter(selector.filter || [])}
}`
          : ""
      }, USER_ID);`;

      if (idx < feature.eventSelectors.length - 1)
        featureSpecificCode += "\n\n// OR\n\n";
    });
  } else {
    featureSpecificCode = `// track the company attributes
bucket.company(COMPANY_ID, {
${renderAttributeFilter(feature.usingItAttributeFilter || [])}
}, USER_ID);`;
  }

  return featureSpecificCode;
}

export function getSegmentNodeSDKCodeSample(feature: FeatureTrackingDetails) {
  if ("eventSelectors" in feature) {
    let featureSpecificCode = "";
    feature.eventSelectors.forEach((selector, idx) => {
      featureSpecificCode += `segmentClient.track({
      event: '${selector.name}',
      userId: USER_ID${
        !selector.filter
          ? ""
          : `,
      properties: {
${renderAttributeFilter(selector.filter, 4)}
      }`
      }
    })`;

      if (idx < feature.eventSelectors.length - 1) {
        featureSpecificCode += "\n\n// OR\n\n";
      }
    });

    return featureSpecificCode;
  } else {
    return `segmentClient.group({
  userId: USER_ID,
  groupId: COMPANY_ID${
    !feature.usingItAttributeFilter
      ? ""
      : `,
  traits: {
${renderAttributeFilter(feature.usingItAttributeFilter, 4)}
  }`
  }
})`;
  }
}

export function getSegmentBrowserSDKCodeSample(
  feature: FeatureTrackingDetails,
) {
  if ("eventSelectors" in feature) {
    let featureSpecificCode = "";
    feature.eventSelectors.forEach((selector, idx) => {
      featureSpecificCode += `analytics.track('${selector.name}'${
        selector.filter?.length
          ? `, {
${renderAttributeFilter(selector.filter, 2)}
}`
          : ""
      });`;

      if (idx < feature.eventSelectors.length - 1) {
        featureSpecificCode += "\n\n// OR\n\n";
      }
    });

    return featureSpecificCode;
  } else {
    return `// track the company attribute in the browser with segment
analytics.group(COMPANY_ID, {
${renderAttributeFilter(feature.usingItAttributeFilter, 2)}
})`;
  }
}

export function getHTTPExampleForFeature(
  feature: FeatureTrackingDetails,
  env: EnvironmentDTO,
): string {
  if ("eventSelectors" in feature) {
    let featureSpecificCode = "";

    feature.eventSelectors.forEach((selector, idx) => {
      const attributeFilters = selector.filter ?? [];

      featureSpecificCode += `POST https://tracking.bucket.co/${
        env.publishableKey
      }/event HTTP/1.1
Content-Type: application/json

{
  "event": "${selector.name}",
  "userId": <USER_ID>${
    attributeFilters.length > 0
      ? `,\n  "attributes": {\n${attributeFilters
          .map(
            (rule) =>
              `    "${rule.field}": <${formatAttributeValue(rule.field)}>`,
          )
          .join(",\n")}\n  }`
      : ""
  }
}`;

      if (idx < feature.eventSelectors.length - 1) {
        featureSpecificCode += "\n\n# OR\n\n";
      }
    });

    return featureSpecificCode;
  } else {
    return `POST https://tracking.bucket.co/${
      env.publishableKey
    }/company HTTP/1.1
Content-Type: application/json

{
  "companyId": <COMPANY_ID>,
  "userId": <USER_ID>
  "attributes": {
${feature.usingItAttributeFilter
  .map((rule) => `    "${rule.field}": <${formatAttributeValue(rule.field)}>`)
  .join("\n")},
  }
}`;
  }
}

const formatAttributeValue = (field: string) =>
  field.toUpperCase().replaceAll(" ", "_");

function renderAttributeFilter(
  filter: AttributeFilter,
  indentSize = 2,
): string {
  const indent = " ".repeat(indentSize);
  return filter
    .map(
      (rule) =>
        `${indent}/** @type {${TypeByOperator[rule.operator]}} */\n${indent}"${
          rule.field
        }": ${formatAttributeValue(rule.field)}`,
    )
    .join(",\n");
}
