import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Box, Center, Spinner, useToast, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

import {
  SlackIntegrationSchema,
  SlackIntegrationType,
} from "@bucketco/shared/featureAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import { FormRootError } from "@/common/components/form/FormRootError";
import FormSubmit from "@/common/components/form/FormSubmit";
import SettingSection from "@/common/components/SettingSection";
import useApiForm from "@/common/hooks/useApiForm";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { OnlyProductionEnvironment } from "@/environment/components/EnvironmentCallouts";
import FormSlackIntegration from "@/feature-legacy/components/FormSlackIntegration";
import { useFeatureContext } from "@/feature-legacy/contexts/featureContext";
import featureQueryKeys from "@/feature-legacy/data/featureQueryKeys";
import SlackOrgIntegration from "@/global-settings/components/SlackOrgIntegration";

export function SlackForm() {
  const { envId, appId } = useCurrentEnv();
  const { featureId } = useParams();

  const { feature } = useFeatureContext();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { form, handleSubmit } = useApiForm(
    (data: SlackIntegrationType) =>
      api
        .patch<"/apps/:appId/features/:featureId">(
          `/apps/${appId}/features/${featureId}`,
          {
            integrations: {
              slack: data,
            },
          },
          {
            params: { envId: envId! },
          },
        )
        .then((res) => res.data.feature),
    SlackIntegrationSchema,
    {
      onSuccess: (feature) => {
        queryClient.setQueryData(
          featureQueryKeys.singleEnv(appId, envId, featureId),
          feature,
        );
        segmentAnalytics.track("Feature Slack Integration Updated", {
          hasSetChannel: !!feature.integrations.slack.channelId,
          weeklyReport: feature.integrations.slack.weeklyReport,
          dailyReport: feature.integrations.slack.dailyReport,
        });
        toast({
          title: "Integration settings saved",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        form.reset(feature.integrations.slack);
      },
    },
    {
      mode: "all",
      defaultValues: feature?.integrations.slack,
    },
  );

  return (
    <form onSubmit={handleSubmit}>
      <FormProvider {...form}>
        <VStack align="flex-start" spacing={6}>
          <FormSlackIntegration />
          <FormRootError />
          <FormSubmit>Save</FormSubmit>
        </VStack>
      </FormProvider>
    </form>
  );
}

export function SlackSection() {
  const { currentOrg, currentEnv } = useAuthContext();

  const hasSlackConnection = currentOrg?.hasSlackConnection;

  useEffect(() => {
    segmentAnalytics.page("Feature Slack Integration");
  }, []);

  return (
    <SettingSection
      description="Get reports about this feature in your preferred Slack channel."
      title="Slack"
    >
      {!currentEnv?.isProduction ? (
        <OnlyProductionEnvironment entity="slack reports" />
      ) : !hasSlackConnection ? (
        <SlackOrgIntegration />
      ) : (
        <SlackForm />
      )}
    </SettingSection>
  );
}

export default function FeatureIntegrations() {
  const { isLoading } = useFeatureContext();

  return isLoading ? (
    <Center h="100%">
      <Spinner size="sm" />
    </Center>
  ) : (
    <Box p={6}>
      <SlackSection />
    </Box>
  );
}
