import { RiSendPlaneLine } from "react-icons/ri";
import { useSearchParams } from "react-router-dom";
import { Badge, Box, HStack, Td, Tr } from "@chakra-ui/react";
import { useIsFetching, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import {
  ReleaseDetailsDTO,
  ReleasesQuerySortBy,
} from "@bucketco/shared/releaseAPI";
import { SCREENSHOT_SELECTOR_ID } from "@bucketco/shared/screenshot";

import NotAvailableCell from "@/common/components/NotAvailableCell";
import SimpleTable from "@/common/components/SimpleTable";
import TimestampCell from "@/common/components/TimestampCell";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import pluralize from "@/common/utils/pluralize";
import featureQueryKeys from "@/feature-legacy/data/featureQueryKeys";
import GoalCell from "@/release/components/GoalCell";

const isDatePastWeek = (date: string | null) =>
  !!date && dayjs(date).isSameOrAfter(dayjs().subtract(7, "days"));

const isEvaluating = (f: ReleaseDetailsDTO) => f.status === "evaluating";
const isNew = (f: ReleaseDetailsDTO) =>
  f.releasedAt && isDatePastWeek(f.releasedAt);

function NewBadge() {
  return (
    <Badge colorScheme="orange" ml={1}>
      New
    </Badge>
  );
}

export default function ReleasesDigestScreenshot() {
  const [searchParams] = useSearchParams();
  const isFetching = useIsFetching();
  const { appId } = useCurrentEnv();

  const { sortBy, sortOrder } = Object.fromEntries(searchParams.entries());

  const { data, isError } = useQuery({
    queryKey: featureQueryKeys.list(appId),

    queryFn: () =>
      api
        .get<"/apps/:appId/releases">(`/apps/${appId}/releases`, {
          params: {
            pageSize: 100,
            sortBy: sortBy as ReleasesQuerySortBy,
            sortOrder: sortOrder as "asc" | "desc",
          },
        })
        .then((res) => res.data.data),
    enabled: !!appId,
  });

  if (isError) return null;

  const evaluatingReleases = data?.filter(isEvaluating) || [];

  return (
    <Box aria-busy={isFetching > 0}>
      <Table releases={evaluatingReleases} />
    </Box>
  );
}

function Table({ releases }: { releases: ReleaseDetailsDTO[] }) {
  const colProps: React.HTMLProps<HTMLTableColElement>[] = [
    { width: "20%" },
    { width: "auto" },
    { width: "50%" },
    { width: "auto" },
  ];

  const columns = ["Name", "Released", "Goals", "Evaluation end"];

  return (
    <SimpleTable
      colProps={colProps}
      columns={columns}
      id={SCREENSHOT_SELECTOR_ID}
      rows={releases}
      rowTemplate={(release, index) => (
        <ReleaseRow key={index} release={release} />
      )}
      size="md"
    />
  );
}

function ReleaseRow({ release }: { release: ReleaseDetailsDTO }) {
  const tightColumnGroupSpacing = 1;

  const isReleaseNew = isNew(release);

  const diff =
    dayjs
      .utc(release.releasedAt)
      .add(release.evaluationPeriod ?? 0, "days")
      .diff(dayjs.utc(), "days") + 1;
  const diffWords =
    diff > 0 ? `${diff} ${pluralize("day", diff)} left` : "Ends today";

  return (
    <Tr>
      {/* Name */}
      <Td paddingInlineEnd={0}>
        {release.name} {isReleaseNew && <NewBadge />}
      </Td>

      {/* Released */}
      <Td paddingInlineEnd={tightColumnGroupSpacing}>
        {release.releasedAt ? (
          <HStack spacing={1}>
            <Box color="dimmed">
              <RiSendPlaneLine aria-hidden="true" size="16" />
            </Box>
            <TimestampCell nearestUnit="day" value={release.releasedAt} />
          </HStack>
        ) : (
          <NotAvailableCell />
        )}
      </Td>

      {/* Goals */}
      <Td
        fontSize="sm"
        minW={7}
        px={tightColumnGroupSpacing}
        textAlign="right"
        whiteSpace="nowrap"
      >
        <GoalCell goals={release.goals} withTooltip={false} />
      </Td>

      {/* Evaluation end */}
      <Td paddingInlineEnd={tightColumnGroupSpacing}>{diffWords}</Td>
    </Tr>
  );
}
