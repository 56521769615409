import { useQuery } from "@tanstack/react-query";

import { FeedbackQueryType } from "@bucketco/shared/feedbackAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import feedbackQueryKeys from "@/feedback/data/feedbackQueryKeys";

const paramDefaults: Omit<FeedbackQueryType, "envId"> = {
  pageIndex: 0,
  pageSize: 4,
  satisfaction: [0, 1, 2, 3, 4, 5],
  funnelSteps: ["company", "segment", "tried", "adopted", "retained"],
  sortBy: "timestamp",
  sortOrder: "desc",
};

export function useFeedbacks(options: Partial<FeedbackQueryType> = {}) {
  const { appId, envId } = useCurrentEnv();
  const params = { ...paramDefaults, ...options };
  return useQuery({
    queryKey: feedbackQueryKeys.list(appId, envId, params),

    queryFn: () =>
      api
        .get<"/apps/:appId/feedbacks">(`/apps/${appId}/feedbacks`, {
          params: { ...params, envId: envId! },
        })
        .then((res) => res.data),
    enabled: !!appId && !!envId,
  });
}
