import { ReactNode } from "react";
import { RiSlackFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Box, Button, HStack, Text, useColorModeValue } from "@chakra-ui/react";

import { GlobalSettingsUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import SlackLogo from "@/common/assets/slack-hash-logo.svg?react";

function ButtonDivider() {
  return (
    <Box
      borderLeft="1px solid"
      borderLeftColor={useColorModeValue("gray.100", "gray.650")}
      h={4}
      verticalAlign="middle"
      w="1px"
    ></Box>
  );
}

interface SlackDigestReportEntity {
  slackChannelId: string | null | undefined;
  slackChannelName: string | null | undefined;
  slackWeeklyDigest: boolean | undefined;
}

export function SlackWeeklyDigestStatus({
  weeklyDigestEntity,
}: {
  weeklyDigestEntity: SlackDigestReportEntity;
}) {
  const { currentOrg, currentEnv } = useAuthContext();
  let content: ReactNode = null;

  const inactiveColor = useColorModeValue("gray.400", "gray.600");
  const activeColor = useColorModeValue("brand.500", "brand.400");

  if (!currentOrg || !currentEnv || !weeklyDigestEntity) return null;

  if (currentOrg.hasSlackConnection) {
    if (weeklyDigestEntity.slackChannelId) {
      if (weeklyDigestEntity.slackWeeklyDigest) {
        content = (
          <HStack>
            <Text>{weeklyDigestEntity?.slackChannelName}</Text>
            <ButtonDivider />
            <Text color={activeColor}>Weekly digest on</Text>
          </HStack>
        );
      } else {
        content = (
          <HStack>
            <Text>{weeklyDigestEntity?.slackChannelName}</Text>
            <ButtonDivider />
            <Text color={inactiveColor}>Weekly digest off</Text>
          </HStack>
        );
      }
    } else {
      content = (
        <HStack color={inactiveColor}>
          <Text>No channel</Text>
          <ButtonDivider />
          <Text>Weekly digest off</Text>
        </HStack>
      );
    }
  } else {
    content = (
      <HStack color={inactiveColor}>
        <Text>Not connected</Text>
        <ButtonDivider />
        <Text>Weekly digest off</Text>
      </HStack>
    );
  }

  return (
    <Button
      as={Link}
      fontWeight="medium"
      size="sm"
      to={GlobalSettingsUrl(currentEnv, "app-feature-views")}
      variant="outline"
    >
      <Box mr={2}>
        {currentOrg.hasSlackConnection ? (
          <SlackLogo width="15px" />
        ) : (
          <Box color={inactiveColor}>
            <RiSlackFill size="19px" />
          </Box>
        )}
      </Box>
      {content}
    </Button>
  );
}
