import { useMemo } from "react";

import { useSearchArrayParam } from "@/common/hooks/useSearchParam";
import { useSegments } from "@/company/data/useSegments";

export const useSubsegmentParam = (fallback?: string[] | null) => {
  const { data: segments } = useSegments();

  const allowlist = useMemo(
    () => (segments ? segments.map((segment) => segment.id) : []),
    [segments],
  );

  const fallbackSegments = useMemo(() => {
    if (!fallback?.length) return undefined;
    return fallback.flatMap((idOrName) => {
      const segmentId = segments?.find(
        (segment) => segment.id === idOrName || segment.name === idOrName,
      )?.id;
      return segmentId ? [segmentId] : [];
    });
  }, [fallback, segments]);

  return useSearchArrayParam("subsegment", {
    fallback: fallbackSegments,
    allowlist,
  });
};
