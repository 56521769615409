import { useMemo } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  HStack,
  Link,
  StackProps,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";

import {
  FeatureCompaniesQueryType,
  FeatureCompany,
} from "@bucketco/shared/featureAPI";
import { FeatureCompaniesUrl, FeatureCompanyUrl } from "@bucketco/shared/urls";

import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import { SectionHeading } from "@/common/components/SectionHeading";
import TimestampCell from "@/common/components/TimestampCell";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import { linkClickModifierHandler } from "@/common/utils/linkClickHandler";
import pluralize from "@/common/utils/pluralize";
import { FeedbackSatisfactionIcon } from "@/feature-legacy/components/FeedbackSatisfaction";
import useFeatureCompaniesData from "@/feature-legacy/data/useFeatureCompaniesData";

type ConfigKey = "tried" | "retained" | "churned";

const configs: Record<
  ConfigKey,
  Pick<FeatureCompaniesQueryType, "funnelSteps"> & {
    title: string;
    dateColumn: { key: "firstUsed" | "lastUsed"; label: string };
  }
> = {
  tried: {
    title: "Tried",
    funnelSteps: ["tried", "retained", "adopted"],
    dateColumn: { key: "firstUsed", label: "First used" },
  },
  retained: {
    title: "Retained",
    funnelSteps: ["retained"],
    dateColumn: { key: "lastUsed", label: "Last used" },
  },
  churned: {
    title: "Churned",
    funnelSteps: ["adopted"],
    dateColumn: { key: "lastUsed", label: "Last used" },
  },
};

type Props = StackProps & {
  configKey: ConfigKey;
  maxCompanies?: number;
  isDisabled?: boolean;
};

export function CompaniesSnippet({
  configKey,
  maxCompanies = 4,
  isDisabled,
  ...rest
}: Props) {
  const navigate = useNavigate();
  const { envId, envName, featureId, featureName } = useParams();
  const [subsegment] = useSubsegmentParam();
  const envUrlArg = useMemo(
    () => ({ id: envId!, name: envName || "" }),
    [envId, envName],
  );
  const featureUrlArg = useMemo(
    () => ({ id: featureId!, name: featureName || "" }),
    [featureId, featureName],
  );

  const { title, funnelSteps, dateColumn } = configs[configKey];

  const { data, isFetching, isLoading } = useFeatureCompaniesData(
    featureId,
    {
      pageIndex: 0,
      pageSize: maxCompanies,
      sortBy: dateColumn.key,
      sortOrder: "desc",
      funnelSteps,
      satisfaction: [],
      subsegment: subsegment[0],
      useTargetingRules: false,
    },
    !isDisabled,
  );

  const companies = (data?.data ?? []) as FeatureCompany[];

  return (
    <VStack alignItems="flex-start" spacing={4} {...rest}>
      <HStack>
        <SectionHeading>{title}</SectionHeading>
        {(isLoading || isFetching) && (
          <AnimatedSpinner show={isFetching} size="sm" />
        )}
      </HStack>
      <Box
        border="1px solid"
        borderColor="appBorder"
        borderRadius="md"
        flexGrow={1}
        w="full"
      >
        {companies.length ? (
          <Table size="sm" variant="clickable" wordBreak="break-word">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>
                  <HStack spacing={0}>
                    <Text>{dateColumn.label}</Text>
                    <RiArrowDropDownLine
                      aria-label="sorted descending"
                      size={18}
                    />
                  </HStack>
                </Th>
                <Th w="auto">Sat.</Th>
              </Tr>
            </Thead>
            <Tbody>
              {companies.map((company, index) => (
                <Tr
                  key={company.id}
                  onClick={(e) => {
                    const url = FeatureCompanyUrl(
                      envUrlArg,
                      company.id,
                      featureId!,
                    );

                    linkClickModifierHandler(url, () => navigate(url))(e);
                  }}
                >
                  <Td borderBottomWidth={index === maxCompanies - 1 ? 0 : 1}>
                    <HStack>
                      <Avatar name={company.name || company.id} size="2xs" />
                      <Text fontSize="sm" fontWeight="medium" noOfLines={1}>
                        {company.name || company.id}
                      </Text>
                    </HStack>
                  </Td>
                  <Td borderBottomWidth={index === maxCompanies - 1 ? 0 : 1}>
                    {company[dateColumn.key] ? (
                      <TimestampCell
                        value={company[dateColumn.key]!}
                        leftAlign
                      />
                    ) : (
                      <NotAvailableCell />
                    )}
                  </Td>
                  <Td borderBottomWidth={index === maxCompanies - 1 ? 0 : 1}>
                    {company.satisfaction ? (
                      <FeedbackSatisfactionIcon
                        colored={true}
                        value={company.satisfaction}
                      />
                    ) : (
                      <NotAvailableCell />
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Text
            alignItems="center"
            as="p"
            display="flex"
            fontSize="sm"
            height="full"
            justifyContent="center"
            py={8}
            variant="dimmed"
          >
            {isDisabled
              ? "Not available for this feature"
              : `No companies have ${configKey} yet`}
          </Text>
        )}
      </Box>
      {data?.totalCount ? (
        <Link
          as={RouterLink}
          color="dimmed"
          fontSize="sm"
          height={6}
          to={FeatureCompaniesUrl(envUrlArg, featureUrlArg, {
            funnelSteps,
            subsegment: subsegment[0],
          })}
        >
          See all {data.totalCount} {pluralize("company", data.totalCount)}
        </Link>
      ) : (
        <Box height={6} />
      )}
    </VStack>
  );
}
