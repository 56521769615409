import {
  AuditChartAxisMetricKey,
  AXIS_METRICS,
} from "@/common/charts/components/AuditChart/types";

export function useAuditChartMetrics(): {
  key: AuditChartAxisMetricKey;
  label: string;
}[] {
  const keys = Object.keys(AXIS_METRICS) as AuditChartAxisMetricKey[];
  return keys.map((key) => {
    return {
      key,
      label: AXIS_METRICS[key].label,
    };
  });
}
