import { Link, Outlet, useMatch } from "react-router-dom";
import {
  Button,
  Card,
  Center,
  HStack,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

import { EnvironmentUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";

export function NonProductionLockdown() {
  const { currentEnv, currentApp } = useAuthContext();
  const prodEnv = currentApp?.environments.find((env) => env.isProduction);

  const match = useMatch("/envs/:envId/*");
  const envUrl = EnvironmentUrl(prodEnv!);
  const fullEnvUrl = `${envUrl}/${match ? match.params["*"] : ""}`;

  const pageBackground = useColorModeValue("gray.25", "gray.850");

  if (currentEnv?.isProduction) {
    return <Outlet />;
  }

  return (
    <Center bg={pageBackground} flexGrow={1}>
      <Card p={8}>
        <VStack maxW="sm" spacing={4} textAlign="center">
          <HStack as="h2" fontSize="lg" fontWeight="medium" spacing={1}>
            <Text>Not accessible in enviroment</Text>
          </HStack>
          <Text color="dimmed" fontSize="sm" sx={{ textWrap: "balance" }}>
            You&apos;re in{" "}
            <EnvironmentDisplayName
              display="inline-flex"
              environment={currentEnv!}
              verticalAlign="bottom"
            />{" "}
            but releases are only available in{" "}
            <EnvironmentDisplayName
              display="inline-flex"
              environment={prodEnv!}
              verticalAlign="bottom"
            />
          </Text>
          <Button as={Link} to={fullEnvUrl} variant="outline">
            Switch to Production
          </Button>
        </VStack>
      </Card>
    </Center>
  );
}
