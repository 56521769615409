import { FunnelStep } from "@bucketco/shared/featureAPI";

import { StarsFunnelStateVisualization } from "@/feature/components/StarsFunnelStateVisualization";
import { StarsFunnelStepVisualization } from "@/feature/components/StarsFunnelStepVisualization";

export type StarsFunnelDescription = {
  id: FunnelStep;
  label: string;
  description: string;
  visualization: JSX.Element;
};

export const starsFunnelStepDescriptions: StarsFunnelDescription[] = [
  {
    id: "company",
    label: "None",
    description: "outside target segment",
    visualization: <StarsFunnelStepVisualization step="company" />,
  },
  {
    id: "segment",
    label: "Segment",
    description: "never interacted",
    visualization: <StarsFunnelStepVisualization step="segment" />,
  },
  {
    id: "tried",
    label: "Tried",
    description: "only tried",
    visualization: <StarsFunnelStepVisualization step="tried" />,
  },
  {
    id: "adopted",
    label: "Adopted",
    description: "used but then churned",
    visualization: <StarsFunnelStepVisualization step="adopted" />,
  },
  {
    id: "retained",
    label: "Retained",
    description: "currently using it",
    visualization: <StarsFunnelStepVisualization step="retained" />,
  },
];

export const starsFunnelStateDescriptions: StarsFunnelDescription[] = [
  {
    id: "company",
    label: "None",
    description: "outside target Segment",
    visualization: <StarsFunnelStateVisualization state="none" />,
  },

  {
    id: "segment",
    label: "Never",
    description: "never interacted (in Segment step)",
    visualization: <StarsFunnelStateVisualization state="never" />,
  },
  {
    id: "tried",
    label: "Tried",
    description: "only tried the feature (in Tried step)",
    visualization: <StarsFunnelStateVisualization state="tried" />,
  },
  {
    id: "retained",
    label: "Retained",
    description: "currently using it (in Retained step)",
    visualization: <StarsFunnelStateVisualization state="retained" />,
  },
  {
    id: "adopted",
    label: "Churned",
    description: "used but then churned (in Adopted step)",
    visualization: <StarsFunnelStateVisualization state="churned" />,
  },
];

export const starsFunnelStepDescriptionsSegmentRemoved: StarsFunnelDescription[] =
  [
    {
      id: "company",
      label: "None",
      description: "never interacted",
      visualization: <StarsFunnelStepVisualization step="company" />,
    },
    {
      id: "tried",
      label: "Tried",
      description: "only tried",
      visualization: <StarsFunnelStepVisualization step="tried" />,
    },
    {
      id: "adopted",
      label: "Adopted",
      description: "used but then churned",
      visualization: <StarsFunnelStepVisualization step="adopted" />,
    },
    {
      id: "retained",
      label: "Retained",
      description: "currently using it",
      visualization: <StarsFunnelStepVisualization step="retained" />,
    },
  ];

export const starsFunnelStateDescriptionsSegmentRemoved: StarsFunnelDescription[] =
  [
    {
      id: "company",
      label: "Never",
      description: "never interacted",
      visualization: <StarsFunnelStateVisualization state="never" />,
    },
    {
      id: "tried",
      label: "Tried",
      description: "only tried the feature (in Tried step)",
      visualization: <StarsFunnelStateVisualization state="tried" />,
    },
    {
      id: "retained",
      label: "Retained",
      description: "currently using it (in Retained step)",
      visualization: <StarsFunnelStateVisualization state="retained" />,
    },
    {
      id: "adopted",
      label: "Churned",
      description: "used but then churned (in Adopted step)",
      visualization: <StarsFunnelStateVisualization state="churned" />,
    },
  ];
