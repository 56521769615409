import { useMemo, useState } from "react";

import {
  FeatureListItem,
  FeaturesMetricsHistoricalData,
  SegmentedList,
} from "@bucketco/shared/featureAPI";
import { getFraction } from "@bucketco/shared/utils/getFraction";

import {
  AuditChartDataItem,
  AuditChartOptions,
} from "@/common/charts/components/AuditChart/types";

export type UseAuditChartResult = ReturnType<typeof useAuditChart>;
export type UseAuditChartProps = {
  featureLists?: SegmentedList<FeatureListItem[]>[];
  historicalSummaries?: SegmentedList<FeaturesMetricsHistoricalData>[];
  isFeaturesLoading: boolean;
  isHistoricalLoading: boolean;
  options: AuditChartOptions;
};

export function useAuditChart({
  featureLists = [],
  historicalSummaries = [],
  isFeaturesLoading,
  isHistoricalLoading,
  options,
}: UseAuditChartProps) {
  const [activeFeature, setActiveFeature] = useState<FeatureListItem | null>(
    null,
  );

  const data = useMemo<SegmentedList<AuditChartDataItem[]>[]>(
    () =>
      featureLists.map((list) => ({
        ...list,
        items: list.items.map((feature) => {
          const current = feature.currentMetrics;
          const historicalList = historicalSummaries.find(
            (f) => f.subsegment?.id === list.subsegment?.id,
          );
          const historical = historicalList?.items[feature.id];
          const period = historical?.[0];

          const currentData = {
            tried:
              current?.tried !== undefined
                ? getFraction(current.tried, current.segment)
                : null,
            adopted:
              current?.adopted !== undefined
                ? getFraction(current.adopted, current.segment)
                : null,
            retained:
              current?.retained !== undefined
                ? getFraction(current.retained, current.segment)
                : null,
            satisfied:
              current?.retained !== undefined &&
              feature.rates?.satisfactionRate !== undefined
                ? feature.rates.satisfactionRate *
                  getFraction(current.retained, current.segment)
                : null,
            adoptionRate: feature.rates?.adoptionRate ?? null,
            retentionRate: feature.rates?.retentionRate ?? null,
            satisfactionRate: feature.rates?.satisfactionRate ?? null,
            averageFrequency:
              feature.source === "attribute"
                ? null
                : (current?.averageFrequency || 0) + 0.5,
          };

          const historicalData = {
            tried: period?.tried ?? null,
            adopted: period?.adopted ?? null,
            retained: period?.retained ?? null,
            satisfied: period?.satisfied ?? null,
            adoptionRate: period?.adoptionRate ?? null,
            retentionRate: period?.retentionRate ?? null,
            satisfactionRate: period?.satisfactionRate ?? null,
            averageFrequency:
              feature.source === "attribute"
                ? null
                : (period?.averageFrequency || 0) + 0.5,
          };

          const hasXData = currentData[options.xAxisMetric] !== null;
          const hasYData = currentData[options.yAxisMetric] !== null;
          const hasBeenEnabled = options.featureVisibilities[feature.id];
          const hasData = hasXData && hasYData;
          const isVisible = hasBeenEnabled && hasData;

          return {
            ...feature,
            current: currentData,
            historical: historicalData,
            subsegment: list.subsegment,
            hasData,
            isVisible,
          };
        }),
      })),
    [
      featureLists,
      historicalSummaries,
      options.xAxisMetric,
      options.yAxisMetric,
      options.featureVisibilities,
    ],
  );

  return {
    data,
    isFeaturesLoading,
    isHistoricalLoading,
    activeFeature,
    setActiveFeature,
    options,
  };
}
