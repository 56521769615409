import React, { useEffect, useRef, useState } from "react";
import { FormProvider } from "react-hook-form";
import { RiDraggable } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  HStack,
  Link,
  List,
  Spacer,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDragControls } from "framer-motion";

import { ErrorResponse } from "@bucketco/shared/api";
import {
  EnvironmentDTO,
  EnvironmentPatchArgs,
  EnvironmentPatchSchema,
  EnvironmentPostArgs,
  EnvironmentPostSchema,
} from "@bucketco/shared/environmentAPI";
import { GlobalSettingsUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import {
  DeleteIconButton,
  EditIconButton,
} from "@/common/components/CommonIconButtons";
import FloatingFormError from "@/common/components/form/FloatingFormError";
import FormCancel from "@/common/components/form/FormCancel";
import FormInput from "@/common/components/form/FormInput";
import { FormRootError } from "@/common/components/form/FormRootError";
import FormSubmit from "@/common/components/form/FormSubmit";
import { ReorderList } from "@/common/components/ReorderList";
import commonQueryKeys from "@/common/data/commonQueryKeys";
import useApiForm from "@/common/hooks/useApiForm";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { IntegrationKey } from "@/environment/components/IntegrationKey";
import { environmentQueryKeys } from "@/environment/data/environmentQueryKeys";
import { useEnvironmentUpdateMutation } from "@/environment/data/useEnvironmentsUpdateMutation";
import { useEnvironments } from "@/environment/hooks/useEnvironments";
import {
  ListItem,
  ListLayout,
  ListLayoutProps,
} from "@/global-settings/components/GlobalSettingsHelpers";

const Layout = (props: ListLayoutProps) => (
  <ListLayout templateColumns="2fr 1fr 1fr 64px" {...props} />
);

type ItemProps = {
  production?: EnvironmentDTO;
  environment: EnvironmentDTO;
  canDrag?: boolean;
  canEdit?: boolean;
};

function Item({ production, environment, canDrag, canEdit }: ItemProps) {
  const toast = useToast();
  const errorToast = useErrorToast();

  const { appId } = useCurrentEnv();
  const queryClient = useQueryClient();

  const dragControls = useDragControls();

  const { form, handleSubmit } = useApiForm(
    (data: EnvironmentPatchArgs) =>
      api
        .patch<"/apps/:appId/environments/:envId">(
          `/apps/${appId}/environments/${environment.id}`,
          data,
        )
        .then((res) => res.data.environment),
    EnvironmentPatchSchema,
    {
      onSuccess: async (environment) => {
        segmentAnalytics.track("Environment Updated", {
          appId,
          environmentId: environment.id,
          environmentName: environment.name,
        });
        await queryClient.invalidateQueries({
          queryKey: commonQueryKeys.bootstrap,
        });
        await queryClient.invalidateQueries({
          queryKey: environmentQueryKeys.list(appId),
        });
        toast({
          title: "Environment updated",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        form.reset({
          name: environment.name,
        });
        setShowEditForm(false);
      },
      onError: (error) => {
        errorToast({
          title: `Failed to update '${environment.name}' environment`,
          description: (error.response?.data as ErrorResponse | null)?.error
            .message,
        });
      },
    },
    {
      defaultValues: {
        name: environment.name,
      },
    },
  );

  const [showEditForm, setShowEditForm] = useState(false);

  return !showEditForm ? (
    <ListItem canDrag={canDrag} dragControls={dragControls} value={environment}>
      {canDrag && (
        <Box
          boxSize={3}
          left={-6}
          position="absolute"
          sx={{
            cursor: "grab",
            "&:active": {
              cursor: "grabbing",
            },
          }}
          onPointerDown={(e) => {
            if (!canDrag) return;
            dragControls.start(e);
          }}
        >
          <RiDraggable />
        </Box>
      )}
      <HStack>
        <EnvironmentDisplayName environment={environment} />
        {environment.isProduction && (
          <Text color="dimmed" fontStyle="italic">
            Default, cannot be changed
          </Text>
        )}
      </HStack>
      <HStack>
        <IntegrationKey value={environment.publishableKey} />
      </HStack>
      <HStack>
        <IntegrationKey value={environment.secretKey} />
      </HStack>
      <Box>
        {canEdit && (
          <>
            <EditIconButton
              label="Edit environment"
              onClick={() => setShowEditForm?.(true)}
            />
            <DeleteEnvironment
              environment={environment}
              production={production}
            />
          </>
        )}
      </Box>
    </ListItem>
  ) : (
    <FormProvider {...form}>
      <EnvironmentForm
        submitLabel="Save"
        onClose={() => {
          setShowEditForm(false);
          form.reset();
        }}
        onSubmit={handleSubmit}
      />
    </FormProvider>
  );
}

function DeleteEnvironment({
  production,
  environment,
}: {
  production?: EnvironmentDTO;
  environment: EnvironmentDTO;
}) {
  const navigate = useNavigate();
  const { currentApp } = useAuthContext();

  const toast = useToast();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  const { mutate: deleteEnvironment, isPending: isDeleteLoading } = useMutation(
    {
      mutationFn: () =>
        api.delete<"/apps/:appId/environments/:envId">(
          `/apps/${currentApp?.id}/environments/${environment.id}`,
        ),

      retry: 0,

      onSuccess: async () => {
        segmentAnalytics.track("Environment Deleted");

        if (production) {
          navigate(GlobalSettingsUrl(production, "app-environments"));
        }

        await queryClient.invalidateQueries({
          queryKey: commonQueryKeys.bootstrap,
        });
        await queryClient.invalidateQueries({
          queryKey: environmentQueryKeys.list(currentApp?.id),
        });

        toast({
          title: "Deleted environment",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      },

      onError: () => {
        errorToast({
          title: `Failed to delete '${environment.name}' environment`,
        });
      },
    },
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  return (
    <>
      <DeleteIconButton
        isDisabled={isDeleteLoading}
        label="Delete environment"
        onClick={onOpen}
      />
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Delete environment</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete{" "}
              <strong>{environment.name}</strong> ?
              <br />
              <br />
              All features using this environment will revert to the default
              Active environment. You can&apos;t undo this action afterwards.
            </AlertDialogBody>
            <AlertDialogFooter>
              <ButtonGroup>
                <Button
                  ref={cancelRef}
                  _hover={{ color: useColorModeValue("gray.700", "gray.300") }}
                  color="gray.500"
                  isDisabled={isDeleteLoading}
                  variant="ghost"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  isLoading={isDeleteLoading}
                  onClick={() => deleteEnvironment()}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

function EnvironmentsList({
  production,
  nonProduction: nonProductionProp,
}: {
  production: EnvironmentDTO;
  nonProduction: EnvironmentDTO[];
}) {
  const [nonProduction, setNonProduction] = useState(nonProductionProp);
  const updateMutation = useEnvironmentUpdateMutation();

  useEffect(() => {
    setNonProduction(nonProductionProp);
  }, [nonProductionProp]);

  if (!production && !nonProduction.length) {
    return (
      <Alert status="info">
        <AlertIcon />
        <AlertDescription>
          This application has no environments
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <>
      <Layout
        color="dimmed"
        fontSize="sm"
        fontWeight="medium"
        minH="auto"
        py={1}
      >
        <Box>Name</Box>
        <Box>Publishable key</Box>
        <Box>Secret key</Box>
      </Layout>
      {/* Static list of production environments */}
      <List display="flex" flexDir="column" gap={2}>
        <Item key={production.id} environment={production} />;
      </List>
      {/* Reorderable list of nonproduction environments */}
      <ReorderList
        axis="y"
        display="flex"
        flexDir="column"
        gap={2}
        values={nonProduction}
        onDrop={(environment, order) => {
          updateMutation.mutateAsync({
            id: environment.id,
            order: order + 1, // 1-based index due to production being the first
          });
        }}
        onReorder={setNonProduction}
      >
        {nonProduction.map((environment) => {
          return (
            <Item
              key={environment.id}
              environment={environment}
              production={production}
              canDrag
              canEdit
            />
          );
        })}
      </ReorderList>
    </>
  );
}

function EnvironmentForm({
  onClose,
  onSubmit,
  submitLabel,
}: {
  onClose: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  submitLabel: string;
}) {
  return (
    <form onSubmit={onSubmit}>
      <Layout py={4} templateColumns="1fr 1fr 64px" showAsCard>
        <Box>
          <FloatingFormError name="name">
            <Box>
              <FormInput
                autoComplete="off"
                bg="appBackground"
                name="name"
                placeholder="Name your environment"
                showErrors={false}
                autoFocus
              />
            </Box>
          </FloatingFormError>
        </Box>
        <Spacer />
        <Box justifySelf="end">
          <FormRootError />
          <ButtonGroup>
            <FormCancel onClick={() => onClose()} />
            <FormSubmit>{submitLabel}</FormSubmit>
          </ButtonGroup>
        </Box>
      </Layout>
    </form>
  );
}

function CreateEnvironment() {
  const { currentApp } = useAuthContext();

  const toast = useToast();
  const errorToast = useErrorToast();
  const queryClient = useQueryClient();

  const { form, handleSubmit } = useApiForm(
    (data: EnvironmentPostArgs) =>
      api
        .post<"/apps/:appId/environments">(
          `/apps/${currentApp?.id}/environments`,
          {
            name: data.name || "",
          },
        )
        .then((res) => res.data.environment),
    EnvironmentPostSchema,
    {
      onSuccess: async (environment) => {
        segmentAnalytics.track("Environment Created", {
          appId: currentApp?.id,
          environmentId: environment.id,
          environmentName: environment.name,
        });
        await queryClient.invalidateQueries({
          queryKey: commonQueryKeys.bootstrap,
        });
        await queryClient.invalidateQueries({
          queryKey: environmentQueryKeys.list(currentApp?.id),
        });
        toast({
          title: "Environment created",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        form.reset({
          name: "",
        });
        setShowCreateForm(false);
      },
      onError: (error, { name }) => {
        errorToast({
          title: `Failed to create '${name}' environment`,
          description: (error.response?.data as ErrorResponse | null)?.error
            .message,
        });
      },
    },
    {
      defaultValues: {
        name: "",
      },
    },
  );

  const [showCreateForm, setShowCreateForm] = useState(false);

  return !showCreateForm ? (
    <Layout px={0}>
      <Box>
        <Button variant="outline" onClick={() => setShowCreateForm((c) => !c)}>
          New environment
        </Button>
      </Box>
    </Layout>
  ) : (
    <FormProvider {...form}>
      <EnvironmentForm
        submitLabel="Create"
        onClose={() => {
          setShowCreateForm(false);
          form.reset();
        }}
        onSubmit={handleSubmit}
      />
    </FormProvider>
  );
}

export default function Environments() {
  const envQuery = useEnvironments();

  useEffect(() => {
    segmentAnalytics.page("App Environment Settings");
  }, []);

  return (
    <Flex direction="column" gap={2} maxW="5xl">
      <Heading as="h3" fontSize="md">
        Environments
      </Heading>
      <Text color="dimmed" fontSize="sm" maxWidth="2xl" mb={4}>
        Environments in Bucket allow you to contain feature data to a specific
        environment and manage feature flags throughout your development
        lifecycle, from local development through to staging and production.{" "}
        <Link
          href="https://docs.bucket.co/product-handbook/environments"
          target="_blank"
        >
          Learn more
        </Link>
      </Text>
      {envQuery.isLoading ? (
        <AnimatedSpinner size="sm" show />
      ) : !envQuery.isSuccess ? (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>Could not load environments</AlertDescription>
        </Alert>
      ) : (
        <>
          <EnvironmentsList
            nonProduction={envQuery.data.nonProduction}
            production={envQuery.data.production}
          />
          <CreateEnvironment />
        </>
      )}
    </Flex>
  );
}
