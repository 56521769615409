import { ReactElement, useMemo } from "react";
import { RiChat1Line, RiPulseLine } from "react-icons/ri";
import { TbPercentage } from "react-icons/tb";
import { useToken } from "@chakra-ui/react";

import { GoalFeatureMetric } from "@bucketco/shared/goalAPI";

import StarsChartIcon from "@/common/components/StarsChartIcon";

export const METRIC_CATEGORIES = [
  { id: "steps", title: "STARS step" },
  { id: "rates", title: "STARS rate" },
  { id: "other", title: "Other" },
] as const;

export type GoalMetricCategoryId = (typeof METRIC_CATEGORIES)[number]["id"];
export type GoalMetricOption = {
  id: string;
  label: string;
  icon: ReactElement;
  restriction: "event-based" | "none";
  categoryId: GoalMetricCategoryId;
  measurements: {
    id: GoalFeatureMetric;
    type: "percentage" | "count";
    maxThreshold: number;
    defaultThreshold: number;
  }[];
};

export default function useGoalMetricOptions(): GoalMetricOption[] {
  const iconColor = useToken("colors", "dimmed");

  return useMemo(
    () => [
      {
        id: "tried",
        label: "Tried",
        icon: <StarsChartIcon step="tried" />,
        restriction: "event-based",
        categoryId: "steps",
        measurements: [
          {
            id: "tried",
            type: "percentage",
            defaultThreshold: 0.9,
            maxThreshold: 1,
          },
          {
            id: "triedCount",
            type: "count",
            defaultThreshold: 5,
            maxThreshold: Infinity,
          },
        ],
      },
      {
        id: "adopted",
        label: "Adopted",
        icon: <StarsChartIcon step="adopted" />,
        restriction: "none",
        categoryId: "steps",
        measurements: [
          {
            id: "adopted",
            type: "percentage",
            defaultThreshold: 0.9,
            maxThreshold: 1,
          },
          {
            id: "adoptedCount",
            type: "count",
            defaultThreshold: 5,
            maxThreshold: Infinity,
          },
        ],
      },
      {
        id: "retained",
        label: "Retained",
        icon: <StarsChartIcon step="retained" />,
        restriction: "none",
        categoryId: "steps",
        measurements: [
          {
            id: "retained",
            type: "percentage",
            defaultThreshold: 0.8,
            maxThreshold: 1,
          },
          {
            id: "retainedCount",
            type: "count",
            defaultThreshold: 5,
            maxThreshold: Infinity,
          },
        ],
      },
      {
        id: "satisfied",
        label: "Satisfied",
        icon: <StarsChartIcon step="satisfied" />,
        restriction: "none",
        categoryId: "steps",
        measurements: [
          {
            id: "satisfied",
            type: "percentage",
            defaultThreshold: 0.7,
            maxThreshold: 1,
          },
          {
            id: "satisfiedCount",
            type: "count",
            defaultThreshold: 5,
            maxThreshold: Infinity,
          },
        ],
      },
      {
        id: "adoptionRate",
        label: "Adoption rate",
        icon: <TbPercentage color={iconColor} />,
        restriction: "none",
        categoryId: "rates",
        measurements: [
          {
            id: "adoptionRate",
            type: "percentage",
            defaultThreshold: 0.9,
            maxThreshold: 1,
          },
        ],
      },
      {
        id: "retentionRate",
        label: "Retention rate",
        icon: <TbPercentage color={iconColor} />,
        restriction: "none",
        categoryId: "rates",
        measurements: [
          {
            id: "retentionRate",
            type: "percentage",
            defaultThreshold: 0.8,
            maxThreshold: 1,
          },
        ],
      },
      {
        id: "satisfaction",
        label: "Satisfaction rate",
        icon: <TbPercentage color={iconColor} />,
        restriction: "none",
        categoryId: "rates",
        measurements: [
          {
            id: "satisfactionRate",
            type: "percentage",
            defaultThreshold: 0.7,
            maxThreshold: 1,
          },
        ],
      },
      {
        id: "feedback",
        label: "Feedback count",
        icon: <RiChat1Line color={iconColor} />,
        restriction: "none",
        categoryId: "other",
        measurements: [
          {
            id: "feedbackCount",
            type: "count",
            defaultThreshold: 5,
            maxThreshold: Infinity,
          },
        ],
      },
      {
        id: "frequency",
        label: "Frequency average",
        icon: <RiPulseLine color={iconColor} />,
        restriction: "none",
        categoryId: "other",
        measurements: [
          {
            id: "averageFrequency",
            type: "count",
            defaultThreshold: 3,
            maxThreshold: 4,
          },
        ],
      },
    ],
    [iconColor],
  );
}
