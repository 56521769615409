import { useEffect, useRef, useState } from "react";

export function useThrottle<T>(
  value: T,
  interval = 500,
  callback?: (throttledValue: T) => void,
) {
  const [throttledValue, setThrottledValue] = useState<T>(value);
  const lastUpdated = useRef<number>(0);

  useEffect(() => {
    const now = Date.now();

    if (now >= lastUpdated.current + interval) {
      lastUpdated.current = now;
      setThrottledValue(value);
      callback?.(value);
    } else {
      const id = window.setTimeout(() => {
        lastUpdated.current = now;
        setThrottledValue(value);
        callback?.(value);
      }, interval);

      return () => window.clearTimeout(id);
    }
  }, [value, interval, callback]);

  return throttledValue;
}
