import { useCallback, useState } from "react";

import { FeatureListItem } from "@bucketco/shared/featureAPI";

import { AuditChartDataItem } from "@/common/charts/components/AuditChart/types";

export function useAuditChartPopover({
  setActiveFeature,
}: {
  setActiveFeature: (feature: FeatureListItem | null) => void;
}) {
  const [anchorPosition, setAnchorPosition] = useState<DOMRect | null>(null);
  const [feature, setFeature] = useState<AuditChartDataItem | null>(null);
  const [isActive, setIsActive] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const show = useCallback(
    (
      feature: AuditChartDataItem,
      options: { anchorElement?: Element } = {},
    ) => {
      if (options.anchorElement !== undefined) {
        const rect = options.anchorElement.getBoundingClientRect();
        setAnchorPosition(rect);
      }

      setFeature(feature);
      setIsActive(true);
    },
    [],
  );

  const remove = useCallback(() => {
    setFeature(null);
    setIsActive(false);
    setIsHovered(false);
    setAnchorPosition(null);
    setActiveFeature(null);
  }, [setActiveFeature]);

  return {
    anchorPosition,
    setAnchorPosition,
    feature,
    isActive,
    isHovered,
    setIsHovered,
    show,
    remove,
  };
}
