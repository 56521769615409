import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import {
  EnvironmentDTO,
  EnvironmentPatchArgs,
} from "@bucketco/shared/environmentAPI";

import commonQueryKeys from "@/common/data/commonQueryKeys";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

import { environmentQueryKeys } from "./environmentQueryKeys";

type EnvironmentPatchArgsWithId = EnvironmentPatchArgs & {
  id: string;
};

export function useEnvironmentUpdateMutation(
  options: Omit<
    UseMutationOptions<
      EnvironmentDTO,
      AxiosError<ErrorResponse>,
      EnvironmentPatchArgsWithId
    >,
    "mutationKey" | "mutationFn"
  > = {},
) {
  const { appId } = useCurrentEnv();
  const queryClient = useQueryClient();
  const { onSuccess, ...otherOptions } = options;

  return useMutation({
    mutationKey: ["environment"],

    mutationFn: async (data) => {
      const { id, ...updatedData } = data;
      return api
        .patch<"/apps/:appId/environments/:envId">(
          `/apps/${appId}/environments/${id}`,
          updatedData,
        )
        .then((res) => res.data.environment);
    },

    onSuccess: (updatedEnvironment, data, context) => {
      queryClient.invalidateQueries({ queryKey: commonQueryKeys.bootstrap });
      queryClient.invalidateQueries({
        queryKey: environmentQueryKeys.list(appId),
      });

      onSuccess?.(updatedEnvironment, data, context);
    },

    ...otherOptions,
  });
}
