import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { CompanyList } from "@bucketco/shared/companyAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

import companyQueryKeys from "./companyQueryKeys";

export type CompanySearchType = { id: string; name?: string };

export function useCompanyAutosuggest(needle = "") {
  const { envId, appId } = useCurrentEnv();

  const queryParams = {
    pageSize: 50,
    pageIndex: 0,
    sortBy: "name" as const,
    sortOrder: "asc" as const,
    idNameFilter: needle,
  };

  const companyNameSearchQuery = useQuery<
    CompanyList,
    AxiosError<ErrorResponse>,
    CompanySearchType[]
  >({
    queryKey: companyQueryKeys.companyNames(appId, envId, queryParams),

    queryFn: () =>
      api
        .get<"/apps/:appId/companies">(`/apps/${appId}/companies`, {
          params: { ...queryParams, envId: envId! },
        })
        .then((res) => res.data),
    select: (payload) =>
      payload.data.map((company) => ({
        id: company.id,
        name: company.name ?? undefined,
      })),
    enabled: !!appId && !!envId,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    placeholderData: keepPreviousData,
  });

  return companyNameSearchQuery;
}
