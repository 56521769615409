import React, { ReactNode, useEffect, useMemo, useRef } from "react";
import {
  RiArrowDropDownLine,
  RiArrowDropUpLine,
  RiCloseLine,
  RiSettings4Line,
} from "react-icons/ri";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  Table,
  TableProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  ColumnDef,
  ColumnSort,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  HeaderContext,
  Row,
  TableMeta,
  TableState,
  useReactTable,
} from "@tanstack/react-table";
import { useLocalStorage } from "usehooks-ts";

import { ColumnState } from "@bucketco/shared/types/columns";

import ErrorBoundary from "@/common/components/ErrorBoundary";
import { FillScrollWrapper } from "@/common/components/FillScrollWrapper";
import { MenuReorderItemOption } from "@/common/components/Menu/MenuReorderItemOption";
import { MenuReorderOptionGroup } from "@/common/components/Menu/MenuReorderOptionGroup";
import { MenuArrow } from "@/common/components/MenuArrow";
import MenuDescription from "@/common/components/MenuDescription";
import type useDataTable from "@/common/hooks/useDataTable";
import { useOverflow } from "@/common/hooks/useOverflow";
import { DragType } from "@/common/types/dragDropTypes";
import {
  getColumnIds,
  getId,
  getOrderFromStates,
  getVisibilityFromIds,
  getVisibilityFromStates,
  stickyHorizontalProps,
  stickyVerticalProps,
} from "@/common/utils/datatable";
import { flattenItemTree } from "@/common/utils/listItemTree";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

export type DataTableProps<
  Data extends { subRows?: Data[]; id: string },
  TMeta = unknown,
> = TableProps & {
  trackingId: string;
  columns: ColumnDef<Data, any>[];
  columnStates: ColumnState[];
  setColumnStates: (nextColumnStates: ColumnState[]) => void;
  data: Data[];
  meta?: TableMeta<TMeta>;
  pageCount: number;
  sortBy: ColumnSort;
  setSortBy: (nextSortBy: ColumnSort) => void;
  setCanPaginate: ({
    canNextPage,
    canPreviousPage,
  }: {
    canNextPage: boolean;
    canPreviousPage: boolean;
  }) => void;
  setPaginateActions: ({
    nextPage,
    previousPage,
  }: {
    nextPage: () => void;
    previousPage: () => void;
  }) => void;
  fetchData: ReturnType<typeof useDataTable>["fetchData"];
  isFetching: boolean;
  children?: ReactNode;
  /**
   * Make the tables <thead> sticky to the top of the table when scrolling.
   *
   * This assumes that the DataTable is a flex child in a vertical flexbox
   */
  freezeHeader?: boolean;
  freezeFirstColumn?: boolean;
  rowOnClick?: (
    row: Row<Data>,
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => void;
  defaultColumns?: string[];
  scrollable?: boolean;
  customizableFooter?: ReactNode;
  expandedPersistenceId?: string;
};

export function DataTable<
  Data extends { id: string; subRows?: Data[] },
  TSortableHeaders extends string,
>({
  trackingId,
  columns,
  data,
  pageCount,
  sortBy,
  setCanPaginate,
  setPaginateActions,
  fetchData,
  freezeHeader = true,
  freezeFirstColumn = true,
  rowOnClick,
  children,
  isFetching,
  defaultColumns = getColumnIds(columns),
  scrollable = true,
  customizableFooter,
  meta,
  expandedPersistenceId,
  columnStates,
  setColumnStates,
  setSortBy,
  ...props
}: DataTableProps<Data>) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { refXScrollBegin } = useOverflow(scrollRef);

  const frozenColumnId = useMemo(() => {
    return freezeFirstColumn ? getId(columns[0]) : null;
  }, [freezeFirstColumn, columns]);

  const [collapsedItems, setCollapsedItems] = useLocalStorage<string[]>(
    `collapsed.${expandedPersistenceId}` ?? "N/A",
    [],
    {},
  );

  const tableState: Partial<TableState> = {
    sorting: [sortBy],
    columnVisibility: getVisibilityFromStates(
      columns,
      columnStates || [],
      defaultColumns,
      frozenColumnId,
    ),
    columnOrder: getOrderFromStates(
      columns,
      columnStates || [],
      frozenColumnId,
    ),
  };

  if (expandedPersistenceId && collapsedItems) {
    const expandedState: ExpandedState = {};
    const expandedItems = flattenItemTree(data).filter(
      (i) => !collapsedItems.includes(i.id),
    );

    for (const expandedItem of expandedItems) {
      expandedState[expandedItem.id] = true;
    }

    tableState.expanded = expandedState;
  }

  const table = useReactTable<Data>({
    columns,
    data,
    manualPagination: true,
    manualSorting: true,
    enableSortingRemoval: false,
    enableMultiSort: false,
    enableHiding: true,
    pageCount,
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    initialState: {
      sorting: [sortBy],
      pagination: {
        pageSize: 20,
      },
    },
    state: tableState,
    enableExpanding: true,
    autoResetExpanded: false,
    meta,
    getSubRows: (row) => {
      return "subRows" in row ? (row.subRows as Data[]) : [];
    },
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel<Data>(),
    getPaginationRowModel: getPaginationRowModel<Data>(),
    getSortedRowModel: getSortedRowModel<Data>(),
    getExpandedRowModel: getExpandedRowModel<Data>(),
    onStateChange: (updater) => {
      if (typeof updater === "function") {
        const tableState = updater(table.getState());

        const columnStates: ColumnState[] = tableState.columnOrder.map(
          (key) => ({ id: key, shown: tableState.columnVisibility[key] }),
        );

        setColumnStates(columnStates);
      }
    },
    onExpandedChange: (updater) => {
      if (typeof updater === "function") {
        const before = table.getState().expanded;
        const after = updater(before);

        segmentAnalytics.track("Feature Tree Collapse Toggled", {
          expanded_count: Object.keys(after).length,
        });

        if (expandedPersistenceId) {
          const expandedItems = Object.keys(after);

          const collapsedItems = flattenItemTree(data)
            .map((i) => i.id)
            .filter((id) => !expandedItems.includes(id));

          setCollapsedItems(collapsedItems);
        } else {
          table.setState((prev) => ({ ...prev, expanded: after }));
        }
      }
    },
  });

  const { pageIndex, pageSize } = table.getState().pagination;
  const order = table.getState().columnOrder;
  const shown = table.getVisibleFlatColumns().flatMap((c) => getId(c) ?? []);

  useEffect(() => {
    const fetchSortBy = sortBy.id as TSortableHeaders;
    const fetchSortOrder = sortBy.desc ? "desc" : "asc";
    fetchData({
      pageIndex,
      pageSize,
      sortBy: fetchSortBy as string,
      sortOrder: fetchSortOrder,
    });
  }, [fetchData, pageIndex, pageSize, sortBy]);

  const [canNextPage, canPreviousPage] = [
    table.getCanNextPage(),
    table.getCanPreviousPage(),
  ];

  useEffect(() => {
    setCanPaginate({ canNextPage, canPreviousPage });
  }, [setCanPaginate, canNextPage, canPreviousPage]);

  useEffect(() => {
    setPaginateActions({
      nextPage: table.nextPage,
      previousPage: table.previousPage,
    });
  }, [setPaginateActions, table.nextPage, table.previousPage]);

  function setVisibleColumns(visibleColumns: string[]) {
    table.setColumnVisibility(getVisibilityFromIds(columns, visibleColumns));

    segmentAnalytics.track("Column Visibility Updated", {
      table: trackingId,
    });
  }

  function setColumnOrder(order: string[]) {
    if (frozenColumnId) {
      order = [frozenColumnId, ...order];
    }

    table.setColumnOrder(order);

    segmentAnalytics.track("Column Order Updated", {
      table: trackingId,
    });
  }

  return (
    <ErrorBoundary>
      <FillScrollWrapper ref={scrollRef} enabled={scrollable}>
        <Table isolation="isolate" {...props}>
          {children}
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <Th
                    key={header.id}
                    cursor={header.column.getCanSort() ? "pointer" : undefined}
                    py={2}
                    style={{
                      maxWidth: `${header.column.columnDef.maxSize}px`,
                      minWidth: `${header.column.columnDef.minSize}px`,
                      width:
                        header.getSize() === Number.MAX_SAFE_INTEGER
                          ? "auto"
                          : `${header.getSize()}px`,
                    }}
                    onClick={(event) => {
                      const handler = header.column.getToggleSortingHandler();
                      if (header.column.getCanSort() && handler !== undefined) {
                        const order = header.column.getNextSortingOrder();
                        setSortBy({ id: header.id, desc: order === "desc" });

                        table.setPageIndex(0);
                        handler(event);
                      }
                    }}
                    {...(freezeHeader && stickyVerticalProps)}
                    {...(freezeFirstColumn &&
                      index === 0 && {
                        ...stickyHorizontalProps(
                          !refXScrollBegin ? "appBorder" : "transparent",
                          2,
                        ),
                      })}
                  >
                    <Flex alignItems="center" justifyContent="start">
                      <Box isTruncated>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </Box>
                      <Flex alignItems="center" position="relative">
                        {{
                          asc: (
                            <RiArrowDropUpLine
                              aria-label="sorted ascending"
                              size={24}
                            />
                          ),
                          desc: (
                            <RiArrowDropDownLine
                              aria-label="sorted descending"
                              size={24}
                            />
                          ),
                        }[header.column.getIsSorted() as string] ?? (
                          <Box height="24px" width="24px"></Box>
                        )}
                        <Flex
                          alignItems="center"
                          height="24px"
                          justifyContent="center"
                          position="absolute"
                          right="-16px"
                          width="16px"
                        >
                          {header.column.getIsSorted() && isFetching && (
                            <Spinner size="xs" />
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                  </Th>
                ))}
                <Th
                  p={0}
                  right={0}
                  w="72px"
                  {...stickyVerticalProps}
                  zIndex={2}
                >
                  <Flex direction="row" gap={2}>
                    <Divider height="auto" my={1} orientation="vertical" />
                    <Menu closeOnSelect={false} placement="bottom-end">
                      <MenuButton
                        aria-label="Customize columns"
                        as={IconButton}
                        color="dimmed"
                        icon={<RiSettings4Line size={16} />}
                        size="md"
                        variant="ghost"
                      />
                      <MenuList
                        color="chakra-body-text"
                        // workaround for to avoid horizontal scrollbar when positioned to the right of the screen
                        rootProps={{ style: { transform: "scale(0)" } }}
                      >
                        <MenuArrow />
                        <MenuDescription>
                          Columns to show in the table:
                        </MenuDescription>
                        <MenuDivider my={0} />
                        {freezeFirstColumn && (
                          <>
                            <MenuItem height={8} pl={30} isDisabled>
                              <Checkbox
                                colorScheme="brand"
                                isChecked={true}
                                marginEnd={2}
                                pointerEvents="none"
                                size="sm"
                              />
                              {flexRender(
                                columns[0]?.header,
                                {} as HeaderContext<Data, any>,
                              )}
                            </MenuItem>
                            <MenuDivider my={0} />
                          </>
                        )}
                        <MenuReorderOptionGroup
                          as="ul"
                          order={order}
                          type="checkbox"
                          value={shown}
                          onChange={setVisibleColumns}
                          onOrderChange={setColumnOrder}
                        >
                          {table.getAllFlatColumns().map((column, index) =>
                            !(freezeFirstColumn && index == 0) ? (
                              <MenuReorderItemOption
                                key={column.id}
                                as="li"
                                dragType={DragType.MenuReorderOption}
                                isDisabled={
                                  shown.length === 1 &&
                                  shown.includes(getId(column)!)
                                }
                                rightIcon={
                                  column.columnDef.meta?.isRemovable ? (
                                    <IconButton
                                      aria-label="Remove column"
                                      icon={<RiCloseLine size={16} />}
                                      size="2xs"
                                      variant="ghost"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setColumnStates(
                                          (columnStates || []).filter(
                                            (state) => state.id !== column.id,
                                          ),
                                        );
                                      }}
                                    />
                                  ) : undefined
                                }
                                value={column.id}
                              >
                                {flexRender(
                                  column.columnDef.header,
                                  {} as HeaderContext<Data, any>,
                                )}
                              </MenuReorderItemOption>
                            ) : undefined,
                          )}
                        </MenuReorderOptionGroup>
                        <Flex justify="space-between" px={1}>
                          {customizableFooter}
                          <Button
                            color="dimmed"
                            mx={2}
                            my={2}
                            size="sm"
                            type="reset"
                            variant="ghost"
                            onClick={() => {
                              table.setState((prevState) => ({
                                ...prevState,
                                columnOrder: [],
                                columnVisibility: {},
                              }));
                            }}
                          >
                            Reset
                          </Button>
                        </Flex>
                      </MenuList>
                    </Menu>
                  </Flex>
                </Th>
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Tr
                  key={row.id}
                  id={row.id}
                  onClick={rowOnClick ? (e) => rowOnClick(row, e) : undefined}
                >
                  {row.getVisibleCells().map((cell, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Td
                      key={cell.id}
                      colSpan={
                        index === row.getVisibleCells().length - 1
                          ? 2
                          : undefined
                      }
                      style={{
                        maxWidth: `${cell.column.columnDef.maxSize}px`,
                        minWidth: `${cell.column.columnDef.minSize}px`,
                        width:
                          cell.column.getSize() === Number.MAX_SAFE_INTEGER
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                      }}
                      verticalAlign="top"
                      {...(freezeFirstColumn &&
                        index === 0 && {
                          ...stickyHorizontalProps(
                            !refXScrollBegin ? "appBorder" : "transparent",
                          ),
                        })}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </FillScrollWrapper>
    </ErrorBoundary>
  );
}

// memoize component so it doesn't re-render when props don't change
export const DataTableMemoized = React.memo(DataTable) as typeof DataTable;
export default DataTableMemoized;
