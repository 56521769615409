import { forwardRef } from "react";
import { HStack, Text } from "@chakra-ui/react";
import startCase from "lodash/startCase";

import { frequencyNumberToText } from "@bucketco/shared/featureAPI";

import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import { FrequencyProgress } from "@/common/components/FrequencyProgress";
import SimpleSelect, {
  SimpleSelectProps,
} from "@/common/components/SimpleSelect";
import {
  FormElementBaseProps,
  useManagedFormControlProps,
} from "@/common/hooks/useManagedFormControlProps";
import { mergeFunctions } from "@/common/utils/mergeFunctions";
import { mergeRefs } from "@/common/utils/mergeRefs";

export type FormFrequencyPickerProps = FormElementBaseProps &
  Omit<
    SimpleSelectProps,
    "options" | "valueAsNumber" | "value" | "onChange"
  > & {
    onChange?: (value: number) => void;
  };

export const FormFrequencyPicker = forwardRef<
  HTMLSelectElement,
  FormFrequencyPickerProps
>(({ _control, onChange, onBlur, ...rest }, ref) => {
  const [managedProps, inputProps] = useManagedFormControlProps(rest);
  return (
    <ManagedFormControl
      {..._control}
      {...managedProps}
      render={({ field }) => (
        <SimpleSelect
          {...inputProps}
          {...field}
          ref={mergeRefs(field.ref, ref)}
          options={[0, 1, 2, 3].map((value) => ({
            value: value,
            label: (
              <HStack>
                <FrequencyProgress
                  filledColor="brand.400"
                  frequency={value}
                  showText={false}
                  size="xs"
                />
                <Text>{startCase(frequencyNumberToText(value))}</Text>
              </HStack>
            ),
          }))}
          placeholder="Select a frequency"
          valueAsNumber
          onBlur={mergeFunctions(field.onBlur, onBlur)}
          onChange={mergeFunctions(field.onChange, onChange)}
        />
      )}
    />
  );
});
FormFrequencyPicker.displayName = "FormFrequencyPicker";
