import { Helmet } from "react-helmet-async";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

import {
  CreateAppArgsSchema,
  CreateAppArgsType,
} from "@bucketco/shared/appAPI";
import { EnvironmentUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import CenteredLayout from "@/common/components/CenteredLayout";
import FormInput from "@/common/components/form/FormInput";
import { FormRootError } from "@/common/components/form/FormRootError";
import FormSubmit from "@/common/components/form/FormSubmit";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import SimpleSelect from "@/common/components/SimpleSelect";
import commonQueryKeys from "@/common/data/commonQueryKeys";
import useApiForm from "@/common/hooks/useApiForm";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

function Form() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const boostrapQuery = queryClient.getQueryState(commonQueryKeys.bootstrap);
  const { fetchStatus } = boostrapQuery ?? {};
  const { currentOrg, isLoading } = useAuthContext();

  const { form, handleSubmit } = useApiForm(
    (data: CreateAppArgsType) =>
      api.post<"/apps">("/apps", data).then((res) => res.data.app),
    CreateAppArgsSchema,
    {
      onSuccess: async ({ environments }) => {
        segmentAnalytics.track("App Created");
        await queryClient.invalidateQueries({
          queryKey: commonQueryKeys.bootstrap,
        });
        navigate(EnvironmentUrl(environments[0]));
      },
    },
    {
      defaultValues: {
        organizationId: currentOrg?.id,
      },
    },
  );

  // keep form disabled if bootstrap is fetching after successful submit
  const isDisabled = form.formState.isSubmitting || fetchStatus === "fetching";

  return (
    <form onSubmit={handleSubmit}>
      <FormProvider {...form}>
        <VStack align="flex-start" spacing={6}>
          <FormControl>
            <FormLabel fontSize="lg">Organization</FormLabel>
            <SimpleSelect
              isLoading={isLoading}
              options={
                currentOrg !== undefined
                  ? [
                      {
                        label: currentOrg.name,
                        value: currentOrg.id,
                        icon: (
                          <Avatar
                            name={currentOrg.name || currentOrg.id}
                            size="2xs"
                          />
                        ),
                      },
                    ]
                  : []
              }
              size="lg"
              value={currentOrg?.id}
              w="full"
              isDisabled
              onChange={() => {}}
            />
          </FormControl>

          <Box w="100%">
            <FormInput
              autoComplete="off"
              label="Name"
              name="name"
              placeholder={`${currentOrg?.name} Testing`}
              showErrors={false}
              size="lg"
            />

            <HStack color="gray.500" mt={2}>
              <Text fontSize="sm" maxW="md">
                You can create more apps later
              </Text>
              <InfoIconTooltip
                text="Create apps for each of your
            applications (web, mobile), environments (prod, dev) or products (consumer,
            supplier) in your organization."
              />
            </HStack>
          </Box>

          <FormRootError />

          <FormSubmit isLoading={isDisabled} size="lg">
            Create
          </FormSubmit>
        </VStack>
      </FormProvider>
    </form>
  );
}

export default function NewApp() {
  return (
    <CenteredLayout>
      <Helmet>
        <title>Create New App</title>
      </Helmet>
      <Flex direction="column" w="compactForm">
        <Heading fontSize="3xl" mb={8}>
          Create app
        </Heading>
        <Form />
      </Flex>
    </CenteredLayout>
  );
}
