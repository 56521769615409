import { createContext, ReactNode, useContext } from "react";

import useCompanyPageData from "@/company/data/useCompanyPageData";

type CompanyContextType = Omit<
  ReturnType<typeof useCompanyPageData>,
  "featuresDataTable" | "feedbackDataTable" | "usersDataTables"
> & {
  featuresDataTable:
    | ReturnType<typeof useCompanyPageData>["featuresDataTable"]
    | null;
  feedbackDataTable:
    | ReturnType<typeof useCompanyPageData>["feedbackDataTable"]
    | null;
  usersDataTables:
    | ReturnType<typeof useCompanyPageData>["usersDataTables"]
    | null;
};

const CompanyContext = createContext<CompanyContextType>({
  company: undefined,
  companyIsLoading: true,
  featuresDataTable: null,
  feedbackDataTable: null,
  usersDataTables: null,
  clearFilters: () => {},
});

export function CompanyProvider({ children }: { children: ReactNode }) {
  const companyPageData = useCompanyPageData();

  return (
    <CompanyContext.Provider value={companyPageData}>
      {children}
    </CompanyContext.Provider>
  );
}

export function useCompanyContext() {
  return useContext(CompanyContext);
}
