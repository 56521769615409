import { useMemo } from "react";
import {
  HStack,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";

import { getFraction } from "@bucketco/shared/utils/getFraction";

import { SimplePieChart } from "@/common/charts/components/SimplePieChart";
import CompactNumber from "@/common/components/CompactNumber";
import pluralize from "@/common/utils/pluralize";
import { useSegment } from "@/company/data/useSegments";

type BaseProps = {
  segmentId?: string;
  showLabel?: boolean;
  showCount?: boolean;
  showTotal?: boolean;
};

export type SegmentChartProps =
  | (BaseProps & { subsegmentId?: string | null; count?: number })
  | (BaseProps & { subsegmentId: string; count: number });

export default function SegmentChart({
  count,
  segmentId,
  subsegmentId,
  showLabel = true,
  showCount = false,
  showTotal = false,
}: SegmentChartProps) {
  const { data: segment, isLoading } = useSegment(segmentId);
  const { data: subsegment } = useSegment(subsegmentId);

  const percentage = useMemo(
    () => getFraction(segment?.segmentCount, segment?.allCount) * 100,
    [segment?.segmentCount, segment?.allCount],
  );
  const subsegmentPercentage = useMemo(
    () => getFraction(subsegment?.segmentCount, subsegment?.allCount) * 100,
    [subsegment?.segmentCount, subsegment?.allCount],
  );

  const subsegmentColor = useColorModeValue("teal.500", "teal.300");

  const tooltip = useMemo(() => {
    if (!segment) return null;
    if (subsegment && count) {
      return `Includes ${count} ${pluralize(
        "company",
        count,
        "companies",
      )} from the intersection of the subsegment (${
        subsegment.segmentCount
      }) and the target segment (${segment.segmentCount}).`;
    }
    return `${segment.segmentCount} ${pluralize(
      "company",
      segment.segmentCount,
      "companies",
    )} in the target segment.`;
  }, [count, segment, subsegment]);

  if (isLoading || !segment) {
    return <Spinner size="xs" />;
  }

  return (
    <Tooltip label={tooltip} openDelay={300} hasArrow>
      <HStack as="span" display="inline-flex" spacing={1.5}>
        {subsegment && (
          <>
            <SimplePieChart
              color={subsegmentColor}
              size={3}
              value={subsegmentPercentage}
            />
            <Text color="dimmed" fontSize="2xs" fontWeight="semibold">
              AND
            </Text>
          </>
        )}
        <SimplePieChart size={3} value={percentage} />
        {showLabel && (
          <Text fontSize="sm" fontWeight="normal">
            {segment.name}
          </Text>
        )}
        {showCount && count && (
          <Text fontSize="sm">
            <CompactNumber value={count} />
            {showTotal && (
              <Text>
                of <CompactNumber value={segment.allCount} />
              </Text>
            )}
            {pluralize("company", count, "companies")}
          </Text>
        )}
      </HStack>
    </Tooltip>
  );
}
