import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { RiCheckFill } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Heading,
  HStack,
  Link,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import { InviteDTO } from "@bucketco/shared/inviteAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import CenteredLayout from "@/common/components/CenteredLayout";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

function AcceptInvite({
  invite,
  onAcceptClick,
}: {
  invite: InviteDTO;
  onAcceptClick: () => Promise<void>;
}) {
  const [loading, setLoading] = useState(false);
  return (
    <VStack spacing={6}>
      <Text textAlign="center">
        You&apos;ve been invited to join {invite.org.name} in Bucket!
      </Text>
      <HStack spacing={4}>
        <Button
          isLoading={loading}
          leftIcon={<RiCheckFill />}
          variant="primary"
          onClick={() => {
            setLoading(true);
            onAcceptClick().finally(() => {
              setLoading(false);
            });
          }}
        >
          Join {invite.org.name}
        </Button>
        <Button as={Link} href="/" isDisabled={loading} variant="outline">
          Never mind
        </Button>
      </HStack>
    </VStack>
  );
}

export default function Invite() {
  const { user, setActiveOrg } = useAuthContext();
  const { orgId, inviteKey } = useParams();
  const navigate = useNavigate();

  const toast = useToast();
  const errorToast = useErrorToast();

  const { data, isError } = useQuery({
    queryKey: ["org", orgId],
    queryFn: () => api.get<"/invite/:inviteKey">(`/invite/${inviteKey}`),
    retry: 0,
  });
  const invite = data?.data;

  useEffect(() => {
    if (isError) {
      errorToast({
        description: "Invitation code is invalid",
      });
      navigate("/");
    }
  }, [isError, errorToast, navigate]);

  useEffect(() => {
    segmentAnalytics.track("Org Invite Opened");
  }, []);

  return (
    <CenteredLayout>
      <Helmet>
        <title>Accept Organization Invite</title>
      </Helmet>
      {invite ? (
        <VStack spacing={6}>
          <Heading fontSize="2xl">Hi {user!.name},</Heading>
          <AcceptInvite
            invite={invite}
            onAcceptClick={async () => {
              try {
                await api.post<"/invite/:inviteKey/accept">(
                  `/invite/${inviteKey}/accept`,
                );
                toast({
                  title: `You're now part of ${invite.org.name}!`,
                  status: "success",
                  duration: 1500,
                  onCloseComplete: () => setActiveOrg(orgId as string),
                });
                segmentAnalytics.track("Org Invite Accepted");
              } catch (error) {
                errorToast({
                  description: `Failed to accept invite: ${String(error)}`,
                });
              }
            }}
          />
        </VStack>
      ) : (
        <Spinner size="sm" />
      )}
    </CenteredLayout>
  );
}
