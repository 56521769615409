import {
  CheckboxGroupButtons,
  CheckboxGroupButtonsProps,
} from "@/common/components/CheckboxGroupButton";
import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import {
  FormElementBaseProps,
  useManagedFormControlProps,
} from "@/common/hooks/useManagedFormControlProps";
import { mergeFunctions } from "@/common/utils/mergeFunctions";

export type FormCheckboxGroupButtonsProps = FormElementBaseProps &
  Omit<CheckboxGroupButtonsProps, "value">;

const FormCheckboxGroupButtons = ({
  _control,
  onChange,
  ...rest
}: FormCheckboxGroupButtonsProps) => {
  const [managedProps, CheckboxGroupButtonsProps] =
    useManagedFormControlProps(rest);
  return (
    <ManagedFormControl
      {..._control}
      {...managedProps}
      render={({ field }) => (
        <CheckboxGroupButtons
          {...CheckboxGroupButtonsProps}
          value={field.value}
          onChange={mergeFunctions(field.onChange, onChange)}
        />
      )}
    />
  );
};

export default FormCheckboxGroupButtons;
