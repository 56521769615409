import { forwardRef } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  ButtonProps,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  MenuProps,
  Text,
  useFormControl,
} from "@chakra-ui/react";

import { GlobalSettingsUrl } from "@bucketco/shared/urls";

import { useFeatureViewsData } from "@/app/data/useFeatureViewsData";
import { useAuthContext } from "@/auth/contexts/authContext";
import FeatureViewsIcon from "@/common/assets/feature-views-icon.svg?react";
import MenuDescription from "@/common/components/MenuDescription";

export type FeatureViewsPickerProps = Omit<ButtonProps, "onChange"> & {
  value?: Array<string>;
  onChange?: (value: Array<string>) => void;
  placement?: MenuProps["placement"];
};

const FeatureViewsPicker = forwardRef<
  HTMLButtonElement,
  FeatureViewsPickerProps
>(
  (
    { value, onChange, isLoading, placement = "bottom-start", id, ...rest },
    ref,
  ) => {
    const { currentEnv } = useAuthContext();
    const formControlProps = useFormControl<HTMLButtonElement>(rest);
    const { data: featureViews, isLoading: isViewsListLoading } =
      useFeatureViewsData();

    const currentViews =
      value?.length === 1
        ? value
            .map((val) => featureViews?.find((v) => v.id === val)?.name)
            .join(", ")
        : undefined;

    return (
      <Menu closeOnSelect={false} id={id} isLazy={true} placement={placement}>
        <MenuButton
          {...formControlProps}
          ref={ref}
          as={Button}
          color={!value?.length ? "gray.500" : undefined}
          isDisabled={formControlProps.disabled}
          isLoading={isLoading || isViewsListLoading}
          leftIcon={<FeatureViewsIcon height="16px" width="16px" />}
          minW={36}
          rightIcon={
            <Box fontSize="xl" mr={-2}>
              <RiArrowDownSLine />
            </Box>
          }
          size="sm"
          variant="input"
          {...rest}
        >
          <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {!value?.length
              ? "No views"
              : currentViews
              ? currentViews
              : `${value.length} views`}
          </Box>
        </MenuButton>
        <MenuList>
          <MenuDescription>Add to feature views:</MenuDescription>
          <MenuDivider my={0} />
          <MenuOptionGroup
            defaultValue={value}
            type="checkbox"
            onChange={(v) => {
              onChange?.(v as string[]);
            }}
          >
            {!featureViews?.length ? (
              <Text color="gray.500" fontSize="sm" maxW={64} px={4} py={3}>
                Use{" "}
                <Link
                  as={RouterLink}
                  to={GlobalSettingsUrl(currentEnv!, "app-feature-views")}
                >
                  feature views
                </Link>{" "}
                to group and organize features
              </Text>
            ) : (
              featureViews?.map((view) => (
                <MenuItemOption key={view.id} value={view.id}>
                  {view.name}
                </MenuItemOption>
              ))
            )}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    );
  },
);

FeatureViewsPicker.displayName = "FeatureViewsPicker";
export default FeatureViewsPicker;
