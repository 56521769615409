import { RiSlackFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Box, Flex, FlexProps, useColorModeValue } from "@chakra-ui/react";

import { FeatureDetail } from "@bucketco/shared/featureAPI";
import { GlobalSettingsUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import SlackLogo from "@/common/assets/slack-hash-logo.svg?react";
import useSlackStatus from "@/common/hooks/useSlackStatus";

export interface SlackEnabledEntity {
  slackChannelId: string | null;
  slackChannelName: string | null;
}

export default function SlackStatus({
  slackEntity,
  settingsLink,
  ...flexProps
}: {
  slackEntity?: FeatureDetail | SlackEnabledEntity;
  settingsLink?: string;
} & FlexProps) {
  const { currentOrg, currentEnv } = useAuthContext();
  const slackStatus = useSlackStatus(slackEntity);

  if (!currentOrg || !currentEnv) return null;

  if (!slackStatus.isConnected) {
    return (
      <Container
        to={GlobalSettingsUrl(currentEnv, "org-slack")}
        {...flexProps}
        isDisabled
      >
        Not Connected
      </Container>
    );
  }

  if (slackEntity) {
    if (slackStatus.channelId) {
      if (settingsLink) {
        return (
          <Container to={settingsLink} {...flexProps}>
            {slackStatus.channelName ?? slackStatus.channelId}
          </Container>
        );
      } else {
        return (
          <Container {...flexProps}>
            {slackStatus.channelName ?? slackStatus.channelId}
          </Container>
        );
      }
    }
  }

  // Fall back to app channel
  return (
    <Container to={GlobalSettingsUrl(currentEnv, "app-slack")} {...flexProps}>
      {slackStatus.channelId ? slackStatus.channelName : "No Channel"}
    </Container>
  );
}

function Container({
  to,
  isDisabled = false,
  children,
  ...flexProps
}: { to?: string; isDisabled?: boolean } & FlexProps) {
  const inactiveColor = useColorModeValue("gray.400", "gray.600");

  const slackLogo = isDisabled ? (
    <Box color={inactiveColor}>
      <RiSlackFill size="19px" />
    </Box>
  ) : (
    <SlackLogo width="15px" />
  );

  if (to) {
    return (
      <Flex alignItems="center" as={Link} gap={2} to={to} {...flexProps}>
        {slackLogo}
        {children}
      </Flex>
    );
  }

  return (
    <Flex alignItems="center" gap={2} {...flexProps}>
      {slackLogo}
      {children}
    </Flex>
  );
}
