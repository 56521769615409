import { RiFlag2Line } from "react-icons/ri";
import {
  Button,
  chakra,
  Flex,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

import FeatureSvg from "@/common/assets/feature-icon.svg?react";
import TimeSeriesIcon from "@/common/assets/widget-timeseries.svg?react";
import { useReleaseEditing } from "@/release/hooks/useReleaseEditing";

const FeatureIcon = chakra(FeatureSvg);

type Props = {
  onCreateFeature?: () => void;
  onAppendGoal?: () => void;
};

export const LegacyGoalsEmptyState = ({
  onCreateFeature,
  onAppendGoal,
}: Props) => {
  const [editing] = useReleaseEditing();
  const placeholderColor = useColorModeValue("gray.100", "gray.650");
  const bg = useColorModeValue("white", "gray.800");
  const border = useColorModeValue("gray.300", "gray.600");

  if (!editing) {
    return (
      <VStack color={placeholderColor} py={8} spacing={8}>
        <TimeSeriesIcon height={64} />
        <Text color="dimmed">No goals have been set for this release</Text>
      </VStack>
    );
  }

  return (
    <Flex
      align="center"
      bg={bg}
      borderColor={border}
      borderRadius="md"
      borderStyle="dashed"
      borderWidth={1}
      boxShadow="sm"
      direction="column"
      gap={4}
      p={6}
      position="relative"
    >
      <Flex
        align="center"
        direction="column"
        gap={4}
        textAlign="center"
        width="md"
      >
        <Text color="dimmed">
          Use goals to let Bucket notify you when metrics has been received and
          remind you at the end of evaluation.
        </Text>
        <Button
          leftIcon={<RiFlag2Line />}
          size="sm"
          variant="outline"
          onClick={onAppendGoal}
        >
          Add Goal
        </Button>
        <Text color="dimmed" mt={4}>
          or, are you launching a new feature with this release?
        </Text>
        <Button
          leftIcon={<FeatureIcon boxSize="1em" />}
          size="sm"
          variant="outline"
          onClick={onCreateFeature}
        >
          New feature
        </Button>
      </Flex>
    </Flex>
  );
};
