import { useFormContext } from "react-hook-form";
import { HStack, SimpleGrid, Text, VStack } from "@chakra-ui/react";

import {
  ReleaseDetailsDTO,
  ReleasePostArgs,
} from "@bucketco/shared/releaseAPI";

import { DateInput } from "@/common/components/DateInput";
import FormNumberInput from "@/common/components/form/FormNumberInput";
import FormSwitch from "@/common/components/form/FormSwitch";
import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import TimestampCell from "@/common/components/TimestampCell";
import useSlackChannels from "@/common/hooks/useSlackChannels";
import pluralize from "@/common/utils/pluralize";
import { SlackChannelAutocompleteSelect } from "@/global-settings/components/SlackChannelAutocompleteSelect";
import { SectionHeading } from "@/release/components/Shared";

type Props = Partial<
  Pick<ReleaseDetailsDTO, "releasedAt" | "evaluationPeriod">
> & {
  editing?: boolean;
};

export const ReleaseDetails = ({
  evaluationPeriod,
  releasedAt,
  editing,
}: Props) => {
  const channelQuery = useSlackChannels();
  const {
    watch,
    formState: { isLoading: isFormLoading },
    setValue,
  } = useFormContext<ReleasePostArgs>();
  const currentChannel = watch("slackChannelId");
  const slackEnabled = watch("slackNotificationsEnabled");

  return (
    <SimpleGrid columns={4} spacing={4}>
      <VStack align="flex-start" spacing={0}>
        <SectionHeading>Release date</SectionHeading>
        {editing ? (
          <ManagedFormControl
            name="releasedAt"
            render={({ field }) => <DateInput w={144} asIsoString {...field} />}
          />
        ) : releasedAt ? (
          <TimestampCell fontSize="2xl" nearestUnit="day" value={releasedAt} />
        ) : (
          <Text fontSize="2xl">Not released yet</Text>
        )}
      </VStack>
      <VStack align="flex-start" spacing={0}>
        <SectionHeading>Evaluation period</SectionHeading>
        {editing ? (
          <FormNumberInput
            max={365}
            min={0}
            name="evaluationPeriod"
            unit="days"
            w={144}
          />
        ) : evaluationPeriod ? (
          <Text fontSize="2xl">
            {evaluationPeriod} {pluralize("day", evaluationPeriod)}
          </Text>
        ) : (
          <Text fontSize="2xl">No period set</Text>
        )}
      </VStack>
      <VStack align="flex-start" spacing={0}>
        <SectionHeading>Slack reporting</SectionHeading>
        {editing ? (
          <HStack spacing={4}>
            <FormSwitch
              name="slackNotificationsEnabled"
              size="sm"
              width="auto"
            />
            <ManagedFormControl
              name="slackChannelId"
              render={({ field }) => (
                <SlackChannelAutocompleteSelect
                  {...field}
                  onChange={(channel) => {
                    field.onChange(channel?.id ?? null);
                    setValue("slackChannelName", channel?.name);
                  }}
                />
              )}
            />
          </HStack>
        ) : (
          <Text fontSize="2xl">
            {channelQuery.isLoading || isFormLoading
              ? "Loading..."
              : channelQuery.isError
              ? "Error loading channels"
              : currentChannel && slackEnabled && channelQuery.data
              ? channelQuery.data.find(({ id }) => id === currentChannel)?.name
              : "Not enabled"}
          </Text>
        )}
      </VStack>
    </SimpleGrid>
  );
};
