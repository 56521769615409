import { useMemo } from "react";

import { FeatureName } from "@bucketco/shared/featureAPI";

import { useFeatureNamesData } from "@/feature/data/useFeatureNamesData";

export function useFeatureName(featureId: string): FeatureName | undefined {
  const { data: featureNames } = useFeatureNamesData();

  const name = useMemo(
    () => featureNames?.find((f) => f.id === featureId),
    [featureId, featureNames],
  );

  return name;
}
