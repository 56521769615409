import { useFormContext } from "react-hook-form";
import { Button, ButtonProps } from "@chakra-ui/react";

type FormSubmitProps = ButtonProps;

export default function FormCancel({
  variant = "ghost",
  size = "md",
  children = "Cancel",
  ...props
}: FormSubmitProps) {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Button
      isDisabled={props.isDisabled || isSubmitting}
      size={size}
      type="button"
      variant={variant}
      {...props}
    >
      {children}
    </Button>
  );
}
