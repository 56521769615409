import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { UsersQueryType } from "@bucketco/shared/userAPI";

import api from "@/common/utils/api";
import { useCurrentEnv } from "../hooks/useCurrentEnv";

import commonQueryKeys from "./commonQueryKeys";

export function useUsers(
  searchValue = "",
  query: Omit<UsersQueryType, "idNameFilter" | "envId"> = {},
) {
  const { appId, envId } = useCurrentEnv();

  const queryParams = {
    idNameFilter: searchValue,
    ...query,
  };

  return useQuery({
    queryKey: commonQueryKeys.users(appId, envId, queryParams),
    queryFn: () =>
      api
        .get<"/apps/:appId/users">(`/apps/${appId}/users`, {
          params: { ...queryParams, envId: envId! },
        })
        .then((res) => res.data.data),
    enabled: !!appId && !!envId,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    placeholderData: keepPreviousData,
  });
}
