import { useQuery } from "@tanstack/react-query";

import { FeaturesMetricsHistoricalDataQueryType } from "@bucketco/shared/featureAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import featureQueryKeys from "@/feature-legacy/data/featureQueryKeys";

export type HistoricalDataParams = Omit<
  FeaturesMetricsHistoricalDataQueryType,
  "featureIds"
>;

export function useFeatureHistoricalData(
  featureId?: string,
  params?: Omit<HistoricalDataParams, "envId">,
) {
  const { appId, envId } = useCurrentEnv();
  return useQuery({
    enabled: !!appId && !!envId && !!featureId,
    queryKey: featureQueryKeys.singleMetricsHistorical(
      appId,
      envId,
      featureId,
      params,
    ),
    queryFn: async ({ signal }) => {
      // Limit fetched data to today to avoid getting data points with 0 values
      const endDateMs = Math.min(params!.endDate.getTime(), Date.now());

      const response =
        await api.get<"/apps/:appId/features/metrics/historical">(
          `/apps/${appId}/features/metrics/historical`,
          {
            signal,
            params: {
              ...params!,
              featureIds: [featureId!],
              endDate: new Date(endDateMs),
              envId: envId!,
            },
          },
        );

      return { featureId, timeseries: response.data[featureId!] };
    },
  });
}
