import {
  chakra,
  ChakraProps,
  HStack,
  StackProps,
  Text,
} from "@chakra-ui/react";

import { GoalDTO, GoalFeatureMetrics } from "@bucketco/shared/goalAPI";

import TargetSvg from "@/common/assets/target.svg?react";
import { formatGoalValue } from "@/release/utils/format";

const TargetIcon = chakra(TargetSvg);

export default function GoalTarget({
  goal,
  color,
  iconSize,
  spacing = 0.5,
}: {
  goal: GoalDTO;
  color?: ChakraProps["color"];
  iconSize?: ChakraProps["width"];
  spacing?: StackProps["spacing"];
}) {
  const metricId = goal.configuration.metric;
  const metric = GoalFeatureMetrics[metricId];
  const isPercentage = metric?.type === "percentage";

  return (
    <HStack spacing={spacing}>
      <TargetIcon color={color} width={iconSize} />
      <Text as="span" color={color} fontSize="sm">
        {formatGoalValue(
          goal.configuration.threshold ?? 0,
          isPercentage,
          metricId,
        )}
      </Text>
    </HStack>
  );
}
