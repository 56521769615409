import { SegmentsQueryType } from "@bucketco/shared/segmentAPI";

const qk = {
  list: (
    appId?: string,
    envId?: string,
    query?: Omit<SegmentsQueryType, "envId">,
  ) =>
    query
      ? (["apps", appId, "environments", envId, "segments", query] as const)
      : (["apps", appId, "environments", envId, "segments"] as const),
};

export default qk;
