import { Text } from "@chakra-ui/react";

import { GoalDTO, GoalFeatureMetrics } from "@bucketco/shared/goalAPI";

import { useGoalStatusColors } from "@/release/hooks/useGoalStatusColors";
import { formatGoalValue } from "@/release/utils/format";

export default function GoalDescription({
  goal,
  colored = false,
  showThreshold = false,
}: {
  goal: GoalDTO;
  colored?: boolean;
  showThreshold?: boolean;
}) {
  const metricId = goal.configuration.metric;
  const metric = GoalFeatureMetrics[metricId];
  const isPercentage = metric?.type === "percentage";

  const colorSet = useGoalStatusColors(goal.status);
  const color = colored ? colorSet.fg : undefined;

  return (
    <Text as="span" color={color} fontSize="sm">
      {metric?.label}:{" "}
      {formatGoalValue(goal.currentValue ?? 0, isPercentage, metricId)}
      {showThreshold &&
        ` / ${formatGoalValue(
          goal.configuration.threshold ?? 0,
          isPercentage,
          metricId,
        )}`}
    </Text>
  );
}
