import { ReactNode } from "react";
import { Flex } from "@chakra-ui/react";

export type TableToolbarProps = {
  children: ReactNode;
};

function TableToolbar({ children }: TableToolbarProps) {
  return (
    <Flex
      align="flex-start"
      borderBottom="1px solid"
      borderBottomColor="appBorder"
      gap={3}
      justify="space-between"
      px={6}
      py={3}
      w="full"
    >
      {children}
    </Flex>
  );
}

export default TableToolbar;
