import { useEffect } from "react";
import { RiFlag2Line, RiLifebuoyLine } from "react-icons/ri";
import { useParams, useResolvedPath } from "react-router-dom";
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import HeaderLayout, {
  HeaderLayoutHeading,
} from "@/common/components/HeaderLayout";
import RouterTabs, { Route } from "@/common/components/RouterTabs";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { FlagImplementationInstructions } from "@/flags/components/FlagImplementationInstructions";
import { useFlagData } from "@/flags/data/useFlagData";

export default function Page() {
  const { flagId } = useParams();

  const { data: flag, isLoading } = useFlagData(flagId);

  const howtoDisclosure = useDisclosure();

  useEffect(() => {
    segmentAnalytics.page("Feature Flag", {
      id: flagId,
    });
  }, [flagId]);

  const tabsRoutes: Route[] = [
    {
      route: useResolvedPath("targeting").pathname,
      name: "Targeting",
    },
    {
      route: useResolvedPath("versions").pathname,
      name: "Change history",
    },
    {
      route: useResolvedPath("settings").pathname,
      name: "Settings",
    },
  ];

  return (
    <HeaderLayout
      actions={
        <Button
          color="dimmed"
          leftIcon={<RiLifebuoyLine size="16" />}
          variant="outlineOnHover"
          {...howtoDisclosure.getButtonProps()}
          isDisabled={!flag}
        >
          How to implement
        </Button>
      }
      title={
        isLoading ? (
          <Spinner size="sm" />
        ) : !flag ? (
          <Text color="dimmed" noOfLines={1}>
            Feature flag not found
          </Text>
        ) : (
          <>
            <Box flexShrink={0}>
              <RiFlag2Line size={16} />
            </Box>
            <HeaderLayoutHeading isTruncated>{flag.key}</HeaderLayoutHeading>
          </>
        )
      }
    >
      <RouterTabs
        routes={tabsRoutes}
        tabListProps={{ px: 2 }}
        titleTemplate={flag?.key ? `Flags › ${flag.key} › %s` : "Flags"}
      />

      {flag && (
        <Modal
          {...howtoDisclosure.getDisclosureProps()}
          isOpen={howtoDisclosure.isOpen}
          size="2xl"
          onClose={howtoDisclosure.onClose}
        >
          <ModalOverlay />
          <ModalContent gap={4} pb={8}>
            <ModalHeader>
              <Heading>Implement feature flag</Heading>
              <Text color="dimmed" fontSize="md" fontWeight="normal" mt={2}>
                Get started in minutes with the example below, or dive into out
                documentation.
              </Text>
            </ModalHeader>

            <ModalCloseButton />

            <ModalBody>
              <FlagImplementationInstructions
                flag={flag}
                sharingPreface={`Implement feature flagging for the "${flag.key}"-flag in Bucket`}
                trackingEventBase="Feature Flag Instructions"
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </HeaderLayout>
  );
}
