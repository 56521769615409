import React, { useMemo } from "react";
import { RiSettings3Line } from "react-icons/ri";
import { Link as RouterLink, To } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Spinner,
  Switch,
  Tag,
  TagLabel,
  TagLeftIcon,
  Tbody,
  Td,
  Text,
  Tr,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import {
  EventSelectorType,
  FeatureDetail,
  TrackingHealth,
} from "@bucketco/shared/featureAPI";
import { CompanyAttributeFilter } from "@bucketco/shared/filter";
import { GoalDetailsDTO } from "@bucketco/shared/goalAPI";
import { NewReleaseUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import CompactNumber from "@/common/components/CompactNumber";
import { FilterItem } from "@/common/components/filters/FilterItem";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import { SectionHeading } from "@/common/components/SectionHeading";
import {
  SidebarContainer,
  SidebarSection,
  SidebarTable,
} from "@/common/components/SidebarLayout";
import { TextBadge } from "@/common/components/TextBadge";
import { TextWordBreak } from "@/common/components/TextWordBreak";
import TimestampCell from "@/common/components/TimestampCell";
import useSlackChannels from "@/common/hooks/useSlackChannels";
import pluralize from "@/common/utils/pluralize";
import { FeatureSourceIcon } from "@/feature-legacy/components/FeatureSource";
import { useFeatureContext } from "@/feature-legacy/contexts/featureContext";
import { useFeatureIntegrations } from "@/feature-legacy/data/useFeatureIntegrations";
import { ReleaseDisplayVerbose } from "@/release/components/ReleaseDisplay";
import useTrackingHealth from "../data/useTrackingHealth";
import useFeatureHasData from "../hooks/useFeatureHasData";

dayjs.extend(utc);

function TimestampCellOrNever({ value }: { value?: string | null }) {
  return value ? <TimestampCell value={value} /> : <>never</>;
}

function EventCount({
  trackingHealth,
  days,
}: {
  trackingHealth: TrackingHealth;
  days: number;
}) {
  const { associatedCount1Week, notAssociatedCount1Week } = trackingHealth;
  if (associatedCount1Week === null || notAssociatedCount1Week === null)
    return null;

  return (
    <Flex columnGap={1} display="inline-flex" wrap="wrap">
      <Text>
        <CompactNumber value={associatedCount1Week}></CompactNumber>
      </Text>
      {notAssociatedCount1Week > 0 ? (
        <HStack spacing={1} whiteSpace="nowrap">
          <Text color="red.500">
            + <CompactNumber value={notAssociatedCount1Week} />
          </Text>
          <InfoIconTooltip
            docsURL="https://docs.bucket.co/introduction/concepts/company/associating-users-and-companies"
            text={
              <>
                In the last {days} {pluralize("day", days)} there were{" "}
                {
                  <CompactNumber
                    value={notAssociatedCount1Week}
                  ></CompactNumber>
                }{" "}
                additional {pluralize("event", notAssociatedCount1Week)} from
                users who are missing an association to a company
              </>
            }
          />
        </HStack>
      ) : null}
    </Flex>
  );
}

function TrackingHealthSnippet() {
  const { feature } = useFeatureContext();
  const { data: trackingHealth, isLoading: loadingHealth } = useTrackingHealth(
    feature?.id,
  );
  const { data: status, isLoading: loadingHasData } = useFeatureHasData(
    feature?.id,
  );

  const firstLastTypeText =
    feature?.source === "attribute" ? "changed" : "seen";
  const isLoading =
    loadingHasData || status?.status === "bootstrapping" || loadingHealth;

  return (
    <SidebarTable>
      <colgroup>
        <col width="50%"></col>
      </colgroup>
      <Tbody>
        {feature?.source === "attribute" ? null : (
          <Tr>
            <Td color="gray.500" fontWeight="medium" verticalAlign="top">
              Events (7d)
            </Td>
            <Td textAlign="right">
              {isLoading ? (
                <Spinner color="dimmed" size="xs" />
              ) : !trackingHealth ? null : (
                <EventCount days={7} trackingHealth={trackingHealth} />
              )}
            </Td>
          </Tr>
        )}
        <Tr>
          <Td color="gray.500" fontWeight="medium" verticalAlign="top">
            First {firstLastTypeText}
          </Td>
          <Td textAlign="right">
            {isLoading ? (
              <Spinner color="dimmed" size="xs" />
            ) : (
              <TimestampCellOrNever value={trackingHealth?.firstSeen} />
            )}
          </Td>
        </Tr>

        <Tr>
          <Td color="gray.500" fontWeight="medium" verticalAlign="top">
            Last {firstLastTypeText}
          </Td>
          <Td textAlign="right">
            {isLoading ? (
              <Spinner color="dimmed" size="xs" />
            ) : (
              <TimestampCellOrNever value={trackingHealth?.lastSeen} />
            )}
          </Td>
        </Tr>
      </Tbody>
    </SidebarTable>
  );
}

function AttributeCriteria({ feature }: { feature?: FeatureDetail }) {
  return (
    <VStack align="flex-start" spacing={2}>
      {feature?.usingItAttributeFilter?.map((rule, index) => (
        <FilterItem
          key={index}
          filter={
            // todo: remove casting when we have a proper type for event attribute filters
            { type: "companyAttribute", ...rule } as CompanyAttributeFilter
          }
          size="sm"
        />
      ))}
    </VStack>
  );
}

function IndividualEventCriteria({
  selector,
}: {
  selector: EventSelectorType;
}) {
  const borderColor = useColorModeValue("gray.100", "gray.700");

  return (
    <VStack align="flex-start" overflow="hidden" spacing={1} width="full">
      <Tag size="sm" variant="subtle" width="full">
        <TagLeftIcon
          as={FeatureSourceIcon}
          h="auto"
          ml="-1px"
          size={16}
          source="event"
          w="auto"
        />
        <TagLabel fontWeight="semibold" whiteSpace="normal">
          <TextWordBreak as="span">{selector.name ?? " "}</TextWordBreak>
        </TagLabel>
      </Tag>
      {(selector.filter ?? []).map((rule, index) => (
        <Box key={`rule-${index}`} pl={8} position="relative" width="full">
          <Box
            borderBottomLeftRadius="lg"
            borderBottomWidth={2}
            borderColor={borderColor}
            borderLeftWidth={2}
            h={
              index === 0
                ? `calc(50% + var(--chakra-space-2))`
                : `calc(1100% + var(--chakra-space-4))`
            }
            left={2}
            position="absolute"
            top={index === 0 ? -2 : `calc(-1050% - var(--chakra-space-4))`}
            w={5}
            zIndex={-1}
          />
          <FilterItem
            key={index}
            filter={
              // todo: remove casting when we have a proper type for event attribute filters
              { type: "companyAttribute", ...rule } as CompanyAttributeFilter
            }
            size="sm"
          />
        </Box>
      ))}
    </VStack>
  );
}

function EventCriteria({ feature }: { feature: FeatureDetail }) {
  return (
    <VStack align="flex-start" spacing={1}>
      {feature.eventSelectors
        .flatMap((selector, index) => [
          <IndividualEventCriteria key={index} selector={selector} />,
          <TextBadge key={`badge-${index}`}>OR</TextBadge>,
        ])
        .slice(0, -1)}
    </VStack>
  );
}

function TrackingCriteria() {
  const { feature } = useFeatureContext();

  return (
    <VStack align="flex-start" spacing={4}>
      {feature?.source === "event" && <EventCriteria feature={feature} />}
      {feature?.source === "attribute" && (
        <AttributeCriteria feature={feature} />
      )}
      <TrackingHealthSnippet />
    </VStack>
  );
}

function Integrations() {
  const { feature } = useFeatureContext();
  const { data: slackChannels = [], isLoading, isError } = useSlackChannels();
  const slackChannel = useMemo(() => {
    return slackChannels.find(
      (channel) => channel.id === feature?.integrations.slack.channelId,
    );
  }, [slackChannels, feature?.integrations.slack.channelId]);

  const { mutate } = useFeatureIntegrations(feature!.id);

  return (
    <VStack spacing={2}>
      <FormControl alignItems="center" display="flex">
        <FormLabel
          color="gray.500"
          flexGrow={1}
          fontSize="sm"
          fontWeight="medium"
          htmlFor="slack-weekly-report"
          pb={0}
        >
          Weekly report
        </FormLabel>
        <Switch
          colorScheme="brand"
          id="slack-weekly-report"
          isChecked={feature?.integrations.slack.weeklyReport}
          size="sm"
          onChange={(e) => {
            if (!feature) return;
            mutate({
              ...feature.integrations.slack,
              weeklyReport: e.target.checked,
            });
          }}
        />
      </FormControl>

      <FormControl alignItems="center" display="flex">
        <FormLabel
          color="gray.500"
          flexGrow={1}
          fontSize="sm"
          fontWeight="medium"
          htmlFor="slack-daily-report"
          pb={0}
        >
          Daily report
        </FormLabel>
        <Switch
          colorScheme="brand"
          id="slack-daily-report"
          isChecked={feature?.integrations.slack.dailyReport}
          size="sm"
          onChange={(e) => {
            if (!feature) return;
            mutate({
              ...feature.integrations.slack,
              dailyReport: e.target.checked,
            });
          }}
        />
      </FormControl>

      <FormControl alignItems="center" display="flex">
        <FormLabel
          color="gray.500"
          flexGrow={1}
          fontSize="sm"
          fontWeight="medium"
          htmlFor="slack-notify-feedback"
          pb={0}
        >
          Notify on feedback
        </FormLabel>
        <Switch
          colorScheme="brand"
          id="slack-notify-feedback"
          isChecked={feature?.integrations.slack.feedbackNotification}
          size="sm"
          onChange={(e) => {
            if (!feature) return;
            mutate({
              ...feature.integrations.slack,
              feedbackNotification: e.target.checked,
            });
          }}
        />
      </FormControl>

      <Flex alignItems="center" w="full">
        <Text flexGrow={1} fontSize="sm" fontWeight="medium" variant="dimmed">
          Slack channel
        </Text>
        {isLoading ? (
          <Spinner size="xs" />
        ) : isError ? (
          <Text variant="dimmed">N/A</Text>
        ) : slackChannels.length === 0 ? (
          <Text variant="dimmed">N/A</Text>
        ) : (
          <Text>{slackChannel?.name ?? "N/A"}</Text>
        )}
      </Flex>
    </VStack>
  );
}

function Goals() {
  const { currentEnv } = useAuthContext();
  const { feature } = useFeatureContext();

  const releases = useMemo(() => {
    if (!feature?.goals) return [];

    return Object.values(
      feature.goals.reduce(
        (acc, goal) => {
          acc[goal.releaseId] = acc[goal.releaseId] || [];
          acc[goal.releaseId].push(goal);
          return acc;
        },
        {} as Record<string, GoalDetailsDTO[]>,
      ),
    );
  }, [feature?.goals]);

  if (releases.length === 0) {
    return (
      <VStack align="flex-start">
        <Text color="dimmed" fontSize="sm">
          No goals yet
        </Text>
        <Button
          as={RouterLink}
          size="sm"
          to={NewReleaseUrl(currentEnv!)}
          variant="outline"
        >
          Create a release
        </Button>
      </VStack>
    );
  }

  return (
    <VStack spacing={4}>
      {releases.map((goals) => {
        const release = goals[0].release;
        return (
          <ReleaseDisplayVerbose
            key={release.id}
            goals={goals}
            release={release}
          />
        );
      })}
    </VStack>
  );
}

function Section({
  title,
  children,
  to,
  toLabel,
}: {
  title?: React.ReactNode;
  children: React.ReactNode;
  to?: To;
  toLabel?: string;
  last?: boolean;
}) {
  return (
    <SidebarSection>
      {title && (
        <Flex align="center" direction="row">
          <SectionHeading flexGrow={1}>{title}</SectionHeading>
          {to && toLabel && (
            <IconButton
              aria-label={toLabel}
              as={RouterLink}
              color="gray.500"
              icon={<RiSettings3Line size={16} />}
              mr={-3}
              size="2xs"
              to={to}
              variant="ghost"
              isRound
            />
          )}
        </Flex>
      )}
      {children}
    </SidebarSection>
  );
}

export default function OverviewSidebar() {
  const { feature } = useFeatureContext();

  if (!feature) return null;

  return (
    <SidebarContainer>
      <Section
        title="Tracking"
        to="../settings"
        toLabel="Update tracking criteria settings"
      >
        <TrackingCriteria />
      </Section>
      <Divider />
      <Section
        title="Slack"
        to="../integrations"
        toLabel="Change integration settings"
      >
        <Integrations />
      </Section>
      <Divider />

      <Section title="Goals" last>
        <Goals />
      </Section>
    </SidebarContainer>
  );
}
