import { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, Text } from "@chakra-ui/react";

import FrequencyChart from "@/common/charts/components/FrequencyChart";
import useChartTokens from "@/common/charts/hooks/useChartTokens";
import PeriodPicker from "@/common/components/PeriodPicker";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import { ChartWrapper } from "@/feature-legacy/components/ChartWrapper";
import { useFeatureContext } from "@/feature-legacy/contexts/featureContext";
import { useFeatureDates } from "@/feature-legacy/data/useFeatureChartDates";
import { useFrequencyChartData } from "@/feature-legacy/data/useFrequencyChartData";

export default function HistoricalFrequency() {
  const { featureId } = useParams();
  const [subsegment] = useSubsegmentParam();
  const { colors } = useChartTokens();
  const { isLoading: featureIsLoading } = useFeatureContext();
  const [width, setWidth] = useState(720);

  const {
    startDate,
    endDate,
    evaluationPeriod,
    granularity,
    setEvaluationPeriod,
    hasPartialPeriod,
  } = useFeatureDates();

  const { isLoading, xAxis, yAxis, data, dataKeys, tooltip, referenceLines } =
    useFrequencyChartData(
      featureId,
      {
        startDate,
        endDate,
        granularity,
        subsegment: subsegment[0],
      },
      {
        chartWidth: width,
      },
    );

  return (
    <ChartWrapper
      gap={2}
      isLoading={featureIsLoading || isLoading}
      metric={
        <>
          <HStack spacing={2}>
            <Box
              borderColor={colors.retained}
              borderLeftWidth={4}
              borderRadius="full"
              h={4}
              ml={1}
              transform="rotate(45deg)"
              w={0}
            />
            <Text color="gray.500" fontSize="sm">
              Retained usage
            </Text>
          </HStack>
          <PeriodPicker
            value={evaluationPeriod}
            onChange={(newPeriod) =>
              setEvaluationPeriod(newPeriod, { replace: true })
            }
          />
        </>
      }
      title="Timeline"
    >
      <FrequencyChart
        data={data}
        dataKeys={dataKeys}
        grid={false}
        height={240}
        highlightPartialPeriod={hasPartialPeriod}
        isLoading={isLoading}
        referenceLines={referenceLines}
        tooltip={tooltip}
        xAxis={xAxis}
        yAxis={yAxis}
        onResize={(newWidth) => setWidth(newWidth)}
      />
    </ChartWrapper>
  );
}
