import { useToken } from "@chakra-ui/react";

type Props = {
  length?: number;
  color?: string;
  colorStart?: string;
  colorEnd?: string;
};

export function AdjustableArrow({
  length = 64,
  color = "currentColor",
  colorStart = color,
  colorEnd = color,
}: Props) {
  const gradientId = `adjustable-arrow-gradient-${colorStart.replace(
    /[^a-z0-9]+/gi,
    "",
  )}-${colorEnd.replace(/[^a-z0-9]+/gi, "")}`;
  const colorStartToken = useToken("colors", colorStart);
  const colorEndToken = useToken("colors", colorEnd);
  return (
    <svg
      fill="none"
      height={8}
      viewBox={`0 0 ${length} 8`}
      width={length}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={gradientId}
          x1={0}
          x2={length - 2}
          y1={4}
          y2={4}
        >
          <stop stopColor={colorStartToken} stopOpacity={0} />
          <stop offset={0.35} stopColor={colorStartToken} stopOpacity={0.5} />
          <stop offset={0.65} stopColor={colorEndToken} stopOpacity={0.75} />
          <stop offset={1} stopColor={colorEndToken} />
        </linearGradient>
      </defs>
      <line
        stroke={`url(#${gradientId})`}
        x1={0}
        x2={length - 2}
        y1={4}
        y2={4}
      />
      <path
        d="M3.086 3.5.404.818l.707-.707L5 4 1.111 7.889l-.707-.707L3.086 4.5v-1Z"
        fill={colorEndToken}
        transform={`translate(${length - 5}, 0)`}
      />
    </svg>
  );
}
