import { Button, ButtonProps, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import omit from "lodash/omit";

import { PostToSlackNowArgsType } from "@bucketco/shared/slackConnectionAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import SlackHashLogo from "@/common/assets/slack-hash-logo.svg?react";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

export default function PostToSlackNow(
  props: PostToSlackNowArgsType & ButtonProps,
) {
  const { currentOrg } = useAuthContext();
  const toast = useToast();

  const errorToast = useErrorToast();

  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      api.post<"/orgs/:orgId/slack/post-now">(
        `/orgs/${currentOrg?.id}/slack/post-now`,
        props,
      ),

    retry: 0,

    onSuccess: () => {
      segmentAnalytics.track("Posted To Slack Now", {
        reportType: props.reportType,
      });
      toast({
        title: "Posted to Slack!",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    },

    onError: (error: any) => {
      console.error("Couldn't post to Slack", error);
      const msg =
        error.response.status === 404
          ? "invalid configuration"
          : "please try again";
      errorToast({
        description: `Couldn't post to Slack: ${msg}.`,
      });
    },
  });

  return (
    <Button
      isDisabled={!props.channelId}
      isLoading={isPending}
      leftIcon={<SlackHashLogo height="14" />}
      size="2xs"
      variant="outline"
      onClick={() => mutate()}
      {...omit(props, [
        "reportType",
        "channelId",
        "featureId",
        "appId",
        "envId",
      ])}
    >
      Send now
    </Button>
  );
}
