import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";

import commonQueryKeys from "@/common/data/commonQueryKeys";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

export function useEventNames(): UseQueryResult<
  string[],
  AxiosError<ErrorResponse>
> {
  const { appId, envId } = useCurrentEnv();

  return useQuery({
    queryKey: commonQueryKeys.distinctEventNames(appId, envId),

    queryFn: () =>
      api
        .get<`/apps/:appId/events/by-name`>(`/apps/${appId}/events/by-name`, {
          params: { envId: envId! },
        })
        .then((res) => res.data.events),
    enabled: !!appId && !!envId,
  });
}
