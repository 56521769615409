import { useEffect } from "react";
import { FormProvider, useFormContext } from "react-hook-form";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Switch,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

import {
  EnvironmentPatchArgs,
  EnvironmentPatchSchema,
} from "@bucketco/shared/environmentAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import { FormRootError } from "@/common/components/form/FormRootError";
import FormSubmit from "@/common/components/form/FormSubmit";
import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import PostToSlackNow from "@/common/components/PostToSlackNow";
import commonQueryKeys from "@/common/data/commonQueryKeys";
import useApiForm from "@/common/hooks/useApiForm";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { SlackChannelAutocompleteSelect } from "@/global-settings/components/SlackChannelAutocompleteSelect";
import SlackOrgIntegration from "@/global-settings/components/SlackOrgIntegration";

function WeeklyReleaseDigestSwitch({ isLoading }: { isLoading: boolean }) {
  const form = useFormContext<EnvironmentPatchArgs>();
  const name = "slackWeeklyReleaseDigest";
  const currentChannel = form.watch("slackChannelId");
  const { currentApp, currentEnv } = useAuthContext();

  return (
    <VStack alignItems="flex-start" spacing={2} w="100%">
      <FormControl
        as={Flex}
        flexDirection="column"
        gap={2}
        id={name}
        isDisabled={form.formState.isSubmitting || isLoading}
      >
        <Flex align="center" gap={4}>
          <Box>
            <FormLabel htmlFor={`slack-${name}`} margin={0} padding={0}>
              Weekly releases digest
            </FormLabel>
          </Box>
          <Box flexGrow={1}>
            <PostToSlackNow
              appId={currentApp!.id}
              channelId={currentChannel ?? ""}
              envId={currentEnv!.id}
              isDisabled={
                form.formState.isSubmitting || isLoading || !currentChannel
              }
              reportType="weeklyReleaseDigest"
            />
          </Box>
          <Box>
            <Switch
              {...form.register(name)}
              colorScheme="brand"
              id={`slack-${name}`}
            />
          </Box>
        </Flex>
        <Flex>
          <Box flexGrow={1} maxW="xs">
            <Text color="gray.500" fontSize="sm">
              Get a digest of your releases that are currently evaluating, every
              week in Slack
            </Text>
          </Box>
        </Flex>
      </FormControl>
    </VStack>
  );
}

function WeeklyAllFeaturesDigestSwitch({ isLoading }: { isLoading: boolean }) {
  const form = useFormContext<EnvironmentPatchArgs>();
  const name = "slackWeeklyAllFeaturesDigest";
  const currentChannel = form.watch("slackChannelId");
  const { currentApp, currentEnv } = useAuthContext();

  return (
    <VStack alignItems="flex-start" spacing={2} w="100%">
      <FormControl
        as={Flex}
        flexDirection="column"
        gap={2}
        id={name}
        isDisabled={form.formState.isSubmitting || isLoading}
      >
        <Flex align="center" gap={4}>
          <Box>
            <FormLabel htmlFor={`slack-${name}`} margin={0} padding={0}>
              Weekly feature digest for all features
            </FormLabel>
          </Box>
          <Box flexGrow={1}>
            <PostToSlackNow
              appId={currentApp!.id}
              channelId={currentChannel ?? ""}
              envId={currentEnv!.id}
              isDisabled={
                form.formState.isSubmitting || isLoading || !currentChannel
              }
              reportType="weeklyAllFeaturesDigest"
            />
          </Box>
          <Box>
            <Switch
              {...form.register(name)}
              colorScheme="brand"
              id={`slack-${name}`}
            />
          </Box>
        </Flex>
        <Flex>
          <Box flexGrow={1} maxW="xs">
            <Text color="gray.500" fontSize="sm">
              Get a digest of all your features, every week in Slack
            </Text>
          </Box>
        </Flex>
      </FormControl>
    </VStack>
  );
}

export default function SlackAppIntegration() {
  const { currentApp, currentEnv, currentOrg } = useAuthContext();
  const toast = useToast();
  const queryClient = useQueryClient();

  const {
    form,
    handleSubmit,
    mutation: { isPending },
  } = useApiForm(
    (data: EnvironmentPatchArgs) =>
      api
        .patch<"/apps/:appId/environments/:envId">(
          `/apps/${currentApp?.id}/environments/${currentEnv?.id}`,
          data,
        )
        .then((res) => res.data.environment),
    EnvironmentPatchSchema,
    {
      onSuccess: (data) => {
        segmentAnalytics.track("App Slack Settings Updated", {
          hasSetChannel: data.slackChannelId,
          weeklyAllFeaturesDigest: data.slackWeeklyAllFeaturesDigest,
          weeklyReleaseDigest: data.slackWeeklyReleaseDigest,
        });

        toast({
          title: "Slack settings saved",
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        queryClient.invalidateQueries({ queryKey: commonQueryKeys.bootstrap });

        form.reset({
          slackChannelId: data.slackChannelId,
          slackWeeklyReleaseDigest: data.slackWeeklyReleaseDigest,
          slackWeeklyAllFeaturesDigest: data.slackWeeklyAllFeaturesDigest,
        });
      },
    },
    {
      mode: "all",
      defaultValues: {
        slackChannelId: currentEnv?.slackChannelId,
        slackChannelName: currentEnv?.slackChannelName,
        slackWeeklyAllFeaturesDigest: currentEnv?.slackWeeklyAllFeaturesDigest,
        slackWeeklyReleaseDigest: currentEnv?.slackWeeklyReleaseDigest,
      },
    },
  );

  useEffect(() => {
    segmentAnalytics.page("App Slack Integration Settings");
  }, []);

  // Quick access to do the right thing when there is no Slack connection on the org yet
  if (!currentOrg?.hasSlackConnection) {
    return <SlackOrgIntegration />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormProvider {...form}>
        <VStack align="flex-start" maxW="compactForm" spacing={6} w="100%">
          <VStack align="flex-start" w="full">
            <ManagedFormControl
              isDisabled={isPending}
              label="Slack Channel"
              name="slackChannelId"
              render={({ field }) => (
                <SlackChannelAutocompleteSelect
                  {...field}
                  onChange={(channel) => {
                    if (channel) {
                      form.setValue("slackChannelName", channel.name);
                      field.onChange(channel.id);
                    } else {
                      form.setValue("slackChannelName", undefined);
                      field.onChange(undefined);
                    }
                  }}
                />
              )}
            />

            <Text color="gray.500" fontSize="xs" mt={2}>
              Feature-specific reports use this channel by default
            </Text>
          </VStack>

          <WeeklyReleaseDigestSwitch isLoading={isPending} />
          <WeeklyAllFeaturesDigestSwitch isLoading={isPending} />
          <FormRootError />
          <FormSubmit>Save</FormSubmit>
        </VStack>
      </FormProvider>
    </form>
  );
}
