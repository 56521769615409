import { z } from "zod";

import { APIEmptyResponse, APIResponse } from "./api";
import { EnvironmentSelectionQueryType } from "./environmentAPI";
import { ReleaseDTO } from "./releaseAPI";

// Configuration
export const GoalFeatureMetricList = [
  "tried",
  "adopted",
  "retained",
  "satisfied",
  "adoptionRate",
  "retentionRate",
  "satisfactionRate",
  "triedCount",
  "adoptedCount",
  "retainedCount",
  "satisfiedCount",
  "averageFrequency",
  "feedbackCount",
] as const;
export type GoalFeatureMetric = (typeof GoalFeatureMetricList)[number];
export const GoalFeatureMetrics: Record<
  GoalFeatureMetric,
  {
    label: string;
    type: "percentage" | "count";
    restriction: "event-based" | "none";
    defaultThreshold: number;
    maxThreshold: number;
  }
> = {
  tried: {
    label: "Tried",
    type: "percentage",
    restriction: "event-based",
    defaultThreshold: 0.25,
    maxThreshold: 1,
  },
  adopted: {
    label: "Adopted",
    type: "percentage",
    restriction: "none",
    defaultThreshold: 0.25,
    maxThreshold: 1,
  },
  retained: {
    label: "Retained",
    type: "percentage",
    restriction: "none",
    defaultThreshold: 0.25,
    maxThreshold: 1,
  },
  satisfied: {
    label: "Satisfied",
    type: "percentage",
    restriction: "none",
    defaultThreshold: 0.25,
    maxThreshold: 1,
  },
  adoptionRate: {
    label: "Adoption rate",
    type: "percentage",
    restriction: "none",
    defaultThreshold: 0.5,
    maxThreshold: 1,
  },
  retentionRate: {
    label: "Retention rate",
    type: "percentage",
    restriction: "none",
    defaultThreshold: 0.5,
    maxThreshold: 1,
  },
  satisfactionRate: {
    label: "Satisfaction rate",
    type: "percentage",
    restriction: "none",
    defaultThreshold: 0.5,
    maxThreshold: 1,
  },
  triedCount: {
    label: "Tried",
    type: "count",
    restriction: "event-based",
    defaultThreshold: 10,
    maxThreshold: Infinity,
  },
  adoptedCount: {
    label: "Adopted",
    type: "count",
    restriction: "none",
    defaultThreshold: 10,
    maxThreshold: Infinity,
  },
  retainedCount: {
    label: "Retained",
    type: "count",
    restriction: "none",
    defaultThreshold: 10,
    maxThreshold: Infinity,
  },
  satisfiedCount: {
    label: "Satisfied",
    type: "count",
    restriction: "none",
    defaultThreshold: 10,
    maxThreshold: Infinity,
  },
  averageFrequency: {
    label: "Frequency",
    type: "count",
    restriction: "none",
    defaultThreshold: 2,
    maxThreshold: 3,
  },
  feedbackCount: {
    label: "Feedback",
    type: "count",
    restriction: "none",
    defaultThreshold: 10,
    maxThreshold: Infinity,
  },
};
export const GoalFeatureMetricSchema = z.enum(GoalFeatureMetricList);

export const GoalConfigurationTypeSchema = z
  .object({
    version: z.enum(["1"]).default("1"),
    type: z.enum(["featureMetric"]).default("featureMetric"),
  })
  .strict();

export const GoalConfigurationFormFieldSchema = z
  .object({
    featureId: z
      .string({
        invalid_type_error: "The goal needs a feature",
      })
      .length(14, "The goal needs a feature"),
    metric: GoalFeatureMetricSchema,
    subsegmentId: z.string().nullish().optional(),
    threshold: z
      .number({
        invalid_type_error: "The goal needs a target",
      })
      .min(0),
  })
  .strict();

export const GoalConfigurationSchema = GoalConfigurationTypeSchema.merge(
  GoalConfigurationFormFieldSchema,
).strict();
export type GoalConfiguration = z.infer<typeof GoalConfigurationSchema>;

// Data evaluation
export type GoalEvaluationData = Array<{ epoch: number; value: number | null }>;

// Post & Patch DTO

export const GoalPostSchema = z
  .object({
    configuration: GoalConfigurationSchema,
  })
  .strict();
export type GoalPostArgs = z.input<typeof GoalPostSchema>;

export const GoalPatchSchema = GoalPostSchema.partial();
export type GoalPatchArgs = z.input<typeof GoalPatchSchema>;

// Model
export type GoalStatus = "planned" | "evaluating" | "achieved" | "missed";

export type GoalDTO = {
  id: string;
  status: GoalStatus;
  progress: number;
  progressIndeterminate: boolean;
  achievedAt: string | null;
  evaluatedAt: string | null;
  configuration: GoalConfiguration;
  currentValue: number | null;
};

export type GoalDetailsDTO = GoalDTO & {
  releaseId: string;
  release: ReleaseDTO;
  achievedValue: number | null;
  evaluatedAt: string | null;
  evaluationData: GoalEvaluationData;
};

// Dry run

export const GoalDryRunPostSchema = z
  .object({
    evaluationPeriod: z.object({
      startDate: z.string().datetime(),
      endDate: z.string().datetime(),
    }),
    configuration: GoalConfigurationSchema,
  })
  .strict()
  .or(
    z
      .object({
        releaseId: z.string().length(14),
        configuration: GoalConfigurationSchema,
      })
      .strict(),
  );
export type GoalDryRunPostArgs = z.input<typeof GoalDryRunPostSchema>;

export type GoalDryRunResult = Pick<
  GoalDetailsDTO,
  | "progress"
  | "progressIndeterminate"
  | "currentValue"
  | "achievedAt"
  | "achievedValue"
  | "evaluationData"
>;

// API

export interface GoalAPI {
  "/apps/:appId/releases/dry-run": {
    POST: {
      body: GoalDryRunPostArgs;
      response: APIResponse<{
        result: GoalDryRunResult;
      }>;
      params: { appId: string; releaseId: string };
      query: EnvironmentSelectionQueryType;
    };
  };
  "/apps/:appId/releases/:releaseId/goals": {
    POST: {
      body: GoalPostArgs;
      response: APIResponse<{
        goal: GoalDetailsDTO;
      }>;
      params: { appId: string; releaseId: string };
    };
    GET: {
      response: APIResponse<{
        goals: GoalDetailsDTO[];
      }>;
      params: { appId: string; releaseId: string };
    };
  };
  "/apps/:appId/releases/:releaseId/goals/:goalId": {
    GET: {
      response: APIResponse<{
        goal: GoalDetailsDTO;
      }>;
      params: { appId: string; releaseId: string; goalId: string };
    };
    PATCH: {
      body: GoalPatchArgs;
      response: APIResponse<{
        goal: GoalDetailsDTO;
      }>;
      params: { appId: string; releaseId: string; goalId: string };
    };
    DELETE: {
      response: APIEmptyResponse;
      params: { appId: string; releaseId: string; goalId: string };
    };
  };
}
