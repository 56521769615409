import { useMemo } from "react";
import {
  RiChat1Line,
  RiCheckboxCircleFill,
  RiCloseCircleFill,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";

import {
  CompanyFeature,
  CompanyFeaturesQuerySortByType,
} from "@bucketco/shared/companyAPI";
import { Feature2Url, FeatureUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import CompactNumber from "@/common/components/CompactNumber";
import DataTable from "@/common/components/DataTable";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import { RecentActivity } from "@/common/components/RecentActivity";
import SearchInput from "@/common/components/SearchInput";
import TimestampCell from "@/common/components/TimestampCell";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import { useLocalStorageTableConfiguration } from "@/common/hooks/useLocalStorageTableConfiguration";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import { createSortableColumnHelper } from "@/common/types/reactTableHelpers";
import { linkClickModifierHandler } from "@/common/utils/linkClickHandler";
import { useCompanyContext } from "@/company/contexts/CompanyContext";
import FeatureFrequencyProgress from "@/feature-legacy/components/FeatureMetrics";
import { FeatureNameCell } from "@/feature-legacy/components/FeatureNameCell";
import { FeedbackSatisfactionIcon } from "@/feature-legacy/components/FeedbackSatisfaction";
import { TableDisplayModePicker } from "@/feature-legacy/components/TableDisplayModePicker";
import { starsFunnelStateDescriptions } from "@/feature-legacy/data/starsFunnelDescriptions";
import { useFeaturesSearch } from "@/feature-legacy/hooks/useFeaturesSearch";

export default function CompanyFeatures() {
  const { currentEnv } = useAuthContext();
  const navigate = useNavigate();

  const [featureFilter] = useSearchParam("featureFilter");

  const { featuresDataTable: table, clearFilters } = useCompanyContext();

  const [searchQuery, setSearchQuery] = useSearchParam("q", { fallback: "" });

  const features = useFeaturesSearch(table?.data?.data ?? [], {
    enabled: Boolean(featureFilter || searchQuery),
    predicate: (f) => {
      if (featureFilter) return f.id === featureFilter;
      return (
        f.id === searchQuery ||
        f.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    },
  });

  const columnHelper = createSortableColumnHelper<
    CompanyFeature,
    CompanyFeaturesQuerySortByType
  >();

  const { isEnabled: revampEnabled } = useFeature("feature-revamp-2024");
  const tableConfiguration = useLocalStorageTableConfiguration(
    "CompanyFeatures" + (revampEnabled ? "_1" : ""),
    {
      defaultColumns: [],
      defaultSort: {
        id: "name",
        desc: false,
      },
    },
  );

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("name", {
        header: "Feature",
        cell: FeatureNameCell,
      }),

      ...(!revampEnabled
        ? []
        : [
            columnHelper.accessor("evaluationResult", {
              header: () => (
                <HStack>
                  <span>Enabled</span>
                  <InfoIconTooltip text="Indicates if company currently matches feature targeting rules. When a timestamp is shown it indicates when targeting first matched." />
                </HStack>
              ),
              cell: (cell) => {
                if (
                  cell.row.original.evaluationResult === true &&
                  cell.row.original.firstPositiveEvalTime
                ) {
                  return (
                    <HStack spacing={1}>
                      <EvaluationCell value={cell.getValue()} />
                      <TimestampCell
                        color="dimmed"
                        value={cell.row.original.firstPositiveEvalTime}
                      />
                    </HStack>
                  );
                }
                return <EvaluationCell value={cell.getValue()} />;
              },
            }),
            columnHelper.accessor("lastCheckTime", {
              header: () => (
                <HStack>
                  <span>Last check</span>
                  <InfoIconTooltip text="Last code check targeting result" />
                </HStack>
              ),
              cell: ({ row }) => {
                const { lastCheckTime, lastCheckResult } = row.original;

                if (!lastCheckTime) {
                  return <NotAvailableCell />;
                }
                return (
                  <HStack>
                    <HStack spacing={1}>
                      {lastCheckResult ? (
                        <RiCheckboxCircleFill color="green" size="16" />
                      ) : (
                        <RiCloseCircleFill color="gray" size="16" />
                      )}
                      <span>{lastCheckResult ? "Enabled" : "Disabled"}</span>
                    </HStack>
                    <TimestampCell color="dimmed" value={lastCheckTime} />
                  </HStack>
                );
              },
            }),
          ]),
      columnHelper.accessor("satisfaction", {
        header: "Satisfaction",
        cell: ({ row }) => (
          <HStack>
            {row.original.satisfaction !== null ? (
              <FeedbackSatisfactionIcon
                value={row.original.satisfaction.toString() as any}
                colored
              />
            ) : (
              <NotAvailableCell />
            )}
          </HStack>
        ),
      }),
      columnHelper.accessor("feedbackCount", {
        header: "Feedback",
        cell: ({ row }) => (
          <HStack>
            {row.original.feedbackCount > 0 && (
              <Flex
                alignItems="center"
                aria-label={`${row.original.feedbackCount} submissions`}
                color="gray.500"
                gap={1}
                minW="60px"
              >
                <RiChat1Line size="16" />
                <CompactNumber value={row.original.feedbackCount} />
              </Flex>
            )}
          </HStack>
        ),
      }),
      columnHelper.accessor("funnelStep", {
        header: `STARS state`,
        sortDescFirst: true,
        cell: (cell) => {
          const value = cell.getValue();
          const description = starsFunnelStateDescriptions.find(
            ({ id }) => id === value,
          );

          return description ? (
            <HStack spacing={2}>
              {description.visualization}
              <Text>{description.label}</Text>
              {value}
            </HStack>
          ) : (
            <NotAvailableCell />
          );
        },
      }),
      columnHelper.accessor("firstUsed", {
        header: "First used",
        sortDescFirst: true,
        cell: (cell) => {
          const value = cell.getValue();
          return value === null ? (
            <NotAvailableCell />
          ) : (
            <TimestampCell value={value} leftAlign />
          );
        },
      }),
      columnHelper.accessor("lastUsed", {
        header: "Last used",
        sortDescFirst: true,
        cell: (cell) => {
          const value = cell.getValue();
          return value === null ? (
            <NotAvailableCell />
          ) : (
            <TimestampCell value={value} leftAlign />
          );
        },
      }),
      columnHelper.accessor("frequency", {
        header: "Frequency",
        cell: (cell) => {
          return cell.row.original.source === "event" &&
            cell.row.original.funnelStep === "retained" ? (
            <FeatureFrequencyProgress frequency={cell.getValue()} />
          ) : (
            <NotAvailableCell />
          );
        },
      }),
      columnHelper.accessor("usage", {
        header: "Recent activity (30d)",
        enableSorting: false,
        cell: ({ row }) => (
          <RecentActivity
            data={row.original.usage.map((usage) => ({
              day: usage.date,
              eventCount: usage.eventCount,
            }))}
            mergeActive={row.original.source === "attribute"}
            showAmplitude={row.original.source === "event"}
            showEventCount={row.original.source === "event"}
          />
        ),
      }),
      columnHelper.accessor("eventCount", {
        header: "Total events",
        cell: ({ row }) =>
          row.original.source === "attribute" ? (
            <NotAvailableCell />
          ) : (
            <>
              <CompactNumber value={row.original.eventCount} />
            </>
          ),
      }),
    ];
  }, [columnHelper, revampEnabled]);

  if (table === null) return null;

  return (
    <Flex direction="column" flexGrow={1}>
      <Flex justify="space-between" px={6} py={3.5}>
        <Box>
          <SearchInput
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.currentTarget.value)}
          />
        </Box>
        <TableDisplayModePicker />
      </Flex>

      <Divider />

      <DataTable
        columns={columns}
        columnStates={tableConfiguration.columns}
        data={features || []}
        expandedPersistenceId="features"
        fetchData={table.fetchData}
        isFetching={table.isFetching}
        meta={table.data?.metadata}
        pageCount={table.pageCount}
        rowOnClick={(row, e) => {
          const url = revampEnabled
            ? Feature2Url(currentEnv!, row.original)
            : FeatureUrl(currentEnv!, row.original);
          e.preventDefault();

          linkClickModifierHandler(url, () => {
            navigate(url);
          })(e);
        }}
        setCanPaginate={table.setCanPaginate}
        setColumnStates={tableConfiguration.setColumns}
        setPaginateActions={table.setPaginateActions}
        setSortBy={tableConfiguration.setSort}
        sortBy={tableConfiguration.sort}
        trackingId="company-features"
        variant="clickable"
      />

      {table?.isLoading && (
        <Box px={6} py={4}>
          <Spinner size="sm" />
        </Box>
      )}

      {!table?.isLoading && table?.data?.data.length === 0 && (
        <Text color="gray.500" fontSize="sm" px={6} py={4}>
          No features found
        </Text>
      )}

      {!table.isLoading &&
        featureFilter &&
        features !== undefined &&
        features.length > 0 && (
          <Button
            color="gray.500"
            fontSize="sm"
            mx={8}
            my={4}
            variant="link"
            onClick={() => {
              clearFilters();
            }}
          >
            Clear filters
          </Button>
        )}
    </Flex>
  );
}

function EvaluationCell({ value }: { value: boolean | null }) {
  let icon: React.ReactNode;
  let description: string;

  if (value) {
    description =
      "Based on the current targeting rules, this feature is enabled for the company";
    icon = (
      <Badge colorScheme="green" rounded="full" variant="outline">
        Yes
      </Badge>
    );
  } else {
    description =
      "Based on the current targeting rules, this feature is disabled for the company";
    icon = (
      <Badge colorScheme="gray" rounded="full" variant="outline">
        No
      </Badge>
    );
  }

  return (
    <Tooltip
      label={description}
      openDelay={300}
      placement="start-start"
      hasArrow
    >
      {icon}
    </Tooltip>
  );
}
