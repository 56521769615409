/* ================================= */
/*            Event Based            */
/* ================================= */

export const reactEventExample = (flagKey: string) => /*jsx*/ `
import { useFeature } from "@bucketco/react-sdk";

// Component wrapped with the BucketProvider
const Feature = () => {
  const { isEnabled, track } = useFeature("${flagKey}");
  if (!isEnabled) return null;
  return <button onClick={() => track()}>Track usage!</button>;
}
`;

export const browserEventExample = (flagKey: string) => /*js*/ `
// Import an initialized BucketClient
import { bucketClient } from "./bucketClient";

const features = bucketClient.getFeatures();

if (features["${flagKey}"]) {
  bucketClient.track("${flagKey}");
}
`;

export const nodeEventExample = (flagKey: string) => /*js*/ `
// Import an initialized BucketClient
import { bucketClient } from "./bucketClient";

const features = bucketClient.getFeatures();

if (features["${flagKey}"]?.isEnabled) {
  features["${flagKey}"].track();
}
`;

/* ================================= */
/*          Attribute Based          */
/* ================================= */

export const reactAttributeExample = (flagKey: string) => /*tsx*/ `
import { BucketProvider, useFeature } from "@bucketco/react-sdk";

const App = ({ company, ...rest }) => (
  <BucketProvider company={{ ...company, attributeMatchingCriteria: true }} {...rest}>
    <Feature />
  </BucketProvider>
)

const Feature = () => {
  const { isEnabled } = useFeature("${flagKey}");
  if (!isEnabled) return null;
  return <p>Feature is enabled!</p>;
}
`;

export const browserAttributeExample = (flagKey: string) => /*js*/ `
import { bucketClient } from "./bucketClient";

// Supply company attributes either in the BucketClient constructor
// or by passing them to an initialized bucketClient.company()
await bucketClient.company({ attributeMatchingCriteria: true });

const features = bucketClient.getFeatures();
if (features["${flagKey}"]) {
  // Feature is enabled
}
`;

export const nodeAttributeExample = (flagKey: string) => /*js*/ `
import { bucketClient } from "./bucketClient";

// Supply company attributes either in the BucketClient constructor
// or by passing them to an initialized bucketClient.company()
await bucketClient.updateCompany({ attributes: { attributeMatchingCriteria: true }});

const features = bucketClient.getFeatures();
if (features["${flagKey}"]?.isEnabled) {
  // Feature is enabled
}
`;
