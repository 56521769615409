import { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  chakra,
  Divider,
  HStack,
  Link,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

import { FeatureName } from "@bucketco/shared/featureAPI";
import {
  GoalFeatureMetric,
  GoalFeatureMetrics,
  GoalStatus,
} from "@bucketco/shared/goalAPI";
import { SegmentDTO } from "@bucketco/shared/segmentAPI";
import { SegmentUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import SubsegmentsSvg from "@/common/assets/subsegments.svg?react";
import TargetSvg from "@/common/assets/target.svg?react";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import FeatureDisplay from "@/feature-legacy/components/FeatureDisplay";
import { GoalStatusIcon } from "@/release/components/GoalStatusIcon";
import { useGoalStatusColors } from "@/release/hooks/useGoalStatusColors";
import { formatGoalValue } from "@/release/utils/format";

const SubsegmentsIcon = chakra(SubsegmentsSvg);
const TargetIcon = chakra(TargetSvg);

type Props = {
  metric: GoalFeatureMetric;
  status: GoalStatus;
  currentValue: number;
  progress: number;
  threshold: number;
  releasedValue: number | null | undefined;
  releasedAt: Date | undefined;
  featureName: FeatureName | undefined;
  subsegment: SegmentDTO | undefined;
  isPercentage?: boolean;
  progressIndeterminate?: boolean;
};

export const LegacyGoalProgress = ({
  metric,
  status,
  currentValue,
  progress,
  threshold,
  featureName,
  subsegment,
  isPercentage,
  progressIndeterminate = false,
}: Props) => {
  const { fg } = useGoalStatusColors(status);
  const dividerColor = useColorModeValue("gray.100", "gray.700");

  const definition = GoalFeatureMetrics[metric];

  const goalValue = useMemo(() => {
    if (status === "achieved") return threshold;
    return currentValue;
  }, [status, threshold, currentValue]);

  return (
    <VStack align="flex-start" h="100%" spacing={4}>
      {definition && (
        <>
          <HStack>
            <GoalStatusIcon
              progress={progress}
              progressIndeterminate={progressIndeterminate}
              showPercentage={false}
              size={24}
              status={status}
            />
            <Text as="span" color={fg} fontSize="xl">
              {definition?.label}:{" "}
              {formatGoalValue(goalValue, isPercentage, metric)}
            </Text>
          </HStack>

          <Divider borderColor={dividerColor} pt={1} />
        </>
      )}

      <VStack alignItems="start" spacing={3} w="100%">
        <VStack alignItems="start" spacing={0} w="100%">
          <Text color="dimmed" fontSize="sm">
            Target value
          </Text>
          <HStack spacing={1}>
            <TargetIcon color="dimmed" flexShrink={0} />
            <Text noOfLines={1}>
              {formatGoalValue(threshold, isPercentage, metric)}
            </Text>
          </HStack>
        </VStack>

        {featureName && (
          <VStack alignItems="start" spacing={0} w="100%">
            <Text color="dimmed" fontSize="sm">
              Feature
            </Text>
            <FeatureDisplay
              feature={featureName}
              fontWeight="normal"
              iconColor="chakra-body-text"
              mt="auto"
              overflow="hidden"
              subsegmentId={subsegment?.id}
              w="100%"
              link
            />
          </VStack>
        )}

        {subsegment && (
          <VStack alignItems="start" spacing={0} w="100%">
            <Text color="dimmed" fontSize="sm">
              Subsegment
            </Text>
            <SubsegmentDisplay subsegment={subsegment} />
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};

function SubsegmentDisplay({ subsegment }: { subsegment: SegmentDTO }) {
  const { currentEnv } = useAuthContext();
  const subsegmentColor = useColorModeValue("teal.500", "teal.300");

  const content = (
    <>
      <HStack spacing={1}>
        <SubsegmentsIcon
          color={subsegmentColor}
          flexShrink={0}
          height={4}
          width={4}
        />
        <Text as="span" color={subsegmentColor} fontSize="md" noOfLines={1}>
          {subsegment.name}
        </Text>
        {subsegment.deletedAt && (
          <>
            <Text color="dimmed">(Archived)</Text>
            <InfoIconTooltip text="This segment has been deleted since the goal was created. The evaluation will continue as if it still existed." />
          </>
        )}
      </HStack>
    </>
  );

  if (subsegment.deletedAt) {
    return content;
  }

  return (
    <Link
      _hover={{ color: subsegmentColor, textDecoration: "underline" }}
      as={RouterLink}
      color={subsegmentColor}
      textDecoration="none"
      to={SegmentUrl(currentEnv!, subsegment.id)}
    >
      {content}
    </Link>
  );
}
