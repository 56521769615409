import { z } from "zod";

import { nameSchema } from "./schemas/nameSchema";
import { APIResponse } from "./api";
import { AppDTO } from "./appAPI";
import { AccessLevelSchema, Member, Org } from "./organizationAPI";

export type AdminOrgMember = Member & { isAdmin: boolean };

export const CreateAppScenarios = ["slick"] as const;
export type CreateAppScenario = (typeof CreateAppScenarios)[number];

export const RunAppScenarios = [
  "releases-events-1",
  "releases-events-2",
  "releases-events-3",
] as const;
export type RunAppScenario = (typeof RunAppScenarios)[number];

export type ScenarioOptions = {
  slick: { userId: string };
  "releases-events-1": { featureId: string };
  "releases-events-2": { featureId: string };
  "releases-events-3": Record<string, never>;
};

export const DemoScenarios = [
  ...CreateAppScenarios,
  ...RunAppScenarios,
] as const;
export type DemoScenario = CreateAppScenario | RunAppScenario;

export const AdminCreatOrgSchema = z
  .object({
    name: nameSchema,
  })
  .strict();

export type AdminCreatOrgType = z.infer<typeof AdminCreatOrgSchema>;

export const AdminPatchOrgSchema = z
  .object({
    accessLevel: AccessLevelSchema,
    trialEndsAt: z.string().datetime().nullable(),
    suspendedAt: z.string().datetime().nullable(),
    logoUrl: z.string().nullable(),
  })
  .partial();
export type AdminPatchOrgType = z.infer<typeof AdminPatchOrgSchema>;

export const AdminCreateDemoAppSchema = z
  .object({
    name: nameSchema,
    demoScenario: z.enum(CreateAppScenarios).optional(),
  })
  .strict();

export const AdminRunAppScenarioParamsSchema = z
  .object({
    orgId: z.string(),
    appId: z.string(),
  })
  .strict();

export const AdminRunAppScenarioQuerySchema = z
  .object({
    scenario: z.enum(RunAppScenarios),
  })
  .strict();

export const AdminDeleteDemoAppParamsSchema = z
  .object({
    orgId: z.string(),
    appId: z.string(),
  })
  .strict();

export const AdminRemoveMemberParamsSchema = z
  .object({
    orgId: z.string(),
    memberId: z.string(),
  })
  .strict();

export type AdminCreateDemoAppType = z.infer<typeof AdminCreateDemoAppSchema>;

export interface AdminAPI {
  "/admin": {
    POST: {
      response: APIResponse<Org>;
      body: AdminCreatOrgType;
    };
  };
  "/admin/:orgId": {
    PATCH: {
      params: {
        orgId: string;
      };
      body: AdminPatchOrgType;
      response: APIResponse<Org>;
    };
  };
  "/admin/:orgId/apps": {
    POST: {
      params: {
        orgId: string;
      };
      body: AdminCreateDemoAppType;
      response: APIResponse<AppDTO>;
    };
  };
  "/admin/:orgId/apps/:appId": {
    DELETE: {
      params: z.infer<typeof AdminDeleteDemoAppParamsSchema>;
      response: object;
    };
  };
  "/admin/:orgId/apps/:appId/scenarios": {
    POST: {
      params: z.infer<typeof AdminRunAppScenarioParamsSchema>;
      response: APIResponse<{ success: boolean }>;
      query: z.infer<typeof AdminRunAppScenarioQuerySchema>;
      body: ScenarioOptions[keyof ScenarioOptions];
    };
  };
  "/admin/:orgId/members": {
    GET: {
      params: {
        orgId: string;
      };
      response: APIResponse<AdminOrgMember[]>;
    };
  };
  "/admin/:orgId/members/:memberId": {
    DELETE: {
      params: z.infer<typeof AdminRemoveMemberParamsSchema>;
      response: object;
    };
  };
}
