import { ReactElement } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";

import StarsStateIcon from "@/common/components/StarsStateIcon";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import FeatureFrequencyProgress from "@/feature-legacy/components/FeatureMetrics";
import { FeedbackSatisfactionIcon } from "@/feature-legacy/components/FeedbackSatisfaction";
import { useFlagContextAttributeValues } from "@/flags/data/useFlagContextAttributeData";

import commonQueryKeys from "./commonQueryKeys";

type Result = {
  label: string;
  value: string;
  icon?: ReactElement;
};

const cacheTime = 30 * 1000; // 30 seconds

export type EntityType =
  | "event"
  | "company"
  | "flag-context-company"
  | "flag-context-user"
  | "flag-context-other"
  | "feature-frequency"
  | "feature-satisfaction"
  | "feature-funnelStep"
  | "segment"
  | "feature-flag";

export function useAttributeValues({
  entityType,
  entityValue,
  attributeKey,
  searchValue,
}: {
  entityType: EntityType;
  entityValue?: string;
  attributeKey?: string;
  searchValue?: string;
}): { data?: Result[] } {
  const { appId, envId } = useCurrentEnv();

  const companyQuery = useQuery<Result[], AxiosResponse<ErrorResponse>>({
    queryKey: commonQueryKeys.companyAttributeValues(appId, envId, {
      entityType,
      entityValue,
      attributeKey,
      searchValue,
    }),

    queryFn: () =>
      api
        .get<"/apps/:appId/company-attributes/:attribute/values">(
          `/apps/${appId}/company-attributes/${encodeURIComponent(
            attributeKey!,
          )}/values`,
          {
            params: {
              filter: searchValue ?? "",
              limit: 20,
              envId: envId!,
            },
          },
        )
        .then((res) =>
          res.data.values.map((value) => ({
            label: value,
            value: value,
          })),
        ),

    gcTime: cacheTime,
    staleTime: cacheTime,
    placeholderData: keepPreviousData,
    enabled:
      !!appId &&
      !!envId &&
      !!attributeKey &&
      entityType === "company" &&
      attributeKey.trim().length > 0,
  });

  const eventQuery = useQuery<Result[], AxiosResponse<ErrorResponse>>({
    queryKey: commonQueryKeys.eventAttributeValues(
      appId,
      envId,
      entityValue,
      attributeKey,
      searchValue,
    ),

    queryFn: () =>
      api
        .get<"/apps/:appId/events/by-name/:eventName/attributes/:attribute/values">(
          `/apps/${appId}/events/by-name/${encodeURIComponent(
            entityValue!,
          )}/attributes/${encodeURIComponent(attributeKey!)}/values`,
          {
            params: {
              filter: searchValue ?? "",
              limit: 20,
              envId: envId!,
            },
          },
        )
        .then((res) =>
          res.data.values.map((value) => ({
            label: value,
            value: value,
          })),
        ),

    gcTime: cacheTime,
    staleTime: cacheTime,
    placeholderData: keepPreviousData,
    enabled:
      !!envId &&
      !!appId &&
      !!attributeKey &&
      entityType === "event" &&
      (entityValue || "").trim().length > 0 &&
      attributeKey.trim().length > 0,
  });

  const flagContextCompanyQuery = useFlagContextAttributeValues(
    entityType == "flag-context-company" ? "company" : undefined,
    attributeKey,
    entityValue,
  );
  const flagContextUserQuery = useFlagContextAttributeValues(
    entityType == "flag-context-user" ? "user" : undefined,
    attributeKey,
    entityValue,
  );
  const flagContextOtherQuery = useFlagContextAttributeValues(
    entityType == "flag-context-other" ? "other" : undefined,
    attributeKey,
    entityValue,
  );

  switch (entityType) {
    case "company":
      return companyQuery;
    case "event":
      return eventQuery;
    case "flag-context-company":
      return flagContextCompanyQuery;
    case "flag-context-user":
      return flagContextUserQuery;
    case "flag-context-other":
      return flagContextOtherQuery;
    case "feature-frequency":
      return {
        data: [
          {
            icon: (
              <FeatureFrequencyProgress
                filledColor="brand.400"
                frequency={0}
                showText={false}
                size="2xs"
              />
            ),
            label: "Quarterly",
            value: "0",
          },
          {
            icon: (
              <FeatureFrequencyProgress
                filledColor="brand.400"
                frequency={1}
                showText={false}
                size="2xs"
              />
            ),
            label: "Monthly",
            value: "1",
          },
          {
            icon: (
              <FeatureFrequencyProgress
                filledColor="brand.400"
                frequency={2}
                showText={false}
                size="2xs"
              />
            ),
            label: "Weekly",
            value: "2",
          },
          {
            icon: (
              <FeatureFrequencyProgress
                filledColor="brand.400"
                frequency={3}
                showText={false}
                size="2xs"
              />
            ),
            label: "Daily",
            value: "3",
          },
        ],
      };

    case "feature-satisfaction":
      return {
        data: [
          {
            icon: <FeedbackSatisfactionIcon size={16} value={1} colored />,
            label: "Very dissatisfied",
            value: "1",
          },
          {
            icon: <FeedbackSatisfactionIcon size={16} value={2} colored />,
            label: "Dissatisfied",
            value: "2",
          },
          {
            icon: <FeedbackSatisfactionIcon size={16} value={3} colored />,
            label: "Neutral",
            value: "3",
          },
          {
            icon: <FeedbackSatisfactionIcon size={16} value={4} colored />,
            label: "Satisfied",
            value: "4",
          },
          {
            icon: <FeedbackSatisfactionIcon size={16} value={5} colored />,
            label: "Very satisfied",
            value: "5",
          },
        ],
      };

    case "feature-funnelStep":
      return {
        data: [
          {
            icon: <StarsStateIcon state="never" />,
            label: "Never",
            value: "0",
          },
          {
            icon: <StarsStateIcon state="tried" />,
            label: "Tried",
            value: "2",
          },
          {
            icon: <StarsStateIcon state="churned" />,
            label: "Churned",
            value: "3",
          },
          {
            icon: <StarsStateIcon state="retained" />,
            label: "Retained",
            value: "4",
          },
        ],
      };
    default:
      return { data: [] };
  }
}
