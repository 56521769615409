import {
  Box,
  BoxProps,
  Text,
  TextProps,
  Tooltip as ChakraTooltip,
} from "@chakra-ui/react";

import useChartTokens from "@/common/charts/hooks/useChartTokens";
import useDimensions from "@/common/hooks/useDimensions";

type Props = BoxProps & {
  text: string;
  tooltip?: string;
  viewBox?: DOMRect;
  chartWidth: number;
  textProps?: TextProps;
  offsetX?: number;
  offsetY?: number;
};

export const ReferenceLabel = ({
  text,
  tooltip,
  viewBox,
  chartWidth,
  textProps,
  offsetX = 0,
  offsetY = 0,
  ...rest
}: Props) => {
  const { referenceLabelBg, referenceLabelBorder, referenceLabelText } =
    useChartTokens().colors;

  const { width, height, measureRef } = useDimensions({
    fallback: { width: 256, height: 32 },
  });

  if (!chartWidth || !viewBox) return null;

  return (
    <foreignObject
      height={height + 10}
      width={width + 10}
      x={
        Math.max(44, Math.min(viewBox.x - width / 2, chartWidth - width - 22)) +
        offsetX
      }
      y={viewBox.y - height + 10 + offsetY}
    >
      <Box
        ref={measureRef}
        bg={referenceLabelBg}
        borderColor={referenceLabelBorder}
        borderRadius="md"
        borderWidth="1px"
        boxShadow="sm"
        color={referenceLabelText}
        px={2}
        py={0.5}
        width="fit-content"
        {...rest}
      >
        <ChakraTooltip label={tooltip} openDelay={300} hasArrow>
          <Text fontSize="xs" fontWeight="medium" {...textProps}>
            {text}
          </Text>
        </ChakraTooltip>
      </Box>
    </foreignObject>
  );
};
