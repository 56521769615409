import { useCallback, useMemo, useState } from "react";
import {
  keepPreviousData,
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { Paginated } from "@bucketco/shared/types/Paginated";
import { getFraction } from "@bucketco/shared/utils/getFraction";
import { Leaves } from "@bucketco/shared/utils/types";

type RequiredQueryParams<
  TData extends Record<string, any> = Record<string, any>,
  TSortBy extends string = Leaves<TData>,
> = {
  sortBy: TSortBy;
};

type BaseQueryParams = {
  sortOrder: "asc" | "desc";
  pageIndex: number;
  pageSize: number;
};

export default function useDataTable<
  TData extends Record<string, any> = Record<string, any>,
  TQueryParams extends RequiredQueryParams<TData, string> = RequiredQueryParams<
    TData,
    Leaves<TData>
  >,
  TMetaData extends Record<string, unknown> = Record<string, never>,
  TQueryFnData extends Paginated<
    TData,
    TQueryParams["sortBy"],
    TMetaData
  > = Paginated<TData, TQueryParams["sortBy"], TMetaData>,
  TError extends AxiosError<ErrorResponse> = AxiosError<ErrorResponse>,
  TQueryKey extends QueryKey = QueryKey,
>({
  apiCacheKey,
  apiHandler,
  apiOptions,
  defaultQueryParams,
}: {
  apiCacheKey: TQueryKey;
  apiHandler: (
    queryParams: BaseQueryParams & TQueryParams,
  ) => QueryFunction<TQueryFnData, TQueryKey>;
  apiOptions?: Omit<
    UseQueryOptions<TQueryFnData, TError, TQueryFnData, TQueryKey>,
    "queryKey" | "queryFn"
  >;
  defaultQueryParams: Partial<BaseQueryParams> & TQueryParams;
}) {
  const [canPaginate, setCanPaginate] = useState({
    canNextPage: false,
    canPreviousPage: false,
  });

  const [paginateActions, setPaginateActions] = useState({
    nextPage: () => {},
    previousPage: () => {},
  });
  const [queryParams, setQueryParams] = useState<
    BaseQueryParams & TQueryParams
  >({
    pageIndex: 0,
    pageSize: 20,
    sortOrder: "desc",
    ...defaultQueryParams,
  });

  const { data, isFetching, isLoading, isPlaceholderData } = useQuery<
    TQueryFnData,
    TError,
    TQueryFnData,
    TQueryKey
  >({
    queryKey: (Array.isArray(apiCacheKey)
      ? [...apiCacheKey, queryParams]
      : [apiCacheKey, queryParams]) as any,
    queryFn: apiHandler(queryParams),
    placeholderData: keepPreviousData,
    ...apiOptions,
  });

  type FetchDataFnType = (
    options: Partial<BaseQueryParams> & Partial<TQueryParams>,
  ) => void;

  const fetchData = useCallback<FetchDataFnType>(
    (options) => {
      setQueryParams((existing) => ({
        ...existing,
        ...options,
      }));
    },
    [setQueryParams],
  );

  const pageCount = useMemo(() => {
    if (!data || !data.totalCount || !data.pageSize) return 0;
    return Math.ceil(getFraction(data.totalCount, data.pageSize));
  }, [data]);

  return {
    data,
    isFetching,
    isLoading,
    isPreviousData: isPlaceholderData,
    pageCount,
    fetchData,
    canPaginate,
    setCanPaginate,
    paginateActions,
    setPaginateActions,
  };
}

export type UseDataTableReturnType<
  TData extends Record<string, any> = Record<string, any>,
  TQueryParams extends RequiredQueryParams<
    TData,
    Leaves<TData>
  > = RequiredQueryParams<TData, Leaves<TData>>,
  TMetaData extends Record<string, unknown> = Record<string, never>,
  TQueryFnData extends Paginated<
    TData,
    TQueryParams["sortBy"],
    TMetaData
  > = Paginated<TData, TQueryParams["sortBy"], TMetaData>,
  TError extends AxiosError<ErrorResponse> = AxiosError<ErrorResponse>,
  TQueryKey extends QueryKey = QueryKey,
> = ReturnType<
  typeof useDataTable<
    TData,
    TQueryParams,
    TMetaData,
    TQueryFnData,
    TError,
    TQueryKey
  >
>;
