import { useEffect } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ButtonGroup, Flex, Switch, Text } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import {
  SlackIntegrationSchema,
  SlackIntegrationType,
} from "@bucketco/shared/featureAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import FormCancel from "@/common/components/form/FormCancel";
import { FormRootError } from "@/common/components/form/FormRootError";
import FormSubmit from "@/common/components/form/FormSubmit";
import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import PostToSlackNow from "@/common/components/PostToSlackNow";
import { getFormMutationSubmitHandler } from "@/common/hooks/useApiForm";
import useDefaultSlackChannel from "@/common/hooks/useDefaultSlackChannel";
import useSlackChannels from "@/common/hooks/useSlackChannels";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { OnlyProductionEnvironment } from "@/environment/components/EnvironmentCallouts";
import { useFeatureData } from "@/feature/data/useFeatureData";
import { useFeatureUpdateMutation } from "@/feature/data/useFeatureUpdateMutation";
import { SlackChannelAutocompleteSelect } from "@/global-settings/components/SlackChannelAutocompleteSelect";
import SlackOrgIntegration from "@/global-settings/components/SlackOrgIntegration";

export function SettingsSlack() {
  const { currentOrg, currentEnv } = useAuthContext();

  const hasSlackConnection = currentOrg?.hasSlackConnection;

  useEffect(() => {
    segmentAnalytics.page("Feature Slack Integration");
  }, []);

  return (
    <>
      {!currentEnv?.isProduction ? (
        <OnlyProductionEnvironment entity="slack reports" />
      ) : !hasSlackConnection ? (
        <SlackOrgIntegration />
      ) : (
        <SlackForm />
      )}
    </>
  );
}

export function SlackForm() {
  const { featureId } = useParams();
  const { data: feature } = useFeatureData(featureId!);

  const form = useForm<z.input<typeof SlackIntegrationSchema>>({
    resolver: zodResolver(SlackIntegrationSchema),
    mode: "onChange",
    defaultValues: {
      channelId: feature?.integrations.slack.channelId ?? undefined,
      ...feature?.integrations.slack,
    },
  });

  const updateMutation = useFeatureUpdateMutation(featureId!);
  const submitHandler = getFormMutationSubmitHandler(
    form,
    updateMutation,
    (result) => {
      form.reset(result.integrations.slack);
    },
    {
      prepareVariables: (values) => ({
        id: featureId!,
        integrations: { slack: values },
      }),
    },
  );

  return (
    <FormProvider {...form}>
      <Flex
        as="form"
        direction={"column"}
        gap={4}
        maxW="compactForm"
        onSubmit={submitHandler}
      >
        <FormSlackIntegration />
        <FormRootError />
        <ButtonGroup>
          <FormSubmit>Save</FormSubmit>
          {form.formState.isDirty && (
            <FormCancel color="dimmed" size="sm" onClick={() => form.reset()}>
              Reset
            </FormCancel>
          )}
        </ButtonGroup>
      </Flex>
    </FormProvider>
  );
}

const reports = [
  {
    reportType: "weeklyFeatureReport" as const,
    fieldName: "weeklyReport" as const,
    display: "Weekly report",
    description:
      "Get the feature report with current adoption metrics and chart in Slack every Monday.",
  },
  {
    reportType: "dailyFeatureReport" as const,
    fieldName: "dailyReport" as const,
    display: "Daily report",
    description:
      "Get a list of new companies entering the Using it state every day.",
  },
];

export default function FormSlackIntegration() {
  const { featureId } = useParams();
  const { currentEnv } = useAuthContext();

  const { data: slackChannels = [], isLoading } = useSlackChannels();

  const form = useFormContext<SlackIntegrationType>();
  const currentChannel = form.watch("channelId");

  useDefaultSlackChannel({
    slackChannels,
    currentChannel,
    preferredChannelId: currentEnv?.slackChannelId,
    cb: (channel) => {
      form.setValue("channelId", channel.id);
    },
  });

  return (
    <Flex direction="column" gap={5} maxW="compactForm" w="full">
      <ManagedFormControl
        label="Slack Channel"
        name="channelId"
        render={({ field }) => (
          <SlackChannelAutocompleteSelect
            {...field}
            onChange={(channel) => field.onChange(channel?.id)}
          />
        )}
      />

      {reports.map((r) => (
        <Flex key={r.fieldName} direction="column" w="full">
          <ManagedFormControl
            alignItems="center"
            isDisabled={form.formState.isSubmitting || isLoading}
            label={r.display}
            name={r.fieldName}
            render={({ field }) => (
              <Flex align="center" justify="space-between">
                <PostToSlackNow
                  key={r.fieldName}
                  channelId={currentChannel ?? ""}
                  featureId={featureId!}
                  isDisabled={
                    form.formState.isSubmitting || isLoading || !currentChannel
                  }
                  reportType={r.reportType}
                />
                <Switch
                  {...field}
                  colorScheme="brand"
                  isChecked={field.value}
                />
              </Flex>
            )}
            horizontal
          />
          <Text color="dimmed" fontSize="sm" maxW="xs">
            {r.description}
          </Text>
        </Flex>
      ))}

      <Flex direction="column" w="full">
        <ManagedFormControl
          alignItems="center"
          isDisabled={form.formState.isSubmitting || isLoading}
          label="Notify on user feedback"
          name="feedbackNotification"
          render={({ field }) => (
            <Flex align="center" justify="flex-end">
              <Switch {...field} colorScheme="brand" isChecked={field.value} />
            </Flex>
          )}
          horizontal
        />
        <Text color="dimmed" fontSize="sm" maxW="xs">
          An immediate notification when a user leaves feedback on this feature
        </Text>
      </Flex>
    </Flex>
  );
}
