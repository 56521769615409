import { Helmet } from "react-helmet-async";
import { FaGoogle } from "react-icons/fa";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import {
  LoginErrorMessages,
  loginErrorMessagesSchema,
} from "@bucketco/shared/schemas/loginErrorSchema";
import { redirectUrlSchema } from "@bucketco/shared/schemas/redirectUrlSchema";

import BucketLogo from "@/common/assets/bucket-logo-currentcolor.svg?react";
import CenteredLayout from "@/common/components/CenteredLayout";
import { API_URL } from "@/common/utils/env";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

const messageTypeToMessageMap: {
  [key in LoginErrorMessages]: React.ReactNode;
} = {
  no_email_error: "Invalid email address",
  only_company_emails_error: (
    <>
      Only work emails are allowed.
      <br />
      Please try again with your work email.
    </>
  ),
};

export default function Login() {
  const locationSearchParams = new URLSearchParams(window.location.search);
  const parsedRedirect = redirectUrlSchema.safeParse(
    locationSearchParams.get("redirect"),
  );
  const errorMessageTypeParseResult = loginErrorMessagesSchema.safeParse(
    locationSearchParams.get("error"),
  );
  const errorMessage = errorMessageTypeParseResult.success
    ? messageTypeToMessageMap[errorMessageTypeParseResult.data]
    : null;

  const redirect = parsedRedirect.success ? parsedRedirect.data : "/";

  const queryParams = new URLSearchParams({
    redirect,
  });

  const isInvited = redirect.includes("/invite/");

  const loginUrl = `${API_URL}/auth/google?${queryParams.toString()}`;

  let header, text;

  if (isInvited) {
    header = "You're invited";
    text = "To accept your invitation, sign in below.";
  } else {
    header = "Log in to Bucket";
    text =
      "If you don't have an account it will be created when signing in for the first time.";
  }

  const logoColor = useColorModeValue("gray.900", "white");

  return (
    <CenteredLayout>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box>
        <Flex alignItems="center" direction="column" maxW="sm">
          <Box color={logoColor} mb={4}>
            <BucketLogo width="30" />
          </Box>
          <Heading fontSize="2xl" mb={4}>
            {header}
          </Heading>
          <Text color="gray.500" fontWeight="medium" textAlign="center">
            {text}
          </Text>
          {errorMessage ? (
            <Alert mt={5} status="warning">
              <AlertIcon />
              {errorMessage}
            </Alert>
          ) : null}
          <Button
            as="a"
            href={loginUrl}
            leftIcon={<FaGoogle />}
            mt="10"
            size="lg"
            variant="outline"
            onClick={() => {
              segmentAnalytics.track("Google Authentication Button Clicked");
            }}
          >
            Continue with Google
          </Button>

          <Text
            color="gray.500"
            fontSize="xs"
            fontWeight="medium"
            mt="6"
            textAlign="center"
          >
            By continuing, you&apos;re agreeing to our{" "}
            <a href="https://bucket.co/pages/policies">policies</a>.
          </Text>
        </Flex>
      </Box>
    </CenteredLayout>
  );
}
