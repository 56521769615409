import { useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Avatar, HStack, Text, useColorModeValue } from "@chakra-ui/react";

import {
  FeatureCompaniesQuerySortByType,
  FeatureCompany,
  FeatureDetail,
} from "@bucketco/shared/featureAPI";
import { FeatureCompanyUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import EmptyStateTriedDark from "@/common/assets/empty-state-companies-tried-dark.svg?react";
import EmptyStateTriedLight from "@/common/assets/empty-state-companies-tried-light.svg?react";
import DataCell from "@/common/components/DataCell";
import DataTable, { DataTableProps } from "@/common/components/DataTable";
import EmptyState from "@/common/components/EmptyState";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import { RecentActivity } from "@/common/components/RecentActivity";
import { createSortableColumnHelper } from "@/common/types/reactTableHelpers";
import { linkClickModifierHandler } from "@/common/utils/linkClickHandler";
import FeatureFrequencyProgress from "@/feature-legacy/components/FeatureMetrics";
import { FeedbackSatisfactionIcon } from "@/feature-legacy/components/FeedbackSatisfaction";
import { useFeatureContext } from "@/feature-legacy/contexts/featureContext";
import { useStarsFunnelColors } from "@/feature-legacy/hooks/useStarsFunnelColors";

type CompaniesListProps = Omit<
  DataTableProps<FeatureCompany>,
  "data" | "columns"
> & {
  feature?: FeatureDetail;
  companies: FeatureCompany[];
  isLoading: boolean;
};

export function CompaniesList({
  companies,
  feature,
  isLoading,
  ...rest
}: CompaniesListProps) {
  const navigate = useNavigate();
  const { featureId } = useParams();

  const { relevantMetrics } = useFeatureContext();
  const { currentEnv } = useAuthContext();
  const { retainedColor } = useStarsFunnelColors();

  const emptyIllu = useColorModeValue(
    <EmptyStateTriedLight />,
    <EmptyStateTriedDark />,
  );

  const emptyDescription = `No companies in selection`;

  const columnHelper = createSortableColumnHelper<
    FeatureCompany,
    FeatureCompaniesQuerySortByType
  >();

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        cell: ({ row }) => {
          return (
            <Text
              as={Link}
              to={FeatureCompanyUrl(currentEnv!, row.original.id, featureId!)}
            >
              <HStack>
                <Avatar
                  name={row.original.name || row.original.id}
                  size="2xs"
                />
                <Text fontSize="md" fontWeight="medium" noOfLines={1}>
                  {row.original.name || row.original.id}
                </Text>
              </HStack>
            </Text>
          );
        },
      }),
      columnHelper.accessor("satisfaction", {
        header: "Satisfaction",
        cell: (cell) => {
          const value = cell.getValue();
          return value ? (
            <FeedbackSatisfactionIcon colored={true} value={value} />
          ) : (
            <NotAvailableCell />
          );
        },
      }),
      columnHelper.accessor("funnelStep", {
        sortDescFirst: true,
        header: () => (
          <HStack spacing={1}>
            <Text>STARS state</Text>
            <InfoIconTooltip
              text={"This is the current state that the company has reached."}
            />
          </HStack>
        ),
        cell: (cell) => <DataCell type="funnelStep" value={cell.getValue()} />,
      }),
      columnHelper.accessor("firstUsed", {
        header: "First used",
        sortDescFirst: true,
        cell: (cell) => <DataCell type="date" value={cell.getValue()} />,
      }),
      columnHelper.accessor("lastUsed", {
        header: "Last used",
        sortDescFirst: true,
        cell: (cell) => <DataCell type="date" value={cell.getValue()} />,
      }),
      columnHelper.accessor("lastSeen", {
        header: "Last seen",
        sortDescFirst: true,
        cell: (cell) => <DataCell type="date" value={cell.getValue()} />,
      }),
      columnHelper.accessor("userCount", {
        header: "Users",
        cell: (cell) => (
          <DataCell
            type={"number"}
            value={cell.getValue().toString()}
            compact
          />
        ),
      }),
      ...(relevantMetrics.includes("frequency")
        ? [
            columnHelper.accessor("frequency", {
              header: "Frequency",
              cell: (cell) => {
                const value = cell.getValue();
                return value && cell.row.original.funnelStep === "retained" ? (
                  <FeatureFrequencyProgress
                    filledColor={retainedColor}
                    frequency={value}
                  />
                ) : (
                  <NotAvailableCell />
                );
              },
            }),
          ]
        : []),
      columnHelper.accessor("usage", {
        header: "Recent activity (30d)",
        enableSorting: false,
        cell: (cell) => (
          <RecentActivity
            data={cell.getValue().map((usage) => ({
              day: usage.date.toString(),
              eventCount: usage.eventCount,
            }))}
            mergeActive={feature?.source === "attribute"}
            showAmplitude={feature?.source === "event"}
            showEventCount={feature?.source === "event"}
          />
        ),
      }),
      columnHelper.accessor("eventCount", {
        header: "Total events",
        cell: (cell) => (
          <DataCell
            type="number"
            value={
              feature?.source === "event" ? cell.getValue().toString() : null
            }
            compact
          />
        ),
      }),
    ],
    [
      columnHelper,
      currentEnv,
      feature?.source,
      featureId,
      retainedColor,
      relevantMetrics,
    ],
  );

  if (isLoading) {
    return null;
  }

  if (companies.length === 0) {
    return (
      <EmptyState description={emptyDescription} flexGrow={1}>
        {emptyIllu}
      </EmptyState>
    );
  }

  return (
    <DataTable
      columns={columns}
      data={companies}
      rowOnClick={(row, e) => {
        const url = FeatureCompanyUrl(currentEnv!, row.original.id, featureId!);
        e.preventDefault();

        linkClickModifierHandler(url, () => navigate(url))(e);
      }}
      variant="clickable"
      {...rest}
    >
      <colgroup>
        <col width="75%" />
        <col width={1} />
        <col width={1} />
        <col width={1} />
        <col width={1} />
        <col width={1} />
        <col width={1} />
        {relevantMetrics.includes("frequency") && <col width={1} />}
        <col width={1} />
      </colgroup>
    </DataTable>
  );
}
