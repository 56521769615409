import {
  FieldValues,
  useForm,
  UseFormProps,
  UseFormReturn,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { z } from "zod";

import { ErrorResponse } from "@bucketco/shared/api";

import { convertZodErrorsToHookFormErrors } from "@/common/utils/convertZodErrorToHookFormError";

export default function useApiForm<TArgs extends FieldValues, TResult>(
  apiHandler: (data: TArgs) => Promise<TResult>,
  argsSchema: z.AnyZodObject | z.ZodTypeAny,
  mutationOptions?: Omit<
    UseMutationOptions<TResult, AxiosError, TArgs>,
    "mutationFn"
  >,
  formOptions?: UseFormProps<TArgs>,
) {
  // form
  const form = useForm<TArgs>({
    resolver: zodResolver(argsSchema),
    mode: "onChange",
    ...formOptions,
  });

  // mutation
  const mutation = useMutation<TResult, AxiosError<ErrorResponse>, TArgs>({
    mutationFn: apiHandler,
    retry: 0,
    ...mutationOptions,
  });

  const { mutateAsync } = mutation;

  const handleSubmit = form.handleSubmit((data) =>
    mutateAsync(data)
      .then(() => {
        form.clearErrors();
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        form.clearErrors();
        const errorResponse = err.response?.data;
        const validationErrors = errorResponse?.validationErrors || [];

        if (validationErrors.length > 0) {
          const hookFormErrors =
            convertZodErrorsToHookFormErrors(validationErrors);

          for (const [fieldName, fieldError] of Object.entries(
            hookFormErrors,
          )) {
            form.setError(
              fieldName as Parameters<typeof form.setError>[0],
              fieldError,
            );
          }
        }

        if (typeof errorResponse?.error?.message === "string") {
          form.setError("root", { message: errorResponse.error.message });
        }
      }),
  );

  return {
    form,
    handleSubmit,
    mutation,
  };
}

export function getFormMutationSubmitHandler<
  TFormFields extends FieldValues,
  TValue,
  TVariables = TFormFields,
>(
  form: UseFormReturn<TFormFields>,
  mutation: UseMutationResult<TValue, AxiosError<ErrorResponse>, TVariables>,
  onSuccess?: (result: TValue, variables: TFormFields) => void,
  options?: {
    prepareVariables?: (data: TFormFields) => TVariables;
  },
) {
  return form.handleSubmit(async (data) => {
    form.clearErrors();

    return mutation.mutateAsync(
      options?.prepareVariables
        ? options.prepareVariables(data)
        : (data as unknown as TVariables),
      {
        onSuccess(result) {
          onSuccess?.(result, data);
        },
        onError(error) {
          // Call reset to force the form out of `isLoading` state
          form.reset(data);

          const errorResponse = error.response?.data;
          const validationErrors = errorResponse?.validationErrors || [];

          if (validationErrors.length > 0) {
            const hookFormErrors =
              convertZodErrorsToHookFormErrors(validationErrors);

            for (const [fieldName, fieldError] of Object.entries(
              hookFormErrors,
            )) {
              form.setError(
                fieldName as Parameters<typeof form.setError>[0],
                fieldError,
              );
            }
          }

          if (typeof errorResponse?.error?.message === "string") {
            form.setError("root", { message: errorResponse.error.message });
          }
        },
      },
    );
  });
}
