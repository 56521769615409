import { forwardRef } from "react";
import { Input } from "@chakra-ui/react";

import { SlackChannel } from "@bucketco/shared/slackConnectionAPI";

import SlackHashLogo from "@/common/assets/slack-hash-logo.svg?react";
import AutocompleteSelect, {
  AutocompleteSelectProps,
  getStringFilterFunction,
  getStringRenderFunction,
} from "@/common/components/AutocompleteSelect";
import useSlackChannels from "@/common/hooks/useSlackChannels";

type SlackChannelAutocompleteSelectProps = {
  value: string | undefined;
  onChange: (value?: SlackChannel) => void;
  placeholder?: string;
} & Omit<
  AutocompleteSelectProps<SlackChannel>,
  | "value"
  | "onChange"
  | "isLoadingSuggestions"
  | "itemFilterFn"
  | "itemKeyProperty"
  | "itemRenderfn"
  | "suggestions"
>;

export const SlackChannelAutocompleteSelect = forwardRef<
  HTMLButtonElement,
  SlackChannelAutocompleteSelectProps
>(function SlackChannelAutocompleteSelect(
  {
    size,
    value,
    onChange,
    placeholder = "No channel selected",
    isDisabled = false,
    showClearButton: _,
  },
  ref,
) {
  const channelQuery = useSlackChannels();

  if (channelQuery.isLoading) {
    return <Input placeholder="Loading..." size={size} disabled />;
  }

  if (channelQuery.isError) {
    return (
      <Input placeholder="Error loading channels..." size={size} disabled />
    );
  }

  if (channelQuery.isSuccess) {
    const { data: channels } = channelQuery;

    if (!channels || channels.length === 0) {
      return <Input placeholder="No channels found" size={size} disabled />;
    }

    return (
      <AutocompleteSelect
        ref={ref}
        isDisabled={isDisabled}
        itemFilterFn={getStringFilterFunction("name")}
        itemKeyProperty="id"
        itemRenderfn={getStringRenderFunction("name")}
        leftIcon={<SlackHashLogo height="14" />}
        maxSuggestions={50}
        placeholder={placeholder}
        size={size}
        suggestions={channels}
        value={channels.find(({ id }) => id === value)}
        onChange={onChange}
        onClear={onChange}
      />
    );
  }
});
