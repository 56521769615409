import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import {
  Alert,
  AlertIcon,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

import { GoalConfiguration } from "@bucketco/shared/goalAPI";
import { ReleasePostArgs } from "@bucketco/shared/releaseAPI";
import { SegmentDTO } from "@bucketco/shared/segmentAPI";

import { FeatureAutocompleteSelect } from "@/common/components/FeatureAutocompleteSelect";
import FormNumberInput from "@/common/components/form/FormNumberInput";
import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import useAllSegment from "@/company/data/useAllSegment";
import FormSegmentPicker from "@/feature-legacy/components/form/FormSegmentPicker";
import FormFrequencyPicker from "@/feature-legacy/components/FormFrequencyPicker";
import SegmentChart from "@/feature-legacy/components/SegmentChart";
import useFeatureData from "@/feature-legacy/data/useFeatureData";
import FormGoalMetricSelector from "@/release/components/FormGoalMetricSelector";
import useGoalMetricOptions from "@/release/hooks/useGoalMetricOptions";
import { formatGoalValue } from "@/release/utils/format";

type Props = {
  index: number;
  segments: SegmentDTO[];
  previewValue: number;
  isPercentage?: boolean;
  onChange?: (field: keyof GoalConfiguration, value: any) => void;
};

export const LegacyGoalForm = ({
  index,
  previewValue,
  isPercentage,
  segments,
  onChange,
}: Props) => {
  const baseName = `goals.${index}` as const;
  const form = useFormContext<ReleasePostArgs>();

  const selectedMetricId = form.watch(`${baseName}.configuration.metric`);
  const selectedFeatureId = form.watch(`${baseName}.configuration.featureId`);
  const selectedSegmentId = form.watch(
    `${baseName}.configuration.subsegmentId`,
  );

  const { data: selectedFeature } = useFeatureData(selectedFeatureId);

  const metrics = useGoalMetricOptions();

  const selectedMetric = metrics.find((m) =>
    m.measurements.some((mm) => mm.id === selectedMetricId),
  );
  const selectedMeasurement = selectedMetric?.measurements.find(
    (mm) => mm.id === selectedMetricId,
  );

  const allSegment = useAllSegment();
  const segment = useMemo(() => {
    return segments.find((s) => s.id === selectedSegmentId);
  }, [selectedSegmentId, segments]);

  const currentValueDisplay = formatGoalValue(
    previewValue,
    isPercentage,
    selectedMeasurement?.id,
  );

  const subsegmentColor = useColorModeValue("teal.500", "teal.300");

  return (
    <VStack>
      <ManagedFormControl
        _label={{ w: "24" }}
        label="Feature"
        name={`${baseName}.configuration.featureId`}
        placeholder="Select a feature"
        render={({ field }) => (
          <FeatureAutocompleteSelect
            {...field}
            onChange={(value) => {
              field.onChange(value?.id);
            }}
          />
        )}
        horizontal
      />

      <FormGoalMetricSelector
        name={`${baseName}.configuration.metric`}
        selectedFeature={selectedFeature}
        onChange={(nextValue) => {
          onChange?.("metric", nextValue);
        }}
      />
      {selectedMeasurement?.id === "averageFrequency" ? (
        <FormFrequencyPicker
          _label={{ w: "24" }}
          label="Goal target"
          name={`${baseName}.configuration.threshold`}
          size="sm"
          horizontal
          onChange={(value) => {
            onChange?.("threshold", value);
          }}
        />
      ) : (
        <FormNumberInput
          _label={{ w: "24" }}
          label="Goal target"
          max={
            selectedMeasurement
              ? selectedMeasurement.maxThreshold * (isPercentage ? 100 : 1)
              : undefined
          }
          min={1}
          name={`${baseName}.configuration.threshold`}
          scaleFactor={isPercentage ? 100 : 1}
          size="sm"
          unit={isPercentage ? "%" : undefined}
          horizontal
          onChange={(value) => {
            onChange?.("threshold", value);
          }}
        />
      )}
      {selectedMetric && (
        <Text
          color="dimmed"
          fontSize="sm"
          mt="0px !important"
          pl="6.5rem"
          w="full"
        >
          Current value is {currentValueDisplay}
        </Text>
      )}
      <FormSegmentPicker
        _label={{ w: "24" }}
        includeAll={false}
        label="Subsegment"
        menuDescription="Apply optional subsegment in addition to the feature's target segment:"
        name={`${baseName}.configuration.subsegmentId`}
        placeholder="Optional subsegment"
        selectedColor={subsegmentColor}
        w="full"
        horizontal
        showClearButton
        onChange={(value) => {
          onChange?.("subsegmentId", value);
        }}
      />
      <Text
        alignItems="end"
        fontSize="sm"
        justifyContent="end"
        mt="0px !important"
        pl="6.5rem"
        w="full"
      >
        <Text as="span" color="dimmed">
          Feature&apos;s target segment is{" "}
        </Text>
        <Text as="span" ml={1} pos="relative" top="1px">
          <SegmentChart
            segmentId={selectedFeature?.segment?.id ?? allSegment?.id}
          />
        </Text>
      </Text>
      {segment && segment.deletedAt !== null && (
        <Alert alignItems="flex-start" mt="18px !important" status="warning">
          <AlertIcon />
          <VStack alignItems="flex-start" spacing={4}>
            <Text as="p">
              The selected subsegment has been deleted since the goal was
              created. The evaluation will continue as if it still existed.
            </Text>
          </VStack>
        </Alert>
      )}
    </VStack>
  );
};
