import { RiChat1Line } from "react-icons/ri";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Avatar,
  Button,
  Flex,
  Grid,
  GridItem,
  GridProps,
  HStack,
  Link,
  Text,
} from "@chakra-ui/react";

import { CompanyUrl, FeatureFeedbackUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import { SectionHeading } from "@/common/components/SectionHeading";
import TimestampCell from "@/common/components/TimestampCell";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import { FeedbackSatisfactionIcon } from "@/feature-legacy/components/FeedbackSatisfaction";
import { useFeatureContext } from "@/feature-legacy/contexts/featureContext";
import { useFeedbacks } from "@/feedback/data/useFeedbacks";

type FeedbackSampleProps = {
  amount?: number;
  columns?: number;
} & GridProps;

export default function FeedbackSample({
  amount = 4,
  columns = 2,
  ...gridProps
}: FeedbackSampleProps) {
  const { currentEnv } = useAuthContext();
  const { feature } = useFeatureContext();
  const { featureId } = useParams();
  const [subsegment] = useSubsegmentParam();

  const query = useFeedbacks({
    featureId,
    pageSize: amount,
  });

  return (
    <>
      {query.isSuccess && query.data.data.length > 0 && (
        <SectionHeading>Responses</SectionHeading>
      )}
      <Grid
        gap={4}
        gridTemplateColumns={`repeat(${columns}, 1fr)`}
        gridTemplateRows="1fr 1fr"
        minHeight={
          query.isSuccess && query.data.data.length > 0 ? "230px" : undefined
        }
        {...gridProps}
      >
        {query.isLoading && (
          <GridItem
            alignSelf="center"
            colSpan={2}
            justifySelf="center"
            rowSpan={2}
          >
            <AnimatedSpinner color="dimmed" size="sm" show />
          </GridItem>
        )}

        {query.isError && (
          <GridItem
            alignSelf="center"
            colSpan={2}
            justifySelf="center"
            rowSpan={2}
          >
            Failed loading feedback
          </GridItem>
        )}

        {query.isSuccess &&
          (query.data.data.length === 0 ? (
            <GridItem
              align="center"
              as={Flex}
              color="dimmed"
              colSpan={3}
              direction="column"
              fontSize="sm"
              gap={4}
              p={4}
              rowSpan={3}
              w="full"
            >
              <HStack>
                <RiChat1Line size={16} />
                <Text>No feedback received yet</Text>
              </HStack>
              <Button
                as={RouterLink}
                to={FeatureFeedbackUrl(currentEnv!, feature!, {
                  subsegment: subsegment[0],
                })}
                variant="outline"
              >
                Start gathering feedback
              </Button>
            </GridItem>
          ) : (
            <>
              {query.data.data.map((feedback) => (
                <Grid
                  key={feedback.id}
                  borderColor="appBorder"
                  borderRadius="md"
                  borderWidth={1}
                  boxShadow="sm"
                  gap={3}
                  gridTemplateAreas={`'icon comment timestamp' 'company company step'`}
                  gridTemplateColumns="min-content 1fr min-content"
                  gridTemplateRows="1fr min-content"
                  px={3.5}
                  py={3}
                >
                  {feedback.score ? (
                    <FeedbackSatisfactionIcon
                      colored={true}
                      gridArea="icon"
                      value={feedback.score}
                    />
                  ) : (
                    <NotAvailableCell />
                  )}

                  {feedback.comment ? (
                    <Text
                      _after={{ content: `'”'` }}
                      _before={{ content: `'“'` }}
                      noOfLines={3}
                    >
                      {feedback.comment}
                    </Text>
                  ) : (
                    <Text color="dimmed" fontStyle={"italic"}>
                      No comment
                    </Text>
                  )}

                  <GridItem color="dimmed" gridArea="timestamp">
                    <TimestampCell value={feedback.timestamp}></TimestampCell>
                  </GridItem>

                  <GridItem gridArea="company">
                    {feedback.companyId ? (
                      <RouterLink
                        to={CompanyUrl(currentEnv!, feedback.companyId)}
                      >
                        <HStack>
                          <Avatar
                            name={feedback.companyName || feedback.companyId}
                            size="2xs"
                          />
                          <Text color="dimmed" fontSize="sm" noOfLines={1}>
                            {feedback.companyName || feedback.companyId}
                          </Text>
                        </HStack>
                      </RouterLink>
                    ) : (
                      <NotAvailableCell />
                    )}
                  </GridItem>

                  <GridItem>{/* TODO: Step */}</GridItem>
                </Grid>
              ))}
            </>
          ))}
      </Grid>

      {query.data && query.data.totalCount > 0 && (
        <Link
          as={RouterLink}
          color="dimmed"
          to={FeatureFeedbackUrl(currentEnv!, feature!, {
            subsegment: subsegment[0],
          })}
        >
          See all {query.data.totalCount} responses
        </Link>
      )}
    </>
  );
}
