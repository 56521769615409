import { useMemo } from "react";
import { Text } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReferenceLineProps, XAxisProps, YAxisProps } from "recharts";

import {
  FrequencyNumber,
  frequencyNumberToText,
} from "@bucketco/shared/featureAPI";
import { getFraction } from "@bucketco/shared/utils/getFraction";

import FrequencyChart from "@/common/charts/components/FrequencyChart";
import useChartTokens from "@/common/charts/hooks/useChartTokens";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useFirstChangeFromTo } from "@/common/hooks/useFirstChangeFromTo";
import {
  epochIsSame,
  epochToDate,
  epochToShortDate,
} from "@/common/utils/datetime";
import dayjs from "@/common/utils/dayjs";
import featureQueryKeys from "@/feature-legacy/data/featureQueryKeys";
import {
  HistoricalDataParams,
  useFeatureHistoricalData,
} from "@/feature-legacy/data/useFeatureHistoricalData";
import useFeatureHasData from "@/feature-legacy/hooks/useFeatureHasData";
import { padTimeseries } from "@/feature-legacy/utils/padTimeseries";

export type AreaParams = Parameters<typeof FrequencyChart>[0] & {
  [key: string]: any;
};

export function useFrequencyChartData(
  featureId: string | undefined,
  params: Omit<HistoricalDataParams, "envId">,
  options?: {
    chartWidth?: number;
  },
): AreaParams {
  const { appId, envId } = useCurrentEnv();

  const { sizes } = useChartTokens();
  const queryClient = useQueryClient();

  const { data, isLoading } = useFeatureHistoricalData(featureId, params);

  const featureHasDataQuery = useFeatureHasData(featureId);

  useFirstChangeFromTo(
    featureHasDataQuery.data?.historicalDataProcessed,
    false,
    true,
    () => {
      queryClient.refetchQueries({
        queryKey: featureQueryKeys.singleMetricsHistoricalFrequency(
          appId,
          envId,
          featureId,
          params,
        ),
      });
    },
  );

  const timeseries = useMemo(() => {
    const res = (data?.timeseries ?? []).map((point) => {
      return {
        ...point,
        value:
          typeof point.averageFrequency === "number"
            ? point.averageFrequency + 0.5
            : point.averageFrequency,
      };
    });
    return padTimeseries(
      res,
      params.startDate,
      params.endDate,
      params.granularity,
    );
  }, [data?.timeseries, params.startDate, params.endDate, params.granularity]);

  const tickCount = options?.chartWidth
    ? Math.floor((options.chartWidth - sizes.yAxisWidth * 2) / 48) // 48px per tick
    : 0;
  const granularityDiff = dayjs(params.endDate).diff(
    params.startDate,
    params.granularity === "daily" ? "day" : "week",
  );
  const nowDiff = dayjs().diff(
    params.startDate,
    params.granularity === "daily" ? "day" : "week",
  );
  const shift = nowDiff % 2; // always show now tick

  const xAxis: XAxisProps = {
    tickFormatter: (epoch: number, index: number) => {
      if (
        (index + shift) % Math.ceil(getFraction(granularityDiff, tickCount)) !==
        0
      )
        return "";
      if (epochIsSame(epoch, params.granularity === "daily" ? "day" : "week"))
        return "Now";
      return epochToShortDate(epoch);
    },
    interval: 0,
    domain: [dayjs(params.startDate).unix(), dayjs(params.endDate).unix()],
  };

  const yAxis: YAxisProps = {
    ticks: [0.5, 1.5, 2.5, 3.5],
    tickFormatter: (n) => frequencyNumberToText(n - 0.5),
    domain: [0, 4],
  };

  const referenceLines: ReferenceLineProps[] = [
    {
      y: 1,
      strokeDasharray: "4",
    },
    {
      y: 2,
      strokeDasharray: "4",
    },
    {
      y: 3,
      strokeDasharray: "4",
    },
    {
      y: 4,
      strokeDasharray: "4",
    },
  ];

  const tooltip = {
    labelFormatter: (epoch: number) => {
      if (epochIsSame(epoch, params.granularity === "daily" ? "day" : "week")) {
        return (
          <>
            <Text as="span">{dayjs().format("MMM D, LT")}</Text>
            <br />
            <Text as="span" color="dimmed">
              (Partial {params.granularity === "daily" ? "day" : "week"})
            </Text>
          </>
        );
      }
      return epochToDate(epoch);
    },
    formatter: (value: FrequencyNumber) => {
      if (typeof value === "number") {
        return frequencyNumberToText(value - 0.5);
      }
      frequencyNumberToText(value);
    },
  };

  const dataKeys = [
    {
      key: "value",
      name: "Frequency",
    },
  ];

  return {
    data: timeseries,
    dataKeys,
    isLoading: isLoading || !featureHasDataQuery.data?.historicalDataProcessed,
    xAxis,
    yAxis,
    tooltip,
    referenceLines,
  };
}
