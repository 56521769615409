import { useEffect, useMemo } from "react";
import { RiFileCopyLine, RiSettings3Line } from "react-icons/ri";
import {
  Link,
  Link as RouterLink,
  Outlet,
  To,
  useParams,
} from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  IconButton,
  Input,
  Tooltip,
  useClipboard,
  useColorModeValue,
} from "@chakra-ui/react";
import dayjs from "dayjs";

import { FunnelStep, FunnelStepList } from "@bucketco/shared/featureAPI";
import { FeedbackDTO, FeedbackQueryType } from "@bucketco/shared/feedbackAPI";
import { SatisfactionSpan } from "@bucketco/shared/schemas/satisfactionScore";

import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import EmptyState from "@/common/components/EmptyState";
import SectionDescription from "@/common/components/SectionDescription";
import { SectionHeading } from "@/common/components/SectionHeading";
import SidebarLayout, {
  SidebarContainer,
  SidebarSection,
} from "@/common/components/SidebarLayout";
import TablePagination from "@/common/components/TablePagination";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import useDataTable from "@/common/hooks/useDataTable";
import { useFeedbackPromptStatistics } from "@/common/hooks/useFeedbackPromptStatistics";
import { useLocalStorageTableConfiguration } from "@/common/hooks/useLocalStorageTableConfiguration";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import {
  useSearchArrayParam,
  useSearchParam,
} from "@/common/hooks/useSearchParam";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import FeedbackPromptStatisticsChart from "@/feature-legacy/components/FeedbackPromptStatisticsChart";
import FeedbackPromptStatisticsTable from "@/feature-legacy/components/FeedbackPromptStatisticsTable";
import SatisfactionFilter, {
  spanToScore,
} from "@/feature-legacy/components/SatisfactionFilter";
import { StarsFunnelFilter } from "@/feature-legacy/components/StarsFunnelFilter";
import { useFeatureContext } from "@/feature-legacy/contexts/featureContext";
import FeedbackEmptyStateIllustration from "@/feedback/components/FeedbackEmptyStateIllustration";
import FeedbackTable from "@/feedback/components/FeedbackTable";
import feedbackQueryKeys from "@/feedback/data/feedbackQueryKeys";
import FeedbackPromptingInstalledWrapper from "@/global-settings/components/FeedbackPromptingInstalledWrapper";

export default function FeatureFeedback() {
  const { featureId } = useParams();
  const { envId, appId } = useCurrentEnv();
  const { relevantMetrics, feature } = useFeatureContext();
  const [subsegment] = useSubsegmentParam();
  const [satisfaction, setSatisfaction] =
    useSearchParam<SatisfactionSpan>("satisfaction");

  const defaultFunnelSteps = useMemo(
    () => FunnelStepList.map((step) => step),
    [],
  );

  const [funnelSteps, setFunnelSteps] = useSearchArrayParam<FunnelStep>(
    "funnelStep",
    {
      fallback: defaultFunnelSteps,
    },
  );

  const tableConfiguration = useLocalStorageTableConfiguration(
    "FeatureFeedback",
    {
      defaultColumns: [],
      defaultSort: {
        id: "timestamp",
        desc: true,
      },
    },
  );

  const segmentId = subsegment[0] || feature?.segment?.id;

  const table = useDataTable<
    FeedbackDTO,
    Omit<FeedbackQueryType, "pageIndex" | "pageSize" | "sortOrder" | "envId">
  >({
    apiCacheKey: feedbackQueryKeys.list(appId, envId),
    apiHandler: (queryParams) => () => {
      return api
        .get<"/apps/:appId/feedbacks">(`/apps/${appId}/feedbacks`, {
          params: { ...queryParams, envId: envId! },
        })
        .then((res) => res.data);
    },
    apiOptions: {
      enabled: !!appId && !!envId,
    },
    defaultQueryParams: {
      featureId,
      sortBy: "timestamp",
      funnelSteps: funnelSteps,
      satisfaction: spanToScore(satisfaction),
      subsegment: feature?.segment?.id || undefined,
    },
  });

  const fetchData = table.fetchData;
  useEffect(() => {
    fetchData({
      funnelSteps,
      pageIndex: 0,
    });

    segmentAnalytics.track("Feature Feedbacks Filter Updated", {
      type: "funnelSteps",
      value: funnelSteps,
    });
  }, [funnelSteps, fetchData]);

  useEffect(() => {
    fetchData({
      satisfaction: spanToScore(satisfaction),
      pageIndex: 0,
    });

    segmentAnalytics.track("Feature Feedbacks Filter Updated", {
      type: "satisfaction",
      value: satisfaction,
    });
  }, [satisfaction, fetchData]);

  useEffect(() => {
    fetchData({
      subsegment: segmentId,
      pageIndex: 0,
    });
  }, [fetchData, segmentId]);

  useEffect(() => {
    segmentAnalytics.page("Feature Feedback");
  }, []);

  return (
    <>
      <SidebarLayout flexGrow={1} sidebarContent={<Sidebar />}>
        <Flex direction="column" width="100%">
          <Flex justify={"space-between"} px={6} py={3}>
            <Flex align="center" gap={4}>
              <Button as={Link} size="sm" to="new" variant="primary">
                Add feedback
              </Button>
              <StarsFunnelFilter
                disabledSteps={FunnelStepList.filter(
                  (step) => !relevantMetrics.includes(step),
                )}
                value={funnelSteps}
                onChange={(newSteps) => {
                  setFunnelSteps(newSteps);
                }}
              />
              <SatisfactionFilter
                size="sm"
                value={satisfaction}
                onChange={setSatisfaction}
              />
              <AnimatedSpinner show={table.isLoading} size="sm" />
            </Flex>
            <TablePagination
              canPaginate={table.canPaginate}
              label="Entries"
              pageCount={table.pageCount}
              pageIndex={table.data?.pageIndex}
              pageSize={table.data?.pageSize}
              paginateActions={table.paginateActions}
              totalCount={table.data?.totalCount}
            />
          </Flex>
          <Divider />
          {table.isLoading ? null : table.data?.data.length === 0 ? (
            <EmptyState description="No feedback entries to show" flexGrow={1}>
              <FeedbackEmptyStateIllustration />
            </EmptyState>
          ) : (
            <FeedbackTable
              columnOrder={[
                "score",
                "question",
                "comment",
                "userName",
                "companyName",
                "companyFunnelStep",
                "source",
                "timestamp",
                "actions",
              ]}
              columnStates={tableConfiguration.columns}
              createEditURL={createEditURL}
              data={table.data?.data ?? []}
              fetchData={table.fetchData}
              isFetching={table.isFetching}
              pageCount={table.pageCount}
              setCanPaginate={table.setCanPaginate}
              setColumnStates={tableConfiguration.setColumns}
              setPaginateActions={table.setPaginateActions}
              setSortBy={tableConfiguration.setSort}
              sortBy={tableConfiguration.sort}
              viewName="FeatureFeedback"
            />
          )}
        </Flex>
      </SidebarLayout>
      <Outlet />
    </>
  );
}

const STATS_START = dayjs.utc().subtract(6, "days").startOf("day").toDate();
const STATS_END = dayjs.utc().add(1, "day").startOf("day").toDate();

function Sidebar() {
  const { featureId } = useParams();
  const { hasCopied, onCopy } = useClipboard(featureId!);
  const featureIdInputBg = useColorModeValue("gray.50", "gray.800");

  const promptStats = useFeedbackPromptStatistics(
    featureId,
    STATS_START,
    STATS_END,
  );

  return (
    <SidebarContainer>
      <SidebarSection>
        <Flex align="center" direction="row">
          <SectionHeading flexGrow={1}>Feedback</SectionHeading>
          <IconButton
            aria-label="Settings"
            as={RouterLink}
            color="dimmed"
            icon={<RiSettings3Line size={16} />}
            mr={-3}
            size="2xs"
            to="../settings#feedback"
            variant="ghost"
            isRound
          />
        </Flex>
        <FeedbackPromptingInstalledWrapper
          showInstalled={false}
          showLastActivity={false}
          showTitle={false}
        >
          <Box display="grid" gap={4}>
            <FeedbackPromptStatisticsTable {...promptStats} />
            <FeedbackPromptStatisticsChart {...promptStats} />
          </Box>
        </FeedbackPromptingInstalledWrapper>
      </SidebarSection>

      <Divider />

      <SidebarSection>
        <SectionHeading>Without Automated Surveys</SectionHeading>
        <SectionDescription>
          Provide this feature ID to the Bucket SDK when submitting user
          feedback.
        </SectionDescription>
        <HStack w="100%">
          <Input
            bg={featureIdInputBg}
            borderRadius={"md"}
            overflow="hidden"
            size={"sm"}
            textOverflow="ellipsis"
            value={featureId}
            variant="outline"
            whiteSpace="nowrap"
            isReadOnly
            onClick={(e) => {
              e.currentTarget.setSelectionRange(
                0,
                e.currentTarget.value.length,
              );
            }}
          />
          <Tooltip
            closeOnClick={false}
            label={hasCopied ? "Copied!" : "Copy to clipboard"}
          >
            <IconButton
              aria-label="Copy to clipboard"
              borderRadius={"md"}
              icon={<RiFileCopyLine />}
              p={0}
              size={"sm"}
              variant="outline"
              onClick={onCopy}
            />
          </Tooltip>
        </HStack>
      </SidebarSection>
    </SidebarContainer>
  );
}

function createEditURL(feedback: FeedbackDTO): To {
  return `edit/${feedback.id}`;
}
