import { useState } from "react";
import { useDragLayer, useDrop } from "react-dnd";
import { RiIndentDecrease } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { Box, Flex, Portal, SlideFade, Text, useToast } from "@chakra-ui/react";

import { FeatureName } from "@bucketco/shared/featureAPI";

import { ErrorResponseDisplay } from "@/common/components/ErrorResponseDisplay";
import { DragType } from "@/common/types/dragDropTypes";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { useFeatureUpdateMutation } from "@/feature-legacy/data/useFeatureUpdateMutation";

export function OrphanFeatureDropTarget() {
  const toast = useToast();
  const { companyId } = useParams();

  const featureUpdateMutation = useFeatureUpdateMutation(companyId!);

  const [isDropping, setIsDropping] = useState(false);

  const { item, itemType, isDragging } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    isDragging: monitor.isDragging(),
  }));

  const shouldShow =
    isDropping ||
    (isDragging &&
      itemType === DragType.FeatureDisplay &&
      item.parentFeatureId !== null);

  const [{ isOver }, drop] = useDrop<FeatureName, unknown, { isOver: boolean }>(
    {
      accept: DragType.FeatureDisplay,
      collect(monitor) {
        return {
          isOver: monitor.isOver(),
        };
      },
      drop(feature) {
        setIsDropping(true);
        featureUpdateMutation.mutate(
          {
            id: feature.id,
            parentFeatureId: null,
          },
          {
            onSuccess(modifiedFeature) {
              segmentAnalytics.track("Feature Options Updated", {
                section: "Feature Orphan Drop Target",
                feature_parent_modified:
                  feature.parentFeatureId !== modifiedFeature.parentFeatureId,
              });
              toast({
                title: `Parent removed from "${feature.name}"`,
                status: "success",
                duration: 1500,
              });
            },
            onError(error) {
              toast({
                title: (
                  <Box>
                    <Text>{`Failed moving "${feature.name}".`}</Text>
                    <ErrorResponseDisplay response={error.response?.data} />
                  </Box>
                ),
                status: "error",
                duration: 5000,
              });
            },
            onSettled() {
              setIsDropping(false);
            },
          },
        );

        return { dropTarget: "unset_drop_area" };
      },
    },
  );

  return (
    <Portal>
      <SlideFade in={shouldShow} unmountOnExit={true}>
        <Flex
          ref={drop}
          alignItems="center"
          bgColor={isOver ? "appBorder" : "appBackground"}
          borderWidth="0.5px"
          bottom={6}
          boxShadow="md"
          gap={2}
          left="50%"
          position="fixed"
          px={6}
          py={4}
          rounded="full"
        >
          <RiIndentDecrease size={16} />
          Drop here to move the feature to top level
        </Flex>
      </SlideFade>
    </Portal>
  );
}
