import { Flex, Spinner, Text } from "@chakra-ui/react";

import {
  operatorDisplayName,
  relevantOperators,
} from "@bucketco/shared/attributeFilter";
import { SegmentFilter } from "@bucketco/shared/filter";
import { SegmentDTOWithTotalCompanies } from "@bucketco/shared/segmentAPI";
import { getFraction } from "@bucketco/shared/utils/getFraction";

import { SimplePieChart } from "@/common/charts/components/SimplePieChart";
import { TextWordBreak } from "@/common/components/TextWordBreak";
import { useSegments } from "@/company/data/useSegments";
import FormSegmentPicker from "@/feature-legacy/components/form/FormSegmentPicker";
import { ManagedFormControl } from "../form/ManagedFormControl";
import RuleOperator from "../Rule/RuleOperator";

type SegmentFilterItemProps = {
  filter: SegmentFilter;
  showIcon?: boolean;
  isLoading?: boolean;
  compact?: boolean;
};

export function SegmentFilterItem({
  filter,
  showIcon,
  isLoading,
  compact,
}: SegmentFilterItemProps) {
  const { data: segments } = useSegments({ includeDeleted: true });

  const segment = segments?.find(({ id }) => id === filter.segmentId);
  return (
    <Flex align="center" display="inline-flex" whiteSpace="normal">
      {isLoading ? (
        <Flex align="center" as="span" color="dimmed" columnGap={2}>
          <Spinner size="xs" />
          <TextWordBreak as="span">Loading filter...</TextWordBreak>
        </Flex>
      ) : (
        <Flex align="center" as="span" columnGap={2} wrap="wrap">
          {!compact ? (
            <Text as="span" color="dimmed">
              {operatorDisplayName[filter.operator]}
            </Text>
          ) : compact && filter.operator === "NOT_SEGMENT" ? (
            <Text as="span" color="dimmed">
              not in
            </Text>
          ) : null}
          {segment && showIcon && (
            <SimplePieChart
              as="span"
              value={getFraction(segment.segmentCount, segment.allCount) * 100}
            />
          )}
          {segment && <Text as="span">{segment.name}</Text>}
          {segment?.deletedAt !== null && (
            <Text color="dimmed">(archived)</Text>
          )}
        </Flex>
      )}
    </Flex>
  );
}

type SegmentFilterFieldsProps = {
  segmentItemFilter?: (segment: SegmentDTOWithTotalCompanies) => boolean;
};

export function SegmentFilterFields({
  segmentItemFilter,
}: SegmentFilterFieldsProps) {
  const operators = relevantOperators(["segment"]);

  return (
    <>
      <ManagedFormControl
        name="operator"
        render={({ field }) => (
          <RuleOperator {...field} operators={operators} />
        )}
      />
      <FormSegmentPicker
        menuDescription="Segments"
        multiselect={false}
        name="segmentId"
        segmentItemFilter={segmentItemFilter}
        showPieChart={true}
        w={"100%"}
      />
    </>
  );
}
