import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { RiArrowDownSLine } from "react-icons/ri";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useColorModeValue,
  useStyleConfig,
  VisuallyHidden,
} from "@chakra-ui/react";

import { FeatureViewDTO } from "@bucketco/shared/featureViewAPI";
import { GlobalSettingsUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import FeatureViewsIcon from "@/common/assets/feature-views-icon.svg?react";
import VizMatrixIcon from "@/common/assets/features-viz-matrix-icon.svg?react";
import VizTableIcon from "@/common/assets/features-viz-table-icon.svg?react";
import HeaderLayout, {
  HeaderLayoutBreadcrumb,
  HeaderLayoutHeading,
} from "@/common/components/HeaderLayout";
import MenuDescription from "@/common/components/MenuDescription";
import { NavLink } from "@/common/components/NavLink";
import SwitchButtonGroup, {
  ButtonDefinition,
} from "@/common/components/SwitchButtonGroup";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import FeaturesAudit from "@/feature-legacy/components/FeaturesAudit";
import FeaturesTable from "@/feature-legacy/components/FeaturesTable";
import { SlackWeeklyDigestStatus } from "@/feature-legacy/components/SlackWeeklyDigestStatus";
import { SubsegmentPicker } from "@/feature-legacy/components/SubsegmentPicker";
import { TableDisplayModePicker } from "@/feature-legacy/components/TableDisplayModePicker";
import { useFeatureViews } from "@/feature-legacy/data/useFeatureViews";
import { useFeatureViewParam } from "@/feature-legacy/hooks/useFeatureViewParam";

const vizOptions = ["table", "matrix"] as const;
type Viz = (typeof vizOptions)[number];

function VisualizationPicker() {
  const [vizType, setVizType] = useSearchParam<Viz>("viz", {
    fallback: "table",
    allowlist: vizOptions,
    storage: localStorage,
  });

  const iconSize = 16;
  const buttons: Array<ButtonDefinition<Viz>> = [
    {
      id: "table" as const,
      label: (
        <HStack spacing={2}>
          <VizTableIcon height={iconSize} width={iconSize} />
          <Text>Table</Text>
        </HStack>
      ),
    },
    {
      id: "matrix" as const,
      label: (
        <HStack spacing={2}>
          <VizMatrixIcon height={iconSize} width={iconSize} />
          <Text>Matrix</Text>
        </HStack>
      ),
    },
  ];

  return (
    <SwitchButtonGroup
      activeId={vizType}
      buttons={buttons}
      label="Visualization"
      size="sm"
      onChange={setVizType}
    />
  );
}

function FeaturesViz({
  vizType,
  viewId,
  view,
}: {
  vizType: Viz | null;
  viewId: string | null;
  view: FeatureViewDTO | null;
}) {
  return vizType === "table" ? (
    <FeaturesTable viewId={viewId} />
  ) : vizType === "matrix" ? (
    <FeaturesAudit view={view} viewId={viewId} />
  ) : null;
}

export default function Features() {
  const { currentEnv } = useAuthContext();
  const errorToast = useErrorToast();
  const [vizType] = useSearchParam<Viz>("viz", {
    fallback: "table",
    allowlist: vizOptions,
    storage: localStorage,
  });
  const [viewId, setViewId] = useFeatureViewParam();

  useEffect(() => {
    segmentAnalytics.page("Features", {
      viz: vizType,
    });
  }, [vizType]);

  const { data: featureViews, isSuccess } = useFeatureViews();
  const allFeaturesView = featureViews?.find((v) => v.isAllFeatureView);
  const currentView = viewId
    ? featureViews?.find((v) => v.id === viewId)
    : allFeaturesView;

  useEffect(() => {
    if (viewId && isSuccess && !currentView) {
      errorToast({
        description: `Could not find the feature view, redirecting to All features`,
        duration: 4000,
      });

      if (allFeaturesView) {
        setViewId(allFeaturesView.id);
      }
    }
  }, [viewId, isSuccess, currentView, errorToast, setViewId, allFeaturesView]);

  if (!currentView) return;

  return (
    <>
      <VisuallyHidden>
        <h1>{currentView.name}</h1>
      </VisuallyHidden>
      <HeaderLayout
        actions={
          <HStack spacing={4}>
            <SubsegmentPicker
              menuDescription={
                vizType === "matrix"
                  ? "Compare company usage across the following subsegments:"
                  : "Only include companies that belong to both the target segment and the following subsegment:"
              }
              multiselect={vizType === "matrix"}
              placeholder={
                vizType === "matrix"
                  ? "Compare subsegments"
                  : "Apply subsegment"
              }
            />
            {currentEnv?.isProduction &&
              (currentView.isAllFeatureView ? (
                <SlackWeeklyDigestStatus
                  weeklyDigestEntity={{
                    slackChannelId: currentEnv?.slackChannelId,
                    slackChannelName: currentEnv?.slackChannelName,
                    slackWeeklyDigest: currentEnv?.slackWeeklyAllFeaturesDigest,
                  }}
                />
              ) : (
                <SlackWeeklyDigestStatus weeklyDigestEntity={currentView} />
              ))}
            {vizType === "table" ? <TableDisplayModePicker /> : null}
            <VisualizationPicker />
          </HStack>
        }
        isFixedViewport={vizType === "matrix"}
        title={
          <Box ml={-3}>
            <FeatureViewPicker currentView={currentView} />
          </Box>
        }
      >
        <Helmet>
          <title>{`Features › ${currentView.name}`}</title>
        </Helmet>
        <FeaturesViz
          view={currentView}
          viewId={currentView.id}
          vizType={vizType}
        />
        <Outlet />
      </HeaderLayout>
    </>
  );
}

function FeatureViewPicker({ currentView }: { currentView: FeatureViewDTO }) {
  const { currentEnv } = useAuthContext();

  const { data: featureViews = [], isLoading: isLoadingFeatureViews } =
    useFeatureViews();
  const featuresMenu = featureViews
    .sort((a, b) =>
      a.isAllFeatureView
        ? -1
        : b.isAllFeatureView
        ? 1
        : a.name.localeCompare(b.name),
    )
    .map((view) => ({
      id: view.id,
      to: `?view=${view.id}`,
      label: view.name,
    }));
  const currentItemColor = useColorModeValue("brand.500", "brand.300");

  return (
    <Menu>
      <MenuButton
        aria-label="Switch feature view"
        as={Button}
        display="flex"
        pl={3}
        pr={2}
        rightIcon={
          <Box fontSize="lg" ml={-0.5}>
            <RiArrowDownSLine />
          </Box>
        }
        size="md"
        variant="outlineOnHover"
      >
        <HStack spacing={1.5}>
          <HeaderLayoutBreadcrumb>View:</HeaderLayoutBreadcrumb>
          <FeatureViewsIcon height="16px" width="16px" />
          <HeaderLayoutHeading>{currentView.name}</HeaderLayoutHeading>
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuDescription maxW="xs">
          Use feature views to group features, enable slack reporting and save
          column configuration.
        </MenuDescription>
        <MenuDivider my={0} />
        {isLoadingFeatureViews ? (
          <Box pb={1} pt={2} px={3}>
            <Spinner size="sm" />
          </Box>
        ) : (
          featuresMenu.map((i) => (
            <MenuItem
              key={i.id}
              as={NavLink}
              icon={<FeatureViewsIcon height="16px" width="16px" />}
              matchSearchParams={["view"]}
              sx={{
                "&[aria-current='page']": {
                  color: currentItemColor,
                },
              }}
              to={i.to}
            >
              {i.label}
            </MenuItem>
          ))
        )}
        <MenuDivider my={0} />

        <MenuItem
          as={Link}
          display="block"
          m="2"
          sx={useStyleConfig("Button", {
            variant: "outline",
            size: "xs",
          })}
          to={GlobalSettingsUrl(currentEnv!, "app-feature-views")}
          width="max-content"
        >
          Manage views
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
