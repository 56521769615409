import { useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { RiArrowDownSLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useColorModeValue,
  useStyleConfig,
  VisuallyHidden,
} from "@chakra-ui/react";
import isEqual from "lodash/isEqual";

import {
  AttributeType,
  CompaniesQueryType,
  CompanyListItem,
} from "@bucketco/shared/companyAPI";
import { SEARCH_DEBOUNCE_MS } from "@bucketco/shared/constants";
import { SegmentDTOWithTotalCompanies } from "@bucketco/shared/segmentAPI";
import { ColumnState } from "@bucketco/shared/types/columns";
import { GlobalSettingsUrl, SegmentUrl } from "@bucketco/shared/urls";
import { getFraction } from "@bucketco/shared/utils/getFraction";

import { useAuthContext } from "@/auth/contexts/authContext";
import { SimplePieChart } from "@/common/charts/components/SimplePieChart";
import HeaderLayout, {
  HeaderLayoutBreadcrumb,
  HeaderLayoutHeading,
} from "@/common/components/HeaderLayout";
import MenuDescription from "@/common/components/MenuDescription";
import MotionBox from "@/common/components/MotionBox";
import { NavLink } from "@/common/components/NavLink";
import TablePagination from "@/common/components/TablePagination";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import useDataTable from "@/common/hooks/useDataTable";
import { useDebounce } from "@/common/hooks/useDebounce";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import api from "@/common/utils/api";
import pluralize from "@/common/utils/pluralize";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { CompaniesTable } from "@/company/components/CompaniesTable";
import SegmentFilters from "@/company/components/SegmentFilters";
import { useSegments } from "@/company/data/useSegments";
import { useFilterParam } from "@/company/hooks/useFilterParam";
import useSegmentColumnStates from "@/company/hooks/useSegmentColumnStates";
import { useSegmentParam } from "@/company/hooks/useSegmentParam";

function getUniqueFeatureIds(states: ColumnState[]) {
  return states.reduce<string[]>((acc, { id }) => {
    const [_, featureId] = id.split(".");
    if (featureId && !acc.includes(featureId)) {
      return [...acc, featureId];
    }
    return acc;
  }, [] as string[]);
}

export function Page({ segment }: { segment: SegmentDTOWithTotalCompanies }) {
  const { envId, appId } = useCurrentEnv();
  const [searchQuery, setSearchQuery] = useSearchParam("q", { fallback: "" });
  const [currentCompanyFilter, setCurrentCompanyFilter] = useFilterParam(
    segment?.companyFilter,
  );

  const {
    columnStates,
    setColumnStates,
    columnSort,
    setColumnSort,
    isColumnStatesDirty,
    resetColumns,
  } = useSegmentColumnStates(segment);

  const isEdited = useMemo(() => {
    return (
      !isEqual(segment.companyFilter, currentCompanyFilter) ||
      isColumnStatesDirty
    );
  }, [currentCompanyFilter, isColumnStatesDirty, segment.companyFilter]);

  const {
    data,
    isFetching,
    isLoading,
    pageCount,
    fetchData,
    canPaginate,
    setCanPaginate,
    paginateActions,
    setPaginateActions,
  } = useDataTable<
    CompanyListItem,
    Pick<
      CompaniesQueryType,
      | "sortBy"
      | "segment"
      | "companyFilter"
      | "includeDetailsForFeatureIds"
      | "idNameFilter"
    >,
    { totalUserCount: number; attributeTypes: Record<string, AttributeType> }
  >({
    apiCacheKey: ["environment", envId, "segment", segment.id],
    apiHandler: (params) => async () => {
      const res = await api.get<`/apps/:appId/companies`>(
        `/apps/${appId}/companies`,
        {
          params: { ...params, envId: envId! },
        },
      );
      return res.data;
    },
    apiOptions: {
      enabled: !!appId && !!envId,
    },
    defaultQueryParams: {
      segment: segment.id === "active" ? "active" : undefined,
      companyFilter: currentCompanyFilter,
      sortBy: "lastSeen",
      includeDetailsForFeatureIds: getUniqueFeatureIds(columnStates ?? []),
      idNameFilter: searchQuery,
    },
  });

  useEffect(() => {
    fetchData({ companyFilter: currentCompanyFilter, segment: undefined });
  }, [currentCompanyFilter, fetchData]);

  useDebounce(
    searchQuery,
    SEARCH_DEBOUNCE_MS,
    useCallback(
      (query: string | undefined) => fetchData({ idNameFilter: query }),
      [fetchData],
    ),
  );

  useEffect(() => {
    fetchData({
      includeDetailsForFeatureIds: getUniqueFeatureIds(columnStates ?? []),
    });
  }, [columnStates, fetchData]);

  useEffect(() => {
    segmentAnalytics.page("Companies", {
      segment: segment.system ? segment.name.toLowerCase() : "custom",
    });
  }, [segment.system, segment.name]);

  const pageTitle = segment?.name;

  return (
    <>
      <VisuallyHidden>
        <h1>{segment?.name}</h1>
      </VisuallyHidden>
      <HeaderLayout
        actions={
          <HStack spacing="4">
            <TablePagination
              canPaginate={canPaginate}
              label="Companies"
              pageCount={pageCount}
              pageIndex={data?.pageIndex}
              pageSize={data?.pageSize}
              paginateActions={paginateActions}
              totalCount={data?.totalCount}
            />
          </HStack>
        }
        title={
          <>
            <Box ml={-3}>
              <SegmentPicker currentSegment={segment} />
            </Box>
            {isEdited && <Badge mr={2}>Edited</Badge>}
            {isLoading ? (
              <MotionBox
                key="spinner"
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                transition={{ type: "ease", duration: "0.1" }}
              >
                <Spinner size="xs" />
              </MotionBox>
            ) : (
              <MotionBox
                key="stats"
                animate={{ opacity: 1 }}
                display="flex"
                gap={4}
                initial={{ opacity: 0 }}
                transition={{ type: "ease", duration: "0.1" }}
              >
                <Text fontSize="sm" fontWeight="normal" variant="dimmed">
                  {data?.totalCount || 0}{" "}
                  {` compan${data?.totalCount === 1 ? "y" : "ies"}`}
                </Text>
                <Text fontSize="sm" fontWeight="normal" variant="dimmed">
                  {data?.metadata.totalUserCount || 0}{" "}
                  {pluralize("user", data?.metadata.totalUserCount || 0)}
                </Text>
              </MotionBox>
            )}
          </>
        }
      >
        <>
          <Helmet>
            <title>Companies › {pageTitle}</title>
          </Helmet>
          <SegmentFilters
            key={segment.id}
            columnSort={columnSort}
            columnStates={columnStates}
            filter={currentCompanyFilter}
            isColumnStatesDirty={isColumnStatesDirty}
            resetColumns={resetColumns}
            searchQuery={searchQuery}
            segment={segment}
            setColumnSort={setColumnSort}
            setColumnStates={setColumnStates}
            setSearchQuery={setSearchQuery}
            onFilterChange={setCurrentCompanyFilter}
          />
          <CompaniesTable
            columnSort={columnSort}
            columnStates={columnStates}
            data={data}
            fetchData={fetchData}
            isFetching={isFetching}
            isLoading={isLoading}
            pageCount={pageCount}
            segment={segment}
            setCanPaginate={setCanPaginate}
            setColumnSort={setColumnSort}
            setColumnStates={setColumnStates}
            setPaginateActions={setPaginateActions}
          />
        </>
      </HeaderLayout>
    </>
  );
}

export default function Companies() {
  const [segmentId, setSegmentId] = useSegmentParam();
  const { data: segments, isSuccess } = useSegments();

  const allSegment = segments?.find((s) => s.isAllSegment);
  const currentSegment =
    segmentId === "all" || segmentId === null
      ? allSegment
      : segments?.find((s) => s.id === segmentId);

  // segments
  const errorToast = useErrorToast();
  useEffect(() => {
    if (segmentId && isSuccess && !currentSegment) {
      errorToast({
        description: `Could not find the segment, redirecting to All`,
        duration: 4000,
      });

      if (allSegment) {
        setSegmentId(allSegment.id);
      }
    }
  }, [
    segmentId,
    isSuccess,
    currentSegment,
    errorToast,
    allSegment,
    setSegmentId,
  ]);

  if (!currentSegment) return null;

  return <Page key={currentSegment.id} segment={currentSegment} />;
}

function SegmentPicker({
  currentSegment,
}: {
  currentSegment: SegmentDTOWithTotalCompanies;
}) {
  const { currentEnv } = useAuthContext();

  const { data: segments = [], isLoading: isLoadingSegments } = useSegments();

  const segmentsMenu = segments
    .sort((a, b) =>
      a.isAllSegment ? -1 : b.isAllSegment ? 1 : a.name.localeCompare(b.name),
    )
    .map((segment) => {
      return {
        id: segment.id,
        to: SegmentUrl(currentEnv!, segment.id),
        label: segment.name,
        count: segment.segmentCount,
        allCount: segment.allCount,
      };
    });

  const currentItemColor = useColorModeValue("brand.500", "brand.300");

  return (
    <Menu>
      <MenuButton
        aria-label="Switch segment"
        as={Button}
        display="flex"
        pl={3}
        pr={2}
        rightIcon={
          <Box fontSize="lg" ml={-0.5}>
            <RiArrowDownSLine />
          </Box>
        }
        size="md"
        variant="outlineOnHover"
      >
        <HStack spacing={1.5}>
          <HeaderLayoutBreadcrumb>Segment:</HeaderLayoutBreadcrumb>
          <SimplePieChart
            size="16px"
            value={
              getFraction(
                currentSegment.segmentCount,
                currentSegment.allCount,
              ) * 100
            }
          />
          <HeaderLayoutHeading>{currentSegment.name}</HeaderLayoutHeading>
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuDescription maxW="xs">
          Segments are filtered lists of companies used for features. Customized
          columns are saved too.
        </MenuDescription>
        <MenuDivider my={0} />
        {isLoadingSegments ? (
          <Box pb={1} pt={2} px={3}>
            <Spinner size="sm" />
          </Box>
        ) : (
          segmentsMenu.map((i) => (
            <MenuItem
              key={i.id}
              as={NavLink}
              icon={
                <SimplePieChart
                  size="16px"
                  value={getFraction(i.count, i.allCount) * 100}
                />
              }
              matchSearchParams={["segment"]}
              sx={{
                "&[aria-current='page']": {
                  color: currentItemColor,
                },
              }}
              to={i.to}
            >
              <HStack>
                <Text flexGrow={1}>{i.label}</Text>
                <Text color="dimmed">{i.count}</Text>
              </HStack>
            </MenuItem>
          ))
        )}
        <MenuDivider my={0} />

        <MenuItem
          as={Link}
          display="block"
          m="2"
          sx={useStyleConfig("Button", {
            variant: "outline",
            size: "xs",
          })}
          to={GlobalSettingsUrl(currentEnv!, "app-segments")}
          width="max-content"
        >
          Manage segments
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
