import { ForwardedRef, forwardRef } from "react";

import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import SimpleSelect, {
  SimpleSelectProps,
} from "@/common/components/SimpleSelect";
import {
  FormElementBaseProps,
  useManagedFormControlProps,
} from "@/common/hooks/useManagedFormControlProps";
import { mergeFunctions } from "@/common/utils/mergeFunctions";
import { mergeRefs } from "@/common/utils/mergeRefs";

export type FormSelectProps<T extends string = string> = FormElementBaseProps &
  SimpleSelectProps<T>;

function FormSelectInner<T extends string = string>(
  { _control, onChange, onBlur, ...rest }: FormSelectProps<T>,
  ref: ForwardedRef<HTMLSelectElement>,
) {
  const [managedProps, selectProps] = useManagedFormControlProps(rest);

  return (
    <ManagedFormControl
      {..._control}
      {...managedProps}
      render={({ field }) => (
        <SimpleSelect
          {...field}
          // Cast because SimpleSelect is a union type
          {...(selectProps as SimpleSelectProps<T>)}
          ref={mergeRefs(field.ref, ref)}
          onBlur={mergeFunctions(field.onBlur, onBlur)}
          onChange={mergeFunctions(field.onChange, onChange) as any}
        />
      )}
    />
  );
}

const FormSelect = forwardRef(FormSelectInner) as <T extends string = string>(
  props: FormSelectProps<T> & { ref?: ForwardedRef<HTMLSelectElement> },
) => ReturnType<typeof FormSelectInner>;

export default FormSelect;
