import { useCallback, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Spinner,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

import { FeatureName } from "@bucketco/shared/featureAPI";
import {
  GoalConfiguration,
  GoalDetailsDTO,
  GoalDryRunResult,
  GoalFeatureMetric,
  GoalFeatureMetrics,
} from "@bucketco/shared/goalAPI";
import { ReleasePostArgs } from "@bucketco/shared/releaseAPI";
import { SegmentDTO } from "@bucketco/shared/segmentAPI";

import TimeSeriesIcon from "@/common/assets/widget-timeseries.svg?react";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import dayjs from "@/common/utils/dayjs";
import { GoalChart } from "@/release/components/GoalChart";
import { LegacyGoalForm } from "@/release/components/LegacyGoalForm";
import { LegacyGoalProgress } from "@/release/components/LegacyGoalProgress";
import { goalQueryKeys } from "@/release/data/goalQueryKeys";
import { useGoalPreviewData } from "@/release/data/useGoalsData";
import { useReleaseEditing } from "@/release/hooks/useReleaseEditing";
import { suggestedThreshold } from "@/release/utils/goal";

type Props = Partial<
  Pick<
    GoalDetailsDTO,
    | "status"
    | "progress"
    | "currentValue"
    | "achievedAt"
    | "evaluationData"
    | "progressIndeterminate"
  >
> & {
  index: number;
  startDate: Date;
  endDate: Date;
  releasedAt?: string | null;
  configuration: Omit<GoalConfiguration, "type" | "version">;
  featureNames: FeatureName[];
  segments: SegmentDTO[];
  onRemove?: () => void;
};

export const LegacyGoal = ({
  index,
  startDate,
  endDate,
  releasedAt,
  configuration,
  status,
  currentValue,
  progress,
  achievedAt,
  evaluationData,
  featureNames,
  segments,
  progressIndeterminate,
  onRemove,
}: Props) => {
  const { appId } = useCurrentEnv();

  const [editing] = useReleaseEditing();
  const queryClient = useQueryClient();
  const { getFieldState, setValue } = useFormContext<ReleasePostArgs>();
  const configName = `goals.${index}.configuration` as const;
  const isValid = !getFieldState(configName).invalid;
  const bg = useColorModeValue("white", "gray.800");
  const placeholderColor = useColorModeValue("gray.200", "gray.600");
  const [suggestNewThreshold, setSuggestNewThreshold] = useState(false);

  const { featureId, metric, subsegmentId, threshold } = useWatch({
    name: configName,
    defaultValue: configuration,
  });
  const previewArgs = useMemo(() => {
    return {
      evaluationPeriod: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
      configuration: {
        featureId,
        metric,
        subsegmentId,
        threshold: 0, // doesn't matter for preview and would cause a loop
      },
    };
  }, [endDate, featureId, metric, startDate, subsegmentId]);

  const handlePreviewChange = useCallback(
    ({ currentValue }: GoalDryRunResult) => {
      if (!suggestNewThreshold) return;
      setSuggestNewThreshold(false);
      setValue(
        `${configName}.threshold`,
        suggestedThreshold(previewArgs.configuration.metric, currentValue ?? 0),
      );
    },
    [
      configName,
      suggestNewThreshold,
      previewArgs.configuration.metric,
      setValue,
    ],
  );

  const { data: previewData, isFetching: isPreviewLoading } =
    useGoalPreviewData(previewArgs, editing, handlePreviewChange);

  const isPercentage = editing
    ? GoalFeatureMetrics[metric as GoalFeatureMetric]?.type === "percentage"
    : GoalFeatureMetrics[configuration.metric]?.type === "percentage";

  const chartConfig = useMemo(() => {
    if (editing) {
      return {
        startDate,
        endDate,
        data: previewData?.evaluationData ?? [],
        threshold: threshold || 0,
        metric: metric || "none",
        releasedAt: releasedAt ? new Date(releasedAt) : undefined,
        achievedAt: previewData?.achievedAt
          ? new Date(previewData.achievedAt)
          : undefined,
      };
    } else {
      return {
        startDate,
        endDate,
        data: evaluationData ?? [],
        threshold: configuration.threshold,
        metric: configuration.metric,
        releasedAt: releasedAt ? new Date(releasedAt) : undefined,
        achievedAt: achievedAt ? new Date(achievedAt) : undefined,
      };
    }
  }, [
    editing,
    startDate,
    endDate,
    previewData?.evaluationData,
    previewData?.achievedAt,
    threshold,
    metric,
    releasedAt,
    evaluationData,
    configuration.threshold,
    configuration.metric,
    achievedAt,
  ]);

  const featureName = useMemo(() => {
    if (!configuration.featureId) return undefined;
    return featureNames?.find((f) => f.id === configuration.featureId);
  }, [configuration.featureId, featureNames]);

  const subsegment = useMemo(() => {
    if (!configuration.subsegmentId) return undefined;
    return segments?.find((s) => s.id === configuration.subsegmentId);
  }, [configuration.subsegmentId, segments]);

  const releasedValue = useMemo(() => {
    return evaluationData?.find((d) =>
      dayjs.unix(d.epoch).isSameOrAfter(releasedAt),
    )?.value;
  }, [evaluationData, releasedAt]);

  const previewValue = useMemo(() => {
    if (previewData?.evaluationData) {
      const data = previewData.evaluationData;
      const result = data.findLast((d) => d.value !== null);

      return result?.value;
    }

    return null;
  }, [previewData]);

  const goalWidth = editing ? "min(320px, 35%)" : "min(220px, 25%)";

  return (
    <Flex
      bg={bg}
      borderColor={"appBorder"}
      borderRadius="md"
      borderWidth={1}
      boxShadow="sm"
      gap={4}
      p={6}
      position="relative"
    >
      <Box width={goalWidth}>
        {editing ? (
          <LegacyGoalForm
            index={index}
            isPercentage={isPercentage}
            previewValue={previewValue ?? 0}
            segments={segments}
            onChange={(key) => {
              if (key !== "threshold") {
                setSuggestNewThreshold(true);
              }
            }}
          />
        ) : (
          <LegacyGoalProgress
            currentValue={currentValue ?? 0}
            featureName={featureName}
            isPercentage={isPercentage}
            metric={configuration.metric}
            progress={progress ?? 0}
            progressIndeterminate={progressIndeterminate ?? false}
            releasedAt={releasedAt ? new Date(releasedAt) : undefined}
            releasedValue={releasedValue}
            status={status ?? "planned"}
            subsegment={subsegment}
            threshold={configuration.threshold}
          />
        )}
      </Box>
      <Flex align="center" width={`calc(100% - ${goalWidth})`}>
        {isValid ? (
          <GoalChart
            {...chartConfig}
            isPercentage={isPercentage}
            status={status ?? "planned"}
          />
        ) : (
          <VStack color={placeholderColor} spacing={8} width="full">
            <TimeSeriesIcon height={64} />
            <Text color="dimmed">
              Configuration is invalid, please check for errors
            </Text>
          </VStack>
        )}
      </Flex>
      {editing && (
        <HStack position="absolute" right={4} spacing={8} top={4}>
          {isPreviewLoading && <Spinner size="sm" />}
          <IconButton
            aria-label="Delete goal"
            icon={<RiDeleteBinLine />}
            size="sm"
            variant="outline"
            onClick={() => {
              queryClient.cancelQueries({
                queryKey: goalQueryKeys.dryRun(appId, previewArgs),
              });
              onRemove?.();
            }}
          />
        </HStack>
      )}
    </Flex>
  );
};
