import { ReactElement, ReactNode } from "react";
import {
  RiChat1Line,
  RiFlag2Line,
  RiMoonLine,
  RiSoundModuleLine,
  RiSunLine,
} from "react-icons/ri";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  FlexProps,
  HStack,
  IconButton,
  Link,
  LinkProps,
  Spacer,
  Text,
  Tooltip,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";

import { GlobalSettingsUrl } from "@bucketco/shared/urls";

import FeatureTargetingManager from "@/admin/components/FeatureTargetingManager";
import OrgSuspendedRibbon from "@/admin/components/OrgSuspendedRibbon";
import { AppEnvironmentPicker } from "@/app/components/AppEnvironmentPicker";
import { useAuthContext } from "@/auth/contexts/authContext";
import BucketLogo from "@/common/assets/bucket-logo-currentcolor.svg?react";
import FeatureViewsIcon from "@/common/assets/feature-views-icon.svg?react";
import PointerPressIcon from "@/common/assets/pointer-press.svg?react";
import ReleasesIcon from "@/common/assets/release-plane-deploy.svg?react";
import { NavLink } from "@/common/components/NavLink";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import { API_URL } from "@/common/utils/env";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { FeatureSegmentIcon } from "@/feature-legacy/components/FeatureSegment";
import { TrialStatus } from "@/global-settings/components/TrialStatus";

export default function AppSidebar({ ...props }: FlexProps) {
  const { user, currentOrg } = useAuthContext();
  const hasFeatureMergeFlag = useFeature("feature-revamp-2024").isEnabled;

  return (
    <Flex
      {...props}
      aria-label="Main"
      as="nav"
      direction="column"
      gap={2.5}
      px={3}
      py={2.5}
    >
      {user?.isAdmin && currentOrg?.suspendedAt !== null && (
        <OrgSuspendedRibbon />
      )}
      <AppEnvironmentPicker />
      {hasFeatureMergeFlag ? (
        <>
          <TrackFeature />
          <MainMenu />
        </>
      ) : (
        <>
          <TrackFeatureLegacy />
          <MainMenuLegacy />
        </>
      )}
      <Spacer />
      <TrialLink />
      <FooterButtons />
      <BucketBrand />
    </Flex>
  );
}

function TrackFeature() {
  const [searchParams] = useSearchParams();

  return (
    <Button
      as={RouterLink}
      bg={useColorModeValue("white", "gray.800")}
      borderColor={useColorModeValue("gray.200", "gray.700")}
      color={useColorModeValue("gray.600", "gray.400")}
      flexShrink={0}
      size="sm"
      to={{
        pathname: "features/new",
        search: searchParams.toString(),
      }}
      variant="outline"
    >
      New feature
    </Button>
  );
}

function TrackFeatureLegacy() {
  const [searchParams] = useSearchParams();

  return (
    <Button
      as={RouterLink}
      bg={useColorModeValue("white", "gray.800")}
      borderColor={useColorModeValue("gray.200", "gray.700")}
      color={useColorModeValue("gray.600", "gray.400")}
      flexShrink={0}
      size="sm"
      to={{
        pathname: "features/new",
        search: searchParams.toString(),
      }}
      variant="outline"
    >
      Track feature
    </Button>
  );
}

function MainMenu() {
  return (
    <Flex
      align="stretch"
      direction="column"
      fontSize="sm"
      gap={0.5}
      h="full"
      justifyContent="start"
      minH="276px"
    >
      <MenuHeading
        icon={<FeatureViewsIcon height={16} width={16} />}
        to="features"
      >
        Features
      </MenuHeading>

      <MenuHeading icon={<FeatureSegmentIcon size={16} />} to="companies">
        Companies
      </MenuHeading>

      <MenuHeading icon={<RiChat1Line size={16} />} to="feedback">
        Feedback
      </MenuHeading>

      <Divider my={1} />

      <MenuHeading
        icon={<PointerPressIcon height={16} width={16} />}
        to="tracking"
      >
        Tracking
      </MenuHeading>

      <MenuHeading icon={<RiSoundModuleLine size={16} />} to="settings">
        Settings
      </MenuHeading>
    </Flex>
  );
}

function MainMenuLegacy() {
  return (
    <Flex
      align="stretch"
      direction="column"
      fontSize="sm"
      gap={0.5}
      h="full"
      justifyContent="start"
      minH="276px"
      mt={1}
    >
      <MenuHeading
        icon={<FeatureViewsIcon height={16} width={16} />}
        to="features"
      >
        <Text>Features</Text>
      </MenuHeading>

      <MenuHeading icon={<ReleasesIcon height={16} width={16} />} to="releases">
        <HStack>
          <Text flexGrow={1}>Releases</Text>
          <Badge colorScheme="brand" size="xs">
            Beta
          </Badge>
        </HStack>
      </MenuHeading>

      <MenuHeading icon={<RiFlag2Line size={16} />} to="flags">
        <HStack>
          <HStack flexGrow={1}>
            <Text>Flags</Text>
          </HStack>
          <Badge colorScheme="brand" size="xs">
            Beta
          </Badge>
        </HStack>
      </MenuHeading>

      <MenuHeading icon={<FeatureSegmentIcon size={16} />} to="companies">
        Companies
      </MenuHeading>

      <MenuHeading icon={<RiChat1Line size={16} />} to="feedback">
        Feedback
      </MenuHeading>

      <MenuHeading
        icon={<PointerPressIcon height={16} width={16} />}
        to="tracking"
      >
        Tracking
      </MenuHeading>

      <MenuHeading icon={<RiSoundModuleLine size={16} />} to="settings">
        Settings
      </MenuHeading>
    </Flex>
  );
}

function MenuHeading({
  children,
  icon,
  to,
}: {
  children: ReactNode;
  icon?: ReactElement;
  manageButton?: ReactElement;
  to?: string;
}) {
  const activeBg = useColorModeValue("gray.100", "gray.800");
  const hoverText = useColorModeValue("gray.900", "gray.50");
  const text = useColorModeValue("gray.600", "gray.400");

  return (
    <Flex
      _hover={{ color: hoverText }}
      align="center"
      as={NavLink}
      borderRadius="md"
      color={text}
      end={false}
      gap={1.5}
      position="relative"
      px={2}
      py={1.5}
      sx={{
        '&[aria-current="page"]': {
          bg: activeBg,
          color: hoverText,
        },
      }}
      to={to}
      transition="all 0.1s ease-in-out"
    >
      <Box flex="0 0 auto">{icon}</Box>
      <Box
        flex="1 1 auto"
        fontWeight="medium"
        overflow="hidden"
        whiteSpace="nowrap"
        data-menu-heading
        isTruncated
      >
        {children}
      </Box>
    </Flex>
  );
}

function FooterLink({ children, ...props }: LinkProps) {
  return (
    <Link
      {...props}
      _hover={{ color: useColorModeValue("gray.900", "white") }}
      color="dimmed"
      fontSize="sm"
      fontWeight="medium"
      px={2}
      textDecoration="none"
    >
      {children}
    </Link>
  );
}

function TrialLink() {
  const hoverColor = useColorModeValue("gray.900", "white");

  const { currentEnv, currentOrg } = useAuthContext();

  if (
    currentEnv === undefined ||
    currentOrg === undefined ||
    currentOrg.trialEndsAt === null
  ) {
    return null;
  }

  return (
    <Link
      _hover={{ color: hoverColor }}
      as={RouterLink}
      color="dimmed"
      px={2}
      py={1.5}
      textDecoration="none"
      to={GlobalSettingsUrl(currentEnv, "org-billing")}
    >
      <TrialStatus />
    </Link>
  );
}

function FooterButtons() {
  return (
    <Flex direction="column" gap={0.5}>
      <FooterLink
        href="mailto:hello@bucket.co"
        px={2}
        textDecoration="none"
        onClick={(e) => {
          if (
            "HubSpotConversations" in window &&
            typeof window.HubSpotConversations?.widget?.open === "function"
          ) {
            e.preventDefault();
            window.HubSpotConversations.widget.open();
          }
        }}
      >
        Chat with us
      </FooterLink>

      <FooterLink
        href="https://bucket.co/category/changelog"
        px={2}
        target="_blank"
        textDecoration="none"
      >
        Changelog
      </FooterLink>
      <FooterLink
        href="https://docs.bucket.co"
        px={2}
        target="_blank"
        textDecoration="none"
      >
        Documentation
      </FooterLink>
      <FooterLink
        href={`${API_URL}/auth/logout`}
        px={2}
        textDecoration="none"
        onClick={(e) => {
          e.preventDefault();

          segmentAnalytics.reset();

          window.location.href = e.currentTarget.href;
        }}
      >
        Log out
      </FooterLink>
    </Flex>
  );
}

function BucketBrand() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { user } = useAuthContext();

  return (
    <Flex alignItems="center" gap={2} px={2}>
      <Box color="dimmed" flex="0 0 auto">
        <BucketLogo height="14px" />
      </Box>

      <Text color="dimmed" flex="1 1 auto" fontSize="md">
        Bucket
      </Text>

      {user?.isAdmin && <FeatureTargetingManager />}

      <Tooltip
        label={`${colorMode === "dark" ? "Light" : "Dark"} mode`}
        hasArrow
      >
        <IconButton
          _hover={{ color: useColorModeValue("gray.800", "gray.200") }}
          aria-label="Toggle color mode"
          color="dimmed"
          icon={
            colorMode === "dark" ? (
              <RiSunLine size={17} />
            ) : (
              <RiMoonLine size={16} />
            )
          }
          mr={-3}
          size="md"
          variant="ghost"
          isRound
          onClick={toggleColorMode}
        />
      </Tooltip>
    </Flex>
  );
}
