import {
  ForwardedRef,
  forwardRef,
  Fragment,
  ReactElement,
  ReactNode,
  useMemo,
} from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import {
  Box,
  Button,
  ButtonProps,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  useFormControl,
} from "@chakra-ui/react";

import MenuDescription from "@/common/components/MenuDescription";
import { PortalWrapper } from "@/common/components/PortalWrapper";

type Option<T extends string | number = string> = {
  icon?: ReactElement;
  label: ReactNode;
  value: T;
};

type Group<T extends string | number = string> = {
  id: string;
  title?: string;
  options: Option<T>[];
};

type OptionOrGroup<T extends string | number = string> = Option<T> | Group<T>;

export type SimpleSelectProps<T extends string = string> = Omit<
  ButtonProps,
  "value" | "onChange" | "children"
> & {
  menuDescription?: string;
  usePortal?: boolean;
} & (
    | {
        valueAsNumber: true;
        options: OptionOrGroup<number>[];
        value?: number;
        onChange?: (value: number) => void;
      }
    | {
        valueAsNumber?: false;
        options: OptionOrGroup<T>[];
        value?: T;
        onChange?: (value: T) => void;
      }
  );

function SimpleSelectInner<T extends string = string>(
  {
    options: optionsOrGroups,
    value,
    menuDescription,
    usePortal,
    valueAsNumber,
    onChange,
    placeholder,
    ...rest
  }: SimpleSelectProps<T>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const formControlProps = useFormControl<HTMLButtonElement>(rest);

  const groupsWithOptions = useMemo(() => {
    // Cast to avoid type error regarding incompatible union types, which doesn't matter here
    const castOptions = optionsOrGroups as any[];
    const options = castOptions.filter((opt): opt is Option<any> => {
      // Is an ungrouped Option and not a Group
      return "value" in opt;
    });

    const groups = castOptions.filter((opt): opt is Group<any> => {
      // Is a Group
      return "options" in opt;
    });

    const defaultGroup = {
      id: "DEFAULT",
      title: menuDescription,
      options: options,
    };

    if (!defaultGroup.title && defaultGroup.options.length === 0) {
      return groups;
    }

    return [defaultGroup, ...groups];
  }, [menuDescription, optionsOrGroups]);

  const activeItem = groupsWithOptions.flatMap((group) => {
    return group.options.filter((opt) => opt.value === value);
  })[0];

  return (
    <Menu autoSelect={false} size="sm">
      <MenuButton
        {...formControlProps}
        ref={ref}
        as={Button}
        bg="appBackground"
        isDisabled={formControlProps.disabled}
        leftIcon={activeItem?.icon}
        rightIcon={
          <Box fontSize="xl" mr={-2}>
            <RiArrowDownSLine />
          </Box>
        }
        variant="input"
        {...rest}
      >
        <Text as="div" noOfLines={1}>
          {activeItem?.label ?? <Text color="dimmed">{placeholder}</Text>}
        </Text>
      </MenuButton>
      <PortalWrapper usePortal={usePortal}>
        <MenuList
          data-testid="rule-field-attributes-list"
          maxH="min(46vh, 32rem)"
          maxW={32}
          overflow="auto"
          // workaround for to avoid horizontal scrollbar when positioned to the right of the screen
          rootProps={{ style: { transform: "scale(0)" } }}
        >
          {groupsWithOptions.map((group) => (
            <Fragment key={`description.${group.id}`}>
              {group.title && <MenuDescription>{group.title}</MenuDescription>}
              <MenuOptionGroup
                key={group.id}
                value={String(value)}
                onChange={(v) => onChange?.(valueAsNumber ? +v : (v as any))}
              >
                {group.options.map((option) => (
                  <MenuItemOption
                    key={option.value}
                    icon={option.icon}
                    iconSpacing={2}
                    value={String(option.value)}
                  >
                    <Text as="div">{option.label}</Text>
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </Fragment>
          ))}
        </MenuList>
      </PortalWrapper>
    </Menu>
  );
}

const SimpleSelect = forwardRef(SimpleSelectInner) as (<
  T extends string = string,
>(
  props: SimpleSelectProps<T> & { ref?: ForwardedRef<HTMLButtonElement> },
) => ReturnType<typeof SimpleSelectInner>) & { displayName: string };

SimpleSelect.displayName = "SimpleSelect";

export default SimpleSelect;
