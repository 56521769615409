import { useRef } from "react";
import { QueryKey, useQueries, UseQueryOptions } from "@tanstack/react-query";
import equal from "fast-deep-equal";

import {
  FeatureListItem,
  FeatureListQueryType,
  SegmentedList,
} from "@bucketco/shared/featureAPI";
import { SegmentDTOWithTotalCompanies } from "@bucketco/shared/segmentAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { useSegments } from "@/company/data/useSegments";
import featureQueryKeys from "@/feature-legacy/data/featureQueryKeys";

type Params = Omit<FeatureListQueryType, "subsegment"> & {
  subsegments?: string[];
};

type Result = {
  data: SegmentedList<FeatureListItem[]>[];
  isError: boolean;
  isInitialLoading: boolean;
};

type QueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  _TQueryData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  "context" | "queryKey" | "queryFn"
>;

const query = (
  appId: string | undefined,
  envId: string | undefined,
  segments: SegmentDTOWithTotalCompanies[],
  params: Omit<FeatureListQueryType, "envId">,
  options?: QueryOptions,
) => {
  return {
    // Compare is used to get around the fact that we can't use the same query key
    queryKey: featureQueryKeys.listFilteredCompare(appId, envId, params),
    queryFn: () =>
      api
        .get<"/apps/:appId/features">(`/apps/${appId}/features`, {
          params: { ...params, envId: envId! },
        })
        .then(
          (res) =>
            ({
              items: res.data.data,
              subsegment: segments.find(
                (segment) => segment.id === params.subsegment,
              ),
            }) satisfies SegmentedList<FeatureListItem[]>,
        ),
    ...options,
  };
};

export default function useFeaturesCompareData(
  queryParams: Omit<Params, "envId"> = {},
  queryOptions?: QueryOptions,
): Result {
  const { appId, envId } = useCurrentEnv();
  const refResults = useRef<Result>();

  const { subsegments, ...params } = queryParams;
  const { data: segments, isInitialLoading: isSegmentsInitialLoading } =
    useSegments();

  const responses = useQueries({
    queries: subsegments?.length
      ? subsegments.map((subsegment) =>
          query(
            appId,
            envId,
            segments ?? [],
            { ...params, subsegment },
            {
              enabled: !!appId && !isSegmentsInitialLoading,
              ...queryOptions,
            },
          ),
        )
      : [
          query(appId, envId, segments ?? [], params, {
            enabled: !!appId && !isSegmentsInitialLoading,
            ...queryOptions,
          }),
        ],
  });

  const isInitialLoading =
    isSegmentsInitialLoading || responses.some((r) => r.isInitialLoading);
  const isError = responses.some((r) => r.isError);
  const data = responses
    .map((response) => response.data)
    .filter((data): data is SegmentedList<FeatureListItem[]> => {
      return data !== undefined;
    });

  const result = { isInitialLoading, isError, data };

  // useQueries is not referentially stable so we need to run a deep equality check
  if (!equal(result, refResults.current)) {
    refResults.current = result;
  }

  return refResults.current!;
}
