import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useFormContext } from "react-hook-form";
import { RiEditLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Flex,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import pick from "lodash/pick";
import { useSessionStorage } from "usehooks-ts";

import {
  ReleaseDetailsDTO,
  ReleasePostArgs,
} from "@bucketco/shared/releaseAPI";
import { ReleasesUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import { ConfirmationDialog } from "@/common/components/ConfirmationDialog";
import FormInput from "@/common/components/form/FormInput";
import HeaderLayout from "@/common/components/HeaderLayout";
import dayjs from "@/common/utils/dayjs";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { CreateAffectEnvironments } from "@/environment/components/EnvironmentCallouts";
import { Goals } from "@/release/components/LegacyGoals";
import { ReleaseDetails } from "@/release/components/ReleaseDetails";
import { ReleasesIcon } from "@/release/components/ReleasesIcon";
import { ReleaseStatusBadge } from "@/release/components/ReleaseStatusBadge";
import { useReleaseDeleteMutation } from "@/release/data/useReleaseDeleteMutation";
import { useReleaseEditing } from "@/release/hooks/useReleaseEditing";
import { ReleaseDraft } from "@/release/types/types";

import { releaseToPostArgs } from "./releaseFormTools";

type ReleasePageProps = {
  release?: ReleaseDetailsDTO | undefined;
  onSaved: () => void;
};

export const LegacyReleasePage = ({ release, onSaved }: ReleasePageProps) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { currentEnv } = useAuthContext();

  const [releaseDraft, setReleaseDraft] = useSessionStorage<
    ReleaseDraft | undefined
  >(`release:${release ? release.id : "new"}`, undefined);
  const [editing, setEditing] = useReleaseEditing();
  const {
    getValues,
    watch,
    reset,
    trigger,
    formState: { isValid, isSubmitting },
  } = useFormContext<ReleasePostArgs>();
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteReleaseMutation = useReleaseDeleteMutation();

  const [releasedAt, evaluationPeriod] = editing
    ? watch(["releasedAt", "evaluationPeriod"])
    : [release?.releasedAt, release?.evaluationPeriod];

  const {
    isOpen: isDiscardOpen,
    onOpen: onDiscardOpen,
    onClose: onDiscardClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const onAppendOrRemoveGoal = () => {
    const values = getValues();
    setReleaseDraft({ id: release?.id, ...values });
  };

  const onDiscardRelease = () => {
    setEditing(false);
    setReleaseDraft(undefined);
    onDiscardClose();
    segmentAnalytics.track("Release Changes Discarded", {
      id: release?.id,
      name: release?.name,
    });
    if (release?.id) {
      reset(releaseToPostArgs(release));
    } else {
      navigate(ReleasesUrl(currentEnv!));
    }
  };

  const onDeleteRelease = () => {
    if (!release?.id) return;
    setIsDeleting(true);
    deleteReleaseMutation.mutate(
      {
        releaseId: release.id,
      },
      {
        onSuccess() {
          setEditing(false);
          navigate(ReleasesUrl(currentEnv!), { replace: true });
        },
        onError() {
          toast({
            title: "Failed to delete the release",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        },
        onSettled() {
          setIsDeleting(false);
        },
      },
    );
  };

  return (
    <HeaderLayout
      actions={
        editing ? (
          <ButtonGroup alignItems="center">
            {!!releaseDraft && (
              <Text color="dimmed" fontStyle="italic" mr={8}>
                Draft not saved
              </Text>
            )}
            <Button
              size="sm"
              variant="ghost"
              onClick={() => {
                releaseDraft ? onDiscardOpen() : onDiscardRelease();
              }}
            >
              Discard
            </Button>
            <Button
              colorScheme="brand"
              isLoading={isSubmitting}
              loadingText="Saving"
              size="sm"
              type="submit"
              onClick={() => {
                trigger(); // Show any validation errors

                if (!isValid) return;
                if (!release?.id || releaseDraft) onSaved();
                else setEditing(false);
              }}
            >
              Save
            </Button>
          </ButtonGroup>
        ) : (
          <ButtonGroup>
            {release?.id && (
              <Button
                colorScheme="red"
                size="sm"
                variant="ghost"
                onClick={onDeleteOpen}
              >
                Delete
              </Button>
            )}
            <Button
              colorScheme={releaseDraft ? "brand" : undefined}
              leftIcon={<RiEditLine />}
              size="sm"
              variant={!releaseDraft ? "outline" : undefined}
              onClick={() => setEditing(true)}
            >
              {releaseDraft ? "Resume editing" : "Edit"}
            </Button>
          </ButtonGroup>
        )
      }
      title={
        editing ? (
          <FormInput
            autoComplete="off"
            name="name"
            placeholder={dayjs().format("MMM D, YYYY")}
            autoFocus
          />
        ) : !release ? (
          <Text color="dimmed" noOfLines={1}>
            Release not found
          </Text>
        ) : (
          <>
            <ReleasesIcon boxSize="16px" flexShrink={0} />
            <Text isTruncated>{release.name}</Text>
            <ReleaseStatusBadge
              ml={3}
              {...pick(release, ["status", "releasedAt", "evaluationPeriod"])}
            />
          </>
        )
      }
    >
      <Helmet>
        <title>Releases{release?.name ? ` › ${release.name}` : ""}</title>
      </Helmet>
      <Flex direction="column" gap={8} px={6} py={4}>
        <CreateAffectEnvironments entity="releases" />
        <ReleaseDetails
          editing={editing}
          evaluationPeriod={evaluationPeriod}
          releasedAt={releasedAt}
        />
        <Goals
          evaluationPeriod={evaluationPeriod}
          releasedAt={releasedAt}
          releaseId={release?.id}
          releaseStatus={release?.status}
          onAppend={onAppendOrRemoveGoal}
          onRemove={onAppendOrRemoveGoal}
        />
      </Flex>
      <ConfirmationDialog
        confirmColor="red"
        confirmLabel="Discard"
        description={
          release?.id ? (
            <Text>
              Are you sure you want to discard your changes to release{" "}
              <strong>{release.name}</strong>?
            </Text>
          ) : (
            `Are you sure you want to discard your new release?`
          )
        }
        isOpen={isDiscardOpen}
        title={release?.id ? "Discard changes" : "Discard new release"}
        warning="This action cannot be undone."
        onCancel={onDiscardClose}
        onClose={onDiscardClose}
        onConfirm={onDiscardRelease}
      />
      <ConfirmationDialog
        confirmColor="red"
        confirmLabel="Delete"
        description={
          <Text>
            Are you sure you want to delete the release{" "}
            <strong>{release?.name}</strong>?
          </Text>
        }
        isLoading={isDeleting}
        isOpen={isDeleteOpen}
        loadingText="Deleting"
        title="Delete release"
        warning="This action cannot be undone."
        onCancel={onDeleteClose}
        onClose={onDeleteClose}
        onConfirm={onDeleteRelease}
      />
    </HeaderLayout>
  );
};
