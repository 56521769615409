import React, { useMemo } from "react";
import { RiBracesLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  HStack,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { ColumnDef, ColumnSort } from "@tanstack/react-table";

import {
  AttributeType,
  CompaniesQuerySortByType,
  CompaniesQueryType,
  CompanyListItem,
} from "@bucketco/shared/companyAPI";
import { FeatureName } from "@bucketco/shared/featureAPI";
import { SegmentDTO } from "@bucketco/shared/segmentAPI";
import { ColumnState } from "@bucketco/shared/types/columns";
import { CompanyUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import EmptyStateActiveDark from "@/common/assets/empty-state-companies-active-dark.svg?react";
import EmptyStateActiveLight from "@/common/assets/empty-state-companies-active-light.svg?react";
import EmptyStateAllDark from "@/common/assets/empty-state-companies-all-dark.svg?react";
import EmptyStateAllLight from "@/common/assets/empty-state-companies-all-light.svg?react";
import DataCell from "@/common/components/DataCell";
import DataTable from "@/common/components/DataTable";
import EmptyState from "@/common/components/EmptyState";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import { featureMetricOptions } from "@/common/components/Rule/RuleMetric";
import { UseDataTableReturnType } from "@/common/hooks/useDataTable";
import { createSortableColumnHelper } from "@/common/types/reactTableHelpers";
import { linkClickModifierHandler } from "@/common/utils/linkClickHandler";
import { AddColumnPopover } from "@/company/components/AddColumnPopover";
import FeatureDisplay from "@/feature-legacy/components/FeatureDisplay";
import FeatureFrequencyProgress from "@/feature-legacy/components/FeatureMetrics";
import { FeedbackSatisfactionIcon } from "@/feature-legacy/components/FeedbackSatisfaction";
import useFeatureNamesData from "@/feature-legacy/data/useFeatureNamesData";
import { useStarsFunnelColors } from "@/feature-legacy/hooks/useStarsFunnelColors";

const emptyIllustrations: {
  [key in string]: [light: React.ReactElement, dark: React.ReactElement];
} = {
  // eslint-disable-next-line react/jsx-key
  all: [<EmptyStateAllLight />, <EmptyStateAllDark />],
  // eslint-disable-next-line react/jsx-key
  active: [<EmptyStateActiveLight />, <EmptyStateActiveDark />],
};

export const defaultCompanyColumns: (keyof CompanyListItem)[] = [
  "name",
  "firstSeen",
  "lastSeen",
  "userCount",
];

const MetricHeader = ({
  feature,
  metric,
}: {
  feature: FeatureName;
  metric: string;
}) => {
  const metricOption = featureMetricOptions.find(
    (option) => option.metric === metric,
  );
  return (
    <Tooltip
      label={`${feature.name} • ${metricOption?.label}`}
      openDelay={300}
      hasArrow
    >
      <HStack spacing={1.5}>
        <FeatureDisplay
          feature={feature}
          flexShrink={1}
          iconSize={12}
          size="sm"
        />
        <Box color="dimmed">{metricOption?.icon}</Box>
      </HStack>
    </Tooltip>
  );
};

type Props = Pick<
  UseDataTableReturnType<
    CompanyListItem,
    Pick<CompaniesQueryType, "sortBy" | "segment" | "companyFilter">,
    { totalUserCount: number; attributeTypes: Record<string, AttributeType> }
  >,
  | "data"
  | "isLoading"
  | "isFetching"
  | "pageCount"
  | "setCanPaginate"
  | "setPaginateActions"
  | "fetchData"
> & {
  segment: SegmentDTO;
  columnStates: ColumnState[];
  setColumnStates: (nextColumnStates: ColumnState[]) => void;
  columnSort: ColumnSort;
  setColumnSort: (nextColumnSort: ColumnSort) => void;
};

export const CompaniesTable = ({
  segment,
  data,
  isLoading,
  isFetching,
  pageCount,
  setCanPaginate,
  setPaginateActions,
  fetchData,
  columnStates: propColumnStates,
  setColumnStates,
  columnSort,
  setColumnSort,
}: Props) => {
  const { currentEnv } = useAuthContext();

  const navigate = useNavigate();

  const { retainedColor } = useStarsFunnelColors();

  const { data: features = [] } = useFeatureNamesData();

  const columnStates = useMemo(
    () =>
      propColumnStates.length
        ? propColumnStates
        : defaultCompanyColumns.map((id) => ({
            id,
            shown: true,
          })),
    [propColumnStates],
  );

  const columnHelper = createSortableColumnHelper<
    CompanyListItem,
    CompaniesQuerySortByType
  >();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        cell: ({ row }) => {
          return (
            <Text as={Link} to={CompanyUrl(currentEnv!, row.original.id)}>
              <HStack>
                <Avatar
                  name={row.original.name || row.original.id}
                  size="2xs"
                />
                <Text fontSize="md" fontWeight="medium" noOfLines={1}>
                  {row.original.name || row.original.id}
                </Text>
              </HStack>
            </Text>
          );
        },
      }),
      columnHelper.accessor("firstSeen", {
        header: "First seen",
        sortDescFirst: true,
        cell: (cell) => <DataCell type="date" value={cell.getValue()} />,
      }),
      columnHelper.accessor("lastSeen", {
        header: "Last seen",
        sortDescFirst: true,
        cell: (cell) => <DataCell type="date" value={cell.getValue()} />,
      }),
      columnHelper.accessor("userCount", {
        header: "Users",
        cell: (cell) => (
          <DataCell type="number" value={cell.getValue().toString()} compact />
        ),
      }),
      // unfortunately we need to type this manually as it can't be inferred
      ...columnStates.flatMap<ColumnDef<CompanyListItem, any>>(
        (columnState) => {
          const [key, id, metric] = columnState.id.split(".");
          if (key !== "featureMetrics") return [];
          const feature = features?.find((f) => f.id === id);
          if (!feature) return [];

          if (metric === "funnelStep") {
            return [
              columnHelper.accessor(`featureMetrics.${id}.funnelStep`, {
                id: `featureMetrics.${id}.funnelStep`,
                sortDescFirst: true,
                maxSize: 200,
                header: () => (
                  <MetricHeader feature={feature} metric={metric} />
                ),
                meta: {
                  isRemovable: true,
                },
                cell: (cell) => (
                  <DataCell type="funnelStep" value={cell.getValue()} />
                ),
              }),
            ];
          }

          if (metric === "eventCount") {
            return [
              columnHelper.accessor(`featureMetrics.${id}.eventCount`, {
                id: `featureMetrics.${id}.eventCount`,
                maxSize: 200,
                header: () => (
                  <MetricHeader feature={feature} metric={metric} />
                ),
                meta: {
                  isRemovable: true,
                },
                cell: (cell) => (
                  <DataCell type="number" value={cell.getValue()} />
                ),
              }),
            ];
          }

          if (metric === "firstUsed") {
            return [
              columnHelper.accessor(`featureMetrics.${id}.firstUsed`, {
                id: `featureMetrics.${id}.firstUsed`,
                sortDescFirst: true,
                maxSize: 200,
                header: () => (
                  <MetricHeader feature={feature} metric={metric} />
                ),
                meta: {
                  isRemovable: true,
                },
                cell: (cell) => (
                  <DataCell type="date" value={cell.getValue()} />
                ),
              }),
            ];
          }

          if (metric === "lastUsed") {
            return [
              columnHelper.accessor(`featureMetrics.${id}.lastUsed`, {
                id: `featureMetrics.${id}.lastUsed`,
                sortDescFirst: true,
                maxSize: 200,
                header: () => (
                  <MetricHeader feature={feature} metric={metric} />
                ),
                meta: {
                  isRemovable: true,
                },
                cell: (cell) => (
                  <DataCell type="date" value={cell.getValue()} />
                ),
              }),
            ];
          }

          if (metric === "frequency") {
            return [
              columnHelper.accessor(`featureMetrics.${id}.frequency`, {
                id: `featureMetrics.${id}.frequency`,
                maxSize: 200,
                header: () => (
                  <MetricHeader feature={feature} metric={metric} />
                ),
                meta: {
                  isRemovable: true,
                },
                cell: (cell) => {
                  const value = cell.getValue();
                  return value ? (
                    <FeatureFrequencyProgress
                      filledColor={retainedColor}
                      frequency={value}
                    />
                  ) : (
                    <NotAvailableCell />
                  );
                },
              }),
            ];
          }

          if (metric === "satisfaction") {
            return [
              columnHelper.accessor(`featureMetrics.${id}.satisfaction`, {
                id: `featureMetrics.${id}.satisfaction`,
                maxSize: 200,
                header: () => (
                  <MetricHeader feature={feature} metric={metric} />
                ),
                meta: {
                  isRemovable: true,
                },
                cell: (cell) => {
                  const value = cell.getValue();
                  return value ? (
                    <FeedbackSatisfactionIcon colored={true} value={value} />
                  ) : (
                    <NotAvailableCell />
                  );
                },
              }),
            ];
          }

          return [];
        },
      ),
      ...columnStates.flatMap((columnState) => {
        if (!columnState.id.startsWith("attributes.")) return [];
        const id = columnState.id.replace("attributes.", "");
        return [
          columnHelper.accessor(`attributes.${id}`, {
            id: `attributes.${id}`,
            maxSize: 200,
            header: () => (
              <Tooltip label={id} openDelay={300} hasArrow>
                <HStack spacing={1}>
                  <Box color="dimmed">
                    <RiBracesLine size={12} />
                  </Box>
                  <Text isTruncated>{id}</Text>
                </HStack>
              </Tooltip>
            ),
            meta: {
              isRemovable: true,
            },
            cell: (cell) => (
              <DataCell
                type={data?.metadata.attributeTypes[id] ?? "string"}
                value={cell.getValue()}
              />
            ),
          }),
        ];
      }),
    ],
    [
      columnHelper,
      columnStates,
      currentEnv,
      features,
      retainedColor,
      data?.metadata.attributeTypes,
    ],
  );

  const emptyIllu = useColorModeValue(
    ...emptyIllustrations[segment.name === "Active" ? "active" : "all"],
  );
  const emptyDescription = `No ${
    segment.name === "Active" ? "active" : ""
  } companies to show`;

  const companies = data?.data || [];

  if (isLoading ? null : companies.length === 0) {
    return (
      <EmptyState description={emptyDescription} flexGrow={1}>
        {emptyIllu}
      </EmptyState>
    );
  }

  return (
    <DataTable
      columns={columns}
      columnStates={columnStates}
      customizableFooter={
        <VStack align="flex-start" p={2}>
          <AddColumnPopover
            columnStates={columnStates}
            setColumnStates={setColumnStates}
          />
        </VStack>
      }
      data={companies}
      defaultColumns={defaultCompanyColumns}
      fetchData={fetchData}
      isFetching={isFetching}
      pageCount={pageCount}
      rowOnClick={(row, e) => {
        const url = CompanyUrl(currentEnv!, row.original.id);
        e.preventDefault();

        linkClickModifierHandler(url, () => {
          navigate(CompanyUrl(currentEnv!, row.original.id));
        })(e);
      }}
      setCanPaginate={setCanPaginate}
      setColumnStates={setColumnStates}
      setPaginateActions={setPaginateActions}
      setSortBy={setColumnSort}
      sortBy={columnSort}
      trackingId="companies"
      variant="clickable"
    />
  );
};
