import { Badge, BadgeProps } from "@chakra-ui/react";

import { ReleaseDetailsDTO } from "@bucketco/shared/releaseAPI";

import { useGoalStatusColors } from "@/release/hooks/useGoalStatusColors";

const sharedProps: BadgeProps = {
  size: "md",
};

type Props = Pick<ReleaseDetailsDTO, "status"> & BadgeProps & { size?: number };

export const ReleaseStatusBadge = ({ status, ...rest }: Props) => {
  const { planned, evaluating } = useGoalStatusColors();

  switch (status) {
    case "planned":
      return (
        <Badge {...sharedProps} bg={planned.bg} color={planned.fg} {...rest}>
          Planned
        </Badge>
      );
    case "evaluating":
      return (
        <Badge
          {...sharedProps}
          bg={evaluating.bg}
          color={evaluating.fg}
          {...rest}
        >
          Evaluating
        </Badge>
      );
    default:
      return null;
  }
};
