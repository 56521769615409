import { ReactNode } from "react";
import { Box, ButtonGroup, Card, Flex } from "@chakra-ui/react";

import {
  CloseIconButton,
  DeleteConfirmIconButton,
  EditIconButton,
} from "@/common/components/CommonIconButtons";
import { SectionHeading } from "@/common/components/SectionHeading";
import { useWidgetDeleteMutation } from "@/widget/data/useWidgetMutations";

const wrapTitle = (title: ReactNode) =>
  typeof title === "string" ? <SectionHeading>{title}</SectionHeading> : title;

type Props = {
  widgetId: string;
  title?: ReactNode;
  content: ReactNode;
  form?: ReactNode;
  isEditing: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  formWidth?: number;
  setIsEditing: (isEditing: boolean) => void;
};

export function WidgetLayout({
  widgetId,
  title,
  content,
  form,
  isEditing,
  canEdit = !!form,
  canDelete = true,
  formWidth = 260,
  setIsEditing,
}: Props) {
  const deleteMutation = useWidgetDeleteMutation(widgetId);

  return (
    <Card
      direction="row"
      display="flex"
      minH="3xs"
      overflow="hidden"
      position="relative"
    >
      <Flex
        align="flex-start"
        direction="column"
        gap={4}
        p={4}
        transition="width 200ms"
        width={isEditing ? `calc(100% - ${formWidth}px)` : "100%"}
      >
        {wrapTitle(title)}
        {content}
      </Flex>

      {isEditing && (
        <Box
          borderLeftColor="appBorder"
          borderLeftWidth={1}
          p={4}
          w={`${formWidth}px`}
        >
          {form}
        </Box>
      )}

      <ButtonGroup position="absolute" right={2} spacing={1} top={2}>
        {isEditing && (
          <CloseIconButton
            borderRadius="full"
            color="dimmed"
            label="Stop editing widget"
            variant="ghost"
            onClick={() => setIsEditing(false)}
          />
        )}
        {canEdit && !isEditing && (
          <EditIconButton
            borderRadius="full"
            color="dimmed"
            label="Edit widget"
            variant="ghost"
            onClick={() => setIsEditing(true)}
          />
        )}
        {canDelete && !isEditing && (
          <DeleteConfirmIconButton
            borderRadius="full"
            color="dimmed"
            confirmLabel="Click again to delete widget"
            label="Delete widget"
            variant="ghost"
            onClick={() => deleteMutation.mutate()}
          />
        )}
      </ButtonGroup>
    </Card>
  );
}
