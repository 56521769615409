import { useMemo } from "react";
import { chakra, Flex, HStack, Text, Tooltip, VStack } from "@chakra-ui/react";

import { FeatureName } from "@bucketco/shared/featureAPI";
import { GoalDTO, GoalFeatureMetrics } from "@bucketco/shared/goalAPI";
import { SegmentDTO } from "@bucketco/shared/segmentAPI";

import SubsegmentsSvg from "@/common/assets/subsegments.svg?react";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import { useSegments } from "@/company/data/useSegments";
import FeatureDisplay from "@/feature-legacy/components/FeatureDisplay";
import useFeatureNamesData from "@/feature-legacy/data/useFeatureNamesData";
import GoalDescription from "@/release/components/GoalDescription";
import { GoalStatusIcon } from "@/release/components/GoalStatusIcon";
import GoalTarget from "@/release/components/GoalTarget";

const SubsegmentsIcon = chakra(SubsegmentsSvg);

export default function GoalCell({
  goals,
  max,
  maxWithDescription,
  withTooltip = true,
}: {
  goals: GoalDTO[];
  max?: number;
  maxWithDescription?: number;
  withTooltip?: boolean;
}) {
  const { data: featureNames = [] } = useFeatureNamesData();
  const { data: segments = [] } = useSegments();

  const filtered = useMemo(
    () => (max ? goals.slice(0, max) : goals),
    [goals, max],
  );

  if (goals.length === 0) {
    return <NotAvailableCell />;
  }

  return (
    <Flex
      alignItems="center"
      flexWrap="wrap"
      fontSize="sm"
      gap={3}
      whiteSpace="nowrap"
    >
      {filtered.map((goal) => {
        const definition = GoalFeatureMetrics[goal.configuration.metric];
        const showDescription =
          maxWithDescription === undefined ||
          (definition && goals.length <= maxWithDescription);

        return (
          <Tooltip
            key={goal.id}
            label={
              withTooltip ? (
                <GoalCellTooltip
                  featureNames={featureNames}
                  goal={goal}
                  segments={segments}
                  showDescription={!showDescription}
                />
              ) : (
                ""
              )
            }
          >
            <HStack spacing={1.5}>
              <GoalStatusIcon
                progress={goal.progress}
                progressIndeterminate={goal.progressIndeterminate}
                showPercentage={false}
                size={16}
                status={goal.status}
              />
              {showDescription && (
                <GoalDescription goal={goal} colored showThreshold />
              )}
            </HStack>
          </Tooltip>
        );
      })}

      {max !== undefined && goals.length > max ? (
        <Text color="dimmed" fontSize="sm">
          + {goals.length - max}
        </Text>
      ) : null}
    </Flex>
  );
}

function GoalCellTooltip({
  goal,
  featureNames,
  segments,
  showDescription,
  showTarget = false,
}: {
  goal: GoalDTO;
  featureNames: FeatureName[];
  segments: SegmentDTO[];
  showDescription: boolean;
  showTarget?: boolean;
}) {
  const definition = GoalFeatureMetrics[goal.configuration.metric];

  const feature = useMemo(
    () => featureNames.find((f) => f.id === goal.configuration.featureId),
    [featureNames, goal.configuration.featureId],
  );

  const subsegment = useMemo(
    () => segments.find((s) => s.id === goal.configuration.subsegmentId),
    [segments, goal.configuration.subsegmentId],
  );

  return (
    <VStack alignItems="start" spacing={0.5}>
      {showDescription && definition && <GoalDescription goal={goal} />}

      {showTarget && (
        <GoalTarget
          color="chakra-body-bg"
          goal={goal}
          iconSize="14px"
          spacing={1}
        />
      )}

      {feature && (
        <FeatureDisplay
          feature={feature}
          fontSize="sm"
          fontWeight="normal"
          iconColor="chakra-body-bg"
          iconSize={14}
          mt="auto"
          subsegmentId={subsegment?.id}
          link
        />
      )}

      {subsegment && (
        <HStack spacing={1.5}>
          <SubsegmentsIcon height={3} ml="1px" mr="-1px" width={3} />
          <Text as="span" fontSize="sm">
            {subsegment.name}
          </Text>
        </HStack>
      )}
    </VStack>
  );
}
