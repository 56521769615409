import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { SlackIntegrationType } from "@bucketco/shared/featureAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import featureQueryKeys from "@/feature-legacy/data/featureQueryKeys";

export function useFeatureIntegrations(featureId: string) {
  const { appId, envId } = useCurrentEnv();
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SlackIntegrationType) =>
      api
        .patch<"/apps/:appId/features/:featureId">(
          `/apps/${appId}/features/${featureId}`,
          {
            integrations: {
              slack: data,
            },
          },
          { params: { envId: envId! } },
        )
        .then((res) => res.data.feature),

    onSuccess: (feature) => {
      queryClient.setQueryData(
        featureQueryKeys.singleEnv(appId, envId, featureId),
        feature,
      );
      segmentAnalytics.track("Feature Slack Integration Updated", {
        hasSetChannel: !!feature.integrations.slack.channelId,
        weeklyReport: feature.integrations.slack.weeklyReport,
        dailyReport: feature.integrations.slack.dailyReport,
      });
      toast({
        title: "Integration settings saved",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    },
  });
}
