import { RiArrowRightLine, RiFlag2Fill, RiFlag2Line } from "react-icons/ri";
import {
  Button,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";

import SimpleSelect from "@/common/components/SimpleSelect";
import SimpleTable from "@/common/components/SimpleTable";
import {
  AvailableFeatures,
  useFeature,
  useFeatures,
} from "@/common/hooks/useFeatureFlags";

export default function FeatureTargetingManager() {
  const { availableFeatures, hasLocalOverrides, resetLocalOverides } =
    useFeatures();

  const activeColor = useColorModeValue("brand.500", "brand.400");

  return (
    <Popover placement="left-start">
      <PopoverTrigger>
        <IconButton
          _hover={{ color: useColorModeValue("gray.800", "gray.200") }}
          aria-label="feature targeting manager"
          color={hasLocalOverrides ? activeColor : "dimmed"}
          icon={
            hasLocalOverrides ? (
              <RiFlag2Fill size={16} />
            ) : (
              <RiFlag2Line size={16} />
            )
          }
          size="md"
          variant="ghost"
          isRound
        />
      </PopoverTrigger>
      <PopoverContent w="auto">
        <PopoverArrow />
        <PopoverBody fontSize="sm" p={2}>
          <SimpleTable
            columns={["Feature key", "Remote", "Env", "Override?", "", "Final"]}
            rows={availableFeatures ?? []}
            rowTemplate={(key) => <SingleFeature key={key} featureKey={key} />}
            size="sm"
          />
          <Button
            isDisabled={!hasLocalOverrides}
            m={3}
            mb={2}
            size="xs"
            variant="outline"
            onClick={() => resetLocalOverides()}
          >
            Reset overrides
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

function SingleFeature({
  featureKey,
}: {
  featureKey: keyof AvailableFeatures;
}) {
  const { isEnabled, values, updateLocalOverride } = useFeature(featureKey);

  return (
    <Tr>
      <Td fontWeight="medium" minW="200px">
        {featureKey}
      </Td>
      <Td>
        <FormatValue value={values.evaluation} />
      </Td>
      <Td>
        <FormatValue value={values.envVar} />
      </Td>
      <Td>
        <SimpleSelect
          options={[
            {
              label: <FormatValue label="enable" value={true} />,
              value: "enable",
            },
            {
              label: <FormatValue label="disable" value={false} />,
              value: "disable",
            },
            {
              label: <FormatValue label="unset" value={null} />,
              value: "unset",
            },
          ]}
          size="xs"
          value={
            values.override === null
              ? "unset"
              : values.override
              ? "enable"
              : "disable"
          }
          w={20}
          onChange={(val) => {
            const newValue = val === "unset" ? null : val === "enable";
            updateLocalOverride(newValue);
          }}
        />
      </Td>
      <Td color="dimmed">
        <RiArrowRightLine size={16} />
      </Td>
      <Td>
        <FormatValue value={isEnabled} />
      </Td>
    </Tr>
  );
}

function FormatValue({
  value,
  label,
}: {
  value: boolean | null;
  label?: string;
}) {
  const enabledColor = useColorModeValue("teal.500", "teal.400");
  const disabledColor = useColorModeValue("orange.500", "orange.400");

  return value === null ? (
    <Text as="span" color="dimmed">
      {label || "unset"}
    </Text>
  ) : value ? (
    <Text as="span" color={enabledColor}>
      {label || "enabled"}
    </Text>
  ) : (
    <Text as="span" color={disabledColor}>
      {label || "disabled"}
    </Text>
  );
}
