import {
  RiCodeSSlashLine,
  RiExternalLinkLine,
  RiFileCodeLine,
} from "react-icons/ri";
import { Box, Button, ButtonProps, Flex, Text } from "@chakra-ui/react";

export function SDKButton({
  section,
  ...buttonProps
}: { section?: string } & ButtonProps) {
  return (
    <Button
      as="a"
      href={`https://github.com/bucketco/bucket-tracking-sdk${
        section ? `#${section}` : ""
      }`}
      justifyContent="space-between"
      leftIcon={
        <Box
          bg="yellow.400"
          borderRadius="base"
          color="gray.900"
          px={1}
          py={0.5}
        >
          <RiCodeSSlashLine />
        </Box>
      }
      px={2.5}
      rightIcon={
        <Box color="gray.500">
          <RiExternalLinkLine size={12} />
        </Box>
      }
      target="_blank"
      variant="outline"
      {...buttonProps}
    >
      <Text flexGrow={1}>Javascript SDK</Text>
    </Button>
  );
}

export function APIButton({
  section,
  ...buttonProps
}: { section?: string } & ButtonProps) {
  return (
    <Button
      as="a"
      display="inline-flex"
      href={`https://docs.bucket.co/api/http-api${
        section ? `#${section}` : ""
      }`}
      justifyContent="space-between"
      leftIcon={
        <Box bg="blue.300" borderRadius="base" color="gray.900" px={1} py={0.5}>
          <RiCodeSSlashLine />
        </Box>
      }
      px={2.5}
      rightIcon={
        <Box color="gray.500">
          <RiExternalLinkLine size={12} />
        </Box>
      }
      target="_blank"
      variant="outline"
      {...buttonProps}
    >
      <Text flexGrow={1}>REST API</Text>
    </Button>
  );
}

export function DocumentationButton(props: ButtonProps) {
  return (
    <Button
      as="a"
      display="inline-flex"
      href="https://docs.bucket.co"
      justifyContent="space-between"
      leftIcon={
        <Flex justify="center" w={5}>
          <RiFileCodeLine size={18} />
        </Flex>
      }
      px={2.5}
      rightIcon={
        <Box color="gray.500">
          <RiExternalLinkLine size={12} />
        </Box>
      }
      target="_blank"
      variant="outline"
      {...props}
    >
      <Text flexGrow={1}>Documentation</Text>
    </Button>
  );
}

export function AutoFeedbackSurveysButton(props: ButtonProps) {
  return (
    <Button
      as="a"
      display="inline-flex"
      href="https://docs.bucket.co/product-handbook/automated-feedback-surveys"
      justifyContent="space-between"
      leftIcon={
        <Flex justify="center" w={5}>
          <RiFileCodeLine size={18} />
        </Flex>
      }
      px={2.5}
      rightIcon={
        <Box color="gray.500">
          <RiExternalLinkLine size={12} />
        </Box>
      }
      target="_blank"
      variant="outline"
      {...props}
    >
      <Text flexGrow={1}>Automated surveys setup</Text>
    </Button>
  );
}
