import { UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { EnvironmentDTO } from "@bucketco/shared/environmentAPI";
import { FlagVersion } from "@bucketco/shared/flagAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import { useEnvironmentsData } from "@/environment/data/useEnvironmentsData";
import { useFlagData } from "@/flags/data/useFlagData";

export type EnvironmentFlagVersion = EnvironmentDTO & {
  version: FlagVersion | undefined;
};

/**
 * Fetches the latest flag rules versions and maps them to the app's environments.
 */
export const useEnvironmentFlagVersionsData = (
  flagId?: string,
): Pick<
  UseQueryResult<EnvironmentFlagVersion[], AxiosError<ErrorResponse>>,
  "data" | "isLoading" | "isFetching" | "isError" | "isSuccess" | "error"
> => {
  const { currentApp } = useAuthContext();
  const { data: flag, ...flagRes } = useFlagData(flagId);
  const { data: environments, ...envsRes } = useEnvironmentsData(
    currentApp?.id,
  );

  return {
    data:
      flag && environments
        ? environments.map((env) => ({
            ...env,
            version: flag.currentVersions?.find(
              (version) => version.environment.id === env.id,
            ),
          }))
        : [],
    isLoading: flagRes.isLoading || envsRes.isLoading,
    isFetching: flagRes.isFetching || envsRes.isFetching,
    isSuccess: flagRes.isSuccess && envsRes.isSuccess,
    isError: flagRes.isError || envsRes.isError,
    error: flagRes.error || envsRes.error,
  };
};
