import { useMemo } from "react";
import { RiCalendarCheckLine, RiTimeLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Box, Divider, Flex, HStack, Text } from "@chakra-ui/react";
import omit from "lodash/omit";

import { SEARCH_DEBOUNCE_MS } from "@bucketco/shared/constants";
import {
  ReleaseDetailsDTO,
  ReleasesQuery,
  ReleasesQuerySortBy,
} from "@bucketco/shared/releaseAPI";
import { ReleaseUrl } from "@bucketco/shared/urls";
import { NonOptional } from "@bucketco/shared/utils/types";

import { useAuthContext } from "@/auth/contexts/authContext";
import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import { DataTable } from "@/common/components/DataTable";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import SearchInput from "@/common/components/SearchInput";
import TablePagination from "@/common/components/TablePagination";
import TimestampCell from "@/common/components/TimestampCell";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import useDataTable from "@/common/hooks/useDataTable";
import { useDebounce } from "@/common/hooks/useDebounce";
import { useLocalStorageTableConfiguration } from "@/common/hooks/useLocalStorageTableConfiguration";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import { createSortableColumnHelper } from "@/common/types/reactTableHelpers";
import api from "@/common/utils/api";
import dayjs from "@/common/utils/dayjs";
import { linkClickModifierHandler } from "@/common/utils/linkClickHandler";
import pluralize from "@/common/utils/pluralize";
import GoalCell from "@/release/components/GoalCell";
import ReleasesEmptyState from "@/release/components/ReleasesEmptyState";
import { ReleasesIcon } from "@/release/components/ReleasesIcon";
import {
  ReleaseStatusIcon,
  ReleaseStatusText,
} from "@/release/components/ReleaseStatusIndicator";
import { releasesQueryKeys } from "@/release/data/releasesQueryKeys";

const sortBy = {
  id: "releasedAt",
  desc: true,
} as const;

export const ReleasesTable = () => {
  const navigate = useNavigate();
  const { currentEnv } = useAuthContext();
  const { appId } = useCurrentEnv();
  const tableConfiguration = useLocalStorageTableConfiguration("Releases", {
    defaultColumns: [],
    defaultSort: sortBy,
  });
  const [searchQuery, setSearchQuery] = useSearchParam("q", { fallback: "" });
  const debouncedSearch = useDebounce(searchQuery, SEARCH_DEBOUNCE_MS);

  const table = useDataTable<
    ReleaseDetailsDTO,
    NonOptional<ReleasesQuery, "sortBy">,
    { idNameFilter?: string | undefined }
  >({
    apiCacheKey: releasesQueryKeys.list(appId, {
      idNameFilter: debouncedSearch,
    }),
    apiHandler: (params) => async () => {
      const res = await api.get<`/apps/:appId/releases`>(
        `/apps/${appId}/releases`,
        {
          params: { ...params, idNameFilter: debouncedSearch },
        },
      );
      return res.data;
    },
    defaultQueryParams: {
      sortBy: (tableConfiguration.sort.id as ReleasesQuerySortBy) ?? sortBy.id,
      sortOrder: tableConfiguration.sort.desc ?? sortBy.desc ? "desc" : "asc",
      pageSize: 20,
      pageIndex: 0,
      idNameFilter: debouncedSearch,
    },
    apiOptions: {
      enabled: !!appId,
    },
  });

  const columnHelper = createSortableColumnHelper<
    ReleaseDetailsDTO,
    ReleasesQuerySortBy
  >();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("name", {
        header: "Name",
        cell: (cell) => {
          const name = cell.getValue();
          return (
            <HStack spacing={2}>
              <Box color="dimmed">
                <ReleasesIcon height="15px" width="15px" />
              </Box>
              <Text>{name}</Text>
            </HStack>
          );
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        enableSorting: false,
        cell: function Cell(cell) {
          return (
            <HStack>
              <ReleaseStatusIcon status={cell.getValue()} />
              <ReleaseStatusText fontSize="sm" status={cell.getValue()} />
            </HStack>
          );
        },
      }),
      columnHelper.accessor("goals", {
        header: "Goals",
        enableSorting: false,
        cell: function Cell(cell) {
          const goals = cell.getValue();
          if (!goals.length) return <NotAvailableCell />;

          return <GoalCell goals={goals} max={4} maxWithDescription={2} />;
        },
      }),
      columnHelper.accessor("releasedAt", {
        header: "Release date",
        sortDescFirst: true,
        cell: (cell) => {
          const releasedAt = cell.getValue();
          if (!releasedAt) return <NotAvailableCell />;
          return (
            <HStack spacing={1.5}>
              <Box color="dimmed">
                <RiCalendarCheckLine size="16" />
              </Box>
              <TimestampCell nearestUnit="day" value={releasedAt} />
            </HStack>
          );
        },
      }),
      columnHelper.accessor("evaluationPeriod", {
        header: "Evaluation period",
        cell: (cell) => {
          const evaluationPeriod = cell.getValue();
          const diff =
            dayjs
              .utc(cell.row.original.releasedAt)
              .add(evaluationPeriod ?? 0, "days")
              .diff(dayjs.utc(), "days") + 1;
          const daysLeft =
            diff > 0 ? `${diff} ${pluralize("day", diff)} left` : "Ends today";
          return (
            <HStack fontSize="sm">
              <Text>
                {evaluationPeriod} {pluralize("day", evaluationPeriod)}
              </Text>
              {cell.row.original.status === "evaluating" && (
                <HStack color="dimmed" spacing={1.5}>
                  <RiTimeLine size="16" />
                  <Text>{daysLeft}</Text>
                </HStack>
              )}
            </HStack>
          );
        },
      }),
    ];
  }, [columnHelper]);

  const releases = table.data?.data ?? [];
  const hasFilter = !!table.data?.metadata.idNameFilter;

  return (
    <Flex direction="column" flex="1 1 auto" width="100%">
      <Flex justify={"space-between"} px={6} py={3}>
        <Flex align="center" gap={4}>
          <SearchInput
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.currentTarget.value)}
          />
          <AnimatedSpinner color="dimmed" show={table.isLoading} size="sm" />
        </Flex>
        <TablePagination
          canPaginate={table.canPaginate}
          label="Entries"
          pageCount={table.pageCount}
          pageIndex={table.data?.pageIndex}
          pageSize={table.data?.pageSize}
          paginateActions={table.paginateActions}
          totalCount={table.data?.totalCount}
        />
      </Flex>
      <Divider />
      {releases.length === 0 ? (
        <ReleasesEmptyState
          isFiltered={hasFilter}
          isLoading={table.isLoading}
        />
      ) : (
        <DataTable
          {...omit(table, [
            "data",
            "isLoading",
            "isPreviousData",
            "canPaginate",
            "paginateActions",
          ])}
          columns={columns}
          columnStates={tableConfiguration.columns}
          data={releases}
          rowOnClick={(row, e) => {
            const url = ReleaseUrl(currentEnv!, row.original.id);
            e.preventDefault();

            linkClickModifierHandler(url, () => navigate(url))(e);
          }}
          setColumnStates={tableConfiguration.setColumns}
          setSortBy={tableConfiguration.setSort}
          sortBy={tableConfiguration.sort}
          sx={{
            td: {
              verticalAlign: "middle",
            },
          }}
          trackingId="releases"
          variant="clickable"
        />
      )}
    </Flex>
  );
};
