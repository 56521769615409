import { useQuery } from "@tanstack/react-query";

import { useAuthContext } from "@/auth/contexts/authContext";
import api from "@/common/utils/api";
import flagsQueryKeys from "@/flags/data/flagsQueryKeys";

export function useFlagVersionsData(flagId?: string) {
  const { currentApp, currentEnv } = useAuthContext();

  return useQuery({
    queryKey: flagsQueryKeys.versions(currentApp?.id, flagId),
    queryFn: () =>
      api
        .get<"/apps/:appId/flags/:flagId/versions">(
          `/apps/${currentApp?.id}/flags/${flagId}/versions`,
          {
            params: {
              sortOrder: "desc",
              pageSize: 100,
              pageIndex: 0,
              envId: currentEnv!.id,
            },
          },
        )
        .then((res) => res.data),
    enabled: !!currentApp && !!currentEnv && !!flagId,
  });
}
