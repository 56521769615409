import { RiFileCopyLine } from "react-icons/ri";
import {
  BoxProps,
  IconButton,
  Input,
  InputGroup,
  Tooltip,
  useClipboard,
  useColorModeValue,
} from "@chakra-ui/react";

import { Flag } from "@bucketco/shared/flagAPI";

export function FlagKeyCopyDisplay({
  flag,
  ...boxProps
}: { flag: Flag } & BoxProps) {
  const { hasCopied, onCopy } = useClipboard(flag.key);

  return (
    <InputGroup w="auto" {...boxProps} display="flex" gap={2}>
      <Input
        bg={useColorModeValue("gray.50", "gray.800")}
        flex="0 1 auto"
        minW="0"
        value={flag.key}
        isReadOnly
        onClick={(e) => {
          e.currentTarget.setSelectionRange(0, e.currentTarget.value.length);
        }}
      />
      <Tooltip
        closeOnClick={false}
        label={hasCopied ? "Copied!" : "Copy to clipboard"}
      >
        <IconButton
          aria-label="Copy to clipboard"
          borderRadius={"md"}
          flex="0 0 auto"
          icon={<RiFileCopyLine />}
          p={0}
          size={"sm"}
          variant="outline"
          onClick={onCopy}
        />
      </Tooltip>
    </InputGroup>
  );
}
