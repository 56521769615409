import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { ReleaseDetailsDTO } from "@bucketco/shared/releaseAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { releasesQueryKeys } from "@/release/data/releasesQueryKeys";

export function useReleaseRefreshMutation() {
  const { appId } = useCurrentEnv();
  const queryClient = useQueryClient();

  return useMutation<
    ReleaseDetailsDTO,
    AxiosResponse<ErrorResponse>,
    { releaseId: string }
  >({
    mutationKey: releasesQueryKeys.list(appId),
    mutationFn: ({ releaseId }) =>
      api
        .post<"/apps/:appId/releases/:releaseId/refresh">(
          `/apps/${appId}/releases/${releaseId}/refresh`,
        )
        .then((res) => res.data.release),
    onSuccess(_, { releaseId }) {
      queryClient.invalidateQueries({
        queryKey: releasesQueryKeys.single(appId, releaseId),
      });
    },
  });
}
