import { useRef } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { FeatureDetail } from "@bucketco/shared/featureAPI";
import { FeaturesUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import AffectedDependencies from "@/common/components/AffectedDependencies";
import { useCanDeleteFeature } from "@/common/data/useFilterCheck";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { featureQueryKeys } from "@/feature/data/featureQueryKeys";

export function SettingsDelete({ feature }: { feature: FeatureDetail }) {
  const { appId } = useCurrentEnv();
  const { currentEnv } = useAuthContext();
  const { id: featureId } = feature;

  const toast = useToast();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  const navigate = useNavigate();

  const { mutate: deleteFeature, isPending: isDeleteLoading } = useMutation({
    mutationFn: () =>
      api.delete<"/apps/:appId/features/:featureId">(
        `/apps/${appId}/features/${featureId}`,
      ),

    retry: 0,

    onSuccess: async () => {
      segmentAnalytics.track("Feature Deleted");
      toast({
        title: "Feature deleted",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      queryClient.invalidateQueries({
        queryKey: featureQueryKeys.listNames(appId),
      });
      queryClient.cancelQueries({
        queryKey: featureQueryKeys.single(appId, featureId),
      });
      queryClient.invalidateQueries({ queryKey: featureQueryKeys.list(appId) });
      navigate(FeaturesUrl(currentEnv!));
    },

    onError: () => {
      errorToast({
        description: "Couldn't delete feature",
      });
    },
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { canDelete, isLoading: loadingCanDelete } = useCanDeleteFeature(
    isOpen ? feature.id : undefined,
  );

  const cancelRef = useRef(null);

  const buttonTextColor = useColorModeValue("red.500", "red.400");

  return (
    <>
      <Button
        color={buttonTextColor}
        isDisabled={isDeleteLoading}
        leftIcon={<RiDeleteBinLine size={16} />}
        loadingText="Deleting..."
        type="submit"
        variant="outline"
        onClick={onOpen}
      >
        Delete feature
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Delete feature</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete <strong>{feature?.name}</strong> ?
              <Text mt={2}>
                No underlying tracking data will be deleted (events, company
                attributes etc.), but you can&apos;t undo this action
                afterwards.
              </Text>
              <Text mt={2}>
                Any user feedback that was collected for this feature{" "}
                <strong>will</strong> be deleted.
              </Text>
              {isOpen && (
                <AffectedDependencies
                  changeType="delete"
                  dependee={{
                    type: "feature",
                    id: feature.id,
                  }}
                  showAffectEnvironments
                />
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              <ButtonGroup>
                <Button
                  ref={cancelRef}
                  _hover={{ color: useColorModeValue("gray.700", "gray.300") }}
                  color="gray.500"
                  isDisabled={isDeleteLoading}
                  variant="ghost"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  isDisabled={!canDelete}
                  isLoading={isDeleteLoading || loadingCanDelete}
                  loadingText={isDeleteLoading ? "Deleting" : undefined}
                  onClick={() => deleteFeature()}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
