import { ReactNode } from "react";
import { Heading, HeadingProps, HStack } from "@chakra-ui/react";

type Props = HeadingProps & {
  actions?: ReactNode;
};

export const SectionHeading = ({ actions, ...rest }: Props) => (
  <HStack as="header" justify="space-between">
    <Heading
      color="dimmed"
      fontSize="md"
      fontWeight="medium"
      mb={2}
      {...rest}
    />
    {actions}
  </HStack>
);
