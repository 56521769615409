import { useEffect, useRef } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Spinner,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  Flag,
  PatchFlagArgsSchema,
  PatchFlagArgsType,
} from "@bucketco/shared/flagAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import FormInput from "@/common/components/form/FormInput";
import { FormRootError } from "@/common/components/form/FormRootError";
import FormSubmit from "@/common/components/form/FormSubmit";
import SettingSection from "@/common/components/SettingSection";
import useApiForm from "@/common/hooks/useApiForm";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import flagsQueryKeys from "@/flags/data/flagsQueryKeys";
import { useFlagData } from "@/flags/data/useFlagData";

import { FlagKeyCopyDisplay } from "./FlagKeyCopyDisplay";
import FlagSlackSettings from "./FlagSlackSettings";

export default function FlagSettings() {
  const { flagId } = useParams();
  const { data: flag } = useFlagData(flagId);

  useEffect(() => {
    segmentAnalytics.page("Feature Flag Settings");
  });

  if (!flag) {
    return <Spinner color="dimmed" size="sm" />;
  }
  return <Settings flag={flag} />;
}

function Settings({ flag }: { flag: Flag }) {
  return (
    <Flex direction="column" gap={4} padding={6}>
      <SettingSection
        description="Flag keys are unique and cannot be changed after creation."
        title="Flag config"
      >
        <FlagGeneral flag={flag} />
      </SettingSection>

      <Divider my={8} />
      <SettingSection
        description="Deleting flag won't delete any of the feature or release data."
        title="Delete flag"
      >
        <FlagDelete flag={flag} />
      </SettingSection>
    </Flex>
  );
}

function FlagGeneral({ flag }: { flag: Flag }) {
  const queryClient = useQueryClient();
  const { currentApp } = useAuthContext();
  const toast = useToast();

  const { form, handleSubmit } = useApiForm(
    async (formData: PatchFlagArgsType) => {
      return api
        .patch<"/apps/:appId/flags/:flagId">(
          `/apps/${currentApp?.id}/flags/${flag.id}`,
          formData,
        )
        .then((response) => response.data);
    },
    PatchFlagArgsSchema,
    {
      onSuccess: ({ flag }) => {
        toast({
          title: "Flag settings saved",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        queryClient.setQueryData(
          flagsQueryKeys.single(currentApp?.id, flag.id),
          flag,
        );

        const {
          description,
          slackChannelId,
          slackChannelName,
          slackNotificationsEnabled,
        } = flag;

        form.reset({
          description,
          slackChannelId,
          slackChannelName,
          slackNotificationsEnabled,
        });
      },
    },
    {
      mode: "onChange",
      shouldUnregister: false,
      defaultValues: {
        description: flag.description,
        slackChannelId: flag.slackChannelId,
        slackChannelName: flag.slackChannelName,
        slackNotificationsEnabled: flag.slackNotificationsEnabled,
      },
    },
  );

  return (
    <FormProvider {...form}>
      <form id="flag-general" onSubmit={handleSubmit}>
        <Flex direction="column" gap={4} w="sm">
          <FormControl>
            <FormLabel>Flag key</FormLabel>
            <FlagKeyCopyDisplay flag={flag} />
          </FormControl>

          <FormInput
            label="Description"
            name="description"
            placeholder="Ex. Send a chat message"
          />
          <FlagSlackSettings />

          <FormRootError />
        </Flex>

        <ButtonGroup pt={4}>
          <FormSubmit form="flag-general">Save</FormSubmit>
        </ButtonGroup>
      </form>
    </FormProvider>
  );
}

function FlagDelete({ flag }: { flag: Flag }) {
  const { currentApp } = useAuthContext();

  const toast = useToast();
  const errorToast = useErrorToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  const { mutate: deleteFlag, isPending: isDeleteLoading } = useMutation({
    mutationFn: () =>
      api.delete<"/apps/:appId/flags/:flagId">(
        `/apps/${currentApp?.id}/flags/${flag.id}`,
      ),

    retry: 0,

    onSuccess: async () => {
      toast({
        title: "Flag deleted",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      queryClient.invalidateQueries({
        queryKey: flagsQueryKeys.list(currentApp?.id),
        exact: true,
      });
      queryClient.cancelQueries({
        queryKey: flagsQueryKeys.single(currentApp?.id, flag.id),
      });
      navigate("../../flags");
    },

    onError: () => {
      errorToast({
        description: "Couldn't delete flag",
      });
    },
  });

  return (
    <>
      <Button
        colorScheme="red"
        isDisabled={isDeleteLoading}
        loadingText="Deleting..."
        type="submit"
        variant="solid"
        onClick={onOpen}
      >
        Delete flag
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Delete flag</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete <strong>{flag.key}</strong> ?
            </AlertDialogBody>
            <AlertDialogFooter>
              <ButtonGroup isDisabled={isDeleteLoading}>
                <Button
                  ref={cancelRef}
                  _hover={{
                    color: useColorModeValue("gray.700", "gray.300"),
                  }}
                  color="gray.500"
                  variant="ghost"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  isLoading={isDeleteLoading}
                  loadingText={isDeleteLoading ? "Deleting" : undefined}
                  onClick={() => deleteFlag()}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
