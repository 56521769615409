import {
  FeatureCompaniesQueryType,
  FeatureNamesQueryType,
} from "@bucketco/shared/featureAPI";

import featureViewsQueryKeys from "@/app/data/featureViewsQueryKeys";

const qk = {
  list: (appId?: string, params?: Record<string, any>) =>
    ["apps", appId, "features", params] as const,

  listNames: (appId?: string, params: FeatureNamesQueryType = {}) =>
    ["apps", appId, "names", params] as const,

  listEnv: (appId?: string, envId?: string) =>
    ["apps", appId, "environments", envId, "features"] as const,

  listFilteredCompare: (
    appId?: string,
    envId?: string,
    params?: Record<string, any>,
  ) => [...qk.listEnv(appId, envId), "compare", params] as const,

  singleEnv: (appId?: string, envId?: string, featureId?: string) =>
    [...qk.single(appId, featureId), "environments", envId] as const,

  listMetricsHistorical: (
    appId?: string,
    envId?: string,
    params?: Record<string, any>,
  ) => [...qk.listEnv(appId, envId), "metrics", "historical", params] as const,

  listMetricsHistoricalCompare: (
    appId?: string,
    envId?: string,
    params?: Record<string, any>,
  ) => [...qk.listMetricsHistorical(appId, envId, params), "compare"] as const,

  single: (appId?: string, featureId?: string) =>
    [...qk.list(appId), featureId] as const,

  singleFeatureFeedbackCampaign: (
    appId?: string,
    envId?: string,
    featureId?: string,
  ) =>
    [
      ...qk.singleEnv(appId, envId, featureId),
      "featureFeedbackCampaigns",
    ] as const,

  singleFeatureActiveGoals: (appId?: string, featureId?: string) =>
    [...qk.single(appId, featureId), "featureActiveGoals"] as const,

  singleMetrics: (appId?: string, envId?: string, featureId?: string) =>
    [...qk.singleEnv(appId, envId, featureId), "metrics"] as const,

  singleMetricsCurrent: (
    appId?: string,
    envId?: string,
    featureId?: string,
    params?: Record<string, any>,
  ) =>
    [...qk.singleMetrics(appId, envId, featureId), "current", params] as const,

  singleMetricsHistorical: (
    appId?: string,
    envId?: string,
    featureId?: string,
    params?: Record<string, any>,
  ) =>
    [
      ...qk.singleMetrics(appId, envId, featureId),
      "historical",
      params,
    ] as const,

  singleMetricsHistoricalFrequency: (
    appId?: string,
    envId?: string,
    featureId?: string,
    params?: Record<string, any>,
  ) =>
    [
      ...qk.singleMetrics(appId, envId, featureId),
      "frequency",
      params,
    ] as const,

  singleFeatureCompanies: (
    appId?: string,
    envId?: string,
    featureId?: string,
    params?: Record<string, any>,
  ) => [...qk.singleEnv(appId, envId, featureId), "companies", params] as const,

  singleFeatureCompanyUsers: (
    appId?: string,
    envId?: string,
    featureId?: string,
    companyId?: string,
  ) => [
    ...qk.singleFeatureCompanies(appId, envId, featureId),
    companyId,
    "users",
  ],

  singleFeatureFeedbackHistogram: (
    appId?: string,
    envId?: string,
    featureId?: string,
    params?: Record<string, any>,
  ) =>
    [
      ...qk.singleEnv(appId, envId, featureId),
      "feedback",
      "histogram",
      params,
    ] as const,

  singleFeatureHasData: (appId?: string, envId?: string, featureId?: string) =>
    [...qk.singleEnv(appId, envId, featureId), "hasData"] as const,

  singleFeatureCompaniesWithParams: (
    appId?: string,
    envId?: string,
    featureId?: string,
    params?: Omit<FeatureCompaniesQueryType, "envId">,
  ) => [...qk.singleFeatureCompanies(appId, envId, featureId), params] as const,

  featureViews: (appId?: string, featureId?: string) =>
    [
      ...featureViewsQueryKeys.singleFeatureRelationViews(appId),
      featureId,
    ] as const,

  singleFeatureTrackingHealth: (
    appId?: string,
    envId?: string,
    featureId?: string,
    dataStatus?: string,
  ) =>
    [
      ...qk.singleEnv(appId, envId, featureId),
      "trackingHealth",
      dataStatus,
    ] as const,
};

export default qk;
