import { ReactNode, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import { SectionHeading } from "@/common/components/SectionHeading";
import SidebarLayout from "@/common/components/SidebarLayout";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { CompaniesSnippet } from "@/feature-legacy/components/CompaniesSnippet";
import EvaluationTimeline from "@/feature-legacy/components/EvaluationTimeline";
import FeedbackSample from "@/feature-legacy/components/FeedbackSample";
import FrequencyHistogram from "@/feature-legacy/components/FrequencyHistogram";
import HistoricalFrequency from "@/feature-legacy/components/HistoricalFrequency";
import { ReportEmptyState } from "@/feature-legacy/components/ReportEmptyState";
import ReportSidebar from "@/feature-legacy/components/ReportSidebar";
import SatisfactionHistogram from "@/feature-legacy/components/SatisfactionHistogram";
import StarsFunnel from "@/feature-legacy/components/StarsFunnel";
import { useFeatureContext } from "@/feature-legacy/contexts/featureContext";
import { SatisfactionTimeline } from "@/feedback/components/SatisfactionTimeline";

const SPACING_BASE = 6;
const SPACING_WIDE = 8;
const RESPONSIVE_SPACING = { base: SPACING_BASE, "3xl": SPACING_WIDE };

function Group({ children }: { children?: ReactNode }) {
  return (
    <Flex as="section" flexDirection="column" gap={RESPONSIVE_SPACING}>
      {children}
    </Flex>
  );
}

function GroupBar({ children }: { children?: ReactNode }) {
  return (
    <HStack
      as="header"
      bg={useColorModeValue("gray.25", "gray.800")}
      borderBottomWidth="1px"
      borderColor={useColorModeValue("gray.100", "gray.700")}
      borderLeftWidth={{ "3xl": "1px" }}
      borderRadius={{ "3xl": "md" }}
      borderRightWidth={{ "3xl": "1px" }}
      borderStyle="solid"
      borderTopWidth="1px"
      mx={{ base: -6, "3xl": -4 }}
      px={{ base: 6, "3xl": 4 }}
      py={3}
    >
      {children}
    </HStack>
  );
}

function GroupHeading({ children }: { children?: ReactNode }) {
  return (
    <Heading as="h2" fontSize="lg" fontWeight="medium">
      {children}
    </Heading>
  );
}

export default function FeatureReport() {
  const { featureId } = useParams();
  const { relevantMetrics, feature } = useFeatureContext();
  const [subsegmentIds] = useSubsegmentParam();

  useEffect(() => {
    if (feature) {
      segmentAnalytics.page("Feature Overview", {
        source: feature.source,
      });
      // add explicit events for each source until we have support
      // for filtering on event attributes
      if (feature.source === "attribute") {
        segmentAnalytics.page("Feature Overview (attribute-based)");
      }
      if (feature.source === "event") {
        segmentAnalytics.page("Feature Overview (event-based)");
      }
    }
  }, [feature]);

  return (
    <SidebarLayout flexGrow={1} sidebarContent={<ReportSidebar />}>
      <Flex flexGrow={1} w="100%">
        <ReportEmptyState>
          <Flex
            direction="column"
            gap={RESPONSIVE_SPACING}
            maxW="7xl"
            mx="auto"
            p={RESPONSIVE_SPACING}
            pt={{ base: 0, "3xl": 4 }}
            w="100%"
          >
            <Group>
              <GroupBar>
                <GroupHeading>STARS</GroupHeading>
                <InfoIconTooltip
                  docsURL="https://docs.bucket.co/introduction/concepts/feature/stars"
                  text={
                    <>
                      <Text mb={2}>
                        The STARS framework stands for Segment, Tried, Adopted,
                        Retained, and Satisfied, and it represents an engagement
                        funnel that&apos;s used to evaluate how a company has
                        interacted with a feature.
                      </Text>
                      <Text>
                        The framework is based on quantitative engagement data
                        and qualitative satisfaction scoring.
                      </Text>
                    </>
                  }
                />
              </GroupBar>
              <StarsFunnel />
              <EvaluationTimeline />
              <SimpleGrid
                columns={{ base: 2, "2xl": 3 }}
                spacing={RESPONSIVE_SPACING}
              >
                <CompaniesSnippet
                  configKey="tried"
                  isDisabled={!relevantMetrics.includes("tried")}
                />
                <CompaniesSnippet configKey="retained" />
                <CompaniesSnippet
                  configKey="churned"
                  display={{ base: "none", "2xl": "flex" }}
                  isDisabled={!relevantMetrics.includes("retained")}
                />
              </SimpleGrid>
            </Group>
            <Group>
              <GroupBar>
                <GroupHeading>Feedback & satisfaction</GroupHeading>
              </GroupBar>
              <Grid gap={RESPONSIVE_SPACING} templateColumns={"1fr 1fr 1fr"}>
                <GridItem colSpan={2}>
                  <SatisfactionTimeline
                    gap={2}
                    h="full"
                    queryBase={{
                      featureIds: [featureId!],
                      subsegment: subsegmentIds[0],
                    }}
                  />
                </GridItem>
                <GridItem as={Flex} direction="column" gap={4}>
                  <SectionHeading>Distribution</SectionHeading>
                  <Text color="dimmed" fontSize="sm">
                    Score distribution for all companies
                  </Text>
                  <SatisfactionHistogram />
                </GridItem>
              </Grid>
              <Flex direction="column" gap={4}>
                <FeedbackSample
                  amount={6}
                  columns={3}
                  flex={"1 1 auto"}
                  gap={{ base: SPACING_BASE / 2, "3xl": SPACING_WIDE / 2 }}
                />
              </Flex>
            </Group>
            <Group>
              {relevantMetrics.includes("frequency") && (
                <>
                  <GroupBar>
                    <GroupHeading>Usage frequency</GroupHeading>
                    <InfoIconTooltip
                      docsURL="https://docs.bucket.co/introduction/concepts/feature/frequency"
                      text={
                        <>
                          <Text mb={2}>
                            Shows how often retained companies are using this
                            feature, on average.
                          </Text>
                          <Text>Target audience is calculated per week.</Text>
                        </>
                      }
                    />
                  </GroupBar>
                  <Grid
                    gap={RESPONSIVE_SPACING}
                    templateColumns={"1fr 1fr 1fr"}
                  >
                    <GridItem colSpan={2}>
                      <HistoricalFrequency />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <FrequencyHistogram />
                    </GridItem>
                  </Grid>
                </>
              )}
            </Group>
          </Flex>
        </ReportEmptyState>
      </Flex>
    </SidebarLayout>
  );
}
