import { useMemo } from "react";
import {
  ButtonGroup,
  Center,
  Flex,
  HStack,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { WidgetConfigurationType } from "@bucketco/shared/widgetAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import EmptyStateDark from "@/common/assets/empty-state-target-dark.svg?react";
import EmptyStateLight from "@/common/assets/empty-state-target-light.svg?react";
import EmptyState from "@/common/components/EmptyState";
import PeriodPicker, {
  Period,
  periodList,
} from "@/common/components/PeriodPicker";
import { SubsegmentPicker } from "@/common/components/SubsegmentPicker";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { FeatureWidgetCreator } from "@/feature/components/FeatureWidgetCreator";
import { UseTargetingSwitch } from "@/feature/components/UseTargetingSwitch";
import { Widget } from "@/widget/components/Widget";
import { useWidgetsData } from "@/widget/data/useWidgetData";

type WidgetsProps = {
  featureId: string;
};

export function FeatureWidgets({ featureId }: WidgetsProps) {
  const { currentEnv } = useAuthContext();
  const [period, setPeriod] = useSearchParam<Period>("period", {
    allowlist: periodList,
    fallback: "past30days",
  });
  const { data: widgets = [], isLoading } = useWidgetsData({
    featureId,
  });

  const allowedTypes = useMemo<WidgetConfigurationType[]>(() => {
    return [
      "featureMetric",
      !widgets.some(({ configuration: { type } }) => type === "starsFunnel") &&
        "starsFunnel",
      !widgets.some(({ configuration: { type } }) => type === "feedback") &&
        "feedback",
    ].filter(Boolean) as WidgetConfigurationType[];
  }, [widgets]);

  const EmptyStateIllustration = useColorModeValue(
    EmptyStateLight,
    EmptyStateDark,
  );

  if (isLoading) {
    return (
      <Center flexGrow={1}>
        <Spinner color="dimmed" size="sm" />
      </Center>
    );
  }

  if (widgets.length === 0) {
    return (
      <Center flexGrow={1}>
        <EmptyState
          action={
            <FeatureWidgetCreator
              allowedTypes={allowedTypes}
              featureId={featureId}
            />
          }
          description={
            <Text color="dimmed" fontSize="sm">
              Tailor your evaluation and add a widget to display data for this
              feature according to your specific needs.
            </Text>
          }
          eyebrow={<EmptyStateIllustration />}
          title="No widgets added"
        />
      </Center>
    );
  }

  return (
    <Flex direction="column" gap={4}>
      <Flex align="center" justify="space-between">
        <ButtonGroup spacing={4}>
          <UseTargetingSwitch />
          <SubsegmentPicker />
          <PeriodPicker
            value={period}
            onChange={(newPeriod) => setPeriod(newPeriod, { replace: true })}
          />
          <HStack fontSize="sm" spacing={1}>
            <Text color="dimmed">Data shown for</Text>
            {currentEnv && <EnvironmentDisplayName environment={currentEnv} />}
          </HStack>
        </ButtonGroup>
        <ButtonGroup>
          <FeatureWidgetCreator
            allowedTypes={allowedTypes}
            featureId={featureId}
          />
        </ButtonGroup>
      </Flex>
      <Flex direction={"column"} gap={3} w="full">
        {widgets.map((widget) => (
          <Widget
            key={widget.id}
            implicitConfiguration={{
              featureId,
            }}
            widget={widget}
          />
        ))}
      </Flex>
    </Flex>
  );
}
