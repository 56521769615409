import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Box, Divider, Flex, useToken } from "@chakra-ui/react";
import { useIsFetching } from "@tanstack/react-query";
import dayjs from "dayjs";

import { SCREENSHOT_SELECTOR_ID } from "@bucketco/shared/screenshot";

import EvaluationChart, {
  EvaluationChartLegend,
} from "@/common/charts/components/EvaluationChart";
import StarsFunnel from "@/feature-legacy/components/StarsFunnel";
import { FeatureProvider } from "@/feature-legacy/contexts/featureContext";
import useEvaluationComboChartData from "@/feature-legacy/data/useEvaluationComboChartData";

function WeeklyAdoption() {
  const { featureId } = useParams();

  const endDate = useMemo(() => {
    return dayjs().utc().startOf("week").subtract(1, "week").toDate();
  }, []);

  const startDate = useMemo(() => {
    return dayjs(endDate).startOf("week").subtract(6, "weeks").toDate();
  }, [endDate]);

  const { data, isLoading, isError, dataKeys, xAxis, barYAxis, lineYAxis } =
    useEvaluationComboChartData(
      featureId,
      {
        startDate,
        endDate,
        granularity: "weekly",
      },
      {
        chartWidth: 1000,
      },
    );
  const [axisLabelFontSize] = useToken("fontSizes", ["md"]);

  if (isError) return null;

  return (
    <Flex
      direction="column"
      gap={6}
      id={SCREENSHOT_SELECTOR_ID}
      maxW="6xl"
      mx="auto"
      p={6}
    >
      <StarsFunnel isAnimated={false} isInteractive={false} />
      <Divider mx={-6} my={0} w="auto" />
      <Box>
        <EvaluationChart
          axisLabelFontSize={axisLabelFontSize}
          barYAxis={barYAxis}
          data={data}
          dataKeys={dataKeys}
          isAnimationActive={false}
          isLoading={isLoading}
          lineYAxis={lineYAxis}
          xAxis={xAxis}
        />
        <Flex justifyContent="flex-end" mr={4}>
          <EvaluationChartLegend fontSize="lg" />
        </Flex>
      </Box>
    </Flex>
  );
}

export default function FeatureAdoptionScreenshot() {
  const isFetching = useIsFetching();

  return (
    <Box aria-busy={isFetching > 0}>
      <FeatureProvider>
        <WeeklyAdoption />
      </FeatureProvider>
    </Box>
  );
}
