import { forwardRef } from "react";
import { RiBracesLine, RiCloseLine } from "react-icons/ri";
import {
  HStack,
  IconButton,
  Tag,
  TagLabelProps,
  TagProps,
  useColorModeValue,
} from "@chakra-ui/react";

import { UIFilter } from "@bucketco/shared/filter";

import { FeatureTargetingFilterItem } from "@/common/components/filters/FeatureTargetingFilterItem";

import { AttributeFilterItem } from "./AttributeFilterItem";
import { CompanyFeatureMetricFilterItem } from "./CompanyFeatureMetricFilterItem";
import { EmptyFilterItem } from "./EmptyFilterItem";
import { SegmentFilterItem } from "./SegmentFilterItem";

export const FilterItemTagLabel = ({
  filter,
  showIcon = true,
  showPrefix = false,
  ...rest
}: Omit<TagLabelProps, "filter"> & {
  filter: UIFilter | undefined;
  showPrefix?: boolean;
  showIcon?: boolean;
}) => {
  return !filter ? (
    <EmptyFilterItem showIcon={showIcon} {...rest} />
  ) : filter.type === "companyAttribute" ? (
    <AttributeFilterItem
      filter={filter}
      icon={showIcon ? RiBracesLine : undefined}
      prefix={showPrefix ? "company" : undefined}
      {...rest}
    />
  ) : filter.type === "otherContext" ? (
    <AttributeFilterItem
      filter={filter}
      icon={showIcon ? RiBracesLine : undefined}
      prefix={showPrefix ? "context" : undefined}
      {...rest}
    />
  ) : filter.type === "userAttribute" ? (
    <AttributeFilterItem
      filter={filter}
      icon={showIcon ? RiBracesLine : undefined}
      prefix={showPrefix ? "user" : undefined}
      {...rest}
    />
  ) : filter.type === "featureMetric" ? (
    <CompanyFeatureMetricFilterItem
      filter={filter}
      showIcon={showIcon}
      {...rest}
    />
  ) : filter.type === "segment" ? (
    <SegmentFilterItem filter={filter} showIcon={showIcon} {...rest} />
  ) : filter.type === "featureTargeting" ? (
    <FeatureTargetingFilterItem filter={filter} {...rest} />
  ) : null;
};

export const FilterItem = forwardRef(
  (
    {
      filter,
      size = "xs",
      showIcon = true,
      showRemove = false,
      showPrefix = false,
      onClick,
      onRemove = () => {},
      ...props
    }: {
      filter: UIFilter | undefined;
      size?: TagProps["size"];
      showPrefix?: boolean;
      showIcon?: boolean;
      showRemove?: boolean;
      onClick?: () => void;
      onRemove?: () => void;
    } & Omit<TagProps, "filter">,
    ref,
  ) => {
    const bg = useColorModeValue("gray.50", "gray.700");
    const hoverBg = useColorModeValue("gray.100", "gray.700");
    const activeBg = useColorModeValue("gray.200", "gray.650");

    return (
      <HStack spacing={0}>
        <Tag
          ref={ref}
          _active={onClick ? { bg: activeBg } : undefined}
          _hover={onClick ? { bg: hoverBg } : undefined}
          bg={bg}
          cursor={onClick ? "pointer" : undefined}
          overflow="hidden"
          pos="relative"
          role="group"
          size={size}
          transitionDuration="var(--chakra-transition-duration-normal)"
          transitionProperty="var(--chakra-transition-property-common)"
          variant="subtle"
          onClick={onClick}
          {...props}
          borderEndRadius={showRemove ? 0 : props.borderEndRadius}
        >
          <FilterItemTagLabel
            filter={filter}
            showIcon={showIcon}
            showPrefix={showPrefix}
          />
        </Tag>
        {showRemove && (
          <IconButton
            _active={{ bg: activeBg }}
            _hover={{ bg: hoverBg }}
            alignItems="center"
            aria-label="Remove filter"
            bg={bg}
            borderBottomLeftRadius={0}
            borderLeft={1}
            borderLeftColor="appBackground"
            borderLeftStyle="solid"
            borderTopLeftRadius={0}
            cursor="pointer"
            display="flex"
            h="8"
            icon={<RiCloseLine size={16} />}
            justifyContent="center"
            m={0}
            variant="ghost"
            w="8"
            onClick={onRemove}
          />
        )}
      </HStack>
    );
  },
);

FilterItem.displayName = "AttributeFilterItem";
