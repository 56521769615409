import { ConfigType, ManipulateType } from "dayjs";

import dayjs from "@/common/utils/dayjs";
import pluralize from "@/common/utils/pluralize";

export const fullFormattedDateTime = (date: ConfigType) =>
  dayjs(date).format("LLLL");

export const fullFormattedDate = (date: ConfigType) =>
  dayjs(date).format("dddd, MMMM D, YYYY");

export const compactFormattedDate = (date: ConfigType) =>
  dayjs(date).format("ddd, ll");

export const epochToWeekNumber = (epoch?: number | string) => {
  if (!epoch || typeof epoch === "string") return "";
  return dayjs.unix(epoch).format("w");
};

export const epochToShortDate = (epoch?: number | string) => {
  if (!epoch || typeof epoch === "string") return "";
  return dayjs.unix(epoch).format("MMM D");
};

export const epochToUtcShortDate = (epoch?: number | string) => {
  if (!epoch || typeof epoch === "string") return "";
  return dayjs.unix(epoch).utc().format("MMM D");
};

export const epochToDate = (epoch?: number | string) => {
  if (!epoch || typeof epoch === "string") return "";
  return dayjs.unix(epoch).format("MMM D, LT");
};

export const epochToUtcDate = (epoch?: number | string) => {
  if (!epoch || typeof epoch === "string") return "";
  return dayjs.unix(epoch).utc().format("MMM D, LT");
};

export const epochToShortDateWeek = (epoch?: number | string) => {
  if (!epoch || typeof epoch === "string") return "";
  return `${dayjs.unix(epoch).format("MMM D")} - ${dayjs
    .unix(epoch)
    .add(6, "days")
    .format("MMM D")}`;
};

export const epochAddWeek = (epoch: number) => epoch + 604800;

export const epochToWeekInterval = (epoch: number) =>
  `${epochToShortDate(epoch)} - ${epochToShortDate(epochAddWeek(epoch))}`;

export const epochToUtcWeekInterval = (epoch: number) =>
  `${epochToUtcShortDate(epoch)} - ${epochToUtcShortDate(epochAddWeek(epoch))}`;

export const epochIsSame = (
  epoch: number,
  unit?: ManipulateType | undefined,
) => {
  const shift = dayjs().utcOffset() >= 0 ? 1 : 0;
  return dayjs.unix(epoch).isSame(dayjs().add(shift, unit), unit);
};

export const daysSinceEpoch = (
  start: string | number | Date | dayjs.Dayjs | null | undefined,
  epoch: number,
) => {
  const diff = dayjs.unix(epoch).diff(dayjs(start), "day");
  return `Day ${diff}`;
};

export const humanizeWeeks = (value: number) => {
  const weeks = Math.trunc(value);
  const days = Math.floor((value % 1) * 7);
  const parts = [];
  if (weeks > 0) {
    parts.push(`${weeks} ${pluralize("week", weeks)}`);
  }
  if (days > 0) {
    parts.push(`${days} ${pluralize("day", days)}`);
  }
  return parts.join(", ");
};
