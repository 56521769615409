import type {
  PartsStyleFunction,
  SystemStyleFunction,
} from "@chakra-ui/theme-tools";
import { mode } from "@chakra-ui/theme-tools";
import { transparentize } from "color2k";

export const focusShadow = "0 0 0 1px rgba(101, 91, 250, 1)";

export const focusStyle = {
  boxShadow: `${focusShadow} !important`,
  borderColor: "transparent !important",
  outline: 0,
};

export const focusResetStyle = {
  boxShadow: null,
  borderColor: null,
  outline: 0,
};

export const buttonSizes = {
  "2xs": {
    h: 6,
    minW: 6,
    fontSize: "xs",
  },
  xs: {
    h: 7,
    minW: 7,
    fontSize: "xs",
  },
  sm: {
    h: 8,
    minW: 8,
    fontSize: "sm",
  },
  md: {
    h: 9,
    minW: 9,
    fontSize: "sm",
  },
  lg: {
    h: 10,
    minW: 10,
    fontSize: "md",
  },
};

export const tagVariantSolid: SystemStyleFunction | PartsStyleFunction = (
  props,
) => {
  const { colorScheme: c } = props;
  return {
    bg: mode(`${c}.500`, `${c}.500`)(props),
    color: mode(`white`, `whiteAlpha.800`)(props),
  };
};

export const tagVariantSubtle: SystemStyleFunction | PartsStyleFunction = (
  props,
) => {
  const { colorScheme: c } = props;
  const color =
    c === "gray"
      ? mode(`${c}.900`, `white`)(props)
      : mode(`${c}.900`, `${c}.50`)(props);
  return {
    bg: mode(`${c}.50`, `${c}.750`)(props),
    color,
    ".chakra-icon": {
      color: mode(`gray.400`, `gray.500`)(props),
    },
  };
};

export const tagVariantOutline: SystemStyleFunction | PartsStyleFunction = (
  props,
) => {
  const { colorScheme: c, theme } = props;
  const darkColor = transparentize(theme.colors[c]["200"], 0.8);
  const lightColor = theme.colors[c]["500"] as string;
  const color = mode(lightColor, darkColor)(props);

  return {
    color,
    boxShadow: `inset 0 0 0px 1px ${color}`,
  };
};

export const tagVariantClickable: SystemStyleFunction | PartsStyleFunction = (
  props,
) => {
  const { colorScheme: c } = props;
  const color =
    c === "gray"
      ? mode(`${c}.900`, `white`)(props)
      : mode(`${c}.900`, `${c}.50`)(props);
  return {
    bg: mode(`${c}.50`, `${c}.750`)(props),
    color,
    ".chakra-icon": {
      color: mode(`gray.400`, `gray.500`)(props),
    },
    _hover: {
      bg: mode(`${c}.100`, `${c}.700`)(props),
    },
    _active: {
      bg: mode(`${c}.150`, `${c}.650`)(props),
    },
  };
};

export const textLink: SystemStyleFunction | PartsStyleFunction = (props) => ({
  textDecoration: "underline",
  textUnderlineOffset: "1px",
  fontWeight: "normal",
  color: "inherit",
  _hover: {
    color: mode("brand.500", "brand.400")(props),
  },
});
