import { useMemo, useState } from "react";
import { ColumnSort } from "@tanstack/react-table";
import equal from "fast-deep-equal";

import { SegmentDTO } from "@bucketco/shared/segmentAPI";
import { ColumnState } from "@bucketco/shared/types/columns";

import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

export default function useSegmentColumnStates(segment: SegmentDTO) {
  const [columnStates, _setColumnStates] = useState<ColumnState[]>(
    segment.columns ?? [],
  );
  const [persistedStates, _setPersistedStates] = useState<ColumnState[]>(
    segment.columns ?? [],
  );

  const [columnSort, _setColumnSort] = useState<ColumnSort>(
    getDefaultColumnSort(segment),
  );
  const [persistedSort, _setPersistedSort] = useState<ColumnSort>(
    getDefaultColumnSort(segment),
  );

  const setColumnStates = (
    nextColumnStates: ColumnState[],
    isPersisted: boolean = false,
  ) => {
    _setColumnStates(nextColumnStates);

    if (isPersisted) {
      _setPersistedStates(nextColumnStates);
    }

    const event = isPersisted ? "Columns saved" : "Columns changed";
    segmentAnalytics.track(event, {
      view: "segment",
    });
  };

  const setColumnSort = (
    nextColumnSort: ColumnSort,
    isPersisted: boolean = false,
  ) => {
    _setColumnSort(nextColumnSort);

    if (isPersisted) {
      _setPersistedSort(nextColumnSort);
    }
  };

  const isColumnStatesDirty = useMemo(() => {
    return (
      !equal(columnStates, persistedStates) || !equal(columnSort, persistedSort)
    );
  }, [columnStates, persistedStates, columnSort, persistedSort]);

  const resetColumns = (states = true, sort = true) => {
    if (states) {
      _setColumnStates(persistedStates);
    }
    if (sort) {
      _setColumnSort(persistedSort);
    }
  };

  return {
    columnStates,
    setColumnStates,
    columnSort,
    setColumnSort,
    isColumnStatesDirty,
    resetColumns,
  };
}

const getDefaultColumnSort = (segment: SegmentDTO) => () => {
  return segment.columnSortKey
    ? {
        id: segment.columnSortKey,
        desc: Boolean(segment.columnSortDesc),
      }
    : {
        id: "lastSeen",
        desc: true,
      };
};
