import { SlackChannel } from "@bucketco/shared/slackConnectionAPI";

const preferredChannelNamesInOrder = ["#product", "#bucket", "#general"];

/**
 * Select a Slack channel that best matches the give criteriae.
 *
 * Optionally supply a Slack channel id
 * to prefer those channels over the built-in defaults.
 */
export function getPreferredSlackChannel(
  channels: Array<SlackChannel>,

  /**
   * Select the Slack channel with this id if it exists.
   *
   * This option takes precedence over defaults.
   */
  preferredChannelId?: string,
): SlackChannel | void {
  if (typeof preferredChannelId === "string") {
    const channel = channels.find((c) => c.id === preferredChannelId);

    if (channel) {
      return channel;
    }
  }

  for (const preferredChannelName of preferredChannelNamesInOrder) {
    const channel = channels.find((c) => c.name === preferredChannelName);

    if (channel) {
      return channel;
    }
  }
}
