import React, { ReactNode } from "react";
import { Link, Text } from "@chakra-ui/react";
import { QueryStatus } from "@tanstack/react-query";

import EmptyState, { EmptyStateProps } from "@/common/components/EmptyState";
import { SectionHeading } from "@/common/components/SectionHeading";
import FeedbackEmptyStateIllustration from "@/feedback/components/FeedbackEmptyStateIllustration";

type Props = Omit<EmptyStateProps, "isLoading"> & {
  status?: QueryStatus;
  children?: ReactNode;
  hasData?: boolean;
};

export const WidgetState = ({ status, hasData, children, ...rest }: Props) => {
  if (status === "success" && hasData) {
    return <>{children}</>;
  }
  return (
    <EmptyState
      description={
        status !== "error" && (
          <Text color="dimmed" fontSize="sm">
            You can start collecting feedback automatically with{" "}
            <Link href="https://docs.bucket.co/product-handbook/automated-feedback-surveys">
              Automated Feedback Surveys
            </Link>{" "}
            or more manually with{" "}
            <Link
              href="https://github.com/bucketco/bucket-tracking-sdk?tab=readme-ov-file#qualitative-feedback"
              target="_blank"
            >
              Bucket SDK feedback
            </Link>
          </Text>
        )
      }
      eyebrow={<FeedbackEmptyStateIllustration />}
      h="full"
      isLoading={status === "pending"}
      title={
        <SectionHeading>
          {status === "error"
            ? "Could not load feedback :("
            : "No feedback yet"}
        </SectionHeading>
      }
      w="full"
      {...rest}
    />
  );
};
