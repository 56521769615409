import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { AttributeField } from "@bucketco/shared/attributeFilter";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import flagsQueryKeys from "@/flags/data/flagsQueryKeys";

const cacheTime = 30 * 1000; // 30 seconds

type PrefixType = "company" | "user" | "other";

export function useFlagContextAttributeNames(prefix?: PrefixType) {
  const { appId, envId } = useCurrentEnv();

  return useQuery({
    queryKey: flagsQueryKeys.attributeNames(appId, prefix),

    queryFn: () =>
      api
        .get<"/apps/:appId/flags/context/keys">(
          `/apps/${appId}/flags/context/keys`,
          { params: { envId: envId!, prefix: prefix! } },
        )
        .then((res) =>
          res.data.map((attr) => {
            const key = attr.substring(attr.indexOf(".") + 1);
            return {
              key,
              label: key,
              type: "text",
              icon: "text",
              system: false,
            } satisfies AttributeField;
          }),
        ),

    gcTime: cacheTime,
    staleTime: cacheTime,
    placeholderData: keepPreviousData,
    enabled: !!appId && !!envId && !!prefix,
  });
}

export function useFlagContextAttributeValues(
  prefix?: PrefixType,
  key?: string,
  filter?: string,
) {
  const { appId, envId } = useCurrentEnv();

  return useQuery({
    queryKey: flagsQueryKeys.attributeKeys(appId, prefix, key),

    queryFn: () =>
      api
        .get<"/apps/:appId/flags/context/values">(
          `/apps/${appId}/flags/context/values`,
          { params: { envId: envId!, key: `${prefix}.${key}`, filter } },
        )
        .then((res) =>
          res.data.map((value: string) => ({ label: value, value })),
        ),

    gcTime: cacheTime,
    staleTime: cacheTime,
    placeholderData: keepPreviousData,
    enabled: !!appId && !!envId && !!prefix && !!key,
  });
}
