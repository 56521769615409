import capitalize from "lodash/capitalize";

import { frequencyNumberToText } from "@bucketco/shared/featureAPI";

import {
  AuditChartAxisMetricKey,
  AXIS_METRICS,
  AxisProps,
} from "@/common/charts/components/AuditChart/types";
import { formatPercentage } from "@/common/utils/numberFormatting";

export function useAxisPropsForMetric(
  metric: AuditChartAxisMetricKey,
): AxisProps {
  if (metric === "averageFrequency") {
    const ticks = [0.5, 1.5, 2.5, 3.5];
    const referenceLines = [1, 2, 3, 4];

    return {
      dataKey: `current.${metric}`,
      name: AXIS_METRICS[metric].label,
      domain: [0, 4],
      ticks,
      referenceLines,
      tickFormatter: (val) => {
        return capitalize(frequencyNumberToText(val - 0.5));
      },
    };
  }

  // All percentage metrics
  const ticks = [0, 0.25, 0.5, 0.75, 1];
  return {
    dataKey: `current.${metric}`,
    name: AXIS_METRICS[metric].label,
    domain: [0, 1],
    ticks,
    referenceLines: ticks,
    tickFormatter: formatPercentage,
  };
}
