import { useMemo, useState } from "react";
import { Box, HStack, Text, useToken } from "@chakra-ui/react";
import clamp from "lodash/clamp";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  SATISFACTION_LABELS,
  SATISFACTION_LABELS_SHORT,
} from "@bucketco/shared/constants";
import { FeedbackTimelineItem } from "@bucketco/shared/feedbackAPI";
import { SatisfactionScore } from "@bucketco/shared/schemas/satisfactionScore";

import {
  IncompleteBarPattern,
  incompleteBarPatternId,
} from "@/common/charts/components/IncompleteBar";
import useChartTokens, {
  useChartColorTokens,
} from "@/common/charts/hooks/useChartTokens";
import { useThrottle } from "@/common/hooks/useThrottle";
import { epochIsSame, epochToShortDate } from "@/common/utils/datetime";
import { formatNumber } from "@/common/utils/numberFormatting";
import {
  FeedbackSatisfactionIcon,
  useSatisfactionColors,
} from "@/feature-legacy/components/FeedbackSatisfaction";

export type SatisfactionAverageTimelineChartDataKeyType = {
  key: string;
  name: string;
  type: "bar" | "line";
  color: keyof useChartColorTokens;
  style?: "solid" | "dashed";
};

export type SatisfactionAverageTimelineChartProps = {
  data: FeedbackTimelineItem[];
  isLoading?: boolean;
  height?: number;
  highlightLatest?: boolean;
  highlightPartialPeriod?: boolean;
  axisLabelFontSize?: string;
  onResize?: (width: number) => void;
};

function isNumber(n: any): n is number {
  return typeof n === "number";
}

export default function SatisfactionAverageTimelineChart({
  data = [],
  isLoading = false,
  height = 300,
  highlightPartialPeriod = false,
  highlightLatest = false,
  axisLabelFontSize,
  onResize,
}: SatisfactionAverageTimelineChartProps) {
  const { colors, fontSizes, radii, sizes, properties } = useChartTokens();
  const axisLabelFontSizeValue = axisLabelFontSize || fontSizes.axisLabel;
  const lastDataPeriod = useMemo<[number, number]>(
    () =>
      data
        .filter((point) => "total" in point)
        .map(({ epoch }) => epoch)
        .reverse()
        .slice(0, 2) as [number, number],
    [data],
  );
  const [width, setWidth] = useState<number>(720);
  useThrottle(width, 500, onResize);

  const scoreGradientName = "scoreGradient";
  const scores: number[] = data.map((d) => d.averageScore).filter(isNumber);

  const minValue = Math.min(...scores);
  const maxValue = Math.max(...scores);

  const scoreDiff = maxValue - minValue;

  const scale = 4 / scoreDiff;
  const offsetY = -1 * ((4 - (maxValue - 1)) / 4);

  const scoreColor: Record<number, string> = {
    0: useToken("colors", useSatisfactionColors(1)),
    1: useToken("colors", useSatisfactionColors(1)),
    2: useToken("colors", useSatisfactionColors(2)),
    3: useToken("colors", useSatisfactionColors(3)),
    4: useToken("colors", useSatisfactionColors(4)),
    5: useToken("colors", useSatisfactionColors(5)),
  };

  return (
    <ResponsiveContainer
      height={height}
      width="100%"
      onResize={(newWidth) => {
        setWidth(newWidth);
      }}
    >
      <LineChart
        data={data}
        margin={{
          top: 24,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <defs>
          {!isLoading && scoreDiff !== 0 && (
            <linearGradient
              gradientTransform={`scale(1, ${scale}) translate(0, ${offsetY})`}
              id={scoreGradientName}
              x2="0"
              y1="1"
              y2="0"
            >
              <stop offset={0} stopColor={scoreColor[1]} />
              <stop offset={0.25} stopColor={scoreColor[2]} />
              <stop offset={0.5} stopColor={scoreColor[3]} />
              <stop offset={0.75} stopColor={scoreColor[4]} />
              <stop offset={1} stopColor={scoreColor[5]} />
            </linearGradient>
          )}
        </defs>
        <CartesianGrid
          stroke={colors.grid}
          strokeDasharray="3"
          vertical={false}
        />
        <XAxis
          axisLine={true}
          dataKey="epoch"
          domain={["dataMin", "dataMax"]}
          fontSize={axisLabelFontSizeValue}
          height={20}
          interval="preserveEnd"
          minTickGap={4}
          scale="utc"
          stroke={colors.axis}
          tickFormatter={(epoch: number, _index: number) => {
            if (epochIsSame(epoch, "day")) return "Now";
            return epochToShortDate(epoch);
          }}
          tickLine={true}
          type="number"
        ></XAxis>
        <YAxis
          axisLine={false}
          dataKey="averageScore"
          domain={[1, 5]}
          fontSize={axisLabelFontSizeValue}
          mirror={false}
          stroke={colors.axis}
          tickFormatter={(value: SatisfactionScore) =>
            SATISFACTION_LABELS_SHORT[value]
          }
          tickLine={false}
          ticks={[1, 2, 3, 4, 5]}
          type="number"
          width={90}
          yAxisId="line"
        ></YAxis>
        {highlightPartialPeriod && data?.length && (
          <>
            <defs>
              <IncompleteBarPattern
                colorName={"partialHighlight"}
                colorValue={colors.partialHighlight}
              />
            </defs>
            <ReferenceArea
              fill={`url(#${incompleteBarPatternId("partialHighlight")})`}
              x1={lastDataPeriod[0]}
              x2={lastDataPeriod[1]}
              yAxisId="line"
            />
          </>
        )}
        {highlightLatest && data?.length && (
          <ReferenceArea
            fill={colors.highlightBg}
            fillOpacity={0.4}
            radius={10}
            x1={lastDataPeriod[0]}
            x2={lastDataPeriod[1]}
            yAxisId="line"
          />
        )}
        <Tooltip
          contentStyle={{
            borderColor: colors.tooltipBorder,
            backgroundColor: colors.tooltipBg,
            borderRadius: radii.tooltip,
            fontSize: fontSizes.tooltip,
          }}
          cursor={{ stroke: colors.cursor }}
          formatter={(value) => {
            if (typeof value === "number") {
              const score = clamp(Math.round(value), 1, 5) as SatisfactionScore;
              return (
                <Box
                  alignItems="center"
                  as="span"
                  display="inline-flex"
                  gap={1}
                  verticalAlign="bottom"
                >
                  <FeedbackSatisfactionIcon
                    size={22}
                    value={score}
                    verticalAlign={"bottom"}
                    colored
                  />
                  <Text>{SATISFACTION_LABELS[score]}</Text>
                  <Text color="dimmed">({formatNumber(value)})</Text>
                </Box>
              ) as unknown as string;
            }

            return value;
          }}
          isAnimationActive={false}
          labelFormatter={epochToShortDate}
        />
        {!isLoading && (
          <Line
            activeDot={properties.activeDot}
            dataKey={"averageScore"}
            dot={false}
            isAnimationActive={false}
            name={"Score"}
            stopColor={colors.primary}
            stroke={
              scoreDiff === 0
                ? `color-mix(in srgb, ${scoreColor[Math.floor(minValue)]} ${
                    (minValue % 1) * 100
                  }%, ${scoreColor[Math.ceil(minValue)]} 100%)`
                : `url(#${scoreGradientName})`
            }
            strokeLinecap="round"
            strokeWidth={sizes.lineStrokeWidth}
            type="monotone"
            yAxisId="line"
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
}

export function SatisfactionAverageTimelineChartLegend() {
  return (
    <HStack spacing={2}>
      <Box
        borderColor={useToken("colors", useSatisfactionColors(3))}
        borderLeftWidth={4}
        borderRadius="full"
        h={4}
        ml={1}
        transform="rotate(45deg)"
        w={0}
      />
      <Text color="dimmed" fontSize="sm">
        Avg. score
      </Text>
    </HStack>
  );
}
