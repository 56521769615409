import { GoalDryRunPostArgs } from "@bucketco/shared/goalAPI";

import { releasesQueryKeys } from "./releasesQueryKeys";

export const goalQueryKeys = {
  list: (appId?: string, releaseId?: string) =>
    [...releasesQueryKeys.single(appId, releaseId), "goals"] as const,

  single: (appId?: string, releaseId?: string, goalId?: string) =>
    [...goalQueryKeys.list(appId), goalId] as const,

  dryRun: (appId?: string, args?: GoalDryRunPostArgs) =>
    args
      ? ([...goalQueryKeys.list(appId), "goals", args] as const)
      : ([...goalQueryKeys.list(appId), "goals"] as const),
};
