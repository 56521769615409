import React from "react";
import { Heading, HeadingProps } from "@chakra-ui/react";

export function SectionHeading({
  children,
  ...props
}: { children: React.ReactNode } & HeadingProps) {
  return (
    <Heading as="h3" fontSize="md" fontWeight="medium" {...props}>
      {children}
    </Heading>
  );
}
