import { chakra, HStack, StackProps, Text } from "@chakra-ui/react";

import { EnvironmentDTO } from "@bucketco/shared/environmentAPI";

import EnvironmentSvg from "@/common/assets/environment-dot-circle-fill.svg?react";
import { useEnvironmentColors } from "@/environment/hooks/useEnvironmentColors";

const EnvironmentIcon = chakra(EnvironmentSvg);

export const EnvironmentDisplayName = ({
  environment,
  ...stackProps
}: {
  environment: Pick<EnvironmentDTO, "name" | "isProduction">;
} & StackProps) => {
  const colors = useEnvironmentColors();
  const envColor =
    colors[environment.isProduction ? "production" : "nonProduction"];

  return (
    <HStack spacing={1} {...stackProps}>
      <EnvironmentIcon boxSize={3} color={envColor.icon} />
      <Text as="span" color={envColor.text} fontWeight="medium">
        {environment.name}
      </Text>
    </HStack>
  );
};
