import { ReactNode, useCallback, useMemo } from "react";
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Switch,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";

import { FeatureListItem } from "@bucketco/shared/featureAPI";
import { FeatureViewDTO } from "@bucketco/shared/featureViewAPI";

import AuditChart from "@/common/charts/components/AuditChart/components/AuditChart";
import {
  useAuditChart,
  UseAuditChartResult,
} from "@/common/charts/components/AuditChart/hooks/useAuditChart";
import { useAuditChartMetrics } from "@/common/charts/components/AuditChart/hooks/useAuditChartMetrics";
import { AuditChartAxisMetricKey } from "@/common/charts/components/AuditChart/types";
import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import { SectionHeading } from "@/common/components/SectionHeading";
import SidebarLayout, {
  SidebarContainer,
  SidebarSection,
} from "@/common/components/SidebarLayout";
import SimpleSelect from "@/common/components/SimpleSelect";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import {
  useSearchArrayParam,
  useSearchParam,
} from "@/common/hooks/useSearchParam";
import FeatureDisplay from "@/feature-legacy/components/FeatureDisplay";
import useFeaturesCompareData from "@/feature-legacy/data/useFeaturesCompareData";
import { useFeaturesCompareHistoricalData } from "@/feature-legacy/data/useFeaturesCompareHistoricalData";
import FeaturesEmptyState from "@/feature-legacy/pages/FeaturesEmptyState";

type FeaturesAuditProps = {
  viewId: string | null;
  view: FeatureViewDTO | null;
};

export default function FeaturesAudit({ view, viewId }: FeaturesAuditProps) {
  const params = useFeaturesAuditParams();
  const [subsegments] = useSubsegmentParam();

  const { data: featureListsData, isInitialLoading: isFeaturesLoading } =
    useFeaturesCompareData(
      {
        view: viewId ?? undefined,
        subsegments,
        sortType: "hierarchical",
      },
      {
        refetchOnWindowFocus: false,
      },
    );

  const featureLists = useMemo(
    () =>
      featureListsData.map((featureLists) => ({
        ...featureLists,
        items: featureLists.items.filter((f) => "currentMetrics" in f),
      })),
    [featureListsData],
  );

  const features = useMemo(() => featureLists[0]?.items || [], [featureLists]);

  const featureVisibilities = useMemo(() => {
    return features.reduce<Record<string, boolean>>((visibilities, { id }) => {
      return {
        ...visibilities,
        [id]: !params.featuresHidden.includes(id),
      };
    }, {});
  }, [features, params.featuresHidden]);

  const {
    data: historicalSummaries = [],
    isInitialLoading: isHistoricalLoading,
  } = useFeaturesCompareHistoricalData(
    {
      periodStart: getTrendlinePeriodStart(params.trendlinesPeriod),
      featureIds: features
        .map((f) => f.id)
        .filter((id) => !params.featuresHidden.includes(id)),
      subsegments,
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const auditChart = useAuditChart({
    featureLists,
    historicalSummaries,
    isFeaturesLoading,
    isHistoricalLoading,
    options: {
      showLabels: params.showLabels === "yes",
      showTrendlines: params.showTrendlines === "yes",
      xAxisMetric: params.xAxisMetric,
      yAxisMetric: params.yAxisMetric,
      featureVisibilities,
    },
  });

  if (!features.length) {
    return <FeaturesEmptyState isLoading={isFeaturesLoading} viewId={viewId} />;
  }

  return (
    <Layout params={params} view={view} {...auditChart}>
      <AuditChart {...auditChart} />
    </Layout>
  );
}

function Layout({
  children,
  data,
  isHistoricalLoading,
  params,
  activeFeature,
  setActiveFeature,
}: UseAuditChartResult & {
  children: ReactNode;
  view: FeatureViewDTO | null;
  isFeaturesLoading: boolean;
  isHistoricalLoading: boolean;
  params: ReturnType<typeof useFeaturesAuditParams>;
  activeFeature: FeatureListItem | null;
  setActiveFeature: (feature: FeatureListItem | null) => void;
}) {
  const hoverColor = useColorModeValue("gray.50", "gray.700");
  const metrics = useAuditChartMetrics();

  const features = useMemo(() => data[0]?.items || [], [data]);
  const isToggleShowAll = useMemo(
    () =>
      features.some((feature) => params.featuresHidden.includes(feature.id)),
    [features, params.featuresHidden],
  );

  const handleHideAllFeatures = useCallback(() => {
    params.setFeaturesHidden(features.map((f) => f.id) || [], {
      replace: true,
    });
  }, [features, params]);

  const handleShowAllFeatures = useCallback(() => {
    params.setFeaturesHidden([], { replace: true });
  }, [params]);

  const periodDatesLabel = useMemo(() => {
    const periodStart = dayjs(getTrendlinePeriodStart(params.trendlinesPeriod));
    const periodEnd = dayjs();
    const format =
      periodStart.year() === periodEnd.year() ? "MMM DD" : "MMM DD YYYY";

    return `${periodStart.format(format)} - ${periodEnd.format(format)}`;
  }, [params.trendlinesPeriod]);

  return (
    <SidebarLayout
      sidebarContent={
        <SidebarContainer>
          <SidebarSection>
            <SectionHeading>Metrics</SectionHeading>
            <FormControl>
              <HStack>
                <FormLabel color="dimmed" fontSize="sm" padding={0} w={32}>
                  Y-axis
                </FormLabel>
                <SimpleSelect
                  menuDescription="Select a metric for the Y-axis"
                  minW={32}
                  options={metrics.map((metric) => ({
                    label: metric.label,
                    value: metric.key,
                  }))}
                  size="sm"
                  value={params.yAxisMetric}
                  usePortal
                  onChange={(nextValue) =>
                    params.setYAxisMetric(nextValue, { replace: true })
                  }
                />
              </HStack>
            </FormControl>
            <FormControl>
              <HStack>
                <FormLabel color="dimmed" fontSize="sm" padding={0} w={32}>
                  X-axis
                </FormLabel>
                <SimpleSelect
                  menuDescription="Select a metric for the X-axis"
                  minW={32}
                  options={metrics.map((metric) => ({
                    label: metric.label,
                    value: metric.key,
                  }))}
                  size="sm"
                  value={params.xAxisMetric}
                  usePortal
                  onChange={(nextValue) =>
                    params.setXAxisMetric(nextValue, { replace: true })
                  }
                />
              </HStack>
            </FormControl>
          </SidebarSection>
          <Divider />
          <SidebarSection>
            <HStack>
              <SectionHeading>Trendlines</SectionHeading>
              <AnimatedSpinner show={isHistoricalLoading} size="xs" />
            </HStack>
            <FormControl>
              <HStack>
                <FormLabel
                  color="dimmed"
                  flexGrow={1}
                  fontSize="sm"
                  padding={0}
                >
                  Show trendlines
                </FormLabel>
                <Switch
                  colorScheme="brand"
                  isChecked={params.showTrendlines === "yes"}
                  size="sm"
                  onChange={(e) => {
                    params.setShowTrendlines(e.target.checked ? "yes" : "no", {
                      replace: true,
                    });
                  }}
                />
              </HStack>
            </FormControl>

            <VStack>
              <FormControl>
                <HStack>
                  <FormLabel
                    color="dimmed"
                    flexGrow={1}
                    fontSize="sm"
                    mr={0}
                    padding={0}
                  >
                    Period
                  </FormLabel>
                  <SimpleSelect
                    isDisabled={params.showTrendlines === "no"}
                    menuDescription="Select a time period for the trendlines"
                    minW={32}
                    options={TRENDLINE_PERIODS.map(({ key, label }) => {
                      return { label, value: key };
                    })}
                    size="sm"
                    value={params.trendlinesPeriod}
                    usePortal
                    onChange={(nextValue) =>
                      params.setTrendlinesPeriod(nextValue, { replace: true })
                    }
                  />
                </HStack>
              </FormControl>
              <Text align="right" color="dimmed" fontSize="xs" w="full">
                {periodDatesLabel}
              </Text>
            </VStack>
          </SidebarSection>
          <Divider />
          <SidebarSection>
            <Flex dir="row" w="100%">
              <SectionHeading flexGrow={1}>Features</SectionHeading>
              <Button
                color="dimmed"
                fontSize="sm"
                variant="link"
                onClick={
                  isToggleShowAll
                    ? handleShowAllFeatures
                    : handleHideAllFeatures
                }
              >
                {isToggleShowAll ? "Enable all" : "Disable all"}
              </Button>
            </Flex>

            <FormControl>
              <HStack>
                <FormLabel
                  color="dimmed"
                  flexGrow={1}
                  fontSize="sm"
                  padding={0}
                >
                  Show labels
                </FormLabel>
                <Switch
                  colorScheme="brand"
                  isChecked={params.showLabels === "yes"}
                  size="sm"
                  onChange={(e) =>
                    params.setShowLabels(e.target.checked ? "yes" : "no")
                  }
                />
              </HStack>
            </FormControl>

            <Grid m={-2} templateColumns={"min-content 1fr min-content"}>
              {features.map((feature, idx) => {
                const isChecked = !params.featuresHidden.includes(feature.id);
                return (
                  <Tooltip
                    key={feature.id}
                    label={
                      feature.hasData ? null : "No data on one of the axes"
                    }
                    placement="left"
                    hasArrow
                  >
                    <FormControl
                      alignItems="center"
                      as={FormLabel}
                      background={
                        feature.hasData && feature.id === activeFeature?.id
                          ? hoverColor
                          : undefined
                      }
                      display="grid"
                      gridColumn="1 / 4"
                      gridGap="2"
                      gridTemplateColumns="subgrid"
                      gridTemplateRows="subgrid"
                      isDisabled={!feature.hasData}
                      marginEnd={0}
                      p={2}
                      rounded={6}
                      onMouseEnter={() => {
                        if (isChecked && feature.hasData) {
                          setActiveFeature(feature);
                        }
                      }}
                      onMouseLeave={() => {
                        setActiveFeature(null);
                      }}
                    >
                      <Text
                        as="span"
                        color="dimmed"
                        fontSize="sm"
                        style={{ fontVariantNumeric: "tabular-nums" }}
                      >
                        {idx + 1}
                      </Text>
                      <FeatureDisplay feature={feature} showIcon={false} />
                      <Switch
                        colorScheme="brand"
                        isChecked={isChecked}
                        size="sm"
                        onChange={(e) => {
                          if (e.currentTarget.checked) {
                            params.setFeaturesHidden(
                              params.featuresHidden.filter(
                                (f) => f !== feature.id,
                              ),
                              { replace: true },
                            );
                          } else {
                            params.setFeaturesHidden(
                              [...params.featuresHidden, feature.id],
                              { replace: true },
                            );
                          }
                        }}
                      />
                    </FormControl>
                  </Tooltip>
                );
              })}
            </Grid>
          </SidebarSection>
        </SidebarContainer>
      }
      sidebarProps={{
        overflowX: "hidden",
        overflowY: "scroll",
        sx: {
          scrollbarWidth: "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        },
      }}
    >
      {children}
    </SidebarLayout>
  );
}

function useFeaturesAuditParams() {
  const [showLabels, setShowLabels] = useSearchParam<"yes" | "no">("labels", {
    fallback: "yes",
    storage: localStorage,
  });

  const [showTrendlines, setShowTrendlines] = useSearchParam<"yes" | "no">(
    "trendlines",
    { fallback: "yes", storage: localStorage },
  );

  const [trendlinesPeriod, setTrendlinesPeriod] =
    useSearchParam<TrendlinePeriods>("period", {
      fallback: DEFAULT_TRENDLINE_PERIOD_KEY,
      storage: localStorage,
    });

  const [featuresHidden, setFeaturesHidden] = useSearchArrayParam("hidden", {
    storage: localStorage,
  });

  const [xAxisMetric, setXAxisMetric] = useSearchParam<AuditChartAxisMetricKey>(
    "xAxis",
    { fallback: "retained", storage: localStorage },
  );

  const [yAxisMetric, setYAxisMetric] = useSearchParam<AuditChartAxisMetricKey>(
    "yAxis",
    { fallback: "averageFrequency", storage: localStorage },
  );

  return {
    showLabels,
    setShowLabels,
    showTrendlines,
    setShowTrendlines,
    trendlinesPeriod,
    setTrendlinesPeriod,
    featuresHidden,
    setFeaturesHidden,
    xAxisMetric,
    setXAxisMetric,
    yAxisMetric,
    setYAxisMetric,
  };
}

const DEFAULT_TRENDLINE_PERIOD_KEY = "month";
const DEFAULT_TRENDLINE_PERIOD = dayjs()
  .subtract(30, "day")
  .startOf("day")
  .utc()
  .toISOString();
const TRENDLINE_PERIODS = [
  {
    label: "1 week",
    key: "week",
    timestamp: dayjs().subtract(7, "day").startOf("day").utc().toISOString(),
  },
  {
    label: "2 weeks",
    key: "two_weeks",
    timestamp: dayjs().subtract(14, "day").startOf("day").utc().toISOString(),
  },
  {
    label: "1 month",
    key: "month",
    timestamp: dayjs().subtract(1, "month").startOf("day").utc().toISOString(),
  },
  {
    label: "3 months",
    key: "three_months",
    timestamp: dayjs().subtract(3, "month").startOf("day").utc().toISOString(),
  },
  {
    label: "6 months",
    key: "six_months",
    timestamp: dayjs().subtract(6, "month").startOf("day").utc().toISOString(),
  },
  {
    label: "1 year",
    key: "year",
    timestamp: dayjs().subtract(1, "year").startOf("day").utc().toISOString(),
  },
] as const;
type TrendlinePeriods = (typeof TRENDLINE_PERIODS)[number]["key"];

const getTrendlinePeriodStart = (periodKey: string) => {
  const period =
    TRENDLINE_PERIODS.find((t) => t.key === periodKey) ||
    TRENDLINE_PERIODS.find((t) => t.key === DEFAULT_TRENDLINE_PERIOD_KEY);

  return period?.timestamp || DEFAULT_TRENDLINE_PERIOD;
};
