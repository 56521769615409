import { useEffect } from "react";
import { useResolvedPath } from "react-router-dom";
import { Avatar, Box, HStack, Spinner } from "@chakra-ui/react";

import { FeatureAutocompleteSelect } from "@/common/components/FeatureAutocompleteSelect";
import HeaderLayout, {
  HeaderLayoutHeading,
} from "@/common/components/HeaderLayout";
import RouterTabs, { Route } from "@/common/components/RouterTabs";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import {
  CompanyProvider,
  useCompanyContext,
} from "@/company/contexts/CompanyContext";

export default function Company() {
  return (
    <CompanyProvider>
      <Content />
    </CompanyProvider>
  );
}

function Content() {
  const [featureFilter, setFeatureFilter] = useSearchParam("featureFilter");

  useEffect(() => {
    segmentAnalytics.track("Company Opened", {
      has_feature_filter: !!featureFilter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { company, companyIsLoading, feedbackDataTable, usersDataTables } =
    useCompanyContext();

  const usersTable = featureFilter
    ? usersDataTables?.featureCompanyUsers
    : usersDataTables?.companyUsers;

  const tabsRoutes: Route[] = [
    {
      name: "Features",
      route: {
        pathname: useResolvedPath("features").pathname,
        search: window.location.search,
      },
    },
    {
      name: "Feedback",
      count: feedbackDataTable?.data?.totalCount,
      countIsLoading: feedbackDataTable?.isLoading,
      route: {
        pathname: useResolvedPath("feedback").pathname,
        search: window.location.search,
      },
    },
    {
      name: "Users",
      count: usersTable?.data?.totalCount,
      countIsLoading: usersTable?.isLoading,
      route: {
        pathname: useResolvedPath("users").pathname,
        search: window.location.search,
      },
    },
    {
      name: "Attributes",
      route: {
        pathname: useResolvedPath("attributes").pathname,
        search: window.location.search,
      },
    },
  ];

  return (
    <HeaderLayout
      actions={
        <HStack spacing={4}>
          <FeatureAutocompleteSelect
            description="Filter all content by the following feature:"
            placeholder="Filter by feature"
            value={featureFilter}
            onChange={(value) => {
              setFeatureFilter(value?.id);
              segmentAnalytics.track("Company Feature Filter Updated");
            }}
          />
        </HStack>
      }
      title={
        companyIsLoading ? (
          <Box minH={6}>
            <Spinner size="sm" />
          </Box>
        ) : (
          <>
            <Avatar name={company?.name || company?.id} size="2xs" />
            <HeaderLayoutHeading noOfLines={1}>
              {company?.name || company?.id}
            </HeaderLayoutHeading>
          </>
        )
      }
    >
      <RouterTabs
        routes={tabsRoutes}
        tabListProps={{ px: 2 }}
        titleTemplate={`Company › ${company?.name} › %s`}
      />
    </HeaderLayout>
  );
}
