import { FeatureSourceType } from "@bucketco/shared/featureAPI";

import { SupportedHighlightLanguage } from "@/common/components/CodeBlock";
import { CodeExample, CodeExampleProps } from "@/common/components/CodeExample";
import {
  browserAttributeExample,
  browserEventExample,
  nodeAttributeExample,
  nodeEventExample,
  reactAttributeExample,
  reactEventExample,
} from "@/feature/data/codeExamples";

type SDK = "react" | "node" | "browser";

type Props = Omit<CodeExampleProps, "examples"> & {
  flagKey: string;
  featureSource?: FeatureSourceType;
};

const sdkConfig: Array<{
  key: SDK;
  label: string;
  highlighterLanguage: SupportedHighlightLanguage;
}> = [
  { key: "react", label: "React", highlighterLanguage: "jsx" },
  { key: "browser", label: "Browser", highlighterLanguage: "javascript" },
  { key: "node", label: "Node.js", highlighterLanguage: "javascript" },
];

export function FeatureCodeExample({
  flagKey,
  featureSource = "event",
  ...rest
}: Props) {
  return (
    <CodeExample
      examples={sdkConfig.map(({ key, label, highlighterLanguage }) => ({
        key,
        label,
        highlighterLanguage,
        code: getCodeExample(flagKey, featureSource, key).trim(),
      }))}
      {...rest}
    />
  );
}

function getCodeExample(
  flagKey: string,
  featureSource: FeatureSourceType,
  output: SDK,
): string {
  const exampleMap = {
    event: {
      react: reactEventExample,
      browser: browserEventExample,
      node: nodeEventExample,
    },
    attribute: {
      react: reactAttributeExample,
      browser: browserAttributeExample,
      node: nodeAttributeExample,
    },
  };

  return exampleMap[featureSource][output](flagKey);
}
