import { forwardRef, useCallback, useMemo } from "react";
import { RiArrowDownSLine, RiCloseLine } from "react-icons/ri";
import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  FormLabel,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  MenuProps,
  Switch,
  Text,
  useFormControl,
} from "@chakra-ui/react";

import { SegmentDTOWithTotalCompanies } from "@bucketco/shared/segmentAPI";
import { getFraction } from "@bucketco/shared/utils/getFraction";

import { SimplePieChart } from "@/common/charts/components/SimplePieChart";
import MenuDescription from "@/common/components/MenuDescription";
import { useSegments } from "@/company/data/useSegments";

export type SegmentPickerProps = Omit<ButtonProps, "value" | "onChange"> & {
  value?: string | string[] | null;
  placement?: MenuProps["placement"];
  size?: ButtonProps["size"];
  placeholder?: string;
  menuDescription?: string | React.ReactNode;
  multiselect?: boolean;
  multiselectLimit?: number;
  includeAll?: boolean;
  showClearButton?: boolean;
  selectedColor?: string;
  showPieChart?: boolean;
  segmentItemFilter?: (segment: SegmentDTOWithTotalCompanies) => boolean;
  allCompaniesToggle?: boolean;
  targetSegmentId?: string;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  onChange?: (value: string | string[] | null) => void;
  onClear?: () => void;
};

function filterListedSegments(
  segments: SegmentDTOWithTotalCompanies[],
  includeAll: boolean,
  multiselect: boolean,
  segmentItemFilter:
    | ((segment: SegmentDTOWithTotalCompanies) => boolean)
    | undefined,
  targetSegmentId: string | undefined,
  allCompaniesToggle: boolean,
  returnedValues: string[],
) {
  return segments.filter((s) => {
    // Remove all-segment if it's not allowed
    if (s.isAllSegment && (!includeAll || multiselect)) {
      return false;
    }

    // Remove target segment in case of all-companies toggle (target segment will be the default state)
    if (allCompaniesToggle && targetSegmentId && targetSegmentId === s.id) {
      return false;
    }

    // Remove stateful filter segments if not allowed
    if (segmentItemFilter && !segmentItemFilter(s)) {
      return false;
    }

    // We still show deleted segments if they are returned from backend
    return s.deletedAt === null || returnedValues.includes(s.id);
  });
}

const SegmentPicker = forwardRef(
  (
    {
      id,
      value,
      size = "sm",
      placeholder = "No segment selected",
      menuDescription = "Set target company segment:",
      multiselect = false,
      multiselectLimit = 5,
      includeAll = true,
      showClearButton = false,
      selectedColor,
      showPieChart = false,
      segmentItemFilter,
      allCompaniesToggle = false,
      targetSegmentId,
      Icon,
      onChange,
      onClear,
      ...rest
    }: SegmentPickerProps,
    ref,
  ) => {
    const formControlProps = useFormControl<HTMLButtonElement>(rest);

    const { data: segmentsData = [], isLoading: isLoadingSegments } =
      useSegments({ includeDeleted: true });

    const allSegmentId = useMemo(
      () => segmentsData.find((s) => s.isAllSegment)?.id,
      [segmentsData],
    );

    const valueArr = useMemo(
      () => (Array.isArray(value) ? value : value ? [value] : []),
      [value],
    );

    const targetSegment = useMemo(
      () => segmentsData.find((s) => s.id === targetSegmentId),
      [segmentsData, targetSegmentId],
    );

    const allCompaniesSelected = useMemo(
      () =>
        valueArr.some(
          (segmentId) =>
            segmentsData.find((s) => s.id === segmentId)?.isAllSegment,
        ),
      [valueArr, segmentsData],
    );

    const selectedSegments = useMemo(
      () => segmentsData.filter((s) => valueArr.includes(s.id)),
      [segmentsData, valueArr],
    );

    const listedSegments = useMemo(
      () =>
        filterListedSegments(
          segmentsData,
          includeAll,
          multiselect,
          segmentItemFilter,
          targetSegmentId,
          allCompaniesToggle,
          valueArr,
        ),
      [
        segmentsData,
        includeAll,
        multiselect,
        segmentItemFilter,
        targetSegmentId,
        allCompaniesToggle,
        valueArr,
      ],
    );

    const selectedSegmentIdsFromList = useMemo(
      () => valueArr.filter((id) => id !== allSegmentId),
      [valueArr, allSegmentId],
    );

    const clearButtonCallback = useCallback(() => {
      onChange?.(null);
      onClear?.();
    }, [onClear, onChange]);

    const allCompaniesToggleCallback = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(e.target.checked && allSegmentId ? [allSegmentId] : null);
      },
      [onChange, allSegmentId],
    );

    const segmentListCallback = useCallback(
      (newValue: string | string[]) => {
        onChange?.(newValue);
      },
      [onChange],
    );

    return (
      <Menu autoSelect={false} closeOnSelect={!multiselect} id={id}>
        <ButtonGroup isAttached>
          <MenuButton
            {...formControlProps}
            ref={ref}
            as={Button}
            bg="appBackground"
            color={
              valueArr.length === 0 && !allCompaniesToggle
                ? "dimmed"
                : undefined
            }
            data-testid="rule-field"
            isDisabled={formControlProps.disabled || isLoadingSegments}
            leftIcon={
              Icon &&
              valueArr.length === 0 &&
              !allCompaniesToggle && <Icon height={16} width={16} />
            }
            placeholder={placeholder}
            rightIcon={
              <Box fontSize="xl" mr={-2}>
                <RiArrowDownSLine />
              </Box>
            }
            size={size}
            variant="input"
            {...rest}
          >
            <HStack spacing={1}>
              {isLoadingSegments ? (
                "Loading..."
              ) : valueArr.length == 0 &&
                allCompaniesToggle &&
                targetSegment ? (
                // Just the target segment
                <SegmentWithPieChart
                  displayPieChart={showPieChart}
                  isTargetSegment={true}
                  segment={targetSegment}
                />
              ) : valueArr.length === 1 &&
                allCompaniesToggle &&
                allCompaniesSelected ? (
                // Just the all companies segment
                <SegmentWithPieChart
                  displayPieChart={showPieChart}
                  isTargetSegment={false}
                  segment={selectedSegments[0]}
                />
              ) : valueArr.length === 1 &&
                allCompaniesToggle &&
                targetSegment ? (
                // Target segment + one selected segment
                <>
                  <SegmentWithPieChart
                    displayPieChart={showPieChart}
                    isTargetSegment={true}
                    segment={targetSegment}
                  />
                  <Text>+</Text>
                  <SegmentWithPieChart
                    color={selectedColor}
                    displayPieChart={showPieChart}
                    Icon={Icon}
                    isTargetSegment={false}
                    segment={selectedSegments[0]}
                  />
                </>
              ) : valueArr.length === 1 && !allCompaniesToggle ? (
                // Just selected segment with no regard if it's the target segment - default case
                <SegmentWithPieChart
                  color={selectedColor}
                  displayPieChart={showPieChart}
                  Icon={Icon}
                  isTargetSegment={false}
                  segment={selectedSegments[0]}
                />
              ) : valueArr.length > 1 && !allCompaniesToggle && multiselect ? (
                // Multiple subsegments selected
                <HStack color={selectedColor}>
                  {Icon && <Icon height={16} width={16} />}
                  <Text>{valueArr.length} segments</Text>
                </HStack>
              ) : (
                <Text color="dimmed">{placeholder}</Text>
              )}
            </HStack>
          </MenuButton>
          {showClearButton &&
            (selectedSegmentIdsFromList.length > 0 || allCompaniesSelected) && (
              <IconButton
                aria-label="Clear selection"
                icon={<RiCloseLine />}
                isDisabled={rest.isDisabled}
                marginInlineStart={"-1px"}
                size={size}
                variant="outline"
                onClick={clearButtonCallback}
              />
            )}
        </ButtonGroup>
        <MenuList
          data-testid="rule-field-attributes-list"
          maxH="sm"
          maxW={280}
          overflow="auto"
        >
          {allCompaniesToggle && targetSegmentId !== allSegmentId && (
            <>
              <MenuDescription>
                <HStack>
                  <FormLabel w={"80%"}>All companies</FormLabel>
                  <Switch
                    isChecked={allCompaniesSelected}
                    name="allCompanies"
                    size={"sm"}
                    onChange={allCompaniesToggleCallback}
                  />
                </HStack>
                Show feature data from all companies
              </MenuDescription>
              <MenuDivider my={0} />
            </>
          )}
          <MenuDescription>
            {allCompaniesToggle && <FormLabel>Apply subsegment</FormLabel>}
            {menuDescription}
          </MenuDescription>
          <MenuOptionGroup
            type={multiselect ? "checkbox" : "radio"}
            value={
              multiselect
                ? selectedSegmentIdsFromList
                : selectedSegmentIdsFromList[0] ?? ""
            }
            onChange={segmentListCallback}
          >
            {listedSegments.map((segment) => (
              <MenuItemOption
                key={segment.id}
                isDisabled={
                  multiselect &&
                  !!valueArr &&
                  valueArr.length >= multiselectLimit &&
                  !valueArr?.includes(segment.id)
                }
                value={segment.id}
              >
                <SegmentWithPieChart
                  displayPieChart={true}
                  isTargetSegment={false}
                  segment={segment}
                />
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    );
  },
);

SegmentPicker.displayName = "SegmentPicker";
export default SegmentPicker;

type SegmentWithPieChartProps = {
  segment: SegmentDTOWithTotalCompanies;
  isTargetSegment: boolean;
  color?: string;
  displayPieChart: boolean;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

function SegmentWithPieChart({
  segment,
  isTargetSegment,
  color,
  displayPieChart,
  Icon,
}: SegmentWithPieChartProps) {
  const displayName = segment.isAllSegment ? "All companies" : segment.name;
  return (
    <HStack color={color}>
      {Icon && <Icon height={16} width={16} />}
      {displayPieChart && (
        <SimplePieChart
          color={color}
          value={getFraction(segment.segmentCount, segment.allCount) * 100}
        />
      )}
      <Text color={color}>{displayName}</Text>
      {isTargetSegment && <Text color="dimmed">(target segment)</Text>}
      {segment.deletedAt !== null && <Text color="dimmed">(archived)</Text>}
    </HStack>
  );
}
