import { useQuery } from "@tanstack/react-query";

import { FeatureNamesQueryType } from "@bucketco/shared/featureAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import featureQueryKeys from "@/feature-legacy/data/featureQueryKeys";

export default function useFeatureNamesData(
  searchParams: Partial<FeatureNamesQueryType> = {},
) {
  const { appId } = useCurrentEnv();

  // Avoid literal undefined as it becomes the string 'undefined' in search params
  const cleanSearchParams: Partial<FeatureNamesQueryType> = Object.fromEntries(
    Object.entries(searchParams).filter(
      ([_key, value]) => typeof value !== "undefined",
    ),
  );

  const dataUrl = `/apps/${appId}/features/names?${new URLSearchParams(
    cleanSearchParams as Record<string, string>,
  ).toString()}`;

  return useQuery({
    queryKey: featureQueryKeys.listNames(appId, cleanSearchParams),

    queryFn: () =>
      api.get<"/apps/:appId/features/names">(dataUrl).then((res) => res.data),
    enabled: !!appId,
    staleTime: 5 * 60 * 1000,
  });
}
