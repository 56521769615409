import { useState } from "react";
import { useParams } from "react-router-dom";

import EvaluationChart, {
  EvaluationChartLegend,
} from "@/common/charts/components/EvaluationChart";
import PeriodPicker from "@/common/components/PeriodPicker";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import { ChartWrapper } from "@/feature-legacy/components/ChartWrapper";
import { useFeatureContext } from "@/feature-legacy/contexts/featureContext";
import useEvaluationComboChartData from "@/feature-legacy/data/useEvaluationComboChartData";
import { useFeatureDates } from "@/feature-legacy/data/useFeatureChartDates";

export default function EvaluationTimeline() {
  const { featureId } = useParams();
  const [subsegment] = useSubsegmentParam();
  const [width, setWidth] = useState(720);

  const { relevantMetrics } = useFeatureContext();
  const {
    startDate,
    endDate,
    evaluationPeriod,
    granularity,
    setEvaluationPeriod,
    hasPartialPeriod,
  } = useFeatureDates();

  const { isLoading, xAxis, barYAxis, lineYAxis, data, dataKeys, tooltip } =
    useEvaluationComboChartData(
      featureId,
      {
        startDate,
        endDate,
        granularity,
        subsegment: subsegment[0],
      },
      {
        chartWidth: width,
        triedDisabled: !relevantMetrics.includes("tried"),
        retentionRateDisabled: !relevantMetrics.includes("retained"),
      },
    );

  return (
    <ChartWrapper
      gap={2}
      isLoading={isLoading}
      metric={
        <>
          <EvaluationChartLegend
            retentionRateDisabled={!relevantMetrics.includes("retained")}
            triedDisabled={!relevantMetrics.includes("tried")}
          />
          <PeriodPicker
            value={evaluationPeriod}
            onChange={(newPeriod) =>
              setEvaluationPeriod(newPeriod, { replace: true })
            }
          />
        </>
      }
      title="Timeline"
    >
      <EvaluationChart
        barYAxis={barYAxis}
        data={data}
        dataKeys={dataKeys}
        highlightPartialPeriod={hasPartialPeriod}
        isLoading={isLoading}
        lineYAxis={lineYAxis}
        tooltip={tooltip}
        xAxis={xAxis}
        onResize={(newWidth) => {
          setWidth(newWidth);
        }}
      />
    </ChartWrapper>
  );
}
