import { Text } from "@chakra-ui/react";

import {
  AttributeFilterOperator,
  AttributeFilterType,
  listOperators,
  TypeByOperator,
} from "@bucketco/shared/attributeFilter";

import pluralize from "@/common/utils/pluralize";
import { TextWordBreak } from "../TextWordBreak";

type FilterValueProps = {
  operator: AttributeFilterOperator;
  type?: AttributeFilterType;
  value?: string[];
};

export default function FilterValue(
  props: FilterValueProps,
): JSX.Element | null {
  if (!props.type) {
    props = {
      ...props,
      type: TypeByOperator[props.operator],
    } as FilterValueProps;
  }

  const [value, ...otherValues] = props.value ?? [];

  if (!props.value || !value) {
    return null;
  }

  if (listOperators.includes(props.operator) || props.type === "list") {
    if (otherValues.length === 0) {
      return <TextWordBreak as="span">{`'${value}'`}</TextWordBreak>;
    }

    return (
      <Text as="span" title={props.value.map((v) => `"${v}"`).join(", ")}>
        {otherValues.length + 1} options
      </Text>
    );
  }

  switch (props.type) {
    case "any":
    case "boolean":
      return null;
    case "date":
      // Explicitly render days ago instead of a relative time difference,
      // since days ago is the literal value the editor lets you set
      return (
        <Text as="span" whiteSpace="nowrap">
          {props.value} {pluralize("day", parseInt(value))} ago
        </Text>
      );
    case "number":
      return <Text as="span">{value}</Text>;
    case "text":
      return <TextWordBreak as="span">{`'${value}'`}</TextWordBreak>;
    default:
      return null;
  }
}
