export type BaseTimeseries = {
  epoch: number;
};

export const padTimeseries = (
  timeseries: BaseTimeseries[],
  startDate: Date,
  endDate: Date,
  granularity: "daily" | "weekly" = "weekly",
): BaseTimeseries[] => {
  const granularityFactor = granularity === "daily" ? 86400 : 604800;
  // Add 1 granularity to start and end as data points use period end
  const startEpoch = Math.round(startDate.getTime() / 1000) + granularityFactor;
  const endEpoch = Math.round(endDate.getTime() / 1000) + granularityFactor;
  const newTimeseries: BaseTimeseries[] = [];

  for (
    let currentEpoch = startEpoch;
    currentEpoch <= endEpoch;
    currentEpoch += granularityFactor
  ) {
    const existingTimeseriesItem = timeseries.find(
      (item) =>
        item.epoch >= currentEpoch &&
        item.epoch < currentEpoch + granularityFactor,
    );
    if (existingTimeseriesItem) {
      newTimeseries.push(existingTimeseriesItem);
    } else {
      newTimeseries.push({
        epoch: currentEpoch,
      });
    }
  }

  return newTimeseries;
};
