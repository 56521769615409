import { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  HStack,
  Link,
  LinkProps,
  Progress,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { GoalDetailsDTO } from "@bucketco/shared/goalAPI";
import { ReleaseDTO } from "@bucketco/shared/releaseAPI";
import { ReleaseUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import GoalDisplay from "@/feature-legacy/components/GoalDisplay";

import { ReleasesIcon } from "./ReleasesIcon";

dayjs.extend(utc);

type ReleaseDisplayProps = {
  release: { id: string; name: string };
} & LinkProps;

export function ReleaseDisplay({ release, ...linkProps }: ReleaseDisplayProps) {
  const { currentEnv } = useAuthContext();

  const flexProps: LinkProps = {
    display: "flex",
    alignItems: "center",
    gap: 1,
  };

  return (
    <Link
      _hover={{ textDecoration: "underline" }}
      as={RouterLink}
      textDecoration="none"
      to={ReleaseUrl(currentEnv!, release.id, false)}
      {...flexProps}
      {...linkProps}
    >
      <Box flexShrink={0}>
        <ReleasesIcon color="dimmed" height="13px" width="13px" />
      </Box>
      <Text flexShrink={1} fontSize="sm" minW={0} noOfLines={1}>
        {release.name}
      </Text>
    </Link>
  );
}

type ReleaseDisplayVerboseProps = {
  release: ReleaseDTO;
  goals?: GoalDetailsDTO[];
  highlight?: string;
  fill?: boolean;
};

export function ReleaseDisplayVerbose({
  release,
  goals,
  fill = true,
}: ReleaseDisplayVerboseProps) {
  const boxBgColor = useColorModeValue("gray.25", "gray.800");

  const daysLeft = useMemo(() => {
    return (
      dayjs
        .utc(release.releasedAt)
        .add(release.evaluationPeriod ?? 0, "days")
        .diff(dayjs.utc(), "days") + 1
    );
  }, [release.releasedAt, release.evaluationPeriod]);

  const stackProps = fill
    ? {
        bg: boxBgColor,
        px: "4",
        p: "3",
        rounded: "md",
        spacing: 2.5,
        w: "full",
      }
    : {};

  return (
    <VStack key={release.id} alignItems="start" {...stackProps}>
      {goals?.length && (
        <VStack spacing={1} w="full">
          {goals.map((goal) => (
            <HStack key={goal.id} w="full">
              <GoalDisplay goal={goal} />
            </HStack>
          ))}
        </VStack>
      )}

      <HStack justifyContent="space-between" w="full">
        <ReleaseDisplay
          color="dimmed"
          flexShrink={1}
          minW={0}
          release={release}
        />

        {release.status === "evaluating" && (
          <Text color="dimmed" flexShrink={0} fontSize="sm" textAlign="right">
            {daysLeft > 0 ? `${daysLeft}d left` : "Today"}
          </Text>
        )}
      </HStack>

      {release.status === "evaluating" && (
        <Box pb="1" w="full">
          <Progress
            color="dimmed"
            h={1}
            max={release.evaluationPeriod}
            min={0}
            value={release.evaluationPeriod - daysLeft}
          />
        </Box>
      )}
    </VStack>
  );
}
