import { DataExportTypeType } from "@bucketco/shared/exportAPI";

const qk = {
  dataExport: (appId?: string, envId?: string, type?: DataExportTypeType) =>
    ["apps", appId, "environments", envId, "dataExport", type] as const,

  dataExportConfiguration: (
    appId?: string,
    envId?: string,
    type?: DataExportTypeType,
  ) => [...qk.dataExport(appId, envId, type), "configuration"] as const,

  dataExportHistory: (
    appId?: string,
    envId?: string,
    type?: DataExportTypeType,
  ) => [...qk.dataExport(appId, envId, type), "history"] as const,
};

export default qk;
