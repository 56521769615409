import { useQuery } from "@tanstack/react-query";

import { TrackingHealth } from "@bucketco/shared/featureAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import useFeatureHasData from "../hooks/useFeatureHasData";

import featureQueryKeys from "./featureQueryKeys";

export function trackingHealthQueryFunctionFactory(
  appId: string,
  envId: string,
  featureId: string,
) {
  return function useTrackingHealthQueryFunction(): Promise<TrackingHealth> {
    return api
      .get<"/apps/:appId/features/:featureId/tracking-health">(
        `/apps/${appId}/features/${featureId}/tracking-health`,
        { params: { envId: envId! } },
      )
      .then((res) => res.data.trackingHealth);
  };
}

export default function useTrackingHealth(featureId?: string) {
  const { appId, envId } = useCurrentEnv();
  const { data: status } = useFeatureHasData(featureId);

  return useQuery({
    queryKey: featureQueryKeys.singleFeatureTrackingHealth(
      appId,
      envId,
      featureId,
      status?.status,
    ),
    queryFn: trackingHealthQueryFunctionFactory(appId!, envId!, featureId!),

    enabled: !!appId && !!envId && !!featureId,
    staleTime: 60000, // 1 minute
  });
}
