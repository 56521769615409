import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { GoalDetailsDTO } from "@bucketco/shared/goalAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import featureQueryKeys from "@/feature-legacy/data/featureQueryKeys";

export default function useFeatureActiveGoalsData(featureId?: string) {
  const { appId } = useCurrentEnv();

  return useQuery<GoalDetailsDTO[], AxiosError<ErrorResponse>>({
    queryKey: featureQueryKeys.singleFeatureActiveGoals(appId, featureId),

    queryFn: () =>
      api
        .get<"/apps/:appId/features/:featureId/active-goals">(
          `/apps/${appId}/features/${featureId}/active-goals`,
        )
        .then((res) => res.data),
    enabled: !!appId && !!featureId,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
