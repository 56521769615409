import { forwardRef } from "react";

import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import RadioGroupList, {
  RadioGroupListProps,
} from "@/common/components/RadioGroupList";
import {
  FormElementBaseProps,
  useManagedFormControlProps,
} from "@/common/hooks/useManagedFormControlProps";
import { mergeFunctions } from "@/common/utils/mergeFunctions";
import { mergeRefs } from "@/common/utils/mergeRefs";

export type FormRadioGroupProps = FormElementBaseProps & RadioGroupListProps;

const FormRadioGroup = forwardRef<HTMLInputElement, FormRadioGroupProps>(
  ({ _control, _radio, onChange, onBlur, ...rest }, ref) => {
    const [managedProps, groupProps] = useManagedFormControlProps(rest);
    return (
      <ManagedFormControl
        {..._control}
        {...managedProps}
        render={({ field }) => (
          <RadioGroupList
            {...groupProps}
            {...field}
            ref={mergeRefs(field.ref, ref)}
            onBlur={mergeFunctions(field.onBlur, onBlur)}
            onChange={mergeFunctions(field.onChange, onChange)}
          />
        )}
      />
    );
  },
);
FormRadioGroup.displayName = "FormRadioGroup";

export default FormRadioGroup;
