import { useEffect } from "react";
import { useResolvedPath } from "react-router-dom";

import HeaderLayout from "@/common/components/HeaderLayout";
import RouterTabs, { Route } from "@/common/components/RouterTabs";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

export default function Tracking() {
  const tabsRoutes: Route[] = [
    {
      route: useResolvedPath("recent").pathname,
      name: "Recent",
    },
    {
      route: useResolvedPath("distinct").pathname,
      name: "Distinct",
    },
  ];

  useEffect(() => {
    segmentAnalytics.page("Tracking");
  }, []);

  return (
    <HeaderLayout title="Tracking">
      <RouterTabs
        routes={tabsRoutes}
        tabListProps={{ px: 2 }}
        titleTemplate="Tracking › %s"
      />
    </HeaderLayout>
  );
}
