import SwitchButtonGroup from "@/common/components/SwitchButtonGroup";
import {
  TableDisplayMode,
  useTableDisplayMode,
} from "@/common/hooks/useTableDisplayMode";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

export function TableDisplayModePicker() {
  const [displayMode, setDisplayMode] = useTableDisplayMode();

  return (
    <SwitchButtonGroup<TableDisplayMode>
      activeId={displayMode}
      buttons={[
        { id: "tree", label: "Tree" },
        { id: "list", label: "List" },
      ]}
      label="Feature display mode"
      size="sm"
      onChange={(mode) => {
        setDisplayMode(mode);
        segmentAnalytics.track("Feature Tree Sort Type Toggled", {
          sort_type: displayMode,
        });
      }}
    />
  );
}
