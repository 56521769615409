import { forwardRef, ReactNode } from "react";
import {
  Box,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
  Text,
  VStack,
} from "@chakra-ui/react";

export type RadioGroupListProps = Omit<RadioGroupProps, "children"> & {
  itemDescriptionLocation?: "side" | "below";
  options: { label: ReactNode; value: string; description?: string }[];
  _radio?: RadioProps;
};

const RadioGroupList = forwardRef(
  (
    {
      options,
      itemDescriptionLocation,
      _radio,
      ...groupProps
    }: RadioGroupListProps,
    ref,
  ) => {
    return (
      <RadioGroup ref={ref} {...groupProps}>
        <VStack align="flex-start" spacing={1}>
          {options.map(({ value, label, description }) => (
            <Box key={value}>
              <Radio {..._radio} key={value} value={value}>
                <Text as="span">{label}</Text>
                {itemDescriptionLocation === "side" && description && (
                  <Text as="span" color="gray.500">
                    {" "}
                    - {description}
                  </Text>
                )}
              </Radio>
              {itemDescriptionLocation === "below" && description && (
                <Box color="gray.500" sx={{ paddingLeft: "25px" }}>
                  {description}
                </Box>
              )}
            </Box>
          ))}
        </VStack>
      </RadioGroup>
    );
  },
);

RadioGroupList.displayName = "RadioGroupList";

export default RadioGroupList;
