import { z } from "zod";

import { paginationQueryBaseSchema } from "./schemas/dataTableSchema";
import { BucketUser } from "./types/BucketUser";
import { Paginated } from "./types/Paginated";
import { APIEmptyResponse, APIResponse } from "./api";
import {
  EnvironmentSelectionQuerySchema,
  EnvironmentSelectionQueryType,
} from "./environmentAPI";
import { getFilterCount, UIFilter, UIFilterSchema } from "./filter";

export const maxRolloutThreshold = 100000;

const partialRolloutThresholdErrorMessage =
  "Rollout threshold should be between 0% and 100%";

const CreateFlagRuleSchema = z
  .object({
    name: z.string().nullish(),
    filter: UIFilterSchema.refine(
      (filter) => {
        return getFilterCount(filter) > 0;
      },
      { message: "At least one filter condition is required" },
    ),
    partialRolloutThreshold: z
      .number({
        required_error: partialRolloutThresholdErrorMessage,
        invalid_type_error: partialRolloutThresholdErrorMessage,
      })
      .int()
      .min(0)
      .max(maxRolloutThreshold, {
        message: partialRolloutThresholdErrorMessage,
      })
      .default(maxRolloutThreshold),
    partialRolloutContextAttribute: z.string().nullish(),
  })
  .strict();

export type CreateFlagRuleType = z.infer<typeof CreateFlagRuleSchema>;

export const CreateFlagArgsSchema = z
  .object({
    key: z.string().min(1).max(128),
    featureId: z.string().nonempty("A feature id must be supplied"),
    description: z.string().max(512).nullish(),
    slackChannelId: z.string().nullish(),
    slackChannelName: z.string().nullish(),
    slackNotificationsEnabled: z.boolean().default(false),
  })
  .strict();

export const PatchFlagArgsSchema = CreateFlagArgsSchema.omit({
  key: true,
})
  .partial()
  .strict();

export type PatchFlagArgsType = z.input<typeof PatchFlagArgsSchema>;

export const CreateFlagVersionArgsSchema = z
  .object({
    rules: z.array(CreateFlagRuleSchema),
    changeDescription: z.string().max(512).nullish(),
  })
  .strict();

export type CreateFlagVersionArgsType = z.input<
  typeof CreateFlagVersionArgsSchema
>;

export type CreateFlagArgsType = z.input<typeof CreateFlagArgsSchema>;

export type FlagRule = {
  id: string;
  partialRolloutThreshold: number; // 0 - 100000
  partialRolloutContextAttribute: string | null;
  filter: UIFilter;
};

export type FlagVersion = {
  id: string;
  environment: {
    id: string;
    isProduction: boolean;
    name: string;
    order: number;
  };
  rules: FlagRule[];
  isValid: boolean;

  version: number;
  currentVersion: boolean;

  createdAt: string;
  createdBy: BucketUser | null;

  changeDescription: string | null;
};

export type Flag = {
  id: string;
  featureId: string;

  key: string;
  description: string | null;

  slackChannelId: string | null;
  slackChannelName: string | null;
  slackNotificationsEnabled: boolean;

  currentVersions: FlagVersion[];

  createdAt: string;
  createdBy: BucketUser | null;

  updatedAt: string;
  updatedBy: BucketUser | null;

  releases: Array<{ id: string; name: string }>;
};

export const FlagListQuerySortBySchema = z.enum([
  "key",
  "createdBy",
  "createdAt",
  "updatedAt",
]);

export type FlagListQuerySortBy = z.infer<typeof FlagListQuerySortBySchema>;

export const FlagListQuerySchema = z
  .object({
    sortBy: FlagListQuerySortBySchema.default("key"),
  })
  .merge(paginationQueryBaseSchema)
  .strict();

export type FlagListQuery = z.input<typeof FlagListQuerySchema>;

export const FlagVersionQuerySchema = paginationQueryBaseSchema.merge(
  EnvironmentSelectionQuerySchema,
);
export type FlagVersionQueryType = z.input<typeof FlagVersionQuerySchema>;

export type FlagEventStatus = {
  environment: {
    id: string;
    name: string;
    isProduction: boolean;
    order: number;
  };
  lastEventAt: string | null;
  estimatedRuleTargetAudience: Record<string, number>;
};

export type FlagEventActionType = "evaluate" | "check";

export const FlagEventEvalContextKeysQuerySchema = z
  .object({
    prefix: z.enum(["user", "company", "other"]),
    filter: z.string().optional(),
  })
  .merge(EnvironmentSelectionQuerySchema)
  .strict();

export type FlagEventEvalContextKeysQueryType = z.input<
  typeof FlagEventEvalContextKeysQuerySchema
>;

export const FlagEventEvalContextKeyValuesQuerySchema = z
  .object({
    key: z.string().min(1),
    filter: z.string().optional(),
  })
  .merge(EnvironmentSelectionQuerySchema)
  .strict();

export type FlagEventEvalContextKeyValuesQueryType = z.input<
  typeof FlagEventEvalContextKeyValuesQuerySchema
>;

export const CreateFlagVersionQuerySchema =
  EnvironmentSelectionQuerySchema.extend({
    sendNotification: z.enum(["true", "false"]).optional(),
  });

export type CreateFlagVersionQueryType = z.input<
  typeof CreateFlagVersionQuerySchema
>;

export const TargetAudienceEstimateSchema = CreateFlagRuleSchema;

export type TargetAudienceEstimateType = z.input<
  typeof TargetAudienceEstimateSchema
>;

export type TargetAudienceEstimate = {
  count: number;
};

export interface FlagAPI {
  "/apps/:appId/flags": {
    GET: {
      response: APIResponse<Paginated<Flag, FlagListQuerySortBy>>;
      params: {
        appId: string;
      };
      query: FlagListQuery;
    };
    POST: {
      body: CreateFlagArgsType;
      response: APIResponse<{
        flag: Flag;
      }>;
      params: { appId: string };
    };
  };
  "/apps/:appId/flags/:flagId": {
    GET: {
      response: APIResponse<Flag>;
      params: {
        appId: string;
        flagId: string;
      };
    };
    PATCH: {
      response: APIResponse<{
        flag: Flag;
      }>;
      params: { appId: string; flagId: string };
      body: PatchFlagArgsType;
    };
    DELETE: {
      response: APIEmptyResponse;
      params: {
        appId: string;
        flagId: string;
      };
    };
  };
  "/apps/:appId/flags/:flagId/versions": {
    GET: {
      response: APIResponse<Paginated<FlagVersion, "version">>;
      params: {
        appId: string;
        flagId: string;
      };
      query: FlagVersionQueryType;
    };
    POST: {
      body: CreateFlagVersionArgsType;
      response: APIResponse<{
        flagVersion: FlagVersion;
      }>;
      params: { appId: string; flagId: string };
      query: CreateFlagVersionQueryType;
    };
  };
  "/apps/:appId/flags/:flagId/event-status": {
    GET: {
      response: APIResponse<FlagEventStatus[]>;
      params: {
        appId: string;
        flagId: string;
      };
    };
  };
  "/apps/:appId/flags/:flagId/estimate": {
    POST: {
      response: APIResponse<TargetAudienceEstimate>;
      params: {
        appId: string;
        flagId: string;
      };
      query: EnvironmentSelectionQueryType;
      body: TargetAudienceEstimateType;
    };
  };
  "/apps/:appId/flags/context/keys": {
    GET: {
      response: APIResponse<string[]>;
      params: {
        envId: string;
      };
      query: FlagEventEvalContextKeysQueryType;
    };
  };
  "/apps/:appId/flags/context/values": {
    GET: {
      response: APIResponse<string[]>;
      params: {
        envId: string;
      };
      query: FlagEventEvalContextKeyValuesQueryType;
    };
  };
}
