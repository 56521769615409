import { useQuery } from "@tanstack/react-query";

import featureViewsQueryKeys from "@/app/data/featureViewsQueryKeys";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

export function useFeatureViews() {
  const { appId } = useCurrentEnv();

  return useQuery({
    queryKey: featureViewsQueryKeys.list(appId),

    queryFn: () =>
      api
        .get<"/apps/:appId/feature-views">(`/apps/${appId}/feature-views`)
        .then((res) => res.data),
    enabled: !!appId,
  });
}
