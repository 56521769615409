import { ReactElement, ReactNode } from "react";
import { useColorModeValue } from "@chakra-ui/react";

import { GoalConfiguration } from "@bucketco/shared/goalAPI";

import FormCheckboxGroupButtons from "@/common/components/form/FormCheckboxGroupButtons";

export type GoalTemplate = {
  icon?: ReactElement;
  label: ReactNode;
  configuration: Omit<GoalConfiguration, "featureId">;
};

type Props = {
  templates: Record<string, GoalTemplate>;
  name?: string;
};

export const GoalTemplateFields = ({
  templates,
  name = "templateKeys",
}: Props) => {
  const checkedBg = useColorModeValue("brand.50", "gray.700");

  return (
    <FormCheckboxGroupButtons
      _button={{
        variant: "outline",
        gap: 2.5,
        py: 6,
        px: 4,
        fontSize: "md",
        justifyContent: "flex-start",
        _checked: {
          bg: checkedBg,
        },
        _check: {
          ml: "auto",
        },
      }}
      display="grid"
      flexDirection="column"
      gap={2}
      gridTemplateColumns="1fr 1fr"
      name={name}
      options={Object.entries(templates).map(([key, { icon, label }]) => ({
        key,
        icon,
        label,
      }))}
      spacing={0}
    />
  );
};
