import { ReactElement, ReactNode } from "react";
import { RiCheckboxCircleFill } from "react-icons/ri";
import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  Text,
  useCheckbox,
  useCheckboxGroup,
  UseCheckboxGroupProps,
  UseCheckboxProps,
  useColorModeValue,
} from "@chakra-ui/react";

import MotionBox, { MotionBoxProps } from "@/common/components/MotionBox";
import { focusStyle } from "@/theme/helpers";

type CheckboxGroupButtonsOption = {
  icon?: ReactElement;
  key: string;
  label: ReactNode;
};

export type CheckboxButtonProps = Omit<ButtonProps, keyof UseCheckboxProps> & {
  _check?: MotionBoxProps;
};

function CheckboxButton({
  children,
  _check,
  ...props
}: UseCheckboxProps & CheckboxButtonProps) {
  const { getInputProps, getCheckboxProps, getRootProps } = useCheckbox(props);

  const root = getRootProps();
  const input = getInputProps();
  const checkbox = getCheckboxProps();
  return (
    <Button
      as="label"
      color="dimmed"
      cursor="pointer"
      display="flex"
      fontSize="sm"
      fontWeight="medium"
      gap={2}
      variant="outline"
      {...root}
      _checked={{
        color: "inherit",
        ...root._checked,
      }}
      _focusWithin={{ ...focusStyle, zIndex: 1, ...root._focusWithin }}
    >
      <input {...input} />
      {children}
      <MotionBox
        {...(checkbox as any)}
        animate={{ scale: props.isChecked ? 1 : 0 }}
        color={useColorModeValue("brand.500", "brand.400")}
        initial={{ scale: 0 }}
        {..._check}
      >
        <RiCheckboxCircleFill size={20} />
      </MotionBox>
    </Button>
  );
}

export type CheckboxGroupButtonsProps = Omit<
  ButtonGroupProps,
  "onChange" | "children"
> &
  UseCheckboxGroupProps & {
    options: CheckboxGroupButtonsOption[];
    checkPlacement?: "inline" | "top-right";
    _button?: CheckboxButtonProps;
  };

export function CheckboxGroupButtons({
  options,
  value,
  defaultValue,
  isDisabled,
  isNative,
  onChange,
  _button,
  ...rest
}: CheckboxGroupButtonsProps) {
  const { getCheckboxProps } = useCheckboxGroup({
    value,
    defaultValue,
    isDisabled,
    isNative,
    onChange,
  });
  return (
    <ButtonGroup {...rest}>
      {options.map(({ icon, key, label }) => {
        const checkbox = getCheckboxProps({ value: key });
        return (
          <CheckboxButton key={key} {...checkbox} {..._button}>
            {icon && icon}
            {typeof label === "string" ? (
              <Text textAlign="left">{label}</Text>
            ) : (
              label
            )}
          </CheckboxButton>
        );
      })}
    </ButtonGroup>
  );
}
