import { z } from "zod";

export type AttributeFieldIcons =
  | "id"
  | "text"
  | "calendar"
  | "group"
  | "active"
  | "custom";

export type AttributeFilterType =
  | "number"
  | "text"
  | "list"
  | "boolean"
  | "date"
  | "any"
  | "segment"
  | "featureTargeting";

export interface AttributeField {
  key: string;
  label: string;
  type?: AttributeFilterType;
  icon?: AttributeFieldIcons;
  system: boolean;
}

export const COMPANY_ID_FIELD_KEY = "$company_id";
export const COMPANY_ID_SYSTEM_ATTR = {
  key: COMPANY_ID_FIELD_KEY,
  label: "Company ID",
  type: "text",
  icon: "id",
  system: true,
} as const;

export const FIRST_SEEN_FIELD_KEY = "$first_seen";
export const FIRST_SEEN_SYSTEM_ATTR = {
  key: FIRST_SEEN_FIELD_KEY,
  label: "First seen",
  type: "date",
  icon: "calendar",
  system: true,
} as const;

export const LAST_SEEN_FIELD_KEY = "$last_seen";
export const LAST_SEEN_SYSTEM_ATTR = {
  key: LAST_SEEN_FIELD_KEY,
  label: "Last seen",
  type: "date",
  icon: "calendar",
  system: true,
} as const;

export const systemAttributeFieldKeys = [
  COMPANY_ID_FIELD_KEY,
  FIRST_SEEN_FIELD_KEY,
  LAST_SEEN_FIELD_KEY,
];

export const systemAttributeFields: AttributeField[] = [
  COMPANY_ID_SYSTEM_ATTR,
  FIRST_SEEN_SYSTEM_ATTR,
  LAST_SEEN_SYSTEM_ATTR,
];

export const attributeFilterOperatorsList = [
  // text, numbers and booleans
  "IS",
  "IS_NOT",

  // text, numbers and boolean arrays
  "ANY_OF",
  "NOT_ANY_OF",

  // numbers
  "GT",
  "LT",

  // text
  "CONTAINS",
  "NOT_CONTAINS",

  // date
  "AFTER",
  "BEFORE",

  // boolean
  "IS_TRUE",
  "IS_FALSE",

  // anything
  "SET",
  "NOT_SET",

  // segment
  "SEGMENT",
  "NOT_SEGMENT",

  // feature flag
  "FLAG",
  "NOT_FLAG",
] as const;

export const operatorsWithoutValue = ["IS_TRUE", "IS_FALSE", "SET", "NOT_SET"];

export type AttributeFilterOperator =
  (typeof attributeFilterOperatorsList)[number];

export type AttributeFilterRuleType = z.infer<typeof AttributeFilterRuleSchema>;
export type AttributeFilter = AttributeFilterRuleType[];

export const numberOperators: AttributeFilterOperator[] = [
  "GT",
  "LT",
  "AFTER",
  "BEFORE",
];

export const listOperators: AttributeFilterOperator[] = [
  "ANY_OF",
  "NOT_ANY_OF",
];

export const segmentOperators: AttributeFilterOperator[] = [
  "SEGMENT",
  "NOT_SEGMENT",
];

// from https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
export function isNumeric(str: unknown) {
  if (typeof str != "string") return false; // we only support strings!
  return (
    !isNaN(str as unknown as number) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export const BaseAttributeFilterRuleSchema = z.object({
  field: z.string().min(1),
  operator: z.enum(attributeFilterOperatorsList),
  // value: z.string().optional(),
  values: z.array(z.string()),
  readOnly: z.boolean().optional(),
});

export const AttributeFilterRuleSchema =
  BaseAttributeFilterRuleSchema.transform((v) => {
    // if (!listOperators.includes(v.operator) && v.value) {
    //   return { ...v, values: [v.value] };
    // }
    return v;
  }).superRefine(({ operator, values }, ctx) => {
    if (numberOperators.includes(operator) && !isNumeric(values[0])) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "value must a number",
        path: ["values", 0],
      });
    }
    if (listOperators.includes(operator) && !values?.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "values must contain at least one element",
        path: ["values", 0],
      });
    }

    if (
      typeof values[0] === "undefined" &&
      !operatorsWithoutValue.includes(operator)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "value must be set",
        path: ["values", 0],
      });
    }
  });

export const TypeByOperator: {
  [key in AttributeFilterOperator]: AttributeFilterType;
} = {
  GT: "number",
  LT: "number",
  IS: "text", // also works for numbers and booleans
  IS_NOT: "text", // also works for numbers and booleans
  ANY_OF: "list", // also works for numbers and booleans
  NOT_ANY_OF: "list", // also works for numbers and booleans
  CONTAINS: "text",
  NOT_CONTAINS: "text",
  IS_TRUE: "boolean",
  IS_FALSE: "boolean",
  AFTER: "date",
  BEFORE: "date",
  SET: "any",
  NOT_SET: "any",
  SEGMENT: "segment",
  NOT_SEGMENT: "segment",
  FLAG: "featureTargeting",
  NOT_FLAG: "featureTargeting",
};

export const OperatorsByType: {
  [key in AttributeFilterType]: AttributeFilterOperator[];
} = {
  any: ["IS", "IS_NOT", "SET", "NOT_SET"],
  text: ["CONTAINS", "NOT_CONTAINS"],
  list: ["ANY_OF", "NOT_ANY_OF"],
  number: ["LT", "GT"],
  boolean: ["IS_TRUE", "IS_FALSE"],
  date: ["AFTER", "BEFORE"],
  segment: ["SEGMENT", "NOT_SEGMENT"],
  featureTargeting: ["FLAG", "NOT_FLAG"],
};

export function relevantOperators(types: AttributeFilterType[]) {
  const operators = { ...OperatorsByType };

  Object.keys(OperatorsByType).forEach((key) => {
    if (!types.includes(key as AttributeFilterType)) {
      operators[key as AttributeFilterType] = [];
    }
  });

  return operators;
}

export const defaultTypeValues: { [key in AttributeFilterType]: any } = {
  text: [""],
  number: ["0"],
  boolean: [""],
  date: ["0"],
  any: [""],
  list: [],
  segment: "",
  featureTargeting: "",
};

export const operatorDisplayName: {
  [key in AttributeFilterOperator]: string;
} = {
  // text, numbers, booleans
  IS: "is",
  IS_NOT: "is not",

  // text, numbers, booleans (array)
  ANY_OF: "is any of",
  NOT_ANY_OF: "is not any of",

  // numbers
  GT: "greater than",
  LT: "less than",

  // text
  CONTAINS: "contains",
  NOT_CONTAINS: "does not contain",

  // date
  AFTER: "less than",
  BEFORE: "more than",

  // boolean
  IS_TRUE: "is true",
  IS_FALSE: "is false",

  // any
  SET: "has any value",
  NOT_SET: "has no value",

  // segment
  SEGMENT: "in segment",
  NOT_SEGMENT: "not in segment",

  // featureFlag
  FLAG: "is enabled",
  NOT_FLAG: "is not enabled",
};
