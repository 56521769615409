import { datadogRum } from "@datadog/browser-rum";

import { DEPLOY_ENV, HEROKU_SLUG_COMMIT } from "@/common/utils/env";

if (DEPLOY_ENV !== "development") {
  datadogRum.init({
    applicationId: "54398c3a-afeb-42e7-bdcc-d4c2a56d19ee",
    clientToken: "pubb4893db2a2505409d01e795f9b9121fa",
    site: "datadoghq.eu",
    service: "bucketdotco-frontend",
    env: DEPLOY_ENV,
    version: HEROKU_SLUG_COMMIT,
    sampleRate: 100,
    replaySampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingOrigins: [
      "https://staging.app.bucket.co",
      "https://app.bucket.co",
    ],
  });

  datadogRum.startSessionReplayRecording();
}

export function setDatadogUser(attributes: { [key: string]: unknown }) {
  datadogRum.setUser(attributes);
}
