import { memo, useCallback, useEffect, useMemo } from "react";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnSort } from "@tanstack/react-table";

import { ErrorResponse } from "@bucketco/shared/api";
import { systemAttributeFieldKeys } from "@bucketco/shared/attributeFilter";
import { CompaniesQuerySortByType } from "@bucketco/shared/companyAPI";
import {
  containsFeatureMetricFilter,
  FilterGroup as FilterGroupModel,
  getFilterCount,
  UIFilter,
  wrapWithFilterGroup,
} from "@bucketco/shared/filter";
import {
  CreateOrUpdateSegmentFormSchema,
  CreateOrUpdateSegmentFormType,
  SegmentDTO,
} from "@bucketco/shared/segmentAPI";
import { ColumnState } from "@bucketco/shared/types/columns";
import { SegmentUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import AffectedDependencies from "@/common/components/AffectedDependencies";
import { AndOrList } from "@/common/components/AndOrList";
import { FilterGroup } from "@/common/components/filters/FilterGroup";
import FormInput from "@/common/components/form/FormInput";
import FormSubmit from "@/common/components/form/FormSubmit";
import SearchInput from "@/common/components/SearchInput";
import TableToolbar from "@/common/components/TableToolbar";
import { useFilterCheck } from "@/common/data/useFilterCheck";
import useApiForm from "@/common/hooks/useApiForm";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import { useFormFieldArrayUpdate } from "@/common/hooks/useFormFieldArrayUpdate";
import useOutsideClickPopover from "@/common/hooks/useOutsideClickPopover";
import api from "@/common/utils/api";
import pluralize from "@/common/utils/pluralize";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { defaultCompanyColumns } from "@/company/components/CompaniesTable";
import segmentsQueryKeys from "@/company/data/segmentsQueryKeys";
import FeatureDisplay from "@/feature-legacy/components/FeatureDisplay";

type ActiveFlagsAlertProps = {
  features: {
    id: string;
    name: string;
  }[];
  canUpdateSegment: boolean;
};

const ActiveFeaturesAlert = ({
  features,
  canUpdateSegment,
}: ActiveFlagsAlertProps) => {
  if (!features?.length) {
    return null;
  }

  return (
    <Alert alignItems="flex-start" maxW="sm" status="warning">
      <AlertDescription>
        The {pluralize("feature", features.length)}{" "}
        <AndOrList
          conjunction="and"
          direction="horizontal"
          display="inline-flex"
          verticalAlign="bottom"
        >
          {features.map((f) => (
            <FeatureDisplay key={f.id} display="inline-flex" feature={f} link />
          ))}
        </AndOrList>{" "}
        {pluralize("is", features.length)} used by this segment.{" "}
        {!canUpdateSegment ? (
          <>
            which includes prohibited <strong>First seen</strong>,{" "}
            <strong>Last seen</strong> or <strong>feature metrics</strong>{" "}
            filters. Remove these to update or create a new segment.
          </>
        ) : (
          <>
            Filter changes may affect the number of companies with access to the{" "}
            {pluralize("feature", features.length)}. Review the filters before
            saving or create a new segment to avoid unexpected changes.
          </>
        )}
      </AlertDescription>
    </Alert>
  );
};

function FiltersSave({
  isColumnStatesDirty,
  segment,
  onClear,
}: {
  segment: SegmentDTO;
  isColumnStatesDirty: boolean;
  onClear: () => void;
}) {
  const {
    formState: { isDirty: isFormDirty },
    watch,
    control,
    getFieldState,
    setValue,
  } = useFormContext();

  const errorToast = useErrorToast();

  const currentAction = watch("action");

  const { isOpen, onToggle, onClose } = useDisclosure();
  const { contentRef, triggerRef } = useOutsideClickPopover({
    isOpen,
    onToggle,
  });

  const updatedFilter = watch("companyFilter");

  const {
    isLoading: isChecking,
    isError: checkError,
    data: checkDetails,
  } = useFilterCheck(
    isOpen ? { type: "segment", id: segment.id } : undefined,
    updatedFilter,
  );

  useEffect(() => {
    if (checkError) {
      errorToast({
        title: "Failed check segment filter",
        description: "Please try again later",
      });
    }
  }, [checkError, errorToast]);

  const canUpdateSegment = useMemo(() => {
    if (isChecking || checkError) {
      return false;
    }

    if (
      checkDetails?.cycle ||
      (checkDetails?.features.length && !checkDetails?.stateless)
    ) {
      return false;
    }

    return true;
  }, [checkDetails, isChecking, checkError]);

  useEffect(() => {
    if (currentAction === "update" && !isChecking && !canUpdateSegment) {
      setValue("action", "create");
    }
  }, [currentAction, canUpdateSegment, setValue, isChecking]);

  const warningColor = useColorModeValue("orange.500", "orange.400");

  const isDirty = isFormDirty || isColumnStatesDirty;
  const isFilterChange = getFieldState("companyFilter").isDirty;
  const isColumnChange = isColumnStatesDirty;

  return (
    <HStack spacing={4}>
      {isDirty && (
        <>
          <Divider alignSelf="stretch" h="auto" orientation="vertical" />
          <Text
            color="dimmed"
            fontSize="sm"
            fontStyle="italic"
            whiteSpace="nowrap"
          >
            {isFilterChange || isColumnChange ? "Unsaved" : ""}
          </Text>
          <Button isLoading={isChecking} variant="outline" onClick={onClear}>
            Clear
          </Button>
        </>
      )}
      <Popover
        autoFocus={false}
        closeOnEsc={true}
        isOpen={isOpen}
        placement="bottom-start"
        trigger="click"
        onClose={onClose}
      >
        <PopoverTrigger>
          <Button
            ref={triggerRef}
            isDisabled={!isDirty || checkError}
            isLoading={isChecking}
            variant={isDirty ? "primary" : "outline"}
            onClick={onToggle}
          >
            Save segment
          </Button>
        </PopoverTrigger>
        <PopoverContent ref={contentRef} w="auto">
          <PopoverBody minW="sm" p={5}>
            <PopoverArrow />
            <VStack align={"start"}>
              {isOpen && (
                <AffectedDependencies
                  changeType="update"
                  dependee={{ type: "segment", id: segment.id }}
                  filter={updatedFilter}
                />
              )}

              {!!checkDetails?.features.length && isFilterChange && (
                <ActiveFeaturesAlert
                  canUpdateSegment={canUpdateSegment}
                  features={checkDetails.features}
                />
              )}
              <Text color="dimmed" whiteSpace="nowrap">
                {isFilterChange && isColumnChange
                  ? "Filters and column configuration unsaved"
                  : isFilterChange
                  ? "Filters unsaved"
                  : isColumnChange
                  ? "Column configuration unsaved"
                  : ""}
              </Text>
              <Controller
                control={control}
                name="action"
                render={({ field }) => (
                  <RadioGroup {...field} colorScheme="brand" size="sm">
                    <Flex direction="column" gap={2}>
                      <Radio isDisabled={!canUpdateSegment} value="update">
                        Update existing &quot;{segment.name}&quot; segment
                      </Radio>
                      <Radio value="create">Create a new segment</Radio>
                      {field.value === "create" && (
                        <Box maxW={48}>
                          <FormInput
                            autoComplete="off"
                            name="name"
                            placeholder="Segment name"
                            size="sm"
                            shouldUnregister
                          />
                        </Box>
                      )}
                    </Flex>
                  </RadioGroup>
                )}
              />
              <FormSubmit
                isDisabled={!isDirty || !!checkDetails?.cycle}
                mt={4}
                size="sm"
              >
                {currentAction === "update"
                  ? "Update segment"
                  : "Create segment"}
              </FormSubmit>
              {currentAction === "update" && segment.isAllSegment ? (
                <Text color={warningColor} fontSize="sm" maxW="72" mt={3}>
                  Filters can&apos;t be changed on the &quot;{segment.name}
                  &quot; segment. Only column configuration will be saved.
                </Text>
              ) : null}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </HStack>
  );
}

type SegmentFiltersProps = {
  segment: SegmentDTO;
  filter: UIFilter;
  onFilterChange: (filters: UIFilter) => void;
  columnStates: ColumnState[];
  setColumnStates: (
    nextColumnStates: ColumnState[],
    isPersisted: boolean,
  ) => void;
  columnSort: ColumnSort;
  setColumnSort: (nextColumnSort: ColumnSort, isPersisted: boolean) => void;
  isColumnStatesDirty: boolean;
  resetColumns: (states?: boolean, sort?: boolean) => void;
  searchQuery: string;
  setSearchQuery: (nextSearchQuery: string) => void;
};

function SegmentFilters({
  segment,
  filter,
  onFilterChange,
  columnStates: propColumnStates,
  setColumnStates,
  columnSort,
  setColumnSort,
  isColumnStatesDirty,
  resetColumns,
  searchQuery,
  setSearchQuery,
}: SegmentFiltersProps) {
  const { appId, envId } = useCurrentEnv();

  const toast = useToast();
  const errorToast = useErrorToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { currentEnv } = useAuthContext();

  const rootFilter = useMemo<FilterGroupModel<UIFilter>>(
    () => wrapWithFilterGroup(segment.companyFilter),
    [segment.companyFilter],
  );

  const columnStates = useMemo(
    () =>
      propColumnStates.length
        ? propColumnStates
        : defaultCompanyColumns.map((id) => ({
            id,
            shown: true,
          })),
    [propColumnStates],
  );

  const { form, handleSubmit } = useApiForm(
    async (data: CreateOrUpdateSegmentFormType) => {
      switch (data.action) {
        case "update": {
          const payload = segment.isAllSegment
            ? {
                columns: columnStates,
                columnSortKey: columnSort.id as CompaniesQuerySortByType,
                columnSortDesc: columnSort.desc,
              }
            : {
                companyFilter: data.companyFilter,
                columns: columnStates,
                columnSortKey: columnSort.id as CompaniesQuerySortByType,
                columnSortDesc: columnSort.desc,
              };

          return api
            .patch<"/apps/:appId/segments/:segmentId">(
              `/apps/${appId}/segments/${segment.id}`,
              payload,
            )
            .then((res) => res.data);
        }
        case "create": {
          const res = await api
            .post<"/apps/:appId/segments">(`/apps/${appId}/segments`, {
              name: data.name,
              companyFilter: data.companyFilter,
              columns: columnStates,
              columnSortKey: columnSort?.id as
                | CompaniesQuerySortByType
                | undefined,
              columnSortDesc: columnSort?.desc,
            })
            .then((res) => res.data);

          return res;
        }
        default: {
          throw new Error("invalid action");
        }
      }
    },
    CreateOrUpdateSegmentFormSchema,
    {
      onSuccess: async (segment, { action }) => {
        await queryClient.invalidateQueries({
          queryKey: segmentsQueryKeys.list(appId, envId),
        });
        switch (action) {
          case "update":
            setColumnStates(columnStates, true);
            setColumnSort(columnSort, true);
            segmentAnalytics.track("Segment Updated", {
              filters_count: segment.companyFilter
                ? getFilterCount(segment.companyFilter)
                : 0,
              feature_metric_filter_used: segment.companyFilter
                ? containsFeatureMetricFilter(segment.companyFilter)
                : false,
            });
            toast({
              title: "Segment updated",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
            form.reset({
              action: "update",
              companyFilter: wrapWithFilterGroup(segment.companyFilter),
            });
            break;
          case "create":
            setColumnStates(columnStates, true);
            setColumnSort(columnSort, true);
            segmentAnalytics.track("Segment Created", {
              filters_count: getFilterCount(segment.companyFilter),
              feature_metric_filter_used: segment.companyFilter
                ? containsFeatureMetricFilter(segment.companyFilter)
                : false,
            });
            toast({
              title: "New segment created",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
            navigate(SegmentUrl(currentEnv!, segment.id));
            break;
        }
      },
      onError: (error, { action }) => {
        const responseError = error.response?.data as ErrorResponse | null;
        errorToast({
          title: `Failed to ${
            action == "create" ? "create a new" : "update the"
          } segment`,
          description: responseError?.error.message,
        });
      },
    },
    {
      defaultValues: {
        name: segment.name,
        action: segment.isAllSegment ? "create" : "update",
        companyFilter: rootFilter,
      },
      mode: "all",
    },
  );

  // Update form with filter values from the URL triggering form to be dirty
  useEffect(() => {
    form.setValue("companyFilter", wrapWithFilterGroup(filter));
  }, [filter, form]);

  useFormFieldArrayUpdate(
    form,
    "companyFilter",
    useCallback(
      (value) => {
        if (value && !form.formState.isValidating && form.formState.isValid) {
          onFilterChange(value);
        }
      },
      [form.formState.isValid, form.formState.isValidating, onFilterChange],
    ),
  );

  return (
    <TableToolbar>
      <HStack alignItems="start" gap={1}>
        <Box>
          <SearchInput
            flexGrow={0}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.currentTarget.value)}
          />
        </Box>
        <Controller
          control={form.control}
          name="companyFilter"
          render={({ field: { value, onChange } }) => (
            <FilterGroup
              context="segment"
              types={[
                "companyAttribute",
                "segment",
                "featureMetric",
                "featureTargeting",
              ]}
              value={value}
              onChange={(filterGroup, filterAction) => {
                if (filterAction.action === "create") {
                  let columnId: string | undefined;
                  if (
                    filterAction.filter.type === "companyAttribute" &&
                    !systemAttributeFieldKeys.includes(
                      filterAction.filter.field,
                    )
                  ) {
                    columnId = `attributes.${filterAction.filter.field}`;
                  } else if (filterAction.filter.type === "featureMetric") {
                    columnId = `featureMetrics.${filterAction.filter.featureId}.${filterAction.filter.metric}`;
                  }
                  if (
                    columnId &&
                    columnStates.every(({ id }) => id !== columnId)
                  ) {
                    setColumnStates(
                      [
                        ...columnStates,
                        {
                          id: columnId,
                          shown: true,
                        },
                      ],
                      false,
                    );
                  }
                }

                onChange(filterGroup);
              }}
            />
          )}
        />
      </HStack>
      <form onSubmit={handleSubmit}>
        <FormProvider {...form}>
          <FiltersSave
            isColumnStatesDirty={isColumnStatesDirty}
            segment={segment}
            onClear={() => {
              form.reset({
                companyFilter: wrapWithFilterGroup(segment.companyFilter),
              });
              resetColumns();
            }}
          />
        </FormProvider>
      </form>
    </TableToolbar>
  );
}

const SegmentFiltersMemoized = memo(SegmentFilters);
export default SegmentFiltersMemoized;
