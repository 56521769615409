import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { releasesQueryKeys } from "@/release/data/releasesQueryKeys";

export function useReleaseDeleteMutation() {
  const { appId } = useCurrentEnv();

  const queryClient = useQueryClient();
  const mutationKey = releasesQueryKeys.list(appId);

  return useMutation<
    object,
    AxiosResponse<ErrorResponse>,
    { releaseId: string }
  >({
    mutationKey,
    mutationFn: ({ releaseId }) =>
      api
        .delete<"/apps/:appId/releases/:releaseId">(
          `/apps/${appId}/releases/${releaseId}`,
        )
        .then((res) => res.data),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: mutationKey });
    },
  });
}
