import { HStack, ResponsiveValue, Switch, Text } from "@chakra-ui/react";

import { SlackChannelAutocompleteSelect } from "./SlackChannelAutocompleteSelect";

type Props = {
  slackChannelId: string;
  size:
    | ResponsiveValue<(string & object) | "sm" | "md" | "lg" | "xs" | "2xs">
    | undefined;
  value: boolean;
  onChange?: (value: boolean) => void;
};

function SlackNotificationStatusToggle({
  slackChannelId,
  size = "md",
  value,
  onChange,
}: Props) {
  return (
    <HStack>
      {slackChannelId && (
        <HStack>
          <Switch
            isChecked={value}
            isReadOnly={!onChange}
            size={size == "xs" || size == "2xs" ? "sm" : size}
            onChange={(e) => {
              onChange && onChange(e.target.checked);
            }}
          />

          <Text color="gray.500" fontSize={size}>
            Send notifications to
          </Text>

          <SlackChannelAutocompleteSelect
            isActive={false}
            isDisabled={true}
            size={"xs"}
            value={slackChannelId || undefined}
            onChange={() => {}}
          />
        </HStack>
      )}
    </HStack>
  );
}

export default SlackNotificationStatusToggle;
