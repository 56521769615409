import { FeatureName } from "@bucketco/shared/featureAPI";
import { getFeatureParentNames } from "@bucketco/shared/utils/getFeatureParentNames";

import useFeatureNamesData from "@/feature-legacy/data/useFeatureNamesData";

export function useFeatureParentNames(featureId?: string): Array<FeatureName> {
  const { data: features, isSuccess } = useFeatureNamesData();

  if (!isSuccess || !featureId) {
    return [];
  }

  return getFeatureParentNames(features, featureId);
}
