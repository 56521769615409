import { TableDisplayMode } from "@/common/hooks/useTableDisplayMode";

const qk = {
  list: (appId?: string, envId?: string) =>
    ["apps", appId, "environments", envId, "companies"] as const,

  single: (appId?: string, envId?: string, companyId?: string) =>
    [...qk.list(appId, envId), companyId] as const,

  singleFeatures: (
    appId?: string,
    envId?: string,
    companyId?: string,
    params: { sortType?: TableDisplayMode } = {},
  ) =>
    params
      ? ([...qk.single(appId, envId, companyId), "features", params] as const)
      : ([...qk.single(appId, envId, companyId), "features"] as const),

  listUsers: (appId?: string, envId?: string, companyId?: string) =>
    [...qk.single(appId, envId, companyId), "users"] as const,

  listFlags: (appId?: string, envId?: string, companyId?: string) =>
    [...qk.single(appId, envId, companyId), "flags"] as const,

  attributes: (appId?: string, envId?: string) =>
    [...qk.list(appId, envId), "attributes"] as const,

  companyNames: (
    appId?: string,
    envId?: string,
    params: Record<string, any> = {},
  ) =>
    params
      ? ([...qk.list(appId, envId), "names", params] as const)
      : ([...qk.list(appId, envId), "names"] as const),
};

export default qk;
