import { useCallback, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { RiFlag2Line, RiRefreshLine } from "react-icons/ri";
import {
  Button,
  ButtonGroup,
  Center,
  chakra,
  IconButton,
  Spinner,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

import { FeatureDetail } from "@bucketco/shared/featureAPI";
import { GoalFeatureMetrics } from "@bucketco/shared/goalAPI";
import { ReleasePostArgs, ReleaseStatus } from "@bucketco/shared/releaseAPI";

import FeatureSvg from "@/common/assets/feature-icon.svg?react";
import { formatRelativeTime } from "@/common/components/TimestampCell";
import dayjs from "@/common/utils/dayjs";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { useSegments } from "@/company/data/useSegments";
import useFeatureNamesData from "@/feature-legacy/data/useFeatureNamesData";
import NewFeature from "@/feature-legacy/pages/NewFeature";
import { LegacyGoal } from "@/release/components/LegacyGoal";
import { LegacyGoalsEmptyState } from "@/release/components/LegacyGoalsEmptyState";
import { LegacyGoalTemplateModal } from "@/release/components/LegacyGoalTemplateModal";
import { SectionHeading } from "@/release/components/Shared";
import { useGoalsData } from "@/release/data/useGoalsData";
import { useReleaseRefreshMutation } from "@/release/data/useReleaseRefreshMutation";
import { useReleaseEditing } from "@/release/hooks/useReleaseEditing";

const FeatureIcon = chakra(FeatureSvg);

type GoalsProps = {
  releaseId?: string;
  releasedAt?: string | null;
  evaluationPeriod?: number;
  releaseStatus?: ReleaseStatus;
  onAppend?: () => void;
  onRemove?: () => void;
};

export const Goals = ({
  releaseId,
  releasedAt,
  evaluationPeriod,
  releaseStatus,
  onAppend,
  onRemove,
}: GoalsProps) => {
  const refreshMutation = useReleaseRefreshMutation();
  const [editing] = useReleaseEditing();
  const { control } = useFormContext<ReleasePostArgs>();
  const { data: goals = [], isInitialLoading } = useGoalsData(releaseId);
  const { data: featureNames = [] } = useFeatureNamesData();
  const { data: segments = [] } = useSegments({
    includeDeleted: true,
  });

  const [templateFeature, setTemplateFeature] = useState<FeatureDetail | null>(
    null,
  );
  const {
    isOpen: isNewFeatureModalOpen,
    onOpen: onNewFeatureModalOpen,
    onClose: onNewFeatureModalClose,
  } = useDisclosure();
  const {
    isOpen: isTemplateModalOpen,
    onOpen: onTemplateModalOpen,
    onClose: onTemplateModalClose,
  } = useDisclosure();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "goals",
  });
  const lastEvaluatedAt = useMemo(() => {
    const evaluatedAt = dayjs.max(
      goals.flatMap((goal) =>
        goal.evaluatedAt ? [dayjs.utc(goal.evaluatedAt)] : [],
      ),
    );
    return evaluatedAt;
  }, [goals]);

  const dates = useMemo(() => {
    // today or the release date, whichever is earlier
    const baseDate = dayjs.min(
      dayjs.utc(),
      dayjs.utc(releasedAt ?? undefined),
    )!;
    return {
      startDate: baseDate.subtract(7, "days").toDate(),
      endDate: baseDate.add(evaluationPeriod ?? 14, "days").toDate(),
    };
  }, [evaluationPeriod, releasedAt]);

  const onAppendNewGoal = useCallback(() => {
    append({
      configuration: {
        featureId: null as any, // Invalid initial state
        metric: "adopted",
        subsegmentId: null,
        threshold: GoalFeatureMetrics["adopted"].defaultThreshold,
      },
    });
    onAppend?.();
    segmentAnalytics.track("Goal Draft Added", {
      releaseId,
    });
  }, [append, onAppend, releaseId]);

  return (
    <VStack align="flexStart" spacing={6}>
      <SectionHeading
        actions={
          editing && (
            <ButtonGroup>
              <Button
                leftIcon={<FeatureIcon boxSize="1em" />}
                size="sm"
                variant="outline"
                onClick={onNewFeatureModalOpen}
              >
                New feature
              </Button>
              <Button
                leftIcon={<RiFlag2Line />}
                size="sm"
                variant="outline"
                onClick={onAppendNewGoal}
              >
                Add Goal
              </Button>
            </ButtonGroup>
          )
        }
        mb={0}
      >
        Goals
        {releaseId && lastEvaluatedAt && (
          <>
            {" "}
            (last evaluated:{" "}
            {formatRelativeTime(lastEvaluatedAt, dayjs.utc(), "second")}
            {releaseStatus !== "concluded" &&
              dayjs().isAfter(lastEvaluatedAt.add(1, "minute")) && (
                <IconButton
                  aria-label="Refresh release's goals"
                  icon={<RiRefreshLine size={16} />}
                  size="2xs"
                  variant="ghost"
                  onClick={() => {
                    refreshMutation.mutate({ releaseId });
                  }}
                />
              )}
            )
          </>
        )}
      </SectionHeading>
      {editing ? (
        fields.length ? (
          fields.map((goal, index) => (
            <LegacyGoal
              {...dates}
              key={goal.id}
              configuration={goal.configuration}
              featureNames={featureNames}
              index={index}
              releasedAt={releasedAt}
              segments={segments}
              onRemove={() => {
                remove(index);
                onRemove?.();
                segmentAnalytics.track("Goal Draft Removed", {
                  releaseId,
                  configuration: goal.configuration,
                });
              }}
            />
          ))
        ) : (
          <LegacyGoalsEmptyState
            onAppendGoal={onAppendNewGoal}
            onCreateFeature={onNewFeatureModalOpen}
          />
        )
      ) : !releaseId || isInitialLoading ? (
        <Center py={12}>
          <Spinner size="sm" />
        </Center>
      ) : goals.length ? (
        goals.map((goal, index) => (
          <LegacyGoal
            {...dates}
            {...goal}
            key={goal.id}
            featureNames={featureNames}
            index={index}
            releasedAt={releasedAt}
            segments={segments}
            onRemove={() => {
              remove(index);
              onRemove?.();
              segmentAnalytics.track("Goal Removed", {
                releaseId,
              });
            }}
          />
        ))
      ) : (
        <LegacyGoalsEmptyState />
      )}
      <NewFeature
        footerDetails="Step 1 of 2"
        isOpen={isNewFeatureModalOpen}
        onClose={onNewFeatureModalClose}
        onSuccess={(feature) => {
          onNewFeatureModalClose();
          setTemplateFeature(feature);
          onTemplateModalOpen();
        }}
      />
      {templateFeature && (
        <LegacyGoalTemplateModal
          key={templateFeature.id}
          feature={templateFeature}
          isOpen={isTemplateModalOpen}
          onClose={onTemplateModalClose}
          onSuccess={(configurations) => {
            for (const configuration of configurations) {
              append({ configuration });
              segmentAnalytics.track("Goal Draft Added", {
                releaseId,
              });
            }
            onAppend?.();
            onTemplateModalClose();
          }}
        />
      )}
    </VStack>
  );
};
