import { useParams } from "react-router-dom";

import { useSearchParam } from "@/common/hooks/useSearchParam";

export const useReleaseEditing = () => {
  const { releaseId } = useParams();
  return useSearchParam<boolean>("editing", {
    fallback: !releaseId ? true : false,
    serialize: (value) => (value ? "true" : "false"),
    deserialize: (value) => value === "true",
  });
};
