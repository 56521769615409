import {
  ColumnDef,
  ColumnOrderState,
  VisibilityState,
} from "@tanstack/react-table";

import { ColumnState } from "@bucketco/shared/types/columns";

export const stickyVerticalProps = {
  position: "sticky",
  top: 0,
  zIndex: 1,
  backgroundColor: "appBackground",
} as const;

export const stickyHorizontalProps = (
  borderRightColor = "transparent",
  zIndex = 1,
) =>
  ({
    position: "sticky",
    left: 0,
    zIndex,
    backgroundColor: "appBackground",
    borderRightWidth: 1,
    borderRightStyle: "solid",
    borderRightColor,
    transition: "border-right-color 100ms",
  }) as const;

// Uses the same formatting and fallbacks as the one in @tanstack/react-table
export function getId<D extends Record<string, unknown>>(
  column: ColumnDef<D>,
): string | null {
  if (column.id) {
    return column.id;
  }
  if ("accessorKey" in column && typeof column.accessorKey === "string") {
    return column.accessorKey.replace(/\./g, "_");
  }
  if ("header" in column && typeof column.header === "string") {
    return column.header;
  }
  return null;
}

export function getColumnIds<D extends Record<string, unknown>>(
  columns: ColumnDef<D>[],
) {
  return columns.flatMap((column) => {
    const id = getId(column);
    return id ? [id] : [];
  });
}

export function serializeColumnState({ id, shown }: ColumnState) {
  return `${!shown ? "*" : ""}${id}`;
}

export function deserializeColumnState(value: string) {
  return {
    id: value.startsWith("*") ? value.slice(1) : value,
    shown: !value.startsWith("*"),
  };
}

export function getVisibilityFromIds<TData extends Record<string, unknown>>(
  columns: ColumnDef<TData, unknown>[],
  visibleIds: string[],
): VisibilityState {
  return Object.fromEntries(
    columns.map((c) => {
      const id = getId(c);
      if (id) {
        return [id, visibleIds.includes(id)];
      }

      return [];
    }),
  );
}

export function getVisibilityFromStates<TData extends Record<string, unknown>>(
  columns: ColumnDef<TData, unknown>[],
  partialStates: ColumnState[],
  defaultColumns: Array<string>,
  frozenColumnId: string | null,
): VisibilityState {
  return Object.fromEntries(
    columns.flatMap((column) => {
      const id = getId(column);
      if (!id) return [];
      const state = partialStates.find((state) => state.id === id);
      // if the column is frozen, it should always be shown
      // else if there is a state, use shown from the state
      // for everything else, fallback to defaultColumns
      const shown =
        (id === frozenColumnId || state?.shown) ?? defaultColumns.includes(id);
      return [[id, shown]];
    }),
  );
}

export function getOrderFromStates<TData extends Record<string, unknown>>(
  columns: ColumnDef<TData, unknown>[],
  partialStates: ColumnState[],
  frozenColumnId: string | null,
): ColumnOrderState {
  return getColumnIds(columns).sort((a, b) => {
    // frozen column should always be first
    if (a === frozenColumnId) return -1;
    if (b === frozenColumnId) return 1;
    const aIndex = partialStates.findIndex((state) => state.id === a);
    const bIndex = partialStates.findIndex((state) => state.id === b);
    // if none of the columns are in partialStates, keep the original order
    if (aIndex === -1 && bIndex === -1) return 0;
    // if the column a is not in partialStates, move order backward
    if (aIndex === -1) return 1;
    // if the column b is not in partialStates, move order forward
    if (bIndex === -1) return -1;
    // otherwise, use the index from partialStates
    return aIndex - bIndex;
  });
}
