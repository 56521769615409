import { z } from "zod";

export const SCREENSHOT_USER_AGENT = "UrlBox";
export const SCREENSHOT_SELECTOR_ID = "urlbox_graph_container";
export const SCOPED_ACCESS_TOKEN_AUTH_HEADER = "featureAccessToken";

export const ScreenshotTypeList = [
  "featureAdoption",
  "featuresDigest",
  "featureViewDigest",
  "releasesDigest",
] as const;

export const ScreenshotTypeSchema = z.enum(ScreenshotTypeList);

export type ScreenshotType = z.infer<typeof ScreenshotTypeSchema>;
