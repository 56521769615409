import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { FeatureFeedbackCampaignDTO } from "@bucketco/shared/featureFeedbackAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import featureQueryKeys from "@/feature-legacy/data/featureQueryKeys";

export default function useFeatureFeedbackCampaignData(featureId?: string) {
  const { appId, envId } = useCurrentEnv();

  return useQuery<FeatureFeedbackCampaignDTO, AxiosError<ErrorResponse>>({
    queryKey: featureQueryKeys.singleFeatureFeedbackCampaign(
      appId,
      envId,
      featureId,
    ),

    queryFn: () =>
      api
        .get<"/apps/:appId/features/:featureId/feedback/campaigns">(
          `/apps/${appId}/features/${featureId}/feedback/campaigns`,
          { params: { envId: envId! } },
        )
        .then((res) => res.data[0] ?? null),
    enabled: !!appId && !!envId && !!featureId,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
