import { FormProvider } from "react-hook-form";
import { useToast, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

import {
  PatchOrgArgsSchema,
  PatchOrgArgsType,
} from "@bucketco/shared/organizationAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import FormInput from "@/common/components/form/FormInput";
import { FormRootError } from "@/common/components/form/FormRootError";
import FormSubmit from "@/common/components/form/FormSubmit";
import commonQueryKeys from "@/common/data/commonQueryKeys";
import useApiForm from "@/common/hooks/useApiForm";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import OrganizationLogoPicker from "@/global-settings/components/OrganizationLogoPicker";

export default function GeneralOrganization() {
  const { currentOrg } = useAuthContext();
  const orgId = currentOrg!.id;

  const toast = useToast();
  const queryClient = useQueryClient();

  const { form, handleSubmit } = useApiForm(
    (data: PatchOrgArgsType) =>
      api
        .patch<"/orgs/:orgId">(`/orgs/${orgId}`, data)
        .then((res) => res.data.org),
    PatchOrgArgsSchema,
    {
      onSuccess: (org) => {
        segmentAnalytics.track("Org Options Updated");
        toast({
          title: "Settings saved",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        queryClient.invalidateQueries({ queryKey: commonQueryKeys.bootstrap });
        form.reset({
          name: org.name,
          logoUrl: org.logoUrl,
        });
      },
    },
    {
      defaultValues: {
        name: currentOrg?.name,
        logoUrl: currentOrg?.logoUrl,
      },
    },
  );
  return (
    <form onSubmit={handleSubmit}>
      <FormProvider {...form}>
        <VStack align="flex-start" maxW="compactForm" spacing={4}>
          <FormInput
            autoComplete="organization"
            label="Organization name"
            name="name"
          />
          <OrganizationLogoPicker />

          <FormRootError />

          <FormSubmit>Save</FormSubmit>
        </VStack>
      </FormProvider>
    </form>
  );
}
