import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";

import { EnvironmentUrl } from "@bucketco/shared/urls";

import AdminIndex from "@/admin";
import RequireAdmin from "@/admin/components/RequireAdmin";
import AdminOrg from "@/admin/pages/AdminOrg";
import AppLayout from "@/app/components/AppLayout";
import GetStarted from "@/app/pages/GetStarted";
import Invite from "@/app/pages/Invite";
import NewApp from "@/app/pages/NewApp";
import Tracking from "@/app/pages/Tracking";
import TrackingDistinct from "@/app/pages/TrackingDistinct";
import TrackingRecent from "@/app/pages/TrackingRecent";
import GoToHome from "@/auth/components/GoToHome";
import OrgSuspended from "@/auth/components/OrgSuspended";
import RequireAuth from "@/auth/components/RequireAuth";
import RequireEnvironment from "@/auth/components/RequireEnvironment";
import RequireUnsuspendedOrg from "@/auth/components/RequireUnsuspendedOrg";
import { AuthProvider, useAuthContext } from "@/auth/contexts/authContext";
import Login from "@/auth/pages/Login";
import { FeatureFlagsProvider } from "@/common/hooks/useFeatureFlags";
import Companies from "@/company/pages/Companies";
import Company from "@/company/pages/Company";
import { CompanyAttributes } from "@/company/pages/CompanyAttributes";
import CompanyFeatures from "@/company/pages/CompanyFeatures";
import CompanyFeedback from "@/company/pages/CompanyFeedback";
import CompanyUsers from "@/company/pages/CompanyUsers";
import Feature2 from "@/feature/pages/Feature";
import FeatureEvaluate from "@/feature/pages/FeatureEvaluate";
import FeatureSettings from "@/feature/pages/FeatureSettings";
import FeatureTargeting from "@/feature/pages/FeatureTargeting";
import FeatureTargetingVersions from "@/feature/pages/FeatureTargetingVersions";
import {
  EditFeedback,
  NewFeedback,
} from "@/feature-legacy/components/NewFeedback";
import { OrphanFeatureDropTarget } from "@/feature-legacy/components/OrphanFeatureDropTarget";
import Feature from "@/feature-legacy/pages/Feature";
import FeatureAdoptionScreenshot from "@/feature-legacy/pages/FeatureAdoptionScreenshot";
import FeatureCompanies from "@/feature-legacy/pages/FeatureCompanies";
import FeatureFeedback from "@/feature-legacy/pages/FeatureFeedback";
import FeatureIntegrations from "@/feature-legacy/pages/FeatureIntegrations";
import FeatureReport from "@/feature-legacy/pages/FeatureReport";
import Features from "@/feature-legacy/pages/Features";
import FeaturesDigestScreenshot from "@/feature-legacy/pages/FeaturesDigestScreenshot";
import FeatureSettingsLegacy, {
  PromptModal,
} from "@/feature-legacy/pages/FeatureSettings";
import NewFeature from "@/feature-legacy/pages/NewFeature";
import ReleasesDigestScreenshot from "@/feature-legacy/pages/ReleasesDigestScreenshot";
import Feedbacks from "@/feedback/pages/Feedbacks";
import FlagSettings from "@/flags/components/FlagSettings";
import FlagTargeting from "@/flags/components/FlagTargeting";
import FlagVersions from "@/flags/components/FlagVersions";
import Flag from "@/flags/pages/Flag";
import Flags from "@/flags/pages/Flags";
import NewFlagPage from "@/flags/pages/NewFlag";
import GlobalSettings from "@/global-settings/pages/GlobalSettings";
import { NonProductionLockdown } from "@/release/components/NonProductionLockdown";
import CreateReleaseRoute from "@/release/pages/CreateReleaseRoute";
import Release from "@/release/pages/Release";
import Releases from "@/release/pages/Releases";
import InvalidRoute from "../pages/InvlidRoute";

import "@/app/styles/app.css";

export default function AppRoutes() {
  return (
    <Routes>
      <Route
        element={<FeaturesDigestScreenshot />}
        path="apps/:appId/screenshot/features-digest"
      />
      <Route
        element={<ReleasesDigestScreenshot />}
        path="apps/:appId/screenshot/releases-digest"
      />
      <Route
        element={<FeatureAdoptionScreenshot />}
        path="apps/:appId/features/:featureId/screenshot/feature-adoption"
      />
      <Route element={<Login />} path="login" />
      <Route element={<Login />} path="signup" />
      <Route
        element={
          <AuthProvider>
            <FeatureFlagsProvider>
              <RequireAuth>
                <RequireUnsuspendedOrg>
                  <Outlet />
                </RequireUnsuspendedOrg>
              </RequireAuth>
            </FeatureFlagsProvider>
          </AuthProvider>
        }
      >
        <Route element={<GoToHome />} path="/" />
        <Route element={<OrgSuspended />} path="org-suspended" />
        <Route element={<RequireAdmin />} path="admin">
          <Route element={<AdminIndex />} index />
          <Route element={<AdminOrg />} path=":orgId" />
        </Route>

        <Route
          element={<Invite />}
          path="/orgs/:orgName-:orgId/invite/:inviteKey"
        />
        <Route element={<GetStarted />} path="get-started" />
        <Route element={<NewApp />} path="apps/new" />
        <Route element={<LegacyAppRedirect />} path="apps/:appName-:appId/*" />

        <Route
          element={
            <RequireEnvironment>
              <AppLayout>
                <Outlet />
                <OrphanFeatureDropTarget />
              </AppLayout>
            </RequireEnvironment>
          }
          path="envs/:envName-:envId"
        >
          <Route element={<Navigate to="features" replace />} path="" />

          <Route element={<NonProductionLockdown />} path="releases">
            <Route element={<Releases />} path="" />
            <Route element={<CreateReleaseRoute />} path="new" />
            <Route element={<Release />} path=":releaseId" />
          </Route>

          <Route path="settings">
            <Route element={<Navigate to="org-general" replace />} path="" />
            <Route element={<GlobalSettings />} path=":section" />
          </Route>
          <Route element={<Flags />} path="flags">
            <Route element={<NewFlagPage />} path="new" />
          </Route>

          <Route element={<Flag />} path="flags/:flagId">
            <Route element={<Navigate to="targeting" replace />} path="" />
            <Route element={<FlagTargeting />} path="targeting" />
            <Route element={<FlagVersions />} path="versions" />
            <Route element={<FlagSettings />} path="settings" />
          </Route>
          <Route element={<Companies />} path="companies"></Route>
          <Route element={<Company />} path="companies/:companyId">
            <Route element={<Navigate to="features" replace />} path="" />
            <Route element={<CompanyFeatures />} path="features" />
            <Route element={<CompanyFeedback />} path="feedback">
              <Route element={<NewFeedback />} path="new" />
              <Route element={<EditFeedback />} path="edit/:feedbackId" />
            </Route>
            <Route element={<CompanyUsers />} path="users" />
            <Route element={<CompanyAttributes />} path="attributes" />
          </Route>
          <Route element={<Tracking />} path="tracking">
            <Route element={<Navigate to="recent" replace />} path="" />
            <Route element={<TrackingRecent />} path="recent" />
            <Route element={<TrackingDistinct />} path="distinct" />
          </Route>
          {/* Legacy feature URLS */}
          <Route element={<Features />} path="features">
            <Route element={<NewFeature />} path="new" />
          </Route>
          <Route element={<Feature />} path="features/:featureName-:featureId">
            <Route element={<Navigate to="report" replace />} path="" />
            <Route
              element={<Navigate to="../report" replace />}
              path="overview"
            />

            <Route element={<NewFeature duplicate />} path="duplicate" />
            <Route element={<FeatureReport />} path="report" />
            <Route element={<FeatureFeedback />} path="feedback">
              <Route element={<NewFeedback />} path="new" />
              <Route element={<EditFeedback />} path="edit/:feedbackId" />
            </Route>
            <Route element={<FeatureSettingsLegacy />} path="settings">
              <Route element={<PromptModal />} path="prompt" />
            </Route>
            <Route element={<FeatureIntegrations />} path="integrations" />
            <Route
              element={<LegacySegmentRedirect />}
              path="companies/:segment"
            />
            <Route element={<FeatureCompanies />} path="companies" />
          </Route>
          {/* New feature URLS */}
          <Route
            element={<Feature2 />}
            path="features2/:featureName-:featureId"
          >
            <Route element={<Navigate to="evaluate" replace />} path="" />
            <Route element={<FeatureEvaluate />} path="evaluate" />
            <Route element={<FeatureTargeting />} path="targeting">
              <Route element={<FeatureTargetingVersions />} path="versions" />
            </Route>
            <Route path="settings">
              <Route element={<Navigate to="general" replace />} path="" />
              <Route element={<FeatureSettings />} path=":section" />
            </Route>
          </Route>
          <Route element={<Feedbacks />} path="feedback">
            <Route element={<NewFeedback />} path="new" />
            <Route element={<EditFeedback />} path="edit/:feedbackId" />
          </Route>
        </Route>
      </Route>

      <Route element={<InvalidRoute />} path="*" />
    </Routes>
  );
}

// TODO: Remove this once we think the apps/:appName-:appId/* route is no longer in use
function LegacyAppRedirect() {
  const { currentOrg, currentEnv } = useAuthContext();
  const { appId, "*": splat } = useParams();

  // If there is a stored environment, redirect to that environment
  if (currentEnv?.appId === appId) {
    return <Navigate to={`${EnvironmentUrl(currentEnv!)}/${splat}`} replace />;
  }

  // Otherwise, redirect to the first environment
  const env = currentOrg?.apps.find(({ id }) => id === appId)?.environments[0];
  if (env) {
    return <Navigate to={`${EnvironmentUrl(env)}/${splat}`} replace />;
  }

  // If the app doesn't exist, redirect to the home page
  return <Navigate to="/" replace />;
}

// TODO: Remove this once we think the apps/:appName-:appId/companies/:segment route is no longer in use
function LegacySegmentRedirect() {
  const { segment } = useParams();

  return <Navigate to={`../companies?segment=${segment}`} replace />;
}
