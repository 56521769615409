import { useMemo, useRef } from "react";
import { QueryKey, useQueries, UseQueryOptions } from "@tanstack/react-query";
import dayjs from "dayjs";
import equal from "fast-deep-equal";

import {
  FeaturesMetricsHistoricalData,
  FeaturesMetricsHistoricalDataQueryType,
  SegmentedList,
} from "@bucketco/shared/featureAPI";
import { SegmentDTOWithTotalCompanies } from "@bucketco/shared/segmentAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { useSegments } from "@/company/data/useSegments";
import featureQueryKeys from "@/feature-legacy/data/featureQueryKeys";

type Params = {
  periodStart: string;
  featureIds: string[];
  subsegments?: string[];
};

type Result = {
  data: SegmentedList<FeaturesMetricsHistoricalData>[];
  isError: boolean;
  isInitialLoading: boolean;
};

type QueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  _TQueryData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  "context" | "queryKey" | "queryFn"
>;

const query = (
  appId: string | undefined,
  envId: string | undefined,
  segments: SegmentDTOWithTotalCompanies[],
  params: Omit<FeaturesMetricsHistoricalDataQueryType, "envId">,
  options?: QueryOptions,
) => {
  return {
    // Compare is used to get around the fact that we can't use the same query key
    queryKey: featureQueryKeys.listMetricsHistoricalCompare(
      appId,
      envId,
      params,
    ),
    queryFn: () =>
      api
        .get<"/apps/:appId/features/metrics/historical">(
          `/apps/${appId}/features/metrics/historical`,
          {
            params: { ...params, envId: envId! },
          },
        )
        .then(
          (res) =>
            ({
              items: res.data,
              subsegment: segments.find(
                (segment) => segment.id === params.subsegment,
              ),
            }) satisfies SegmentedList<FeaturesMetricsHistoricalData>,
        ),
    ...options,
  };
};

export function useFeaturesCompareHistoricalData(
  { periodStart, featureIds, subsegments }: Params,
  queryOptions?: QueryOptions,
): Result {
  const { appId, envId } = useCurrentEnv();

  const refResults = useRef<Result>();
  const { data: segments, isInitialLoading: isSegmentsInitialLoading } =
    useSegments();

  const params = useMemo(() => {
    const date = dayjs(periodStart);
    const daysAgo = Math.abs(date.diff(new Date(), "days"));
    const granularity = daysAgo > 30 ? "weekly" : "daily";

    return {
      granularity: granularity,
      startDate: date.toDate(),
      endDate: date.toDate(),
    } as const;
  }, [periodStart]);

  const responses = useQueries({
    queries: subsegments?.length
      ? subsegments.map((subsegment) =>
          query(
            appId,
            envId,
            segments ?? [],
            { ...params, featureIds, subsegment },
            {
              enabled:
                !!appId &&
                !!envId &&
                !isSegmentsInitialLoading &&
                !!featureIds.length,
              ...queryOptions,
            },
          ),
        )
      : [
          query(
            appId,
            envId,
            segments ?? [],
            { ...params, featureIds },
            {
              enabled:
                !!appId &&
                !!envId &&
                !isSegmentsInitialLoading &&
                !!featureIds.length,
              ...queryOptions,
            },
          ),
        ],
  });

  const isInitialLoading =
    isSegmentsInitialLoading || responses.some((r) => r.isInitialLoading);
  const isError = responses.some((r) => r.isError);
  const data = responses
    .map((response) => response.data)
    .filter((data): data is SegmentedList<FeaturesMetricsHistoricalData> => {
      return data !== undefined;
    });

  const results = { isInitialLoading, isError, data };

  // useQueries is not referentially stable so we need to run a deep equality check
  if (!equal(results, refResults.current)) {
    refResults.current = results;
  }

  return refResults.current!;
}
