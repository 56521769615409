import { useEffect, useMemo, useRef } from "react";
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  UseFormReturn,
} from "react-hook-form";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

// this performs deep comparison of the value and returns a new object only if
// the value has changed, thus making useEffect deep change aware
function useCustomCompareMemo<T>(value: T): T {
  const ref = useRef<T>(value);
  const signalRef = useRef<number>(0);

  if (!isEqual(value, ref.current)) {
    ref.current = cloneDeep(value);
    signalRef.current += 1;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ref.current, [signalRef.current]);
}

/**
 * Get a callback each time a form field array value changes
 *
 * The callback gets the field name and old and new values as the first argument.
 *
 * This implementation is for array fields and supports deletions too which you
 * don't get with the useFormFieldUpdate/Diff hook.
 *
 * @example
 * ```js
 * useFormFieldArrayUpdate(
 *  form,
 *  "my-field-name",
 *  (value) => {
 *    console.log('triggers only on value updates of "my-field-name"')
 *  }
 * )
 * ```
 */
export function useFormFieldArrayUpdate<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues>,
>(
  form: UseFormReturn<TFieldValues>,
  fieldName: TFieldName,
  fn: (value: FieldPathValue<TFieldValues, TFieldName>) => void,
) {
  const value = form.watch(fieldName);

  useEffect(
    () => fn(value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fn, useCustomCompareMemo(value)],
  );
}
