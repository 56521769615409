import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { FeatureDetail } from "@bucketco/shared/featureAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import featureQueryKeys from "@/feature-legacy/data/featureQueryKeys";

export default function useFeatureData(featureId?: string | null) {
  const { appId, envId } = useCurrentEnv();

  return useQuery<FeatureDetail, AxiosError<ErrorResponse>>({
    queryKey: featureQueryKeys.single(appId, featureId!),
    queryFn: () =>
      api
        .get<"/apps/:appId/features/:featureId">(
          `/apps/${appId}/features/${featureId}`,
          { params: { envId: envId! } },
        )
        .then((res) => res.data.feature),
    enabled: !!appId && !!featureId,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
