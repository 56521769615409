import { useQuery } from "@tanstack/react-query";

import { useAuthContext } from "@/auth/contexts/authContext";
import api from "@/common/utils/api";

export default function useSlackChannels() {
  const { currentOrg } = useAuthContext();

  return useQuery({
    queryKey: ["slackChannels", currentOrg?.id],

    queryFn: () => {
      return api
        .get<`/orgs/:orgId/slack/channels`>(
          `/orgs/${currentOrg?.id}/slack/channels`,
        )
        .then((res) => res.data);
    },

    retry: 1,

    select: (channels) =>
      (channels || []).map(({ id, name }) => ({
        id,
        name: `#${name}`,
      })),
  });
}
