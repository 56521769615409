import { useEffect } from "react";
import { RiCheckboxCircleFill } from "react-icons/ri";
import {
  Button,
  ButtonGroup,
  HStack,
  Text,
  useColorModeValue,
  useRadio,
  useRadioGroup,
  UseRadioGroupProps,
  UseRadioProps,
} from "@chakra-ui/react";

import MotionBox from "@/common/components/MotionBox";
import { focusStyle } from "@/theme/helpers";

type RadioGroupButtonsOption = {
  icon?: React.ReactElement;
  key: string;
  label: string;
};

function RadioButton({
  children,
  ...props
}: UseRadioProps & { children: React.ReactNode }) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const radio = getRadioProps();

  return (
    <Button
      {...radio}
      _checked={{
        color: "inherit",
      }}
      _focusWithin={{ ...focusStyle, zIndex: 1 }}
      as="label"
      color="gray.500"
      cursor="pointer"
      fontSize="sm"
      fontWeight="medium"
      variant="outline"
    >
      <input {...input} />
      {children}
      <MotionBox
        animate={{ scale: props.isChecked ? 1 : 0 }}
        color={useColorModeValue("brand.500", "brand.400")}
        initial={{ scale: 0 }}
        ml={2}
      >
        <RiCheckboxCircleFill size={20} />
      </MotionBox>
    </Button>
  );
}

export type RadioGroupButtonsProps = UseRadioGroupProps & {
  options: RadioGroupButtonsOption[];
  autoFocus?: boolean;
};

export function RadioGroupButtons({
  options,
  autoFocus = false,
  ...props
}: RadioGroupButtonsProps) {
  const { getRootProps, getRadioProps, focus } = useRadioGroup(props);
  const group = getRootProps();

  useEffect(() => {
    if (autoFocus) {
      focus();
    }
  }, [autoFocus, focus]);

  return (
    <ButtonGroup isAttached {...group}>
      {options.map(({ icon, key, label }) => {
        const radio = getRadioProps({ value: key });
        return (
          <RadioButton key={key} {...radio}>
            <HStack>
              {icon && icon}
              <Text textAlign="left">{label}</Text>
            </HStack>
          </RadioButton>
        );
      })}
    </ButtonGroup>
  );
}
