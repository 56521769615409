import { useCallback, useEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import omit from "lodash/omit";

import {
  CompanyFeature,
  CompanyFeaturesTableQueryType,
  CompanyUserDTO,
  CompanyUsersQueryType,
  FeatureCompanyUserDTO,
  FeatureUsersQueryType,
} from "@bucketco/shared/companyAPI";
import {
  FunnelStep,
  FunnelStepList,
  SortType,
} from "@bucketco/shared/featureAPI";
import { FeedbackDTO, FeedbackQueryType } from "@bucketco/shared/feedbackAPI";
import { SatisfactionSpan } from "@bucketco/shared/schemas/satisfactionScore";
import { NonOptional } from "@bucketco/shared/utils/types";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import useDataTable from "@/common/hooks/useDataTable";
import {
  useSearchArrayParam,
  useSearchParam,
} from "@/common/hooks/useSearchParam";
import { useTableDisplayMode } from "@/common/hooks/useTableDisplayMode";
import api from "@/common/utils/api";
import { getItemTree } from "@/common/utils/listItemTree";
import { spanToScore } from "@/feature-legacy/components/SatisfactionFilter";
import featureQueryKeys from "@/feature-legacy/data/featureQueryKeys";
import feedbackQueryKeys from "@/feedback/data/feedbackQueryKeys";

import companyQueryKeys from "./companyQueryKeys";
import { useCompany } from "./useCompany";

export default function useCompanyPageData() {
  const { companyId } = useParams();

  const [_, setParams] = useSearchParams();
  const [filterFeatureId] = useSearchParam("featureFilter");
  const [satisfactionSpan] = useSearchParam<SatisfactionSpan>("satisfaction");

  const clearFilters = useCallback(() => {
    setParams(new URLSearchParams());
  }, [setParams]);

  const { data: company, isLoading: companyIsLoading } = useCompany(companyId);

  const featuresDataTable = useCompanyFeatures({
    companyId,
  });

  const usersDataTables = useCompanyUsers({
    companyId,
    filterFeatureId,
  });

  const feedbackDataTable = useCompanyFeedbackDataTable(
    companyId,
    filterFeatureId,
    satisfactionSpan,
  );

  return {
    company,
    companyIsLoading,
    featuresDataTable,
    feedbackDataTable,
    usersDataTables,
    clearFilters,
  };
}

function useCompanyFeedbackDataTable(
  companyId?: string,
  filterFeatureId?: string,
  satisfactionSpan?: SatisfactionSpan,
) {
  const { appId, envId } = useCurrentEnv();
  const defaultSteps = useMemo(() => [...FunnelStepList], []);

  const [funnelSteps] = useSearchArrayParam<FunnelStep>("funnelStep", {
    fallback: defaultSteps,
  });

  const table = useDataTable<
    FeedbackDTO,
    Omit<FeedbackQueryType, "pageIndex" | "pageSize" | "sortOrder" | "envId">
  >({
    apiCacheKey: feedbackQueryKeys.list(appId, envId),
    apiHandler: (params) => async () => {
      const res = await api.get<"/apps/:appId/feedbacks">(
        `/apps/${appId}/feedbacks`,
        {
          params: { ...params, envId: envId! },
        },
      );

      return res.data;
    },
    apiOptions: {
      enabled: !!appId && !!envId && !!companyId,
    },
    defaultQueryParams: {
      sortBy: "timestamp",
      satisfaction: spanToScore(satisfactionSpan),
      featureId: filterFeatureId,
      funnelSteps,
      companyId,
    },
  });

  const fetchData = table.fetchData;
  useEffect(() => {
    fetchData({
      satisfaction: spanToScore(satisfactionSpan),
      featureId: filterFeatureId,
      funnelSteps,
      pageIndex: 0,
    });
  }, [fetchData, filterFeatureId, satisfactionSpan, funnelSteps]);

  return table;
}

function useCompanyFeatures({ companyId }: { companyId?: string }) {
  const { appId, envId } = useCurrentEnv();

  const [displayMode] = useTableDisplayMode();
  const table = useDataTable<
    CompanyFeature,
    Omit<CompanyFeaturesTableQueryType, "envId">,
    { sortType: SortType }
  >({
    apiCacheKey: companyQueryKeys.singleFeatures(appId, envId, companyId),
    apiHandler: (params) => () =>
      api
        .get<"/apps/:appId/companies/:companyId/features">(
          `/apps/${appId}/companies/${encodeURIComponent(companyId!)}/features`,
          {
            params: {
              ...omit(params, ["pageIndex", "pageSize"]),
              envId: envId!,
            },
          },
        )
        .then((res) => res.data),
    apiOptions: {
      enabled: !!appId && !!envId && !!companyId,
      select({ data, ...rest }) {
        return {
          data:
            rest.metadata.sortType === "hierarchical"
              ? getItemTree(data, "parentFeatureId")
              : data,
          ...rest,
        };
      },
    },
    defaultQueryParams: {
      sortBy: "name",
      sortOrder: "asc",
      sortType: displayMode === "tree" ? "hierarchical" : "flat",
    },
  });

  // De-structure to avoid an infinite loop on the following useEffect call
  const { fetchData } = table;
  useEffect(() => {
    fetchData({
      sortType: displayMode === "tree" ? "hierarchical" : "flat",
    });
  }, [fetchData, displayMode]);

  return table;
}

function useCompanyUsers({
  companyId,
  filterFeatureId,
}: {
  envId?: string;
  companyId?: string;
  filterFeatureId?: string;
}) {
  const { appId, envId } = useCurrentEnv();

  const companyUsers = useDataTable<
    CompanyUserDTO,
    NonOptional<Omit<CompanyUsersQueryType, "envId">, "sortBy">
  >({
    apiCacheKey: companyQueryKeys.listUsers(appId, envId, companyId),
    apiHandler: (params) => async () => {
      const response = await api.get<"/apps/:appId/companies/:companyId/users">(
        `/apps/${appId}/companies/${encodeURIComponent(companyId!)}/users`,
        {
          params: { ...params, envId: envId! },
        },
      );

      return response.data;
    },
    apiOptions: {
      enabled: !!appId && !!envId && !!companyId && !filterFeatureId,
    },
    defaultQueryParams: {
      sortBy: "lastSeen",
    },
  });

  const featureCompanyUsers = useDataTable<
    FeatureCompanyUserDTO,
    NonOptional<Omit<FeatureUsersQueryType, "envId">, "sortBy">
  >({
    apiCacheKey: featureQueryKeys.singleFeatureCompanyUsers(
      appId,
      envId,
      filterFeatureId,
      companyId,
    ),
    apiHandler: (params) => async () => {
      const response =
        await api.get<"/apps/:appId/companies/:companyId/users/by-feature/:featureId">(
          `/apps/${appId}/companies/${encodeURIComponent(
            companyId!,
          )}/users/by-feature/${filterFeatureId}`,
          {
            params: { ...params, envId: envId! },
          },
        );

      return response.data;
    },
    apiOptions: {
      enabled: !!appId && !!envId && !!companyId && !!filterFeatureId,
    },
    defaultQueryParams: {
      sortBy: "lastUsed",
    },
  });

  return {
    companyUsers,
    featureCompanyUsers,
  };
}
