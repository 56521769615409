import { Link as RouterLink } from "react-router-dom";
import { Button, ButtonProps, useColorModeValue } from "@chakra-ui/react";

type Props = ButtonProps & {
  shouldNavigate?: boolean;
};

export default function ModalCancelButton({
  shouldNavigate = true,
  ...rest
}: Props) {
  return (
    <Button
      _hover={{ color: useColorModeValue("gray.700", "gray.300") }}
      as={shouldNavigate ? RouterLink : undefined}
      color="gray.500"
      to={shouldNavigate ? ".." : undefined}
      type="button"
      variant="ghost"
      {...rest}
    >
      Cancel
    </Button>
  );
}
