import { AccessLevel } from "../organizationAPI";

const planOrder: Array<AccessLevel> = [
  "starter",
  "pro",
  "business",
  "enterprise",
];

/**
 * Check if an organizations current plan is the same or better
 * than the minimum required plan
 */
export function hasAccessLevel(
  requiredLevel: AccessLevel,
  currentLevel?: AccessLevel | null,
): boolean {
  if (!currentLevel) {
    return false;
  }

  return planOrder.indexOf(currentLevel) >= planOrder.indexOf(requiredLevel);
}
