import { useEffect } from "react";
import { RiFileCopyLine } from "react-icons/ri";
import { Link, useNavigate, useResolvedPath } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Spinner,
  useToast,
  VisuallyHidden,
} from "@chakra-ui/react";

import HeaderLayout from "@/common/components/HeaderLayout";
import RouterTabs, { Route } from "@/common/components/RouterTabs";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { FeatureTreeNavigation } from "@/feature-legacy/components/FeatureTreeNavigation";
import FeatureViewsPicker from "@/feature-legacy/components/FeatureViewsPicker";
import { SubsegmentPicker } from "@/feature-legacy/components/SubsegmentPicker";
import {
  FeatureProvider,
  useFeatureContext,
} from "@/feature-legacy/contexts/featureContext";
import { useFeatureFeatureViews } from "@/feature-legacy/data/useFeatureFeatureViews";
import useFeatureFeatureViewsUpdateMutation from "@/feature-legacy/data/useFeatureFeatureViewsUpdateMutation";

function Content() {
  const { feature, isLoading, isError } = useFeatureContext();
  const navigate = useNavigate();
  const toast = useToast();
  const [subsegment] = useSubsegmentParam();
  const params = new URLSearchParams();
  if (subsegment.length) {
    subsegment.forEach((subsegment) => params.append("subsegment", subsegment));
  }
  const search = params.toString();

  useEffect(() => {
    if (isError) {
      navigate("..");
      toast({
        title: "Could not retrieve feature",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [isError, navigate, toast]);

  const tabsRoutes: Route[] = [
    {
      route: { ...useResolvedPath("report"), search },
      name: "Report",
    },
    {
      route: { ...useResolvedPath("companies"), search },
      name: "Companies",
    },
    {
      route: { ...useResolvedPath("feedback"), search },
      name: "Feedback",
    },
    {
      route: { ...useResolvedPath("integrations"), search },
      name: "Integrations",
    },
    {
      route: { ...useResolvedPath("settings"), search },
      name: "Settings",
    },
  ];

  const { data: featureViews = [], isLoading: isFeatureViewsLoading } =
    useFeatureFeatureViews(feature?.id);

  const { mutate: updateFeatureViews } = useFeatureFeatureViewsUpdateMutation(
    feature?.id as string,
  );

  const handleFeatureViewChange = (featureViewIds: string[]) => {
    updateFeatureViews(featureViewIds, {
      onSuccess: () => {
        segmentAnalytics.track("Feature FeatureViews updated", {
          numViews: featureViewIds.length,
        });
      },
    });
  };

  return (
    <>
      <VisuallyHidden>
        <h1>{feature?.name}</h1>
      </VisuallyHidden>
      <HeaderLayout
        actions={
          <ButtonGroup spacing={4}>
            <SubsegmentPicker
              showPieChart={true}
              targetSegmentId={feature?.segment?.id}
              allCompaniesToggle
            />
            <FeatureViewsPicker
              isDisabled={isFeatureViewsLoading}
              isLoading={isFeatureViewsLoading}
              maxW={60}
              value={featureViews}
              onChange={handleFeatureViewChange}
            />
            <Button
              as={Link}
              leftIcon={<RiFileCopyLine />}
              size="sm"
              to="duplicate"
              variant="outline"
            >
              Duplicate
            </Button>
          </ButtonGroup>
        }
        title={
          isLoading ? (
            <Spinner size="sm" />
          ) : (
            <Box ml={-3}>
              <FeatureTreeNavigation rootFeatureId={feature!.id} />
            </Box>
          )
        }
      >
        <RouterTabs
          routes={tabsRoutes}
          tabListProps={{ px: 2 }}
          titleTemplate={`Feature › ${feature?.name} › %s`}
        />
      </HeaderLayout>
    </>
  );
}

export default function Feature() {
  return (
    <FeatureProvider>
      <Content />
    </FeatureProvider>
  );
}
