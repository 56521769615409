import { Link } from "react-router-dom";
import { Button, useColorModeValue } from "@chakra-ui/react";

import EmptyStateDark from "@/common/assets/empty-state-features-dark.svg?react";
import EmptyStateLight from "@/common/assets/empty-state-features-light.svg?react";
import EmptyState from "@/common/components/EmptyState";

export default function FeaturesEmptyState({
  viewId,
  isLoading,
}: {
  viewId: string | null;
  isLoading: boolean;
}) {
  const emptyStateIllustration = useColorModeValue(
    <EmptyStateLight />,
    <EmptyStateDark />,
  );
  if (viewId) {
    return (
      <EmptyState
        description="Feature views are great for grouping features into product teams or by product area. Features can be added to multiple views."
        flexGrow={1}
        isLoading={isLoading}
        title="No features in view"
      >
        {emptyStateIllustration}
      </EmptyState>
    );
  }

  return (
    <EmptyState
      action={<NewFeatureButton />}
      description="A feature can be anything in your product, big and small. It can be user facing, like a key interaction in the UI, a minor preference-toggle or even a background job."
      flexGrow={1}
      isLoading={isLoading}
      title="Track your first feature"
    >
      {}
    </EmptyState>
  );
}

function NewFeatureButton() {
  return (
    <Button as={Link} size="sm" to="new" variant="outline">
      Track new feature
    </Button>
  );
}
