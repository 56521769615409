import { useFormContext } from "react-hook-form";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Switch,
  VStack,
} from "@chakra-ui/react";

import {
  CreateFlagArgsType,
  PatchFlagArgsType,
} from "@bucketco/shared/flagAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import useDefaultSlackChannel from "@/common/hooks/useDefaultSlackChannel";
import useSlackChannels from "@/common/hooks/useSlackChannels";
import { SlackChannelAutocompleteSelect } from "@/global-settings/components/SlackChannelAutocompleteSelect";

export default function FlagSlackSettings() {
  const { currentApp } = useAuthContext();

  const { data: slackChannels = [], isLoading } = useSlackChannels();

  const form = useFormContext<CreateFlagArgsType | PatchFlagArgsType>();
  const currentSlackChannelId = form.watch("slackChannelId");
  const slackNotificationsEnabled = form.watch("slackNotificationsEnabled");

  useDefaultSlackChannel({
    slackChannels,
    currentChannel: currentSlackChannelId,
    preferredChannelId: currentApp?.environments.find((e) => e.isProduction)
      ?.slackChannelId,
    cb: (channel) => {
      form.setValue("slackChannelId", channel.id);
    },
  });

  return (
    <VStack alignItems="flex-start" maxW="compactForm" spacing={6} w="100%">
      <FormControl
        as={Flex}
        flexDirection="column"
        gap={2}
        isDisabled={form.formState.isSubmitting || isLoading}
      >
        <Flex align="center" gap={4}>
          <Box>
            <FormLabel flexGrow={1} margin={0} padding={0}>
              Notify on Slack when flag version changes
            </FormLabel>
          </Box>
          <Box flexGrow={1}></Box>
          <Box>
            <Switch
              {...form.register("slackNotificationsEnabled")}
              colorScheme="brand"
            />
          </Box>
        </Flex>
        <ManagedFormControl
          isDisabled={!slackNotificationsEnabled}
          name="slackChannelId"
          render={({ field }) => (
            <SlackChannelAutocompleteSelect
              {...field}
              onChange={(channel) => {
                field.onChange(channel?.id ?? null);
                form.setValue("slackChannelName", channel?.name);
              }}
            />
          )}
        />
      </FormControl>
    </VStack>
  );
}
