import React, { ReactElement, useCallback, useMemo } from "react";
import { RiAddLine, RiLineChartLine } from "react-icons/ri";
import {
  Button,
  chakra,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";

import {
  WidgetConfiguration,
  WidgetConfigurationType,
  WidgetConfigurationTypes,
} from "@bucketco/shared/widgetAPI";

import SatisfactionSvg from "@/common/assets/satisfaction.svg?react";
import StarsSvg from "@/common/assets/stars-icon.svg?react";
import { useWidgetCreateMutation } from "@/widget/data/useWidgetMutations";

const StarsFunnelIcon = chakra(StarsSvg);
const FeatureMetricIcon = chakra(RiLineChartLine);
const FeedbackIcon = chakra(SatisfactionSvg);

type WidgetOption = {
  title: string;
  icon: ReactElement;
  configuration: WidgetConfiguration;
};

type Props = {
  featureId: string;
  allowedTypes?: WidgetConfigurationType[] | readonly WidgetConfigurationType[];
};

export const FeatureWidgetCreator = ({
  featureId,
  allowedTypes = WidgetConfigurationTypes,
}: Props) => {
  const createWidget = useWidgetCreateMutation();

  const widgetOptions = useMemo<WidgetOption[]>(
    () =>
      [
        allowedTypes.includes("starsFunnel") && {
          title: "STARS funnel",
          icon: <StarsFunnelIcon boxSize={4} color="dimmed" />,
          configuration: {
            version: "1",
            type: "starsFunnel",
            featureId,
          },
        },
        allowedTypes.includes("featureMetric") && {
          title: "Feature metric",
          icon: <FeatureMetricIcon boxSize={4} color="dimmed" />,
          configuration: {
            version: "1",
            type: "featureMetric",
            featureId,
            metric: "tried",
          },
        },
        allowedTypes.includes("feedback") && {
          title: "Feedback",
          icon: <FeedbackIcon boxSize={4} color="dimmed" />,
          configuration: {
            version: "1",
            type: "feedback",
            featureId,
          },
        },
      ].filter(Boolean) as WidgetOption[],
    [featureId, allowedTypes],
  );

  const onSelect = useCallback(
    (configuration: WidgetConfiguration) => {
      createWidget.mutate({ configuration });
    },
    [createWidget],
  );

  return (
    <Menu>
      <MenuButton as={Button} leftIcon={<RiAddLine />} variant="outline">
        Add widget
      </MenuButton>
      <MenuList>
        {widgetOptions.map(({ title, icon, configuration }) => (
          <MenuItem
            key={configuration.type}
            icon={icon}
            iconSpacing={2}
            onClick={() => {
              onSelect(configuration);
            }}
          >
            {title}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
