import { useColorModeValue, useToken } from "@chakra-ui/react";
import { transparentize } from "color2k";

import { GoalStatus } from "@bucketco/shared/goalAPI";

type ColorSet = {
  fg: string;
  bg: string;
};

export function useGoalStatusColors(): Record<GoalStatus, ColorSet>;
export function useGoalStatusColors(status?: GoalStatus): ColorSet;
export function useGoalStatusColors(status?: GoalStatus) {
  const colors = {
    planned: useColorModeValue("gray.500", "gray.500"),
    evaluating: useColorModeValue("yellow.600", "orange.300"),
    missed: useColorModeValue("red.600", "red.400"),
    achieved: useColorModeValue("green.600", "green.400"),
  };

  const colorSets = {
    planned: {
      fg: colors.planned,
      bg: transparentize(useToken("colors", colors.planned), 0.9),
    },
    evaluating: {
      fg: colors.evaluating,
      bg: transparentize(useToken("colors", colors.evaluating), 0.9),
    },
    missed: {
      fg: colors.missed,
      bg: transparentize(useToken("colors", colors.missed), 0.9),
    },
    achieved: {
      fg: colors.achieved,
      bg: transparentize(useToken("colors", colors.achieved), 0.9),
    },
  };

  if (status !== undefined) return colorSets[status];
  return colorSets;
}
