import { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthContext } from "@/auth/contexts/authContext";

export default function RequireUnsuspendedOrg({ children }: PropsWithChildren) {
  const { currentOrg, user } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.isAdmin && currentOrg?.suspendedAt) {
      navigate("/org-suspended");
    }
  }, [currentOrg?.suspendedAt, user?.isAdmin, navigate]);

  return <>{children}</>;
}
