import { useCallback, useEffect, useState } from "react";
import { Text, Tooltip } from "@chakra-ui/react";
import { ConfigType } from "dayjs";
import doCapitalize from "lodash/capitalize";

import { fullFormattedDateTime } from "@/common/utils/datetime";
import dayjs from "@/common/utils/dayjs";

const HIGHLIGHT_PERIOD_MS = 6 * 60 * 1000; // Six minutes

type NearestUnit = "second" | "hour" | "day";

type Props = {
  value?: ConfigType;
  autoUpdate?: boolean;
  leftAlign?: boolean;
  fontSize?: string;
  capitalize?: boolean;
  highlight?: boolean;
  color?: string;
  nearestUnit?: NearestUnit;
};

export function formatRelativeTime(
  value: dayjs.Dayjs,
  now: dayjs.Dayjs,
  nearestUnit: NearestUnit,
) {
  let relative = value.from(now);

  if (nearestUnit === "hour") {
    const hours = now.diff(value, "hours");
    if (hours < 1) {
      relative = "less than an hour ago";
    }
  } else if (nearestUnit === "day") {
    if (value.isSame(now, "day")) {
      relative = "today";
    }
  }

  return relative;
}

export default function TimestampCell({
  value,
  autoUpdate = false,
  leftAlign = false,
  fontSize = "sm",
  capitalize = true,
  highlight = true,
  color,
  nearestUnit = "second",
}: Props) {
  const [time, setTime] = useState(
    formatRelativeTime(dayjs(value), dayjs(), nearestUnit),
  );
  const [isHighlighted, setIsHighlighted] = useState(
    highlight && HIGHLIGHT_PERIOD_MS > Math.abs(dayjs(value).diff()),
  );

  const update = useCallback(
    (newValue: ConfigType) => {
      const newDayjs = dayjs(newValue);
      setTime(formatRelativeTime(newDayjs, dayjs(), nearestUnit));
      setIsHighlighted(
        highlight && HIGHLIGHT_PERIOD_MS > Math.abs(newDayjs.diff()),
      );
    },
    [highlight, nearestUnit],
  );

  // make sure relative timestamps are updated every minute, if enabled
  useEffect(() => {
    if (!autoUpdate) return;
    const timer = setInterval(() => {
      update(value);
    }, 60000);
    return () => clearInterval(timer);
  }, [autoUpdate, update, value]);

  // update the relative timestamp when the value changes
  useEffect(() => {
    update(value);
  }, [update, value]);

  if (!value) return null;

  return (
    <Tooltip
      label={fullFormattedDateTime(value)}
      openDelay={300}
      placement={leftAlign ? "bottom-start" : undefined}
      hasArrow
    >
      <Text
        as="time"
        color={isHighlighted ? "green.500" : color}
        dateTime={dayjs(value).toISOString()}
        fontSize={fontSize}
        whiteSpace="nowrap"
      >
        {capitalize ? doCapitalize(time) : time}
      </Text>
    </Tooltip>
  );
}
