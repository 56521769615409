import { useQuery } from "@tanstack/react-query";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import feedbackQueryKeys from "@/feedback/data/feedbackQueryKeys";

export function useFeatureFeedbackData(featureId?: string) {
  const { appId, envId } = useCurrentEnv();

  return useQuery({
    queryKey: feedbackQueryKeys.single(appId, envId, featureId),
    queryFn: async () => {
      return api
        .get<"/apps/:appId/feedbacks">(`/apps/${appId}/feedbacks`, {
          params: {
            featureId,
            sortBy: "timestamp",
            sortOrder: "desc",
            envId: envId!,
            funnelSteps: [],
            satisfaction: [],
            pageIndex: 0,
            pageSize: 5,
          },
        })
        .then((res) => res.data);
    },
    enabled: !!appId && !!envId && !!featureId,
  });
}
