import { Box, Grid, GridItem, Heading, useToken } from "@chakra-ui/react";

export default function SettingSection({
  title,
  description,
  children,
}: {
  title: string;
  description?: string | React.ReactNode;
  children: React.ReactNode;
}) {
  const sidebarMaxSize = useToken("sizes", "2xs");

  return (
    <Grid templateColumns={`${sidebarMaxSize} auto`}>
      <GridItem pr={12}>
        <Heading fontSize="xl" fontWeight="medium" mb={2}>
          {title}
        </Heading>
        <Box color="gray.500" fontSize="sm">
          {description}
        </Box>
      </GridItem>
      <GridItem maxW="wideForm" pl={4}>
        {children}
      </GridItem>
    </Grid>
  );
}
