import { useColorModeValue } from "@chakra-ui/react";

import { FunnelState } from "@bucketco/shared/featureAPI";

type FunnelStateWithNone = FunnelState | "none";

export function useStarsStateColors(): Record<FunnelStateWithNone, string>;
export function useStarsStateColors(status?: FunnelStateWithNone): string;
export function useStarsStateColors(status?: FunnelStateWithNone) {
  const colors = {
    none: useColorModeValue("gray.300", "gray.600"),
    never: useColorModeValue("gray.300", "gray.600"),
    tried: useColorModeValue("orange.300", "orange.300"),
    retained: useColorModeValue("brand.300", "brand.300"),
    churned: useColorModeValue("red.300", "red.300"),
  };

  if (status !== undefined) return colors[status];
  return colors;
}
