import { useMemo } from "react";
import { Link, To } from "react-router-dom";
import { Box, BoxProps, HStack, Text } from "@chakra-ui/react";

import { FEEDBACK_SOURCE_LABELS } from "@bucketco/shared/constants";
import {
  FeedbackDTO,
  FeedbackQuerySortByType,
  FeedbackSource,
} from "@bucketco/shared/feedbackAPI";
import { FeatureUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import {
  DeleteIconButton,
  EditIconButton,
} from "@/common/components/CommonIconButtons";
import CompanyDisplay from "@/common/components/CompanyDisplay";
import DataTable, { DataTableProps } from "@/common/components/DataTable";
import ExpandableTextCell from "@/common/components/ExpandableTextCell";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import TimestampCell from "@/common/components/TimestampCell";
import { useLocalStorageTableConfiguration } from "@/common/hooks/useLocalStorageTableConfiguration";
import { createSortableColumnHelper } from "@/common/types/reactTableHelpers";
import FeatureDisplay from "@/feature-legacy/components/FeatureDisplay";
import { starsFunnelStateDescriptions } from "@/feature-legacy/data/starsFunnelDescriptions";
import DeleteFeedbackDialog from "@/feedback/components/DeleteFeedbackDialog";
import SatisfactionCell from "@/feedback/components/SatisfactionCell";

const cellVerticalAdjust: BoxProps = {
  display: "inline-flex",
  alignItems: "center",
  minH: 7,
};

type ColumnKey =
  | "question"
  | "score"
  | "comment"
  | "userName"
  | "companyName"
  | "featureName"
  | "timestamp"
  | "companyFunnelStep"
  | "source"
  | "actions";

type Props = Omit<DataTableProps<FeedbackDTO>, "trackingId" | "columns"> & {
  columnOrder: ColumnKey[];
  createEditURL: (feedback: FeedbackDTO) => To;
  viewName: string;
};

export default function FeedbackTable({
  createEditURL,
  columnOrder,
  viewName,
  defaultColumns,
  ...tableProps
}: Props) {
  const { currentEnv } = useAuthContext();

  const columnHelper = createSortableColumnHelper<
    FeedbackDTO,
    FeedbackQuerySortByType
  >();

  const columns = useMemo(() => {
    const definitions = {
      score: columnHelper.accessor("score", {
        header: "Score",
        cell: (cell) => {
          const value = cell.getValue();
          return (
            <Box {...cellVerticalAdjust}>
              {value !== null ? (
                <SatisfactionCell value={value} />
              ) : (
                <NotAvailableCell />
              )}
            </Box>
          );
        },
      }),
      comment: columnHelper.accessor("comment", {
        header: "Comment",
        cell: (cell) => {
          const value = cell.getValue();
          return (
            <Box {...cellVerticalAdjust}>
              {value ? (
                <ExpandableTextCell value={value} />
              ) : (
                <Text color="dimmed">No comment</Text>
              )}
            </Box>
          );
        },
        minSize: 280,
      }),
      question: columnHelper.accessor("question", {
        header: "Question",
        enableSorting: false,
        cell: (cell) => {
          const value = cell.getValue();
          return (
            <Box {...cellVerticalAdjust}>
              {value ? (
                <ExpandableTextCell value={value} />
              ) : (
                <Text color="dimmed">No question</Text>
              )}
            </Box>
          );
        },
      }),
      userName: columnHelper.accessor("userName", {
        header: "User",
        cell: ({ row }) => (
          <Box {...cellVerticalAdjust}>
            <Text fontSize="sm" noOfLines={1}>
              {row.original.userName || row.original.userId}
            </Text>
          </Box>
        ),
      }),
      companyFunnelStep: columnHelper.accessor("companyFunnelStep", {
        header: "STARS state",
        sortDescFirst: true,
        cell: (cell) => {
          const value = cell.getValue();
          const description = starsFunnelStateDescriptions.find(
            ({ id }) => id === value,
          );
          return description ? (
            <HStack spacing={2} {...cellVerticalAdjust}>
              {description.visualization}
              <Text>{description.label}</Text>
            </HStack>
          ) : (
            <NotAvailableCell />
          );
        },
      }),
      companyName: columnHelper.accessor("companyName", {
        header: "Company",
        cell: ({ row }) => (
          <Box {...cellVerticalAdjust}>
            {row.original.companyId ? (
              <CompanyDisplay
                id={row.original.companyId}
                name={row.original.companyName}
                link
              />
            ) : (
              <NotAvailableCell />
            )}
          </Box>
        ),
      }),
      featureName: columnHelper.accessor("featureName", {
        header: "Feature",
        id: "feature",
        enableSorting: false,
        cell: ({ row }) => (
          <Box {...cellVerticalAdjust}>
            {row.original.featureId ? (
              <Text
                as={Link}
                to={FeatureUrl(currentEnv!, {
                  id: row.original.featureId,
                  name: row.original.featureName || "",
                })}
              >
                <FeatureDisplay
                  feature={{
                    id: row.original.featureId,
                    name: row.original.featureName || "",
                  }}
                />
              </Text>
            ) : (
              <NotAvailableCell />
            )}
          </Box>
        ),
      }),
      timestamp: columnHelper.accessor("timestamp", {
        header: "Received",
        sortDescFirst: true,
        cell: (cell) => {
          const value = cell.getValue();
          return (
            <Box {...cellVerticalAdjust}>
              {value !== undefined ? (
                <TimestampCell value={value} leftAlign />
              ) : (
                <NotAvailableCell />
              )}
            </Box>
          );
        },
      }),
      source: columnHelper.accessor("source", {
        header: "Source",
        enableSorting: false,
        cell: (cell) => {
          const value = cell.getValue();
          const name = FEEDBACK_SOURCE_LABELS[value as FeedbackSource];
          if (!name) return <NotAvailableCell />;

          return (
            <Box {...cellVerticalAdjust}>
              <Text>{name}</Text>
            </Box>
          );
        },
      }),
      actions: columnHelper.display({
        header: "Actions",
        id: "actions",
        enableSorting: false,
        cell: ({ row }) => (
          <Box {...cellVerticalAdjust}>
            <HStack spacing={0}>
              <EditIconButton
                label="Edit feedback entry"
                to={createEditURL(row.original)}
              />
              <DeleteFeedbackDialog feedbackId={row.original.id}>
                {({ openDialog }) => (
                  <DeleteIconButton
                    label="Delete feedback entry"
                    onClick={openDialog}
                  />
                )}
              </DeleteFeedbackDialog>
            </HStack>
          </Box>
        ),
      }),
    };

    return columnOrder.map((key) => definitions[key]);
  }, [currentEnv, columnHelper, columnOrder, createEditURL]);

  const tableConfiguration = useLocalStorageTableConfiguration(viewName, {
    defaultColumns: [],
    defaultSort: tableProps.sortBy,
  });

  return (
    <DataTable
      {...tableProps}
      columns={columns}
      columnStates={tableConfiguration.columns}
      defaultColumns={defaultColumns}
      freezeFirstColumn={false}
      setColumnStates={tableConfiguration.setColumns}
      setSortBy={tableConfiguration.setSort}
      sortBy={tableConfiguration.sort}
      trackingId="feedback"
    >
      <colgroup>
        {columnOrder.map((col) => {
          if (col === "score") return <col key={col} width={1} />;
          if (col === "question") return <col key={col} width="30%" />;
          if (col === "comment") return <col key={col} width="50%" />;
          if (col === "userName") return <col key={col} width="15%" />;
          if (col === "companyFunnelStep") return <col key={col} width="15%" />;
          if (col === "companyName") return <col key={col} width="15%" />;
          if (col === "featureName") return <col key={col} width="15%" />;
          if (col === "timestamp") return <col key={col} width={1} />;
          if (col === "actions") return <col key={col} width={1} />;
        })}
      </colgroup>
    </DataTable>
  );
}
