import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  HStack,
  Link,
  Popover,
  PopoverAnchor,
  PopoverContent,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

import { FeatureListItem } from "@bucketco/shared/featureAPI";
import { FeatureReportUrl } from "@bucketco/shared/urls";
import { getFraction } from "@bucketco/shared/utils/getFraction";

import { useAuthContext } from "@/auth/contexts/authContext";
import { useAuditChartMetricText } from "@/common/charts/components/AuditChart/hooks/useAuditChartMetricText";
import { useAuditChartPopover } from "@/common/charts/components/AuditChart/hooks/useAuditChartPopover";
import {
  AuditChartDataItem,
  AuditChartOptions,
} from "@/common/charts/components/AuditChart/types";
import { SimplePieChart } from "@/common/charts/components/SimplePieChart";
import useChartTokens from "@/common/charts/hooks/useChartTokens";

export function FeaturePopover({
  parentRef,
  segmentId,
  features,
  activeFeature,
  setActiveFeature,
  options,
}: {
  parentRef: React.RefObject<HTMLElement>;
  segmentId: string;
  features: AuditChartDataItem[];
  activeFeature: FeatureListItem | null;
  setActiveFeature: Dispatch<SetStateAction<FeatureListItem | null>>;
  options: AuditChartOptions;
}) {
  const { currentEnv } = useAuthContext();
  const { colors, radii, fontSizes } = useChartTokens();
  const subsegmentColor = useColorModeValue("teal.500", "teal.300");

  const {
    feature,
    show,
    remove,
    anchorPosition,
    isActive,
    isHovered,
    setIsHovered,
  } = useAuditChartPopover({
    setActiveFeature,
  });

  const xText = useAuditChartMetricText(feature, options.xAxisMetric);
  const yText = useAuditChartMetricText(feature, options.yAxisMetric);

  // Display highlight popovers when the `highlightedFeature`
  // option has been set.
  useEffect(() => {
    if (parentRef.current === null) return;

    // If already hovering over a popover, don't
    // try to change or remove it.
    if (isHovered) return;

    if (activeFeature === null) {
      remove();
      return;
    }

    const isVisible =
      options.featureVisibilities[activeFeature.id.split("-")[0]];
    if (!isVisible) {
      remove();
      return;
    }

    const item = features.find(
      (item) =>
        activeFeature.id == item.id ||
        `${activeFeature.id}-${segmentId}` == item.id,
    );

    if (!item) return;

    if (parentRef.current === null) return;

    const dotElement = parentRef.current.querySelector(
      `[data-scatter-dot-item-id=${item.id}]`,
    );
    if (dotElement === null) return;

    show(item, {
      anchorElement: dotElement,
    });
  }, [
    features,
    options.featureVisibilities,
    activeFeature,
    parentRef,
    segmentId,
    show,
    remove,
    isHovered,
    setIsHovered,
  ]);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, [setIsHovered]);

  const handleMouseLeave = useCallback(() => {
    remove();
  }, [remove]);

  if (feature === null) return null;

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Popover
        arrowShadowColor={colors.tooltipBorder}
        autoFocus={false}
        gutter={0}
        isOpen={isActive}
        variant="tooltip"
      >
        <PopoverAnchor>
          <Link
            as={RouterLink}
            style={
              anchorPosition === null
                ? {}
                : {
                    position: "fixed",
                    zIndex: 100,
                    top: anchorPosition.y,

                    // Make it less finicky to hover over the Popover
                    left: anchorPosition.x - 12,
                    minWidth: anchorPosition.width + 24,

                    // Don't clear when moving cursor to the Popover
                    height: anchorPosition.height + 5,
                  }
            }
            to={FeatureReportUrl(
              currentEnv!,
              {
                id: feature.id.split("-")[0],
                name: feature.name,
              },
              { subsegment: feature.subsegment?.id },
            )}
          />
        </PopoverAnchor>
        <PopoverContent
          backgroundColor={colors.tooltipBg}
          borderColor={colors.tooltipBorder}
          borderRadius={radii.tooltip}
          fontSize={fontSizes.tooltip}
          maxW={240}
          px={2.5}
          py={2}
          w="auto"
        >
          <VStack align="flex-start" spacing={0}>
            <Link
              _hover={{ textDecoration: "underline" }}
              as={RouterLink}
              fontSize="md"
              fontWeight="medium"
              mb={1}
              textDecoration="none"
              to={FeatureReportUrl(
                currentEnv!,
                {
                  id: feature.id.split("-")[0],
                  name: feature.name,
                },
                { subsegment: feature.subsegment?.id },
              )}
              w="full"
              isTruncated
            >
              {feature.name}
            </Link>

            {feature.subsegment && (
              <HStack>
                <SimplePieChart
                  color={subsegmentColor}
                  value={
                    getFraction(
                      feature.subsegment.segmentCount,
                      feature.subsegment.allCount,
                    ) * 100
                  }
                />
                <Text
                  color={subsegmentColor}
                  fontWeight="medium"
                  textDecoration="none"
                  w="100%"
                  isTruncated
                >
                  {feature.subsegment.name}
                </Text>
              </HStack>
            )}

            {xText !== null && (
              <Text color="dimmed">
                {xText.label}: {xText.formattedValue}
              </Text>
            )}

            {yText !== null && (
              <Text color="dimmed">
                {yText.label}: {yText.formattedValue}
              </Text>
            )}
          </VStack>
        </PopoverContent>
      </Popover>
    </div>
  );
}
