import { forwardRef } from "react";
import {
  HStack,
  InputGroup,
  InputRightElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
} from "@chakra-ui/react";

import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import {
  FormElementBaseProps,
  useManagedFormControlProps,
} from "@/common/hooks/useManagedFormControlProps";
import { mergeFunctions } from "@/common/utils/mergeFunctions";
import { mergeRefs } from "@/common/utils/mergeRefs";

const roundToTwoDecimals = (value: number) => Math.round(value * 100) / 100;

export type FormNumberInputProps = FormElementBaseProps &
  Omit<NumberInputProps, "value"> & {
    unit?: string | ((value: number) => string);
    scaleFactor?: number;
  };

const FormNumberInput = forwardRef<HTMLInputElement, FormNumberInputProps>(
  (
    { unit, scaleFactor = 1, onChange, onBlur, _control, children, ...rest },
    ref,
  ) => {
    const [managedProps, inputProps] = useManagedFormControlProps({
      ...rest,
      children: undefined,
    });
    return (
      <ManagedFormControl
        {..._control}
        {...managedProps}
        render={({ field }) => (
          <InputGroup>
            <HStack>
              <NumberInput
                bg="appBackground"
                role="input"
                w="full"
                {...field}
                {...inputProps}
                value={
                  typeof field.value === "number" && !isNaN(field.value)
                    ? roundToTwoDecimals(field.value * scaleFactor)
                    : ""
                }
                onBlur={mergeFunctions(field.onBlur, onBlur)}
                onChange={(s, n) => {
                  if (isNaN(n)) return field.onChange(null);
                  field.onChange(roundToTwoDecimals(+n / scaleFactor));
                  onChange?.(s, n);
                }}
              >
                <NumberInputField ref={mergeRefs(field.ref, ref)} />
                {unit && (
                  <InputRightElement
                    height="100%"
                    opacity={managedProps.isDisabled ? 0.4 : 1}
                    right={9}
                    transitionDuration="normal"
                    userSelect="none"
                    width="auto"
                  >
                    {typeof unit === "string" ? unit : unit(field.value)}
                  </InputRightElement>
                )}

                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              {children}
            </HStack>
          </InputGroup>
        )}
      />
    );
  },
);
FormNumberInput.displayName = "FormNumberInput";

export default FormNumberInput;
