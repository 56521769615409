import { z } from "zod";

import { paginationQueryBaseSchema } from "./schemas/dataTableSchema";
import {
  SatisfactionScore,
  SatisfactionScoreFilterSchema,
} from "./schemas/satisfactionScore";
import { Paginated } from "./types/Paginated";
import { APIEmptyResponse, APIResponse } from "./api";
import {
  EnvironmentSelectionQuerySchema,
  EnvironmentSelectionQueryType,
} from "./environmentAPI";
import { FunnelStep, FunnelStepList } from "./featureAPI";

export type FeedbackSource = "api" | "manual" | "prompt" | "sdk" | "widget";

export type FeedbackDTO = {
  id: string;
  companyId: string | null;
  companyName: string | null;
  companyFunnelStep: FunnelStep | null;
  featureId: string;
  featureName: string | null;
  userId: string | null;
  userName: string | null;
  question: string | null;
  score: SatisfactionScore;
  comment: string | null;
  source: FeedbackSource | null;
  timestamp: string;
  updatedAt: string;
};

export type FeedbackModel = Omit<FeedbackDTO, "featureName" | "featureStatus">;

const scoreType = z.coerce.number().min(1).max(5);

export const CreateFeedbackArgsSchema = z
  .object({
    userId: z.string().min(1).nullable(),
    featureId: z.string().min(1).optional(),
    companyId: z.string().min(1, "A company must be set"),
    question: z.string().max(256).nullish(),
    comment: z.string().max(4000).nullish(),
    sendToSlack: z.boolean().optional(),
    score: scoreType.nullish(),
  })
  .strict();

export const PatchFeedbackArgsSchema = CreateFeedbackArgsSchema.partial();

export type PatchFeedbackArgsType = z.infer<typeof PatchFeedbackArgsSchema>;

export type CreateFeedbackArgsType = z.infer<typeof CreateFeedbackArgsSchema>;

const FeedbackQuerySortBySchema = z.enum([
  "timestamp",
  "userName",
  "companyName",
  "comment",
  "score",
  "companyFunnelStep",
  "source",
]);

export type FeedbackQuerySortByType = z.infer<typeof FeedbackQuerySortBySchema>;

export const FeedbackQuerySchema = EnvironmentSelectionQuerySchema.merge(
  paginationQueryBaseSchema,
)
  .merge(
    z.object({
      sortBy: FeedbackQuerySortBySchema.default("timestamp"),
      funnelSteps: z
        .enum(FunnelStepList)
        .array()
        .optional()
        .default(["company", "segment", "tried", "adopted", "retained"]),
      satisfaction: SatisfactionScoreFilterSchema.optional().default([
        0, 1, 2, 3, 4, 5,
      ]),
      featureId: z.string().length(14).optional(),
      companyId: z.string().min(1).optional(),
      subsegment: z.string().length(14).optional(),
    }),
  )
  .strict();

export type FeedbackQueryType = z.infer<typeof FeedbackQuerySchema>;

export const FeedbackTimelineQuerySchema =
  EnvironmentSelectionQuerySchema.extend({
    subsegment: z.string().optional(),
    featureIds: z.string().array().optional(),
    startDate: z.coerce.date(),
    endDate: z.coerce.date(),
    rollingWindowSize: z.coerce.number().int().min(1).max(90).default(1),
  });

export type FeedbackTimelineQueryType = z.output<
  typeof FeedbackTimelineQuerySchema
>;

export type FeedbackTimelineItem = {
  epoch: number;
  averageScore: number | null;
};

export interface FeedbackApi {
  "/apps/:appId/feedbacks": {
    GET: {
      response: APIResponse<Paginated<FeedbackDTO, FeedbackQuerySortByType>>;
      params: { appId: string };
      query: FeedbackQueryType;
    };
    POST: {
      body: CreateFeedbackArgsType;
      response: APIResponse<FeedbackDTO>;
      params: { appId: string };
      query: EnvironmentSelectionQueryType;
    };
  };
  "/apps/:appId/feedbacks/:feedbackId": {
    GET: {
      response: APIResponse<FeedbackDTO>;
      params: { appId: string; feedbackId: string };
      query: EnvironmentSelectionQueryType;
    };
    PATCH: {
      body: PatchFeedbackArgsType;
      response: APIResponse<FeedbackDTO>;
      params: { appId: string; feedbackId: string };
      query: EnvironmentSelectionQueryType;
    };
    DELETE: {
      response: APIEmptyResponse;
      params: { appId: string; feedbackId: string };
      query: EnvironmentSelectionQueryType;
    };
  };
  "/apps/:appId/feedback/timeline": {
    GET: {
      response: APIResponse<FeedbackTimelineItem[]>;
      params: { appId: string };
      query: FeedbackTimelineQueryType;
    };
  };
}
