import capitalize from "lodash/capitalize";
import { XAxisProps, YAxisProps } from "recharts";

import {
  FeatureListItem,
  frequencyNumberToText,
} from "@bucketco/shared/featureAPI";
import { SegmentDTOWithTotalCompanies } from "@bucketco/shared/segmentAPI";
import { NullablePartial } from "@bucketco/shared/utils/types";

import { formatPercentage } from "@/common/utils/numberFormatting";

export type AuditChartAxisMetricKey =
  | "tried"
  | "adopted"
  | "retained"
  | "satisfied"
  | "adoptionRate"
  | "retentionRate"
  | "satisfactionRate"
  | "averageFrequency";

export const AXIS_METRICS: Record<
  AuditChartAxisMetricKey,
  {
    label: string;
    getFormattedValue: (item: AuditChartDataItem) => string | undefined;
  }
> = {
  tried: {
    label: "Tried",
    getFormattedValue: ({ current }) => {
      if (current.tried === null) return;
      return formatPercentage(current.tried);
    },
  },
  adopted: {
    label: "Adopted",
    getFormattedValue: ({ current }) => {
      if (current.adopted === null) return;
      return formatPercentage(current.adopted);
    },
  },
  retained: {
    label: "Retained",
    getFormattedValue: ({ current }) => {
      if (current.retained === null) return;
      return formatPercentage(current.retained);
    },
  },
  satisfied: {
    label: "Satisfied",
    getFormattedValue: ({ current }) => {
      if (current.satisfied === null) return;
      return formatPercentage(current.satisfied);
    },
  },
  adoptionRate: {
    label: "Adoption rate",
    getFormattedValue: ({ current }) => {
      if (current.adoptionRate === null) return;
      return formatPercentage(current.adoptionRate);
    },
  },
  retentionRate: {
    label: "Retention rate",
    getFormattedValue: ({ current }) => {
      if (current.retentionRate === null) return;
      return formatPercentage(current.retentionRate);
    },
  },
  satisfactionRate: {
    label: "Satisfaction rate",
    getFormattedValue: ({ current }) => {
      if (current.satisfactionRate === null) return;
      return formatPercentage(current.satisfactionRate);
    },
  },
  averageFrequency: {
    label: "Frequency",
    getFormattedValue: ({ current }) => {
      if (current.averageFrequency === null) return;
      return `${capitalize(
        frequencyNumberToText(current.averageFrequency - 0.5),
      )}`;
    },
  },
} as const;

export type AxisMetricValues = {
  [K in AuditChartAxisMetricKey]: number;
};

export type AuditChartDataItem = FeatureListItem & {
  current: NullablePartial<AxisMetricValues>;
  historical: NullablePartial<AxisMetricValues>;
  dotLabel?: string;
  subsegment?: SegmentDTOWithTotalCompanies;
  hasData: boolean;
  isVisible: boolean;
};

export type AuditChartOptions = {
  showLabels: boolean;
  showTrendlines: boolean;
  featureVisibilities: Record<string, boolean>;
  xAxisMetric: AuditChartAxisMetricKey;
  yAxisMetric: AuditChartAxisMetricKey;
};

export type AxisProps = XAxisProps &
  YAxisProps & {
    referenceLines: number[];
  };
