import { FormProvider } from "react-hook-form";
import { Box, FormLabel, HStack, useToast, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";

import {
  EnvironmentPatchArgs,
  EnvironmentPatchSchema,
} from "@bucketco/shared/environmentAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import { FormRootError } from "@/common/components/form/FormRootError";
import FormSelect from "@/common/components/form/FormSelect";
import FormSubmit from "@/common/components/form/FormSubmit";
import commonQueryKeys from "@/common/data/commonQueryKeys";
import useApiForm from "@/common/hooks/useApiForm";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useFeedbackPromptStatistics } from "@/common/hooks/useFeedbackPromptStatistics";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import FeedbackPromptStatisticsChart from "@/feature-legacy/components/FeedbackPromptStatisticsChart";
import FeedbackPromptStatisticsTable from "@/feature-legacy/components/FeedbackPromptStatisticsTable";

import FeedbackPromptingInstalledWrapper from "./FeedbackPromptingInstalledWrapper";

const STATS_START = dayjs.utc().subtract(6, "days").startOf("day").toDate();
const STATS_END = dayjs.utc().add(1, "day").startOf("day").toDate();

const MIN = 60;
const HOUR = 60 * MIN;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const MONTH = 30 * DAY;

export default function FeedbackPrompting() {
  const { currentEnv } = useAuthContext();
  const { envId, appId } = useCurrentEnv();

  const toast = useToast();
  const queryClient = useQueryClient();

  const { form, handleSubmit } = useApiForm(
    (data: EnvironmentPatchArgs) =>
      api
        .patch<"/apps/:appId/environments/:envId">(
          `/apps/${appId}/environments/${envId}`,
          data,
        )
        .then((res) => res.data.environment),
    EnvironmentPatchSchema,
    {
      onSuccess: (data) => {
        segmentAnalytics.track("Feedback Prompting Options Updated", {
          snooze_seconds: data.feedbackPromptSnoozePeriodSeconds,
        });

        toast({
          title: "Settings saved",
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        queryClient.invalidateQueries({ queryKey: commonQueryKeys.bootstrap });
        form.reset({
          feedbackPromptSnoozePeriodSeconds:
            data.feedbackPromptSnoozePeriodSeconds,
        });
      },
    },
    {
      defaultValues: {
        feedbackPromptSnoozePeriodSeconds:
          currentEnv?.feedbackPromptSnoozePeriodSeconds,
      },
    },
  );

  const promptStats = useFeedbackPromptStatistics(
    undefined,
    STATS_START,
    STATS_END,
  );

  return (
    <FeedbackPromptingInstalledWrapper>
      <VStack align="flex-start" spacing={5}>
        <VStack align="flex-start" spacing={2}>
          <FormLabel>Global prompting statistics</FormLabel>
          <HStack spacing={12}>
            <FeedbackPromptStatisticsTable {...promptStats} />
            <Box minW="180px">
              <FeedbackPromptStatisticsChart {...promptStats} />
            </Box>
          </HStack>
        </VStack>

        <form onSubmit={handleSubmit}>
          <FormProvider {...form}>
            <VStack align="flex-start" maxW="compactForm" spacing={4}>
              <FormSelect
                description="The user will not be asked for feedback again until this time has passed."
                label="Minimum time between asking for feedback"
                name="feedbackPromptSnoozePeriodSeconds"
                options={[
                  { value: 5 * MIN, label: "5 minutes" },
                  { value: 20 * MIN, label: "20 minutes" },
                  { value: 1 * HOUR, label: "1 hour" },
                  { value: 6 * HOUR, label: "6 hours" },
                  { value: 2 * DAY, label: "2 days" },
                  { value: 5 * DAY, label: "5 days" },
                  { value: 1 * WEEK, label: "1 week" },
                  { value: 2 * WEEK, label: "2 weeks" },
                  { value: 1 * MONTH, label: "1 month" },
                  { value: 2 * MONTH, label: "2 months" },
                  { value: 3 * MONTH, label: "3 months" },
                ]}
                valueAsNumber
              />

              <FormRootError />

              <FormSubmit>Save</FormSubmit>
            </VStack>
          </FormProvider>
        </form>
      </VStack>
    </FeedbackPromptingInstalledWrapper>
  );
}
