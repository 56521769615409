import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { getFilterCount } from "@bucketco/shared/filter";
import {
  TargetAudienceEstimate,
  TargetAudienceEstimateType,
} from "@bucketco/shared/flagAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import api from "@/common/utils/api";
import flagsQueryKeys from "@/flags/data/flagsQueryKeys";

export function useTargetAudienceEstimate(
  envId?: string,
  flagId?: string,
  ruleId?: string,
  args?: TargetAudienceEstimateType,
) {
  const { currentApp } = useAuthContext();

  return useQuery<TargetAudienceEstimate, AxiosError<ErrorResponse>>({
    queryKey: flagsQueryKeys.targetAudienceEstimate(
      currentApp?.id,
      envId,
      flagId,
      ruleId,
      args,
    ),

    queryFn: () =>
      api
        .post<"/apps/:appId/flags/:flagId/estimate">(
          `/apps/${currentApp?.id}/flags/${flagId}/estimate`,
          args,
          {
            params: { envId: envId! },
          },
        )
        .then((res) => res.data),
    enabled:
      !!currentApp &&
      !!envId &&
      !!flagId &&
      !!args &&
      getFilterCount(args.filter) > 0,
  });
}
