import { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { RiFlashlightLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spinner,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import { useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { AttributeFilterRuleSchema } from "@bucketco/shared/attributeFilter";
import { FeatureDetail } from "@bucketco/shared/featureAPI";
import {
  CreateFeatureFeedbackCampaignArgsType,
  DEFAULT_FEEDBACK_CAMPAIGN_PROPERTIES,
  FeatureFeedbackCampaignDTO,
  PatchFeatureFeedbackCampaignArgsSchema,
} from "@bucketco/shared/featureFeedbackAPI";

import FormCancel from "@/common/components/form/FormCancel";
import FormNumberInput from "@/common/components/form/FormNumberInput";
import FormRadioGroup from "@/common/components/form/FormRadioGroup";
import { FormRootError } from "@/common/components/form/FormRootError";
import FormSubmit from "@/common/components/form/FormSubmit";
import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import useApiForm from "@/common/hooks/useApiForm";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useFeedbackPromptStatistics } from "@/common/hooks/useFeedbackPromptStatistics";
import api from "@/common/utils/api";
import dayjs from "@/common/utils/dayjs";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { AffectsSpecificEnvironment } from "@/environment/components/EnvironmentCallouts";
import { FeedbackPromptModal } from "@/feature/components/FeedbackPromptModal";
import FeedbackPromptStatisticsChart from "@/feature/components/FeedbackPromptStatisticsChart";
import FeedbackPromptStatisticsTable from "@/feature/components/FeedbackPromptStatisticsTable";
import FormEventAttributeFilterPicker from "@/feature/components/FormEventAttributeFilterPicker";
import FormEventAutoCompleteInput from "@/feature/components/FormEventAutoCompleteInput";
import { featureQueryKeys } from "@/feature/data/featureQueryKeys";
import { useFeatureData } from "@/feature/data/useFeatureData";
import useFeatureFeedbackCampaignData from "@/feature/data/useFeatureFeedbackCampaignData";
import FeedbackPromptingInstalledWrapper from "@/global-settings/components/FeedbackPromptingInstalledWrapper";
import { useFeedbackPromptingStatus } from "@/global-settings/data/useFeedbackPromptingStatus";

const STATS_START = dayjs.utc().subtract(6, "days").startOf("day").toDate();
const STATS_END = dayjs.utc().add(1, "day").startOf("day").toDate();

export function SettingsFeedback() {
  const { featureId } = useParams();
  const {
    data: feedbackCampaign,
    isLoading,
    isError,
  } = useFeatureFeedbackCampaignData(featureId);

  const toast = useToast();

  const { data: feature } = useFeatureData(featureId!);

  useEffect(() => {
    if (isError) {
      toast({
        title: "Error loading automated survey settings",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [isError, toast]);

  return (
    <>
      {isLoading || isError ? (
        <Center height="100%">
          <Spinner color="dimmed" size="sm" />
        </Center>
      ) : (
        <FeedbackPromptingForm
          feature={feature!}
          feedbackCampaign={feedbackCampaign}
        />
      )}
    </>
  );
}

const feedbackFormSchema = z
  .object({
    eventIdentifier: z.string().max(255),
    eventAttributeFilter: z.array(AttributeFilterRuleSchema),
    customEvent: z.enum(["yes", "no"]).default("no"),
    data: PatchFeatureFeedbackCampaignArgsSchema,
  })
  .superRefine((schema, ctx) => {
    if (schema.customEvent === "yes" && !schema.eventIdentifier?.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Event name is required",
        path: ["data", "eventIdentifier"],
      });
    }
  });

const getDefaults = (
  feature: FeatureDetail,
  campaign: FeatureFeedbackCampaignDTO | undefined,
) => ({
  customEvent:
    campaign?.eventSelector || feature.source == "attribute" ? "yes" : "no",
  eventAttributeFilter: campaign?.eventSelector?.filter ?? [],
  eventIdentifier: campaign?.eventSelector?.name ?? "",

  data: {
    enabled: campaign?.enabled ?? DEFAULT_FEEDBACK_CAMPAIGN_PROPERTIES.enabled,
    question:
      campaign?.question ?? DEFAULT_FEEDBACK_CAMPAIGN_PROPERTIES.question,
    allowRepeatFeedback:
      campaign?.allowRepeatFeedback ??
      DEFAULT_FEEDBACK_CAMPAIGN_PROPERTIES.allowRepeatFeedback,
    minimumInteractions:
      campaign?.minimumInteractions ??
      DEFAULT_FEEDBACK_CAMPAIGN_PROPERTIES.minimumInteractions,
    promptMinSecondsAfterUse:
      campaign?.promptMinSecondsAfterUse ??
      DEFAULT_FEEDBACK_CAMPAIGN_PROPERTIES.promptMinSecondsAfterUse,
    promptMaxSecondsAfterUse:
      campaign?.promptMaxSecondsAfterUse ??
      DEFAULT_FEEDBACK_CAMPAIGN_PROPERTIES.promptMaxSecondsAfterUse,
    eventSelector: campaign?.eventSelector,
  } as CreateFeatureFeedbackCampaignArgsType,
});

function FeedbackPromptingForm({
  feedbackCampaign,
  feature,
}: {
  feedbackCampaign?: FeatureFeedbackCampaignDTO;
  feature: FeatureDetail;
}) {
  const { featureId } = useParams();
  const { envId, appId } = useCurrentEnv();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { form, handleSubmit } = useApiForm(
    async (formValues) => {
      const c = {
        ...formValues.data,
      };

      if (formValues.customEvent === "no") {
        c.eventSelector = null;
      } else {
        c.eventSelector = {
          name: formValues.eventIdentifier,
          filter: formValues.eventAttributeFilter,
        };
      }

      if (feedbackCampaign) {
        return api
          .patch<"/apps/:appId/features/:featureId/feedback/campaigns/:campaignId">(
            `/apps/${appId}/features/${featureId}/feedback/campaigns/${feedbackCampaign.id}`,
            c,
            { params: { envId: envId! } },
          )
          .then((res) => res.data);
      }

      return api
        .post<"/apps/:appId/features/:featureId/feedback/campaigns">(
          `/apps/${appId}/features/${featureId}/feedback/campaigns`,
          c,
          { params: { envId: envId! } },
        )
        .then((res) => res.data);
    },
    feedbackFormSchema,
    {
      onSuccess: (campaign) => {
        segmentAnalytics.track("Feature Options Updated", {
          enabled: campaign.enabled,
          section: "FeedbackPrompting",
        });
        toast({
          title: "Settings saved",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        queryClient.invalidateQueries({
          queryKey: featureQueryKeys.singleFeatureFeedbackCampaign(
            appId,
            envId,
            featureId,
          ),
        });
        queryClient.setQueryData(
          featureQueryKeys.singleFeatureFeedbackCampaign(
            appId,
            envId,
            featureId,
          ),
          campaign,
        );
        form.reset(getDefaults(feature, campaign));
      },
    },
    {
      defaultValues: getDefaults(feature, feedbackCampaign),
      mode: "onChange",
      reValidateMode: "onChange",
    },
  );

  const enabled = form.watch("data.enabled");
  const customEvent = form.watch("customEvent");
  const status = useFeedbackPromptingStatus();
  const isInstalled = status.data?.configured;

  const EventSelector = useMemo(() => {
    return function EventSelector() {
      return feature.source === "event" ? (
        <Flex direction="column" gap={3} w="full">
          <FormRadioGroup
            isDisabled={!enabled}
            label="Prompt trigger"
            name="customEvent"
            options={[
              { label: "Same as usage criteria", value: "no" },
              { label: "Custom event", value: "yes" },
            ]}
          />
          {customEvent === "yes" && (
            <Card display="flex" gap={2} p={4} variant="filled" w="full">
              <FormEventAutoCompleteInput
                isDisabled={!enabled}
                name="eventIdentifier"
                placeholder="Custom event name"
                size="sm"
              />
              <FormErrorMessage>
                <ErrorMessage name="eventIdentifier" />
              </FormErrorMessage>
              <FormEventAttributeFilterPicker
                buttonText="Add attribute filter"
                eventName={form.watch("eventIdentifier")}
                isDisabled={!enabled}
                name="eventAttributeFilter"
                size="sm"
              />
            </Card>
          )}
        </Flex>
      ) : (
        <Flex direction="column">
          <FormControl isDisabled={!enabled}>
            <FormLabel>Prompt trigger</FormLabel>
          </FormControl>
          <Card display="flex" gap={2} p={4} variant="filled" w="full">
            <FormEventAutoCompleteInput
              isDisabled={!enabled}
              name="eventIdentifier"
              placeholder="Custom event name"
              size="sm"
            />
            <FormErrorMessage>
              <ErrorMessage name="eventIdentifier" />
            </FormErrorMessage>
            <FormEventAttributeFilterPicker
              buttonText="Add attribute filter"
              eventName={form.watch("eventIdentifier")}
              isDisabled={!enabled}
              name="eventAttributeFilter"
              size="sm"
            />
          </Card>
        </Flex>
      );
    };
  }, [customEvent, enabled, feature.source, form]);

  const promptStats = useFeedbackPromptStatistics(
    featureId,
    STATS_START,
    STATS_END,
  );

  const [promptModalIsOpen, setPromptModalIsOpen] = useState(false);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <Flex direction="column" gap={5} maxW="compactForm">
          {!isInstalled ? (
            <FeedbackPromptingInstalledWrapper
              showInstalled={false}
              showLastActivity={false}
              showTitle={!isInstalled}
            />
          ) : (
            <>
              <AffectsSpecificEnvironment entity="live satisfaction" />
              <Flex direction="column" w="full">
                <ManagedFormControl
                  alignItems="center"
                  isDisabled={!feedbackCampaign?.enabled && !isInstalled}
                  label="Automated feedback survey"
                  name="data.enabled"
                  render={({ field }) => (
                    <Flex align="center" justify="space-between">
                      <Button
                        isDisabled={!isInstalled}
                        leftIcon={<RiFlashlightLine size="14" />}
                        size="2xs"
                        variant="outline"
                        onClick={() => setPromptModalIsOpen(true)}
                      >
                        Try it out
                      </Button>
                      <Switch
                        {...field}
                        colorScheme="brand"
                        isChecked={field.value}
                      />
                    </Flex>
                  )}
                  horizontal
                />
                <Text
                  color={
                    !feedbackCampaign?.enabled && !isInstalled
                      ? "dimmedDisabled"
                      : "dimmed"
                  }
                  fontSize="sm"
                  maxW="xs"
                >
                  Gather qualitative feedback from your users based on feature
                  interactions, directly in your web app
                </Text>
              </Flex>
              <Flex direction="column">
                <FormLabel>Prompting statistics</FormLabel>
                <Flex direction="row" gap={12}>
                  <FeedbackPromptStatisticsTable {...promptStats} />
                  <Box minW="180px">
                    <FeedbackPromptStatisticsChart {...promptStats} />
                  </Box>
                </Flex>
              </Flex>
              <ManagedFormControl
                description="This is what's shown to the user in the Feedback UI widget"
                isDisabled={!enabled}
                label="Prompt question"
                name="data.question"
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="How do you like this feature?"
                  />
                )}
              />
              <EventSelector />
              <FormNumberInput
                description="The minimum amount of user interactions with the feature before the feedback prompt can be shown."
                isDisabled={!enabled}
                label="Min. interactions before triggering"
                min={1}
                name="data.minimumInteractions"
                unit={(v) => (v === 1 ? "event" : "events")}
                w={144}
              />
              <FormNumberInput
                isDisabled={!enabled}
                label="Min. time after interactions"
                min={1}
                name="data.promptMinSecondsAfterUse"
                unit={(v) => (v === 1 ? "second" : "seconds")}
                w={144}
                onChange={() => {
                  form.trigger("data.promptMaxSecondsAfterUse");
                }}
              />
              <FormNumberInput
                description="The time window (min and max) when feedback prompt can be displayed to the user after successful interaction with the feature."
                isDisabled={!enabled}
                label="Max. time after interactions"
                min={1}
                name="data.promptMaxSecondsAfterUse"
                unit={(v) => (v === 1 ? "second" : "seconds")}
                w={144}
                onChange={() => {
                  form.trigger("data.promptMinSecondsAfterUse");
                }}
              />
              <FormRootError />
              <ButtonGroup>
                <FormSubmit>Save</FormSubmit>
                {form.formState.isDirty && (
                  <FormCancel
                    color="dimmed"
                    size="sm"
                    onClick={() => form.reset()}
                  >
                    Reset
                  </FormCancel>
                )}
              </ButtonGroup>
            </>
          )}
        </Flex>
      </form>
      <FeedbackPromptModal
        isOpen={promptModalIsOpen}
        onClose={() => setPromptModalIsOpen(false)}
      />
    </FormProvider>
  );
}
