import { useEffect } from "react";

import { SlackChannel } from "@bucketco/shared/slackConnectionAPI";

import { getPreferredSlackChannel } from "@/common/utils/getPreferredSlackChannel";

// set default slack channel if none is set
export default function useDefaultSlackChannel({
  slackChannels,
  currentChannel,
  preferredChannelId,
  cb,
}: {
  slackChannels: SlackChannel[];
  currentChannel: string | undefined | null;
  preferredChannelId?: string | null;
  cb: (channel: SlackChannel) => void;
}) {
  useEffect(() => {
    if (!currentChannel && slackChannels.length) {
      const channel = getPreferredSlackChannel(
        slackChannels,
        preferredChannelId || undefined,
      );

      if (channel) {
        cb(channel);
      }
    }
  }, [currentChannel, slackChannels, preferredChannelId, cb]);
}
