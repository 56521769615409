import { RiArrowDropLeftLine, RiArrowDropRightLine } from "react-icons/ri";
import { ButtonGroup, HStack, IconButton, Text } from "@chakra-ui/react";

import CompactNumber from "@/common/components/CompactNumber";
import { formatNumber } from "@/common/utils/numberFormatting";

export default function TablePagination({
  pageCount,
  pageIndex,
  pageSize,
  totalCount,
  canPaginate,
  paginateActions,
  label,
}: {
  pageCount?: number;
  pageIndex?: number;
  pageSize?: number;
  totalCount?: number;
  canPaginate: { canNextPage: boolean; canPreviousPage: boolean };
  paginateActions: { nextPage: () => void; previousPage: () => void };

  /**
   * Aria label to identify
   */
  label: string;
}) {
  const hasPageInfo =
    pageCount !== undefined &&
    pageIndex !== undefined &&
    pageSize !== undefined &&
    totalCount !== undefined;
  return (
    <HStack aria-label={label} as="nav" spacing={3}>
      <Text color="gray.500" fontSize="sm">
        {hasPageInfo ? (
          pageCount > 1 ? (
            <>
              {`Showing ${formatNumber(
                pageIndex * pageSize + 1,
              )} to ${formatNumber(
                Math.min(pageSize * pageIndex + pageSize, totalCount),
              )} of `}
              <CompactNumber value={totalCount} />
            </>
          ) : (
            <>
              Showing <CompactNumber value={totalCount} /> of{" "}
              <CompactNumber value={totalCount} />
            </>
          )
        ) : null}
      </Text>
      <ButtonGroup size="xs" variant="outline" isAttached>
        <IconButton
          aria-label="Previous page"
          icon={<RiArrowDropLeftLine size={27} />}
          isDisabled={!canPaginate.canPreviousPage}
          variant="outline"
          onClick={paginateActions.previousPage}
        />
        <IconButton
          aria-label="Next page"
          icon={<RiArrowDropRightLine size={27} />}
          isDisabled={!canPaginate.canNextPage}
          variant="outline"
          onClick={paginateActions.nextPage}
        />
      </ButtonGroup>
    </HStack>
  );
}
