import { FormEventHandler } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Flex, HStack, Text } from "@chakra-ui/react";

import { CreateFeatureArgsType } from "@bucketco/shared/featureAPI";
import { generateFeatureKey } from "@bucketco/shared/utils/feature";

import { FormFieldSet } from "@/common/components/form/FormFieldSet";
import FormInput from "@/common/components/form/FormInput";
import FormRadioGroupButtons from "@/common/components/form/FormRadioGroupButtons";
import FormSwitch from "@/common/components/form/FormSwitch";
import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import { useFeatureKeys } from "@/feature/data/useFeatureData";
import FormFeatureViewsPicker from "@/feature-legacy/components/form/FormFeatureViewsPicker";
import FormSegmentPicker from "@/feature-legacy/components/form/FormSegmentPicker";
import { FormCompanyAttributeFilterPickerInput } from "@/feature-legacy/components/FormCompanyAttributeFilterPicker";
import { FormEventBasedFilterInputs } from "@/feature-legacy/components/FormEventBasedFilterInputs";

function FormAutoFeedbackSurveys() {
  const { trigger } = useFormContext();
  const isFeedbackCampaignEnabled = useWatch({
    name: "feedbackCampaign.enabled",
  });

  return (
    <ManagedFormControl
      _label={{ w: "140px" }}
      description="Enables you to automatically gather qualitative feedback from your
      users based on feature interactions."
      descriptionInPortal={false}
      descriptionLink="https://docs.bucket.co/product-handbook/automated-feedback-surveys"
      justifyContent="flex-start"
      label="Auto. feedback"
      name="feedbackCampaign.question"
      showErrors={false}
      w="compactForm"
      horizontal
    >
      <HStack alignItems="flex-start" gap={0}>
        <FormSwitch
          _control={{ w: "auto" }}
          mt={1.5}
          name="feedbackCampaign.enabled"
          showErrors={false}
          onChange={() => {
            trigger("feedbackCampaign.question");
          }}
        />
        <FormInput
          isDisabled={!isFeedbackCampaignEnabled}
          name="feedbackCampaign.question"
          placeholder="How do you like this feature?"
        />
      </HStack>
    </ManagedFormControl>
  );
}

function FormEventInputs() {
  const isSourceEventSelected = useWatch({ name: "source" }) === "event";

  const sharedFieldProps = {
    _label: { w: "140px" },
  };

  if (isSourceEventSelected) {
    return (
      <ManagedFormControl
        {...sharedFieldProps}
        label="Events"
        name="eventSelectors"
        horizontal
      >
        <FormEventBasedFilterInputs />
      </ManagedFormControl>
    );
  }

  return (
    <ManagedFormControl
      {...sharedFieldProps}
      label="Company attributes"
      name="usingItAttributeFilter"
      horizontal
    >
      <FormCompanyAttributeFilterPickerInput
        buttonText="Add filter"
        name="usingItAttributeFilter"
      />
    </ManagedFormControl>
  );
}

type Props = {
  id?: string;
  showAutoFeedbackSurveys?: boolean;
  onSubmit?: FormEventHandler<HTMLFormElement>;
};

export const NewFeatureForm = ({
  id = "new-feature",
  showAutoFeedbackSurveys: showLiveSatisfaction,
  onSubmit,
}: Props) => {
  const { clearErrors, setValue, getFieldState } =
    useFormContext<CreateFeatureArgsType>();
  const hasFeedbackQuestionChanged = getFieldState(
    "feedbackCampaign.question",
  ).isDirty;

  const sharedFieldProps = {
    _control: { w: "compactForm" },
    _label: { w: "140px" },
  };

  const { isEnabled: featureRevamp } = useFeature("feature-revamp-2024");

  const existingKeys = useFeatureKeys();

  return (
    <form id={id} onSubmit={onSubmit}>
      <Flex direction="column" gap={4}>
        <FormFieldSet>
          <FormInput
            {...sharedFieldProps}
            autoComplete="off"
            label="Name"
            name="name"
            placeholder="Ex. User sent a chat message"
            horizontal
            onChange={(e) => {
              if (!hasFeedbackQuestionChanged) {
                clearErrors("feedbackCampaign.question");
                setValue(
                  "feedbackCampaign.question",
                  e.currentTarget.value
                    ? `How do you like ${e.currentTarget.value.toLowerCase()}?`
                    : "",
                );

                setValue(
                  "key",
                  generateFeatureKey(
                    e.currentTarget.value,
                    existingKeys.data || [],
                  ),
                );
              }
            }}
          />

          {featureRevamp && (
            <FormInput
              {...sharedFieldProps}
              description={
                "Feature key is autogenerated based on the feature name. This key is then used internally to reference the tracking events."
              }
              descriptionInPortal={false}
              isReadOnly={true}
              label="Key"
              name="key"
              horizontal
            />
          )}

          {!featureRevamp && (
            <FormFeatureViewsPicker
              {...sharedFieldProps}
              description="Use feature views to organize your features into teams, product categories etc."
              descriptionInPortal={false}
              label="Feature views"
              name="featureViews"
              horizontal
            />
          )}

          {showLiveSatisfaction && !featureRevamp && (
            <FormAutoFeedbackSurveys />
          )}
        </FormFieldSet>

        {!featureRevamp && (
          <FormFieldSet title="Tracking Criteria">
            <FormRadioGroupButtons
              {...sharedFieldProps}
              description={
                <>
                  <Text>
                    Events are used for tracking frequently used features, such
                    as &quot;Sent chat message&quot;.
                  </Text>
                  <Text mt={2}>
                    Company attributes are used to track workspace features,
                    such as &quot;Authenticated with Slack&quot;.
                  </Text>
                </>
              }
              descriptionInPortal={false}
              label="Source"
              name="source"
              options={[
                {
                  key: "event",
                  label: "Event",
                },
                {
                  key: "attribute",
                  label: "Company attributes",
                },
              ]}
              horizontal
            />

            <FormEventInputs />

            <FormSegmentPicker
              {...sharedFieldProps}
              label="Target segment"
              name="segmentId"
              horizontal
              showPieChart
            />
          </FormFieldSet>
        )}
      </Flex>
    </form>
  );
};
