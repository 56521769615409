import dayjs from "dayjs";
import { z } from "zod";

import { RealtimeFeatureMetrics } from "./realtime/types";
import { paginationQueryBaseSchema } from "./schemas/dataTableSchema";
import { Paginated } from "./types/Paginated";
import { NonEmpty } from "./utils/types";
import { APIEmptyResponse, APIResponse } from "./api";
import { AttributeFilter } from "./attributeFilter";
import { EventSelectorType, FeatureName } from "./featureAPI";
import { GoalDTO, GoalPostSchema } from "./goalAPI";

export type ReleaseStatus = "planned" | "evaluating" | "concluded";

// Post & Patch DTO

export const ReleasePostSchema = z
  .object({
    name: z
      .string()
      .max(128)
      .transform((v) => v.trim() || undefined)
      .default(() => `Untitled, ${dayjs().format("MMM D, YYYY")}`),
    releasedAt: z.string().datetime().nullable().optional(),
    evaluationPeriod: z.number().min(1).default(14),
    slackChannelId: z.string().nullable().optional(),
    slackChannelName: z.string().nullable().optional(),
    slackNotificationsEnabled: z.boolean().default(false),
    flagId: z.string().nullable().optional(),
    goals: z.lazy(() => z.array(GoalPostSchema).optional()),
  })
  .strict();
export type ReleasePostArgs = z.input<typeof ReleasePostSchema>;

export const ReleasePatchSchema = ReleasePostSchema.partial()
  .extend({
    name: z
      .string()
      .trim()
      .min(1, "The release needs a name")
      .max(128)
      .optional(),
    concludedAt: z.string().datetime().nullable().optional(),
    flagId: z.string().nullable().optional(),
    goals: z.lazy(() =>
      z
        .array(
          GoalPostSchema.extend({
            id: z.string().length(14).optional(),
          }),
        )
        .optional(),
    ),
  })
  .strict();
export type ReleasePatchArgs = z.input<typeof ReleasePatchSchema>;

// Model
export type ReleaseDTO = {
  id: string;
  name: string;
  status: ReleaseStatus;

  flagId: string | null;

  releasedAt: string | null;
  concludedAt: string | null;
  evaluationPeriod: number;

  slackChannelId: string | null;
  slackChannelName: string | null;
  slackNotificationsEnabled: boolean;
};

export type ReleaseDetailsDTO = ReleaseDTO & {
  goals: Array<GoalDTO>;
  flag: { id: string; key: string; description: string | null } | null;
};

export type ReleaseLinkedFeatureDTO = Omit<FeatureName, "source"> & {
  hasLiveSatisfaction: boolean;
  metrics?: RealtimeFeatureMetrics;
} & (
    | {
        source: "event";
        eventSelectors: EventSelectorType[];
      }
    | {
        source: "attribute";
        usingItAttributeFilter: NonEmpty<AttributeFilter>;
      }
  );

// Query

export const ReleasesSortByColumns = [
  "name",
  "releasedAt",
  "evaluationPeriod",
  "concludedAt",
] as const;
const ReleasesQuerySortBySchema = z.enum(ReleasesSortByColumns);
export type ReleasesQuerySortBy = z.infer<typeof ReleasesQuerySortBySchema>;

export const ReleasesQuerySchema = paginationQueryBaseSchema
  .extend({
    sortBy: ReleasesQuerySortBySchema.default("releasedAt"),
    idNameFilter: z.string().optional(),
  })
  .strict();
export type ReleasesQuery = z.input<typeof ReleasesQuerySchema>;

// API

export interface ReleaseAPI {
  "/apps/:appId/releases": {
    POST: {
      body: ReleasePostArgs;
      response: APIResponse<{
        release: ReleaseDetailsDTO;
      }>;
      params: { appId: string };
    };
    GET: {
      response: APIResponse<
        Paginated<
          ReleaseDetailsDTO,
          ReleasesQuerySortBy,
          { idNameFilter?: string | undefined }
        >
      >;
      params: { appId: string };
      query: ReleasesQuery;
    };
  };
  "/apps/:appId/releases/:releaseId": {
    GET: {
      response: APIResponse<{
        release: ReleaseDetailsDTO;
      }>;
      params: { appId: string; releaseId: string };
    };
    PATCH: {
      body: ReleasePatchArgs;
      response: APIResponse<{
        release: ReleaseDetailsDTO;
      }>;
      params: { appId: string; releaseId: string };
    };
    DELETE: {
      response: APIEmptyResponse;
      params: { appId: string; releaseId: string };
    };
  };
  "/apps/:appId/releases/:releaseId/refresh": {
    POST: {
      response: APIResponse<{
        release: ReleaseDetailsDTO;
      }>;
      params: { appId: string; releaseId: string };
    };
  };
  "/apps/:appId/releases/:releaseId/features": {
    GET: {
      response: APIResponse<ReleaseLinkedFeatureDTO[]>;
      params: { appId: string; releaseId: string };
    };
    PUT: {
      body: { id: string };
      response: APIResponse<ReleaseLinkedFeatureDTO[]>;
      params: { appId: string; releaseId: string };
    };
  };
  "/apps/:appId/releases/:releaseId/features/:featureId": {
    DELETE: {
      response: APIEmptyResponse;
      params: { appId: string; releaseId: string; featureId: string };
    };
  };
}
