import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import {
  CreateFlagVersionArgsType,
  FlagVersion,
} from "@bucketco/shared/flagAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import flagsQueryKeys from "@/flags/data/flagsQueryKeys";

export function useFlagVersionCreateMutation(
  flagId: string,
  sendNotification?: boolean,
  envId?: string,
) {
  const { appId, envId: currentEnvId } = useCurrentEnv();
  const queryClient = useQueryClient();

  return useMutation<
    FlagVersion,
    AxiosError<ErrorResponse>,
    CreateFlagVersionArgsType
  >({
    mutationFn: (formData) =>
      api
        .post<"/apps/:appId/flags/:flagId/versions">(
          `/apps/${appId}/flags/${flagId}/versions`,
          formData,
          {
            params: {
              envId: envId ?? currentEnvId!,
              sendNotification: sendNotification
                ? sendNotification
                  ? "true"
                  : "false"
                : undefined,
            },
          },
        )
        .then((response) => response.data.flagVersion),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: flagsQueryKeys.single(appId, flagId),
      });
      queryClient.invalidateQueries({
        queryKey: flagsQueryKeys.versions(appId, flagId),
      });
    },
  });
}
