import { useEffect, useMemo, useRef } from "react";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { RiFlashlightLine } from "react-icons/ri";
import {
  Link as RouterLink,
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { AttributeFilterRuleSchema } from "@bucketco/shared/attributeFilter";
import {
  DEFAULT_FEATURE_ADOPTION_SETTINGS,
  EventPatchFeatureArgsSchema,
  FeatureDetail,
  PatchFeatureArgsSchema,
  PatchFeatureArgsType,
} from "@bucketco/shared/featureAPI";
import {
  CreateFeatureFeedbackCampaignArgsType,
  DEFAULT_FEEDBACK_CAMPAIGN_PROPERTIES,
  FeatureFeedbackCampaignDTO,
  PatchFeatureFeedbackCampaignArgsSchema,
  SendPromptArgsSchema,
  SendPromptDTO,
} from "@bucketco/shared/featureFeedbackAPI";
import { getFeatureParentNames } from "@bucketco/shared/utils/getFeatureParentNames";

import { getStringFilterFunction } from "@/common/components/AutocompleteSelect";
import { FeatureAutocompleteSelect } from "@/common/components/FeatureAutocompleteSelect";
import FormInput from "@/common/components/form/FormInput";
import FormNumberInput from "@/common/components/form/FormNumberInput";
import FormRadioGroup from "@/common/components/form/FormRadioGroup";
import { FormRootError } from "@/common/components/form/FormRootError";
import FormSubmit from "@/common/components/form/FormSubmit";
import FormSwitch from "@/common/components/form/FormSwitch";
import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import ModalCancelButton from "@/common/components/ModalCancelButton";
import SettingSection from "@/common/components/SettingSection";
import { UserAutocompleteSelect } from "@/common/components/UserAutocompleteSelect";
import useApiForm from "@/common/hooks/useApiForm";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import {
  AffectsSpecificEnvironment,
  DeleteAffectEnvironments,
} from "@/environment/components/EnvironmentCallouts";
import { FormCompanyAttributeFilterPickerInput } from "@/feature-legacy/components/FormCompanyAttributeFilterPicker";
import FormEventAttributeFilterPicker from "@/feature-legacy/components/FormEventAttributeFilterPicker";
import FormEventAutoCompleteInput from "@/feature-legacy/components/FormEventAutoCompleteInput";
import { FormEventBasedFilterInputs } from "@/feature-legacy/components/FormEventBasedFilterInputs";
import SegmentPicker from "@/feature-legacy/components/SegmentPicker";
import { useFeatureContext } from "@/feature-legacy/contexts/featureContext";
import featureQueryKeys from "@/feature-legacy/data/featureQueryKeys";
import useFeatureFeedbackCampaignData from "@/feature-legacy/data/useFeatureFeedbackCampaignData";
import { useUserFeedbackPromptingStatus } from "@/feature-legacy/data/useUserFeedbackPromptingStatus";
import { useFeedbackPromptingStatus } from "@/global-settings/data/useFeedbackPromptingStatus";
import { goalQueryKeys } from "@/release/data/goalQueryKeys";
import useFeatureActiveGoalsData from "../data/useFeatureActiveGoalsData";

function Delete() {
  const { appId } = useCurrentEnv();
  const { featureId } = useParams();

  const toast = useToast();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  const navigate = useNavigate();
  const { feature } = useFeatureContext();

  const { data: goals, isLoading: isGoalsLoading } =
    useFeatureActiveGoalsData(featureId);

  const { mutate: deleteFeature, isPending: isDeleteLoading } = useMutation({
    mutationFn: () =>
      api.delete<"/apps/:appId/features/:featureId">(
        `/apps/${appId}/features/${featureId}`,
      ),

    retry: 0,

    onSuccess: async () => {
      segmentAnalytics.track("Feature Deleted");
      toast({
        title: "Feature deleted",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      queryClient.invalidateQueries({
        queryKey: featureQueryKeys.listNames(appId),
      });
      queryClient.cancelQueries({
        queryKey: featureQueryKeys.single(appId, featureId),
      });
      queryClient.invalidateQueries({ queryKey: featureQueryKeys.list(appId) });
      goals?.forEach(({ release }) => {
        queryClient.removeQueries({
          queryKey: goalQueryKeys.list(appId, release.id),
        });
      });
      navigate("../../");
    },

    onError: () => {
      errorToast({
        description: "Couldn't delete feature",
      });
    },
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  return (
    <SettingSection
      description="This won't delete any of the feature data."
      title="Delete feature"
    >
      <Button
        colorScheme="red"
        isDisabled={isDeleteLoading}
        loadingText="Deleting..."
        type="submit"
        variant="solid"
        onClick={onOpen}
      >
        Delete feature
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Delete feature</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete <strong>{feature?.name}</strong> ?
              <Text mt={2}>
                No underlying tracking data will be deleted (events, company
                attributes etc.), but you can&apos;t undo this action
                afterwards.
              </Text>
              <Text mt={2}>
                Any user feedback that was collected for this feature{" "}
                <strong>will</strong> be deleted.
              </Text>
              {isGoalsLoading ? (
                <Center height="100%">
                  <Spinner color="dimmed" size="sm" />
                </Center>
              ) : goals?.length ? (
                <Text mt={2}>
                  <strong>{feature?.name}</strong> is currently associated with{" "}
                  <strong>{goals.length}</strong> active release goals. Deleting
                  this feature will delete these goals and they will{" "}
                  <strong>no longer count</strong> towards their releases&apos;
                  progress.
                </Text>
              ) : (
                <Text mt={2}>
                  <strong>{feature?.name}</strong> is not associated with any
                  active release goals.
                </Text>
              )}
              <DeleteAffectEnvironments entity="feature" mt={8} />
            </AlertDialogBody>
            <AlertDialogFooter>
              <ButtonGroup>
                <Button
                  ref={cancelRef}
                  _hover={{ color: useColorModeValue("gray.700", "gray.300") }}
                  color="gray.500"
                  isDisabled={isDeleteLoading || isGoalsLoading}
                  variant="ghost"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  isDisabled={isGoalsLoading}
                  isLoading={isDeleteLoading}
                  loadingText="Deleting"
                  onClick={() => deleteFeature()}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </SettingSection>
  );
}

function General() {
  const { appId, envId } = useCurrentEnv();
  const { featureId } = useParams();
  const { feature } = useFeatureContext();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { form, handleSubmit } = useApiForm(
    (data: PatchFeatureArgsType) =>
      api
        .patch<"/apps/:appId/features/:featureId">(
          `/apps/${appId}/features/${featureId}`,
          data,
          { params: { envId: envId! } },
        )
        .then((res) => res.data.feature),
    PatchFeatureArgsSchema,
    {
      onSuccess: (modifiedFeature) => {
        segmentAnalytics.track("Feature Options Updated", {
          section: "General",
          feature_parent_modified:
            feature?.parentFeatureId !== modifiedFeature.parentFeatureId,
        });
        toast({
          title: "Settings saved",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        queryClient.invalidateQueries({
          queryKey: featureQueryKeys.listNames(appId),
        });
        queryClient.invalidateQueries({
          queryKey: featureQueryKeys.list(appId),
        });
        queryClient.invalidateQueries({
          queryKey: featureQueryKeys.single(appId, featureId),
        });
        queryClient.setQueryData(
          featureQueryKeys.single(appId, modifiedFeature.id),
          modifiedFeature,
        );
        form.reset({
          name: modifiedFeature.name,
          parentFeatureId: modifiedFeature.parentFeatureId,
        });
      },
    },
    {
      defaultValues: {
        name: feature?.name,
        parentFeatureId: feature?.parentFeatureId,
      },
    },
  );

  const namefilter = getStringFilterFunction("name");

  return (
    <SettingSection
      description="Choose a descriptive name for this feature."
      title="General"
    >
      <form onSubmit={handleSubmit}>
        <FormProvider {...form}>
          <VStack align="flex-start" maxW="compactForm" spacing={4}>
            <FormInput
              autoComplete="off"
              label="Feature name"
              name="name"
              placeholder="Ex. Chat Messaging"
              autoFocus
            />

            <Box w="full">
              <ManagedFormControl
                label="Parent feature"
                name="parentFeatureId"
                render={({ field }) => {
                  return (
                    <FeatureAutocompleteSelect
                      placeholder="Select a parent feature"
                      w="full"
                      {...field}
                      itemFilterFn={(f, search, features) => {
                        // Don't suggest the current feature
                        if (f.id === featureId) {
                          return false;
                        }

                        // Don't suggest any children of the current feature
                        const parentNames = getFeatureParentNames(
                          features,
                          f.id,
                        );
                        if (parentNames.some((p) => p.id === featureId)) {
                          return false;
                        }

                        return namefilter(f, search);
                      }}
                      onChange={(value) => field.onChange(value?.id)}
                    />
                  );
                }}
              />
            </Box>

            <FormRootError />
            <FormSubmit>Save</FormSubmit>
          </VStack>
        </FormProvider>
      </form>
    </SettingSection>
  );
}

function TrackingCriteria() {
  const { appId, envId } = useCurrentEnv();
  const { featureId } = useParams();
  const { feature } = useFeatureContext();
  const queryClient = useQueryClient();
  const toast = useToast();

  const defaultValues =
    feature?.source === "event"
      ? {
          customEventSelectors: feature.customEventSelectors,
          segmentId: feature?.segment?.id ?? null,
        }
      : {
          usingItAttributeFilter: feature?.usingItAttributeFilter ?? [
            {
              field: "",
              operator: "IS",
              values: [""],
            },
          ],
          segmentId: feature?.segment?.id ?? null,
        };

  const { form, handleSubmit } = useApiForm(
    async (data: PatchFeatureArgsType) => {
      return api
        .patch<"/apps/:appId/features/:featureId">(
          `/apps/${appId}/features/${featureId}`,
          data,
          { params: { envId: envId! } },
        )
        .then((res) => res.data.feature);
    },
    PatchFeatureArgsSchema,
    {
      onSuccess: (feature) => {
        segmentAnalytics.track("Feature Options Updated", {
          section: "Tracking criteria",
        });
        toast({
          title: "Settings saved",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        queryClient.invalidateQueries({
          queryKey: featureQueryKeys.list(appId),
        });
        queryClient.invalidateQueries({
          queryKey: featureQueryKeys.single(appId, featureId),
        });
        queryClient.setQueryData(
          featureQueryKeys.single(appId, featureId),
          feature,
        );

        form.reset({
          customEventSelectors: feature.customEventSelectors,
          segmentId: feature.segment?.id ?? null,
          usingItAttributeFilter: feature.usingItAttributeFilter,
        });
      },
    },
    {
      defaultValues,
      mode: "onChange",
    },
  );

  return (
    <SettingSection
      description={
        <Box color="gray.500">
          <Text mb={2}>
            {feature?.source === "event"
              ? "Choose event that counts as usage of this feature and which companies this feature is relevant for."
              : "Choose company attribute value(s) that count as usage of this feature and which companies this feature is relevant for."}
          </Text>
        </Box>
      }
      title="Tracking criteria"
    >
      <form onSubmit={handleSubmit}>
        <FormProvider {...form}>
          <VStack align="flex-start" spacing={4} w={"xl"}>
            <FormEventInputs feature={feature} />
            <FormControl id="segment">
              <FormLabel>Target segment</FormLabel>
              <HStack>
                <Controller
                  control={form.control}
                  name="segmentId"
                  render={({ field }) => (
                    <SegmentPicker id="segmentId" showPieChart {...field} />
                  )}
                />
              </HStack>
            </FormControl>
            <FormRootError />
            <FormSubmit>Save</FormSubmit>
          </VStack>
        </FormProvider>
      </form>
    </SettingSection>
  );
}

function FormEventInputs({ feature }: { feature?: FeatureDetail }) {
  if (feature?.source === "event") {
    return <FormEventBasedFilterInputs w="full" />;
  }

  return (
    <FormCompanyAttributeFilterPickerInput
      buttonText="Add filter"
      name="usingItAttributeFilter"
    />
  );
}

function FeedbackPrompting({ feature }: { feature: FeatureDetail }) {
  const { featureId } = useParams();
  const {
    data: feedbackCampaign,
    isLoading,
    isError,
  } = useFeatureFeedbackCampaignData(featureId);
  const status = useFeedbackPromptingStatus();

  const toast = useToast();

  useEffect(() => {
    if (isError) {
      toast({
        title: "Error loading automated feedback surveys settings",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [isError, toast]);

  const tryItOutButton =
    feedbackCampaign?.enabled && status.data?.configured ? (
      <Button
        as={RouterLink}
        leftIcon={<RiFlashlightLine size="16" />}
        size="sm"
        to={{
          pathname: "prompt",
        }}
        variant="outline"
      >
        Try it out
      </Button>
    ) : (
      <Button
        as="button"
        isDisabled={true}
        leftIcon={<RiFlashlightLine size="16" />}
        size="sm"
        variant="outline"
      >
        Try it out
      </Button>
    );

  return (
    <SettingSection
      description={
        <VStack align="flex-start">
          <Text mb={2}>
            Enables you to automatically gather qualitative feedback from your
            users based on feature interactions.
          </Text>
          {tryItOutButton}
        </VStack>
      }
      title="Feedback"
    >
      {isLoading || isError ? (
        <Center height="100%">
          <Spinner color="dimmed" size="sm" />
        </Center>
      ) : (
        <FeedbackPromptingForm
          feature={feature}
          feedbackCampaign={feedbackCampaign}
        />
      )}
    </SettingSection>
  );
}

const feedbackFormSchema = z
  .object({
    eventIdentifier: z.string().max(255),
    eventAttributeFilter: z.array(AttributeFilterRuleSchema),
    customEvent: z.enum(["yes", "no"]).default("no"),
    data: PatchFeatureFeedbackCampaignArgsSchema,
  })
  .superRefine((schema, ctx) => {
    if (schema.customEvent === "yes" && !schema.eventIdentifier?.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Event name is required",
        path: ["data", "eventIdentifier"],
      });
    }
  });

function FeedbackPromptingForm({
  feedbackCampaign,
  feature,
}: {
  feedbackCampaign?: FeatureFeedbackCampaignDTO;
  feature: FeatureDetail;
}) {
  const { featureId } = useParams();
  const { envId, appId } = useCurrentEnv();
  const queryClient = useQueryClient();
  const toast = useToast();

  const getDefaults = (campaign: FeatureFeedbackCampaignDTO | undefined) => ({
    customEvent:
      campaign?.eventSelector || feature.source == "attribute" ? "yes" : "no",
    eventAttributeFilter: campaign?.eventSelector?.filter ?? [],
    eventIdentifier: campaign?.eventSelector?.name ?? "",

    data: {
      enabled:
        campaign?.enabled ?? DEFAULT_FEEDBACK_CAMPAIGN_PROPERTIES.enabled,
      question:
        campaign?.question ?? DEFAULT_FEEDBACK_CAMPAIGN_PROPERTIES.question,
      allowRepeatFeedback:
        campaign?.allowRepeatFeedback ??
        DEFAULT_FEEDBACK_CAMPAIGN_PROPERTIES.allowRepeatFeedback,
      minimumInteractions:
        campaign?.minimumInteractions ??
        DEFAULT_FEEDBACK_CAMPAIGN_PROPERTIES.minimumInteractions,
      promptMinSecondsAfterUse:
        campaign?.promptMinSecondsAfterUse ??
        DEFAULT_FEEDBACK_CAMPAIGN_PROPERTIES.promptMinSecondsAfterUse,
      promptMaxSecondsAfterUse:
        campaign?.promptMaxSecondsAfterUse ??
        DEFAULT_FEEDBACK_CAMPAIGN_PROPERTIES.promptMaxSecondsAfterUse,
      eventSelector: campaign?.eventSelector,
    } as CreateFeatureFeedbackCampaignArgsType,
  });

  const { form, handleSubmit } = useApiForm(
    async (formValues) => {
      const c = {
        ...formValues.data,
      };

      if (formValues.customEvent === "no") {
        c.eventSelector = null;
      } else {
        c.eventSelector = {
          name: formValues.eventIdentifier,
          filter: formValues.eventAttributeFilter,
        };
      }

      if (feedbackCampaign) {
        return api
          .patch<"/apps/:appId/features/:featureId/feedback/campaigns/:campaignId">(
            `/apps/${appId}/features/${featureId}/feedback/campaigns/${feedbackCampaign.id}`,
            c,
            { params: { envId: envId! } },
          )
          .then((res) => res.data);
      }

      return api
        .post<"/apps/:appId/features/:featureId/feedback/campaigns">(
          `/apps/${appId}/features/${featureId}/feedback/campaigns`,
          c,
          { params: { envId: envId! } },
        )
        .then((res) => res.data);
    },
    feedbackFormSchema,
    {
      onSuccess: (campaign) => {
        segmentAnalytics.track("Feature Options Updated", {
          enabled: campaign.enabled,
          section: "FeedbackPrompting",
        });
        toast({
          title: "Settings saved",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        queryClient.invalidateQueries({
          queryKey: featureQueryKeys.singleFeatureFeedbackCampaign(
            appId,
            envId,
            featureId,
          ),
        });
        queryClient.setQueryData(
          featureQueryKeys.singleFeatureFeedbackCampaign(
            appId,
            envId,
            featureId,
          ),
          campaign,
        );
        form.reset(getDefaults(campaign));
      },
    },
    {
      defaultValues: getDefaults(feedbackCampaign),
      mode: "onChange",
      reValidateMode: "onChange",
    },
  );

  const enabled = form.watch("data.enabled");
  const customEvent = form.watch("customEvent");

  const EventSelector = useMemo(() => {
    return function EventSelector() {
      return feature.source === "event" ? (
        <>
          <FormRadioGroup
            isDisabled={!enabled}
            label="Prompt trigger"
            name="customEvent"
            options={[
              { label: "Same as tracking criteria", value: "no" },
              { label: "Custom event", value: "yes" },
            ]}
          />
          {customEvent === "yes" && (
            <VStack align="flex-start" pl={"2em"} w="100%">
              <FormEventAutoCompleteInput
                isDisabled={!enabled}
                name="eventIdentifier"
                placeholder="Custom event name"
              />
              <FormErrorMessage>
                <ErrorMessage name="eventIdentifier" />
              </FormErrorMessage>
              <FormEventAttributeFilterPicker
                eventName={form.watch("eventIdentifier")}
                isDisabled={!enabled}
                name="eventAttributeFilter"
              />
            </VStack>
          )}
        </>
      ) : (
        <VStack align="flex-start" w="100%">
          <FormEventAutoCompleteInput
            isDisabled={!enabled}
            label="Prompt trigger"
            name="eventIdentifier"
            placeholder="Custom event name"
          />
          <FormErrorMessage>
            <ErrorMessage name="eventIdentifier" />
          </FormErrorMessage>
          <FormEventAttributeFilterPicker
            eventName={form.watch("eventIdentifier")}
            isDisabled={!enabled}
            name="eventAttributeFilter"
          />
        </VStack>
      );
    };
  }, [customEvent, enabled, feature.source, form]);

  return (
    <form onSubmit={handleSubmit}>
      <FormProvider {...form}>
        <VStack align="flex-start" maxW="compactForm" spacing={4}>
          <AffectsSpecificEnvironment entity="Feedback surveys" />
          <FormSwitch
            _control={{ mb: 2 }}
            label="Enable Automated Feedback Surveys"
            name="data.enabled"
          />
          <FormInput
            description="This is what's shown to the user in the Feedback UI widget"
            isDisabled={!enabled}
            label="Prompt question"
            name="data.question"
            placeholder="How do you like this feature?"
          />
          <EventSelector />
          <FormNumberInput
            description="The minimum amount of user interactions with the feature before the feedback prompt can be shown."
            isDisabled={!enabled}
            label="Min. interactions before triggering"
            min={1}
            name="data.minimumInteractions"
            unit={(v) => (v === 1 ? "event" : "events")}
            w={144}
          />
          <FormNumberInput
            isDisabled={!enabled}
            label="Min. time after interactions"
            min={1}
            name="data.promptMinSecondsAfterUse"
            unit={(v) => (v === 1 ? "second" : "seconds")}
            w={144}
            onChange={() => {
              form.trigger("data.promptMaxSecondsAfterUse");
            }}
          />
          <FormNumberInput
            description="The time window (min and max) when feedback prompt can be displayed to the user after successful interaction with the feature."
            isDisabled={!enabled}
            label="Max. time after interactions"
            min={1}
            name="data.promptMaxSecondsAfterUse"
            unit={(v) => (v === 1 ? "second" : "seconds")}
            w={144}
            onChange={() => {
              form.trigger("data.promptMinSecondsAfterUse");
            }}
          />
          <FormRootError />
          <FormSubmit>Save</FormSubmit>
        </VStack>
      </FormProvider>
    </form>
  );
}

function featureDefaultsForStarsConfig(feature: FeatureDetail) {
  return {
    adoptionEvaluationStrategy: feature.adoptionEvaluationStrategy,
    adoptionWindowSizeInWeeks:
      (feature.adoptionWindowSizeInDays ||
        DEFAULT_FEATURE_ADOPTION_SETTINGS.adoptionWindowSizeInDays) / 7,
    adoptionStrategyEventCountMinEventCount:
      feature.adoptionStrategyEventCountMinEventCount ||
      DEFAULT_FEATURE_ADOPTION_SETTINGS.adoptionStrategyEventCountMinEventCount,
    adoptionStrategyFrequencyMinDaysCount:
      feature.adoptionStrategyFrequencyMinDaysCount ||
      DEFAULT_FEATURE_ADOPTION_SETTINGS.adoptionStrategyFrequencyMinDaysCount,
  };
}

const StarsConfigurationFormSchema = EventPatchFeatureArgsSchema.extend({
  adoptionWindowSizeInWeeks: z.number().min(1),
});

function StarsConfiguration() {
  const { appId, envId } = useCurrentEnv();
  const { featureId } = useParams();

  const queryClient = useQueryClient();
  const { feature } = useFeatureContext();
  const toast = useToast();

  const { form, handleSubmit } = useApiForm(
    async (formValues) => {
      return api
        .patch<"/apps/:appId/features/:featureId">(
          `/apps/${appId}/features/${featureId}`,
          {
            ...formValues,
            adoptionWindowSizeInDays: formValues.adoptionWindowSizeInWeeks * 7,
          },
          { params: { envId: envId! } },
        )
        .then((res) => res.data.feature);
    },
    StarsConfigurationFormSchema,
    {
      onSuccess: (feature) => {
        segmentAnalytics.track("Feature Options Updated", {
          section: "STARS Configuration",
        });
        toast({
          title: "Settings saved",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        queryClient.setQueryData(
          featureQueryKeys.single(appId, featureId),
          feature,
        );
        form.reset(featureDefaultsForStarsConfig(feature));
      },
    },
    {
      defaultValues: featureDefaultsForStarsConfig(feature!),
    },
  );

  return (
    <SettingSection
      description="Define how adoption and retention for the feature is measured."
      title="STARS configuration"
    >
      <form onSubmit={handleSubmit}>
        <VStack align="flex-start" maxW="xl" spacing={4}>
          <FormProvider {...form}>
            <FormRadioGroup
              itemDescriptionLocation="side"
              label="Adoption strategy"
              name="adoptionEvaluationStrategy"
              options={[
                {
                  label: "Frequency",
                  value: "frequency",
                  description: "Events on multiple days",
                },
                {
                  label: "Count",
                  value: "eventCount",
                  description: "Event count threshold",
                },
              ]}
            />
            <FormRootError />
            <StepsTable />
            <FormSubmit>Save</FormSubmit>
          </FormProvider>
        </VStack>
      </form>
    </SettingSection>
  );
}

function StepsTable() {
  const { watch } = useFormContext();

  const strategy = watch("adoptionEvaluationStrategy");
  const adoptionStrategyEventCountMinEventCount = watch(
    "adoptionStrategyEventCountMinEventCount",
  );
  const adoptionWindowSizeInWeeks = watch("adoptionWindowSizeInWeeks");
  const adoptionStrategyFrequencyMinDaysCount = watch(
    "adoptionStrategyFrequencyMinDaysCount",
  );

  return (
    <>
      <Table
        sx={{
          border: "1px solid",
          borderColor: "appBorder",
          borderRadius: "5px",
          "td:first-of-type": {
            fontWeight: "semibold",
          },
          "tr:last-child": {
            td: {
              borderBottom: "none",
            },
          },
        }}
      >
        <Tbody>
          {strategy === "eventCount" && (
            <>
              <Tr>
                <Td>Tried</Td>
                <Td>
                  <Text color="dimmed">Sent at least one event</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>Adopted</Td>
                <Td>
                  <HStack>
                    <Text color="dimmed">Sent at least</Text>
                    <FormNumberInput
                      min={1}
                      name="adoptionStrategyEventCountMinEventCount"
                      size="sm"
                      unit="events"
                      w={105}
                    />
                    <Text color="dimmed">within</Text>
                    <FormNumberInput
                      min={1}
                      name="adoptionWindowSizeInWeeks"
                      size="sm"
                      unit="weeks"
                      w={105}
                    />
                  </HStack>
                </Td>
              </Tr>
              <Tr>
                <Td>Retained</Td>
                <Td>
                  <Text color="dimmed">
                    Sent at least {adoptionStrategyEventCountMinEventCount}{" "}
                    events within the last {adoptionWindowSizeInWeeks} weeks
                  </Text>
                </Td>
              </Tr>
            </>
          )}
          {strategy === "frequency" && (
            <>
              <Tr>
                <Td>Tried</Td>
                <Td>
                  <Text color="dimmed">Sent at least one event</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>Adopted</Td>
                <Td>
                  <HStack>
                    <Text color="dimmed">Sent an event on at least </Text>
                    <FormNumberInput
                      _control={{ w: 100 }}
                      min={1}
                      name="adoptionStrategyFrequencyMinDaysCount"
                      size="sm"
                      unit="days"
                    />
                    <Text color="dimmed">within</Text>
                    <FormNumberInput
                      _control={{ w: 110 }}
                      isDisabled={false}
                      min={1}
                      name="adoptionWindowSizeInWeeks"
                      size="sm"
                      unit="weeks"
                    />
                  </HStack>
                </Td>
              </Tr>
              <Tr>
                <Td>Retained</Td>
                <Td>
                  <Text color="dimmed">
                    Sent an event on at least{" "}
                    {adoptionStrategyFrequencyMinDaysCount} days within the last{" "}
                    {adoptionWindowSizeInWeeks} weeks
                  </Text>
                </Td>
              </Tr>
            </>
          )}
        </Tbody>
      </Table>
    </>
  );
}

export default function FeatureSettings() {
  const { isLoading, feature } = useFeatureContext();

  useEffect(() => {
    segmentAnalytics.page("Feature Settings");
  }, []);

  const showStarsConfiguration = feature && feature.source === "event";

  return isLoading ? (
    <Center height="100%">
      <Spinner size="sm" />
    </Center>
  ) : (
    <Box p={6}>
      <General />
      <Divider my={8} />
      <TrackingCriteria />
      {showStarsConfiguration && (
        <>
          <Divider my={8} />
          <StarsConfiguration />
        </>
      )}
      <Divider my={8} />
      {feature && (
        <>
          <FeedbackPrompting feature={feature} />
          <Divider my={8} />
        </>
      )}
      <Delete />
      <Outlet />
    </Box>
  );
}

type PromptFormType = z.input<typeof SendPromptArgsSchema>;

export function PromptModal() {
  const { featureId } = useParams();
  const { envId, appId } = useCurrentEnv();
  const toast = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { form, handleSubmit } = useApiForm<PromptFormType, SendPromptDTO>(
    async (data) => {
      const res =
        await api.post<"/apps/:appId/features/:featureId/feedback/prompt">(
          `/apps/${appId}/features/${featureId}/feedback/prompt`,
          data,
          { params: { envId: envId! } },
        );
      return res.data;
    },
    SendPromptArgsSchema,
    {
      onSuccess: () => {
        segmentAnalytics.track("Test Prompt Sent", {
          section: "FeedbackPrompting",
        });
        toast({
          title: "Test prompt sent",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      },
    },
    {
      mode: "onChange",
      reValidateMode: "onChange",
    },
  );

  const userId = form.watch("userId");
  const userStatus = useUserFeedbackPromptingStatus(userId);

  return (
    <Modal
      size="md"
      isOpen
      onClose={() =>
        navigate({
          pathname: "..",
          search: searchParams.toString(),
        })
      }
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="lg">Test automated feedback surveys in-app</Heading>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <FormProvider {...form}>
              <VStack align="flex-start" maxW="compactForm" spacing={4}>
                <Box color="gray.500">
                  Here you can test the automated feedback surveys directly in
                  your app by triggering a prompt for a given user.
                </Box>

                <ManagedFormControl
                  label="User"
                  name="userId"
                  render={({ field }) => (
                    <UserAutocompleteSelect
                      placeholder="Select a user"
                      {...field}
                      showClearButton={false}
                    />
                  )}
                />

                <UserStatus userStatus={userStatus.data!} />

                <FormRootError />
                <FormSubmit>Trigger prompt</FormSubmit>
              </VStack>
            </FormProvider>
          </form>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <ModalCancelButton />
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function UserStatus({ userStatus }: { userStatus: string }) {
  if (userStatus != "online" && userStatus != "offline") {
    return null;
  }

  const color = userStatus === "online" ? "green.500" : "red.500";

  return (
    <Box color="gray.500">
      User is currently
      <Box color={color} display="inline">
        &nbsp;&#9679;&nbsp;{userStatus}
      </Box>
    </Box>
  );
}
