import startCase from "lodash/startCase";

import { frequencyNumberToText } from "@bucketco/shared/featureAPI";
import { GoalFeatureMetric } from "@bucketco/shared/goalAPI";

import {
  formatNumber,
  formatPercentage,
} from "@/common/utils/numberFormatting";

export const formatGoalValue = (
  value: number,
  isPercentage?: boolean,
  metric?: GoalFeatureMetric,
) => {
  if (metric === "averageFrequency") {
    return startCase(frequencyNumberToText(value));
  }

  return isPercentage
    ? formatPercentage(value)
    : formatNumber(Math.round(value));
};
