import { Box, BoxProps, Text, TextProps } from "@chakra-ui/react";

import { ReleaseStatus } from "@bucketco/shared/releaseAPI";

import { useReleaseStatusColors } from "@/release/hooks/useReleaseStatusColors";

export function ReleaseStatusIcon({
  status,
  ...rest
}: { status: ReleaseStatus } & Omit<BoxProps, "bg">) {
  const colors = useReleaseStatusColors();
  return (
    <Box bg={colors[status].bg} borderRadius="full">
      <Box
        bg={colors[status].fg}
        borderRadius="full"
        h="5px"
        m="5px"
        w="5px"
        {...rest}
      ></Box>
    </Box>
  );
}

export function ReleaseStatusText({
  status,
  ...rest
}: { status: ReleaseStatus } & TextProps) {
  const colors = useReleaseStatusColors();

  const label = {
    planned: "Planned",
    evaluating: "Evaluating",
    concluded: "Evaluated",
  } as const;

  return (
    <Text color={colors[status].fg} fontSize="md" {...rest}>
      {label[status]}
    </Text>
  );
}
