import { createContext, ReactNode, useContext } from "react";
import { useParams } from "react-router-dom";

import { FeatureDetail as FeatureAPIType } from "@bucketco/shared/featureAPI";

import useFeatureData from "@/feature-legacy/data/useFeatureData";
import {
  RelevantMetrics,
  relevantMetricsForFeature,
} from "@/feature-legacy/utils/relevantMetricsForFeature";

export const FeatureContext = createContext<FeatureContextType>(null!);

export interface FeatureContextType {
  feature?: FeatureAPIType;
  relevantMetrics: RelevantMetrics[];
  isLoading: boolean;
  isError: boolean;
}

export function FeatureProvider({ children }: { children: ReactNode }) {
  const { featureId } = useParams();
  const { data: feature, isLoading, isError } = useFeatureData(featureId);

  const relevantMetrics = relevantMetricsForFeature(feature);

  return (
    <FeatureContext.Provider
      value={{
        feature,
        relevantMetrics,
        isLoading,
        isError,
      }}
    >
      {children}
    </FeatureContext.Provider>
  );
}

export function useFeatureContext() {
  return useContext<FeatureContextType>(FeatureContext);
}
