import { forwardRef } from "react";

import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import {
  FormElementBaseProps,
  useManagedFormControlProps,
} from "@/common/hooks/useManagedFormControlProps";
import { mergeFunctions } from "@/common/utils/mergeFunctions";
import { mergeRefs } from "@/common/utils/mergeRefs";
import FeatureViewsPicker, {
  FeatureViewsPickerProps,
} from "@/feature-legacy/components/FeatureViewsPicker";

export type FormFeatureViewsPickerProps = FormElementBaseProps &
  Omit<FeatureViewsPickerProps, "value">;

const FormFeatureViewsPicker = forwardRef<
  HTMLInputElement,
  FormFeatureViewsPickerProps
>(({ _control, onChange, onBlur, ...rest }, ref) => {
  const [managedProps, inputProps] = useManagedFormControlProps(rest);
  return (
    <ManagedFormControl
      {..._control}
      {...managedProps}
      render={({ field }) => (
        <FeatureViewsPicker
          {...inputProps}
          {...field}
          ref={mergeRefs(field.ref, ref)}
          onBlur={mergeFunctions(field.onBlur, onBlur)}
          onChange={mergeFunctions(field.onChange, onChange)}
        />
      )}
    />
  );
});
FormFeatureViewsPicker.displayName = "FormFeatureViewsPicker";

export default FormFeatureViewsPicker;
