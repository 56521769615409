import { useCallback } from "react";
import { FormControl, FormLabel, useColorModeValue } from "@chakra-ui/react";

import SubsegmentsIcon from "@/common/assets/subsegments.svg?react";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import FormSegmentPicker from "@/feature-legacy/components/SegmentPicker";

type Props = {
  label?: string;
  placeholder?: string;
  menuDescription?: string;
  multiselect?: boolean;
  allCompaniesToggle?: boolean;
  targetSegmentId?: string;
  showPieChart?: boolean;
};

export function SubsegmentPicker({
  label,
  placeholder = "Apply subsegment",
  menuDescription = "Only include companies that belong to both the target segment and the following subsegment:",
  multiselect = false,
  allCompaniesToggle = false,
  targetSegmentId,
  showPieChart = false,
}: Props) {
  const [segments, setSegments] = useSubsegmentParam();

  const onChangeCallback = useCallback(
    (newSegments: string | string[] | null) => {
      const newSegmentsArray = Array.isArray(newSegments)
        ? newSegments
        : newSegments
        ? [newSegments]
        : [];
      setSegments(newSegmentsArray);
      segmentAnalytics.track("Subsegment Applied", {
        multiple: newSegmentsArray.length > 1,
        count: newSegmentsArray.length,
      });
    },
    [setSegments],
  );

  const segmentActiveColor = useColorModeValue("teal.500", "teal.300");
  return (
    <FormControl alignItems="center" display="flex" width="auto">
      {!!label && (
        <FormLabel color="dimmed" pb={0}>
          {label}
        </FormLabel>
      )}
      <FormSegmentPicker
        allCompaniesToggle={allCompaniesToggle}
        Icon={SubsegmentsIcon}
        id="subSegmentId"
        includeAll={false}
        maxW={600}
        menuDescription={menuDescription}
        multiselect={multiselect}
        placeholder={placeholder}
        selectedColor={segmentActiveColor}
        showPieChart={showPieChart}
        targetSegmentId={targetSegmentId}
        value={segments}
        showClearButton
        onChange={onChangeCallback}
      />
    </FormControl>
  );
}
