import { useMemo } from "react";
import { Link, Outlet, To } from "react-router-dom";
import { Button, Divider, Flex, HStack, Text, VStack } from "@chakra-ui/react";

import { FunnelStep, FunnelStepList } from "@bucketco/shared/featureAPI";
import { FeedbackDTO } from "@bucketco/shared/feedbackAPI";
import { SatisfactionSpan } from "@bucketco/shared/schemas/satisfactionScore";

import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import EmptyState from "@/common/components/EmptyState";
import TablePagination from "@/common/components/TablePagination";
import { useLocalStorageTableConfiguration } from "@/common/hooks/useLocalStorageTableConfiguration";
import {
  useSearchArrayParam,
  useSearchParam,
} from "@/common/hooks/useSearchParam";
import { useCompanyContext } from "@/company/contexts/CompanyContext";
import SatisfactionFilter from "@/feature-legacy/components/SatisfactionFilter";
import { StarsFunnelFilter } from "@/feature-legacy/components/StarsFunnelFilter";
import FeedbackEmptyStateIllustration from "@/feedback/components/FeedbackEmptyStateIllustration";
import FeedbackTable from "@/feedback/components/FeedbackTable";

export default function CompanyFeedback() {
  const { feedbackDataTable: table, clearFilters } = useCompanyContext();

  const [featureFilter] = useSearchParam("featureFilter");
  const [satisfaction, setSatisfaction] =
    useSearchParam<SatisfactionSpan>("satisfaction");

  const defaultSteps = useMemo(() => FunnelStepList.map((step) => step), []);
  const [steps, setSteps] = useSearchArrayParam<FunnelStep>("funnelStep", {
    fallback: defaultSteps,
  });

  const tableConfiguration = useLocalStorageTableConfiguration(
    "CompanyFeedback",
    {
      defaultColumns: [],
      defaultSort: {
        id: "timestamp",
        desc: true,
      },
    },
  );

  if (table === null) return null;

  const newSearchParams = new URLSearchParams(window.location.search);
  if (featureFilter) {
    newSearchParams.append("featureId", featureFilter);
  }

  return (
    <Flex direction="column" flexGrow={1} width="100%">
      <Flex justify={"space-between"} px={6} py={3}>
        <HStack spacing={4}>
          <Button
            as={Link}
            size="sm"
            to={{
              pathname: "new",
              search: newSearchParams.toString(),
            }}
            variant="primary"
          >
            Add feedback
          </Button>
          <StarsFunnelFilter
            value={steps}
            onChange={(newSteps) => {
              setSteps(newSteps);
            }}
          />
          <SatisfactionFilter
            size="sm"
            value={satisfaction}
            onChange={setSatisfaction}
          />
          <AnimatedSpinner show={table.isLoading} size="sm" />
        </HStack>
        <TablePagination
          canPaginate={table.canPaginate}
          label="Entries"
          pageCount={table.pageCount}
          pageIndex={table.data?.pageIndex}
          pageSize={table.data?.pageSize}
          paginateActions={table.paginateActions}
          totalCount={table.data?.totalCount}
        />
      </Flex>
      <Divider />
      {table.isLoading ? null : table.data?.data.length === 0 ? (
        <EmptyState
          description={
            <VStack>
              <Text>No feedback entries to show</Text>
              {!table.isLoading && (satisfaction || featureFilter) && (
                <Button
                  color="gray.500"
                  fontSize="sm"
                  variant="link"
                  onClick={() => {
                    clearFilters();
                  }}
                >
                  Clear filters
                </Button>
              )}
            </VStack>
          }
          flexGrow={1}
          pt="24"
        >
          <FeedbackEmptyStateIllustration />
        </EmptyState>
      ) : (
        <FeedbackTable
          columnOrder={[
            "score",
            "comment",
            "featureName",
            "userName",
            "companyFunnelStep",
            "source",
            "timestamp",
            "actions",
          ]}
          columnStates={tableConfiguration.columns}
          createEditURL={createEditURL}
          data={table.data?.data ?? []}
          fetchData={table.fetchData}
          isFetching={table.isFetching}
          pageCount={table.pageCount}
          setCanPaginate={table.setCanPaginate}
          setColumnStates={tableConfiguration.setColumns}
          setPaginateActions={table.setPaginateActions}
          setSortBy={tableConfiguration.setSort}
          sortBy={tableConfiguration.sort}
          viewName="CompanyFeedback"
        />
      )}

      <Outlet />
    </Flex>
  );
}

function createEditURL(feedback: FeedbackDTO): To {
  const search = new URLSearchParams(window.location.search);
  search.append("featureId", feedback.featureId);

  return {
    pathname: `edit/${feedback.id}`,
    search: search.toString(),
  };
}
