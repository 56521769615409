import { useNavigate } from "react-router-dom";
import { Avatar, ButtonProps, Flex, Text } from "@chakra-ui/react";

import { useOrgsData } from "@/app/data/useOrgsData";
import AutocompleteSelect, {
  getStringFilterFunction,
  highlightStringMatch,
} from "@/common/components/AutocompleteSelect";
import pluralize from "@/common/utils/pluralize";

type AdminOrgSwitcherProps = { currentOrgId?: string } & Pick<
  ButtonProps,
  "size" | "isDisabled" | "id"
>;

export default function AdminOrgSwitcher({
  size,
  currentOrgId,
}: AdminOrgSwitcherProps) {
  const navigate = useNavigate();
  const { data: orgs = [] } = useOrgsData();

  return (
    <AutocompleteSelect
      itemFilterFn={getStringFilterFunction("name")}
      itemKeyProperty="id"
      itemRenderfn={(org, search) => (
        <Flex alignItems="center" fontSize={size} gap={2} padding={1}>
          <Avatar
            key={`${org.id}-${org.logoUrl}`}
            name={org.name}
            size="xs"
            src={org.logoUrl ?? undefined}
          />
          <Text>{highlightStringMatch(org.name, search ?? "")}</Text>
          <Text color="dimmed" marginInlineStart="auto">
            {org.apps.length} {pluralize("app", org.apps.length, "apps")}
          </Text>
        </Flex>
      )}
      placeholder="Select organization"
      size={size}
      suggestions={orgs}
      value={orgs.find((o) => o.id === currentOrgId)}
      onChange={(org) => navigate(`/admin/${org.id}`)}
    />
  );
}
