import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";

import EmptyState from "@/common/components/EmptyState";
import FlagsEmptyStateIllustration from "@/feature-legacy/components/FlagsEmptyStateIllustration";

export default function FlagsEmptyState({ isLoading }: { isLoading: boolean }) {
  return (
    <EmptyState
      action={<NewFlagButton />}
      description="Feature flags enable teams to gradually roll out feature releases, providing a safe way to ship product updates with confidence."
      flexGrow={1}
      isLoading={isLoading}
      title="Create first feature flag"
    >
      <FlagsEmptyStateIllustration />
    </EmptyState>
  );
}

function NewFlagButton() {
  return (
    <Button as={Link} size="sm" to="new" variant="outline">
      Create feature flag
    </Button>
  );
}
