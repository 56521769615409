import { useMemo } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  MenuProps,
  Text,
} from "@chakra-ui/react";

import { FunnelStep } from "@bucketco/shared/featureAPI";

import MenuDescription from "@/common/components/MenuDescription";
import {
  starsFunnelStateDescriptionsSegmentRemoved,
  starsFunnelStepDescriptionsSegmentRemoved,
} from "../data/starsFunnelDescriptions";

type StarsFunnelFilterProps = Omit<MenuProps, "children"> & {
  value: FunnelStep[];
  disabledSteps?: FunnelStep[];
  useFunnelStates?: boolean;
  onChange: (steps: FunnelStep[]) => void;
};

export function StarsFunnelFilter({
  value,
  disabledSteps = [],
  useFunnelStates = true,
  onChange,
  ...rest
}: StarsFunnelFilterProps) {
  const enabledValues = useMemo(() => {
    return value.filter((id) => !disabledSteps.includes(id));
  }, [value, disabledSteps]);

  const enabledStarsFunnelDescriptions = useMemo(() => {
    const descriptions = useFunnelStates
      ? starsFunnelStateDescriptionsSegmentRemoved
      : starsFunnelStepDescriptionsSegmentRemoved;
    return descriptions.filter(({ id }) => !disabledSteps.includes(id));
  }, [disabledSteps, useFunnelStates]);

  const subject = useFunnelStates ? "state" : "step";

  return (
    <Menu closeOnSelect={false} {...rest}>
      <MenuButton
        as={Button}
        color={
          enabledValues.length === enabledStarsFunnelDescriptions.length
            ? "gray.500"
            : undefined
        }
        rightIcon={
          <Box fontSize="xl" mr={-2}>
            <RiArrowDownSLine />
          </Box>
        }
        size="sm"
        variant="input"
      >
        <HStack spacing={2}>
          {enabledValues.length === enabledStarsFunnelDescriptions.length ? (
            <Text>Any STARS {subject}</Text>
          ) : enabledValues.length ? (
            enabledStarsFunnelDescriptions
              .filter(({ id }) => enabledValues.includes(id))
              .map(({ id, label, visualization }, i, selected) => (
                <HStack key={id} spacing={1}>
                  {visualization}
                  <Text key={label} as="span">
                    {label}
                    {i !== selected.length - 1 && ","}
                  </Text>
                </HStack>
              ))
          ) : (
            <Text>No {subject} selected</Text>
          )}
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuDescription>
          Filter companies by their current {subject} in the STARS funnel:
        </MenuDescription>
        <MenuDivider my={0} />
        <MenuOptionGroup
          type="checkbox"
          value={enabledValues}
          onChange={(newValue) => {
            if (newValue.length) onChange(newValue as FunnelStep[]);
          }}
        >
          {enabledStarsFunnelDescriptions.map(
            ({ id, label, description, visualization }) => (
              <MenuItemOption
                key={id}
                isDisabled={disabledSteps.includes(id)}
                value={id}
              >
                <HStack spacing={3}>
                  {visualization}
                  <Text whiteSpace="nowrap" width={14}>
                    {label}
                  </Text>
                  <Text variant="dimmed" whiteSpace="nowrap">
                    {description}
                  </Text>
                </HStack>
              </MenuItemOption>
            ),
          )}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
