import { useMemo, useRef } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import {
  CartesianGrid,
  Cell,
  LabelList,
  ReferenceLine,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";

import { UseAuditChartResult } from "@/common/charts/components/AuditChart/hooks/useAuditChart";
import { useAuditChartAxis } from "@/common/charts/components/AuditChart/hooks/useAuditChartAxis";
import {
  AuditChartDataItem,
  AuditChartOptions,
} from "@/common/charts/components/AuditChart/types";
import useChartTokens from "@/common/charts/hooks/useChartTokens";
import useElementSize from "@/common/hooks/useElementSize";

import { CellLabel } from "./CellLabel";
import { Dot } from "./Dot";
import { FeaturePopover } from "./FeaturePopover";

type AuditChartProps = BoxProps & UseAuditChartResult;

export default function AuditChart({
  data,
  isFeaturesLoading,
  isHistoricalLoading,
  activeFeature,
  setActiveFeature,
  options,
  ...rest
}: AuditChartProps) {
  const { colors } = useChartTokens();

  const features = useMemo<AuditChartDataItem[]>(
    () =>
      data.flatMap((list) =>
        list.items.flatMap((item, index) =>
          item.isVisible
            ? {
                ...item,
                id: `${item.id}-${list.subsegment?.id ?? "all"}`,
                dotLabel: `${index + 1}`,
              }
            : [],
        ),
      ),
    [data],
  );

  const xAxis = useAuditChartAxis(options.xAxisMetric, "x");
  const yAxis = useAuditChartAxis(options.yAxisMetric, "y");

  const ref = useRef<HTMLDivElement>(null);
  const size = useElementSize(ref);
  const scatterRef = useRef<any>(null);

  return (
    <Box
      ref={ref}
      sx={{
        ".recharts-cartesian-axis .recharts-label": {
          fill: colors.axisLabel,
          fontWeight: "semibold",
        },
        ".recharts-label-list .recharts-label": { fill: colors.primary },
      }}
      w="100%"
      {...rest}
    >
      {data.map((list) => (
        <FeaturePopover
          key={list.subsegment?.id || "all"}
          activeFeature={activeFeature}
          features={features}
          options={options}
          parentRef={ref}
          segmentId={list.subsegment?.id ?? "all"}
          setActiveFeature={setActiveFeature}
        />
      ))}
      <div>
        <ScatterChart
          ref={scatterRef}
          height={size?.height}
          margin={{
            top: 35,
            right: 35,
            bottom: 55,
            left: 55,
          }}
          width={size?.width}
        >
          <CartesianGrid horizontal={false} vertical={false} />
          <XAxis {...xAxis} />
          <YAxis {...yAxis} yAxisId="left" />
          <ZAxis range={[180]} />

          {xAxis.referenceLines.map((tick, idx) => (
            <ReferenceLine
              key={`xaxis-ref-${idx}`}
              stroke={colors.grid}
              strokeDasharray="4"
              x={tick}
              yAxisId="left"
            />
          ))}
          {yAxis.referenceLines.map((tick, idx) => (
            <ReferenceLine
              key={`yaxis-ref-${idx}`}
              stroke={colors.grid}
              strokeDasharray="4"
              y={tick}
              yAxisId="left"
            />
          ))}

          <defs>
            {/* Use a 0%->100% gradient when positive, as it'll be pointing from the bottom left to the dot */}
            <linearGradient id={`gradient-positive`}>
              <stop offset="0%" stopColor={colors.primary} stopOpacity={0} />
              <stop
                offset="100%"
                stopColor={colors.primary}
                stopOpacity={1.0}
              />
            </linearGradient>
            {/* Use a 100%->0% gradient when negative, as it'll be pointing from the dot to the top right */}
            <linearGradient id={`gradient-negative`}>
              <stop offset="0%" stopColor={colors.primary} stopOpacity={1.0} />
              <stop offset="100%" stopColor={colors.primary} stopOpacity={0} />
            </linearGradient>
          </defs>

          {renderTrendlines({
            data: features,
            options,
            isLoading: isFeaturesLoading || isHistoricalLoading,
          })}

          {!isFeaturesLoading && (
            <Scatter
              data={features}
              isAnimationActive={false}
              shape={
                <Dot
                  data={features}
                  onMouseEnter={(item) => setActiveFeature(item)}
                />
              }
              yAxisId="left"
            >
              {features.map((feature, index) => {
                return (
                  <Cell
                    key={`cell-${index}`}
                    onMouseEnter={() => {
                      setActiveFeature(feature);
                    }}
                  />
                );
              })}
              {options.showLabels && (
                <LabelList
                  content={<CellLabel data={features} />}
                  dataKey="name"
                  position="top"
                />
              )}
            </Scatter>
          )}
        </ScatterChart>
      </div>
    </Box>
  );
}

function renderTrendlines({
  isLoading,
  options,
  data,
}: {
  options: AuditChartOptions;
  data: AuditChartDataItem[];
  isLoading: boolean;
}) {
  if (isLoading) return null;
  if (!options.showTrendlines) return null;

  return data.map((item, index) => {
    const x1 = item.current[options.xAxisMetric];
    const y1 = item.current[options.yAxisMetric];
    let x2 = item.historical[options.xAxisMetric] || 0;
    let y2 = item.historical[options.yAxisMetric] || 0;

    if (x1 === null || y1 === null) return null;

    // Fix trendlines disappearing when perfectly horizontal
    // or vertical. See https://github.com/recharts/recharts/issues/1234
    // and related StackOverflow issues
    if (x1 === x2) {
      x2 *= 1.001;
    } else if (y1 === y2) {
      y2 *= 1.001;
    }

    const isTrendPositive = x1 > x2;
    const gradient = isTrendPositive
      ? `gradient-positive`
      : `gradient-negative`;

    return (
      <ReferenceLine
        key={`trendline-${index}`}
        segment={[
          { x: x2, y: y2 },
          { x: x1, y: y1 },
        ]}
        stroke={`url(#${gradient})`}
        yAxisId="left"
      />
    );
  });
}
