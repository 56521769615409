import partition from "lodash/partition";

import { useAuthContext } from "@/auth/contexts/authContext";
import { useEnvironmentsData } from "@/environment/data/useEnvironmentsData";

/**
 * A convenience hook that fetches the environments for the current app and splits them into production and non-production.
 * Note: this hook "guarantees" there is at least one production environment if successful.
 */
export function useEnvironments() {
  const { currentApp } = useAuthContext();
  return useEnvironmentsData(currentApp?.id, undefined, {
    select: (environments) => {
      const [production, nonProduction] = partition(
        environments,
        "isProduction",
      );
      return {
        production: production[0]!,
        nonProduction,
      };
    },
  });
}
