import { useFormContext } from "react-hook-form";
import { MdPlaylistAdd, MdPlaylistAddCheck } from "react-icons/md";
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";

import AutocompleteInput from "@/common/components/AutocompleteInput";
import { useEventNames } from "../../common/data/useEventNames";

type FormEventAutoSuggestInputProps = {
  name: string;
  label?: string;
} & InputProps;

export default function FormEventAutoCompleteInput({
  name,
  label,
  size = "md",
  ...inputProps
}: FormEventAutoSuggestInputProps) {
  const form = useFormContext();
  const fieldState = form.getFieldState(name);

  return (
    <FormControl
      id={name}
      isDisabled={form.formState.isSubmitting}
      isInvalid={Boolean(fieldState.error)}
      maxW="compactForm"
    >
      {label && <FormLabel fontSize={size}>{label}</FormLabel>}
      <FormEventAutoCompleteInputOnly name={name} {...inputProps} />
    </FormControl>
  );
}

export function FormEventAutoCompleteInputOnly({
  name,
  ...inputProps
}: Omit<FormEventAutoSuggestInputProps, "label">) {
  const form = useFormContext();

  const { data: events = [] } = useEventNames();

  const currentValue = form.watch(name) ?? "";

  const isTracked = events.includes(currentValue);

  return (
    <AutocompleteInput
      maxSuggestions={50}
      search={currentValue}
      suggestions={events.map((e) => ({ key: e, label: e }))}
      onValuePick={(pick) =>
        form.setValue(name, pick.key, {
          shouldDirty: true,
          shouldValidate: true,
        })
      }
    >
      <InputGroup>
        <Input
          autoComplete="off"
          background="appBackground"
          type="search"
          {...inputProps}
          {...form.register(name)}
        />
        <InputRightElement>
          {currentValue.trim() ? (
            isTracked ? (
              <MdPlaylistAddCheck opacity={0.3} size={24} />
            ) : (
              <MdPlaylistAdd opacity={0.3} size={24} />
            )
          ) : null}
        </InputRightElement>
      </InputGroup>
    </AutocompleteInput>
  );
}
