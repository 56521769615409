import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import {
  FeatureDetail,
  PatchFeatureArgsType,
} from "@bucketco/shared/featureAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import companyQueryKeys from "@/company/data/companyQueryKeys";

import featureQueryKeys from "./featureQueryKeys";

type PatchFeatureArgsTypeWithId = PatchFeatureArgsType & {
  id: string;
};

export function useFeatureUpdateMutation(
  companyId: string,
  options: Omit<
    UseMutationOptions<
      FeatureDetail,
      AxiosError<ErrorResponse>,
      PatchFeatureArgsTypeWithId
    >,
    "mutationKey" | "mutationFn"
  > = {},
) {
  const { appId, envId } = useCurrentEnv();

  const queryClient = useQueryClient();
  const { onSuccess, ...otherOptions } = options;

  return useMutation<
    FeatureDetail,
    AxiosError<ErrorResponse>,
    PatchFeatureArgsTypeWithId
  >({
    mutationKey: ["feature"],

    mutationFn: async (data) => {
      const { id: featureId, ...updatedData } = data;
      return api
        .patch<"/apps/:appId/features/:featureId">(
          `/apps/${appId}/features/${featureId}`,
          updatedData,
          { params: { envId: envId! } },
        )
        .then((res) => res.data.feature);
    },

    onSuccess: (updatedFeature, data, context) => {
      queryClient.invalidateQueries({
        queryKey: featureQueryKeys.listNames(appId),
      });
      queryClient.invalidateQueries({ queryKey: featureQueryKeys.list(appId) });
      queryClient.invalidateQueries({
        queryKey: featureQueryKeys.single(appId, updatedFeature.id),
      });

      queryClient.invalidateQueries({
        queryKey: companyQueryKeys.singleFeatures(appId, companyId),
      });
      queryClient.setQueryData(
        featureQueryKeys.single(appId, updatedFeature.id),
        updatedFeature,
      );

      onSuccess?.(updatedFeature, data, context);
    },

    ...otherOptions,
  });
}
