import { Flex, Spinner, TagLabel, Text } from "@chakra-ui/react";

import {
  operatorDisplayName,
  relevantOperators,
} from "@bucketco/shared/attributeFilter";
import { FeatureTargetingFilter } from "@bucketco/shared/filter";

import { FeatureAutocompleteSelect } from "@/common/components/FeatureAutocompleteSelect";
import { TextWordBreak } from "@/common/components/TextWordBreak";
import { FeatureDisplay } from "@/feature/components/FeatureDisplay";
import useFeatureNamesData from "@/feature-legacy/data/useFeatureNamesData";
import { ManagedFormControl } from "../form/ManagedFormControl";
import RuleOperator from "../Rule/RuleOperator";

type FeatureTargetingFilterItemProps = {
  filter: FeatureTargetingFilter;
  isLoading?: boolean;
};

export function FeatureTargetingFilterItem({
  filter,
  isLoading,
}: FeatureTargetingFilterItemProps) {
  const { data: features } = useFeatureNamesData();

  const feature = features?.find(({ id }) => id === filter.featureId);
  return (
    <TagLabel whiteSpace="normal">
      {isLoading ? (
        <Flex align="center" color="dimmed" columnGap={2}>
          <Spinner size="xs" />
          <TextWordBreak as="span">Loading filter...</TextWordBreak>
        </Flex>
      ) : (
        <Flex align="center" columnGap={2} wrap="wrap">
          {feature && <FeatureDisplay feature={feature} size="sm" />}
          {feature && (
            <Text as="span" color="dimmed">
              {operatorDisplayName[filter.operator]}
            </Text>
          )}
        </Flex>
      )}
    </TagLabel>
  );
}

export function FeatureTargetingFilterFields() {
  const operators = relevantOperators(["featureTargeting"]);

  return (
    <>
      <ManagedFormControl
        name="featureId"
        render={({ field }) => (
          <FeatureAutocompleteSelect
            showClearButton={false}
            {...field}
            value={field.value}
            onChange={(nextFeature) => {
              if (nextFeature) {
                field.onChange(nextFeature.id);
              }
            }}
          />
        )}
      />
      <ManagedFormControl
        name="operator"
        render={({ field }) => (
          <RuleOperator {...field} operators={operators} />
        )}
      />
    </>
  );
}
