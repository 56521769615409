import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { systemAttributeFields } from "@bucketco/shared/attributeFilter";

import {
  AttributeFilters,
  defaultRuleTemplate,
} from "@/common/components/AttributeFilters";
import useCompanyAttributesData from "@/company/data/useCompanyAttributesData";

export function FormCompanyAttributeFilterPickerInput({
  name,
  buttonText,
}: {
  name: string;
  buttonText?: string;
}) {
  const form = useFormContext();
  const { data: attributes = [] } = useCompanyAttributesData();

  const attributesWithoutSystemAttributes = useMemo(() => {
    return attributes.filter((attr) =>
      systemAttributeFields.every((f) => f.key !== attr.key),
    );
  }, [attributes]);

  const currentUsingItAttributeFilter = form.watch(name);
  const hasCurrentRules = (currentUsingItAttributeFilter?.length ?? 0) > 0;

  // add a default rule if there are no rules and attributes exist
  useEffect(() => {
    if (!hasCurrentRules) {
      form.setValue(name, [defaultRuleTemplate("")], {
        shouldDirty: true,
      });
    }
  }, [name, form, hasCurrentRules]);

  return (
    <AttributeFilters
      attributes={attributesWithoutSystemAttributes}
      buttonText={buttonText}
      entityType="company"
      minFilterCount={1}
      name={name}
      size="sm"
      canAdd
    />
  );
}
