import { useMemo } from "react";
import { Text } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { XAxisProps } from "recharts";

import { FeatureMetricsHistoricalDataPoint } from "@bucketco/shared/featureAPI";
import { getFraction } from "@bucketco/shared/utils/getFraction";

import useChartTokens from "@/common/charts/hooks/useChartTokens";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useFirstChangeFromTo } from "@/common/hooks/useFirstChangeFromTo";
import {
  epochIsSame,
  epochToDate,
  epochToShortDate,
} from "@/common/utils/datetime";
import dayjs from "@/common/utils/dayjs";
import {
  formatLargeNumber,
  formatPercentage,
} from "@/common/utils/numberFormatting";
import featureQueryKeys from "@/feature-legacy/data/featureQueryKeys";
import {
  HistoricalDataParams,
  useFeatureHistoricalData,
} from "@/feature-legacy/data/useFeatureHistoricalData";
import useFeatureHasData from "@/feature-legacy/hooks/useFeatureHasData";
import { padTimeseries } from "@/feature-legacy/utils/padTimeseries";

type DataKey = {
  key: string;
  name: string;
  type: "bar" | "line";
  color: "tried" | "retained" | "problem";
  style?: "solid" | "dashed";
};

const transformNumberToExplicitSign = (num: number) =>
  num > 0 ? `+${formatLargeNumber(num)}` : formatLargeNumber(num);

export default function useEvaluationComboChartData(
  featureId: string | undefined,
  params: Omit<HistoricalDataParams, "envId">,
  options?: {
    chartWidth?: number;
    triedDisabled?: boolean;
    retentionRateDisabled?: boolean;
  },
) {
  const { sizes } = useChartTokens();
  const queryClient = useQueryClient();
  const { appId, envId } = useCurrentEnv();

  const {
    data,
    isLoading: historicalIsLoading,
    isError,
    error,
  } = useFeatureHistoricalData(featureId, params);

  const featureHasDataQuery = useFeatureHasData(featureId);

  useFirstChangeFromTo(
    featureHasDataQuery.data?.historicalDataProcessed,
    false,
    true,
    () => {
      queryClient.refetchQueries({
        queryKey: featureQueryKeys.singleMetricsHistorical(
          appId,
          envId,
          featureId,
          params,
        ),
      });
    },
  );

  const errors = error ? [error] : undefined;
  const isLoading =
    historicalIsLoading || !featureHasDataQuery.data?.historicalDataProcessed;

  const { timeseries, newChurnMax } = useMemo(() => {
    const exitRetainedFigures = (data?.timeseries || []).map((i) => {
      return i.exitRetained;
    });

    const newChurnMax = Math.max(20, ...exitRetainedFigures);

    // We need to pad the timeseries with empty items for the missing days
    // This is used for evaluation periods that have not yet completed
    const paddedTimeseries = padTimeseries(
      data?.timeseries || [],
      params.startDate,
      params.endDate,
      params.granularity,
    );

    return {
      timeseries: paddedTimeseries as FeatureMetricsHistoricalDataPoint[],
      newChurnMax,
    };
  }, [data?.timeseries, params.startDate, params.endDate, params.granularity]);

  const tickCount = options?.chartWidth
    ? Math.floor((options.chartWidth - sizes.yAxisWidth * 2) / 48) // 48px per tick
    : 0;
  const granularityDiff = dayjs(params.endDate).diff(
    params.startDate,
    params.granularity === "daily" ? "day" : "week",
  );
  const nowDiff = dayjs().diff(
    params.startDate,
    params.granularity === "daily" ? "day" : "week",
  );
  const shift = nowDiff % 2; // always show now tick

  const xAxis: XAxisProps = {
    tickFormatter: (epoch: number, index: number) => {
      if (
        (index + shift) % Math.ceil(getFraction(granularityDiff, tickCount)) !==
        0
      )
        return "";
      if (epochIsSame(epoch, params.granularity === "daily" ? "day" : "week"))
        return "Now";
      return epochToShortDate(epoch);
    },
    interval: 0,
    domain: [dayjs(params.startDate).unix(), dayjs(params.endDate).unix()],
  };

  const lineYAxis = {
    tickFormatter: (val: any) => (val < 0 ? "" : formatPercentage(val)),
    domain: [0, 1],
    ticks: [0, 0.25, 0.5, 0.75, 1],
  };

  // Multiply churn by 2 so that it only takes up half of the chart
  const deltaMax = newChurnMax * 2;

  const barYAxis = {
    tickFormatter: (val: number, index: number) => {
      // Hide the top 2 ticks in the axis
      if (index > 2) return "";
      return transformNumberToExplicitSign(val);
    },
    domain: [0, deltaMax],
    ticks: [0, deltaMax * 0.25, deltaMax * 0.5, deltaMax * 0.75, deltaMax],
  };

  const dataKeys: DataKey[] = [
    {
      key: "retained",
      name: "Retained",
      type: "line",
      color: "retained",
    },
  ];

  if (!options?.retentionRateDisabled) {
    dataKeys.splice(
      1,
      0,
      {
        key: "retentionRate",
        name: "Retention rate",
        type: "line",
        color: "retained",
        style: "dashed",
      },
      {
        key: "exitRetained",
        name: "Retained churn",
        type: "bar",
        color: "problem",
      },
    );
  }

  if (!options?.triedDisabled) {
    dataKeys.splice(0, 0, {
      key: "tried",
      name: "Tried",
      type: "line",
      color: "tried",
    });
  }

  const tooltip = {
    labelFormatter: (epoch: number) => {
      if (epochIsSame(epoch, params.granularity === "daily" ? "day" : "week")) {
        return (
          <>
            <Text as="span">{dayjs().format("MMM D, LT")}</Text>
            <br />
            <Text as="span" color="dimmed">
              (Partial {params.granularity === "daily" ? "day" : "week"})
            </Text>
          </>
        );
      }
      return epochToDate(epoch);
    },
    formatter: (
      value: number,
      name: string,
      props: { payload?: (typeof timeseries)[0] },
    ) => {
      if (name === "Tried") {
        const { total, triedCount } = props.payload ?? {};
        return `${formatPercentage(value)} (${formatLargeNumber(
          triedCount ?? 0,
        )}/${formatLargeNumber(total ?? 0)})`;
      }

      if (name === "Retained") {
        const { total, retainedCount } = props.payload ?? {};
        return `${formatPercentage(value)} (${formatLargeNumber(
          retainedCount ?? 0,
        )}/${formatLargeNumber(total ?? 0)})`;
      }

      if (name === "Retention rate") {
        return formatPercentage(value);
      }

      return [formatLargeNumber(Math.abs(value)), name];
    },
  };

  return {
    data: timeseries,
    isLoading,
    isError,
    errors,
    dataKeys,
    xAxis,
    lineYAxis,
    barYAxis,
    tooltip,
  };
}
