import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { RiExternalLinkLine, RiFlag2Line } from "react-icons/ri";
import { Link, Outlet } from "react-router-dom";
import { Badge, Box, Button, Spinner, Text } from "@chakra-ui/react";

import EmptyState from "@/common/components/EmptyState";
import HeaderLayout from "@/common/components/HeaderLayout";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { FlagsTable } from "@/flags/components/FlagsTable";

export default function Page() {
  useEffect(() => {
    segmentAnalytics.page("Feature Flags");
  }, []);

  const { isEnabled, isLoading } = useFeature("feature-revamp-2024");

  useEffect(() => {
    segmentAnalytics.page("Feature Flags");
  });

  if (isLoading) {
    return <Spinner color="dimmed" size="sm" />;
  }

  if (!isEnabled) {
    return (
      <EmptyState
        action={
          <Button
            as="a"
            display="inline-flex"
            href="https://docs.bucket.co/introduction/concepts/feature-flag"
            justifyContent="space-between"
            leftIcon={<RiFlag2Line size={16} />}
            px={2.5}
            rightIcon={
              <Box color="dimmed">
                <RiExternalLinkLine size={12} />
              </Box>
            }
            target="_blank"
            variant="outline"
          >
            <Text flexGrow={1}>Join private beta</Text>
          </Button>
        }
        description="Feature flags are coming to Bucket! These aren't your normal feature flags, they're feature flags for customer-centric rollouts."
        eyebrow={
          <Badge colorScheme="brand" size="md">
            Private beta
          </Badge>
        }
        flexGrow={1}
        title="Feature flags"
      />
    );
  }

  return (
    <HeaderLayout
      actions={
        <Button as={Link} colorScheme="brand" size="sm" to="new">
          New feature flag
        </Button>
      }
      title="Feature flags"
    >
      <Helmet>
        <title>Feature flags › All</title>
      </Helmet>
      <FlagsTable />
      <Outlet />
    </HeaderLayout>
  );
}
