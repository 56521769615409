import React, { ReactNode } from "react";
import { useColorModeValue } from "@chakra-ui/react";
import { QueryStatus } from "@tanstack/react-query";

import EmptyStateDark from "@/common/assets/empty-state-events-dark.svg?react";
import EmptyStateLight from "@/common/assets/empty-state-events-light.svg?react";
import EmptyState, { EmptyStateProps } from "@/common/components/EmptyState";
import { SectionHeading } from "@/common/components/SectionHeading";

type Props = Omit<EmptyStateProps, "isLoading"> & {
  status?: QueryStatus;
  children?: ReactNode;
  hasData?: boolean;
};

export const WidgetState = ({ status, hasData, children, ...rest }: Props) => {
  const EmptyStateIllustration = useColorModeValue(
    EmptyStateLight,
    EmptyStateDark,
  );

  if (status === "success" && hasData) {
    return <>{children}</>;
  }
  return (
    <EmptyState
      eyebrow={<EmptyStateIllustration />}
      h="full"
      isLoading={status === "pending"}
      title={
        <SectionHeading>
          {status === "error"
            ? "Could not load feature metric :("
            : "No feature metric data"}
        </SectionHeading>
      }
      w="full"
      {...rest}
    />
  );
};
