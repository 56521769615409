import { useState } from "react";
import {
  Button,
  ButtonGroup,
  Heading,
  Link,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";

import { SalesEmail } from "@bucketco/shared/urls";

import { useOrgsData } from "@/app/data/useOrgsData";
import { useAuthContext } from "@/auth/contexts/authContext";
import CenteredLayout from "@/common/components/CenteredLayout";
import OrganizationSwitcher from "@/common/components/OrganizationSwitcher";

export default function OrgSuspended() {
  const { currentOrg } = useAuthContext();
  const [orgSwitcherIsOpen, setOrgSwitcherIsOpen] = useState(false);

  const { data: orgs = [] } = useOrgsData();

  return (
    <CenteredLayout>
      {currentOrg ? (
        <VStack spacing={6}>
          <Heading fontSize="2xl">Account suspended</Heading>

          <Text>
            <strong>{currentOrg.name}</strong> has been suspended by Bucket.
          </Text>
          <Text>
            Please <Link href={`mailto:${SalesEmail()}`}>Contact sales</Link> to
            re-activate your organization.
          </Text>

          <ButtonGroup>
            {orgs.length > 1}
            <Button
              variant="outline"
              onClick={() => {
                setOrgSwitcherIsOpen(true);
              }}
            >
              Switch to a different organization
            </Button>

            <Button variant="outline">Log out</Button>
          </ButtonGroup>
          <OrganizationSwitcher
            isOpen={orgSwitcherIsOpen}
            onClose={() => {
              setOrgSwitcherIsOpen(false);
            }}
          />
        </VStack>
      ) : (
        <Spinner size="sm" />
      )}
    </CenteredLayout>
  );
}
