import {
  FeedbackQueryType,
  FeedbackTimelineQueryType,
} from "@bucketco/shared/feedbackAPI";

const qk = {
  list: (appId?: string, envId?: string, query?: Partial<FeedbackQueryType>) =>
    query
      ? (["apps", appId, "environment", envId, "feedbacks", query] as const)
      : (["apps", appId, "environment", envId, "feedbacks"] as const),

  single: (appId?: string, envId?: string, feedbackId?: string) => [
    ...qk.list(appId, envId),
    feedbackId,
  ],

  timeline: (
    appId: string | undefined,
    envId: string | undefined,
    query: Omit<FeedbackTimelineQueryType, "envId">,
  ) =>
    query
      ? [...qk.list(appId, envId), "timeline", query]
      : [...qk.list(appId, envId), "timeline"],
};

export default qk;
