import { useQuery } from "@tanstack/react-query";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { releasesQueryKeys } from "@/release/data/releasesQueryKeys";

export function useReleaseData(releaseId?: string) {
  const { appId } = useCurrentEnv();

  return useQuery({
    queryKey: releasesQueryKeys.single(appId, releaseId),

    queryFn: () =>
      api
        .get<"/apps/:appId/releases/:releaseId">(
          `/apps/${appId}/releases/${releaseId}`,
        )
        .then((res) => res.data.release),
    enabled: !!appId && !!releaseId,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
