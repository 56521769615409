import { forwardRef } from "react";
import { useFormContext } from "react-hook-form";
import { Switch, SwitchProps } from "@chakra-ui/react";

import { ManagedFormControl } from "@/common/components/form/ManagedFormControl";
import {
  FormElementBaseProps,
  useManagedFormControlProps,
} from "@/common/hooks/useManagedFormControlProps";
import { mergeFunctions } from "@/common/utils/mergeFunctions";
import { mergeRefs } from "@/common/utils/mergeRefs";

export type FormSwitchProps = FormElementBaseProps &
  Omit<SwitchProps, "value" | "isChecked">;

const FormSwitch = forwardRef<HTMLInputElement, FormSwitchProps>(
  ({ _control, onChange, onBlur, ...rest }, ref) => {
    const [managedProps, switchProps] = useManagedFormControlProps(rest);
    const { getFieldState } = useFormContext();
    const fieldState = getFieldState(managedProps.name);

    return (
      <ManagedFormControl
        horizontal
        {..._control}
        {...managedProps}
        _inputGroup={{
          flex: "0 1 auto",
          width: "auto",
          ...managedProps._inputGroup,
        }}
        _label={{
          lineHeight: "base",
          flex: "1 0 auto",
          ...managedProps._label,
        }}
        render={({ field }) => (
          <Switch
            alignSelf="flex-end"
            {...switchProps}
            {...field}
            ref={mergeRefs(field.ref, ref)}
            colorScheme={
              managedProps.isInvalid || fieldState.invalid ? "red" : undefined
            }
            isChecked={field.value}
            onBlur={mergeFunctions(field.onBlur, onBlur)}
            onChange={mergeFunctions(field.onChange, onChange)}
          />
        )}
      />
    );
  },
);
FormSwitch.displayName = "FormSwitch";

export default FormSwitch;
