import { SatisfactionScore } from "@bucketco/shared/schemas/satisfactionScore";

import { FeedbackSatisfactionIcon } from "@/feature-legacy/components/FeedbackSatisfaction";

export function SatisfactionRateIcon({
  satisfactionRate,
  size,
}: {
  satisfactionRate: number;
  size?: number;
}) {
  return (
    <FeedbackSatisfactionIcon
      colored={true}
      size={size}
      value={
        Math.min(5, Math.floor(satisfactionRate * 5) + 1) as SatisfactionScore
      }
    />
  );
}
