import {
  Box,
  Divider,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";

import { CompanyDetail } from "@bucketco/shared/companyAPI";

import { fieldIcons } from "@/common/components/AttributeFilters";
import { SectionHeading } from "@/common/components/SectionHeading";
import TimestampCell from "@/common/components/TimestampCell";
import { useCompanyContext } from "@/company/contexts/CompanyContext";

export default function CompanyAttributes() {
  const { company, companyIsLoading } = useCompanyContext();

  if (companyIsLoading) return null;

  return (
    <Flex direction="column" gap={5}>
      <SystemAttributes company={company} />
      <Divider />
      <CustomAttributes company={company} />
    </Flex>
  );
}

function SystemAttributes({ company }: { company?: CompanyDetail }) {
  return (
    <Box>
      <SectionHeading mb={4}>System attributes</SectionHeading>
      <>
        <Table size="xs" sx={{ tableLayout: "fixed" }} variant="unstyled">
          <Tbody>
            <Tr>
              <TdIcon>{fieldIcons.id}</TdIcon>
              <TdKey>Company ID</TdKey>
              <TdValue>{company?.id}</TdValue>
            </Tr>
            {company?.name && (
              <Tr>
                <TdIcon>{fieldIcons.text}</TdIcon>
                <TdKey>Company name</TdKey>
                <TdValue>{company?.name}</TdValue>
              </Tr>
            )}
            <Tr>
              <TdIcon>{fieldIcons.calendar}</TdIcon>
              <TdKey>First seen</TdKey>
              <TdValue>
                <TimestampCell value={company?.firstSeen} />
              </TdValue>
            </Tr>
            <Tr>
              <TdIcon>{fieldIcons.calendar}</TdIcon>
              <TdKey>Last seen</TdKey>
              <TdValue>
                <TimestampCell value={company?.lastSeen} />
              </TdValue>
            </Tr>
          </Tbody>
        </Table>
      </>
    </Box>
  );
}

function CustomAttributes({ company }: { company?: CompanyDetail }) {
  const attributes = Object.entries(company?.attributes ?? {});
  const filteredAttributes = attributes.sort(([a], [b]) => a.localeCompare(b));

  return (
    <Box>
      <SectionHeading mb={4}>Custom attributes</SectionHeading>
      <>
        {filteredAttributes.length === 0 ? (
          <Text color="gray.500" fontSize="sm">
            No attributes set for this company
          </Text>
        ) : (
          <Table size="xs" sx={{ tableLayout: "fixed" }} variant="unstyled">
            <Tbody>
              {filteredAttributes.map(([key, value], index) => (
                <Tr key={index}>
                  <TdIcon>{fieldIcons.custom}</TdIcon>
                  <TdKey>{key}</TdKey>
                  <TdValue>{value}</TdValue>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </>
    </Box>
  );
}

function TdKey({ children }: { children: string }) {
  return (
    <Td pl={2} width="25%">
      <Text color="gray.500" noOfLines={1} title={children}>
        {children}
      </Text>
    </Td>
  );
}

function TdIcon({ children }: { children: React.ReactNode }) {
  return (
    <Td color="gray.500" width={3}>
      {children}
    </Td>
  );
}

function TdValue({ children }: { children: React.ReactNode }) {
  return (
    <Td>
      <Text
        noOfLines={3}
        title={typeof children === "string" ? children : undefined}
      >
        {children}
      </Text>
    </Td>
  );
}
