import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Box, Divider, Flex, Spacer } from "@chakra-ui/react";

import { SEARCH_DEBOUNCE_MS } from "@bucketco/shared/constants";
import {
  FeatureCompaniesTableQueryType,
  FeatureCompany,
  FunnelStep,
  FunnelStepList,
} from "@bucketco/shared/featureAPI";
import { SatisfactionSpan } from "@bucketco/shared/schemas/satisfactionScore";

import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import SearchInput from "@/common/components/SearchInput";
import TablePagination from "@/common/components/TablePagination";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import useDataTable from "@/common/hooks/useDataTable";
import { useDebounce } from "@/common/hooks/useDebounce";
import { useLocalStorageTableConfiguration } from "@/common/hooks/useLocalStorageTableConfiguration";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import {
  useSearchArrayParam,
  useSearchParam,
} from "@/common/hooks/useSearchParam";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { CompaniesList } from "@/feature-legacy/components/CompaniesList";
import { ReportEmptyState } from "@/feature-legacy/components/ReportEmptyState";
import SatisfactionFilter, {
  spanToScore,
} from "@/feature-legacy/components/SatisfactionFilter";
import { StarsFunnelFilter } from "@/feature-legacy/components/StarsFunnelFilter";
import { useFeatureContext } from "@/feature-legacy/contexts/featureContext";
import featureQueryKeys from "../data/featureQueryKeys";

export default function CompaniesTable() {
  const { envId, appId } = useCurrentEnv();
  const { featureId } = useParams() as {
    appId: string;
    featureId: string;
  };
  const [subsegment] = useSubsegmentParam();
  const { feature } = useFeatureContext();

  const defaultFunnelSteps = useMemo(
    () => FunnelStepList.filter((step) => step !== "company"),
    [],
  );
  const [funnelSteps, setFunnelSteps] = useSearchArrayParam<FunnelStep>(
    "funnelStep",
    {
      fallback: defaultFunnelSteps,
    },
  );
  const [satisfaction, setSatisfaction] =
    useSearchParam<SatisfactionSpan>("satisfaction");

  const tableConfiguration = useLocalStorageTableConfiguration(
    "FeatureCompanies",
    {
      defaultColumns: [],
      defaultSort: {
        id: "lastUsed",
        desc: true,
      },
    },
  );

  const [searchQuery, setSearchQuery] = useSearchParam("q", { fallback: "" });
  const debouncedSearch = useDebounce(searchQuery, SEARCH_DEBOUNCE_MS);

  const {
    data,
    isFetching,
    isLoading,
    pageCount,
    fetchData,
    canPaginate,
    setCanPaginate,
    paginateActions,
    setPaginateActions,
  } = useDataTable<
    FeatureCompany,
    Pick<
      FeatureCompaniesTableQueryType,
      "funnelSteps" | "satisfaction" | "sortBy" | "idNameFilter"
    >
  >({
    apiCacheKey: featureQueryKeys.singleFeatureCompanies(
      appId,
      envId,
      featureId,
      {
        funnelSteps,
        satisfaction,
        subsegment: subsegment[0],
        idNameFilter: debouncedSearch,
      },
    ),
    apiHandler: (queryParams) => () =>
      api
        .get<`/apps/:appId/features/:featureId/companies`>(
          `/apps/${appId}/features/${featureId}/companies`,
          {
            params: {
              ...queryParams,
              funnelSteps,
              satisfaction: spanToScore(satisfaction),
              subsegment: subsegment[0],
              envId: envId!,
            },
          },
        )
        .then((res) => res.data),
    apiOptions: {
      enabled: !!appId && !!envId && !!featureId,
    },
    defaultQueryParams: {
      sortBy: "lastUsed",
      funnelSteps: defaultFunnelSteps,
      satisfaction: [],
      idNameFilter: debouncedSearch,
    },
  });

  useEffect(() => {
    fetchData({
      funnelSteps,
      pageIndex: 0,
    });

    segmentAnalytics.track("Feature Companies Filter Updated", {
      type: "funnelSteps",
      value: funnelSteps,
    });
  }, [fetchData, funnelSteps]);

  useEffect(() => {
    fetchData({
      satisfaction: spanToScore(satisfaction),
      pageIndex: 0,
    });

    segmentAnalytics.track("Feature Companies Filter Updated", {
      type: "satisfaction",
      value: satisfaction,
    });
  }, [fetchData, satisfaction]);

  useEffect(() => {
    fetchData({
      idNameFilter: debouncedSearch,
      pageIndex: 0,
    });
  }, [fetchData, debouncedSearch]);

  useEffect(() => {
    segmentAnalytics.page("Feature Companies");
  }, []);

  const companies = data?.data || [];

  return (
    <ReportEmptyState>
      <Flex px={6} py={3}>
        <Flex alignItems="center" gap={4}>
          <Box>
            <SearchInput
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.currentTarget.value)}
            />
          </Box>
          <StarsFunnelFilter
            disabledSteps={[]}
            value={funnelSteps}
            onChange={(newSteps) => {
              setFunnelSteps(newSteps);
            }}
          />
          <SatisfactionFilter
            size="sm"
            value={satisfaction}
            onChange={setSatisfaction}
          />
          <AnimatedSpinner show={isFetching} size="sm" />
        </Flex>
        <Spacer />
        <TablePagination
          canPaginate={canPaginate}
          label="Companies pagination"
          pageCount={pageCount}
          pageIndex={data?.pageIndex}
          pageSize={data?.pageSize}
          paginateActions={paginateActions}
          totalCount={data?.totalCount}
        />
      </Flex>
      <Divider />
      <CompaniesList
        columnStates={tableConfiguration.columns}
        companies={companies}
        feature={feature}
        fetchData={fetchData}
        isFetching={isFetching}
        isLoading={isLoading}
        pageCount={pageCount}
        setCanPaginate={setCanPaginate}
        setColumnStates={tableConfiguration.setColumns}
        setPaginateActions={setPaginateActions}
        setSortBy={tableConfiguration.setSort}
        sortBy={tableConfiguration.sort}
        trackingId="companies"
      />
    </ReportEmptyState>
  );
}
