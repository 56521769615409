import { useCallback } from "react";
import { FormControl, FormLabel, useColorModeValue } from "@chakra-ui/react";

import SubsegmentsIcon from "@/common/assets/subsegments.svg?react";
import {
  SegmentPicker,
  SegmentPickerProps,
} from "@/common/components/SegmentPicker";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

type Props = SegmentPickerProps & {
  label?: string;
};

export function SubsegmentPicker({ label, ...rest }: Props) {
  const [segments, setSegments] = useSubsegmentParam(["Active"]);

  const onChangeCallback = useCallback(
    (newSegments: string | string[] | null) => {
      const newSegmentsArray = Array.isArray(newSegments)
        ? newSegments
        : newSegments
        ? [newSegments]
        : [];
      setSegments(newSegmentsArray);
      segmentAnalytics.track("Subsegment Applied", {
        multiple: newSegmentsArray.length > 1,
        count: newSegmentsArray.length,
      });
    },
    [setSegments],
  );

  const segmentActiveColor = useColorModeValue("teal.500", "teal.300");
  return (
    <FormControl alignItems="center" display="flex" width="auto">
      {!!label && (
        <FormLabel color="dimmed" pb={0}>
          {label}
        </FormLabel>
      )}
      <SegmentPicker
        Icon={SubsegmentsIcon}
        id="subSegmentId"
        menuDescription="Only include companies that belong to the segment:"
        multiselect={false}
        placeholder="Apply segment"
        selectedColor={segmentActiveColor}
        value={segments}
        includeAll
        showPieChart
        onChange={onChangeCallback}
        {...rest}
      />
    </FormControl>
  );
}
