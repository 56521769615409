import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Heading,
  HStack,
  Link,
  Progress,
  Spinner,
  Text,
  TextProps,
  useColorModeValue,
} from "@chakra-ui/react";

import CompactNumber from "@/common/components/CompactNumber";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import clampProgressValue from "@/feature-legacy/utils/clampProgressValue";

export function MetricHeading({
  children,
  isDisabled,
  docsURL,
  tooltip,
}: {
  children: string | React.ReactNode;
  isDisabled?: boolean;
  docsURL?: string;
  tooltip?: string | React.ReactNode;
}) {
  return (
    <HStack align="center" mb={2}>
      <Heading
        as="h3"
        color={isDisabled ? "gray.500" : undefined}
        fontSize="xl"
        fontWeight="medium"
      >
        {children}
      </Heading>
      {tooltip ? <InfoIconTooltip docsURL={docsURL} text={tooltip} /> : null}
    </HStack>
  );
}

export function MetricDescription({
  isLoading,
  color,
  tooltip,
  docsURL,
  children,
}: {
  isLoading: boolean;
  color?: TextProps["color"];
  tooltip?: string | React.ReactNode;
  docsURL?: string;
  children?: string | React.ReactNode;
}) {
  return (
    <HStack color={useColorModeValue("gray.500", "gray.500")} h={8}>
      {isLoading ? (
        " "
      ) : children ? (
        <Text
          align="center"
          color={color}
          fontSize="sm"
          lineHeight="shorter"
          maxW={60}
        >
          {children}
        </Text>
      ) : null}
      {tooltip ? <InfoIconTooltip docsURL={docsURL} text={tooltip} /> : null}
    </HStack>
  );
}

export function MetricValue({
  children,
  isLoading,
  isDisabled = false,
}: {
  children: ReactNode | string;
  isLoading: boolean;
  isDisabled?: boolean;
}) {
  const disabledColor = useColorModeValue("gray.400", "gray.600");
  return (
    <Text
      as="div"
      casing="capitalize"
      color={isDisabled ? disabledColor : undefined}
      fontSize={isDisabled ? "2xl" : "3xl"}
      fontWeight="bold"
      h={12}
      lineHeight={10}
      mb={2}
      mt={2}
    >
      {isLoading ? <Spinner mb={1} size="sm" /> : children}
    </Text>
  );
}

type ProgressColors = "neutral" | "tentative" | "positive" | "negative";

const progressColors: { [key in ProgressColors]: string } = {
  neutral: "gray",
  tentative: "yellow",
  positive: "brand",
  negative: "red",
};

interface MetricProgressProps {
  isDisabled?: boolean;
  color?: ProgressColors;
  to: string;
  isLoading: boolean;
  fraction?: number;
  count?: number;
  isAnimated?: boolean;
}

export function MetricProgress({
  isDisabled = false,
  fraction,
  count,
  isLoading,
  color = "neutral",
  to,
  isAnimated = true,
}: MetricProgressProps) {
  const colorSchema = progressColors[color];

  return (
    <Box maxW={44}>
      <Progress
        colorScheme={colorSchema}
        max={1}
        mb={2}
        size="sm"
        value={isLoading ? 0 : clampProgressValue(fraction ?? 0)}
        variant={isAnimated ? "transition" : "default"}
      />
      <Link as={RouterLink} color="gray.500" fontSize="sm" to={to}>
        {typeof count !== "number" ? (
          <>&nbsp;</>
        ) : (
          !isDisabled && (
            <>
              <CompactNumber value={count} />{" "}
              {count === 1 ? "company" : "companies"}
            </>
          )
        )}
      </Link>
    </Box>
  );
}

export function Metric({ children }: { children: ReactNode }) {
  return (
    <Box flexBasis={0} flexGrow={1}>
      {children}
    </Box>
  );
}
