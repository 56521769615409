import { useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import {
  Box,
  Button,
  Flex,
  FlexProps,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";

import { FeedbackTimelineQueryType } from "@bucketco/shared/feedbackAPI";

import RollingTimeWindowIcon from "@/common/assets/rolling-time-window-icon.svg?react";
import MenuDescription from "@/common/components/MenuDescription";
import PeriodPicker from "@/common/components/PeriodPicker";
import { ChartWrapper } from "@/feature-legacy/components/ChartWrapper";
import { useFeatureDates } from "@/feature-legacy/data/useFeatureChartDates";
import SatisfactionAverageTimelineChart, {
  SatisfactionAverageTimelineChartLegend,
} from "@/feedback/components/SatisfactionAverageTimelineChart";
import { useFeedbackTimelineData } from "@/feedback/data/useFeedbackTimelineData";

type SatisfactionTimelineGraphOptions = FlexProps & {
  queryBase?: Partial<FeedbackTimelineQueryType>;
};

export function SatisfactionTimeline({
  queryBase = {},
  ...props
}: SatisfactionTimelineGraphOptions) {
  const {
    startDate,
    endDate,
    evaluationPeriod,
    setEvaluationPeriod,
    // hasPartialPeriod,
  } = useFeatureDates();

  const [rollingWindowSize, setRollingWindowSize] =
    useState<keyof typeof rollingWindowLabels>(7);

  const {
    data = [],
    isFetching,
    isLoading,
  } = useFeedbackTimelineData({
    ...queryBase,
    startDate,
    endDate,
    rollingWindowSize,
  });

  const rollingWindowLabels = {
    1: "1 day",
    7: "1 week",
    14: "2 weeks",
    30: "30 days",
  };

  return (
    <ChartWrapper
      gap={2}
      isLoading={isFetching}
      metric={
        <>
          <SatisfactionAverageTimelineChartLegend />

          <Menu>
            <MenuButton
              as={Button}
              leftIcon={
                <Text fontSize="lg" variant="dimmed">
                  <RollingTimeWindowIcon />
                </Text>
              }
              rightIcon={
                <Box fontSize="xl" mr={-2}>
                  <RiArrowDownSLine />
                </Box>
              }
              size="sm"
              variant="input"
            >
              {rollingWindowLabels[rollingWindowSize]} avg.
            </MenuButton>
            <MenuList>
              <MenuDescription color="dimmed">
                Select rolling average time window
              </MenuDescription>
              <MenuOptionGroup
                value={String(rollingWindowSize)}
                onChange={(value) =>
                  setRollingWindowSize(
                    Number(value) as keyof typeof rollingWindowLabels,
                  )
                }
              >
                <MenuItemOption value="1">
                  {rollingWindowLabels[1]}
                </MenuItemOption>
                <MenuItemOption value="7">
                  {rollingWindowLabels[7]}
                </MenuItemOption>
                <MenuItemOption value="14">
                  {rollingWindowLabels[14]}
                </MenuItemOption>
                <MenuItemOption value="30">
                  {rollingWindowLabels[30]}
                </MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>

          <PeriodPicker
            value={evaluationPeriod}
            onChange={(newPeriod) =>
              setEvaluationPeriod(newPeriod, { replace: true })
            }
          />
        </>
      }
      title="Timeline"
      {...props}
    >
      <Flex alignItems="flex-end" flex="1 1 auto">
        <SatisfactionAverageTimelineChart
          data={data}
          height={200}
          highlightPartialPeriod={true}
          isLoading={isLoading}
        />
      </Flex>
    </ChartWrapper>
  );
}
