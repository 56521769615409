import { ComponentProps } from "react";
import { chakra, shouldForwardProp } from "@chakra-ui/react";
import { isValidMotionProp, motion } from "framer-motion";

const MotionBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

export type MotionBoxProps = ComponentProps<typeof MotionBox>;
export default MotionBox;
