import {
  keepPreviousData,
  QueryFunction,
  useQuery,
} from "@tanstack/react-query";

import {
  FeatureMetricsHistoricalQuery,
  FeatureMetricsHistoricalQuerySchema,
  FeatureMetricsHistoricalTimeseries,
} from "@bucketco/shared/featureAPI";
import { OptionalKeys } from "@bucketco/shared/utils/types";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { featureQueryKeys } from "@/feature/data/featureQueryKeys";

export const fetchFeatureHistoricalData =
  (
    appId: string | undefined,
    featureId: string | undefined,
    params: FeatureMetricsHistoricalQuery,
  ): QueryFunction<FeatureMetricsHistoricalTimeseries> =>
  async ({ signal }) => {
    return api
      .get<"/apps/:appId/features/:featureId/metrics/historical">(
        `/apps/${appId}/features/${featureId}/metrics/historical`,
        {
          signal,
          params,
        },
      )
      .then((res) => {
        return res.data;
      });
  };

export function useFeatureHistoricalData(
  featureId: string,
  partialParams: OptionalKeys<FeatureMetricsHistoricalQuery, "envId">,
) {
  const { appId, envId } = useCurrentEnv();
  const params = {
    envId: envId!,
    ...partialParams,
  };

  return useQuery<FeatureMetricsHistoricalTimeseries>({
    queryKey: featureQueryKeys.singleMetricsHistorical(
      appId,
      envId,
      featureId,
      params,
    ),
    queryFn: fetchFeatureHistoricalData(appId, featureId, params),
    enabled:
      !!appId && FeatureMetricsHistoricalQuerySchema.safeParse(params).success,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    staleTime: 1000 * 10, // 10 seconds before an identical request can be made
    retry: false,
  });
}
