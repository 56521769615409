import { useQuery } from "@tanstack/react-query";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import featureQueryKeys from "@/feature-legacy/data/featureQueryKeys";

export function useFeatureFeatureViews(featureId?: string) {
  const { appId } = useCurrentEnv();

  return useQuery({
    queryKey: featureQueryKeys.featureViews(appId, featureId),

    queryFn: () =>
      api
        .get<"/apps/:appId/features/:featureId/views">(
          `/apps/${appId}/features/${featureId}/views`,
        )
        .then((res) => res.data),
    staleTime: 60 * 60 * 1000,
    enabled: !!appId && !!featureId,
  });
}
